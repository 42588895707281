import { NewItemsButtonView } from './NewItemsButtonView';
import React, { memo } from 'react';

import { useNewItemsButton } from './hooks/useNewItemsButton';

export const NewItemsButtonContainer = memo(({ tab, isNewAtTop, scrollBoxRef }) => {
  const { icon, buttonStyle, hasNewItems, newItemsCount, scrollToNewItems } = useNewItemsButton({
    isNewAtTop,
    scrollBoxRef,
    tab
  });
  return (
    <>
      {hasNewItems && (
        <NewItemsButtonView
          icon={icon}
          buttonStyle={buttonStyle}
          newItemsCount={newItemsCount}
          scrollToNewItems={scrollToNewItems}
        />
      )}
    </>
  );
});
