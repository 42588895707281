import { SocialVotesView } from './SocialVotesView';
import React, { forwardRef, memo } from 'react';

import { useSocialVotes } from './hooks/useSocialVotes';

export const SocialVotesContainer = memo(
  forwardRef(({ voteId }, ref) => {
    const { socialGroups, info, createPoll, url, setUrl, addOkPollRequest, bindPoll } =
      useSocialVotes({ voteId });
    return (
      <SocialVotesView
        ref={ref}
        socialGroups={socialGroups}
        info={info}
        createPoll={createPoll}
        url={url}
        setUrl={setUrl}
        addOkPollRequest={addOkPollRequest}
        voteId={voteId}
        bindPoll={bindPoll}
      />
    );
  })
);
