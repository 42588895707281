import {
  SET_ISETTINGS_INFO,
  SET_IS_INTERACTIVE_EXIST,
  SET_OPENED_INTERACTIVE_TYPE,
  UPDATE_ISETTINGS_INFO,
  UPDATE_SESSION_FROM_INFO
} from './actionTypes';

export function setISettingsInfoSuccess(info) {
  return {
    type: SET_ISETTINGS_INFO,
    info
  };
}

export function updateInfoField(field, value) {
  return {
    type: UPDATE_ISETTINGS_INFO,
    field,
    value
  };
}

export function updateSessionFromInfo(info) {
  return {
    type: UPDATE_SESSION_FROM_INFO,
    info
  };
}

export function setIsInteractiveExist(isInteractiveExist) {
  return {
    type: SET_IS_INTERACTIVE_EXIST,
    isInteractiveExist
  };
}

export function setOpenedInteractiveType(interactiveType) {
  return {
    type: SET_OPENED_INTERACTIVE_TYPE,
    interactiveType
  };
}
