import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { pickerObjToString } from 'helpers/dateTime';
import { isValidTime } from 'helpers/pulse';

import styles from './TimePicker.module.css';

function TimePicker(props) {
  const inputRef = useRef(null);
  const { minDate, selectedDate, minTime } = props;

  // const checkIsValid = (value) => {
  //   const dateTimeLessThanMin = isDateTimeLessThanMin(value);

  //   if (value === '' || dateTimeLessThanMin)
  //     return false;
  //   return true;
  // }

  const isDateTimeLessThanMin = (value) => {
    return (
      minDate &&
      selectedDate &&
      +new Date(`${pickerObjToString(minDate)} ${minTime}`) >
        +new Date(`${pickerObjToString(selectedDate)} ${value}`)
    );
  };

  const focusInput = () => {
    if (inputRef.current) inputRef.current.focus();
  };

  const blurHandler = () => {
    if (!isValidTime(time)) {
      props.setErrorMsg('Некорректное время.');
      setTime(props.value);
      focusInput();
      return;
    }
    if (minTime && isDateTimeLessThanMin(time)) {
      props.setErrorMsg('Разница между стартом и финшом должна быть больше n минут.');
      focusInput();
      return;
    }
    props.setErrorMsg('');
    props.changeHandler(time);
  };

  // const inputHandler = e => {
  //   const value = e.currentTarget.value;
  //   // props.setErrorMsg(checkIsValid(value));
  //   props.inputHandler(value)
  // }

  const inputHandler = (evt) => {
    setTime(evt.target.value);
    if (isValidTime(time)) props.setErrorMsg('');
  };

  const classes = classNames(styles['bar'], {
    [styles['invalid']]: props.errorMsg.length > 0
  });

  // const time = checkIsValid(props.value) ? props.value : undefined;
  const [time, setTime] = useState(props.value);

  useEffect(() => {
    if (props.value !== time);
    setTime(props.value);
  }, [props.value]);
  return (
    <>
      <h3>{time}</h3>
      <div className={styles['input-time-wrapper']}>
        <input
          ref={inputRef}
          value={time}
          onBlur={blurHandler}
          onInput={inputHandler}
          className={styles['input-time']}
          type="time"
        />
        <i className={classes} />
      </div>
      {props.errorMsg && <div className={styles['error-msg']}>{props.errorMsg}</div>}
    </>
  );
}

export default TimePicker;
