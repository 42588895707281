import React from 'react';

import styles from './CurrentDate.module.css';

function CurrentDate() {
  return (
    <div className={styles['main-chat-current-date']} style={{ top: 65 }}>
      10 янв.
    </div>
  );
}

export default CurrentDate;
