import useActions from 'hooks/useActions';

export const useMarkers = ({ messageId, labels, senderNumber }) => {
  const { addLabel, deleteLabel, clearLabels } = useActions();

  const toggleHandler = (event, color, isSelected) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    if (isSelected) deleteLabel(messageId, color);
    else addLabel(messageId, color);
  };

  const clearHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    clearLabels(messageId, senderNumber);
  };

  return {
    toggleHandler,
    clearHandler
  };
};
