import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'components';

import { INTERACTIVE_ACTIVITIES } from 'store/constants';

import styles from './InteractiveSettingsView.module.css';

const propTypes = {
  copySession: PropTypes.func,
  onCloseHandler: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export function InteractiveSettingsView({
  withGuide,
  copySession,
  onCloseHandler,
  children,
  startGuide,
  okButtonRef,
  setStepIndex,
  interactiveInfoRef,
  noCopy,
  activity,
  handlePublish
}) {
  return (
    <div className={styles['module-common']}>
      {children}
      <div className="d-flex align-center">
        <div className="d-flex" ref={okButtonRef}>
          {activity !== INTERACTIVE_ACTIVITIES.active && (
            <Button
              clickHandler={() => {
                onCloseHandler();
                setStepIndex?.((v) => ++v);
              }}>
              OK
            </Button>
          )}

          {activity === INTERACTIVE_ACTIVITIES.active && (
            <Button className="ml-2" clickHandler={handlePublish}>
              {window.t('publishAndSave')}
            </Button>
          )}
          {!noCopy && (
            <Button className="ml-2" clickHandler={copySession}>
              {window.t('copyInteractive')}
            </Button>
          )}
        </div>

        {withGuide && (
          <Button
            className="ml-auto"
            clickHandler={() => {
              // onCloseHandler();
              startGuide?.();
            }}>
            {window.t('helpToUse')}
          </Button>
        )}
      </div>
    </div>
  );
}

InteractiveSettingsView.propTypes = propTypes;
