import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'components/Button/Button';

import styles from './ErrorScreen.module.css';

function ErrorScreen() {
  const navigate = useHistory();
  return (
    <div className={styles['error-screen']}>
      <div className={styles['error-block']}>
        <p className={styles['error-message']}>
          Упс... Произошла ошибка при загрузке страницы, попробуйте еще раз
        </p>
        <p className={`${styles['error-message']} ${styles['error-message--small']}`}>
          Если ошибка повторится - сообщайте об этом{' '}
          <a rel="noreferrer" target="_blank" href="http://iactive.pro/index.html#contacts">
            нам
          </a>
        </p>
        <div className="d-flex">
          <Button
            clickHandler={() => {
              navigate.push('/');
              window.location.reload();
            }}>
            На главную
          </Button>
          <Button
            className="ml-2"
            clickHandler={() => {
              window.location.reload();
            }}>
            Перезагрузить
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ErrorScreen;
