import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  broadcastId: null,
  messages: [],
  selectedMessages: [],
  lastExportedMessages: {
    leftMsgId: null,
    rightMsgId: null
  },
  isSettingsOpened: false,
  isMsgCreatorOpened: false,
  activeBroadcastData: null
};

export const broadcastSlice = createSlice({
  name: 'broadcast',
  initialState,
  reducers: {
    fetchBroadcastMessagesSuccess: (state, action) => {
      const { messages } = action.payload;
      state.messages = messages;
    },
    // TODO: unused
    fetchBroadcastMessagesError: (state, action) => {
      const { error } = action.payload;
    },
    exportMessageSuccess: (state, action) => {
      const { id, messageFeedId, exportSideKey, interactiveId } = action.payload;
      return {
        ...state,
        messages: state.messages.map((el) => {
          return el.id === id
            ? {
                ...el,
                titr_tag:
                  el.titr_tag && Array.isArray(el.titr_tag)
                    ? [...el.titr_tag, exportSideKey]
                    : [exportSideKey]
              }
            : el;
        }),
        activeBroadcastData:
          state.activeBroadcastData?.id !== interactiveId
            ? state.activeBroadcastData
            : state.activeBroadcastData != null
            ? {
                ...state.activeBroadcastData,
                messagesInTitr: [
                  ...state.activeBroadcastData.messagesInTitr,
                  {
                    id: messageFeedId,
                    broadcastMessageId: id,
                    titr_tag: exportSideKey,
                    broadcast_id: interactiveId
                  }
                ]
              }
            : null
      };
    },
    delFromTitrSuccess: (state, action) => {
      const { id, messageFeedId, exportSideKey, broadcastId } = action.payload;
      return {
        ...state,
        messages: state.messages.map((el) =>
          el.id === id
            ? {
                ...el,
                titr_tag:
                  el.titr_tag && Array.isArray(el.titr_tag)
                    ? el.titr_tag.filter((key) => key !== exportSideKey)
                    : []
              }
            : el
        ),
        activeBroadcastData:
          state.activeBroadcastData != null
            ? {
                ...state.activeBroadcastData,
                messagesInTitr: state.activeBroadcastData.messagesInTitr.filter(
                  (el) =>
                    el.broadcastMessageId !== id ||
                    el.id !== messageFeedId ||
                    el.titr_tag !== exportSideKey
                )
                // [...state.activeBroadcastData.messagesInTitr, {
                //   id: messageFeedId,
                //   broadcastMessageId: id,
                //   titr_tag: exportSideKey,
                // }]
              }
            : null
      };
    },
    setBroadcastId: (state, action) => {
      const { id } = action.payload;
      return {
        ...state,
        broadcastId: id
      };
    },
    addMessageToBroadcastSuccess: (state, action) => {
      const { msg } = action.payload;
      if (state.messages.some((message) => message.id === msg.id)) return state;
      return {
        ...state,
        messages: [...state.messages, msg]
      };
    },
    clearTitreSuccess: (state, action) => {
      const { titreTag, broadcastId } = action.payload;
      return {
        ...state,
        messages: state.messages.map((el) => {
          if (broadcastId !== el.broadcast_id) return el;
          if (!titreTag)
            return {
              ...el,
              titr_tag: []
            };
          else
            return {
              ...el,
              titr_tag: Array.isArray(el.titr_tag)
                ? el.titr_tag.filter((elm) => elm !== titreTag)
                : []
            };
        }),
        activeBroadcastData: state.activeBroadcastData && {
          ...state.activeBroadcastData,
          messagesInTitr: state.activeBroadcastData.messagesInTitr.filter(
            (el) => el.broadcast_id !== broadcastId
          )
        }
      };
    },
    updateMessageText: (state, action) => {
      const { id, text } = action.payload;
      return {
        ...state,
        messages: state.messages.map((el) => (el.id === id ? { ...el, content: text } : el))
      };
    },
    removeBroadcastMessageSuccess: (state, action) => {
      const { id } = action.payload;
      return {
        ...state,
        messages: state.messages.filter((el) => el.id !== id)
      };
    },
    setBroadcastMessageFavoriteState: (state, action) => {
      const { id, value } = action.payload;
      return {
        ...state,
        messages: state.messages.map((message) =>
          message.id === id ? { ...message, favorite: value } : message
        )
      };
    },
    setActiveBroadcastData: (state, action) => {
      const { data } = action.payload;
      return {
        ...state,
        activeBroadcastData: data
      };
    },
    updateActiveBroadcastField: (state, action) => {
      const { field, value } = action.payload;
      return {
        ...state,
        activeBroadcastData: {
          ...state.activeBroadcastData,
          [field]: value
        }
      };
    },
    resetBroadcastStore: (state, action) => {
      return {
        ...initialState
      };
    }
  }
});

export const broadcastActions = broadcastSlice.actions;
export const broadcastReducer = broadcastSlice.reducer;
