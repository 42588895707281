import { useSelector } from 'react-redux';

import { useActions } from 'hooks';

export const useVoteAlerts = () => {
  const { setIsAlertEnable, setVoteAlerts } = useActions();
  const { alerts } = useSelector((state) => state.vote);

  const alertCloseHandler = () => {
    setVoteAlerts({ alerts: [] });
  };

  const disableAlerts = () => {
    setVoteAlerts({ alerts: [] });
    setIsAlertEnable({ isAlertEnable: false });
  };
  return {
    alerts,
    alertCloseHandler,
    disableAlerts
  };
};
