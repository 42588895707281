import { RegistrationToolbarControls } from '../RegistrationToolbarControls/RegistrationToolbarControls';
import PropTypes from 'prop-types';
import React, { memo, useRef, useState } from 'react';

import { RegistrationSearchContainer } from 'containers';

import { Button, Toolbar } from 'components';

import s from './RegistrationToolbarView.module.css';

const propTypes = {
  interactiveName: PropTypes.string,
  toggleSettings: PropTypes.func
};

export const RegistrationToolbarView = memo(
  ({ interactiveName, toggleSettings, activity, interactiveId }) => {
    const [isFilterActive, setIsFilterActive] = useState(false);

    return (
      <Toolbar
        title={interactiveName || window.t('registration')}
        className={`${s.toolbar} ${isFilterActive ? s.toolbarFull : s.toolbarMin}`}>
        <RegistrationToolbarControls
          settingsClickHandler={toggleSettings}
          activity={activity}
          id={interactiveId}
        />
        <RegistrationSearchContainer setIsFilterActive={setIsFilterActive} />
      </Toolbar>
    );
  }
);

RegistrationToolbarView.propTypes = propTypes;
