import {
  SET_CHART_FULL_DATA,
  SET_IS_CHART_LOADING,
  SET_PULSE_TIMER_ACTIVITY,
  UPDATE_CHART_FULL_DATA,
  UPDATE_CHART_OPTION
} from './actionTypes';

export function setIsChartLoading(value) {
  return {
    type: SET_IS_CHART_LOADING,
    value
  };
}

export function updateChartOption(option) {
  return {
    type: UPDATE_CHART_OPTION,
    option
  };
}

export function updateChartFullData(field, value) {
  return {
    type: UPDATE_CHART_FULL_DATA,
    field,
    value
  };
}

export function setChartFullData(data) {
  return {
    type: SET_CHART_FULL_DATA,
    data
  };
}

export function setTimerActivity(isActive) {
  return {
    type: SET_PULSE_TIMER_ACTIVITY,
    isActive
  };
}
