import { AudioBox } from '../AudioBox/AudioBox';
import { ImgBox } from '../ImgBox/ImgBox';
import { VideoBox } from '../VideoBox/VideoBox';
import classNames from 'classnames';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import { FileItem } from 'components';

import { getUIDateFromDBDate } from 'helpers/dateTime';
import { getFormattedContent } from 'helpers/text';

import styles from './ChatMessage.module.css';

const propTypes = {
  message: PropTypes.object.isRequired
};

const defaultProps = {
  message: {}
};

const hasAttachment = (atch) => {
  return atch && atch.length > 0;
};

function ChatMessage({ message, openMedia }) {
  const messageRef = useRef();
  const [error, setError] = useState(null);
  const [fetching, setFetching] = useState(false);
  const { content, date, attachments, own, message_type } = message;
  const classes = classNames(styles['chat-msg'], {
    [styles['owner']]: Boolean(own || message_type)
  });

  const textClasses = classNames(styles['chat-msg-text'], styles[`chan-${message.channel}`], {
    [styles['with-attachments']]: hasAttachment(attachments)
  });

  const formattedContent = getFormattedContent(content).replace(/\r?\n/g, '<br />');

  const download = (url, name) => {
    if (!url) {
      throw new Error('Resource URL not provided! You need to provide one');
    }
    setFetching(true);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobURL;
        a.style = 'display: none';

        if (name) {
          a.download = name;
        } else {
          a.download = url.split('/').slice(-1)[0];
        }

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(blobURL);
        }, 100);
      })
      .catch(() => setError(true))
      .finally(() => {
        setFetching(false);
      });
  };

  return (
    <div
      ref={messageRef}
      className={classes}
      data-date={date}
      data-show-date={getUIDateFromDBDate(date)}>
      <div className={styles['chat-msg-date']}>{format(new Date(date), 'HH:mm')}</div>
      <div className={styles['chat-msg-content']}>
        <div className={styles['chat-msg-wrapper']}>
          {!content && hasAttachment(attachments) && (
            <div
              className={classNames(styles['chat-msg-attachments'], {
                [styles['chat-msg-attachments--channel']]: !content,
                [styles[`chan-${message.channel}`]]: !content
              })}>
              {attachments.map((el) => {
                if (el.type?.includes('image'))
                  return (
                    <ImgBox
                      key={el.url}
                      className={styles['msg-img-wrapper']}
                      url={el.url}
                      openMedia={openMedia}
                    />
                  );
                else if (el.type?.includes('video'))
                  return (
                    <VideoBox
                      key={el.url}
                      className={styles['msg-video-wrapper']}
                      url={el.url}
                      openMedia={openMedia}
                    />
                  );
                else if (el.type?.includes('audio_msg') || el.type?.includes('audio'))
                  return (
                    <AudioBox
                      key={el.ogg || el.url}
                      className={styles['msg-audio-wrapper']}
                      messageId={message.ID || message.id}
                      url={el.ogg || el.url}
                      openMedia={openMedia}
                    />
                  );
                return (
                  <button
                    key={el.url}
                    style={{ display: 'block' }}
                    disabled={fetching}
                    onClick={() => download(el.url, el.name)}>
                    <FileItem file={el} />
                  </button>
                );
              })}
            </div>
          )}
          {content && (
            <div className={textClasses}>
              <p dangerouslySetInnerHTML={{ __html: formattedContent || '' }} />
              <div className={styles['chat-msg-attachments-with-text']}>
                {attachments?.map((el) => {
                  if (el.type?.includes('image'))
                    return (
                      <ImgBox
                        key={el.url}
                        className={styles['msg-img-wrapper']}
                        url={el.url}
                        openMedia={openMedia}
                      />
                    );
                  else if (el.type?.includes('video'))
                    return (
                      <VideoBox
                        key={el.url}
                        className={styles['msg-video-wrapper']}
                        url={el.url}
                        openMedia={openMedia}
                      />
                    );
                  else if (el.type?.includes('audio_msg') || el.type?.includes('audio'))
                    return (
                      <AudioBox
                        key={el.ogg || el.url}
                        className={styles['msg-audio-wrapper']}
                        messageId={message.ID || message.id}
                        url={el.ogg || el.url}
                        openMedia={openMedia}
                      />
                    );
                  else
                    return (
                      <button
                        key={el.url}
                        style={{ display: 'block' }}
                        disabled={fetching}
                        onClick={() => download(el.url, el.name)}>
                        <FileItem file={el} />
                      </button>
                    );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChatMessage;

ChatMessage.propTypes = propTypes;
ChatMessage.defaultProps = defaultProps;
