import { format } from 'date-fns';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import FeedbackInput from 'components/List/FeedbackInput/FeedbackInput';

import styles from './ChatFooter.module.css';

function ChatFooter({ setMessages, messageId, senderNumber, channel, hasMoreNext }) {
  const answer = useSelector((state) => state.settings.messageFeedConfig.answer);
  const [text, setText] = useState('');
  const send = async (textContent = null) => {
    // dispatch(sendMessage('992985007065', message, 'whatsapp'))
    const messageContent = textContent || text;
    const message = {
      attachments: [],
      author: '79123595584',
      content: messageContent.replace(/\r?\n/g, '<br />'),
      // "2021-07-01 19:46:05"
      date: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      favorite: '',
      id: Math.random(),
      image: 'http://mb.iactive.pro/bot_itr/avatar/79123595584_viber.jpg',
      region: '',
      senderNumber: '79123595584',
      own: 1,
      isNew: true
    };

    setMessages((messages) => [...messages, message]);
    setText('');
    // await sendChatMessage({ to: '992985007065', message: message.content, channel: 'whatsapp' });
    // makeMessageSended(message.id);
  };

  // const makeMessageSended = (messageId) => {
  //   setMessages(messages => messages.map(message => message.id === messageId ? { ...message, unsend: false } : message))
  // }

  return (
    <div className={styles['main-chat-msg']}>
      <FeedbackInput
        emojiPickerOffset={200}
        handleSend={!hasMoreNext ? send : () => {}}
        id={messageId}
        senderNumber={senderNumber}
        channel={channel}
        quickReplies={answer}
      />
    </div>
  );
}

export default ChatFooter;
