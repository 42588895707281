import hum from '../../../../../assets/images/hum1.jpg';
import { ActionBtnCard } from './ActionBtnCard/ActionBtnCard';
import { FieldsFormCard } from './FieldsFormCard/FieldsFormCard';
import { Participation } from './Participation/Participation';
import { memo, useMemo, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { Button, Input } from 'components';
import FeedbackInput from 'components/List/FeedbackInput/FeedbackInput';

import { getUIDateFromJSDate } from 'helpers/dateTime';
import { getImageByChannel } from 'helpers/image';
import { goTo } from 'helpers/routing';

import styles from './ParticipantCard.module.css';

export const ParticipantCard = memo(
  ({
    card = {},
    shouldBeBodyConfig,
    shouldBeHeadConfig,
    quickReplies,
    deleteParticipant,
    editAuthorData,
    editParticipantAuthorData,
    date
  }) => {
    const [isActive, setIsActive] = useState(false);
    const [edit, setEdit] = useState(false);

    const {
      author_data = {},
      author_id,
      channel,
      avatar,
      card_id,
      iactive_id,
      identifiter: senderNumber
    } = card;
    const {
      name = '',
      surname = '',
      phone = '',
      participation,
      city,
      job,
      position,
      speciality,
      patronymic = '',
      participationFact = null
    } = author_data;

    const deleteHandler = () => {
      if (confirm(window.t('removingParticipant'))) {
        deleteParticipant(card_id, iactive_id);
      }
    };

    const [isMinimized, setIsMinimized] = useState(false);
    const minCardHeight = 80;

    const formatDate = getUIDateFromJSDate(date || '');

    const history = useHistory();
    const location = useLocation();

    const iconClickHandler = () => {
      // setProfileMessage(data);
      goTo(history, `/chat/${channel}_${senderNumber}`, null, location);
    };

    const fieldNames = useMemo(() => {
      let obj = {};
      shouldBeHeadConfig?.forEach((el) => {
        obj[el.name] = true;
      });
      return obj;
    }, [shouldBeHeadConfig]);

    const onErrorHandler = (e) => {
      let chanImg = getImageByChannel(channel);
      e.target.src = chanImg.includes('channel-none') ? hum : chanImg;
    };

    return (
      <AnimateHeight duration={600} height={isMinimized ? minCardHeight : 'auto'}>
        <div className={styles.card}>
          <div className={styles.head}>
            <div className={styles.profile}>
              <div className={styles.profile_image}>
                <img
                  src={(channel && getImageByChannel(channel, avatar)) || hum}
                  alt="Photo"
                  width={40}
                  height={40}
                  onClick={channel && iconClickHandler}
                  style={{ cursor: channel ? 'pointer' : 'auto' }}
                  onError={onErrorHandler}
                />
              </div>
              <div className={styles.profile_data}>
                <div className={styles.name}>
                  {fieldNames?.['name'] &&
                    (edit ? (
                      <Input
                        // width={120}
                        key="name"
                        type="text"
                        value={author_data['name']}
                        placeholder={window.t('name')}
                        inpStyle={{ fontWeight: 700 }}
                        changeHandler={(e) => {
                          editParticipantAuthorData({
                            card_id,
                            field: 'name',
                            value: e.target.value
                          });
                        }}
                        style={{
                          flex: `2 1 120px`
                        }}
                      />
                    ) : (
                      <span>{name}</span>
                    ))}
                  {fieldNames?.['patronymic'] &&
                    (edit ? (
                      <Input
                        // width={140}
                        key="patronymic"
                        type="text"
                        value={author_data['patronymic']}
                        placeholder={window.t('patronymic')}
                        inpStyle={{ fontWeight: 700 }}
                        style={{
                          flex: `3 1 140px`
                        }}
                        changeHandler={(e) => {
                          editParticipantAuthorData({
                            card_id,
                            field: 'patronymic',
                            value: e.target.value
                          });
                        }}
                      />
                    ) : (
                      <span>{patronymic}</span>
                    ))}
                  {fieldNames?.['surname'] &&
                    (edit ? (
                      <Input
                        // width={140}
                        key="surname"
                        type="text"
                        value={author_data['surname']}
                        placeholder={window.t('surname')}
                        inpStyle={{ fontWeight: 700 }}
                        style={{
                          flex: `3 1 140px`
                        }}
                        changeHandler={(e) => {
                          editParticipantAuthorData({
                            card_id,
                            field: 'surname',
                            value: e.target.value
                          });
                        }}
                      />
                    ) : (
                      <span>{surname}</span>
                    ))}
                </div>
                <div className={styles.senderNumber}>
                  {fieldNames?.['phone'] &&
                    (edit ? (
                      <Input
                        width={200}
                        key="phone"
                        type="text"
                        value={
                          author_data['phone'] ||
                          // || (senderNumber && `+${senderNumber}`)
                          ''
                        }
                        placeholder={window.t('phone')}
                        inpStyle={{ fontWeight: 700 }}
                        changeHandler={(e) => {
                          editParticipantAuthorData({
                            card_id,
                            field: 'phone',
                            value: e.target.value
                          });
                        }}
                      />
                    ) : (
                      <span>
                        {phone ||
                          // || (senderNumber && `+${senderNumber}`)
                          ''}
                      </span>
                    ))}
                </div>
              </div>
            </div>
            <div className={styles.controls}>
              <div className={styles.participationBlock}>
                {fieldNames?.['participation'] && (
                  <Participation
                    edit={edit}
                    card_id={card_id}
                    toggleParticipation={editParticipantAuthorData}
                    typeOfParticipation={participation}
                    type="plan"
                    title={window.t('participationPlan')}
                  />
                )}
                <Participation
                  edit={edit}
                  card_id={card_id}
                  toggleParticipation={editParticipantAuthorData}
                  typeOfParticipation={participationFact}
                  type="fact"
                  title={window.t('factOfParticipation')}
                  // для первого нажатия на факт участия
                  editAuthorData={editAuthorData}
                  iactive_id={iactive_id}
                  author_data={author_data}
                />
              </div>

              <ActionBtnCard
                isMinimized={isMinimized}
                setIsMinimized={setIsMinimized}
                channel={channel}
                isActive={isActive}
                setIsActive={setIsActive}
                edit={edit}
                setEdit={setEdit}
                deleteHandler={deleteHandler}
              />
            </div>
          </div>
          <div className={styles.bodyCard}>
            <div className={styles.date}>
              <span>{formatDate}</span>
            </div>
            <FieldsFormCard
              author_data={author_data}
              card_id={card_id}
              config={shouldBeBodyConfig}
              edit={edit}
              editParticipantAuthorData={editParticipantAuthorData}
            />
          </div>
          <div className={styles.chat}>
            <FeedbackInput
              channel={channel}
              isActive={isActive}
              quickReplies={quickReplies}
              senderNumber={senderNumber}
            />
          </div>
          {edit && (
            <div className={styles.editBtnBlock}>
              <Button
                clickHandler={() => {
                  editAuthorData(card_id, iactive_id, author_data);
                  setEdit(!edit);
                }}>
                {window.t('save')}
              </Button>
            </div>
          )}
        </div>
      </AnimateHeight>
    );
  }
);
