import { useContext, useEffect, useState } from 'react';

import { Button } from 'components';

import { LocaleContext } from 'providers/LocaleProvider';

export const useStateItem = ({ type }) => {
  const [localeState] = useContext(LocaleContext);
  const [startMenuRect, setStartMenuRect] = useState(false);
  const [startMenuActivity, setStartMenuActivity] = useState(false);
  const [currentType, setCurrentType] = useState('cycle');

  useEffect(() => {
    setCurrentType(type);
  }, [type]);

  const startActivatorHandler = (e) => {
    if (e) {
      const rect = e.currentTarget.getBoundingClientRect();
      setStartMenuRect(rect);
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
    const newValue = !startMenuActivity;
    // if (newValue)
    //     setDurationMenuActivity(false);
    setStartMenuActivity(newValue);
  };

  const startBtn = (
    <Button
      variant="text"
      color="#808080"
      size="large"
      clickHandler={startActivatorHandler}
      hoverColor="transparent"
      activeColor="transparent"
      style={{
        padding: '2px 2px',
        borderBottom: '1px solid #808080',
        minWidth: 'unset',
        margin: '0px 8px 4px 4px',
        borderRadius: 0
      }}
      title={window.t('settings')}>
      {window.t(currentType)}
    </Button>
  );

  return {
    localeState,
    startMenuRect,
    startBtn,
    startActivatorHandler,
    startMenuActivity,
    setStartMenuActivity
  };
};
