import { addOwnMessage, makeMessageSended } from 'store/actions/chat';

import { sendChatMessage } from 'api/dataLayer';

export function setChatScrollTop(scrollHeight) {
  return (dispatch, getState) => {
    const scrollBoxRef = getState().chat.scrollBoxRef;
    if (scrollHeight) {
      setTimeout(() => {
        scrollBoxRef.current.scrollTop = scrollBoxRef.current.scrollHeight - scrollHeight;
      }, 20);
    }
  };
}

export function sendMessage(to, message, channel) {
  return async (dispatch) => {
    // SenderNumber: "79123595584"
    // answer: ""
    // attachments: []
    // author: "79123595584"
    // channel: "viber"
    // content: "Привет"
    // date: "2021-04-22 12:24:37"
    // favorite: ""
    // id: "7328807"
    // image: "http://mb.iactive.pro/bot_itr/avatar/79123595584_viber.jpg"
    // region: ""
    // senderNumber: "79123595584"
    // socialAccountID: ""
    // socialData: ""
    // socialGroupID: ""
    // socialPostID: ""
    // socialURLdata: ""
    // social_href: ""
    const msgId = Symbol();
    dispatch(
      addOwnMessage({
        attachments: [],
        author: '79123595584',
        channel: 'viber',
        content: message,
        date: new Date().toString(),
        favorite: '',
        id: msgId,
        image: 'http://mb.iactive.pro/bot_itr/avatar/79123595584_viber.jpg',
        region: '',
        senderNumber: '79123595584',
        own: 1,
        unsend: true
      })
    );
    try {
      await sendChatMessage({ to, message, channel });
      dispatch(makeMessageSended(msgId));
    } catch (ex) {
      console.error(ex);
    }
  };
}
