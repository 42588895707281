import AddPhoneModal from './AddPhoneModal';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import reactStringReplace from 'react-string-replace';

import {
  Avatar,
  BlurWrapper,
  Button,
  Icon,
  List,
  ListItem,
  ListItemText,
  Portal,
  SvgCheckbox,
  Text,
  Title
} from 'components';

import {
  getTelegramClients,
  logoutTelegram,
  logoutTelegramBot,
  toggleCheckboxState
} from 'api/dataLayer';

import { getImageByChannel } from 'helpers/image';

const propTypes = {
  setDeleteCardData: PropTypes.func.isRequired,
  setIsDeleteCardActive: PropTypes.func.isRequired,
  setIsTelegramConnected: PropTypes.func.isRequired
};

const defaultProps = {
  setDeleteCardData: () => {},
  setIsDeleteCardActive: () => {},
  setIsTelegramConnected: () => {}
};

function TelegramCollapseBody({
  setDeleteCardData,
  setIsDeleteCardActive,
  setIsTelegramConnected
}) {
  const [connectId, setConnectId] = useState(null);
  const [groups, setGroups] = useState([]);
  const [isModalActive, setIsModalActive] = useState(false);
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [isAuthed, setIsAuthed] = useState(false);

  const updateGroups = useCallback(async () => {
    try {
      const res = await getTelegramClients();
      const clients = res?.body;

      if (!clients) return;

      setGroups(
        Object.values(clients).map((el) => ({
          ...el,
          groupId: el.id,
          groupName: el.app_phone,
          isComment: null,
          isMessage: null
        }))
      );
    } catch (ex) {
      console.error(ex);
    }
  }, []);

  useEffect(() => {
    updateGroups();
  }, [updateGroups]);

  useEffect(() => {
    setIsTelegramConnected(groups.some((g) => g.status === 'connected'));
  }, [groups]);

  const checkboxHandler = async (groupId, key, isChecked) => {
    try {
      await toggleCheckboxState({
        groupId,
        key,
        channel: 'vk',
        isChecked: Number(isChecked)
      });
      setGroups((groups) =>
        groups.map((el) => (el.groupId === groupId ? { ...el, [key]: isChecked } : el))
      );
    } catch (ex) {
      console.error(ex);
    }
  };

  const deleteRequest = async ({ groupId, type, isComment, isMessage }) => {
    const api = type === 'bot' ? logoutTelegramBot : logoutTelegram;

    try {
      await api(groupId);
      setGroups((groups) => groups.filter((el) => el.groupId !== groupId));
      setIsDeleteCardActive(false);
    } catch (ex) {
      console.error(ex);
    }
  };

  const deleteHandler = (groupId) => {
    const groupData = groups.find((el) => el.groupId === groupId);
    groupData.deleteRequest = deleteRequest;
    groupData.removeMessage = window.t('realyWantToDeleteBot');
    // для принудительного обновления стейта в DeleteCardModal
    groupData.random = Math.random();

    setDeleteCardData(groupData);
    setIsDeleteCardActive(true);
  };

  const replaceStatus = (text, status, phone) => {
    // let replacedText = reactStringReplace(text, />status/g, (match, i) => (
    //   <strong key={match + i}>{status}</strong>
    // ));

    //  let replacedText = reactStringReplace(text, /@([\s\w]+)@/g, (match, i) => (
    //     <Button key={match + i}>{match}</Button>
    //   ));

    //   return replacedText;
    let replacedText = reactStringReplace(text, />([а-яА-ЯЁё\s\w]+)/g, (match, i) => (
      <strong key={match + i}>{status}</strong>
    ));

    replacedText = reactStringReplace(replacedText, /@([а-яА-ЯЁё\s\w\.]+)@/g, (match, i) => (
      // <Icon className="align-middle" size={24} key={match + i} icon={match} />
      <Button
        clickHandler={() => {
          setPhone(phone);
          setIsAuthed(true);
          setIsModalActive(true);
        }}
        variant="text"
        className="d-inline-block"
        key={match + i}>
        {match}
      </Button>
    ));

    return replacedText;
  };

  const closeHandler = () => {
    setIsModalActive(false);
    setIsAuthed(false);
    setPhone('');
    setPassword('');
    setCode('');
    setConnectId(null);
    updateGroups();
  };

  return (
    <div style={{ padding: '10px 0' }}>
      <Portal nodeId="work-pane-modals">
        <BlurWrapper
          isOpened={isModalActive}
          clickHandler={() => setIsModalActive(false)}
          position="absolute"
          background="#5a5a5a2e"
          className="d-flex justify-center align-center">
          {isModalActive && (
            <AddPhoneModal
              closeHandler={closeHandler}
              setGroups={setGroups}
              phone={phone}
              setPhone={setPhone}
              isAuthed={isAuthed}
              setIsAuthed={setIsAuthed}
              password={password}
              setPassword={setPassword}
              code={code}
              setCode={setCode}
              connectId={connectId}
            />
          )}
        </BlurWrapper>
      </Portal>
      <Title className="d-inline-block" weight="normal" style={{ marginLeft: 50 }}>
        {window.t('accounts')}
      </Title>
      <Text tag="span" color="#808080" size={14}>
        {` ${groups.length}`}
      </Text>
      <List noStyle style={{ marginLeft: 50 }}>
        {groups.map(({ groupId, groupName, isComment, isMessage, photo, status, type }) => (
          <ListItem key={groupId} noHover className="ml-0 mr-0 mt-2 mb-0 pa-0 flex-column">
            <div className="d-flex">
              {type === 'bot' ? (
                <a
                  className="d-flex"
                  style={{ color: 'inherit' }}
                  href={`https://t.me/${groupName}`}
                  target="_blank"
                  rel="noreferrer">
                  <Avatar
                    src={getImageByChannel('telegram')}
                    size={30}
                    className="flex-shrink-0 mr-2 align-self-center"
                  />
                  <ListItemText primary={groupName} />
                </a>
              ) : (
                <>
                  <Avatar
                    src={getImageByChannel('telegram')}
                    size={30}
                    className="flex-shrink-0 mr-2 align-self-center"
                  />
                  <ListItemText primary={groupName} />
                </>
              )}
              {status !== 'connected' && (
                <Button
                  variant="icon"
                  color="#57adf2"
                  size="large"
                  clickHandler={() => {
                    setConnectId(groupId);
                    setIsAuthed(true);
                    setPhone(groupName);
                    setIsModalActive(true);
                  }}
                  style={{ padding: '6px 8px', margin: '-1px 0 0 8px' }}>
                  {window.t('confirm')}
                </Button>
              )}
              <Button
                onlyIcon
                startIcon={<Icon size={14} color="#CDCDCD" fontName="far fa-times" />}
                variant="icon"
                hoverColor="transparent"
                style={{
                  opacity: 1
                }}
                clickHandler={() => deleteHandler(groupId)}
              />
              <div className="d-flex ml-auto">
                {isComment !== null && isComment !== undefined && (
                  <SvgCheckbox
                    isChecked={Boolean(+isComment)}
                    changeHandler={(isChecked) => checkboxHandler(groupId, 'isComment', isChecked)}
                    style={{
                      color: '#808080',
                      fontSize: 14,
                      marginLeft: 10
                    }}>
                    {window.t('comments')}
                  </SvgCheckbox>
                )}
                {isMessage !== null && isMessage !== undefined && (
                  <SvgCheckbox
                    isChecked={Boolean(+isMessage)}
                    changeHandler={(isChecked) => checkboxHandler(groupId, 'isMessage', isChecked)}
                    style={{
                      color: '#808080',
                      fontSize: 14,
                      marginLeft: 10
                    }}>
                    {window.t('messages')}
                  </SvgCheckbox>
                )}
              </div>
            </div>
            {
              // status === 'connected' &&
              // <div className="d-flex align-center" style={{ paddingLeft: 40, maxWidth: 630 }}>
              //   <Icon fontName="fad fa-exclamation-circle mr-3" color="#ea0000" />
              //   <Text color="#808080">{replaceStatus(window.t('telegramStatus'), status, groupName)}</Text>
              // </div>
            }
          </ListItem>
        ))}
      </List>

      <Button
        clickHandler={() => setIsModalActive(true)}
        color="#fcfcfc"
        textColor="#757575"
        variant="google"
        size="medium"
        className="ml-auto mr-auto mt-5 mb-5"
        startIcon={<Icon icon="telegram" color="#419FD9" width={19} height={17} />}>
        {window.t('connectNewNumberOrBot')}
      </Button>
    </div>
  );
}

export default TelegramCollapseBody;

TelegramCollapseBody.propTypes = propTypes;
TelegramCollapseBody.defaultProps = defaultProps;
