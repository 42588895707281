import classNames from 'classnames';
import { useCallback, useContext, useMemo, useRef } from 'react';

import { MainGuideContext } from 'providers/MainGuideProvider';

import { getUILastDate } from 'helpers/dateTime';

import styles from './DateTag.module.css';

function DateTag({ className, date, clickHandler, isLast }) {
  const timestamp = useMemo(() => new Date(date).getTime(), [date]);
  const { newBtnRef } = useContext(MainGuideContext);

  const handleClick = useCallback(
    (event) => {
      event.stopPropagation();
      clickHandler();
    },
    [clickHandler]
  );

  const modifications = useMemo(() => {
    const result = {};
    if (!date) {
      result[styles['dateTag--new']] = true;
    }
    // else if (now - timestamp <= 24 * 60 * 60 * 1000) {
    //     result[styles['dateTag--day']] = true
    // } else if (now - timestamp <= 7 * 24 * 60 * 60 * 1000) {
    //     result[styles['dateTag--week']] = true
    // } else if (now - timestamp <= 15 * 24 * 60 * 60 * 1000) {
    //     result[styles['dateTag--halfAMonth']] = true
    // } else if (now - timestamp <= 30 * 24 * 60 * 60 * 1000) {
    //     result[styles['dateTag--month']] = true
    // } else if (now - timestamp <= 3 * 30 * 24 * 60 * 60 * 1000) {
    //     result[styles['dateTag--1-3month']] = true
    // } else if (now - timestamp <= 6 * 30 * 24 * 60 * 60 * 1000) {
    //     result[styles['dateTag--3-6months']] = true
    // } else if (now - timestamp <= 12 * 30 * 24 * 60 * 60 * 1000) {
    //     result[styles['dateTag--6-12months']] = true
    // } else {
    //     result[styles['dateTag--yearAndMore']] = true
    // }
    return result;
  }, [date]);

  return (
    <button
      ref={isLast ? newBtnRef : null}
      onClick={handleClick}
      className={classNames(className, styles.dateTag, modifications)}>
      {!date ? window.t('newUser') : getUILastDate(timestamp)}
    </button>
  );
}

export default DateTag;
