export const breakTagsAndInsertText = (key) => {
  let html = '';
  try {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const temp = document.createElement('div');
    const insertion = document.createDocumentFragment();

    const parent = getSelectionParentElement();

    if (parent.localName === 'font') {
      // html = `</font>${key}<font color=${parent.color}>`;
      html = `<font color="#4d4d4d" id="temp-font">${key}</font>`;
    }

    temp.innerHTML = html;

    while (temp.firstChild) {
      insertion.appendChild(temp.firstChild);
    }

    range.deleteContents();
    range.insertNode(insertion);
    selectText('temp-font');
    document.execCommand('removeFormat', false, 'foreColor');
  } catch (error) {
    try {
      document.selection.createRange().pasteHTML(html);
    } catch (error) {}
  }
};

export const getTextAndEffects = (parent) => {
  let text = '';
  let effects = {};
  const nodesArray = [...parent.childNodes];

  let totalLength = 0;
  nodesArray.forEach((el) => {
    const text = el.textContent || el.outerText;
    if (el.color) {
      effects[Object.keys(effects).length] = {
        type: 'color',
        value: el.color,
        textOffset: totalLength,
        textLength: text.length
      };
    }

    totalLength += text?.length || 0;
  });

  const textArray = nodesArray.map((el) => {
    const text = el.textContent || el.outerText;
    return text;
  });

  text = textArray.join('');
  return {
    text,
    effects: new Array(...[...Object.values(effects)])
  };
};

export const getFormattedHtml = (text) => {
  if (!text?.effects?.length) return text?.text ?? text;
  let result = '';
  let effectsToApply = [...text.effects];
  for (let i = 0; i < text.text.length; i++) {
    const char = text.text[i];
    if (!effectsToApply.length) {
      result += char;
    } else {
      const length = effectsToApply[0]?.textLength;
      const offset = effectsToApply[0]?.textOffset;
      const type = effectsToApply[0]?.type;
      const value = effectsToApply[0]?.value;

      if (i < offset) result += char;
      else if (i === offset) result += `<font ${type}="${value}">${char}`;
      else if (i > offset && i < offset + length) result += char;
      else if (i === offset + length) {
        result += `</font>`;
        effectsToApply.shift();
        const offset = effectsToApply[0]?.textOffset;
        const type = effectsToApply[0]?.type;
        const value = effectsToApply[0]?.value;
        if (i === offset) result += `<font ${type}="${value}">${char}`;
        else result += char;
      }

      if (i === offset + length) {
        if (offset + length === text.text.length) result += '</font>';
      }
    }
  }
  return result;
};

export function selectText(nodeId) {
  const node = document.getElementById(nodeId);

  if (!node) return node;

  if (document.body.createTextRange) {
    const range = document.body.createTextRange();
    range.moveToElementText(node);
    range.select();
  } else if (window.getSelection) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);
    selection.removeAllRanges();
    selection.addRange(range);
  } else {
    console.warn('Could not select text in node: Unsupported browser.');
  }

  return node;
}

export function getSelectionParentElement() {
  var parentEl = null,
    sel;
  if (window.getSelection) {
    sel = window.getSelection();
    if (sel.rangeCount) {
      parentEl = sel.getRangeAt(0).commonAncestorContainer;
      if (parentEl.nodeType != 1) {
        parentEl = parentEl.parentNode;
      }
    }
  } else if ((sel = document.selection) && sel.type != 'Control') {
    parentEl = sel.createRange().parentElement();
  }
  return parentEl;
}
