import AddGroupCardModal from '../AddGroupCardModal/AddGroupCardModal';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  Avatar,
  BlurWrapper,
  Button,
  Icon,
  Input,
  List,
  ListItem,
  ListItemText,
  Portal,
  Radio,
  SvgCheckbox,
  Text,
  Title
} from 'components';

import { useAsyncEffect } from 'hooks';
import useActions from 'hooks/useActions';

import {
  addViberPhone,
  deleteViberPhone,
  getViberGroups,
  toggleCheckboxState
} from 'api/dataLayer';

import { getImageByChannel } from 'helpers/image';
import { isValdidPhone } from 'helpers/phone';

const propTypes = {
  setDeleteCardData: PropTypes.func.isRequired,
  setIsDeleteCardActive: PropTypes.func.isRequired,
  setIsViberConnected: PropTypes.func.isRequired
};

const defaultProps = {
  setDeleteCardData: () => {},
  setIsDeleteCardActive: () => {},
  setIsViberConnected: () => {}
};

function ViberCollapseBody({ setDeleteCardData, setIsDeleteCardActive, setIsViberConnected }) {
  const { addSnack } = useActions();
  const contactChannels = ['WhatsApp', 'Viber', 'Telegram'];
  const [groups, setGroups] = useState([]);
  const [isModalActive, setIsModalActive] = useState(false);
  const [viberPhone, setViberPhone] = useState('');
  const [viberPhoneError, setViberPhoneError] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [contactChannel, setContactChannel] = useState(contactChannels[1]);

  useAsyncEffect(async () => {
    try {
      const res = await getViberGroups();
      const groups = res.body;

      if (!groups) return;

      setGroups(
        Object.values(groups).map((el) => ({
          ...el,
          groupName: el.groupId
        }))
      );
    } catch (ex) {
      console.error(ex);
    }
  }, []);

  useEffect(() => {
    setIsViberConnected(groups.length > 0);
  }, [setIsViberConnected, groups]);

  const connectNewGroup = () => {
    if (groups.length >= 1) {
      addSnack({
        message: window.t('canConnectOneNumber'),
        type: 'info'
      });
    } else setIsModalActive(true);
  };

  const checkboxHandler = async (groupId, key, isChecked) => {
    try {
      await toggleCheckboxState({
        groupId,
        key,
        channel: 'viber',
        isChecked: Number(isChecked)
      });
      setGroups((groups) =>
        groups.map((el) => (el.groupId === groupId ? { ...el, [key]: isChecked } : el))
      );
    } catch (ex) {
      console.error(ex);
    }
  };

  const deleteRequest = async ({ groupId, isComment, isMessage }) => {
    try {
      await deleteViberPhone({ groupId, isComment, isMessage });
      setGroups((groups) => groups.filter((el) => el.groupId !== groupId));
      setIsDeleteCardActive(false);
    } catch (ex) {
      console.error(ex);
    }
  };

  const deleteHandler = (groupId) => {
    const groupData = groups.find((el) => el.groupId === groupId);
    groupData.deleteRequest = deleteRequest;
    groupData.removeMessage = window.t('realyWantToDeletePhone');
    // для принудительного обновления стейта в DeleteCardModal
    groupData.random = Math.random();

    setDeleteCardData(groupData);
    setIsDeleteCardActive(true);
  };

  const validateInputs = () => {
    const isViberValid = isValdidPhone(viberPhone);
    const isContactValid = isValdidPhone(contactPhone);

    if (!isViberValid) {
      setViberPhoneError(window.t('invalidPhone'));
    }
    if (!isContactValid) {
      setPhoneError(window.t('invalidPhone'));
    }

    return isViberValid && isContactValid;
  };

  const connectHandler = async () => {
    if (!validateInputs()) return;

    if (groups.find((el) => el.groupName === viberPhone)) {
      setViberPhoneError(window.t('phoneNumberAlreadyAdded'));
      return;
    }

    const res = await addViberPhone({
      viberNum: viberPhone,
      contactNum: contactPhone,
      messenger: contactChannel.toLowerCase()
    });

    const newGroup = {
      ...res.body,
      groupName: res.body?.groupId
    };
    setIsModalActive(false);
    setContactPhone('');
    setViberPhone('');
    setGroups((groups) => [...groups, newGroup]);
  };

  return (
    <div style={{ padding: '10px 0' }}>
      <Portal nodeId="work-pane-modals">
        <BlurWrapper
          isOpened={isModalActive}
          clickHandler={() => setIsModalActive(false)}
          position="absolute"
          background="#5a5a5a2e"
          className="d-flex justify-center align-center">
          <AddGroupCardModal
            title={window.t('connectViberNumber')}
            closeHandler={() => setIsModalActive(false)}
            headerColor="#675da9">
            <Text size={16}>{window.t('viberNote')}</Text>
            <Input
              type="number"
              value={viberPhone}
              label={`${window.t('phoneNumber')} Viber:`}
              placeholder="+7 (965) 789 - 06 - 93"
              changeHandler={(evt) => {
                const value = evt.target.value;
                setViberPhone(value);
                const error = value.length === 0 ? window.t('requiredField') : '';
                setViberPhoneError(error);
              }}
              className="mb-4"
              error={viberPhoneError}
            />
            <Input
              type="number"
              value={contactPhone}
              label={`${window.t('yourPhone')}:`}
              placeholder="+7 (965) 789 - 06 - 93"
              changeHandler={(evt) => {
                const value = evt.target.value;
                setContactPhone(value);
                const error = value.length === 0 ? window.t('requiredField') : '';
                setPhoneError(error);
              }}
              className="mb-4"
              error={phoneError}
            />
            <div className="d-flex">
              {contactChannels.map((channel, i) => (
                <Radio
                  changeHandler={(evt) => {
                    setContactChannel(evt.target.value);
                  }}
                  className={i !== 0 && 'ml-4'}
                  key={channel}
                  isChecked={contactChannel === channel}
                  name="channel"
                  value={channel}
                  label={channel}
                />
              ))}
            </div>
            <Button
              className="ml-auto flex-shrink-0 align-self-center"
              style={{
                padding: '8px 20px'
              }}
              clickHandler={connectHandler}
              // isDisabled={isLoadingSubscribe}
            >
              {window.t('connect')}
            </Button>
          </AddGroupCardModal>
        </BlurWrapper>
      </Portal>
      <Title className="d-inline-block" weight="normal" style={{ marginLeft: 50 }}>
        {window.t('accounts')}
      </Title>
      <Text tag="span" color="#808080">
        {` ${groups.length}`}
      </Text>
      <List noStyle style={{ marginLeft: 50 }}>
        {groups.map(({ groupId, groupName, isComment, isMessage, isActive }) => (
          <ListItem key={groupId} noHover className="ml-0 mr-0 mt-2 mb-0 pa-0 flex-column">
            <div className="d-flex">
              <Avatar
                src={getImageByChannel('viber')}
                size={30}
                className="flex-shrink-0 mr-2 align-self-center"
              />
              <ListItemText primary={groupName} />
              <Button
                onlyIcon
                startIcon={<Icon size={14} color="#CDCDCD" fontName="far fa-times" />}
                variant="icon"
                hoverColor="transparent"
                style={{
                  opacity: 1
                }}
                clickHandler={() => deleteHandler(groupId)}
              />
              <div className="d-flex ml-auto">
                {isComment !== null && isComment !== undefined && (
                  <SvgCheckbox
                    isChecked={Boolean(+isComment)}
                    changeHandler={(isChecked) => checkboxHandler(groupId, 'isComment', isChecked)}
                    style={{
                      color: '#808080',
                      fontSize: 14,
                      marginLeft: 10
                    }}>
                    {window.t('comments')}
                  </SvgCheckbox>
                )}
                {isMessage !== null && isMessage !== undefined && (
                  <SvgCheckbox
                    isChecked={Boolean(+isMessage)}
                    changeHandler={(isChecked) => checkboxHandler(groupId, 'isMessage', isChecked)}
                    style={{
                      color: '#808080',
                      fontSize: 14,
                      marginLeft: 10
                    }}>
                    {window.t('messages')}
                  </SvgCheckbox>
                )}
              </div>
            </div>
            {!+isActive && (
              <div className="d-flex align-center" style={{ paddingLeft: 40, maxWidth: 630 }}>
                <Icon fontName="fad fa-exclamation-circle mr-3" color="#ea0000" />
                <Text color="#808080">
                  Ваша заявка на подключение номера Viber принята. Дождитесь пожалуйста получения
                  инструкций на указанный к заявке номер.
                </Text>
              </div>
            )}
          </ListItem>
        ))}
      </List>
      <Button
        clickHandler={connectNewGroup}
        color="#fcfcfc"
        textColor="#757575"
        variant="google"
        size="medium"
        className="ml-auto mr-auto mt-5 mb-5"
        startIcon={<Icon icon="viber" color="#675DA9" size={20} />}>
        {window.t('connectNewGroup')}
      </Button>
    </div>
  );
}

export default ViberCollapseBody;

ViberCollapseBody.propTypes = propTypes;
ViberCollapseBody.defaultProps = defaultProps;
