import React from 'react';

import { ControlsView } from 'components';

import { useRegistrationStartStopData } from './hooks/useRegistrationStartStopData';
import { useToolbarRegistrationControls } from './hooks/useToolbarRegistrationControls';

export function RegistrationToolbarControls({ settingsClickHandler, activity, id }) {
  const { startStopData } = useRegistrationStartStopData({ activity, id });

  const { controls } = useToolbarRegistrationControls({
    settingsClickHandler,
    startStopData
  });

  return <ControlsView>{controls}</ControlsView>;
}
