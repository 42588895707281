import { FormInitialValuesUpdater } from './FormInitialValuesUpdater';
import { fields } from './fields';
import classNames from 'classnames';
import { FieldArray, Form, Formik } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { useCallback, useEffect, useMemo, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { PictureLoaderContainer } from 'containers/PictureLoaderContainer/PictureLoaderContainer';

import { Button, TagsInput, TextField, Textarea } from 'components';
import { DateTimePickerField } from 'components/DateTimePickerField/DateTimePickerField';

import { useActions, useLocalStorage } from 'hooks';

import styles from './NewItemForm.module.scss';

const getTitles = () => ({
  news: window.t('createNews')
});

const components = {
  TextField,
  TagsInput,
  Textarea,
  PictureLoaderContainer,
  DateTimePickerField
};

export function NewItemForm({ type, isActive, closeForm, scrollBoxes, initialFormValues }) {
  const currentProfile = useSelector((state) => state.profile.currentProfile);

  const [locale] = useLocalStorage('locale');

  const [formValues, setFormValues] = useState(null);
  const [formErrors, setFormErrors] = useState(null);
  const [formTouched, setFormTouched] = useState(null);
  const { addSnack, addNewFeedItem } = useActions();
  const [isFullFormShown, setIsFullFormShown] = useState(false);

  useEffect(() => {
    if (!isActive) {
      setFormErrors({});
      setFormTouched({});
      setFormValues(
        !fields(currentProfile)[type]
          ? {}
          : Object.fromEntries(
              Object.values(fields(currentProfile)[type]).map(({ name, initialValue }) => [
                name,
                initialValue
              ])
            )
      );
    }
  }, [isActive, type]);

  const showWarning = useCallback(() => {
    addSnack({
      type: 'danger',
      message: window.t('caregoryAlreadyExist')
    });
  }, [addSnack]);

  const addValue = useCallback(
    (key, value, values, setFieldValue) => setFieldValue(key, [...(values[key] || []), value]),
    []
  );

  const deleteValue = useCallback(
    (key, value, values, setFieldValue) =>
      setFieldValue(
        key,
        (values[key] || []).filter((item) => item !== value)
      ),
    []
  );

  const initialValues = useMemo(
    () =>
      !fields(currentProfile)[type]
        ? {}
        : Object.fromEntries(
            Object.values(fields(currentProfile)[type]).map(({ name, initialValue }) => [
              name,
              cloneDeep(get(initialFormValues, name)) || initialValue
            ])
          ),
    [type, initialFormValues, currentProfile, locale]
  );

  const validationSchemas = () => ({
    news: Yup.object({
      rss_name: Yup.string().required(window.t('requiredField')),
      title: Yup.string().required(window.t('requiredField')),
      description: Yup.string(),
      category: Yup.array(),
      link: Yup.string().url(window.t('linkValidURL')),
      pubDate: Yup.string(),
      author: Yup.string(),
      enclosure: Yup.array()
    })
  });

  useEffect(() => {
    setFormErrors({});
    setFormTouched({});
    setFormValues(initialValues);
  }, [initialValues, locale]);

  if (!fields(currentProfile)[type]) return null;

  return (
    <AnimateHeight duration={600} height={!isActive ? 0 : 'auto'}>
      <div className={styles.newItem}>
        <h4 className={styles.newItem__title}>{getTitles()[type]}</h4>
        <Formik
          className={styles.newItem__form}
          validationSchema={validationSchemas()[type] || {}}
          initialValues={initialValues}
          onSubmit={(values, { setValues }) => {
            addNewFeedItem({ ...(initialFormValues || {}), ...values }, scrollBoxes);
            setValues(initialValues);
            setIsFullFormShown(false);
            closeForm();
          }}>
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
            setValues,
            setErrors,
            setTouched
          }) => {
            return (
              <FormInitialValuesUpdater
                initialValues={formValues}
                setValues={setValues}
                formErrors={formErrors}
                setErrors={setErrors}
                formTouched={formTouched}
                setTouched={setTouched}>
                <Form className={styles.newItem__form}>
                  {fields(currentProfile)[type].map((field) => {
                    const Component = components[field.component];

                    if (
                      field.component === 'PictureLoaderContainer' &&
                      Array.isArray(get(values, field.name))
                    ) {
                      return (
                        <FieldArray
                          key={field.name}
                          name={field.name}
                          render={({ push, remove }) => {
                            return (
                              <div
                                className={classNames(styles.newItem__attachments, {
                                  [styles.newItem__attachments_hidden]:
                                    !field.isAlwaysShown && !isFullFormShown
                                })}>
                                <h5 className={styles.newItem__label}>{window.t('attachments')}</h5>
                                <div className={styles.newItem__imagesContainer}>
                                  {[...get(values, field.name), null].map((item, index) => {
                                    return (
                                      <Component
                                        key={`${field.name}.${index}`}
                                        type={field.type}
                                        // multiple={true}
                                        isRequired={false}
                                        inputHeight="31px"
                                        inputFontSize="14px"
                                        borderStyle="dotted"
                                        id={`${field.name}.${index}`}
                                        name={`${field.name}.${index}`}
                                        label={window.t(field.name)}
                                        placeholder={window.t(field.placeholder)}
                                        className={classNames(
                                          'flex-grow',
                                          styles.newItem__field,
                                          styles.newItem__field_image,
                                          field.size && styles[`newItem__field_${field.size}`],
                                          {
                                            [styles.newItem__field_hidden]:
                                              !field.isAlwaysShown && !isFullFormShown,
                                            [styles.newItem__field_textarea]:
                                              field.component === 'Textarea'
                                          }
                                        )}
                                        classNameField={styles.newItem__fieldContainer}
                                        value={item?.url}
                                        blurHandler={handleBlur}
                                        error={
                                          touched[`${field.name}.${index}`] &&
                                          errors[`${field.name}.${index}`]
                                            ? errors[`${field.name}.${index}`]
                                            : null
                                        }
                                        withError={true}
                                        tags={item}
                                        add={(value) =>
                                          addValue(field.name, value, values, setFieldValue)
                                        }
                                        remove={(value) =>
                                          deleteValue(field.name, value, values, setFieldValue)
                                        }
                                        onRepeat={showWarning}
                                        picture={item?.url}
                                        changeHandler={(event) => {
                                          event.target.files[0] &&
                                            push({
                                              type: 'image',
                                              url: URL.createObjectURL(event.target.files[0])
                                            });
                                        }}
                                        deleteHandler={() => remove(index)}
                                        // isImageUploading={isImageUploading}
                                      />
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          }}
                        />
                      );
                    }

                    return (
                      <Component
                        key={field.name}
                        type={field.type}
                        isRequired={false}
                        inputHeight="31px"
                        inputFontSize="14px"
                        borderStyle="dotted"
                        id={field.name}
                        name={field.name}
                        label={window.t(field.name)}
                        placeholder={window.t(field.placeholder)}
                        className={classNames(
                          'flex-grow',
                          styles.newItem__field,
                          field.size && styles[`newItem__field_${field.size}`],
                          {
                            [styles.newItem__field_hidden]:
                              !field.isAlwaysShown && !isFullFormShown,
                            [styles.newItem__field_textarea]: field.component === 'Textarea',
                            [styles.newItem__field_logo]: field.name === 'logo'
                          }
                        )}
                        classNameField={styles.newItem__fieldContainer}
                        changeHandler={
                          field.name !== 'logo'
                            ? handleChange
                            : (event) =>
                                setFieldValue(field.name, {
                                  type: 'image',
                                  url: URL.createObjectURL(event.target.files[0])
                                })
                        }
                        deleteHandler={() => setFieldValue(field.name, {})}
                        value={values[field.name]}
                        blurHandler={handleBlur}
                        error={
                          touched[field.name] && errors[field.name] ? errors[field.name] : null
                        }
                        withError={true}
                        tags={values[field.name]}
                        add={(value) => addValue(field.name, value, values, setFieldValue)}
                        remove={(value) => deleteValue(field.name, value, values, setFieldValue)}
                        onRepeat={showWarning}
                        picture={values[field.name]?.url}
                        // changeHandler={(event) =>
                        //   pictureChangeHandler(event, block.id, key)
                        // }
                        // deleteHandler={() => pictureChangeHandler(null, block.id, key)}
                        // isImageUploading={isImageUploading}
                      />
                    );
                  })}
                  <div className={classNames(styles.newItem__row, styles.newItem__row_full)}>
                    <Button
                      clickHandler={() => setIsFullFormShown((state) => !state)}
                      type="button"
                      className={styles.newItem__button}>
                      {window.t(`show_${isFullFormShown ? 'less' : 'more'}`)}
                    </Button>
                    <Button type="submit" className={styles.newItem__button}>
                      {window.t('createNews')}
                    </Button>
                  </div>
                </Form>
              </FormInitialValuesUpdater>
            );
          }}
        </Formik>
      </div>
    </AnimateHeight>
  );
}
