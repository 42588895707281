import { useState } from 'react';

import { Button, Icon } from 'components';

export const useClearMenu = ({ withIcon } = {}) => {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [clearMenuPosition, setClearMenuPosition] = useState({
    left: 0,
    top: 0
  });

  const toggleClearMenu = (event) => {
    if (!isMenuActive) {
      const btnRect = event.target.getBoundingClientRect();
      setClearMenuPosition({
        left: `${btnRect.left}px`,
        top: `${btnRect.top + 35}px`
      });
    }
    setIsMenuActive(!isMenuActive);
  };

  const clearBtn = (
    <Button
      startIcon={
        <div className="d-flex align-center">
          {withIcon && <Icon size={15} color="rgba(0, 0, 0, 0.54)" fontName="fal fa-tv-alt" />}
          <Icon className={withIcon ? 'pl-2' : ''} size={20} fontName="fal fa-times" />
        </div>
      }
      onlyIcon
      variant="icon"
      color="#ff0033"
      clickHandler={toggleClearMenu}
      title={window.t('clearTitr')}
      style={{ padding: '6px 8px' }}
    />
  );

  return {
    clearBtn,
    toggleClearMenu,
    isMenuActive,
    clearMenuPosition
  };
};
