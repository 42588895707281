import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import styles from './ListItem.module.css';

const propTypes = {
  children: PropTypes.any,
  isActive: PropTypes.bool,
  noHover: PropTypes.bool,
  clickHandler: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object
};

const defaultProps = {
  isActive: false,
  noHover: false,
  clickHandler: () => {},
  className: '',
  style: {}
};

function ListItem({
  isActive,
  test = 'test123',
  noHover,
  children,
  clickHandler,
  className,
  style
}) {
  const classes = classNames(styles['list-item'], className, {
    [styles['selected']]: isActive,
    [styles['list-item--no-hover']]: noHover
  });

  const [isHover, setIsHover] = useState(false);

  const cloneProps = {
    isActive,
    ...(!noHover && { isHover })
  };

  const childrenWithProps = React.Children.map(children, (child, index) => {
    // const props = (child.type.name === 'ListItemText') ? cloneProps : {};
    return child ? React.cloneElement(child, cloneProps) : null;
  });

  return (
    <li
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={clickHandler}
      className={classes}
      style={style}>
      {/* {props.children} */}
      {/* {React.cloneElement(props.children, { isHover, isActive: props.isActive })} */}
      {childrenWithProps}
    </li>
  );
}

export default ListItem;

ListItem.propTypes = propTypes;
ListItem.defaultProps = defaultProps;
