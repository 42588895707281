import { UploadToExcel } from './UploadToExcel';
import { memo } from 'react';

import { Title } from 'components';

import { INTERACTIVE_ACTIVITIES } from 'store/constants';

import styles from './RegistrationState.module.css';

export const RegistrationState = memo(
  ({
    count,
    activity,
    registration_url,
    participants,
    dateRegistration,
    interactiveName,
    checkboxes
  }) => {
    return (
      <div className={styles.states}>
        <div className={styles.container}>
          {registration_url && (
            <div>
              <Title color="#808080" className="mb-2">
                {window.t('linkToForm')}
              </Title>
              <Title color="#0088ee" className="mb-2">
                <a href={registration_url} target="_blanck" className={styles.link}>
                  {registration_url}
                </a>
              </Title>
            </div>
          )}
          <div>
            <Title color="#808080">{window.t('totalRegisteredParticipants')}</Title>
            <Title color="#0088ee" size={16}>
              {count}
            </Title>
          </div>
        </div>
        <UploadToExcel
          checkboxes={checkboxes}
          participants={participants}
          dateRegistration={dateRegistration}
          interactiveName={interactiveName}
        />
      </div>
    );
  }
);
