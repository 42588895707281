import { InteractiveSettingsView } from './InteractiveSettingsView';
import React from 'react';

import { InteractiveSettingsFormContainer } from 'containers/InteractiveSettingsFormContainer/InteractiveSettingsFormContainer';

import { useInteractiveSettings } from './hooks/useInteractiveSettings';

export function InteractiveSettingsContainer({
  withGuide = true,
  type,
  interactiveId,
  onCloseHandler,
  children,
  startGuide,
  interactiveInfoRef,
  okButtonRef,
  setStepIndex,
  extended,
  noCopy,
  activity,
  handlePublish
}) {
  const { copySession } = useInteractiveSettings({ type, interactiveId });
  return (
    <InteractiveSettingsView
      handlePublish={handlePublish}
      activity={activity}
      noCopy={noCopy}
      withGuide={withGuide}
      copySession={copySession}
      onCloseHandler={onCloseHandler}
      startGuide={startGuide}
      okButtonRef={okButtonRef}
      setStepIndex={setStepIndex}
      interactiveInfoRef={interactiveInfoRef}>
      <InteractiveSettingsFormContainer
        extended={extended}
        interactiveInfoRef={interactiveInfoRef}
      />
      {children}
    </InteractiveSettingsView>
  );
}
