import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

const propTypes = {
  children: PropTypes.node,
  accept: PropTypes.string,
  changeHandler: PropTypes.func,
  isDisabled: PropTypes.bool,
  setInputRef: PropTypes.func,
  style: PropTypes.object
};

const defaultProps = {
  accept: '*',
  changeHandler: () => {},
  isDisabled: false,
  setInputRef: () => {},
  style: {}
};

function InputFile({
  children,
  accept,
  changeHandler,
  isDisabled,
  setInputRef,
  style,
  multiple = false,
  id,
  name
}) {
  const inputRef = useRef(null);

  useEffect(() => {
    setInputRef(inputRef);
  }, []);

  return (
    <label style={style}>
      <input
        id={id || 'file-upload'}
        name={name || 'file'}
        ref={inputRef}
        onChange={changeHandler}
        style={{ display: 'none' }}
        type="file"
        accept={accept}
        disabled={isDisabled}
        multiple={multiple}
      />
      {children}
    </label>
  );
}

export default InputFile;

InputFile.propTypes = propTypes;
InputFile.defaultProps = defaultProps;
