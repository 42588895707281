import { Forecast } from './WeatherCardComponents/Forecast/Forecast';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import MessageActions from 'components/FeedItem/MessageActions/MessageActions';

import { useActions } from 'hooks';

import styles from './WeatherCard.module.css';

export const getWeatherIconUrl = (icon) =>
  `https://yastatic.net/weather/i/icons/funky/dark/${icon}.svg`;

function WeatherCard({
  isSelected,
  isDraggable,
  data,
  style,
  selectMessage,
  toggleFavorite,
  isBroadcastMsg,
  activeBroadcastData,
  messageActionsConfig,
  copyItemHandler,
  tab
}) {
  const { setDraggingFeedItemId } = useActions();

  const cardRef = useRef(null);
  const [isMsgHover, setIsMsgHover] = useState(false);

  const { id, isHidden, status, forecasts } = data;

  const {
    date,
    sunrise,
    sunset,
    moon_code,
    parts,

    temp_min,
    temp_max,
    temp_avg,
    feels_like,
    icon,
    condition,
    daytime,
    polar,
    wind_speed,
    wind_gust,
    wind_dir,
    pressure_mm,
    pressure_pa,
    humidity,
    prec_mm,
    prec_period,
    prec_type,
    prec_strength,
    cloudness
  } = forecasts[0];
  const { night, morning, day, evening, day_short } = parts;

  const dragStart = () => {
    setDraggingFeedItemId(id);
  };

  const dragEnd = () => {
    setDraggingFeedItemId(null);
  };

  const isMinimized = status && status.includes('minimized');
  const classes = classNames(styles.container, {
    [styles.selected]: isSelected,
    hidden: isHidden
  });

  return (
    <AnimateHeight duration={600} height={!isHidden && isMinimized ? 62 : 'auto'}>
      <div
        ref={cardRef}
        draggable={isDraggable}
        style={style}
        onMouseOver={() => {
          setIsMsgHover(true);
        }}
        onMouseLeave={() => {
          setIsMsgHover(false);
        }}
        onDragStart={dragStart}
        onDragEnd={dragEnd}
        className={classes}
        onClick={() => {
          selectMessage?.(id);
        }}
        data-message="true"
        data-id={id}>
        <table className={styles.weather}>
          <thead>
            <th colSpan={4} className={styles.weather__th}>
              <div className={styles.weather__date}>
                <div className={styles.weather__day}>{new Date(date).getDate()}</div>
                <div className={styles.weather__month}>
                  {window.t(`weather_month_${new Date(date).getMonth() + 1}`)}
                </div>
              </div>
            </th>
            {isMinimized && <Forecast forecast={day_short} isTableHead />}
            {!isMinimized && (
              <>
                <th
                  className={styles.weather__th}
                  dangerouslySetInnerHTML={{
                    __html: window.t('weather_pressure')
                  }}
                />
                <th
                  className={styles.weather__th}
                  dangerouslySetInnerHTML={{
                    __html: window.t('weather_humidity')
                  }}
                />
                <th
                  className={styles.weather__th}
                  dangerouslySetInnerHTML={{
                    __html: window.t('weather_windSpeed')
                  }}
                />
                <th
                  className={styles.weather__th}
                  dangerouslySetInnerHTML={{
                    __html: window.t('weather_feelsLike')
                  }}
                />
              </>
            )}
            <th className={styles.weather__actions}>
              <MessageActions
                type={tab}
                isExportTurnedOn={false}
                message={data}
                isMinimized={Boolean(+isMinimized)}
                isMsgHover={isMsgHover || isBroadcastMsg}
                messageActionsConfig={messageActionsConfig}
                // settingsMenu={settingsMenu}
                toggleFavorite={toggleFavorite}
                handleCopy={() => copyItemHandler(data)}
              />
              {/* {activeBroadcastData && (
                  <BroadcastActions
                    messageData={data}
                    activeBroadcastData={activeBroadcastData}
                  />
                )} */}
            </th>
          </thead>
          <tbody>
            {Object.entries({ morning, day, evening, night }).map(([key, value]) => (
              <Forecast forecast={value} time={key} />
            ))}
          </tbody>
        </table>
      </div>
    </AnimateHeight>
  );
}

export default WeatherCard;
