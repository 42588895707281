import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';

import { INTERACTIVES } from 'store/constants';

import styles from './CounterView.module.css';

export function CounterView({ type, count, percent, label, addVote }) {
  return (
    <div className={styles['count']}>
      <span>
        <span className={styles['count-title']}>{label}: </span>
        <span> {count}</span>
      </span>

      <div className="d-flex justify-end">
        <span className={styles['percent']}>{percent}</span>
        {type === INTERACTIVES.vote && (
          <Button
            startIcon={<Icon size={14} fontName="fal fa-plus" />}
            onlyIcon
            color="#6f6f6fbf"
            style={{
              alignSelf: 'center',
              marginLeft: 8,
              padding: '4px 6px'
            }}
            clickHandler={addVote}
          />
        )}
      </div>
    </div>
  );
}
