import React from 'react';

import { Avatar, Icon, Skeleton } from 'components';

import { getExternalLink } from 'helpers/externalLinks';
import { getPhoneNumber } from 'helpers/getPhoneNumber';
import { getImageByChannel } from 'helpers/image';

import styles from './ChatHeader.module.css';

function ChatHeader({ author, userInfo, routeInfo, children }) {
  const { region, image } = userInfo || {};

  const { channel: userChannel, senderNumber } = routeInfo;

  const contactLink = getExternalLink(author, senderNumber, userChannel, userInfo.userName);

  return (
    <div className={styles['main-chat-header']}>
      <div className={styles['main-chat-header_user-data']}>
        <Avatar size={50} src={getImageByChannel(userChannel, image)} />
        <div className={styles['main-chat-header_user-data_info']}>
          <div className={styles['main-chat-header_user-data_info-name']}>
            <span className={styles['name']}>
              {author || <Skeleton width={185} height={23} borderRadius={12} />}
            </span>
            <span className={styles['location']}>
              {region ? (
                <>
                  <Icon fontName="fa fa-map-marker-alt" /> {region}
                </>
              ) : (
                window.t('noLocationInfo')
              )}
            </span>
          </div>
          <a
            className={styles['main-chat-header_user-data_info-contacts']}
            href={contactLink.href}
            target="_blank"
            rel="noreferrer">
            {userChannel?.includes('telegram')
              ? userInfo.userName
              : ['vbr', 'viber', 'whatsapp'].some((channel) => userChannel?.includes(channel))
              ? getPhoneNumber(senderNumber)
              : senderNumber}
          </a>
        </div>
      </div>
      {children}
    </div>
  );
}

export default ChatHeader;
