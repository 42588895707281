import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { SvgCheckbox } from 'components';

import styles from './MessageViewSettings.module.css';

function MessageViewSettings({ updateProfileSettings, setAvatarForm }) {
  const settings = useSelector((state) => state.settings);
  const { messageActionsConfig, messageViewConfig, avatarForm } = settings;

  // при смене домена, дефолтное значение
  useEffect(() => {
    if (!avatarForm) {
      updateProfileSettings({
        ...settings,
        avatarForm: 'circle'
      });
    }
  }, []);

  const { idMark, dateTime, labels, region, attachments } = messageViewConfig;
  const { copy, exportToBroadcast, minimize, favorite } = messageActionsConfig;

  const checkboxes = [
    {
      id: 'idMark',
      valueKey: 'messageId',
      isChecked: idMark,
      changeHandler: () => {}
    },
    {
      id: 'dateTime',
      valueKey: 'messageDateTime',
      isChecked: dateTime,
      changeHandler: () => {}
    },
    {
      id: 'minimize',
      valueKey: 'messageHiding',
      isChecked: minimize,
      changeHandler: () => {}
    },
    {
      id: 'labels',
      valueKey: 'messageLabels',
      isChecked: labels,
      changeHandler: () => {}
    },
    {
      id: 'copy',
      valueKey: 'copyMessage',
      isChecked: copy,
      changeHandler: () => {}
    },
    {
      id: 'favorite',
      valueKey: 'addToFavourites',
      isChecked: favorite,
      changeHandler: () => {}
    },
    {
      id: 'region',
      valueKey: 'cityIfKnown',
      isChecked: region,
      changeHandler: () => {}
    },
    {
      id: 'exportToBroadcast',
      valueKey: 'sendMessageToInteractive',
      isChecked: exportToBroadcast,
      changeHandler: () => {}
    },
    {
      id: 'attachments',
      valueKey: 'attachments',
      isChecked: attachments,
      changeHandler: () => {}
    }
  ];
  const style = {
    fontWeight: 700,
    borderBottom: '1px dashed #d6d6d6',
    paddingBottom: 5,
    marginTop: 10
  };

  const avatarFormCheck = [
    {
      id: 'circleAvatar',
      valueKey: 'circle',
      isChecked: avatarForm ? avatarForm === 'circle' : true,
      changeHandler: () => {
        setAvatarForm('circle');
      }
    },
    {
      id: 'squareAvatar',
      valueKey: 'square',
      isChecked: avatarForm ? avatarForm === 'square' : false,
      changeHandler: () => {
        setAvatarForm('square');
      }
    }
  ];

  return (
    <div className={styles['other-sett']}>
      <h3 className={styles['mvs__title']}>{window.t('messageAppearance')}</h3>
      <div className={styles['avatarForm']}>
        <span> {window.t('avatarForm')}:</span>
        <div className={styles['avatarForm__checkboxes']}>
          {avatarFormCheck.map(({ id, valueKey, isChecked, changeHandler }) => (
            <SvgCheckbox
              isChecked={isChecked}
              changeHandler={changeHandler}
              svgMarginLeft="auto"
              key={id}>
              {window.t(valueKey)}
            </SvgCheckbox>
          ))}
        </div>
      </div>
      <div style={{ display: 'none' }}>
        <p>{window.t('messageInFeed')}</p>
        {/* class="msg-view-box" */}
        <div>
          {checkboxes.map(({ id, valueKey, isChecked, changeHandler }) => (
            <SvgCheckbox
              isChecked={isChecked}
              changeHandler={changeHandler}
              svgMarginLeft="auto"
              style={style}
              key={id}>
              {window.t(valueKey)}
            </SvgCheckbox>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MessageViewSettings;

MessageViewSettings.propTypes = {
  messageViewConfig: PropTypes.object,
  messageActionsConfig: PropTypes.object,
  feedbackActions: PropTypes.object
};
