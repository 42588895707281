import { PinnedAuthor } from './PinnedAuthor';
import { useSelector } from 'react-redux';

import styles from './PinnedAuthors.module.css';

export function PinnedAuthors() {
  const pinnedAuthors = useSelector((state) => state.pinnedAuthors.authors);

  if (!pinnedAuthors.length) return null;

  return (
    <div className={styles.container}>
      {pinnedAuthors.map((author, i) => (
        <PinnedAuthor author={author} messages={author.messages || []} key={i} />
      ))}
    </div>
  );
}
