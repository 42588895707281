import {
  ADD_PROFILE,
  EDIT_CURRENT_PROFILE,
  EDIT_PROFILE,
  REMOVE_PROFILE,
  SET_ARE_PROFILES_LOADING,
  SET_CURRENT_PROFILE,
  SET_CURRENT_PROFILE_CONFIG,
  SET_IS_NEW_PROFILE_CREATING,
  SET_PROFILES,
  TOGGLE_PROFILE_EDIT
} from '../actions/actionTypes';

const initialState = {
  areProfilesLoading: true,
  isNewProfileCreating: false,
  profiles: [],
  currentProfile: null,
  currentProfileConfig: null
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROFILES:
      return {
        ...state,
        profiles: action.payload
      };
    case SET_CURRENT_PROFILE:
      return {
        ...state,
        currentProfile: action.payload
      };
    case SET_CURRENT_PROFILE_CONFIG:
      return {
        ...state,
        currentProfileConfig: action.payload
      };
    case TOGGLE_PROFILE_EDIT:
      return {
        ...state,
        profiles: state.profiles?.map((profile) =>
          profile.id !== action.payload
            ? profile
            : {
                ...profile,
                isOnEdit: !profile.isOnEdit
              }
        )
      };
    case SET_IS_NEW_PROFILE_CREATING:
      return {
        ...state,
        isNewProfileCreating: action.payload
      };
    case SET_ARE_PROFILES_LOADING:
      return {
        ...state,
        areProfilesLoading: action.payload
      };
    case ADD_PROFILE:
      return {
        ...state,
        profiles: [...state.profiles, action.payload]
      };
    case REMOVE_PROFILE:
      const filteredProfiles = state.profiles.filter((profile) => profile.id !== action.payload);
      return {
        ...state,
        profiles: filteredProfiles
      };
    case EDIT_PROFILE:
      return {
        ...state,
        profiles: state.profiles?.map((profile) =>
          profile.id !== action.payload.id
            ? profile
            : {
                ...profile,
                ...action.payload
              }
        )
      };
    case EDIT_CURRENT_PROFILE:
      return {
        ...state,
        currentProfile: {
          ...state.currentProfile,
          ...action.payload
        }
      };
    default:
      return state;
  }
}
