import { Avatar, Button, Icon, ListItem, ListItemText, SvgCheckbox } from 'components';

import { getImageByChannel } from 'helpers/image';

import styles from '../../SocialSettings.module.css';

export function RSSListItem({ rssInfo, remove, subscribe, unsubscribe }) {
  return (
    <ListItem noHover className="ml-0 mr-0 mt-2 mb-0 pa-0">
      <a
        className={`d-flex ${styles['group-name']}`}
        style={{ color: 'inherit' }}
        href={rssInfo.rssLink}
        target="_blank"
        rel="noreferrer">
        <Avatar
          src={rssInfo.rssImage || getImageByChannel('rss')}
          size={30}
          className="flex-shrink-0 mr-2 align-self-center"
        />
        <ListItemText primary={rssInfo.rssName} />
      </a>
      <Button
        onlyIcon
        startIcon={<Icon size={14} color="#CDCDCD" fontName="far fa-times" />}
        variant="icon"
        hoverColor="transparent"
        style={{
          opacity: 1
        }}
        clickHandler={() => remove(rssInfo)}
      />
      <div className="d-flex ml-auto">
        <SvgCheckbox
          isChecked={rssInfo.status === 'sub'}
          changeHandler={() => {
            if (rssInfo.status === 'sub') {
              unsubscribe(rssInfo);
            } else {
              subscribe(rssInfo.rssUrl);
            }
          }}
          style={{
            color: '#808080',
            fontSize: 14,
            marginLeft: 10
          }}>
          {window.t('subscription')}
        </SvgCheckbox>
      </div>
    </ListItem>
  );
}
