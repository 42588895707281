import { BroadcastSettingsMenuView } from './BroadcastSettingsMenuView';
import PropTypes from 'prop-types';
import React from 'react';

import { useBroadcastSettingsMenu } from './hooks/useBroadcastSettingsMenu';

const propTypes = {
  messageId: PropTypes.string.isRequired
};

export const BroadcastSettingsMenuContainer = React.memo(({ messageId }) => {
  const { restoreHandler, removeHandler } = useBroadcastSettingsMenu({
    messageId
  });

  return (
    <BroadcastSettingsMenuView restoreHandler={restoreHandler} removeHandler={removeHandler} />
  );
});

BroadcastSettingsMenuContainer.propTypes = propTypes;
