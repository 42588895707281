import {
  ADD_CHAT_MESSAGES,
  FETCH_CHAT_MESSAGES_SUCCESS,
  HAS_MORE_NEXT_CHAT,
  HAS_MORE_PREVIOUS_CHAT,
  SET_AVAILABLE_CHAT_CHANNEL,
  SET_CHAT_MESSAGES,
  SET_CHAT_SCROLLBOX_REF,
  SET_HAS_MORE_CHAT_MESSAGES,
  SET_IS_SENDING_MESSAGE,
  SET_SELECTED_CHAT_CHANNEL
} from '../actions/actionTypes';

const initialState = {
  scrollBoxRef: null,
  messages: [],
  // change to true to activate load on scroll
  isSendingMessage: false,
  hasMoreNext: false,
  hasMorePrevious: false,
  availableChannels: [
    {
      channel: 'whatsapp',
      isActive: true,
      isSelected: true
    },
    {
      channel: 'viber',
      isActive: false,
      isSelected: false
    },
    {
      channel: 'telegram',
      isActive: true,
      isSelected: false
    }
  ]
};

export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CHAT_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.messages
      };
    case HAS_MORE_NEXT_CHAT:
      return {
        ...state,
        hasMoreNext: action.flag
      };
    case HAS_MORE_PREVIOUS_CHAT:
      return {
        ...state,
        hasMorePrevious: action.flag
      };
    case SET_CHAT_MESSAGES:
      return {
        ...state,
        messages: action.messages
      };
    case ADD_CHAT_MESSAGES:
      if (
        state.messages.some((msg) => msg.ID === action.payload.messageId)
        //   && !state.hasMoreNext
      ) {
        return {
          ...state,
          messages: [...state.messages, ...action.payload.messages]
        };
      } else {
        return state;
      }
    case SET_HAS_MORE_CHAT_MESSAGES:
      return {
        ...state,
        hasMoreMessages: action.hasMoreMessages
      };
    case SET_AVAILABLE_CHAT_CHANNEL:
      return {
        ...state,
        availableChannels: state.availableChannels.map((el) =>
          el.channel === action.channel
            ? {
                ...el,
                isActive: action.isActive
              }
            : el
        )
      };
    case SET_SELECTED_CHAT_CHANNEL:
      return {
        ...state,
        availableChannels: state.availableChannels.map((el) =>
          el.channel === action.channel
            ? {
                ...el,
                isSelected: action.isSelected
              }
            : el
        )
      };
    case SET_CHAT_SCROLLBOX_REF:
      return {
        ...state,
        scrollBoxRef: action.ref
      };
    case SET_IS_SENDING_MESSAGE:
      return {
        ...state,
        isSendingMessage: action.payload
      };
    default:
      return state;
  }
}
