import React from 'react';

import styles from './Tag.module.css';

function Tag({ name, remove, edit }) {
  return (
    <div
      onDoubleClick={(event) => {
        event.stopPropagation();
        edit(event);
      }}
      className={styles['tag']}>
      {/* <span className={styles['tag-name']}></span> */}
      {name}
      {remove && (
        <div
          onClick={(event) => {
            event.stopPropagation();
            remove();
          }}
          className={styles['delete-tag']}>
          <i className="fal fa-times" />
        </div>
      )}
    </div>
  );
}

export default Tag;
