import React from 'react';

import styles from './ChatMessageLoader.module.css';

function ChatMessageLoader({ children }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        {children}
        <span className={styles.loading}>...</span>
      </div>
    </div>
  );
}

export default ChatMessageLoader;
