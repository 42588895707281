import { changeSessionInfo } from './isettings';
import { addSnack } from './snackBox';
import { parse } from 'date-fns';

import { updateInfoField } from 'store/actions/isettings';
import { setSessionActivity } from 'store/actions/sessions';
import { INTERACTIVE_ACTIVITIES } from 'store/constants';
import { registrationActions } from 'store/slices/registrationSlise';

import {
  deleteRegistrationCard,
  deleteRegistrationImg,
  editAuthorCard,
  editRegistration,
  getRegistrationCards,
  getRegistrationOneCard,
  startRegistration,
  stopRegistration
} from 'api/dataLayer';

export function updateRegistration_json() {
  return async (dispatch, getState) => {
    let { checkboxes } = getState().registration;
    const { id, registration_json } = getState().isettings.info;

    try {
      await editRegistration({
        id,
        checkboxes
      });
      dispatch(updateInfoField('registration_json', { ...registration_json, checkboxes }));
      dispatch(
        addSnack({
          type: 'success',
          message: window.t('settingsHaveBeenSaved')
        })
      );
    } catch (ex) {
      console.error(ex);
    }
  };
}

export function updateStopRegistration(id) {
  return async (dispatch, getState) => {
    try {
      await stopRegistration(id);
      dispatch(updateInfoField('activity', INTERACTIVE_ACTIVITIES.finished));
      dispatch(setSessionActivity(id, INTERACTIVE_ACTIVITIES.finished));
    } catch (ex) {
      console.error(ex);
    }
  };
}

export function updateStartRegistration(id) {
  return async (dispatch, getState) => {
    try {
      const res = await startRegistration(id);
      dispatch(updateInfoField('registration_url', res.body));
      dispatch(updateInfoField('activity', INTERACTIVE_ACTIVITIES.active));
      dispatch(setSessionActivity(id, INTERACTIVE_ACTIVITIES.active));
    } catch (ex) {
      console.error(ex);
    }
  };
}

export function updateParticipants(id) {
  return async (dispatch, getState) => {
    try {
      const res = await getRegistrationCards(id);
      dispatch(
        registrationActions.setParticipants(
          res.body.map((el) => {
            return { ...el, isHidden: false };
          })
        )
      );
    } catch (ex) {
      console.error(ex);
    }
  };
}

export function deleteParticipant(id, registration_id) {
  return async (dispatch, getState) => {
    try {
      await deleteRegistrationCard(id, registration_id);
      dispatch(registrationActions.deleteParticipant({ id }));
      dispatch(
        addSnack({
          type: 'success',
          message: window.t('eventRemovingParticipant')
        })
      );
    } catch (ex) {
      console.error(ex);
    }
  };
}

export function addParticipantCard(id) {
  return async (dispatch, getState) => {
    try {
      const res = await getRegistrationOneCard(id);
      dispatch(registrationActions.addParticipant(res.body));
    } catch (ex) {
      console.error(ex);
    }
  };
}

export function editAuthorData(card_id, registration_id, author_data) {
  return async (dispatch, getState) => {
    try {
      await editAuthorCard(card_id, registration_id, author_data);
      dispatch(
        addSnack({
          type: 'success',
          message: window.t('sccChangesSaved')
        })
      );
    } catch (ex) {
      console.error(ex);
    }
  };
}
export function updateStartRegistr(id, link) {
  return async (dispatch, getState) => {
    const { info } = getState().isettings;

    if (info.id === id && info.type === 'registration') {
      dispatch(updateInfoField('activity', INTERACTIVE_ACTIVITIES.active));
      dispatch(updateInfoField('registration_url', link));
    }
    // // остановка другой открытой регистрации в другом окне
    // if (
    //   info.id !== id &&
    //   info.type === 'registration' &&
    //   info.activity === INTERACTIVE_ACTIVITIES.active
    // ) {
    //   await stopRegistration(info.id);
    //   dispatch(updateInfoField('activity', INTERACTIVE_ACTIVITIES.finished));
    //   dispatch(setSessionActivity(info.id, INTERACTIVE_ACTIVITIES.finished));
    // }
    dispatch(setSessionActivity(id, INTERACTIVE_ACTIVITIES.active));
  };
}
export function updateStopRegistr(id) {
  return async (dispatch, getState) => {
    const { info } = getState().isettings;

    if (info.id === id && info.type === 'registration') {
      dispatch(updateInfoField('activity', INTERACTIVE_ACTIVITIES.finished));
    }
    dispatch(setSessionActivity(id, INTERACTIVE_ACTIVITIES.finished));
  };
}

export function deleteRegistrationImage(id) {
  return async (dispatch, getState) => {
    const { registration_json } = getState().isettings.info;

    try {
      await deleteRegistrationImg(id);
      dispatch(updateInfoField('registration_json', { ...registration_json, event_image: '' }));
      dispatch(changeSessionInfo('event_image', ''));
      dispatch(
        addSnack({
          type: 'success',
          message: window.t('Изображение удалено')
        })
      );
    } catch (ex) {
      console.error(ex);
    }
  };
}

export function handlePublishandSave() {
  return async (dispatch, getState) => {
    const { info } = getState().isettings;
    try {
      await dispatch(updateRegistration_json());
      await dispatch(updateStopRegistration(info?.id));
      await dispatch(updateStartRegistration(info?.id));
    } catch (ex) {
      console.error(ex);
    }
  };
}
// теги
export function addTags(id) {
  return async (dispatch, getState) => {
    const { info } = getState().isettings;
    // все данные о тегах регистрации в info
    const idTagsIsChecked = JSON.parse(info.advanced_settings)?.tags || [];
    const allTags = info.tags || [];
    const tagIsCheckedChange = allTags.find((el) => el.id === id);
    if (!tagIsCheckedChange) {
      return;
    }
    const newAllTags = [
      ...allTags.filter((el) => el.id !== id),
      { ...tagIsCheckedChange, is_checked: true }
    ];

    if (idTagsIsChecked.includes(id)) {
      dispatch(removeTags(id));
    } else
      try {
        await editRegistration({
          id: info?.id,
          advanced_settings: { tags: [...idTagsIsChecked, id] }
        });
        dispatch(
          updateInfoField('advanced_settings', JSON.stringify({ tags: [...idTagsIsChecked, id] }))
        );
        dispatch(updateInfoField('tags', newAllTags));
        dispatch(
          addSnack({
            type: 'success',
            message: window.t('tagAdded')
          })
        );
      } catch (ex) {
        console.error(ex);
      }
  };
}

export function removeTags(id) {
  return async (dispatch, getState) => {
    const { info } = getState().isettings;
    // все данные о тегах регистрации в info
    const idTagsIsChecked = JSON.parse(info.advanced_settings)?.tags || [];
    const allTags = info.tags || [];
    const tagIsCheckedChange = allTags.find((el) => el.id === id);
    if (!tagIsCheckedChange) {
      return;
    }
    const newAllTags = [
      ...allTags.filter((el) => el.id !== id),
      { ...tagIsCheckedChange, is_checked: false }
    ];
    try {
      await editRegistration({
        id: info?.id,
        advanced_settings: { tags: idTagsIsChecked.filter((t) => t !== id) }
      });
      dispatch(
        updateInfoField(
          'advanced_settings',
          JSON.stringify({ tags: idTagsIsChecked.filter((t) => t !== id) })
        )
      );
      dispatch(updateInfoField('tags', newAllTags));
      dispatch(
        addSnack({
          type: 'success',
          message: window.t('tagRemoved')
        })
      );
    } catch (ex) {
      console.error(ex);
    }
  };
}
