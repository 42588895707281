import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useActions from 'hooks/useActions';
import useInterval from 'hooks/useInterval';

export const useVote = ({
  interactiveId,
  match,
  location,
  isSettingsOpened,
  setIsSettingsOpened
}) => {
  const { fetchVoteVariants, resetVoteStore, updateVoteVariantsStats } = useActions();

  const { isVoteActive } = useSelector((state) => state.vote);
  const { isInteractiveExist, info } = useSelector((state) => state.isettings);

  const {
    visibility,
    interactiveName,
    broadcastId,
    broadcastActivity,
    result,
    settings,
    state,
    activity
  } = info;

  useEffect(() => {
    fetchVoteVariants(interactiveId);
    const detail = location?.state?.detail;
    if (detail) {
      const { isCreated } = detail;
      setIsSettingsOpened(isCreated);
    }
    return () => {
      resetVoteStore();
    };
  }, [interactiveId, fetchVoteVariants, resetVoteStore]);

  useInterval(
    () => {
      updateVoteVariantsStats(match.params.id);
    },
    isVoteActive ? 5000 : null
  );

  const toggleSettings = () => {
    setIsSettingsOpened(!isSettingsOpened);
  };

  return {
    voteInfo: info,
    interactiveName,
    isInteractiveExist,
    isVoteActive,
    result,
    broadcastId,
    broadcastActivity,
    toggleSettings,
    visibility,
    settings,
    state,
    activity
  };
};
