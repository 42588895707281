import useActions from 'hooks/useActions';

import { INTERACTIVE_ACTIVITIES } from 'store/constants';

export const useBroadcastControls = ({
  msgCreatorClickHandler,
  activity,
  broadcastId,
  setStepIndex
}) => {
  const { startBroadcast, stopBroadcast, changeVisibility } = useActions();

  return {
    startStopData: {
      startStopIcon: activity === INTERACTIVE_ACTIVITIES.active ? 'far fa-stop' : 'far fa-play',
      startStopTitle:
        activity === INTERACTIVE_ACTIVITIES.active ? 'stopBroadcast' : 'startBroadcast',
      startStopColor: activity === INTERACTIVE_ACTIVITIES.active ? '#ff4d29' : '#00bb00',
      startStopClickHandler:
        activity === INTERACTIVE_ACTIVITIES.active
          ? () => {
              setStepIndex((v) => ++v);
              stopBroadcast(broadcastId);
            }
          : () => {
              setStepIndex((v) => ++v);
              startBroadcast(broadcastId);
            }
    },
    msgCreatorClickHandler,
    changeVisibility
  };
};
