import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, Icon, Input } from 'components';

import { useActions } from 'hooks';

import styles from './EmailSettings.module.css';

const emailElmtRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

function EmailSettings() {
  const emails = useSelector((store) => store.settings?.exportConfig.email);
  const { addExportConfig, deleteExportConfig, updateExportConfig } = useActions();

  if (!emails) {
    return null;
  }
  return (
    <div className={styles['input-groups__wrapper']}>
      {emails?.length > 0 &&
        emails.map((item, i) => (
          <EmailInputGroup
            key={i + item.name + item.email}
            value={item}
            index={i}
            deleteEmailConfig={deleteExportConfig}
            updateEmailConfig={updateExportConfig}
          />
        ))}
      <Button
        clickHandler={() => addExportConfig('email', { name: '', email: '' })}
        isDisabled={
          emails?.length > 9 ||
          emails?.some(
            (item) => !item.email || emails?.some((item) => !item.email.match(emailElmtRegex))
          )
        }
        startIcon={
          <Icon fontName="fal fa-plus" size={18} color="#81C2F5" style={{ marginRight: 11 }} />
        }
        variant="text"
        className={styles['add-btn']}>
        {window.t('addEmail')}
      </Button>
    </div>
  );
}

export default EmailSettings;

function EmailInputGroup({ value, index, updateEmailConfig, deleteEmailConfig }) {
  const [nameValue, setNameValue] = useState(value.name);
  const [emailValue, setEmailValue] = useState(value.email);

  return (
    <div className={styles['input-group']}>
      <Button
        onlyIcon
        startIcon={<Icon fontName="fal fa-times-circle" size={18} />}
        variant="icon"
        color="#CDCDCD"
        className="ml-1"
        style={{
          borderRadius: '50%',
          padding: 0
        }}
        clickHandler={() => deleteEmailConfig('email', index)}
      />
      <Input
        blurHandler={(e) => updateEmailConfig('email', index, { email: e.target.value })}
        value={emailValue}
        placeholder="Email"
        error={emailValue?.match(emailElmtRegex) || emailValue === '' ? '' : 'Невалидный email'}
        changeHandler={(e) => setEmailValue(e.target.value)}
      />
      <Input
        blurHandler={(e) => updateEmailConfig('email', index, { name: e.target.value })}
        value={nameValue}
        placeholder={window.t('name')}
        changeHandler={(e) => setNameValue(e.target.value)}
      />
    </div>
  );
}
