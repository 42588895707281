import { useEffect } from 'react';

// https://react.toolkit.d.foundation/hooks/use-async-effect
export const useAsyncEffect = (callback, onDestroyOrDependencies, dependencies) => {
  let deps;
  let destroy;

  if (typeof onDestroyOrDependencies === 'function') {
    destroy = onDestroyOrDependencies;
    deps = dependencies;
  } else {
    deps = onDestroyOrDependencies || [];
  }

  useEffect(() => {
    let result;
    let mounted = true;

    const maybePromise = callback(() => {
      return mounted;
    });

    Promise.resolve(maybePromise).then((value) => {
      result = value;
    });

    return () => {
      mounted = false;

      if (typeof destroy === 'function') {
        destroy(result);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
