import throttle from 'lodash/throttle';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { useInterval } from 'hooks';
// import throttle from "lodash/throttle";
import useActions from 'hooks/useActions';

export const useMessageFeedScrollBox = ({
  scrollBoxRef,
  tab,
  isNewAtTop,
  activeMenu,
  loadPrevious,
  isLoaded
}) => {
  const hasInitiallyScrolledRef = useRef(false);
  const [isButtonsInTheView, setIsButtonInTheView] = useState(false);
  const loadPreviousButtonRef = useRef(null);
  const { onScrollBox } = useActions();
  const feed = useSelector((state) => state.feed);
  const items = feed[tab]?.items;
  const { isSearchModalCollapsed } = useSelector((state) => state.messageFilters);
  const messageFeedConfig = useSelector((state) => state.settings.messageFeedConfig);
  const { isAlwaysAutoScroll } = messageFeedConfig;
  const previousOffsetTop = useRef(0);
  const containerRef = useRef(null);

  useInterval(
    () => {
      loadPrevious();
    },
    isButtonsInTheView ? 1000 : null
  );

  const scrollBoxPadding = useMemo(
    () => (activeMenu === 'search' ? (isSearchModalCollapsed ? 36 : 170) : 0),
    [isSearchModalCollapsed, activeMenu]
  );

  const options = useMemo(
    () => ({
      root: scrollBoxRef.current,
      threshold: 1
    }),
    [scrollBoxRef]
  );

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        if (entry.isIntersecting) loadPrevious();
        setIsButtonInTheView(entry.isIntersecting);
      }
    }, options);
    if (loadPreviousButtonRef.current) observer.observe(loadPreviousButtonRef.current);
    const oldRef = loadPreviousButtonRef.current;
    return () => {
      observer.disconnect();
      observer.unobserve(oldRef);
    };
  }, [tab, feed.currentTab, options, loadPrevious]);

  const findFirstElementInViewPort = (elements) =>
    Array.prototype.find.call(elements, (element) => element.getBoundingClientRect().y >= 0);

  const scrollTo = useMemo(() => {
    const nodeElements = scrollBoxRef.current?.querySelectorAll('[data-items]');
    return nodeElements && findFirstElementInViewPort(nodeElements);
  }, [items]);

  useEffect(() => {
    if (!scrollBoxRef.current) return;

    const difference = Math.abs(previousOffsetTop.current - containerRef.current.offsetHeight);

    previousOffsetTop.current = containerRef.current.offsetHeight;

    if (!isNewAtTop && !scrollBoxRef.current.scrollTop) {
      if (scrollTo) {
        scrollTo.scrollIntoView();
      } else {
        if (!isNewAtTop) scrollBoxRef.current.scrollTop = scrollBoxRef.current.scrollHeight;
      }
      return;
    }
    const offset =
      scrollBoxRef.current.scrollHeight -
      scrollBoxRef.current.offsetHeight -
      scrollBoxRef.current.scrollTop;
    if (isNewAtTop && scrollBoxRef.current.scrollTop && offset >= -1 && offset <= 1) {
      if (scrollTo) {
        scrollTo.scrollIntoView();
      } else {
        if (!isNewAtTop) scrollBoxRef.current.scrollTop = scrollBoxRef.current.scrollHeight;
      }
      return;
    }

    // const shouldScroll =
    //   containerRef.current.offsetHeight -
    //     scrollBoxRef.current.scrollTop -
    //     scrollBoxRef.current.offsetHeight -
    //     20 ===
    //   0;

    const shouldScroll =
      containerRef.current.offsetHeight -
        scrollBoxRef.current.scrollTop -
        scrollBoxRef.current.offsetHeight >
      0;

    if (isAlwaysAutoScroll && shouldScroll) {
      scrollBoxRef.current.scrollTop = isNewAtTop
        ? 0
        : containerRef.current.offsetHeight + difference;
    }
    window.scrollBy(0, 0);
    onScrollBox(
      scrollBoxRef.current.scrollTop,
      tab,
      scrollBoxRef.current.offsetHeight,
      scrollBoxRef.current.scrollHeight
    );
  }, [items, tab, isAlwaysAutoScroll, isNewAtTop]);

  const onFeedScroll = throttle((event) => {
    onScrollBox(event.target.scrollTop, tab, event.target.offsetHeight, event.target.scrollHeight);
    // if (isLoading) return;
    // if (isNewAtTop) {
    //   const offset =
    //     event.target.scrollHeight -
    //     event.target.offsetHeight -
    //     event.target.scrollTop;
    //   if (offset >= -1 && offset <= 1) {
    //     loadPrevious();
    //   }
    // } else {
    //   if (event.target.scrollTop <= 0) {
    //     loadPrevious();
    //   }
    // }
  }, 100);

  useEffect(() => {
    if (!isLoaded || hasInitiallyScrolledRef.current) return;
    scrollBoxRef.current.scrollTop = isNewAtTop ? 0 : containerRef.current.offsetHeight;
    hasInitiallyScrolledRef.current = true;
  }, [isLoaded, isNewAtTop, scrollBoxRef]);

  return {
    onFeedScroll,
    scrollBoxPadding,
    containerRef,
    loadPreviousButtonRef
  };
};
