import { forwardRef } from 'react';

import { Button } from 'components';

export const LoadPreviousButton = forwardRef(({ isLoading, isNewAtTop, hasMorePrevious }, ref) => {
  return (
    <Button
      ref={ref}
      color="#00000040"
      isDisabled
      isLoading={isLoading}
      style={{
        height: 35,
        padding: '5px 18px',
        fontSize: '0.9em',
        minWidth: 195,
        margin: '10px auto',
        display: hasMorePrevious ? 'block' : 'none',
        border: 0,
        background: 'none',
        flexShrink: 0,
        order: isNewAtTop ? 1 : 0
      }}
    />
  );
});
