import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';

import styles from './SearchModeSwitcher.module.css';

function SearchModeSwitcher({ activeSearchMode, searchConditions, setSearchMode }) {
  const getButtonClasses = (mode, isActive) => {
    return classNames({
      [styles['selected']]: isActive,
      [styles['affected']]:
        mode === 'text'
          ? searchConditions[mode] && searchConditions[mode].length > 2
          : !!searchConditions[mode]
    });
  };
  const modes = [
    {
      mode: 'text',
      icon: 'fal fa-text'
    },
    {
      mode: 'timeType',
      icon: 'fal fa-clock'
    },
    {
      mode: 'label',
      icon: 'fad fa-circle'
    },
    {
      mode: 'media',
      icon: 'fal fa-photo-video'
    },
    {
      mode: 'channel',
      icon: 'fas fa-share-alt'
    }
  ];

  return (
    <ul className={styles['searchTabButtons']}>
      {modes.map(({ mode, icon }, i) => {
        return (
          <Button
            startIcon={<Icon size={18} fontName={icon} />}
            clickHandler={() => {
              setSearchMode({ mode });
            }}
            className={getButtonClasses(mode, mode === activeSearchMode)}
            onlyIcon
            color="#00000026"
            textColor="#ffffff"
            variant="text"
            style={{
              padding: '10px 12px',
              borderRadius: 0
            }}
            key={i}
          />
        );
      })}
    </ul>
  );
}

export default SearchModeSwitcher;

SearchModeSwitcher.propTypes = {
  activeSearchMode: PropTypes.string,
  searchConditions: PropTypes.object,
  setSearchMode: PropTypes.func
};
