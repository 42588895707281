import { VoteAlertsView } from './VoteAlertsView';
import React from 'react';

import { useVoteAlerts } from './hooks/useVoteAlerts';

export function VoteAlertsContainer() {
  const { alerts, alertCloseHandler, disableAlerts } = useVoteAlerts();

  return (
    <VoteAlertsView
      alerts={alerts}
      alertCloseHandler={alertCloseHandler}
      disableAlerts={disableAlerts}
    />
  );
}
