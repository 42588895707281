import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useClearMenu } from 'containers/BroadcastControlsContainer/hooks/useClearMenu';

import { ControlsView } from 'components';

import { useLocalStorage } from 'hooks';
import useActions from 'hooks/useActions';

import { INTERACTIVES } from 'store/constants';

import { useBroadcast, useVote } from './hooks';
import { useControls } from './hooks/useControls';

const propTypes = {
  type: PropTypes.oneOf([INTERACTIVES.vote, INTERACTIVES.quiz]),
  voteId: PropTypes.string,
  isVoteActive: PropTypes.bool,
  result: PropTypes.string,
  settingsClickHandler: PropTypes.func.isRequired,
  visibility: PropTypes.bool,
  buttonsField: PropTypes.string
};

const defaultProps = {
  type: INTERACTIVES.vote,
  isVoteActive: false,
  result: '0',
  visibility: false
};

export const VoteControlsContainer = forwardRef(
  (
    {
      isVoteActive,
      type,
      voteId,
      result,
      settingsClickHandler,
      visibility,
      broadcastId,
      broadcastActivity
    },
    ref
  ) => {
    const { changeVisibility, fetchVoteVariants, setISettingsInfoFromSession } = useActions();

    const broadcastSettings = useSelector((state) => state.isettings.info.settings);

    const { clearVotesHandler, voteProps } = useVote({
      type,
      result,
      isVoteActive
    });

    const { broadcastProps } = useBroadcast({ broadcastId, broadcastActivity });

    const { clearBtn, toggleClearMenu, isMenuActive, clearMenuPosition } = useClearMenu({
      withIcon: true
    });

    const reload = useCallback(() => {
      setISettingsInfoFromSession(voteId, type);
      fetchVoteVariants(voteId);
    }, [type, voteId, fetchVoteVariants, setISettingsInfoFromSession]);

    // favorites in interactive

    const [favoriteMsg, setFavoriteMsg] = useLocalStorage('favoriteMsg', []);

    const statusFavorite = useMemo(() => {
      return favoriteMsg.find((el) => el.id === voteId && el.type === type);
    }, [favoriteMsg]);

    const toggleFavoriteHandle = useCallback(() => {
      if (!statusFavorite) {
        setFavoriteMsg([
          ...favoriteMsg,
          {
            id: voteId,
            type,
            isFavorite: true
          }
        ]);
      } else {
        setFavoriteMsg([
          ...favoriteMsg.filter((el) => el.id !== statusFavorite.id),
          {
            id: voteId,
            type,
            isFavorite: !statusFavorite.isFavorite
          }
        ]);
      }
    }, [type, voteId, favoriteMsg, statusFavorite]);

    const { controls } = useControls({
      voteProps,
      broadcastProps,
      settingsClickHandler,
      reload,
      clearVotesHandler,
      visibility,
      changeVisibility,
      clearBtn,
      toggleClearMenu,
      isMenuActive,
      clearMenuPosition,
      broadcastSettings,
      broadcastId,
      toggleFavoriteHandle,
      statusFavorite
    });

    return <ControlsView ref={ref}>{controls}</ControlsView>;
  }
);

VoteControlsContainer.propTypes = propTypes;
VoteControlsContainer.defaultProps = defaultProps;
