import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useInterval from 'hooks/useInterval';

import { NOTIFICATION_PERMISSIONS } from 'store/constants';

import { getIconImage } from 'helpers/image';

export const useNotifications = ({ localeState }) => {
  const [alert, setAlert] = useState(false);
  const { permission } = useSelector((state) => state.notifications);
  const { currentTab, ...feedState } = useSelector((state) => state.feed);
  const currentTabState = feedState[currentTab];
  let hasNewItems, newItemsCount;

  if (currentTabState) {
    hasNewItems = currentTabState.hasNewItems;
    newItemsCount = currentTabState.newItemsCount;
  }

  useInterval(
    () => {
      if (alert) {
        document.getElementById('favicon').href = getIconImage('alert.png');
      } else {
        document.getElementById('favicon').href = getIconImage('transparent.png');
      }
      setAlert((v) => !v);
    },
    permission === NOTIFICATION_PERMISSIONS.granted &&
      document.visibilityState === 'hidden' &&
      hasNewItems
      ? 400
      : null
  );

  useEffect(() => {
    if (!hasNewItems) document.getElementById('favicon').href = getIconImage('favicon.ico');
  }, [hasNewItems]);

  // useEffect(() => {
  //   const listener = () => {
  //     if (document.visibilityState === 'visible') {
  //       document.getElementById("favicon").href = getIconImage('favicon.ico');
  //       document.title = window.t('interactivesIActivePro', localeState);
  //     }
  //   }
  //   document.addEventListener('visibilitychange', listener);
  //   return () => document.removeEventListener('visibilitychange', listener);
  // }, [localeState])

  useEffect(() => {
    if (permission !== NOTIFICATION_PERMISSIONS.granted) return;

    if (newItemsCount > 0 && document.visibilityState === 'hidden') {
      document.title = `+${newItemsCount} ${window.t('messageFeed', localeState)}`;
    } else {
      document.title = window.t('interactivesIActivePro', localeState);
    }
  }, [newItemsCount, permission, localeState]);
};
