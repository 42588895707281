import { memo, useRef } from 'react';

import { Button, SearchInput } from 'components';

import s from './RegistrationSearchView.module.css';

export const RegistrationSearchView = memo(
  ({
    isSearchActive,
    setIsSearchActive,
    searchText,
    setSearchText,
    isFiltered,
    setIsFiltered,
    registrationFilters,
    setRegistrationFilters,
    setIsFilterActive
  }) => {
    return (
      <>
        <SearchInput
          // interactiveSearchBtnRef={interactiveSearchBtnRef}
          isActive={isSearchActive}
          isFiltered={isFiltered}
          searchText={searchText}
          toggleSearchActivity={(value) => {
            setIsSearchActive(value);
          }}
          changeHandler={(value) => setSearchText(value)}
          onFocusHandler={() => setIsFilterActive(true)}
          styles={{ marginLeft: 'auto' }}
          width="30%"
        />
        <ul className={`${s.filtersContainer} mt-2`}>
          {registrationFilters?.length !== 0 &&
            registrationFilters?.map(({ value, isActive }, i) => {
              return (
                <li key={value + i}>
                  <Button
                    clickHandler={() => {
                      setRegistrationFilters((interactives) =>
                        interactives.map((el) =>
                          el.value === value ? { ...el, isActive: !isActive } : el
                        )
                      );
                    }}
                    // variant="text"
                    color={isActive ? '#57ADF2' : '#83888e'}
                    style={{ fontSize: 15 }}>
                    {window.t(value === 'On-line' ? 'online' : 'offline')}
                  </Button>
                </li>
              );
            })}
        </ul>
        <div className={s.btnBlock}>
          <Button clickHandler={() => setIsFilterActive(false)}>{window.t('close')}</Button>
        </div>
      </>
    );
  }
);
