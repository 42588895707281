import PropTypes from 'prop-types';
import React from 'react';

import styles from './CardHeader.module.css';

function CardHeader({ children, className, style }) {
  return (
    <div className={`${styles['card-header']} ${className}`} style={style}>
      {children}
    </div>
  );
}

export default CardHeader;

CardHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  className: PropTypes.string,
  style: PropTypes.object
};

CardHeader.defaultProps = {
  style: {},
  className: ''
};
