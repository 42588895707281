import { VoteVariantsOtherType } from './VoteVariantsOtherType';
import { VoteVariantsVoteType } from './VoteVariantsVoteType';
import React, { memo } from 'react';

export const VoteVariantsContainer = memo(({ type, voteInfo, variantRef }) => {
  return (
    <>
      {type === 'vote' ? (
        <VoteVariantsVoteType type={type} voteInfo={voteInfo} variantRef={variantRef} />
      ) : (
        <VoteVariantsOtherType type={type} voteInfo={voteInfo} variantRef={variantRef} />
      )}
    </>
  );
});
