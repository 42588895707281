import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './TextSearchMode.module.css';

function TextSearchMode({ searchConditions, setSearchConditions, activeSearchMode, scrollBoxRef }) {
  const classes = classNames(styles['pane'], {
    hidden: activeSearchMode !== 'text'
  });

  function changeHandler(event) {
    const newValue = event.target.value;
    setSearchConditions('text', newValue, scrollBoxRef);
  }
  const { text } = searchConditions;
  return (
    <div className={classes}>
      {/* Айди режимов поиска должны именоваться как классы кнопок + 'Tab' */}
      <p className={styles['title']}>{window.t('textSearch')}</p>
      <input onChange={changeHandler} className={styles['search-field']} type="text" value={text} />
      <p className={styles['note']}>{window.t('searchStartCondition')}</p>
    </div>
  );
}

export default TextSearchMode;

TextSearchMode.propTypes = {
  searchConditions: PropTypes.object,
  setSearchConditions: PropTypes.func,
  activeSearchMode: PropTypes.string
};
