import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';

import { formatTimeToPlayer } from 'helpers/dateTime';

import styles from './AudioMediaItem.module.css';

class AudioMediaItem extends React.Component {
  constructor(props) {
    super(props);
    this.player = new Audio();
    this.player.src = props.src;
  }

  state = {
    isPlaying: false,
    restTime: 0
  };

  componentDidMount() {
    this.player.addEventListener('loadedmetadata', this.audioLoadHandler);
    this.player.addEventListener('timeupdate', this.timeUpdateHandler);
    this.player.onended = () => {
      this.setState({
        isPlaying: false,
        restTime: Math.round(this.player.duration)
      });
      this.player.pause();
    };
  }

  componentWillUnmount() {
    this.player.removeEventListener('loadedmetadata', this.audioLoadHandler);
    this.player.removeEventListener('timeupdate', this.timeUpdateHandler);
  }

  audioLoadHandler = () => {
    this.setState({
      restTime: Math.round(this.player.duration)
    });
  };

  timeUpdateHandler = () => {
    const { duration, currentTime } = this.player;
    const restTime = Math.round(duration - currentTime);
    this.setState({
      restTime
    });
  };

  play = () => {
    if (this.player.paused) {
      this.player.play().then();
      this.setState({
        isPlaying: true
      });
    } else {
      this.player.pause();
      this.setState({
        isPlaying: false
      });
    }
  };

  render() {
    return (
      <div className={styles['profile-card__media-item']}>
        {this.state.isPlaying ? (
          <Button
            startIcon={<Icon size={32} fontName="fa fa-pause" />}
            onlyIcon
            variant="message-action"
            color="#474747"
            clickHandler={this.play}
            style={{ padding: '6px 8px', opacity: 1, margin: '5px auto' }}
            key="stop"
          />
        ) : (
          <Button
            startIcon={<Icon size={40} fontName="fa fa-volume-up" />}
            onlyIcon
            variant="message-action"
            color="#474747"
            clickHandler={this.play}
            style={{ padding: '6px 8px', opacity: 1 }}
            key="start"
          />
        )}
        <div className={styles['profile-card__media-item-music-desc']}>
          {formatTimeToPlayer(this.state.restTime)}
        </div>
      </div>
    );
  }
}

export default AudioMediaItem;

AudioMediaItem.propTypes = {
  src: PropTypes.string.isRequired
};
