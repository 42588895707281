import { MessageActionsMenuView } from './MessageActionsMenuView';
import React from 'react';

import { useMessageActionsMenu } from './hooks/useMessageActionsMenu';

export function MessageActionsMenuContainer({ message, hideModal }) {
  const {
    labels,
    listItems,
    isCreated,
    listEmails,
    listTelegrams,
    blockAuthorHandler,
    deleteMessageHandler,
    exportMessageHandler
  } = useMessageActionsMenu({ messageId: message.id, hideModal });

  return (
    <MessageActionsMenuView
      message={message}
      labels={labels}
      listItems={listItems}
      listEmails={listEmails}
      listTelegrams={listTelegrams}
      isCreated={isCreated}
      exportMessageHandler={exportMessageHandler}
      blockAuthorHandler={blockAuthorHandler}
      deleteMessageHandler={deleteMessageHandler}
      hideModal={hideModal}
    />
  );
}
