const timeZoneFromDB = '+03';
// // Возвращает суффикс текущего часового пояса в формате ".000±HH:MM"
let currentTimezoneSuffix = '';

export const zeroFormatter = (val) => (val < 10 ? `0${val}` : val);
// Number.prototype.padLeft = function (n, str) {
//   return Array(n - String(this).length + 1).join(str || '0') + this;
// };

export function getTimePickerValue(date) {
  return `${zeroFormatter(date.getHours())}:${zeroFormatter(date.getMinutes())}`;
}

export function getPickerObject(date) {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate()
  };
}

export function pickerObjToString({ day, month, year }) {
  return `${year} ${month} ${day}`;
}

export function isValidStrDate(dateStr) {
  if (Object.prototype.toString.call(dateStr) === '[object Date]') {
    if (isNaN(dateStr.getTime())) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}
// ----------------------------------
//* Преобразования дат
// ----------------------------------

//  Возвращает строковое представление переданной даты dt в форматах:
//  12:34 - только время для сегодняшних дат;
//  Вчера, 12:34 - для предыдущих суток;
//  21 янв., 12:34 - для текущего года;
//  21 янв. 2017 г., 12:34 - для дат не текущего года.
//	Если noNullTime установлено в true и время имеет значение 00:00:00, то
//	возвращается строка без указания времени.
export function getUIDateFromJSDate(dt, noNullTime, onlyDate, today = false) {
  let localDate;
  // TODO не корректное отображение времени в зависимости от часового пояса
  if (dt instanceof Date) {
    localDate = dt.toUTCString();
    localDate = new Date(localDate);
  } else {
    const [date, time] = (typeof dt === 'string' ? dt : dt.toString()).split(' ');
    const formattedDate = `${date}T${time}+0300`;
    localDate = new Date(formattedDate).toUTCString();
    localDate = new Date(localDate);
  }

  if (onlyDate) {
    let dateForRet = new Date(localDate);
    let m = dateForRet.getMonth() + 1;
    if (m < 10) m = `0${m}`;
    let d = dateForRet.getDate();
    if (d < 10) d = `0${d}`;
    return `${d}.${m}.${dateForRet.getFullYear()}`;
  }
  if (localDate) {
    const locale = JSON.parse(localStorage.getItem('locale'));

    let today = new Date();
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    let byesterday = new Date();
    byesterday.setDate(byesterday.getDate() - 2);
    let ymd = [localDate.getFullYear(), localDate.getMonth(), localDate.getDate()];
    let options = {
      hour: '2-digit',
      minute: '2-digit'
    };
    let dayStr = '';
    if (
      today.getFullYear() === ymd[0] &&
      today.getMonth() === ymd[1] &&
      today.getDate() === ymd[2]
    ) {
      dayStr = `${window.t('today')}, `;
    } else if (
      yesterday.getFullYear() === ymd[0] &&
      yesterday.getMonth() === ymd[1] &&
      yesterday.getDate() === ymd[2]
    ) {
      dayStr = `${window.t('yesterday')}, `;
    } else if (
      byesterday.getFullYear() === ymd[0] &&
      byesterday.getMonth() === ymd[1] &&
      byesterday.getDate() === ymd[2]
    ) {
      dayStr = `${window.t('theDayBeforeYesterday')}, `;
    } else if (today.getFullYear() !== ymd[0]) {
      options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      };
    } else {
      options = {
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      };
    }
    let dateTimeFormat = new Intl.DateTimeFormat(locale === 'ru' ? 'ru-RU' : 'en-US', options);
    let dtStr;
    try {
      dtStr = dayStr + dateTimeFormat.format(localDate);
    } catch (error) {
      console.error({ error });
    }

    if (noNullTime === true) {
      let dta = dtStr.split(',');
      let timeStr = '';
      if (dta.length > 1) {
        timeStr = dta[1].trim();
      } else if (dta.length === 1) {
        timeStr = dta[0].trim();
      }
      if (
        timeStr === '0:00' ||
        timeStr === '00:00' ||
        timeStr === '0:00:00' ||
        timeStr === '00:00:00'
      ) {
        dtStr = dta[0];
      }
    }
    return dtStr;
  }
}

export function getUILocaleDateFromJSDate(dt, noNullTime, onlyDate, today = false) {
  if (onlyDate) {
    let dateForRet = new Date(dt);
    let m = dateForRet.getMonth() + 1;
    if (m < 10) m = `0${m}`;
    let d = dateForRet.getDate();
    if (d < 10) d = `0${d}`;
    return `${d}.${m}.${dateForRet.getFullYear()}`;
  }
  if (dt) {
    const locale = JSON.parse(localStorage.getItem('locale'));

    let today = new Date();
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    let byesterday = new Date();
    byesterday.setDate(byesterday.getDate() - 2);
    let ymd = [dt.getFullYear(), dt.getMonth(), dt.getDate()];
    let options;
    let dayStr = '';
    if (
      today.getFullYear() === ymd[0] &&
      today.getMonth() === ymd[1] &&
      today.getDate() === ymd[2]
    ) {
      dayStr = `${window.t('today')}`;
    } else if (
      yesterday.getFullYear() === ymd[0] &&
      yesterday.getMonth() === ymd[1] &&
      yesterday.getDate() === ymd[2]
    ) {
      dayStr = `${window.t('yesterday')}`;
    } else if (
      byesterday.getFullYear() === ymd[0] &&
      byesterday.getMonth() === ymd[1] &&
      byesterday.getDate() === ymd[2]
    ) {
      dayStr = `${window.t('theDayBeforeYesterday')}`;
    } else if (today.getFullYear() !== ymd[0]) {
      options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      };
    } else {
      options = {
        month: 'long',
        day: 'numeric'
      };
    }
    let dateTimeFormat = new Intl.DateTimeFormat(locale === 'ru' ? 'ru-RU' : 'en-US', options);
    let dtStr = dayStr;
    if (options) {
      try {
        dtStr = dayStr + dateTimeFormat.format(dt);
      } catch (error) {
        console.error({ error });
      }
    }
    if (noNullTime === true) {
      let dta = dtStr.split(',');
      let timeStr = '';
      if (dta.length > 1) {
        timeStr = dta[1].trim();
      } else if (dta.length === 1) {
        timeStr = dta[0].trim();
      }
      if (
        timeStr === '0:00' ||
        timeStr === '00:00' ||
        timeStr === '0:00:00' ||
        timeStr === '00:00:00'
      ) {
        dtStr = dta[0];
      }
    }
    return dtStr;
  }
}

export function getUITimeFromJSDate(dateString) {
  const [date, time] = (typeof dateString === 'string' ? dateString : dateString.toString()).split(
    ' '
  );
  const formattedDate = `${date}T${time}+0300`;
  let localDate = new Date(formattedDate).toUTCString();
  localDate = new Date(localDate);
  if (localDate) {
    const locale = JSON.parse(localStorage.getItem('locale'));
    let options = {
      hour: '2-digit',
      minute: '2-digit'
    };
    let dayStr = '';
    let dateTimeFormat = new Intl.DateTimeFormat(locale === 'ru' ? 'ru-RU' : 'en-US', options);
    let dtStr;
    try {
      dtStr = dayStr + dateTimeFormat.format(localDate);
    } catch (error) {
      console.error({ error });
    }
    return dtStr;
  }
}

export function getUIDateFromJSDateForChat(dt, noNullTime, onlyDate, today = false) {
  try {
    if (onlyDate) {
      let dateForRet = new Date(dt);
      let m = dateForRet.getMonth() + 1;
      if (m < 10) m = `0${m}`;
      let d = dateForRet.getDate();
      if (d < 10) d = `0${d}`;
      return `${d}.${m}.${dateForRet.getFullYear()}`;
    }
    if (dt) {
      const locale = JSON.parse(localStorage.getItem('locale'));

      let today = new Date();
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      let byesterday = new Date();
      byesterday.setDate(byesterday.getDate() - 2);
      let ymd = [dt.getFullYear(), dt.getMonth(), dt.getDate()];
      let options = {
        hour: '2-digit',
        minute: '2-digit'
      };
      let dayStr = '';
      if (
        today.getFullYear() === ymd[0] &&
        today.getMonth() === ymd[1] &&
        today.getDate() === ymd[2]
      ) {
        // options = { hour: '2-digit', minute: '2-digit' };
        return window.t('today');
      } else if (
        yesterday.getFullYear() === ymd[0] &&
        yesterday.getMonth() === ymd[1] &&
        yesterday.getDate() === ymd[2]
      ) {
        return window.t('yesterday');
      } else if (
        byesterday.getFullYear() === ymd[0] &&
        byesterday.getMonth() === ymd[1] &&
        byesterday.getDate() === ymd[2]
      ) {
        return window.t('theDayBeforeYesterday');
      } else if (today.getFullYear() !== ymd[0]) {
        options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        };
      } else {
        options = {
          month: 'long',
          day: 'numeric'
        };
      }
      let dateTimeFormat = new Intl.DateTimeFormat(locale === 'ru' ? 'ru-RU' : 'en-US', options);
      let dtStr;
      try {
        dtStr = dayStr + dateTimeFormat.format(dt);
      } catch (error) {
        console.error({ error });
      }
      if (noNullTime === true) {
        let dta = dtStr.split(',');
        let timeStr = '';
        if (dta.length > 1) {
          timeStr = dta[1].trim();
        } else if (dta.length === 1) {
          timeStr = dta[0].trim();
        }
        if (
          timeStr === '0:00' ||
          timeStr === '00:00' ||
          timeStr === '0:00:00' ||
          timeStr === '00:00:00'
        ) {
          dtStr = dta[0];
        }
      }
      return dtStr;
    }
  } catch (ex) {
    return '';
  }
}

export function getUILastDate(timestamp) {
  try {
    let dt = new Date(timestamp);
    if (dt) {
      let today = new Date();
      let now = Date.now();
      let ymd = [dt.getFullYear(), dt.getMonth(), dt.getDate()];
      if (
        today.getFullYear() === ymd[0] &&
        today.getMonth() === ymd[1] &&
        today.getDate() === ymd[2]
      ) {
        return `${0}${window.t('day')[0]}`;
      } else {
        dt.setMilliseconds(0);
        dt.setSeconds(0);
        dt.setMinutes(0);
        dt.setHours(0);
        dt.setDate(dt.getDate() + 1);
        return `${Math.ceil(Math.abs(now - dt.getTime()) / 1000 / 60 / 60 / 24)}${window.t(
          'dayAgo'
        )}`;
      }
    }
  } catch (ex) {
    return '';
  }
}

// Преобразует строку (SQL DateTime) в объект даты JS
export function getJSDateFromDBDate(dbDate) {
  if (!dbDate) return '';
  let dbDateTime = dbDate;
  let jsDateTime = `${dbDateTime.replace(' ', 'T')}.000${timeZoneFromDB}:00`;
  let dt = new Date(Date.parse(jsDateTime));
  return dt;
}

export function getJSDateFromDBDateNoTimeZone(dbDate) {
  let dbDateTime = dbDate;
  let jsDateTime = `${dbDateTime.replace(' ', 'T')}.000+05:00`;
  let dt = new Date(Date.parse(jsDateTime));
  return dt;
}

export function getDateNow() {
  const timeZone = (new Date().getTimezoneOffset() / 60) * -1;
  const date = new Date(new Date().getTime() - timeZone * 3600 * 1000 + 3 * 3600 * 1000); // Время мск
  const year = `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(
    -2
  )}-${`0${date.getDate()}`.slice(-2)}`;
  const time = `${`0${date.getHours()}`.slice(-2)}:${`0${date.getMinutes()}`.slice(
    -2
  )}:${`0${date.getSeconds()}`.slice(-2)}`;

  return `${year} ${time}`;
}

export function getDateMoscow(m_time) {
  const timeZone = (new Date().getTimezoneOffset() / 60) * -1;
  const date = new Date(new Date(m_time).getTime() - timeZone * 3600 * 1000 + 3 * 3600 * 1000); // Время мск
  const year = `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(
    -2
  )}-${`0${date.getDate()}`.slice(-2)}`;
  const time = `${`0${date.getHours()}`.slice(-2)}:${`0${date.getMinutes()}`.slice(
    -2
  )}:${`0${date.getSeconds()}`.slice(-2)}`;

  return `${year} ${time}`;
}

// Преобразует строку (SQL DateTime) в строку "умного" представления даты.
// 	'12:34' - если дата сегодняшняя;
//	'Вчера, 12:34';
//	'Позавчера: 12:34';
//	'14 мар, 12:34' - если ранее, чем позвачера.
//
// Если noNullTime установлено в true и время имеет значение 00:00:00, то
// возвращается строка без указания времени.
export function getUIDateFromDBDate(dbDate, noNullTime, onlyDate = false) {
  let dt = getJSDateFromDBDate(dbDate);
  if (typeof dt.getTime !== 'function' || isNaN(dt.getTime())) return 'Invalid date';
  let dtStr = getUIDateFromJSDate(dt, noNullTime, onlyDate);

  return dtStr;
}

// Преобразует строку (SQL DateTime) в строку "умного" представления даты.
// 	'Сегодня' - если дата сегодняшняя;
//	'Вчера';
//	'Позавчера';
//	'14 мар, 12:34' - если ранее, чем позвачера.
export function getUIDateFromDBDateForChat(dbDate, noNullTime, onlyDate = false) {
  let dt = getJSDateFromDBDate(dbDate);
  let dtStr = getUIDateFromJSDateForChat(dt, noNullTime, onlyDate);

  return dtStr;
}

export function getCurrentTimezoneSuffix() {
  if (currentTimezoneSuffix !== '') {
    return currentTimezoneSuffix;
  }
  let today = new Date();
  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  let tzMinutes = today.getTimezoneOffset() * -1;
  let tzHours = tzMinutes / 60;
  tzMinutes = tzMinutes - tzHours * 60;
  currentTimezoneSuffix = '.000';
  if (tzHours > 0) {
    currentTimezoneSuffix = '.000+';
  }

  currentTimezoneSuffix = `${currentTimezoneSuffix + tzHours.padLeft(2)}:${tzMinutes.padLeft(2)}`;
  return currentTimezoneSuffix;
}

// Преобразует JS-дату в строку SQL DateTime
export function getSQLDateTimeLocal(jsDate) {
  // 2018-03-29 13:12:07
  if (!jsDate) {
    jsDate = new Date();
  }
  let s = `${jsDate.getFullYear()}-${(jsDate.getMonth() + 1).padLeft(2)}-${jsDate
    .getDate()
    .padLeft(2)} `;
  s = `${s + jsDate.getHours().padLeft(2)}:${jsDate.getMinutes().padLeft(2)}:${jsDate
    .getSeconds()
    .padLeft(2)}`;
  return s;
}

export function getSQLDateTime(jsDate) {
  // 2018-03-29 13:12:07.000+05:00
  return getSQLDateTimeLocal(jsDate) + getCurrentTimezoneSuffix();
}

export function getTimeFromDateTime(dateTime) {
  let dt = getJSDateFromDBDate(dateTime);
  let formatter = (val) => (val < 10 ? `0${val}` : val);
  let h = formatter(dt.getHours());
  let m = formatter(dt.getMinutes());
  return `${h}:${m}`;
}

export const formatTimeToPlayer = (seconds) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = seconds % 60;
  return [h, m > 9 ? m : h ? `0${m}` : m || '00', s > 9 ? s : `0${s}`].filter((a) => a).join(':');
};

export function isDateDifferent(newDateTime, lastDateTime) {
  if (new Date(newDateTime).getFullYear() - new Date(lastDateTime).getFullYear() !== 0) return true;
  if (new Date(newDateTime).getMonth() - new Date(lastDateTime).getMonth() !== 0) return true;
  if (new Date(newDateTime).getDate() - new Date(lastDateTime).getDate() !== 0) return true;
  return false;
}

export function getDBDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
