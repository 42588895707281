import React, { createContext } from 'react';

import useLocalStorage from 'hooks/useLocalStorage';

export const LocaleContext = createContext([{}, () => {}]);

export function LocaleProvider({ children }) {
  const [locale, setLocale] = useLocalStorage('locale', window.DEFAULT_LOCALE);

  return <LocaleContext.Provider value={[locale, setLocale]}>{children}</LocaleContext.Provider>;
}
