import React, { useState } from 'react';

import { Button, Icon, Input, SvgCheckbox, Text } from 'components';

import useFetch from 'hooks/useFetch';

import {
  checkTelegramStatus,
  confirmTelegram,
  connectTelegramBot,
  connectTelegramClient
} from 'api/dataLayer';

import { isValdidPhone } from 'helpers/phone';

export function PhoneForm({
  setGroups,
  phone,
  setPhone,
  isAuthed,
  setIsAuthed,
  password,
  setPassword,
  code,
  setCode,
  type,
  closeHandler,
  connectId
}) {
  const [id, setId] = useState(connectId);
  const [phoneError, setPhoneError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [codeError, setCodeError] = useState('');
  const [withPassword, setWithPassword] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [{ response: responseAuth, isLoading: isLoadingAuth, error: errorAuth }, doFetchAuth] =
    useFetch('api_connect_telegram_client');

  const validateInputs = () => {
    const includesLetters = /[a-zа-я]/i.test(phone);
    if (includesLetters) {
      setPhoneError(window.t('invalidPhone'));
    }

    let phoneNumber = phone.replace(/\D/g, '');
    if (phoneNumber.startsWith('8')) {
      phoneNumber = `7${phoneNumber.slice(1)}`;
    }
    const isPhoneValid = isValdidPhone(phoneNumber);
    if (!isPhoneValid) {
      setPhoneError(window.t('invalidPhone'));
    }

    const isPasswordValid = !(withPassword && password.length === 0);
    if (!isPasswordValid) {
      setPasswordError(window.t('requiredField'));
    }

    return !includesLetters && isPhoneValid && isPasswordValid;
  };

  const authHandler = async () => {
    if (type !== 'bot' && !validateInputs()) return;

    let phoneNumber = phone;
    if (type !== 'bot') {
      if (phoneNumber.replace(/\D/g, '').startsWith('8')) {
        phoneNumber = `7${phoneNumber.replace(/\D/g, '').slice(1)}`;
      } else {
        phoneNumber = phoneNumber.replace(/\D/g, '');
      }
    }

    const api = type === 'bot' ? connectTelegramBot : connectTelegramClient;
    const data =
      type === 'bot'
        ? phoneNumber
        : {
            appPhone: phoneNumber,
            appPassword: password
          };

    try {
      const res = await api(data);

      if (res.status === 'error') throw new Error(res);
      if (res.body === 'Database error') throw new Error(res);

      const { connectionId } = res.body || {};
      if (!connectionId) return;

      setId(connectionId);

      if (type === 'bot') setGroupName(res?.body?.name || phone);

      setIsAuthed(true);
      checkStatus(connectionId);
    } catch (ex) {}
  };

  const checkStatus = async (connectId) => {
    const res = await checkTelegramStatus(connectId);
    const status = res?.body;

    if (status === 'error') {
      // make invalid
      setIsAuthed(false);
      setPhoneError(window.t('enteredIncorrectPhone'));
      closeHandler();
    } else if (status === 'connecting') {
      if (!isAuthed) return;
      setTimeout(() => {
        checkStatus(connectId);
      }, 3000);
    } else if (status === 'connected') {
      setGroups((groups) => [
        ...groups,
        {
          groupId: connectId,
          groupName: groupName || phone,
          type,
          isComment: null,
          isMessage: null
        }
      ]);
      closeHandler();
    }
  };

  const connectHandler = async () => {
    if (!code.trim()) {
      setCodeError(window.t('requiredField'));
      return;
    }
    try {
      const res = await confirmTelegram({
        connectId: id,
        confirmCode: code.trim()
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {/* <MaskedInput
mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
placeholder="Enter a phone number"
id="my-input-id"
render={(ref, props) => (
<MyStyledInput innerRef={ref} {...props} />
)}
/> */}
      <Input
        type="text"
        value={phone}
        label={type === 'bot' ? `${window.t('botToken')}:` : `${window.t('phoneNumber')}:`}
        placeholder=""
        changeHandler={(evt) => {
          const value = evt.target.value;
          setPhone(value);
          const error = value.length === 0 ? window.t('requiredField') : '';
          setPhoneError(error);
        }}
        className="mt-4"
        error={phoneError}
        isDisabled={isAuthed}
      />

      {type === 'bot' ? (
        <Button
          className="ml-auto flex-shrink-0 align-self-center"
          style={{
            padding: '8px 20px'
          }}
          clickHandler={authHandler}>
          {window.t('authorizeApp')}
        </Button>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              color: '#808080',
              fontSize: 14
            }}>
            <SvgCheckbox
              isChecked={withPassword}
              changeHandler={(isChecked) => {
                setWithPassword(isChecked);
                setPasswordError('');
              }}
              cbStyle={{
                marginRight: 10,
                flexShrink: 0,
                order: -1
              }}
              isDisabled={isAuthed}
            />
            <p>{window.t('useTelegramDoubleFactorAuthentication')}</p>
          </div>
          <Input
            type="text"
            value={password}
            label={`${window.t('password')}:`}
            placeholder={window.t('enterTelegramPassword')}
            changeHandler={(evt) => {
              const value = evt.target.value;
              setPassword(value);
              const error = value.length === 0 ? window.t('requiredField') : '';
              setPasswordError(error);
            }}
            className="mt-5"
            error={passwordError}
            isDisabled={!withPassword || isAuthed}
          />
          <Button
            className="ml-auto flex-shrink-0 align-self-center"
            style={{
              padding: '8px 20px'
            }}
            clickHandler={authHandler}
            isDisabled={isLoadingAuth || isAuthed}>
            {window.t('authorizeApp')}
          </Button>

          <div className="d-flex mt-4">
            <Input
              type="text"
              value={code}
              label={`${window.t('сonfirmationCode')}:`}
              placeholder={`${window.t('enter')} ${window.t('сonfirmationCode').toLowerCase()}`}
              changeHandler={(evt) => {
                const value = evt.target.value;
                setCode(value);
                const error = value.length === 0 ? window.t('requiredField') : '';
                setCodeError(error);
              }}
              error={codeError}
              isDisabled={!isAuthed}
            />
            <Button
              className="ml-3 flex-shrink-0 align-self-center"
              style={{
                padding: '9px 20px',
                marginBottom: '17px'
              }}
              clickHandler={connectHandler}
              isDisabled={!isAuthed}>
              {window.t('connect')}
            </Button>
          </div>
          {/* <div className="d-flex align-self-baseline mr-1">
              <Icon size={16} color="#CDCDCD" fontName="fal fa-question-circle" />
              <Text className="ml-2 ml-1" size={12}>Подробная инструкция получения токена доступна по <a href="#" target="_blank">ссылке</a></Text>
            </div> */}
        </>
      )}
    </>
  );
}
