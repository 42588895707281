import React, { useCallback } from 'react';

import Icon from 'components/Icon/Icon';

import useActions from 'hooks/useActions';

import { INTERACTIVES } from 'store/constants';

import { confirm } from 'helpers/common';

export const useVote = ({ type, result, isVoteActive }) => {
  const { clearVotes, startVote, addSnack, stopVote } = useActions();

  const startVoteHandler = useCallback(() => {
    if (type === INTERACTIVES.quiz && result === '0') {
      addSnack({
        type: 'danger',
        message: window.t('quizChooseCorrectAnswer')
      });
      return;
    }

    startVote();
  }, [type, result, addSnack, startVote]);

  const clearVotesHandler = useCallback(() => {
    const message =
      type === INTERACTIVES.quiz ? window.t('clearQuizQuestion') : window.t('clearVoteQuestion');
    if (confirm(message)) {
      clearVotes();
    }
  }, [type, clearVotes]);

  const voteProps = {
    startIcon: isVoteActive ? (
      <Icon size={20} fontName="far fa-stop" />
    ) : (
      <Icon size={20} fontName="far fa-play" />
    ),
    title:
      isVoteActive && type === INTERACTIVES.vote
        ? window.t('stopVote')
        : !isVoteActive && type === INTERACTIVES.vote
        ? window.t('startVote')
        : isVoteActive && type === INTERACTIVES.quiz
        ? window.t('stopQuiz')
        : window.t('startQuiz'),
    color: isVoteActive ? '#ff4d29' : '#00bb00',
    clickHandler: isVoteActive ? stopVote : startVoteHandler,
    key: isVoteActive ? 'stop' : 'start'
  };

  return {
    clearVotesHandler,
    voteProps
  };
};
