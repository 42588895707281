import { getEventOptions } from './constants';
import { useMemo } from 'react';
import Joyride from 'react-joyride';
import { useSelector } from 'react-redux';

import { InteracitveNotFoundView, SessionModuleView } from 'components';

import { useActions } from 'hooks';

import { INTERACTIVES } from 'store/constants';

import { editAutoreply } from 'api/dataLayer';

import { getGuideLocale } from 'helpers/locale';

import { AutoReplyBody, AutoReplySettings, AutoReplyToolbar } from './components';

import { useAutoReplyGuide } from './hooks/useAutoReplyGuid';

export function AutoReplyView({
  isSettingsOpened,
  toggleSettings,
  autoReplyId,
  isInteractiveExist
}) {
  const { updateInfoField } = useActions();
  const autoreplyInfo = useSelector((state) => state.isettings.info);
  const { condition_event, template_message, trigger_event, interactiveName, activity } =
    autoreplyInfo;

  const startWords = trigger_event === 'keyWord' && condition_event ? condition_event : [];

  const selectedEvent = useMemo(
    () => getEventOptions().find((el) => el.value === trigger_event),
    [trigger_event]
  );

  const {
    run,
    startGuide,
    steps,
    stepIndex,
    setStepIndex,
    handleJoyrideCallback,
    controlsRef,
    newsTypeRef,
    textToSpeechRef,
    contentViewRef,
    interactiveInfoRef,
    selectControlsRef,
    okButtonRef,
    chooseTypeRef,
    startBroadcast,
    greenRef,
    chooseAnotherTypeRef
  } = useAutoReplyGuide({ type: INTERACTIVES.content });

  const updateStartWords = (newValue) => {
    updateInfoField('condition_event', newValue);
    editAutoreply({
      id: autoReplyId,
      ...(startWords &&
        selectedEvent?.value === 'keyWord' && {
          conditionEvent: newValue
        })
    });
  };

  const addStartWordValue = (newValue) => {
    const newStartWords = [...startWords, newValue];
    updateStartWords(newStartWords);
  };

  const deleteStartWordValue = (deletedValue) => {
    const newStartWords = startWords.filter((val) => val !== deletedValue);
    updateStartWords(newStartWords);
  };

  const changeAutoreplayEvent = (newValue) => {
    const newEvent = getEventOptions().find((el) => el.value === newValue.value);
    updateInfoField('trigger_event', newEvent.value);
    editAutoreply({
      id: autoReplyId,
      ...(newEvent && { triggerEvent: newEvent.value })
    });
  };

  return (
    <>
      {isInteractiveExist ? (
        <>
          <SessionModuleView className="overflow-hidden">
            <Joyride
              callback={handleJoyrideCallback}
              continuous
              run={run}
              scrollToFirstStep
              showProgress
              steps={steps}
              stepIndex={stepIndex}
              locale={getGuideLocale()}
            />
            <AutoReplyToolbar
              interactiveName={interactiveName}
              activity={activity}
              toggleSettings={toggleSettings}
              autoReplyId={autoReplyId}
              startBroadcast={startBroadcast}
            />
            <div className="h-full">
              {isSettingsOpened && (
                <AutoReplySettings
                  chooseTypeRef={chooseTypeRef}
                  chooseAnotherTypeRef={chooseAnotherTypeRef}
                  startGuide={startGuide}
                  setStepIndex={setStepIndex}
                  interactiveInfoRef={interactiveInfoRef}
                  eventOptions={getEventOptions()}
                  startWords={startWords}
                  addStartWordValue={addStartWordValue}
                  deleteStartWordValue={deleteStartWordValue}
                  selectedEvent={selectedEvent}
                  changeAutoreplayEvent={changeAutoreplayEvent}
                  toggleSettings={toggleSettings}
                  autoReplyId={autoReplyId}
                  selectControlsRef={selectControlsRef}
                  okButtonRef={okButtonRef}
                />
              )}
              <AutoReplyBody
                startWord={startWords}
                editAutoreply={editAutoreply}
                autoReplyId={autoReplyId}
                template={template_message}
                controlsRef={controlsRef}
                setStepIndex={setStepIndex}
                newsTypeRef={newsTypeRef}
                chooseTypeRef={chooseTypeRef}
                contentViewRef={contentViewRef}
                greenRef={greenRef}
              />
            </div>
          </SessionModuleView>
        </>
      ) : (
        <InteracitveNotFoundView
          message={window.t('autoReplyDoesNotExist').replace('[id]', autoReplyId)}
        />
      )}
    </>
  );
}
