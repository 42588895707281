import { StatesSettingsView } from './StatesSettingsView';
import PropTypes from 'prop-types';
import React, { forwardRef, memo } from 'react';

import { useStatesButtons } from './hooks/useStatesButtons';

const propTypes = {
  sessionId: PropTypes.string,
  editRequest: PropTypes.func.isRequired,
  state: PropTypes.object
};

export const StatesSettingsContainer = memo(
  forwardRef(({ sessionId, editRequest, state, setStepIndex }, ref) => {
    const { statesState, addState, removeState, updatePropertyValue } = useStatesButtons({
      sessionId,
      editRequest,
      state
    });

    return (
      <StatesSettingsView
        ref={ref}
        statesState={statesState}
        addState={() => {
          setStepIndex((v) => ++v);
          addState();
        }}
        removeState={removeState}
        updatePropertyValue={updatePropertyValue}
      />
    );
  })
);
StatesSettingsContainer.propTypes = propTypes;
