import avatar from '../../../../assets/images/socials/channel-whatsapp.png';
import AddPhoneModal from './AddPhoneModal/AddPhoneModal';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  Avatar,
  BlurWrapper,
  Button,
  Icon,
  List,
  ListItem,
  ListItemText,
  Portal,
  SvgCheckbox,
  Text,
  Title
} from 'components';

import { getWhatsAppClients, postWhatsAppLogOut, toggleCheckboxState } from 'api/dataLayer';

const propTypes = {
  setDeleteCardData: PropTypes.func.isRequired,
  setIsDeleteCardActive: PropTypes.func.isRequired,
  setIsWhatsAppConnected: PropTypes.func.isRequired
};

const defaultProps = {
  setDeleteCardData: () => {},
  setIsDeleteCardActive: () => {},
  setIsWhatsAppConnected: () => {}
};

function WhatsAppCollapseBody({
  setDeleteCardData,
  setIsDeleteCardActive,
  setIsWhatsAppConnected,
  setNoteCount
}) {
  const [isModalActive, setIsModalActive] = useState(false);
  const [userInfo, setUserInfo] = useState([]);

  const updateGroups = async () => {
    try {
      const res = await getWhatsAppClients();
      const client = res?.body;
      const code = res?.code;

      if (!client) return;

      if (client.length && code === 200) {
        setUserInfo(
          Object.values(client).map((el) => ({
            groupId: el.groupId,
            image: el.image,
            pushname: el.pushname
          }))
        );
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  useEffect(() => {
    const fd = async () => {
      await updateGroups();
    };
    fd();
  }, []);

  useEffect(() => {
    setIsWhatsAppConnected(userInfo.length > 0);
  }, [userInfo]);

  const checkboxHandler = async (groupId, key, isChecked) => {
    try {
      await toggleCheckboxState({
        groupId,
        key,
        channel: 'whatsapp',
        isChecked: Number(isChecked)
      });
      setUserInfo((groups) =>
        groups.map((el) => (el.groupId === groupId ? { ...el, [key]: isChecked } : el))
      );
    } catch (ex) {
      console.error(ex);
    }
  };

  const deleteHandler = async () => {
    try {
      const res = await postWhatsAppLogOut();
      const body = res?.body;
      if (body === 'ok') {
        setUserInfo('');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ padding: '10px 0' }}>
      <Portal nodeId="work-pane-modals">
        <BlurWrapper
          isOpened={isModalActive}
          clickHandler={() => setIsModalActive(false)}
          position="absolute"
          background="#5a5a5a2e"
          className="d-flex justify-center align-center">
          {isModalActive && (
            <AddPhoneModal setIsModalActive={setIsModalActive} setUserInfo={setUserInfo} />
          )}
        </BlurWrapper>
      </Portal>

      <Title className="d-inline-block" weight="normal" style={{ marginLeft: 50 }}>
        {window.t('accounts')}
      </Title>

      <Text tag="span" color="#808080" size={14}>
        {` ${userInfo.length}`}
      </Text>

      {userInfo && (
        <List noStyle style={{ marginLeft: 50 }}>
          {userInfo.map(({ groupId, image, pushname, groupName, isComment, isMessage }) => (
            <ListItem key={groupId} noHover className="ml-0 mr-0 mt-2 mb-0 pa-0 flex-column">
              <div className="d-flex">
                {image ? (
                  <Avatar
                    alt="avatar"
                    src={image}
                    size={30}
                    className="flex-shrink-0 mr-2 align-self-center"
                  />
                ) : (
                  <Avatar
                    alt="avatar"
                    src={avatar}
                    size={30}
                    className="flex-shrink-0 mr-2 align-self-center"
                  />
                )}
                <ListItemText primary={groupId} />
                <Button
                  onlyIcon
                  startIcon={<Icon size={14} color="#CDCDCD" fontName="far fa-times" />}
                  variant="icon"
                  hoverColor="transparent"
                  style={{
                    opacity: 1
                  }}
                  clickHandler={() => deleteHandler(groupId)}
                />
                <div className="d-flex ml-auto">
                  {isComment !== null && isComment !== undefined && (
                    <SvgCheckbox
                      isChecked={Boolean(+isComment)}
                      changeHandler={(isChecked) =>
                        checkboxHandler(groupId, 'isComment', isChecked)
                      }
                      style={{
                        color: '#808080',
                        fontSize: 14,
                        marginLeft: 10
                      }}>
                      {window.t('comments')}
                    </SvgCheckbox>
                  )}
                  {isMessage !== null && isMessage !== undefined && (
                    <SvgCheckbox
                      isChecked={Boolean(+isMessage)}
                      changeHandler={(isChecked) =>
                        checkboxHandler(groupId, 'isMessage', isChecked)
                      }
                      style={{
                        color: '#808080',
                        fontSize: 14,
                        marginLeft: 10
                      }}>
                      {window.t('messages')}
                    </SvgCheckbox>
                  )}
                </div>
              </div>
              {/* {status === WHATSAPP_STATUS.LOADING ? <UseSessionState /> : <span />} */}
            </ListItem>
          ))}
        </List>
      )}
      {userInfo.length === 0 && (
        <Button
          clickHandler={() => {
            setIsModalActive(true);
          }}
          color="#fcfcfc"
          textColor="#757575"
          variant="google"
          size="medium"
          className="ml-auto mr-auto mt-5 mb-5"
          startIcon={<Icon icon="whatsapp" color="#2ED64C" />}>
          {window.t('connectNumber')}
        </Button>
      )}
    </div>
  );
}

export default WhatsAppCollapseBody;

WhatsAppCollapseBody.propTypes = propTypes;
WhatsAppCollapseBody.defaultProps = defaultProps;
