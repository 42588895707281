import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { forwardRef, memo, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import { PictureLoaderContainer } from 'containers';

import {
  AudioPlayer,
  Button,
  Card,
  Icon,
  Range,
  Select,
  SvgCheckbox,
  Textarea,
  Title
} from 'components';

import { PICTURE_LOADER_TYPES, VOICES_INTONATION_SP, VOICES_YANDEX_SP } from 'store/constants';

import { orderedFields } from 'helpers/content';
import { selectedOptionByValue } from 'helpers/interactives';

import FormatedTextPreview from './components/FormatedTextPreview/FormatedTextPreview';
import { TexareaLengthInfoView } from './components/TexareaLengthInfoView/TexareaLengthInfoView';

import styles from './AdvCardView.module.css';

const propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  removeBlock: PropTypes.func,
  updateBlockField: PropTypes.func,
  moveUp: PropTypes.func,
  moveDown: PropTypes.func,
  block: PropTypes.object,
  pictureChangeHandler: PropTypes.func,
  isImageUploading: PropTypes.bool,
  synthesisSpeech: PropTypes.func,
  isLoadingSynthesis: PropTypes.bool
};

export const AdvCardView = memo(
  forwardRef(
    (
      {
        order,
        isFirst,
        isLast,
        removeBlock,
        updateBlockField,
        moveUp,
        moveDown,
        block,
        pictureChangeHandler,
        isImageUploading,
        synthesisSpeech,
        isLoadingSynthesis,
        controls,
        inputRef,
        textToSpeechRef,
        audioMetaData
      },
      ref
    ) => {
      // const [isActive, setIsActive] = useState(true);
      const { isMinimized, isActive, isTouched } = block;

      const [isShowPreview, setIsShowPreview] = useState(false);
      const [isValidPreview, setIsValidPreview] = useState(true);
      const [descText, setDescText] = useState('');

      return (
        <div ref={ref}>
          <AnimateHeight duration={600} height={isMinimized ? 62 : 'auto'}>
            <Card
              className={classNames(styles['news-card'], {
                disabled: !isActive
              })}
              style={{ borderRadius: 2 }}>
              <div className="d-flex align-center justify-end">
                {order && <Title size={18}>№{order} </Title>}
                {isMinimized && (
                  <Title className="ml-2" size={18} color="#888">
                    {block?.caption?.value}
                  </Title>
                )}
                <Button
                  className="ml-auto"
                  startIcon={<Icon size={20} fontName="fal fa-chevron-up" />}
                  onlyIcon
                  variant="icon"
                  clickHandler={() => moveUp(block)}
                  style={{ padding: '6px 8px' }}
                  color={isFirst ? '#ccc' : 'primary'}
                  isDisabled={isFirst}
                />
                <Button
                  startIcon={<Icon size={20} fontName="fal fa-chevron-down" />}
                  onlyIcon
                  variant="icon"
                  clickHandler={() => moveDown(block)}
                  style={{ padding: '6px 8px' }}
                  color={isLast ? '#ccc' : 'primary'}
                  isDisabled={isLast}
                />
                <Button
                  startIcon={<Icon size={20} fontName="fal fa-eye" />}
                  onlyIcon
                  variant="icon"
                  clickHandler={() => updateBlockField(block.id, 'isMinimized', !isMinimized, null)}
                  style={{ padding: '6px 8px' }}
                  color={isMinimized ? '#ccc' : 'primary'}
                />
                <SvgCheckbox
                  isChecked={isActive}
                  changeHandler={() => updateBlockField(block.id, 'isActive', !isActive, null)}
                  className="mr-2"
                />
                <Button
                  startIcon={<Icon size={20} fontName="fal fa-times" />}
                  onlyIcon
                  variant="icon"
                  color="#ff0033"
                  clickHandler={() => removeBlock(block.id)}
                  // title={window.t('clearTitr')}
                  style={{ padding: '6px 8px' }}
                />
              </div>
              {orderedFields(block).map(([key, value], i) => {
                const maxLength = value && value['max_length#'];
                const length = value?.value?.length;

                switch (key) {
                  case 'note2':
                  case 'note1':
                    return (
                      <Textarea
                        isValid={!(length > maxLength || (length === 0 && isTouched))}
                        key={key}
                        text={value.value}
                        setText={(newVal) => updateBlockField(block.id, key, newVal)}
                        blurHandler={() => {
                          // request to update json on back
                        }}
                        afterLabel={
                          <TexareaLengthInfoView length={length} maxLength={Number(maxLength)} />
                        }
                        focusable={false}
                        label={window.t(key)}
                        style={{
                          border: '0.5px solid #D0D0D0',
                          borderRadius: 2,
                          padding: '8px 10px',
                          margin: 0
                        }}
                      />
                    );
                  case 'text':
                    return (
                      <>
                        <Textarea
                          isValid={
                            !(length > maxLength || (length === 0 && isTouched) || !isValidPreview)
                          }
                          key={key}
                          text={value.value}
                          setText={(newVal) => updateBlockField(block.id, key, newVal)}
                          blurHandler={() => {
                            // request to update json on back
                            setIsShowPreview(false);
                          }}
                          focusHandler={() => {
                            setDescText(value.value);
                            setIsShowPreview(true);
                          }}
                          changeHandler={(e) => {
                            setDescText(e.target.value);
                          }}
                          afterLabel={
                            <TexareaLengthInfoView length={length} maxLength={Number(maxLength)} />
                          }
                          focusable={false}
                          label={window.t(key)}
                          style={{
                            border: '0.5px solid #D0D0D0',
                            borderRadius: 2,
                            padding: '8px 10px',
                            margin: 0
                          }}
                        />
                      </>
                    );
                  case 'preview':
                    const font = value['type#'];
                    const [width, fontSize, fontWeight] = value['max_length#'].split('|');
                    return (
                      <>
                        <FormatedTextPreview
                          key={key}
                          text={descText}
                          font={font}
                          width={Number(width)}
                          fontSize={Number(fontSize)}
                          fontWeight={Number(fontWeight)}
                          isShow={isShowPreview}
                          setIsValidPreview={setIsValidPreview}
                          isValidPreview={isValidPreview}
                        />
                      </>
                    );
                  case 'text_to_speech':
                    return (
                      <Textarea
                        isValid={
                          !(
                            audioMetaData.duration > maxLength ||
                            (audioMetaData.duration === 0 && isTouched)
                          )
                        }
                        ref={textToSpeechRef}
                        inputRef={inputRef}
                        controls={controls}
                        key={key}
                        text={value.value}
                        setText={(newVal) => updateBlockField(block.id, key, newVal)}
                        focusable={false}
                        label={window.t('textToRead')}
                        style={{
                          border: '0.5px solid #D0D0D0',
                          borderRadius: 2,
                          padding: '8px 10px',
                          margin: 0
                        }}
                        afterLabel={
                          <TexareaLengthInfoView
                            type="time"
                            length={audioMetaData.duration}
                            maxLength={Number(maxLength)}
                          />
                        }>
                        <div className="d-flex align-center mt-2">
                          <Button
                            className="mr-2"
                            clickHandler={() => synthesisSpeech(block.id, key)}
                            isLoading={isLoadingSynthesis}>
                            {window.t('generateSpeech')}
                          </Button>
                          <AudioPlayer
                            className="flex-grow"
                            src={value.src}
                            style={{
                              margin: 0,
                              padding: '8px 0'
                            }}
                          />
                          <Button
                            startIcon={<Icon size={20} fontName="fal fa-times" />}
                            onlyIcon
                            variant="icon"
                            color="#ff0033"
                            clickHandler={() => updateBlockField(block.id, key, null, 'src')}
                            // title={window.t('clearTitr')}
                            style={{ padding: '6px 8px' }}
                          />
                        </div>
                        <div className={styles['audio-setting']}>
                          <Select
                            label={window.t('voiceList')}
                            options={VOICES_YANDEX_SP}
                            selectedOption={selectedOptionByValue(VOICES_YANDEX_SP, value.voice)}
                            setSelectedOption={(newVal) => {
                              updateBlockField(block.id, key, newVal.value, 'voice');
                              updateBlockField(block.id, key, '', 'emotion');
                            }}
                            width="100%"
                            style={{
                              borderBottom: '1px solid',
                              borderRadius: '3px 3px 0 0',
                              paddingLeft: '0px'
                            }}
                          />
                          {VOICES_INTONATION_SP[value.voice]?.length > 0 && (
                            <Select
                              label={window.t('voiceIntonation')}
                              options={VOICES_INTONATION_SP[value.voice]}
                              selectedOption={selectedOptionByValue(
                                VOICES_INTONATION_SP[value.voice],
                                value.emotion
                              )}
                              setSelectedOption={(newVal) =>
                                updateBlockField(block.id, key, newVal.value, 'emotion')
                              }
                              width="100%"
                              style={{
                                borderBottom: '1px solid',
                                borderRadius: '3px 3px 0 0',
                                paddingLeft: '0px'
                              }}
                            />
                          )}
                          <Range
                            minValue={0.1}
                            maxValue={3.0}
                            step={0.1}
                            value={value.speed}
                            title={`${window.t('voiceSpeed')}:`}
                            blurHandler={(newVal) =>
                              updateBlockField(block.id, key, newVal, 'speed')
                            }
                          />
                        </div>
                      </Textarea>
                    );
                  case 'adv_image1':
                    return (
                      <PictureLoaderContainer
                        className={styles.pictureLoader}
                        key={key}
                        type={PICTURE_LOADER_TYPES.showModal}
                        style={{
                          width: 180,
                          height: 100,
                          backgroundPosition: '50% 50%',
                          border: '0.5px solid #D0D0D0',
                          marginRight: '5px'
                        }}
                        picture={value.value}
                        changeHandler={(event) => pictureChangeHandler(event, block.id, key)}
                        deleteHandler={() => pictureChangeHandler(null, block.id, key)}
                        isImageUploading={isImageUploading}
                      />
                    );
                  case 'adv_image2':
                    return (
                      <PictureLoaderContainer
                        className={styles.pictureLoader}
                        key={key}
                        type={PICTURE_LOADER_TYPES.showModal}
                        style={{
                          width: 180,
                          height: 100,
                          backgroundPosition: '50% 50%',
                          border: '0.5px solid #D0D0D0',
                          marginRight: '5px'
                        }}
                        picture={value.value}
                        changeHandler={(event) => pictureChangeHandler(event, block.id, key)}
                        deleteHandler={() => pictureChangeHandler(null, block.id, key)}
                        isImageUploading={isImageUploading}
                      />
                    );
                  case 'adv_image3':
                    return (
                      <PictureLoaderContainer
                        className={styles.pictureLoader}
                        key={key}
                        type={PICTURE_LOADER_TYPES.showModal}
                        style={{
                          width: 180,
                          height: 100,
                          backgroundPosition: '50% 50%',
                          border: '0.5px solid #D0D0D0'
                        }}
                        picture={value.value}
                        changeHandler={(event) => pictureChangeHandler(event, block.id, key)}
                        deleteHandler={() => pictureChangeHandler(null, block.id, key)}
                        isImageUploading={isImageUploading}
                      />
                    );
                  default:
                    return null;
                }
              })}
            </Card>
          </AnimateHeight>
        </div>
      );
    }
  )
);

AdvCardView.propTypes = propTypes;
