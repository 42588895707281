export function ImgBox({ className, url, openMedia }) {
  return (
    <div
      className={className}
      onClick={() => {
        openMedia(url);
      }}>
      <img src={url} alt="" />
    </div>
  );
}
