import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';

import { useActions } from 'hooks';

import { copyInteractive } from 'api/dataLayer';

import { getSessionData } from 'helpers/interactives';
import { goTo } from 'helpers/routing';

export const useInteractiveSettings = ({ type, interactiveId }) => {
  const history = useHistory();
  const location = useLocation();
  const { addSnack, createSessionSuccess } = useActions();

  const copySession = useCallback(async () => {
    try {
      const response = await copyInteractive({
        type,
        iactive_id: interactiveId
      });

      const session = getSessionData(type, response);
      createSessionSuccess(session);
      goTo(
        history,
        `/${type}/${session.id}`,
        {
          ...session,
          isCreated: true
        },
        location
      );
      addSnack({
        type: 'success',
        message: window.t('sccInteractiveCopied')
      });
    } catch (ex) {
      addSnack({
        type: 'danger',
        message: window.t('errInteractiveCopied')
      });
    }
  }, [history, type, interactiveId, addSnack, createSessionSuccess]);

  return {
    copySession
  };
};
