import { INTERACTIVES, INTERACTIVE_ACTIVITIES } from '../constants';
import { addSnack } from './snackBox';

import {
  setISettingsInfoSuccess,
  setIsInteractiveExist,
  setOpenedInteractiveType,
  updateInfoField,
  updateSessionFromInfo
} from 'store/actions/isettings';
import { updateSessionInfo } from 'store/actions/sessions';
import { voteActions } from 'store/slices/voteSlice';

import {
  editBroadcast,
  editContent,
  editRegistration,
  editVote,
  getInteractiveById,
  updateInteractiveTitle as updateInteractiveTitleApi
} from 'api/dataLayer';

import { clearAndParseJSON, parseJSON } from 'helpers/text';

export function setISettingsInfoFromSession(id, type) {
  return async (dispatch) => {
    try {
      dispatch(voteActions.setVoteId({ id }));
      dispatch(setOpenedInteractiveType(type));
      const session = await getSessionObject(id, type);

      const {
        iactive_id,
        id_interactive,
        title,
        description,
        memo,
        settings,
        broadcastSettings,
        state,
        activity,
        broadcastActivity,
        rangeNumbers,
        content_rule,
        content_json,
        content_slot
      } = session;

      if (id_interactive) dispatch(voteActions.setVoteId({ id: id_interactive }));

      let parsedState = state ? clearAndParseJSON(state) : {};
      parsedState = parsedState
        ? Object.fromEntries(
            Object.entries(parsedState).map((el) => ({
              ...el,
              isRunned: Boolean(+el.active)
            }))
          )
        : parsedState;

      const parsedJson = parseJSON(content_json, {});

      dispatch(
        setISettingsInfo({
          ...session,
          id: id || iactive_id,
          type,
          title,
          description,
          notes: memo || '',
          settings:
            type === INTERACTIVES.vote || type === INTERACTIVES.quiz
              ? parseJSON(broadcastSettings, {})
              : parseJSON(settings, {}),
          broadcastSettings:
            type === INTERACTIVES.vote || type === INTERACTIVES.quiz
              ? parseJSON(broadcastSettings, {})
              : null,
          state: parsedState,
          activity:
            activity === '1' ? INTERACTIVE_ACTIVITIES.active : INTERACTIVE_ACTIVITIES.finished,
          broadcastActivity:
            broadcastActivity === '1'
              ? INTERACTIVE_ACTIVITIES.active
              : INTERACTIVE_ACTIVITIES.finished,
          rangeNumbers: parseJSON(rangeNumbers, []),
          content_rule: parseJSON(content_rule, {}),
          content_json: Array.isArray(parsedJson) ? parsedJson : [],
          content_slot
        })
      );
      const isVoteActive = session.activity === 'active';
      dispatch(voteActions.setVoteActivity({ isVoteActive }));
    } catch (ex) {
      if (ex === 'not_found') dispatch(setIsInteractiveExist(false));
      console.error(ex);
    }
  };
}

export function setISettingsInfo(info) {
  return (dispatch) => {
    dispatch(setISettingsInfoSuccess(info));
    dispatch(updateSessionInfo(info));
  };
}

export function getBroadcastInfoForVote(id) {
  return async (dispatch, getState) => {
    try {
      const res = await getInteractiveById({
        id,
        type: INTERACTIVES.broadcast
      });
      const data = res.body[0];
      if (!data) return;
      // updateInfoField('binded_interactive')
    } catch (ex) {
      console.error(ex);
    }
  };
}

async function getSessionObject(id, type) {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await getInteractiveById({ id, type });
      if (res.body === 'not_found' && res.code === 404) reject('not_found');
      else resolve(res.body[0]);
    } catch (ex) {
      console.error(ex);
      reject(ex);
      // dispatch(addSnack({
      //   type: 'danger',
      //   message: `Произошла ошибка при загрузке информации об интерактиве`,
      // }))
    }
  });
}

export function changeSessionInfo(field, value) {
  return async (dispatch, getState) => {
    let { info } = getState().isettings;
    const { id, type } = info;
    const updateMethod = getApiMethod(type);
    if (!updateMethod) return;

    try {
      await updateMethod({
        id,
        [field]: value
      });
      dispatch(
        updateSessionFromInfo({
          id,
          field,
          value
        })
      );

      // dispatch(updateISettingsInfo(info));
      // update sessions
    } catch (ex) {
      // get back changes
      const sessions = getState().sessions.sessions;
      const session = sessions.find((el) => el.id === id);
      info = {
        ...info,
        title: session.title,
        description: session.description,
        notes: session.memo
      };
      dispatch(setISettingsInfo(info));
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errChangingInteractiveInfo')
        })
      );
    }
  };
}

export function updateInteractiveTitle() {
  return async (dispatch, getState) => {
    const { info } = getState().isettings;
    const { id, interactiveName, type } = info;
    try {
      await updateInteractiveTitleApi({ id, interactiveName, type });
      dispatch(
        updateSessionFromInfo({
          id,
          field: 'title',
          value: interactiveName
        })
      );
      // dispatch(updateISettingsInfo(info));
      // update sessions
    } catch (ex) {
      // get back changes
      const sessions = getState().sessions.sessions;
      const session = sessions.find((el) => el.id === info.id);
      const updatedInfo = {
        ...info,
        interactiveName: session.interactiveName
      };
      dispatch(setISettingsInfo(updatedInfo));
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errChangingInteractiveInfo')
        })
      );
    }
  };
}

export function changeVisibility(newState) {
  return async (dispatch, getState) => {
    let info = { ...getState().isettings.info };

    const updateMethod = getApiMethod(info.type);
    try {
      const visibility = newState ? '1' : '0';
      const updatedInfo = { ...info, visibility };
      await updateMethod(updatedInfo);
      dispatch(
        updateSessionFromInfo({
          id: info.id,
          field: 'visibility',
          value: visibility
        })
      );
      // dispatch(setISettingsInfoSuccess(info));
      dispatch(updateInfoField('visibility', updatedInfo.visibility));
      // dispatch(updateISettingsInfo(info));
      // update sessions
    } catch (ex) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errChangingTitreVisibility')
        })
      );
    }
  };
}

function getApiMethod(type) {
  switch (type) {
    case INTERACTIVES.vote:
    case INTERACTIVES.quiz:
      return editVote;
    case INTERACTIVES.broadcast:
      return editBroadcast;
    case INTERACTIVES.content:
      return editContent;
    case INTERACTIVES.registration:
      return editRegistration;
    default:
      return null;
  }
}
