import {
  ADD_SNACK,
  RESET_SNACKS_TRANSITION,
  SET_SNACK_BOTTOM,
  SET_SNACK_HEIGHT,
  SET_SNACK_TRANSITION
} from './actionTypes';

export function addSnackSucces(snack) {
  return {
    type: ADD_SNACK,
    snack
  };
}

export function setSnackHeight(index, height) {
  return {
    type: SET_SNACK_HEIGHT,
    index,
    height
  };
}

export function setSnackBottom(index, bottom) {
  return {
    type: SET_SNACK_BOTTOM,
    index,
    bottom
  };
}

export function setSnackTransition(id, transition = '') {
  return {
    type: SET_SNACK_TRANSITION,
    id,
    transition
  };
}

export function resetSnacksTransition() {
  return {
    type: RESET_SNACKS_TRANSITION
  };
}
