import ProgressBar from './ProgressBar/ProgressBar';
import RatePicker from './RatePicker/RatePicker';
import SubControls from './SubControls/SubControls';
import Time from './Time/Time';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';

import { updateFeedItemAudio } from 'store/actions/feed';
import { addSnack } from 'store/thunks/snackBox';

import { requestApiPost } from 'api';

import styles from './AudioPlayer.module.css';

const propTypes = {
  messageId: PropTypes.string,
  src: PropTypes.string,
  style: PropTypes.object
};

class AudioPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.player = new Audio();
    this.player.src = props.src;
    this.player.loop = false;
    this.noPlayerSpeed = props.noPlayerSpeed;
    // if (settingsArray.noAutoload)
    // this.player.preload = 'metadata';
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.src !== this.props.src) {
      this.setState({
        pastTime: 0,
        pastTimePercent: 0,
        isPlaying: false
      });

      this.player.src = this.props.src;
    }
  }

  componentDidMount() {
    this.player.addEventListener('loadedmetadata', () => {
      this.setState({
        restTime: Math.round(this.player.duration)
      });
    });

    this.player.addEventListener('timeupdate', () => {
      const pastTime = Math.round(this.player.currentTime);
      const restTime = Math.round(this.player.duration - this.player.currentTime);
      const pastTimePercent = (this.player.currentTime * 100) / this.player.duration;

      if (pastTime && restTime && pastTimePercent)
        this.setState({
          restTime,
          pastTime,
          pastTimePercent
        });
    });

    this.player.onended = () => {
      this.setState({
        isPlaying: false,
        pastTime: 0,
        pastTimePercent: 0,
        restTime: Math.round(this.player.duration)
      });
      this.player.pause();
    };

    window.addEventListener('mouseup', this.mouseUpHandler);
  }

  upload = async (file) => {
    this.setState({
      isPlaying: false,
      pastTime: 0,
      pastTimePercent: 0,
      restTime: Math.round(this.player.duration)
    });
    this.player.pause();

    try {
      const result = await requestApiPost('api_upload_audio', {
        file,
        type: 'audio_msg',
        mid: this.props.messageId
      });
      this.props.updateMessageAudio(
        this.props.messageId,
        this.state.src,
        result.body,
        this.props.senderNumber
      );
      this.state.src = result.body;
    } catch (error) {
      console.log(error);
    }
  };

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.mouseUpHandler);
  }

  state = {
    src: this.props.src,
    isPlaying: false,
    restTime: 0,
    pastTime: 0,
    pastTimePercent: 0,
    timeMouseDown: false,
    volumeMouseDown: false,
    currentRate: 1,
    isRatePickerActive: false,
    isVolumeActive: false,
    volumePercent: 100
  };

  setTimeMouseDown = (bool) => {
    this.setState({
      timeMouseDown: bool
    });
  };

  mouseUpHandler = () => {
    this.setState({
      timeMouseDown: false,
      volumeMouseDown: false
    });
  };

  play = async () => {
    if (!this.props.src) {
      this.props.addSnack({
        type: 'danger',
        message: window.t('invalidAudioFile')
      });
      return;
    }

    if (this.player.paused) {
      try {
        await this.player.play();
        this.setState({
          isPlaying: true
        });
      } catch (ex) {
        this.props.addSnack({
          type: 'danger',
          message: window.t('invalidAudioFile')
        });
      }
    } else {
      this.player.pause();
      this.setState({
        isPlaying: false
      });
    }
  };

  setTimeMove = (event, timeProgressRef) => {
    if (this.state.timeMouseDown) this.setTime(event, timeProgressRef);
  };

  setTimeClick = (event, timeProgressRef) => {
    this.setTime(event, timeProgressRef);
  };

  setTime = (event, timeProgressRef) => {
    if (!this.props.src) return;

    const timeProgress = timeProgressRef.current;
    let timeProgressWidth = timeProgress.offsetWidth;

    const x = event.pageX ? event.pageX : event.clientX;

    let nowWidth = x - timeProgress.getBoundingClientRect().left;
    let pastTimePercent = (nowWidth * 100) / timeProgressWidth;
    pastTimePercent = pastTimePercent > 100 ? 100 : pastTimePercent < 0 ? 0 : pastTimePercent;

    this.setState({
      pastTimePercent
    });
    this.player.currentTime = (pastTimePercent * this.player.duration) / 100;
  };

  pickRate = (rate) => {
    this.player.playbackRate = rate;
    this.setState({
      currentRate: rate,
      isRatePickerActive: false
    });
  };

  setVolumeMouseDown = (bool) => {
    this.setState({
      volumeMouseDown: bool
    });
  };

  setVolumeMove = (event, volumeLineRef) => {
    if (this.state.volumeMouseDown) this.setVolume(event, volumeLineRef);
  };

  setVolumeClick = (event, volumeLineRef) => {
    this.setVolume(event, volumeLineRef);
  };

  setVolume(event, volumeLineRef) {
    let x;
    const volumeLineWidth = volumeLineRef.current.offsetWidth;

    if (event.pageX) {
      x = event.pageX;
    } else {
      x = event.clientX;
    }

    let nowWidth = x - volumeLineRef.current.getBoundingClientRect().left;
    let volumePercent = (nowWidth * 100) / volumeLineWidth;

    volumePercent = volumePercent > 100 ? 100 : volumePercent < 0 ? 0 : volumePercent;

    this.setState({
      volumePercent
    });

    this.player.volume = volumePercent / 100;
  }

  render() {
    return (
      <div className={`${styles.player} ${this.props.className}`} style={this.props.style}>
        <div className={styles.artist} style={{ display: 'none' }} />
        <div className={styles.title} style={{ display: 'none' }}>
          Голосовое сообщение
        </div>
        <div className={styles.controls}>
          <Button
            startIcon={
              <Icon size={18} fontName={`fa fa-${this.state.isPlaying ? 'pause' : 'play'}`} />
            }
            onlyIcon
            color="#524e4e"
            variant="message-action"
            clickHandler={this.play}
          />
          <Time type="past_time" time={this.state.pastTime} />
          <ProgressBar
            pastTimePercent={this.state.pastTimePercent}
            setTimeMouseDown={this.setTimeMouseDown}
            setTimeMove={this.setTimeMove}
            setTimeClick={this.setTimeClick}
          />
          <Time type="rest_time" time={this.state.restTime} />
          <RatePicker
            id={this.props.messageId || this.props.src}
            currentRate={this.state.currentRate}
            isRatePickerActive={this.state.isRatePickerActive}
            pickRate={this.pickRate}
          />
          {!this.noPlayerSpeed && (
            <Button
              color="#55606e"
              variant="message-action"
              clickHandler={() => {
                this.setState({
                  isRatePickerActive: true
                });
              }}
              className="pa-0 opacity-100 d-inline-flex min-w-unset ml-4 mr-4">
              {this.state.currentRate}x
            </Button>
          )}

          <SubControls
            upload={this.upload}
            setVolumeMouseDown={this.setVolumeMouseDown}
            volumePercent={this.state.volumePercent}
            setVolumeMove={this.setVolumeMove}
            setVolumeClick={this.setVolumeClick}
            url={this.state.src}
          />
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addSnack: (snack) => dispatch(addSnack(snack)),
    updateMessageAudio: (...args) => dispatch(updateFeedItemAudio(...args))
  };
}

export default connect(null, mapDispatchToProps)(AudioPlayer);

AudioPlayer.propTypes = propTypes;

AudioPlayer.defaultProps = {
  style: {},
  isExpanded: true
};
