import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  config: [], // все поля формы с отмеченными
  checkboxes: [], // только отмеченные поля для формы
  participants: [] // зарегистрированные участники
};

export const registrationSlise = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    setCheckboxes: (state, action) => {
      state.checkboxes = action.payload;
    },
    updateRegistration: (state, action) => {
      const { name, key, value } = action.payload;
      state.config.find((el) => el.name === name)[key] = value;
      state.checkboxes = state.config.filter((el) => el.shouldBe === true);
    },
    setParticipants: (state, action) => {
      state.participants = action.payload;
    },
    addParticipant: (state, action) => {
      let currentCard_id = state.participants.find(
        (el) => el.card_id === action.payload.card_id
      )?.card_id;
      if (currentCard_id) {
        state.participants = state.participants.map((el) => {
          if (el.card_id === currentCard_id) {
            return {
              ...action.payload,
              isHidden: el.isHidden
            };
          } else return el;
        });
      } else {
        state.participants = [...state.participants, { ...action.payload, isHidden: false }];
      }
    },
    deleteParticipant: (state, action) => {
      state.participants = state.participants.filter((el) => el.card_id !== action.payload.id);
    },
    editParticipantAuthorData: (state, action) => {
      const { card_id, field, value } = action.payload;
      state.participants = state.participants.map((el) => {
        if (el.card_id === card_id) {
          el.author_data[field] = value;
          return el;
        } else return el;
      });
    },
    setFilteredRegistration: (state, action) => {
      state.participants = state.participants.map((el) =>
        action.payload.includes(el.card_id) ? { ...el, isHidden: false } : { ...el, isHidden: true }
      );
    },
    resetRegistrationStore: (state, action) => {
      return { ...initialState };
    }
  }
});

export const registrationActions = registrationSlise.actions;
export const registrationReducer = registrationSlise.reducer;
