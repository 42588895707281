import PropTypes from 'prop-types';
import React from 'react';

import styles from './Text.module.css';

const propTypes = {
  tag: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string
};

const defaultProps = {
  tag: 'p',
  className: '',
  style: {},
  size: 14
};

function Text({ tag, children, className, style, color, size, title }) {
  const TagName = tag;
  return (
    <TagName
      className={`${styles['text']} ${className}`}
      style={{ ...style, color, fontSize: size }}
      title={title}>
      {children}
    </TagName>
  );
}

export default Text;

Text.propTypes = propTypes;
Text.defaultProps = defaultProps;
