import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Button, Icon } from 'components';

import useActions from 'hooks/useActions';

import { searchProfileBanned } from 'api/dataLayer';

import styles from './ToolbarControls.module.css';

const propTypes = {
  routeInfo: PropTypes.object.isRequired
};

const defaulProps = {
  routeInfo: {}
};

function ToolbarControls({ routeInfo, author }) {
  const { blockAuthorSuccess, blockAuthor, unblockAuthor, addSnack } = useActions();
  const { blockedId } = useSelector((state) => state.profileCard);

  const { channel, senderNumber } = routeInfo || {};

  const checkIfAuthorBanned = useCallback(
    async (senderNumber) => {
      try {
        const response = await searchProfileBanned(senderNumber);
        const target = response?.Authors?.find((item) => item.senderNumber === senderNumber);
        blockAuthorSuccess(+target?.id || null);
      } catch (error) {
        console.error(error);
      }
    },
    [blockAuthorSuccess]
  );

  useEffect(() => {
    if (!senderNumber) return;
    checkIfAuthorBanned(senderNumber);
  }, [senderNumber, checkIfAuthorBanned]);

  const authorActionHandler = useCallback(
    (type) => () => {
      if (author) {
        switch (type) {
          case 'block':
            blockAuthor(channel, senderNumber, author);
            break;
          case 'unblock':
            unblockAuthor(channel, senderNumber, author);
            break;
          default:
            throw new Error('unexpected type');
        }
      } else {
        addSnack({
          type: 'danger',
          message: window.t('createdAuthor')
        });
      }
    },
    [blockAuthor, unblockAuthor, channel, senderNumber, author, addSnack]
  );

  return (
    <div className={styles['main-chat-header-controls']}>
      {blockedId ? (
        <Button
          startIcon={<Icon size={16} fontName="fa fa-user-plus" />}
          variant="icon"
          color={author ? '#2e7d32' : '#aaaaaa'}
          clickHandler={authorActionHandler('unblock')}
          size="large"
          style={{ padding: '6px 8px', opacity: 1 }}>
          {window.t('unblock')}
        </Button>
      ) : (
        <Button
          startIcon={<Icon size={16} fontName="fa fa-user-times" />}
          variant="icon"
          color={author ? '#b71c1c' : '#aaaaaa'}
          size="large"
          clickHandler={authorActionHandler('block')}
          style={{ padding: '6px 8px', opacity: 1 }}>
          {window.t('block')}
        </Button>
      )}
    </div>
  );
}

export default ToolbarControls;

ToolbarControls.propTypes = propTypes;
ToolbarControls.defaulProps = defaulProps;
