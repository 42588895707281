import { useState } from 'react';
import { useSelector } from 'react-redux';

import { TABS } from 'containers/Feed/constants';

import { Button, TextField, Title } from 'components';

import { useActions, useInterval } from 'hooks';

import styles from './FillDataSettings.module.css';

const FillingFeedStatus = {
  unactive: 'unactive',
  started: 'started',
  finished: 'finished'
};

function FillDataSettings() {
  const { fetchMessagesSuccess, setItems } = useActions();
  const { currentTab, ...feedState } = useSelector((state) => state.feed);
  const messages = feedState[currentTab]?.items;
  const { scrollBoxRef } = useSelector((state) => state.scrollBox);

  const [tempData, setTempData] = useState(null);
  const [count, setCount] = useState('2');
  const [period, setPeriod] = useState('3');
  const [fillingStatus, setFillingStatus] = useState(FillingFeedStatus.unactive);
  const fillTheFeed = async () => {
    const response = await fetch('/TestData.json');
    const data = await response.json();
    setTempData(data);
    setFillingStatus(FillingFeedStatus.started);
  };

  const clearTheFeed = async () => {
    const response = await fetch('/TestData.json');
    const data = await response.json();
    const ids = data.map((el) => el.id);
    const filteredMessages = [...messages].filter((el) => !ids.includes(el.id));
    fetchMessagesSuccess(filteredMessages);
    setFillingStatus(FillingFeedStatus.unactive);
  };

  useInterval(
    () => {
      const messages = [...tempData];
      const toShow = messages.splice(0, count);
      setItems(toShow, scrollBoxRef, true, TABS.messages);
      setTempData(messages);
      if (messages.length === 0) setFillingStatus(FillingFeedStatus.finished);
    },
    tempData?.length > 0 ? period * 1000 : null
  );
  return (
    <div className={styles['wrapper']}>
      <h3 className={styles['ba__title']}>{window.t('fillTheFeed')}</h3>
      <div className="d-flex align-baseline">
        <TextField
          value={count}
          type="number"
          changeHandler={(event) => {
            setCount(event.target.value);
          }}
          className="pt-5 pb2 pl-5"
          inputHeight="31px"
          inputFontSize="14px"
          width={100}
          label={window.t('count')}
        />
        <TextField
          value={period}
          type="number"
          changeHandler={(event) => {
            setPeriod(event.target.value);
          }}
          className="pt-5 pb2 pl-5"
          inputHeight="31px"
          inputFontSize="14px"
          width={100}
          label={window.t('period')}
        />
        <Button
          className="ml-2"
          clickHandler={fillingStatus === FillingFeedStatus.finished ? clearTheFeed : fillTheFeed}
          isDisabled={count <= 0 || period <= 0 || fillingStatus === FillingFeedStatus.started}>
          {fillingStatus === FillingFeedStatus.finished ? window.t('clear') : window.t('start')}
        </Button>
      </div>
    </div>
  );
}
export default FillDataSettings;
