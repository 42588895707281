import { addHours } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import { List, ListItem, ListItemText } from 'components';

function DurationMenu({ startTime, hideEndMenu, setEndTime, togglePicker }) {
  const finishHandler = (hours) => {
    hideEndMenu();
    const endTime = addHours(new Date(startTime), hours);
    setEndTime(endTime);
  };

  const finishImmediatly = () => {
    hideEndMenu();
    setEndTime(new Date());
  };

  return (
    <List>
      <ListItem
        clickHandler={() => {
          finishHandler(1);
        }}>
        <ListItemText primary={window.t('finishOne')} />
      </ListItem>
      <ListItem
        clickHandler={() => {
          finishHandler(2);
        }}>
        <ListItemText primary={window.t('finishTwo')} />
      </ListItem>
      <ListItem
        clickHandler={() => {
          finishHandler(3);
        }}>
        <ListItemText primary={window.t('finishThree')} />
      </ListItem>
      <ListItem
        clickHandler={() => {
          finishHandler(4);
        }}>
        <ListItemText primary={window.t('finishFour')} />
      </ListItem>
      <ListItem clickHandler={finishImmediatly}>
        <ListItemText primary={window.t('finishImmediatly')} />
      </ListItem>
      <ListItem
        clickHandler={() => {
          hideEndMenu();
          togglePicker();
        }}>
        <ListItemText primary={window.t('select')} />
      </ListItem>
    </List>
  );
}

export default DurationMenu;

DurationMenu.propTypes = {
  startTime: PropTypes.string,
  hideEndMenu: PropTypes.func,
  setEndTime: PropTypes.func,
  togglePicker: PropTypes.func
};
