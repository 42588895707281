import { AddNewBlock } from '../AddNewBlock/AddNewBlock';
import PropTypes from 'prop-types';
import React, { forwardRef, memo } from 'react';

import { Button, Icon, Title } from 'components';

import { getUIDateFromDBDate } from 'helpers/dateTime';

const propTypes = {
  types: PropTypes.arrayOf(PropTypes.object),
  slots: PropTypes.arrayOf(PropTypes.object),
  selectedType: PropTypes.object,
  setSelectedType: PropTypes.func,
  selectedSlot: PropTypes.object,
  setSelectedSlot: PropTypes.func,
  addBlock: PropTypes.func,
  blocksLength: PropTypes.number,
  publishTime: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  publish: PropTypes.func
};

export const ContentControlsView = memo(
  forwardRef(
    (
      {
        blockTypes,
        selectedType,
        selectedSlot,
        publishTime,
        publish,
        addBlock,
        activeBlocksLength,
        needBlocksCount,
        setStepIndex,
        locale
      },
      ref
    ) => {
      const isTypeNotChoosed = selectedType === null;
      const isSlotSelected = Boolean(selectedSlot);
      const isAddDisabled = isTypeNotChoosed || !isSlotSelected; // || (isBlocksEnough && selectedSlot);
      const isBlocksFilled = activeBlocksLength !== needBlocksCount;
      const isPublishDisabled = !needBlocksCount
        ? false
        : needBlocksCount && (!isSlotSelected || isBlocksFilled);

      return (
        <div>
          <div className="d-flex flex-wrap align-center px-2 py-3">
            <Title color="#0088ee" className="ml-3" size={16}>
              {selectedType?.title || window.t('selectContentType')}
            </Title>
            <Title color="#0088ee" className="ml-5" size={16}>
              {selectedSlot?.title || window.t('selectSlot')}
            </Title>

            <AddNewBlock
              locale={locale}
              listItems={blockTypes}
              select={(type) => {
                if (isAddDisabled) return;
                addBlock(type);
                setTimeout(() => {
                  setStepIndex(5);
                }, 300);
              }}
              activator={
                <Button
                  ref={ref}
                  startIcon={<Icon size={20} fontName="fal fa-plus" />}
                  onlyIcon
                  variant="icon"
                  title={window.t('addNews')}
                  style={{ padding: '6px 8px' }}
                  className="ml-3"
                  isDisabled={isAddDisabled}
                  {...(isAddDisabled &&
                    ((isTypeNotChoosed && {
                      title: window.t('selectContentType')
                    }) ||
                      (!isSlotSelected && {
                        title: 'Выберите слот'
                      })))}
                />
              }
            />
            {activeBlocksLength ? (
              <Title size={15} className="ml-4">
                {window.t('totalBlocks')}: {activeBlocksLength}
              </Title>
            ) : null}

            {!isNaN(needBlocksCount) && !!+needBlocksCount && (
              <Title
                size={15}
                className="ml-4"
                color={activeBlocksLength > needBlocksCount ? 'red' : null}>
                {`${activeBlocksLength}/${needBlocksCount}`}
              </Title>
            )}

            <div className="ml-auto d-flex align-center">
              {publishTime && <Title>{getUIDateFromDBDate(publishTime)}</Title>}
              <Button
                className="ml-2"
                isDisabled={isPublishDisabled}
                clickHandler={publish}
                {...(isPublishDisabled && {
                  title: isBlocksFilled
                    ? window.t('mustFillContent').replace('[count]', needBlocksCount)
                    : window.t('slotMustSpecified')
                })}>
                {window.t('publish')}
              </Button>
            </div>
          </div>
        </div>
      );
    }
  )
);

ContentControlsView.propTypes = propTypes;
