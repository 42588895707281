import WelcomHeader from './WelcomeHeader/WelcomeHeader';
import WelcomeNav from './WelcomeNav/WelcomeNav';
import React from 'react';

// import CreateNew from './CreateNew/CreateNew';
// import AboutTech from './AboutTech/AboutTech';
// import WelcomeFooter from './WelcomeFooter/WelcomeFooter';
// import WhatsNew from './WhatsNew/WhatsNew';
import { SessionModuleView, Title } from 'components';

import styles from './Welcome.module.css';

export function Welcome() {
  return (
    <SessionModuleView>
      <div style={{ height: '100%' }}>
        <WelcomHeader />
        <WelcomeNav />
        <div className={styles['lucky-broadcast']}>
          <Title size="2.5vmax" weight="200">
            {window.t('luckyBroadcast')}
          </Title>
        </div>
        {/* <CreateNew />
        <AboutTech />
        <WhatsNew />
        <WelcomeFooter /> */}
      </div>
    </SessionModuleView>
  );
}
