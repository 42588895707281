import { useState } from 'react';
import { useSelector } from 'react-redux';

import useActions from 'hooks/useActions';

export const useDropArea = () => {
  const { addDraggingMessage } = useActions();
  const [isHovered, setIsHovered] = useState(false);
  const { currentTab, ...feedState } = useSelector((state) => state.feed);
  const draggingMessageId = feedState[currentTab]?.draggingMessageId;
  const isActive = !!draggingMessageId;

  const dragOver = (event) => {
    event.preventDefault();
  };

  const dragEnter = (event) => {
    event.preventDefault();
    setIsHovered(true);
  };

  const dragLeave = () => {
    setIsHovered(false);
  };

  const dragDrop = () => {
    addDraggingMessage();
  };

  return {
    isActive,
    isHovered,
    dragOver,
    dragEnter,
    dragLeave,
    dragDrop
  };
};
