import { SOCIAL_KEYS } from './constants';
import React, { forwardRef, memo } from 'react';

import { Button, Collapsible, CollaspibleHead, Input, Title } from 'components';

import { SocialVotesCollapseBodyView } from './components/SocialVotesCollapseBodyView/SocialVotesCollapseBodyView';

export const SocialVotesView = memo(
  forwardRef(
    ({ socialGroups, info, createPoll, url, setUrl, addOkPollRequest, voteId, bindPoll }, ref) => {
      return (
        <div ref={ref}>
          <Title weight="500" size={16}>
            {window.t('linkToPolls')}
          </Title>
          {Object.entries(socialGroups).length > 0 ? (
            Object.entries(socialGroups).map(([social, groups]) => {
              const bindedPollId = info[SOCIAL_KEYS[social]];
              const bindedPoll = groups.find((el) => el.postId === bindedPollId);
              return (
                <Collapsible
                  key={social}
                  wrapperClassName="mt-3 mb-3"
                  trigger={
                    <CollaspibleHead name={window.t(social)} channel={social}>
                      {bindedPollId && (
                        <div className="d-flex align-center ml-5">
                          <Title size={16} fontStyle="italic">
                            {bindedPoll.question}
                          </Title>
                          <Title className="ml-1 mt-05">
                            {' '}
                            ({window.t('variantsCount')}: {bindedPoll.pollVars.length})
                          </Title>
                          <Button
                            className="ml-3"
                            clickHandler={(evt) => createPoll(evt, bindedPollId, social)}>
                            {window.t('fillWording')}
                          </Button>
                        </div>
                      )}
                    </CollaspibleHead>
                  }>
                  {social === 'ok' && (
                    <div className="d-flex mt-2">
                      <Input
                        type="text"
                        value={url}
                        placeholder={window.t('enterButtonName')}
                        width={400}
                        changeHandler={(e) => setUrl(e.target.value)}
                        blurHandler={() => {}}
                      />
                      <Button className="ml-2" clickHandler={addOkPollRequest}>
                        {window.t('add')}
                      </Button>
                    </div>
                  )}
                  <SocialVotesCollapseBodyView
                    voteId={voteId}
                    socialName={social}
                    groups={groups}
                    bindPoll={bindPoll}
                    bindedPollId={bindedPollId}
                  />
                </Collapsible>
              );
            })
          ) : (
            <div className="d-flex justify-center py-5">
              <Title color="#7b7b7b82" size={20}>
                {window.t('noPollsAvailable')}
              </Title>
            </div>
          )}
          {/* <Collapsible wrapperClassName="mt-3 mb-3" trigger={<CollaspibleHead name={window.t('ok')} channel="ok"/>}>
                <VoteCollapseBody voteId={voteId} />
            </Collapsible> */}
        </div>
      );
    }
  )
);
