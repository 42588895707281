import DurationMenu from './DurationMenu/DurationMenu';
import StartMenu from './StartMenu/StartMenu';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Button, Icon, Menu } from 'components';

import { PULSE_STATES } from 'store/constants';

import { getFormattedBtnTime } from 'helpers/pulse';

import styles from './ToolbarControls.module.css';

const propTypes = {
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  toggleSettings: PropTypes.func.isRequired,
  activity: PropTypes.oneOf(Object.values(PULSE_STATES)),
  stopPulse: PropTypes.func.isRequired,
  startPulse: PropTypes.func.isRequired,
  toggleStartPicker: PropTypes.func.isRequired,
  setStartTime: PropTypes.func.isRequired,
  toggleEndPicker: PropTypes.func.isRequired,
  setEndTime: PropTypes.func.isRequired
};

function ToolbarControls({
  startTime,
  endTime,
  toggleSettings,
  activity,
  stopPulse,
  startPulse,
  toggleStartPicker,
  setStartTime,
  toggleEndPicker,
  setEndTime
}) {
  const [startMenuActivity, setStartMenuActivity] = useState(false);
  const [startMenuRect, setStartMenuRect] = useState(false);

  const startActivatorHandler = (e) => {
    if (e) {
      const rect = e.currentTarget.getBoundingClientRect();
      setStartMenuRect(rect);
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
    const newValue = !startMenuActivity;
    if (newValue) setDurationMenuActivity(false);
    setStartMenuActivity(newValue);
  };

  const startBtn = (
    <Button
      startIcon={<Icon size={16} fontName="far fa-calendar-alt" />}
      variant="icon"
      color="#000"
      size="large"
      clickHandler={startActivatorHandler}
      style={{ padding: '6px 8px' }}
      title={window.t('settings')}
      isDisabled={!startTime}>{`Старт с ${
      startTime ? getFormattedBtnTime(new Date(startTime)) : '...'
    }`}</Button>
  );

  const [durationMenuActivity, setDurationMenuActivity] = useState(false);
  const [durationMenuRect, setDurationMenuRect] = useState(false);

  const durationActivatorHandler = (e) => {
    if (e) {
      const rect = e.currentTarget.getBoundingClientRect();
      setDurationMenuRect(rect);
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
    const newValue = !durationMenuActivity;
    if (newValue) setStartMenuActivity(false);
    setDurationMenuActivity(newValue);
  };

  const durationBtn = (
    <Button
      startIcon={<Icon size={16} fontName="fas fa-hourglass-end" />}
      variant="icon"
      color="#000"
      size="large"
      clickHandler={durationActivatorHandler}
      style={{ padding: '6px 8px' }}
      title={window.t('settings')}>{`Финиш ${
      endTime ? getFormattedBtnTime(new Date(endTime)) : '...'
    }`}</Button>
  );

  return (
    <>
      <Button
        startIcon={<Icon size={20} fontName="fal fa-cog" />}
        onlyIcon
        variant="icon"
        color="#6f6f6f"
        clickHandler={toggleSettings}
        style={{ padding: '6px 8px' }}
        title={window.t('settings')}
      />
      {activity === PULSE_STATES.active ? (
        <Button
          startIcon={<Icon size={20} fontName="far fa-stop" />}
          clickHandler={stopPulse}
          onlyIcon
          variant="icon"
          color="#ff4d29"
          style={{ padding: '6px 8px' }}
          key="stop"
        />
      ) : (
        <Button
          startIcon={<Icon size={20} fontName="far fa-play" />}
          clickHandler={startPulse}
          onlyIcon
          variant="icon"
          color="#00bb00"
          style={{ padding: '6px 8px' }}
          key="start"
        />
      )}

      <div className={styles['menu-wrapper']}>
        <Menu
          activator={startBtn}
          activatorHandler={startActivatorHandler}
          isActive={startMenuActivity}
          left={startMenuRect ? startMenuRect.left : 0}
          top={startMenuRect ? startMenuRect.bottom : 0}>
          <StartMenu
            hideStartMenu={() => {
              setStartMenuActivity(false);
            }}
            togglePicker={toggleStartPicker}
            setStartTime={setStartTime}
          />
        </Menu>

        <Menu
          activator={durationBtn}
          activatorHandler={durationActivatorHandler}
          isActive={durationMenuActivity}
          left={durationMenuRect ? durationMenuRect.left : 0}
          top={durationMenuRect ? durationMenuRect.bottom : 0}>
          <DurationMenu
            hideEndMenu={() => {
              setDurationMenuActivity(false);
            }}
            togglePicker={toggleEndPicker}
            startTime={startTime}
            setEndTime={setEndTime}
          />
        </Menu>
      </div>
    </>
  );
}

export default ToolbarControls;

ToolbarControls.propTypes = propTypes;
