import PropTypes from 'prop-types';
import React from 'react';

import styles from './CardActions.module.css';

function CardActions({ children, className, style }) {
  return (
    <div style={style} className={`${styles['card-actions']} ${className}`}>
      {children}
    </div>
  );
}

export default CardActions;

CardActions.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  className: PropTypes.string,
  style: PropTypes.object
};

CardActions.defaultProps = {
  style: {},
  className: ''
};
