import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { Avatar, List, ListItem, ListItemText } from 'components';

import { getIconImage } from 'helpers/image';

import styles from './AddNewInteractiveMenu.module.css';

const propTypes = {
  interactives: PropTypes.array.isRequired,
  createSession: PropTypes.func,
  toggleAddInteractive: PropTypes.func
};

const defaulProps = {
  interactives: []
};

function AddNewInteractiveMenu({ interactives, createSession, toggleAddInteractive }) {
  const { enableInteractives } = useSelector((state) => state.sessions);

  return (
    <List className={styles['add-new-interactive']}>
      {interactives
        // TODO
        // .filter((el) => enableInteractives.includes(el.type))
        .map(({ type, title, description }, i) => (
          <ListItem
            key={type + i}
            clickHandler={() => {
              toggleAddInteractive();
              createSession(type);
            }}>
            <Avatar
              src={getIconImage(`module-${type}.png`)}
              size={48}
              rounded={false}
              className="flex-shrink-0 mr-2"
            />
            <ListItemText
              primary={window.t(title)}
              secondary={window.t(description)}
              primaryClassName="font-bold"
            />
          </ListItem>
        ))}
    </List>
  );
}

export default AddNewInteractiveMenu;

AddNewInteractiveMenu.propTypes = propTypes;
AddNewInteractiveMenu.defaulProps = defaulProps;
