import { Wind } from '../Wind/Wind';
import { Fragment } from 'react';

import { getWeatherIconUrl } from 'components/FeedItem/WeatherCard';

import styles from './Forecast.module.scss';

const getFormattedTemperatureRangeString = (min, max) => {
  if (min === max) {
    return `${min > 0 ? '+' : ''}${min}\u00B0`;
  } else {
    return `${min > 0 ? '+' : ''}${min}\u00B0...${max > 0 ? '+' : ''}${max}\u00B0`;
  }
};

const getFormattedTemperatureString = (t) => `${t > 0 ? '+' : ''}${t}\u00B0`;

export function Forecast({ forecast, time, isTableHead }) {
  const Container = isTableHead ? Fragment : 'tr';
  const Cell = isTableHead ? 'th' : 'td';

  return (
    <Container {...(!isTableHead && { className: styles.forecast })}>
      <Cell className={styles.forecast__temperature}>
        {time && <span className={styles.forecast__time}>{window.t(`weather_time_${time}`)}</span>}
        <span>
          {forecast.temp
            ? getFormattedTemperatureString(forecast.temp)
            : getFormattedTemperatureRangeString(forecast.temp_min, forecast.temp_max)}
        </span>
      </Cell>
      <Cell className={styles.forecast__iconContainer}>
        <img src={getWeatherIconUrl(forecast.icon)} alt="" />
      </Cell>
      {/* <p>{forecast.label}</p> */}
      <Cell colspan="2" className={styles.forecast__condition}>
        {window.t(`weather_condition_${forecast.condition}`)}
      </Cell>
      <Cell>{forecast.pressure_mm}</Cell>
      <Cell>{forecast.humidity}%</Cell>
      <Cell>
        <Wind speed={forecast.wind_speed} direction={forecast.wind_dir} />
      </Cell>
      <Cell>{getFormattedTemperatureString(forecast.feels_like)}</Cell>
    </Container>
  );
}
