import Tag from './Tag/Tag';
import classNames from 'classnames';
import PropsTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import EmojiPicker from 'components/EmojiPicker/EmojiPicker';

import styles from './TagsInput.module.css';

const propTypes = {
  isMultiWord: PropsTypes.bool,
  id: PropsTypes.string,
  label: PropsTypes.string,
  placeholder: PropsTypes.string,
  tags: PropsTypes.array,
  tagRegExp: PropsTypes.string,
  add: PropsTypes.func,
  showError: PropsTypes.func,
  remove: PropsTypes.func,
  onRepeat: PropsTypes.func
};
const defaultProps = {
  placeholder: '',
  tags: [],
  add: () => {},
  showError: () => {},
  remove: () => {},
  onRepeat: () => {}
};

function TagsInput({
  isMultiWord,
  id,
  className,
  label,
  placeholder,
  tags,
  tagRegExp,
  add,
  showError,
  remove,
  onRepeat,
  withEmoji,
  children,
  textField,
  keyField,
  withEdit,
  classNameField,
  disabledTextarea = false
}) {
  const tagsInputRef = useRef(null);
  const inputRef = useRef(null);
  const emojiRef = useRef(null);
  const blurRef = useRef(null);
  const [tagValue, setTagValue] = useState('');
  const KeyCodes = {
    comma: 188,
    enter: 13,
    backspace: 8,
    space: 32
  };

  const blurHandler = () => {
    if (withEmoji) return;
    if (tagValue?.trim()?.length > 0) addTag();
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        !tagsInputRef.current?.contains?.(event.target) &&
        !emojiRef.current?.contains?.(event.target) &&
        !blurRef.current?.contains?.(event.target) &&
        tagValue?.trim().length
      ) {
        addTag();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tagsInputRef, tagValue]);

  const changeHandler = (event) => {
    event.target.style.height = 'inherit';
    event.target.style.height = `${event.target.scrollHeight}px`;

    setTagValue(event.target.value);
  };

  const keydownHandler = (event) => {
    event.target.style.height = 'inherit';
    event.target.style.height = `${event.target.scrollHeight}px`;

    if (event.keyCode === KeyCodes.enter && event.shiftKey) {
      return;
    }

    switch (event.keyCode) {
      case KeyCodes.space:
        if (isMultiWord) {
          return;
        }
      case KeyCodes.enter:
        event.preventDefault();
        if (tagValue?.trim()?.length > 0) {
          addTag();
        }
        break;
      case KeyCodes.backspace:
        if (tagValue?.length === 0) {
          event.preventDefault();
          editTag(null, event);
        }
        break;
      default:
        return;
    }
  };
  const addTag = () => {
    if (tagRegExp) {
      const regExp = new RegExp(tagRegExp);
      if (!regExp.test(tagValue)) {
        showError();
        setTagValue('');
        return;
      }
    }

    if (
      tags.some((el) =>
        el?.additional_text !== undefined ? el?.additional_text === tagValue : el === tagValue
      )
    ) {
      onRepeat?.();
      return;
    }

    const value = tagValue;
    setTagValue('');
    add(value);
    inputRef.current.style.height = 'auto';
  };

  const editTag = (el, event) => {
    if (event && event.target) {
      inputRef.current.style.height = `${event.target.scrollHeight + 16}px`;
    }

    if (tagValue?.trim()?.length > 0) {
      addTag();
    }
    if (el) {
      const target = tags.find((item) => item === el);
      remove(textField ? target[textField] : target);
      setTagValue(textField ? target[textField] : target);
      inputRef.current?.focus?.();
    } else {
      const last = tags?.[tags?.length - 1];
      remove(textField ? last[textField] : last);
      setTagValue(textField ? last[textField] : last);
    }
  };

  const handleEmojiClick = (emoji) => {
    setTagValue((tag) => tag + emoji);
    inputRef.current?.focus?.();
  };

  return (
    <div className={`${styles['item']} ${className}`} style={styles}>
      <div className={styles['label']}>
        <label htmlFor={id}>{label}</label>
      </div>
      <div className={`${styles['edit-element']} ${styles['keywords']}`}>
        <div ref={tagsInputRef} className={classNames(styles['inputTag'], classNameField)}>
          {children}
          {tags.map((el) => (
            <Tag
              key={keyField ? el[keyField] : el}
              name={textField ? el[textField] : el}
              edit={(event) => withEdit && editTag(el, event)}
              remove={() => {
                remove(textField ? el[textField] : el, keyField && el[keyField]);
                inputRef.current?.focus?.();
              }}
            />
          ))}
          <textarea
            ref={inputRef}
            value={tagValue}
            placeholder={tags.length === 0 ? placeholder : ''}
            id={id}
            onKeyDown={keydownHandler}
            onChange={changeHandler}
            onBlur={blurHandler}
            type="text"
            className={classNames(styles['handlerTag'], {
              [styles.multiline]: isMultiWord
            })}
            disabled={disabledTextarea}
          />
          {withEmoji && (
            <EmojiPicker
              toggleHandler={() => {
                inputRef.current?.focus?.();
              }}
              blurRef={blurRef}
              emojiRef={emojiRef}
              className={styles['emojiPicker']}
              handleEmojiClick={handleEmojiClick}
              emoji={true}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default TagsInput;

TagsInput.propTypes = propTypes;
TagsInput.defaultProps = defaultProps;
