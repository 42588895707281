import { createContext } from 'react';

import { useMainGuide } from 'hooks/useMainGuide';

export const MainGuideContext = createContext(null);

export function MainGuideProvider({ children }) {
  const {
    run,
    startGuide,
    steps,
    stepIndex,
    setStepIndex,
    handleJoyrideCallback,
    newBtnRef,
    leftToolbarRef,
    interactivesBtn,
    interactiveToolbarRef,
    interactiveSearchBtnRef,
    interactiveSearchMenuRef
  } = useMainGuide({ type: 'interface' });

  return (
    <MainGuideContext.Provider
      value={{
        startGuide,
        steps,
        run,
        stepIndex,
        setStepIndex,
        handleJoyrideCallback,
        leftToolbarRef,
        newBtnRef,
        interactivesBtn,
        interactiveToolbarRef,
        interactiveSearchBtnRef,
        interactiveSearchMenuRef
      }}>
      {children}
    </MainGuideContext.Provider>
  );
}
