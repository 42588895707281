import { AdvCardView } from './AdvCardView';
import PropTypes from 'prop-types';
import React from 'react';

import { useAdvCard } from './hooks';
import { useAdvCardControls } from './hooks/useAdvCardControls';

const propTypes = {
  index: PropTypes.number,
  blocks: PropTypes.array,
  removeBlock: PropTypes.func,
  moveUp: PropTypes.func,
  moveDown: PropTypes.func,
  block: PropTypes.object
};

export function AdvBlock({
  order,
  contentId,
  isFirst,
  isLast,
  removeBlock,
  moveUp,
  moveDown,
  block,
  setStepIndex,
  newsBlockRef,
  textToSpeechRef
}) {
  const {
    inputRef,
    insertTags,
    updateBlockField,
    pictureChangeHandler,
    isImageUploading,
    synthesisSpeech,
    isLoadingSynthesis,
    audioMetaData
  } = useAdvCard({ contentId, block, setStepIndex });

  const controls = useAdvCardControls({ insertTags });

  return (
    <AdvCardView
      ref={newsBlockRef}
      textToSpeechRef={textToSpeechRef}
      order={order}
      isFirst={isFirst}
      isLast={isLast}
      key={block.id}
      block={block}
      moveUp={moveUp}
      moveDown={moveDown}
      updateBlockField={updateBlockField}
      removeBlock={removeBlock}
      pictureChangeHandler={pictureChangeHandler}
      isImageUploading={isImageUploading}
      synthesisSpeech={synthesisSpeech}
      isLoadingSynthesis={isLoadingSynthesis}
      controls={controls}
      inputRef={inputRef}
      audioMetaData={audioMetaData}
    />
  );
}

AdvBlock.propTypes = propTypes;
