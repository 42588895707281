import { VideoPlayer } from 'components';

export function VideoBox({ className, url, openMedia }) {
  const expandHandler = () => {
    openMedia(url);
  };

  return (
    <div className={className}>
      <VideoPlayer src={url} height="100%" isExpanded={false} expandHandler={expandHandler} />
    </div>
  );
}
