import { useCallback, useEffect, useMemo, useState } from 'react';

const useNumberForm = ({ initialValues, clear, change }) => {
  const { id, ...restValues } = initialValues;
  const [values, setValues] = useState(restValues);

  const hasChanged = useMemo(() => {
    return Object.entries(values).some(([key, value]) => value !== initialValues[key]);
  }, [values, initialValues]);

  useEffect(() => {
    if (!hasChanged) return;
    change({
      ...initialValues,
      ...values
    });
  }, [hasChanged, values, initialValues, change]);

  const isEmpty = useMemo(() => {
    return Object.values(values).every((value) => !value);
  }, [values]);

  const handleChange = useCallback((key, value) => {
    setValues((state) => ({
      ...state,
      [key]: value
    }));
  }, []);

  const handleClear = useCallback(() => {
    if (isEmpty) return;
    const resetValues = {
      num: '',
      aon: ''
    };
    setValues(resetValues);
    clear(initialValues.id);
  }, [clear, initialValues, isEmpty]);

  return {
    values,
    handleChange,
    handleClear
  };
};

export default useNumberForm;
