import React from 'react';
import Joyride from 'react-joyride';

import { VoteAlertsContainer, VoteControlsContainer } from 'containers';

import { Toolbar } from 'components';

import { INTERACTIVES } from 'store/constants';

import { getGuideLocale } from 'helpers/locale';

import { VoteBodyView, VoteSettingsView } from './components';

import styles from './VoteView.module.css';

export function VoteView({
  voteInfo,
  interactiveName,
  type,
  isInteractiveExist,
  interactiveId,
  isVoteActive,
  result,
  broadcastId,
  broadcastActivity,
  toggleSettings,
  visibility,
  isSettingsOpened,
  settings,
  state,
  activity,
  // guide props
  run,
  startGuide,
  steps,
  stepIndex,
  setStepIndex,
  handleJoyrideCallback,
  interactiveInfoRef,
  socialPollsRef,
  exportButtonsRef,
  statesSettingsRef,
  okButtonRef,
  questionRef,
  variantRef,
  voteControlsRef
}) {
  return (
    <div className={styles['session-module']}>
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={run}
        scrollToFirstStep
        showProgress
        steps={steps}
        stepIndex={stepIndex}
        locale={getGuideLocale()}
      />
      <Toolbar
        title={
          interactiveName || (type === INTERACTIVES.vote ? window.t('poll') : window.t('quiz'))
        }>
        {isInteractiveExist && (
          <VoteControlsContainer
            ref={voteControlsRef}
            type={type}
            voteId={interactiveId}
            isVoteActive={isVoteActive}
            result={result}
            broadcastId={broadcastId}
            broadcastActivity={broadcastActivity}
            settingsClickHandler={toggleSettings}
            visibility={visibility == '1'}
          />
        )}
      </Toolbar>
      <div className={styles['scroll-body']}>
        {isSettingsOpened && (
          <VoteSettingsView
            type={type}
            interactiveId={interactiveId}
            toggleSettings={toggleSettings}
            broadcastId={broadcastId}
            settings={settings}
            broadcastActivity={broadcastActivity}
            state={state}
            activity={activity}
            // guide props
            startGuide={startGuide}
            setStepIndex={setStepIndex}
            interactiveInfoRef={interactiveInfoRef}
            socialPollsRef={socialPollsRef}
            exportButtonsRef={exportButtonsRef}
            statesSettingsRef={statesSettingsRef}
            okButtonRef={okButtonRef}
          />
        )}
        <VoteAlertsContainer />
        <VoteBodyView
          voteInfo={voteInfo}
          type={type}
          voteId={interactiveId}
          isVoteActive={isVoteActive}
          isInteractiveExist={isInteractiveExist}
          broadcastId={voteInfo.broadcastId}
          // guide props
          questionRef={questionRef}
          variantRef={variantRef}
        />
      </div>
    </div>
  );
}
