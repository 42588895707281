import { useState } from 'react';

import { WEATHER_PARAMS } from 'containers/AutoReplyContainer/constants';

import { Button, Input, Menu, SvgCheckbox } from 'components';

import styles from './LocationButton.module.css';

export function LocationButton({ handleAdd, initialData = WEATHER_PARAMS }) {
  const [data, setData] = useState(initialData);
  const [isActive, setIsActive] = useState(false);
  const [btnRect, setBtnRect] = useState(null);
  const clickHandler = (e) => {
    if (e) {
      const rect = e.currentTarget.getBoundingClientRect();
      setBtnRect(rect);
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
    setIsActive(!isActive);
  };
  const activator = (
    <Button type="button" clickHandler={clickHandler} variant="text">
      {initialData.location || window.t('params')}
    </Button>
  );

  const addHandler = () => {
    handleAdd(data);
    setIsActive(false);
    // setData(WEATHER_PARAMS);
  };

  const locationHandler = (e) => {
    setData((prevData) => ({
      ...prevData,
      location: e.target.value
    }));
  };

  const checkHandler = (nameProp) => {
    setData((prevData) => ({
      ...prevData,
      [nameProp]: !prevData[nameProp]
    }));
  };

  return (
    <Menu
      activator={activator}
      isActive={isActive}
      activatorHandler={setIsActive}
      left={btnRect ? btnRect.left : 0}
      top={btnRect ? btnRect.bottom : 0}>
      <div className={styles.wrapper}>
        <Input
          placeholder={window.t('Location')}
          value={data.location}
          changeHandler={locationHandler}
        />
        {Object.entries(data).map(([key, value]) => {
          if (key === 'location') return null;

          return (
            <SvgCheckbox
              className={styles.checkBox}
              isChecked={value}
              changeHandler={() => checkHandler(key)}>
              {window.t(key)}
            </SvgCheckbox>
          );
        })}
        <Button type="button" isDisabled={!data.location} clickHandler={addHandler}>
          Save
        </Button>
      </div>
    </Menu>
  );
}
