import arrow from 'assets/images/icons/right-arrow-blue@2x.png';

export const IMAGES = {
  arrow
};

export const ResponseTypes = {
  quick: 'quick',
  message: 'message',
  like: 'like',
  positive: 'positive',
  negative: 'negative'
};

export const STATE_TYPES = {
  stop: 'stop',
  cycle: 'cycle'
};

export const INTERACTIVES = {
  vote: 'vote',
  quiz: 'quiz',
  broadcast: 'broadcast',
  pulse: 'pulse',
  content: 'content',
  autoReply: 'autoreply',
  registration: 'registration'
};

export const CONTENT_TYPES = {
  news: 'news',
  weather: 'weather',
  avito: 'avito',
  congratulations: 'congratulations'
};

export const MEDIA_TYPES = {
  image: 'image', // url
  audio_msg: 'audio_msg', // ogg
  video: 'video', // url
  string: 'string',
  speech: 'speech'
};

export const SOCIAL_DOMAINS = {
  ok: 'ok.ru',
  vk: 'vk.com'
};

export const NOTIFICATION_PERMISSIONS = {
  denied: 'denied',
  granted: 'granted',
  default: 'default'
};

export const PICTURE_LOADER_TYPES = {
  default: 'default',
  showModal: 'show-modal'
};

// Активность сессий интерактивов
export const ActivityTypesArray = ['created', 'active', 'paused', 'finished'];
export const INTERACTIVE_ACTIVITIES = {
  created: 'created',
  active: 'active',
  paused: 'paused',
  finished: 'finished'
};

export const RULES = {
  news: {
    'name#': 'Новости в 11',
    'type#': 'news',
    'start_time#': 'время в формате cron - потом доработаем и дни недели и т.п.',
    publish_time: 'unixtime чтобы знать обновилось или нет',
    'id#': '123456',
    duration: '100',
    'max_duration#': '120',
    'md5#': 'хеш этого json со всеми пустыми полями кроме имя которых заканчивается на # ',
    'blocksCount#': '5',
    content: [
      {
        order: '1',
        'name#': 'caption',
        'type#': 'string',
        'max_length#': '250'
      },
      {
        order: '2',
        'name#': 'text',
        'type#': 'string',
        'max_length#': '250'
      },
      {
        order: '3',
        'name#': 'text_to_speech',
        'type#': 'speech',
        'max_length#': '250'
      },
      {
        order: '4',
        'name#': 'news_image',
        'type#': 'image',
        'max_length#': '250'
      }
    ]
  },
  weather: {
    'name#': 'Новости в 11',
    'type#': 'news',
    'start_time#': 'время в формате cron - потом доработаем и дни недели и т.п.',
    publish_time: 'unixtime чтобы знать обновилось или нет',
    'id#': '123456',
    duration: '100',
    'max_duration#': '120',
    'md5#': 'хеш этого json со всеми пустыми полями кроме имя которых заканчивается на # ',
    'blocksCount#': '5',
    content: [
      {
        'name#': 'caption',
        'type#': 'string',
        'max_length#': '250'
      },
      {
        'name#': 'text',
        'type#': 'string',
        'max_length#': '250'
      },
      {
        'name#': 'text_to_speech',
        'type#': 'string',
        'max_length#': '250'
      },
      {
        'name#': 'location',
        'type#': 'string',
        'max_lenght#': '250'
      },
      {
        'name#': 'news_image',
        'type#': 'image',
        'max_length#': '250'
      },
      {
        'name#': 'news_audio',
        'type#': 'audio_msg',
        'max_length#': '250'
      }
    ]
  },
  congratulations: {
    'name#': 'Новости в 11',
    'type#': 'news',
    'start_time#': 'время в формате cron - потом доработаем и дни недели и т.п.',
    publish_time: 'unixtime чтобы знать обновилось или нет',
    'id#': '123456',
    duration: '100',
    'max_duration#': '120',
    'md5#': 'хеш этого json со всеми пустыми полями кроме имя которых заканчивается на # ',
    'blocksCount#': '5',
    content: [
      {
        'name#': 'caption',
        'type#': 'string',
        'max_length#': '250'
      },
      {
        'name#': 'text',
        'type#': 'string',
        'max_length#': '250'
      },
      {
        'name#': 'text_to_speech',
        'type#': 'string',
        'max_length#': '250'
      },
      {
        'name#': 'birthday',
        'type#': 'string',
        'max_lenght#': '250'
      },
      {
        'name#': 'news_image',
        'type#': 'image',
        'max_length#': '250'
      },
      {
        'name#': 'news_audio',
        'type#': 'audio_msg',
        'max_length#': '250'
      }
    ]
  },
  avito: {
    'name#': 'Новости в 11',
    'type#': 'news',
    'start_time#': 'время в формате cron - потом доработаем и дни недели и т.п.',
    publish_time: 'unixtime чтобы знать обновилось или нет',
    'id#': '123456',
    duration: '100',
    'max_duration#': '120',
    'md5#': 'хеш этого json со всеми пустыми полями кроме имя которых заканчивается на # ',
    'blocksCount#': '5',
    content: [
      {
        'name#': 'caption',
        'type#': 'string',
        'max_length#': '250'
      },
      {
        'name#': 'text',
        'type#': 'string',
        'max_length#': '250'
      },
      {
        'name#': 'text_to_speech',
        'type#': 'string',
        'max_length#': '250'
      },
      {
        'name#': 'price',
        'type#': 'string',
        'max_lenght#': '250'
      },
      {
        'name#': 'contacts',
        'type#': 'string',
        'max_lenght#': '250'
      },
      {
        'name#': 'news_image',
        'type#': 'image',
        'max_length#': '250'
      },
      {
        'name#': 'news_audio',
        'type#': 'audio_msg',
        'max_length#': '250'
      }
    ]
  }
};

// Типы модулей
export const ModuleTypesArray = ['vote', 'pull', 'quiz', 'broadcast', 'pulse'];
// Типы каналов
// из css
export const СhannelTypesArray = [
  'fb',
  'tlgr',
  'vk',
  'sms',
  'vbr',
  'live',
  'whatsapp',
  'smsmoney',
  'fbinstagram',
  'vkcomm',
  'fbcomm',
  'vkcomm',
  'ok',
  'alisa',
  'yt_live',
  'tlgr'
];
// из бд
export const СhannelNamesArray = [
  'facebook',
  'telegrambot',
  'vkgroup',
  'sms',
  'viber',
  'livechat',
  'whatsapp',
  'smsMoney',
  'fbinstagram',
  'vkcomm',
  'facebookcomments',
  'vkcomments',
  'ok',
  'alisa',
  'yt_live',
  'telegram'
];
// каналы для которых доступен чат
export const EnableChatChannels = ['vkcomm', 'whatsapp', 'viber'];

// pulse interactive

export const MIN_CHART_DURATION = 15; // кол-во минимальных минут графика

export const PULSE_STATES = {
  active: 'active',
  created: 'created',
  finished: 'finished'
};

export const RANGE_STATUS = {
  active: 'active',
  unActive: 'unActive',
  nextStart: 'nextStart'
};

export const DURATION_SELECT_OPTION_ARRAY = [
  {
    value: 60,
    innerText: 'Финиш через 1 час',
    class: 'enabled'
  },
  {
    value: 120,
    innerText: 'Финиш через 2 часа',
    class: 'enabled'
  },
  {
    value: 180,
    innerText: 'Финиш через 3 часа',
    class: 'enabled'
  },
  {
    value: 240,
    innerText: 'Финиш через 4 часа',
    class: 'enabled'
  },
  {
    value: -1,
    innerText: 'Финиш немедленно',
    class: 'enabled'
  }
];

export const DATE_PICKER_LOCALE = {
  // months list by order
  months: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
  ],

  // week days by order
  weekDays: [
    {
      name: 'Понедельник',
      short: 'ПН'
    },
    {
      name: 'Вторник',
      short: 'ВТ'
    },
    {
      name: 'Среда',
      short: 'СР'
    },
    {
      name: 'Четверг',
      short: 'ЧТ'
    },
    {
      name: 'Пятница',
      short: 'ПТ'
    },
    {
      name: 'Суббота',
      short: 'СБ',
      isWeekend: true
    },
    {
      name: 'Воскресенье', // used for accessibility
      short: 'ВС', // displayed at the top of days' rows
      isWeekend: true // is it a formal weekend or not?
    }
  ],

  // just play around with this number between 0 and 6
  weekStartingIndex: 6,

  // return a { year: number, month: number, day: number } object
  getToday(gregorainTodayObject) {
    return gregorainTodayObject;
  },

  // return a native JavaScript date here
  toNativeDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  },

  // return a number for date's month length
  getMonthLength(date) {
    return new Date(date.year, date.month, 0).getDate();
  },

  // return a transformed digit to your locale
  transformDigit(digit) {
    return digit;
  },

  // texts in the date picker
  nextMonth: 'След. месяц',
  previousMonth: 'Пред. месяц',
  openMonthSelector: 'Выбрать месяц',
  openYearSelector: 'Выбрать год',
  closeMonthSelector: 'Закрыть селектор месяца',
  closeYearSelector: 'Закрыть селектор года',
  defaultPlaceholder: 'Выбрать...',

  // for input range value
  from: 'от',
  to: 'до',

  // used for input value when multi dates are selected
  digitSeparator: ',',

  // if your provide -2 for example, year will be 2 digited
  yearLetterSkip: 0,

  // is your language rtl or ltr?
  isRtl: false
};

export const WEATHER_CONDITIONS = [
  { value: 'clear', title: 'Ясно' },
  { value: 'partly-cloudy', title: 'Малооблачно' },
  { value: 'cloudy', title: 'Облачно с прояснениями' },
  { value: 'overcast', title: 'Пасмурно' },
  { value: 'drizzle', title: 'Морось' },
  { value: 'light-rain', title: 'Небольшой дождь' },
  { value: 'rain', title: 'Дождь' },
  { value: 'moderate-rain', title: 'Умеренно сильный дождь' },
  { value: 'heavy-rain', title: 'Сильный дождь' },
  { value: 'continuous-heavy-rain', title: 'Длительный сильный дождь' },
  { value: 'showers', title: 'Ливень' },
  { value: 'wet-snow', title: 'Дождь со снегом' },
  { value: 'light-snow', title: 'Небольшой снег' },
  { value: 'snow', title: 'Снег' },
  { value: 'snow-showers', title: 'Снегопад' },
  { value: 'hail', title: 'Град' },
  { value: 'thunderstorm', title: 'Гроза' },
  { value: 'thunderstorm-with-rain', title: 'Дождь с грозой' },
  { value: 'thunderstorm-with-hail', title: 'Гроза с градом' }
];

export const WIND_DIRECTIONS = [
  { value: 's', title: 'Южное' },
  { value: 'n', title: 'Северное' },
  { value: 'w', title: 'Западное' },
  { value: 'e', title: 'Восточное' },
  { value: 'sw', title: 'Юго-западное' },
  { value: 'se', title: 'Юго-восточное' },
  { value: 'nw', title: 'Северо-западное' },
  { value: 'ne', title: 'Северо-восточное' },
  { value: 'c', title: 'Штиль' }
];

export const MAX_COUNT_STATE = 8;

export const VOICES_YANDEX_SP = [
  {
    value: 'alena',
    title: 'Alena'
  },
  {
    value: 'filipp',
    title: 'Filipp'
  },
  {
    value: 'jane',
    title: 'Jane'
  },
  {
    value: 'zahar',
    title: 'Zahar'
  }
];

export const VOICES_INTONATION_SP = {
  alena: [
    {
      value: '',
      title: 'Neutral'
    },
    {
      value: 'good',
      title: 'Good'
    }
  ],
  filipp: [
    {
      value: '',
      title: 'Neutral'
    }
  ],
  jane: [
    {
      value: '',
      title: 'Neutral'
    },
    {
      value: 'good',
      title: 'Good'
    },
    {
      value: 'evil',
      title: 'Evil'
    }
  ],
  zahar: [
    {
      value: '',
      title: 'Neutral'
    },
    {
      value: 'good',
      title: 'Good'
    }
  ]
};
// Максимально допустимое количество строк для AE
export const MAX_COUNT_LINES = 71;
