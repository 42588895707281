import useFetch from '../../../hooks/useFetch';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import useActions from 'hooks/useActions';

export const useRegistrationEvents = () => {
  const { deleteRegistrationImage, updateInfoField, changeSessionInfo, addTags, removeTags } =
    useActions();

  const { info } = useSelector((state) => state.isettings);
  let { registration_json, id } = info;

  const [{ response, isLoading, error }, doFetch] = useFetch('api_upload_registration_image');

  const pictureChangeHandler = useCallback(
    (evt, varnum = null) => {
      const file = evt.target.files[0];
      if (!file) return;

      file.isBinary = true;

      doFetch({
        method: 'POST',
        postBody: {
          attachment: file,
          registrationId: id
        }
      });
    },
    [id, doFetch]
  );

  useEffect(() => {
    if (!response) return;
    changeSessionInfo('event_image', response);
    updateInfoField('registration_json', { ...registration_json, event_image: response });
  }, [response, updateInfoField]);

  const deletePicture = useCallback(() => {
    deleteRegistrationImage(id);
  }, [id, deleteRegistrationImage]);

  const changeEventField = (field, value) => {
    updateInfoField('registration_json', { ...registration_json, [field]: value });
  };

  return {
    // возможно передавать объектом одним
    event_title: registration_json?.event_title || '',
    event_description: registration_json?.event_description || '',
    event_place: registration_json?.event_place || '',
    event_image: registration_json?.event_image || '',
    event_date: registration_json?.event_date || '',
    changeSessionInfo,
    pictureChangeHandler,
    deletePicture,
    isLoading,
    changeEventField,
    addTags,
    removeTags
  };
};
