import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import styles from './MarkersView.module.css';

const propTypes = {
  labels: PropTypes.array,
  colors: PropTypes.array,
  toggleHandler: PropTypes.func,
  clearHandler: PropTypes.func
};

const defaultProps = {
  labels: []
};

export const MarkersView = memo(({ colors, labels, toggleHandler, clearHandler }) => {
  const classes = classNames(styles['labels-item'], {
    [styles.disabled]: labels.length <= 0
  });

  function Marker({ color, isSelected }) {
    const classes = classNames(styles[`label-${color}`], {
      [styles.selected]: isSelected
    });
    return (
      <li
        onClick={(e) => {
          toggleHandler(e, color, isSelected);
        }}
        className={styles['label']}>
        <span className={classes} />
      </li>
    );
  }

  return (
    <>
      <div className={`${styles['labels-item']} ${styles['disabled']}`}>{window.t('markers')}</div>
      {colors.map((el, i) => {
        return <Marker key={el + i} color={el} isSelected={labels.includes(el)} />;
      })}
      <div onClick={clearHandler} className={classes}>
        {window.t('clearAll')}
      </div>
    </>
  );
});

MarkersView.propTypes = propTypes;
MarkersView.defaultProps = defaultProps;
