import AddGroupCardModal from '../AddGroupCardModal/AddGroupCardModal';
import { PhoneForm } from './PhoneForm';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Select } from 'components';

const propTypes = {
  setGroups: PropTypes.func
};

const defaultProps = {
  setGroups: () => {}
};

function AddPhoneModal({
  closeHandler,
  setGroups,
  phone,
  setPhone,
  connectId,
  isAuthed,
  setIsAuthed,
  password,
  setPassword,
  code,
  setCode
}) {
  const options = [
    {
      value: 'phone',
      title: window.t('phoneNumber')
    },
    {
      value: 'bot',
      title: window.t('bot')
    }
  ];
  const [selectedType, setSelectedType] = useState(options[0]);

  return (
    <AddGroupCardModal
      title={window.t('connectTelegramBot')}
      closeHandler={closeHandler}
      headerColor="#419fd9"
      style={{
        maxWidth: 810,
        width: '90%'
      }}>
      <Select
        label={window.t('selectContentType')}
        options={options}
        selectedOption={selectedType}
        setSelectedOption={setSelectedType}
        width={220}
        className="mb-2"
        color="primary"
        style={{
          borderBottom: '1px solid',
          borderRadius: '3px 3px 0 0'
        }}
      />
      <PhoneForm
        connectId={connectId}
        setGroups={setGroups}
        phone={phone}
        setPhone={setPhone}
        isAuthed={isAuthed}
        setIsAuthed={setIsAuthed}
        password={password}
        setPassword={setPassword}
        code={code}
        setCode={setCode}
        type={selectedType?.value}
        closeHandler={closeHandler}
      />
    </AddGroupCardModal>
  );
}

export default AddPhoneModal;

AddPhoneModal.propTypes = propTypes;
AddPhoneModal.defaultProps = defaultProps;
