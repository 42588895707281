import { useCallback, useEffect, useMemo, useState } from 'react';

import { useLocalStorage } from 'hooks';

import { isValdidPhone } from 'helpers/phone';

const MAX_NUMBERS_COUNT = 15;

const useVotingNumbers = (setIsVotingNumbersConnected) => {
  const [votingNumbers, setVotingNumbers] = useLocalStorage('votingNumbers', []);
  const [groups, setGroups] = useState(
    new Array(MAX_NUMBERS_COUNT).fill(null).map((_, i) => ({
      ...(votingNumbers?.find((num) => num.id === i + 1) || {
        id: i + 1,
        num: '',
        aon: ''
      })
    }))
  );
  const [values, setValues] = useState(groups);
  const [errors, setErrors] = useState(
    groups.map((item) => ({
      num: null,
      aon: null
    }))
  );
  const [rowsCount, setRowsCount] = useState(votingNumbers.length >= 2 ? votingNumbers.length : 2);

  useEffect(() => {
    const connectedNumbers = groups.filter((item) => item.aon && item.num);
    setIsVotingNumbersConnected(!!connectedNumbers.length);
    setVotingNumbers(connectedNumbers.map((item, i) => ({ ...item, id: i + 1 })));
  }, [groups]);

  const totalNumbersCount = useMemo(
    () => groups.filter((item) => item.aon && item.num).length,
    [groups]
  ); // ???

  const handleChange = useCallback((newNumber) => {
    setValues((state) => state.map((item) => (item.id !== newNumber.id ? item : newNumber)));
  }, []);

  const handleRemoveNumber = useCallback((id) => {
    setValues((state) =>
      state.map((item) =>
        item.id !== id
          ? item
          : {
              ...item,
              num: '',
              aon: ''
            }
      )
    );
    setErrors((state) =>
      state.map((errorObj, i) => {
        if (!values[i].aon && !values[i].num)
          return {
            ...errorObj,
            num: null,
            aon: null
          };
        return errorObj;
      })
    );
  }, []);

  const addForm = useCallback(() => {
    setRowsCount((state) => (state < 15 ? state + 1 : state));
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      let isValid = true;
      setErrors((state) =>
        state.map((errorObj, i) => {
          if (!values[i].aon && !values[i].num)
            return {
              ...errorObj,
              num: null,
              aon: null
            };
          const isValidAON = isValdidPhone(values[i].aon);
          const isValidNum = values[i].num.match(/[\d\s-_]+/);
          if (!isValidAON || !isValidNum) isValid = false;
          return {
            ...errorObj,
            num: isValidNum ? null : window.t('invalidPhone'),
            aon: isValidAON ? null : window.t('invalidPhone')
          };
        })
      );
      if (!isValid) return;
      setGroups(values);
    },
    [values]
  );

  const labels = useMemo(() => {
    return {
      num: window.t('numberForVisualization'),
      aon: window.t('numberForAON')
    };
  }, []);

  return {
    handleSubmit,
    totalNumbersCount,
    labels,
    rowsCount,
    values,
    errors,
    handleChange,
    addForm,
    handleRemoveNumber
  };
};

export default useVotingNumbers;
