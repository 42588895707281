import {
  addSnackSucces,
  resetSnacksTransition,
  setSnackBottom,
  setSnackTransition
} from 'store/actions/snackBox';

export function addSnack(snack) {
  return (dispatch, getState) => {
    const { snacks } = getState().snackBox;
    const length = snacks.length;
    dispatch(resetSnacksTransition());
    snack.id = `s${length + 1}`; // Symbol();
    snack.transition = 'all 200ms ease 0s';
    snack.timeout = 5000; // 4000 + length * 3000;
    // snack.canDelete = false;
    // snack.shouldDelete = false;

    dispatch(addSnackSucces(snack));
  };
}

export function removeSnack(id) {
  return (dispatch, getState) => {
    const snacks = getState().snackBox.snacks;
    const deletedIndex = snacks.findIndex((el) => el.id === id);
    const deletedHeight = snacks[deletedIndex].height;

    snacks.forEach((el, i) => {
      if (i < deletedIndex) dispatch(setSnackBottom(i, el.bottom - (deletedHeight + 10)));
      else dispatch(setSnackTransition(el.id));
    });
    setTimeout(() => {
      dispatch(resetSnacksTransition());
    });
  };
}
