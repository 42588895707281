import TreeSelect from 'rc-tree-select';
import { useMemo } from 'react';

import { AudioPlayer, SvgCheckbox } from 'components';

import { isLeaf } from 'helpers/content';

import styles from './AudioPicker.module.css';
import 'rc-tree-select/assets/index.css';

export function AudioPicker({
  blockId,
  backgroundAudioEffects,
  block,
  selected,
  updateBlockField,
  locale,
  initialAudioFiles
}) {
  const audioEffects = useMemo(
    () =>
      backgroundAudioEffects?.map((el) => ({
        type: el.value,
        label: el.label?.[locale] || el.label,
        isActive: block?.background_audio?.value?.effects?.includes(el.value)
      })),
    [backgroundAudioEffects, block]
  );

  const handleAudioEffectChange = (effect) => (newValue) => {
    const newAudioEffects = audioEffects.map((item) =>
      item.type !== effect.type ? item : { ...item, isActive: newValue }
    );

    if (newAudioEffects) {
      updateBlockField(blockId, 'background_audio', {
        ...selected,
        effects: newAudioEffects.filter((item) => item.isActive).map((item) => item.type)
      });
    }
  };

  const handleBackgroundAudioChange = (...args) => {
    const url = args[0];
    const fileName = args[1][0];

    if (isLeaf(url, initialAudioFiles)) {
      updateBlockField(blockId, 'background_audio', {
        ...selected,
        fileName,
        url
      });
    }
  };

  return (
    <div>
      {initialAudioFiles && (
        <div className={styles['audio']}>
          <div className={styles['audio__track']}>
            <div className={styles['audio__label']}>{window.t('backgroundAudio')}:</div>
            <TreeSelect
              dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
              style={{ width: 300 }}
              showSearch={false}
              treeLine
              placeholder={window.t('backgroundAudio')}
              value={selected?.url}
              treeData={initialAudioFiles}
              treeNodeFilterProp="label"
              filterTreeNode={false}
              onChange={handleBackgroundAudioChange}
            />
          </div>
          {selected?.url && (
            <AudioPlayer
              className="flex-grow"
              src={selected?.url}
              style={{
                margin: 0,
                padding: '4px 0'
              }}
            />
          )}
          {audioEffects && (
            <div className={styles['effects']}>
              {audioEffects.map((effect) => (
                <SvgCheckbox
                  key={effect.type}
                  isDisabled={!effect.isActive && !selected.url}
                  className={styles['effects__item']}
                  isChecked={effect.isActive}
                  changeHandler={handleAudioEffectChange(effect)}>
                  {effect.type}
                </SvgCheckbox>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
