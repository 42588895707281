import Picker, { EmojiStyle } from 'emoji-picker-react';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import { Button, Icon, Menu } from 'components';

import { getIconImage } from 'helpers/image';

import styles from './EmojiPicker.module.css';

function EmojiPicker({
  emojiStateHandler,
  toggleHandler,
  blurRef,
  emojiRef,
  offset = 0,
  className,
  handleEmojiClick
}) {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const wasOpen = useRef(false);
  const [btnRect, setBtnRect] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    emojiStateHandler?.(isPickerOpen);
  }, [isPickerOpen]);

  useEffect(() => {
    if (wasOpen.current) return;
    wasOpen.current = isPickerOpen;
  }, [isPickerOpen]);

  useEffect(() => {
    if (!wasOpen.current) return;
    toggleHandler?.();
  }, [isPickerOpen]);

  useEffect(() => {
    const listener = (event) => {
      if (event.key === 'Enter') {
        setIsPickerOpen(false);
      }
    };
    document.addEventListener('keydown', listener);

    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    return () => {
      document.removeEventListener('keydown', listener);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleEmojiPicker = useCallback((e) => {
    if (e) {
      const rect = e.currentTarget.getBoundingClientRect();
      setBtnRect(rect);
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
    setIsPickerOpen((state) => !state);
  }, []);

  const onEmojiClick = useCallback(
    (event) => {
      if (event) {
        handleEmojiClick(event.emoji);
      }
    },
    [handleEmojiClick]
  );

  const activator = (
    <Button
      startIcon={
        <img
          style={{
            display: 'block',
            height: 30,
            width: 30,
            objectFit: 'contain'
          }}
          src={isPickerOpen ? getIconImage('cancel.svg') : getIconImage('emoji.png')}
          alt=""
        />
      }
      tag="div"
      variant="icon"
      onlyIcon
      color="#524e4e"
      clickHandler={toggleEmojiPicker}
    />
  );

  // 450 это ширина окна со смайликами. Если отступ + ширина окна со смайликами больше, чем ширина window, то это учитывается
  let left;
  const settings = btnRect?.left + (offset || 0);

  if (settings + 450 > windowWidth) {
    left = windowWidth - 350;
  } else {
    left = settings;
  }

  return (
    <div className={className}>
      <Menu
        blurRef={blurRef}
        activator={activator}
        isActive={isPickerOpen}
        activatorHandler={toggleEmojiPicker}
        left={btnRect ? left : 0}
        top={btnRect ? btnRect.bottom : 0}>
        <div ref={emojiRef} className={styles.pickerContainer}>
          {isPickerOpen && <Picker onEmojiClick={onEmojiClick} emojiStyle={EmojiStyle.GOOGLE} />}
        </div>
      </Menu>
    </div>
  );
}

export default memo(EmojiPicker);
