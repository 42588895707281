const { AudioPlayer } = require('components');

export function AudioBox({ className, messageId, url, openMedia }) {
  const expandHandler = () => {
    openMedia(url);
  };
  return (
    <div className={className}>
      <AudioPlayer
        messageId={messageId}
        src={url}
        style={{
          margin: 0,
          padding: '5px'
        }}
        height="100%"
        expandHandler={expandHandler}
      />
    </div>
  );
}
