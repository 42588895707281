import { InputEdit } from './InputEdit/InputEdit';

import { InteractiveSettingsContainer, RegistrationEventsContainer } from 'containers';

import { Button, Collapsible, Icon, TagsInput, Title } from 'components';

import styles from './RegistrationSettingsView.module.css';

export function RegistrationSettingsView({
  saveSettings,
  config,
  interactiveId,
  updateRegistration,
  activity,
  handlePublish,
  tags
}) {
  return (
    <InteractiveSettingsContainer
      onCloseHandler={saveSettings}
      interactiveId={interactiveId}
      noCopy={true}
      withGuide={false}
      activity={activity}
      handlePublish={handlePublish}>
      <div>
        <RegistrationEventsContainer tags={tags} />

        <Collapsible
          style={{ marginBottom: '20px' }}
          trigger={
            <Title color="#808080" size={16}>
              {window.t('formFields')}
            </Title>
          }
          triggerStyle={{ paddingLeft: 0 }}>
          {/* список а не таблица, для возможности смены порядка полей перетаскиванием */}
          {config?.length !== 0 && (
            <ul className={styles.list}>
              <li>
                <span />
                <span>{window.t('nameFields')}</span>
                <span>{window.t('required')}</span>
                {/* <span>Тип поля</span> */}
              </li>
              {config?.map((el) => (
                <InputEdit
                  updateRegistration={updateRegistration}
                  key={el.label}
                  label={el.label}
                  name={el.name}
                  // value={el.label}
                  value={window.t(el.name)}
                  isRequired={el.isRequired}
                  // isHidden={el.isHidden}
                  shouldBe={el.shouldBe}
                  type={el.type}
                  options={el.options}
                  // updateValue={(newValue) => updateValue(key, newValue)}
                  // removeHandler={() => removeButton(key)}
                  // blurHandler={() => updateRequest(settingsState)}
                />
              ))}
            </ul>
          )}
        </Collapsible>
      </div>
    </InteractiveSettingsContainer>
  );
}
