import { ClearTitreMenuView } from './ClearTitreMenuView';
import React, { memo } from 'react';

import { useClearTitreMenu } from './hooks/useClearTitreMenu';

export const ClearTitreMenuContainer = memo(({ exportButtons, toggleClearMenu, broadcastId }) => {
  const { clearTitrHandler } = useClearTitreMenu({
    toggleClearMenu,
    broadcastId
  });

  return <ClearTitreMenuView exportButtons={exportButtons} clearTitrHandler={clearTitrHandler} />;
});
