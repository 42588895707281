import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import Menu2 from 'components/Menu2/Menu2';
import NoteCount from 'components/NoteCount/NoteCount';

import useActions from 'hooks/useActions';

const propTypes = {
  title: PropTypes.string,
  activeButtons: PropTypes.array
};

const defaultProps = {
  title: '',
  activeButtons: []
};

function MultipleMenu({ title, activeButtons }) {
  const { removeExportedFromFeed } = useActions();
  const [isActive, setIsActive] = useState(false);
  const [btnRect, setBtnRect] = useState(null);
  const clickHandler = (e) => {
    if (e) {
      const rect = e.currentTarget.getBoundingClientRect();
      setBtnRect(rect);
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
    setIsActive(!isActive);
  };

  const activator = (
    <Button
      isActive
      clickHandler={clickHandler}
      style={{
        fontSize: 12
      }}>
      {title}
      <NoteCount count={activeButtons.length} />
    </Button>
  );

  return (
    <Menu2
      activator={activator}
      isActive={isActive}
      activatorHandler={setIsActive}
      btnRect={btnRect}
      vertical="top"
      horizontal="center"
      verticalAdd={-10}>
      <Card
        style={{
          padding: 5,
          width: 100,
          boxShadow: '0px 0px 4px rgb(0 0 0 / 20%)'
        }}>
        {activeButtons.map(({ id, titr_tag, broadcastMessageId }, i) => (
          <Button
            key={titr_tag}
            isActive
            clickHandler={() => removeExportedFromFeed(id, titr_tag, broadcastMessageId)}
            style={{
              fontSize: 12,
              marginTop: i === 0 ? 0 : 10,
              width: '100%'
            }}>
            {`${activeButtons.length - i}.${title}`}
          </Button>
        ))}
      </Card>
    </Menu2>
  );
}

export default MultipleMenu;

MultipleMenu.propTypes = propTypes;
MultipleMenu.defaultProps = defaultProps;
