import { memo, useCallback } from 'react';

import { Button, Icon, Input, Text } from 'components';

function VotingNumberInput({ label, updateValue, value, removeHandler, error }) {
  const changeHandler = useCallback(
    (event) => {
      updateValue(event.target.value);
    },
    [updateValue]
  );

  return (
    <div className="d-flex mb-4 align-center">
      {label && (
        <Text size={15} className="mr-1" style={{ minWidth: 90 }}>
          <span>{label}</span>
        </Text>
      )}
      <Input
        error={error || ''}
        type="text"
        value={value}
        width={200}
        changeHandler={changeHandler}
      />
      {removeHandler && (
        <Button
          onlyIcon
          startIcon={<Icon fontName="fal fa-times-circle" size={18} />}
          variant="icon"
          color="#CDCDCD"
          className="ml-1"
          style={{
            borderRadius: '50%',
            padding: 0
          }}
          clickHandler={removeHandler}
          tag="div"
        />
      )}
    </div>
  );
}

export default memo(VotingNumberInput);
