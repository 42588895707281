import { Button, Icon } from 'components';

export const useToolbarContentControls = ({ settingsClickHandler }) => {
  const buttons = [
    {
      titleKey: 'settings',
      classes: ['tb-button', 'common-data'],
      icon: 'fal fa-cog',
      color: '#6f6f6f',
      isSelected: false,
      isDisabled: false,
      clickHandler: settingsClickHandler
    }
    // {
    //   titleKey: 'addMessage',
    //   classes: ['tb-button', 'create'],
    //   icon: 'fal fa-plus',
    //   isSelected: false,
    //   isDisabled: false,
    //   clickHandler: () => {
    //   },
    // },
  ];

  const controls = [
    ...buttons.map((el, i) => {
      return (
        <Button
          startIcon={<Icon size={20} fontName={el.icon} />}
          onlyIcon
          variant="icon"
          {...(el.color ? { color: el.color } : {})}
          clickHandler={el.clickHandler}
          title={window.t(el.titleKey)}
          key={`btn${i}`}
          style={{ padding: '6px 8px' }}
        />
      );
    })
  ];

  return {
    controls
  };
};
