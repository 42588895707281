export function adjust(color, amount) {
  return `#${color
    .replace(/^#/, '')
    .replace(/../g, (color) =>
      `0${Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)}`.substr(-2)
    )}`;
}
export function setProperty(node, key, value) {
  node.style.setProperty(`--${key}`, value);
}

export function convertHexToRGBA(hexCode, opacity) {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
}

export function getButtonColor(color) {
  switch (color) {
    case 'primary':
      return '#0088ee';
    case 'success':
      return '#0eca2d';
    case 'danger':
      return '#de4646';
    case 'warning':
      return '#ffa000';
    default:
      return color;
  }
}
