import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { useActions, useFetch, useLocalStorage } from 'hooks';

import { getRouteInfo } from 'helpers/profile';

const userInfoOption = { dataKey: null };

export const useChat = (match) => {
  const { fetchChatMessagesSuccess, updateHasMoreNext, updateHasMorePrevious } = useActions();
  const { senderNumber, channel } = getRouteInfo(match.params.id);
  const [hasMoreNext, setHasMoreNext] = useState(false);
  const [hasMorePrevious, setHasMorePrevious] = useState(false);
  const [profileMessage] = useLocalStorage('profileMessage', null);
  const messages = useSelector((state) => {
    return state.chat.messages;
  });
  const isSendingMessage = useSelector((state) => {
    return state.chat.isSendingMessage;
  });
  const [userInfo, setUserInfo] = useState({});
  const [routeInfo, setRouteInfo] = useState({});
  const [
    { response: responseUserInfo, isLoading: isLoadingUserInfo, error: errorUserInfo },
    doFetchUserInfo
  ] = useFetch('api_get_profile_user_info', userInfoOption);

  const messagesOption = useMemo(
    () => ({
      method: 'POST',
      dataKey: null,
      postBody: {
        channel,
        mesId: profileMessage?.id || '99999999',
        position: ''
      }
    }),
    [senderNumber, channel, profileMessage?.id]
  );

  messagesOption.getParams = {
    id: senderNumber
  };

  const [{ response: responseMessages, isLoading, error: errorMessages }, doFetchMessages] =
    useFetch('api_get_chat_messages', messagesOption);
  const [
    { response: responseMessagesNext, isLoading: isLoadingNext, error: errorMessagesNext },
    doFetchMessagesNext
  ] = useFetch('api_get_chat_messages', messagesOption);
  const [
    {
      response: responseMessagesPrevious,
      isLoading: isLoadingPrevious,
      error: errorMessagesPrevious
    },
    doFetchMessagesPrevious
  ] = useFetch('api_get_chat_messages', messagesOption);

  useEffect(() => {
    updateHasMoreNext(hasMoreNext);
  }, [hasMoreNext]);

  useEffect(() => {
    updateHasMorePrevious(hasMorePrevious);
  }, [hasMorePrevious]);

  useEffect(() => {
    setRouteInfo({ senderNumber, channel });
    fetchChatMessagesSuccess([]);
    if (profileMessage?.isCreated === '1') {
      fetchChatMessagesSuccess([profileMessage]);
      setHasMoreNext(false);
      setHasMorePrevious(false);
      const { region, date } = profileMessage;

      setUserInfo((userInfo) => ({
        ...userInfo,
        region,
        firstMessage: date,
        lastMessage: date
      }));
      return;
    }

    doFetchUserInfo({
      method: 'POST',
      postBody: {
        action: 'getUserInfo',
        identifier: senderNumber,
        channel
      }
    });
    doFetchMessages(messagesOption);
    return () => {
      fetchChatMessagesSuccess([]);
      updateHasMoreNext(true);
      updateHasMorePrevious(true);
      setUserInfo({});
      // uncomment to activate infinite load on scroll
      // setHasMoreNext(true);
      // setHasMorePrevious(true);
    };
  }, [
    channel,
    senderNumber,
    messagesOption,
    doFetchMessages,
    doFetchUserInfo,
    fetchChatMessagesSuccess,
    updateHasMoreNext,
    updateHasMorePrevious
  ]);

  useEffect(() => {
    if (!responseUserInfo) return;

    setUserInfo((info) => ({ ...info, ...responseUserInfo }));
  }, [responseUserInfo]);

  useEffect(() => {
    if (errorMessages || responseMessages === 'no messages') {
      setHasMoreNext(false);
      setHasMorePrevious(false);
      return;
    }

    if (!responseMessages) return;

    let newMessages = responseMessages.body;
    newMessages = newMessages?.map((el) =>
      el.attachments && !Array.isArray(el.attachments)
        ? { ...el, attachments: JSON.parse(el.attachments) }
        : el
    );

    if (!Array.isArray(newMessages)) return;

    fetchChatMessagesSuccess([...messages, ...newMessages]);
    if (!userInfo.author) {
      setUserInfo((info) => ({
        ...info,
        author: newMessages[0]?.author || ''
      }));
    }
  }, [responseMessages, errorMessages, fetchChatMessagesSuccess]);

  useEffect(() => {
    if (errorMessagesNext || responseMessagesNext === 'no messages') {
      setHasMoreNext(false);
      return;
    }

    if (!responseMessagesNext) return;

    let newMessages = responseMessagesNext.body;
    newMessages = newMessages?.map((el) =>
      el.attachments && !Array.isArray(el.attachments)
        ? { ...el, attachments: JSON.parse(el.attachments) }
        : el
    );

    if (!Array.isArray(newMessages)) return;

    const tail = messages.slice(-newMessages.length);
    newMessages = newMessages.filter(
      (el) => !tail.find((item) => (item.id ? item.id === el.id : item.ID === el.ID))
    );

    if (!newMessages.length) {
      setHasMoreNext(false);
      return;
    }

    fetchChatMessagesSuccess([...messages, ...newMessages]);
    if (!userInfo.author) setUserInfo((info) => ({ ...info, author: newMessages[0].author }));
  }, [responseMessagesNext, errorMessagesNext, fetchChatMessagesSuccess]);

  useEffect(() => {
    if (errorMessagesPrevious || responseMessagesPrevious === 'no messages') {
      setHasMorePrevious(false);
      return;
    }

    if (!responseMessagesPrevious) return;

    let newMessages = responseMessagesPrevious.body;
    newMessages = newMessages?.map((el) =>
      el.attachments && !Array.isArray(el.attachments)
        ? { ...el, attachments: JSON.parse(el.attachments) }
        : el
    );

    if (!Array.isArray(newMessages)) return;

    const head = messages.slice(0, newMessages.length);
    newMessages = newMessages.filter(
      (el) => !head.find((item) => (item.id ? item.id === el.id : item.ID === el.ID))
    );

    if (!newMessages.length) {
      setHasMorePrevious(false);
      return;
    }

    fetchChatMessagesSuccess([...newMessages, ...messages]);
    if (!userInfo.author) setUserInfo((info) => ({ ...info, author: newMessages[0].author }));
  }, [responseMessagesPrevious, errorMessagesPrevious, fetchChatMessagesSuccess]);

  const loadMoreNext = () => {
    if (hasMoreNext && !isLoadingNext) {
      doFetchMessagesNext({
        method: 'POST',
        dataKey: null,
        getParams: {
          id: senderNumber
        },
        postBody: {
          channel,
          mesId: messages.reduce((res, el) => el.ID || res, null),
          position: 'next'
        }
      });
    }
  };
  const loadMorePrevious = () => {
    if (hasMorePrevious && !isLoadingPrevious) {
      doFetchMessagesPrevious({
        method: 'POST',
        dataKey: null,
        getParams: {
          id: senderNumber
        },
        postBody: {
          channel,
          mesId: messages.find((el) => el.ID)?.ID,
          position: 'previous'
        }
      });
    }
  };

  const author = useMemo(() => {
    if (messages && messages.length > 0)
      return messages.reduce((res, el) => (el.ID ? el : res), null)?.author;
    return;
  }, [messages]);

  const setMessages = (cb) => fetchChatMessagesSuccess(cb(messages));

  return {
    routeInfo,
    userInfo,
    author,
    messages,
    setMessages,
    loadMoreNext,
    hasMoreNext,
    isLoadingNext,
    loadMorePrevious,
    hasMorePrevious,
    isLoadingPrevious,
    isSendingMessage
  };
};
