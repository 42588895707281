import PropTypes from 'prop-types';
import React from 'react';

import BlurWrapper from 'components/BlurWrapper/BlurWrapper';

import styles from './Dialog.module.css';

function Dialog({ isActive, toggleDialog, children }) {
  return (
    <BlurWrapper isOpened={isActive} clickHandler={toggleDialog}>
      <div className={styles['dialog']}>{children}</div>
    </BlurWrapper>
  );
}

export default Dialog;

Dialog.propTypes = {
  isActive: PropTypes.bool,
  toggleDialog: PropTypes.func.isRequired,
  children: PropTypes.element
};

Dialog.defaultProps = {
  isActive: false
};
