export function getImageByChannel(channel, image = null) {
  if (
    image &&
    (image.toString().indexOf('http://') !== -1 || image.toString().indexOf('https://') !== -1)
  )
    return image;

  const images = require.context('assets/images/socials/', true);

  try {
    return images(`./channel-${channel.trim()}.png`);
  } catch {
    return images('./channel-none.png');
  }
}

export function getIconImage(image) {
  const images = require.context('assets/images/icons/', true);

  try {
    return images(`./${image}`);
  } catch {
    return images('./delete@2x.png');
  }
}

export function getSocialsImage(image) {
  const images = require.context('assets/images/socials/', true);

  try {
    return images(`./${image}`);
  } catch {
    return images('./delete@2x.png');
  }
}
