import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button } from 'components';

import useActions from 'hooks/useActions';

import { getDiff, getObjectFromArray } from 'helpers/object';

import styles from './BroadcastActions.module.css';

const propTypes = {
  id: PropTypes.string,
  broadcastConfig: PropTypes.object,
  inLeftTitr: PropTypes.string,
  inRightTitr: PropTypes.string,
  contentLength: PropTypes.number,
  titr_tag: PropTypes.array
};

function BroadcastActions({ id, messageFeedId, contentLength, titr_tag }) {
  const { exportToTitr, delFromTitr } = useActions();
  const { broadcastConfig } = useSelector((state) => state.settings);
  const { info } = useSelector((state) => state.isettings);
  const { isExportAvailable, isCharCounterAvailable, charAmount } = broadcastConfig;
  const { settings, iactive_id, broadcastId } = info;
  const [activeButtons, setActiveButtons] = useState([]);
  const [availableButtons, setAvailableButtons] = useState({});
  // const [shouldRemoveButtons, setShouldRemoveButtons] = useState([]);

  useEffect(() => {
    if (!settings && typeof settings !== 'object') return;
    // костыль, чтобы убрать null,1
    const filtered =
      titr_tag && Array.isArray(titr_tag)
        ? titr_tag.filter((el) => typeof el === 'string' && el.includes('F'))
        : [];
    setActiveButtons(filtered);

    // кнопки, которые активны, но были удалены
    const activeButtonsObject = getObjectFromArray(
      titr_tag?.filter((el) => el && el !== 'undefined') || []
    );

    const diff = getDiff(activeButtonsObject, settings);

    // setShouldRemoveButtons(Object.keys(diff))
    setAvailableButtons({ ...settings, ...diff });
  }, [titr_tag, settings]);

  const clickHandler = (id, messageFeedId, key) => {
    if (activeButtons.includes(key)) delFromTitr(id, messageFeedId, key, iactive_id || broadcastId);
    else exportToTitr(id, messageFeedId, key, iactive_id || broadcastId);
  };

  return (
    <div className={`${styles['action-export']} d-inline-flex`}>
      {isCharCounterAvailable && (
        <div
          className={classNames(styles['char-counter'], {
            [styles['warning']]: contentLength > charAmount
          })}>
          {window.t('symbols').toLowerCase()}: {contentLength} {window.t('from').toLowerCase()}{' '}
          {charAmount}
        </div>
      )}
      {isExportAvailable && (
        <>
          {Object.entries(availableButtons).map(([key, value], i) => (
            <Button
              key={key}
              isActive={activeButtons.includes(key)}
              clickHandler={() => clickHandler(id, messageFeedId, key)}
              style={{
                marginLeft: i === 0 ? 0 : 10,
                fontSize: 12
              }}>
              {value || key}
            </Button>
          ))}
        </>
      )}
    </div>
  );
}

export default BroadcastActions;

BroadcastActions.propTypes = propTypes;
