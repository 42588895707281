import className from 'classnames';
import PropTypes from 'prop-types';
import React, { forwardRef, memo } from 'react';

import { BroadcastControlsContainer } from 'containers';

import { Toolbar } from 'components';

import styles from './BroadcastToolbarView.module.css';

const propTypes = {
  interactiveName: PropTypes.string,
  isBroadcastStateFixed: PropTypes.bool,
  toggleSettings: PropTypes.func,
  toggleMessageCreator: PropTypes.func,
  visibility: PropTypes.string,
  activity: PropTypes.string,
  broadcastId: PropTypes.string,
  exportButtons: PropTypes.object
};

export const BroadcastToolbarView = memo(
  forwardRef(
    (
      {
        interactiveName,
        isBroadcastStateFixed,
        toggleSettings,
        toggleMessageCreator,
        visibility,
        activity,
        broadcastId,
        exportButtons,
        startButtonRef,
        addClearRef,
        setStepIndex
      },
      ref
    ) => {
      return (
        <div>
          <Toolbar
            title={interactiveName || window.t('broadcast')}
            className={className({
              [styles['toolbar--fixed']]: isBroadcastStateFixed
            })}>
            <BroadcastControlsContainer
              settingsClickHandler={toggleSettings}
              msgCreatorClickHandler={toggleMessageCreator}
              visibility={visibility === '1'}
              activity={activity}
              broadcastId={broadcastId}
              exportButtons={exportButtons}
              startButtonRef={startButtonRef}
              addClearRef={addClearRef}
              setStepIndex={setStepIndex}
            />
          </Toolbar>
        </div>
      );
    }
  )
);
BroadcastToolbarView.propTypes = propTypes;
