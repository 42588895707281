import useNumberForm from '../hooks/useNumberForm';
import VotingNumberInput from './VotingNumberInput';
import PropTypes from 'prop-types';
import { memo } from 'react';

import styles from '../styles.module.css';

const propTypes = {
  change: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};

const defaultProps = {
  change: () => {},
  clear: () => {},
  values: {}
};

function VotingNumberForm({ change, clear, values: initialValues, errors, label }) {
  const { values, handleChange, handleClear } = useNumberForm({
    change,
    clear,
    initialValues
  });

  return (
    <div className={styles.group}>
      <div className={styles.group__fieldsContainer}>
        <VotingNumberInput
          error={errors.num}
          label={label}
          value={values.num}
          updateValue={(newValue) => handleChange('num', newValue)}
        />
        <VotingNumberInput
          error={errors.aon}
          value={values.aon}
          updateValue={(newValue) => handleChange('aon', newValue)}
          removeHandler={handleClear}
        />
      </div>
    </div>
  );
}

VotingNumberForm.propTypes = propTypes;
VotingNumberForm.defaultProps = defaultProps;

export default memo(VotingNumberForm);
