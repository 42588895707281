import React, { useEffect, useRef } from 'react';
import sanitizeHtml from 'sanitize-html';

import { getFormattedHtml } from 'components/TextEditor/helpers';

import styles from './FormattedTextPreview.module.css';

function FormattedTextPreview({
  label,
  text,
  font,
  width,
  fontSize,
  fontWeight,
  fontStyle,
  isUpperCase,
  isShow,
  setIsValidPreview,
  isValidPreview
}) {
  const contentRef = useRef();

  const isOverflowed = (el) => {
    return el.scrollWidth > el.offsetWidth;
  };

  useEffect(() => {
    if (isValidPreview !== !isOverflowed(contentRef.current)) {
      setIsValidPreview(!isOverflowed(contentRef.current));
    }
  }, [text, isValidPreview, setIsValidPreview]);

  const zoomPreview = 0.35;

  return (
    <div className={styles.wrapper} style={isShow ? { maxHeight: '500px' } : null}>
      <div className={styles.head}>{label}</div>
      <div
        ref={contentRef}
        className={styles.content}
        style={{
          fontWeight,
          fontFamily: `${font}, sans-serif`,
          width: `${width * zoomPreview}px`,
          fontSize: `${fontSize * zoomPreview}px`,
          textTransform: `${isUpperCase ? 'uppercase' : 'none'}`,
          fontStyle: fontStyle || 'normal'
        }}
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(text?.text !== undefined ? getFormattedHtml(text) : text, {
            allowedTags: ['br', 'font'],
            allowedAttributes: { font: ['color'] }
          })
        }}
      />
    </div>
  );
}

export default FormattedTextPreview;
