import { InteractiveSettingsFormView } from './InteractiveSettingsFormView';
import React, { memo } from 'react';

import { useISettingsForm } from './hooks/useISettingsForm';

export const InteractiveSettingsFormContainer = memo(({ interactiveInfoRef, extended }) => {
  const {
    interactiveName,
    description,
    notes,
    changeInteractiveName,
    changeDescription,
    changeNotes,
    updateInteractiveTitle,
    changeSessionInfo
  } = useISettingsForm();

  return (
    <InteractiveSettingsFormView
      interactiveName={interactiveName}
      description={description}
      notes={notes}
      changeInteractiveName={changeInteractiveName}
      changeDescription={changeDescription}
      changeNotes={changeNotes}
      updateInteractiveTitle={updateInteractiveTitle}
      changeSessionInfo={changeSessionInfo}
      ref={interactiveInfoRef}
      extended={extended}
    />
  );
});
