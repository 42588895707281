import uniqBy from 'lodash/uniqBy';

import { TABS } from 'containers/Feed/constants';

import {
  ADD_LABEL_SUCCESS,
  ADD_RESPONSE_MESSAGE,
  CLEAR_LABELS_SUCCESS,
  DELETE_LABEL_SUCCESS,
  REMOVE_FEED_ITEM,
  SET_FEED_ITEM_FAV_STATUS,
  SET_FEED_ITEM_HIDDEN_STATE,
  SET_FEED_ITEM_LIKE_STATUS,
  SET_FEED_ITEM_MINIMIZED_STATUS,
  UPDATE_FEED_ITEM_AUDIO
} from 'store/actions/actionTypes';
import { editProfile } from 'store/actions/profile';
import settingsReducer from 'store/reducers/settings';

import { editProfile as editProfileApi } from 'api/dataLayer';

export const updateSideProfilePinnedAuthors =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (
      [
        SET_FEED_ITEM_FAV_STATUS,
        SET_FEED_ITEM_LIKE_STATUS,
        SET_FEED_ITEM_MINIMIZED_STATUS,
        ADD_RESPONSE_MESSAGE,
        SET_FEED_ITEM_HIDDEN_STATE,
        ADD_LABEL_SUCCESS,
        DELETE_LABEL_SUCCESS,
        CLEAR_LABELS_SUCCESS,
        REMOVE_FEED_ITEM,
        UPDATE_FEED_ITEM_AUDIO
      ].includes(action.type) &&
      !action.updatedFromSocket
    ) {
      const state = getState();
      if (state.feed.currentTab !== TABS.messages) return next(action);

      const { profiles, currentProfile } = state.profile;
      const targetProfiles = profiles.filter((profile) =>
        profile.settings.authors?.some((author) =>
          author.messages?.some((message) => message.id === action.id)
        )
      );

      if (targetProfiles.length) {
        for (const profile of targetProfiles) {
          if (profile.id === currentProfile.id) continue;
          dispatch(
            editProfile({
              id: profile.id,
              settings: settingsReducer(profile.settings, action)
            })
          );

          let authors = settingsReducer(profile.settings, action).authors;
          authors = authors.map((author) => ({
            ...author,
            messages: uniqBy(author.messages, 'id')
          }));

          editProfileApi({
            profileId: profile.id,
            parametr: 'settings',
            value: {
              authors: settingsReducer(profile.settings, action).authors
            }
          }).catch((error) => {
            console.error(error);
          });
        }
      }
    }

    return next(action);
  };
