import { useDispatch, useSelector } from 'react-redux';

import useActions from 'hooks/useActions';

import * as AC from 'store/actions/isettings';

export const useBroadcastFeedSettings = () => {
  const { updateBroadcastConfig, changeSessionInfo } = useActions();
  const dispatch = useDispatch();
  const { broadcastConfig } = useSelector((state) => state.settings);
  const isNewsAutoFill = useSelector((state) => state.isettings.info.autofill);
  const isNewsAutoWithDrawal = useSelector((state) => state.isettings.info.auto_withdrawal);

  const toggleMessagesAutoSend = (isChecked) => updateBroadcastConfig('isAutoSend', isChecked);
  const toggleMessagesAutoAdd = (isChecked) => updateBroadcastConfig('isAutoAdd', isChecked);
  const toggleNewsAutoFill = (isChecked) => {
    changeSessionInfo('autofill', isChecked);
    dispatch(AC.updateInfoField('autofill', isChecked));
  };
  const toggleNewsAutoWithDrawal = (isChecked) => {
    changeSessionInfo('auto_withdrawal', isChecked);
    dispatch(AC.updateInfoField('auto_withdrawal', isChecked));
  };

  return {
    isMessagesAutoSend: broadcastConfig.isAutoSend,
    isMessagesAutoAdd: broadcastConfig.isAutoAdd,
    isNewsAutoFill,
    isNewsAutoWithDrawal,
    toggleMessagesAutoSend,
    toggleMessagesAutoAdd,
    toggleNewsAutoFill,
    toggleNewsAutoWithDrawal
  };
};
