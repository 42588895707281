import React from 'react';

import styles from './Skeleton.module.css';

function Skeleton({ children, className, width, height, borderRadius, style }) {
  return (
    <div
      style={{ ...style, width, height, borderRadius }}
      className={`${styles['skeleton-wrapper']} ${className}`}>
      {children}
    </div>
  );
}

export default Skeleton;
