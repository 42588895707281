import { useEffect, useState } from 'react';

import useActions from 'hooks/useActions';

import { INTERACTIVE_ACTIVITIES } from 'store/constants';

export const useExportButtons = ({ settings, sessionId, editRequest, buttonsField, activity }) => {
  const [settingsState, setSettingsState] = useState(null);
  const { updateInfoField, updateActiveBroadcastField } = useActions();

  // TODO socket sync
  useEffect(() => {
    if (settingsState || !settings || !(typeof settings === 'object')) return;
    setSettingsState(settings);
  }, [settings]);

  const addButton = () => {
    const length = Object.entries(settingsState).length;
    const newState = { ...settingsState, [`F${length + 1}`]: '' };
    setSettingsState(newState);
    updateRequest(newState);
  };

  const removeButton = (key) => {
    // settingsState
    let state = { ...settingsState };
    delete state[key];
    state = resetButtonsKeys(state);
    setSettingsState(state);
    updateRequest(state);
  };

  const resetButtonsKeys = (buttons) => {
    let i = 1;
    for (let [key, value] of Object.entries(buttons)) {
      delete buttons[key];
      buttons[`F${i++}`] = value;
    }
    return buttons;
  };

  const updateRequest = async (settings) => {
    const updatedInfo = {
      id: sessionId,
      settings: JSON.stringify(settings)
    };

    try {
      await editRequest(updatedInfo);
      updateInfoField(buttonsField, settings);

      if (activity === INTERACTIVE_ACTIVITIES.active)
        updateActiveBroadcastField(buttonsField, settings);
    } catch (ex) {
      console.error(ex);
    }
  };

  const updateValue = (key, newValue) => {
    setSettingsState((state) => ({
      ...state,
      [key]: newValue
    }));
  };

  return {
    settingsState,
    setSettingsState,
    addButton,
    removeButton,
    resetButtonsKeys,
    updateRequest,
    updateValue
  };
};
