import { Button } from 'components';

export const WeatherCardControls = ({ insertTags }) => {
  const buttons = [
    {
      value: '+',
      titleKey: 'stress',
      icon: 'fal fa-cog',
      color: '#6f6f6f',
      isSelected: false,
      isDisabled: false,
      clickHandler: () => insertTags('+')
    },
    {
      value: '-',
      titleKey: 'withPause',
      icon: 'far fa-stop',
      color: '#ff4d29',
      isSelected: false,
      isDisabled: false,
      clickHandler: () => insertTags('-')
    },
    {
      value: '**',
      titleKey: 'emphasisWord',
      icon: 'fal fa-plus',
      // <i class="far fa-brackets"></i>
      isSelected: false,
      isDisabled: false,
      clickHandler: () => insertTags('****')
    }
  ];

  return [
    ...buttons.map((el, i) => {
      return (
        <Button
          variant="text"
          {...(el.color ? { color: el.color } : {})}
          clickHandler={el.clickHandler}
          title={window.t(el.titleKey)}
          key={`btn${i}`}
          style={{
            padding: '4px 10px',
            minWidth: 'unset',
            fontSize: 24,
            lineHeight: 1
          }}>
          {el.value}
        </Button>
      );
    })
  ];
};
