import { isDateDifferent } from './dateTime';

export const getChatFeed = (messages) => {
  const elements = [];
  const filteredMessages = messages.filter((el) => el.attachments?.length || el.content);
  filteredMessages.forEach((el, i, arr) => {
    if (i === 0 || (i + 1 !== arr.length && isDateDifferent(arr[i].date, arr[i - 1]?.date))) {
      elements.push({
        type: 'dateLine',
        date: el.date,
        id: Math.random()
      });
    }

    elements.push(el);
  });
  return elements;
};

export function getIconByFile(fileType) {
  const fileTypesIcons = {
    image: 'fal fa-file-image',
    pdf: 'fal fa-file-pdf',
    audio: 'fal fa-file-music',
    zip: 'fal fa-file-archive',
    application: 'fal fa-file-alt'
  };
  const icon = Object.entries(fileTypesIcons).find(([type, value]) => fileType?.includes(type));
  return icon ? icon[1] : fileTypesIcons.application;
}
