import { PropTypes } from 'prop-types';
import React, { memo } from 'react';
import Joyride from 'react-joyride';

import { Avatar, Button, NoteCount } from 'components';

import { getIconImage, getImageByChannel, getSocialsImage } from 'helpers/image';
import { getGuideLocale } from 'helpers/locale';

import styles from './LeftToolbarView.module.css';

const propTypes = {
  activeMenu: PropTypes.oneOf(['favorite', 'search', 'socials', 'youtube']),
  setActiveLeftMenu: PropTypes.func
};

export const LeftToolbarView = memo(
  ({
    currentProfile,
    totalNoteCount,
    activeMenu,
    toggleHandler,
    changeRoute,
    // guide
    startGuide,
    handleJoyrideCallback,
    run,
    stepIndex,
    setStepIndex,
    steps,
    leftToolbarRef,
    interactivesBtn
  }) => {
    function Separator() {
      return <div className={styles.separator} />;
    }
    return (
      <>
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          run={run}
          showProgress
          steps={steps}
          stepIndex={stepIndex}
          locale={getGuideLocale()}
        />
        <div ref={leftToolbarRef} id={styles['left-toolbar']}>
          <div>
            <Button
              title={window.t('home')}
              variant="text"
              size="small"
              color="#fff"
              hoverColor="#00000080"
              activeColor="#00000080"
              className={`${styles['toolbar-btn']} ${styles['main-btn']}`}
              clickHandler={() => changeRoute('/')}>
              <Avatar
                src={
                  currentProfile
                    ? currentProfile.profile_avatar || getImageByChannel('')
                    : getIconImage('home@2x.png')
                }
                rounded
                size={39.6}
                style={{
                  margin: '0 auto'
                }}
              />
            </Button>
            <Button
              title={window.t('settings')}
              variant="text"
              size="small"
              color="#fff"
              hoverColor="#00000080"
              activeColor="#00000080"
              className={`${styles['toolbar-btn']} ${styles['main-btn']}`}
              clickHandler={() => changeRoute('/settings')}>
              <Avatar
                src={getIconImage('prefs@2x.png')}
                rounded={false}
                size={39.6}
                style={{ margin: '0 auto' }}
              />
              {window.t('settings')}
              {totalNoteCount > 0 && <NoteCount count={totalNoteCount} top={7} right={14} />}
            </Button>
            <Separator />
            <Button
              title={window.t('interactives')}
              variant="text"
              size="small"
              color="#fff"
              hoverColor="#00000080"
              activeColor="#00000080"
              className={`${styles['toolbar-btn']} ${styles['main-btn']}`}
              clickHandler={() => {
                changeRoute('/sessions');
                setStepIndex((v) => ++v);
              }}
              ref={interactivesBtn}>
              <Avatar
                src={getIconImage('sessions@2x.png')}
                rounded={false}
                size={39.6}
                style={{ margin: '0 auto' }}
              />
              {window.t('interactives')}
            </Button>
            <Button
              title="Youtube"
              variant="text"
              size="small"
              color="#fff"
              hoverColor="#00000080"
              activeColor="#00000080"
              className={`${styles['toolbar-btn']} ${styles['main-btn']}`}
              style={{
                backgroundColor: activeMenu === 'youtube' ? '#0088ee' : ''
              }}
              clickHandler={() => {
                toggleHandler('youtube');
              }}>
              <Avatar
                src={getSocialsImage('channel-yt_live.png')}
                rounded={false}
                size={32.4}
                style={{ margin: '0 auto 6px' }}
              />
              Youtube
            </Button>
            <Button
              title={window.t('socialsFull')}
              variant="text"
              size="small"
              color="#fff"
              hoverColor="#00000080"
              activeColor="#00000080"
              className={`${styles['toolbar-btn']} ${styles['social-btn']}`}
              style={{
                backgroundColor: activeMenu === 'socials' ? '#0088ee' : ''
              }}
              clickHandler={() => {
                toggleHandler('socials');
              }}>
              <Avatar
                src={getIconImage('share.png')}
                size={28.2}
                rounded={false}
                style={{ margin: '0 auto 10px' }}
              />
              {window.t('socialsShort')}
            </Button>
            <Separator />
            <Button
              title={window.t('search')}
              variant="text"
              size="small"
              color="#fff"
              hoverColor="#00000080"
              activeColor="#00000080"
              className={`${styles['toolbar-btn']} ${styles['main-btn']}`}
              style={{
                backgroundColor: activeMenu === 'search' ? '#0088ee' : ''
              }}
              clickHandler={() => {
                toggleHandler('search');
              }}>
              <Avatar
                src={getIconImage('search@2x.png')}
                rounded={false}
                size={39.6}
                style={{ margin: '0 auto' }}
              />
              {window.t('search')}
            </Button>
            <Button
              title={window.t('favorite')}
              variant="text"
              size="small"
              color="#fff"
              hoverColor="#00000080"
              activeColor="#00000080"
              className={`${styles['toolbar-btn']} ${styles['main-btn']}`}
              style={{
                backgroundColor: activeMenu === 'favorite' ? '#0088ee' : ''
              }}
              clickHandler={() => {
                toggleHandler('favorite');
              }}>
              <Avatar
                src={getIconImage('favorite@2x.png')}
                rounded={false}
                size={39.6}
                style={{ margin: '0 auto' }}
              />
              {window.t('favorite')}
            </Button>
            <Separator />
            <Button
              title={window.t('help')}
              variant="text"
              size="small"
              color="#fff"
              hoverColor="#00000080"
              activeColor="#00000080"
              className={`${styles['toolbar-btn']} ${styles['main-btn']}`}
              style={{
                backgroundColor: ''
              }}
              clickHandler={() => {
                toggleHandler(activeMenu);
                startGuide && startGuide();
              }}>
              {window.t('help')}
            </Button>
          </div>
        </div>
      </>
    );
  }
);

LeftToolbarView.propTypes = propTypes;
