export function isEmailAddress(str) {
  var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return pattern.test(str); // returns a boolean
}
export function isNotEmpty(str) {
  var pattern = /\S+/;
  return pattern.test(str); // returns a boolean
}
export function isNumber(str) {
  var pattern = /^\d+$/;
  return pattern.test(str); // returns a boolean
}

export function isSame(str1, str2) {
  return str1 === str2;
}
