import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';

import useActions from 'hooks/useActions';

export const useStatesButtons = ({ editRequest, sessionId, state }) => {
  const { updateInfoField } = useActions();

  const [statesState, setStatesState] = useState(null);

  useEffect(() => {
    if (statesState) return;

    setStatesState(state);
  }, [state]);

  const addState = () => {
    const length = Object.entries(statesState).length;
    const newState = {
      ...statesState,
      [`S${length + 1}`]: {
        value: '',
        type: 'cycle',
        duration: '1',
        active: 0
      }
    };
    setStatesState(newState);
    updateRequest(newState);
  };

  const removeState = (key) => {
    let state = { ...statesState };
    delete state[key];
    state = resetStatesKeys(state);
    setStatesState(state);
    updateRequest(state);
  };

  const resetStatesKeys = (states) => {
    let i = 1;
    for (let [key, value] of Object.entries(states)) {
      delete states[key];
      states[`S${i++}`] = value;
    }
    return states;
  };

  const updateRequest = async (states) => {
    const updatedInfo = {
      id: sessionId,
      state: JSON.stringify(states)
    };

    try {
      await editRequest(updatedInfo);
      updateInfoField('state', states);
    } catch (ex) {
      console.error(ex);
    }
  };

  const debouncedUpdateRequest = useMemo(() => debounce(updateRequest, 1000), [state]);

  const updatePropertyValue = (key, property, newValue) => {
    const newState = {
      ...statesState,
      [key]: {
        ...statesState[key],
        [property]: newValue
      }
    };
    setStatesState(newState);
    debouncedUpdateRequest(newState);
  };

  return {
    statesState,
    addState,
    removeState,
    updateRequest,
    updatePropertyValue
  };
};
