import throttle from 'lodash/throttle';
import React from 'react';
import { connect } from 'react-redux';

import { resizeActions } from 'store/slices/resizeSlice';

import styles from './MouseMover.module.css';

class MouseMover extends React.Component {
  constructor(props) {
    super(props);

    this.resizerRef = React.createRef();
  }

  state = {
    isMouseDown: false,
    timeoutSize: null
  };

  componentDidMount() {
    window.addEventListener('mouseup', this.mouseUpHandler);
    window.addEventListener('mousemove', this.mouseMoveHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.mouseUpHandler);
    window.removeEventListener('mousemove', this.mouseMoveHandler);
  }

  mouseDownHandler = () => {
    this.setState({
      isMouseDown: true
    });
  };

  mouseUpHandler = () => {
    this.setState({
      isMouseDown: false
    });
  };

  mouseMoveHandler = throttle((e) => {
    if (!this.state.isMouseDown) return;

    const offset = e.pageX - this.resizerRef.current.offsetWidth / 2 - 76;
    if (offset > 0 && offset < document.documentElement.clientWidth - 100) {
      const hun = document.documentElement.clientWidth;
      const proc = ((e.pageX - 38) * 100) / hun;
      this.props.listPane.current.style.width = `calc(${proc}% - 38px)`;
      this.props.workPane.current.style.width = `calc(${100 - proc}% - 38px)`;
      clearTimeout(this.state.timeoutSize);
      let _timeoutSize = setTimeout(() => {
        this.props.setListPaneWidth(proc);
        this.props.setWorkPaneWidth(100 - proc);
      }, 500);

      this.setState({
        timeoutSize: _timeoutSize
      });
    }
  });

  render() {
    return (
      <div ref={this.resizerRef} onMouseDown={this.mouseDownHandler} id={styles['mouse-mover']} />
    );
  }
}

function mapStateToProps(state) {
  return {
    listPane: state.resize.listPane,
    workPane: state.resize.workPane,
    listPaneWidth: state.resize.listPaneWidth,
    workPaneWidth: state.resize.workPaneWidth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setListPaneWidth: (width) => dispatch(resizeActions.setListPaneWidth({ width })),
    setWorkPaneWidth: (width) => dispatch(resizeActions.setWorkPaneWidth({ width }))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MouseMover);
