import { RegistrationSearchView } from './RegistrationSearchView';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useActions, useLocalStorage } from 'hooks';

import { filterByParticipants, filterByTextRegistration } from 'helpers/registration';

export const RegistrationSearchContainer = memo(({ setIsFilterActive }) => {
  const [isFiltered, setIsFiltered] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);

  const [registrationSearchText, setRegistrationSearchText] = useLocalStorage(
    'registrationSearchText',
    ''
  );
  const [registrationFilters, setRegistrationFilters] = useLocalStorage('registrationFilters', [
    { filter: 'participation', value: 'On-line', filterDesc: '', isActive: true },
    { filter: 'participation', value: 'Off-line', filterDesc: '', isActive: true }
  ]);

  const { participants } = useSelector((state) => state.registration);
  const { setFilteredRegistration } = useActions();

  const filterParticipants = (participantsList = null) => {
    const toFilter = participantsList || participants;

    if (toFilter.length === 0) return;

    const filtered = filterByParticipants(toFilter, registrationFilters);
    const toShow = filterByTextRegistration(filtered, registrationSearchText).map(
      (el) => el.card_id
    );

    setIsFiltered(filtered.length !== toFilter.length || toShow.length !== toFilter.length);

    setFilteredRegistration(toShow);
  };

  useEffect(() => {
    filterParticipants();
  }, [registrationFilters, registrationSearchText]);

  return (
    <RegistrationSearchView
      setIsFiltered={setIsFiltered}
      isSearchActive={isSearchActive}
      setIsSearchActive={setIsSearchActive}
      searchText={registrationSearchText}
      setSearchText={setRegistrationSearchText}
      isFiltered={isFiltered}
      registrationFilters={registrationFilters}
      setRegistrationFilters={setRegistrationFilters}
      setIsFilterActive={setIsFilterActive}
    />
  );
});
