import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import styles from './VolumeProgressBar.module.css';

function VolumeProgressBar({
  isVolumeBarActive,
  setVolumeMouseDown,
  volumePercent,
  setVolumeMove,
  setVolumeClick
}) {
  const classes = classNames(styles['volume_control'], 'mt-1', 'mr-2', {
    [styles['active']]: isVolumeBarActive
  });
  // setVolumeMove={this.setVolumeMove}
  const volumeLineRef = useRef(null);

  const mousemoveHandler = (event) => {
    setVolumeMove(event, volumeLineRef);
  };

  useEffect(() => {
    window.addEventListener('mousemove', mousemoveHandler);

    return function cleanup() {
      window.removeEventListener('mousemove', mousemoveHandler);
    };
  });

  return (
    <div
      className={classes}
      onMouseDown={() => {
        setVolumeMouseDown(true);
      }}
      onClick={(event) => {
        setVolumeClick(event, volumeLineRef);
      }}>
      <div ref={volumeLineRef} className={styles['volume_progress_bar']}>
        <div className={styles['volume_progress_line']} style={{ width: `${volumePercent}%` }} />
        <div
          className={styles['volume_progress_control']}
          style={{ left: `calc(${volumePercent}% - 4.5px)` }}
        />
      </div>
    </div>
  );
}

export default VolumeProgressBar;

VolumeProgressBar.propTpes = {
  isVolumeBarActive: PropTypes.bool,
  setVolumeMouseDown: PropTypes.func.isRequired,
  volumePercent: PropTypes.number.isRequired,
  setVolumeMove: PropTypes.func.isRequired,
  setVolumeClick: PropTypes.func.isRequired
};

VolumeProgressBar.defaultProps = {
  isVolumeBarActive: false
};
