import { PropTypes } from 'prop-types';
import React, { forwardRef, memo } from 'react';

import { SvgCheckbox } from 'components';

import styles from './BroadcastFeedSettingsView.module.css';

const propTypes = {
  isAutoSend: PropTypes.bool,
  isAutoAdd: PropTypes.bool,
  toggleAutoSend: PropTypes.func,
  toggleAutoAdd: PropTypes.func
};

export const BroadcastFeedSettingsView = memo(
  forwardRef(({ isAutoSend, isAutoAdd, toggleAutoSend, toggleAutoAdd }, ref) => {
    return (
      <div ref={ref} className={styles['bs__checkboxes']}>
        <SvgCheckbox
          isChecked={isAutoSend}
          changeHandler={toggleAutoSend}
          style={{
            color: '#6f6f6f',
            fontSize: 16,
            opacity: 0.75
          }}>
          {window.t('autoSend')}
        </SvgCheckbox>
        <SvgCheckbox
          isChecked={isAutoAdd}
          changeHandler={toggleAutoAdd}
          style={{
            color: '#6f6f6f',
            fontSize: 16,
            opacity: 0.75,
            marginLeft: 10
          }}>
          {window.t('autoAdd')}
        </SvgCheckbox>
      </div>
    );
  })
);

BroadcastFeedSettingsView.propTypes = propTypes;
