import { requestApiGet, requestApiPost } from '.';

import { INTERACTIVES } from 'store/constants';

export async function getDomainInfo(domain) {
  return await requestApiGet('api_get_doman_info', {
    domain
  });
}

// message feed
export async function setMessageFavoriteState(payload) {
  const { id, value } = payload;
  return await requestApiPost('api_set_feed_message_favorite_state', {
    action: 'MessageFavorite',
    param1: id,
    param2: value
  });
}

export async function setMessageMinimizedState(payload) {
  const { id, value } = payload;
  const str = value ? 'minimized' : '';
  return await requestApiPost('api_set_feed_message_minimized_state', {
    action: 'MessageStatus',
    param1: id,
    param2: str
  });
}

export async function toggleFeedMessageLabel(payload) {
  const { id, label } = payload;
  return await requestApiPost('api_toggle_feed_message_label', {
    action: 'MessageLabels',
    param1: id,
    param2: label
  });
}

export async function clearFeedMessageLabels(labels) {
  return await requestApiPost('api_clear_feed_message_labels', {
    action: 'MessageLabels',
    param1: labels,
    param2: ''
  });
}

export async function deleteFeedMessage(id) {
  return await requestApiPost('api_delete_feed_message', { id });
}

export async function recoveryFeedMessage(id) {
  return await requestApiPost('api_recovery_feed_message', { id });
}

// TODO test
export async function editFeedMessage(payload) {
  return await requestApiPost('api_edit_feed_message', payload);
}

// template messages
export async function getTemplates() {
  return await requestApiGet('api_get_templates');
}

export async function deleteTemplate(qAnswerId) {
  return await requestApiPost('api_delete_template', { qAnswerId });
}

export async function addTemplate(payload) {
  const { image, img, name, value } = payload;
  return await requestApiPost('api_add_template', {
    image,
    img,
    name,
    value
  });
}

export async function updateTemplate(payload) {
  const { image, img, name, value } = payload;
  return await requestApiPost('api_update_template', {
    image,
    img,
    name,
    value
  });
}

// interactives
export async function updateInteractiveTitle(payload) {
  const { id, interactiveName, type } = payload;
  return await requestApiPost('api_update_interactive_title', {
    id,
    interactiveName,
    type
  });
}

export async function getInteractives() {
  return await requestApiGet('api_get_interactives');
}

export async function getInteractiveById(payload) {
  const { id, type } = payload;
  const key =
    type === INTERACTIVES.broadcast
      ? 'api_get_broadcast'
      : type === INTERACTIVES.vote || type === INTERACTIVES.quiz
      ? 'api_get_vote'
      : type === INTERACTIVES.content
      ? 'api_get_content_news'
      : type === INTERACTIVES.autoReply
      ? 'api_get_autoreply'
      : type === INTERACTIVES.registration
      ? 'api_get_registration'
      : null;

  if (!key) throw new Error(`interactive ${type} doesn't exist`);
  return await requestApiGet(key, { id });
}

export async function createInteractive(payload) {
  const { type, title, description, memo } = payload;

  if (typeof type !== 'string') return;

  return type === INTERACTIVES.pulse
    ? await requestApiPost('api_old_add_interactive', {
        actionName: 'IactiveADD',
        param1: payload
      })
    : await requestApiPost('api_add_interactive', {
        type,
        title,
        description,
        memo
      });
}

export async function deleteInteractive(payload) {
  const { type, param1 } = payload;
  return await requestApiPost('api_delete_interactive', {
    type,
    param1
  });
}

export async function deleteInteractiveOld(id) {
  return await requestApiPost('api_old_delete_interactive', {
    actionName: 'IactiveDELETE',
    param1: id
  });
}

export async function copyInteractive(payload) {
  const { iactive_id, type } = payload;
  return await requestApiPost('api_copy_interactive', {
    iactive_id,
    type
  });
}

// autoreply
export async function startAutoreply(id) {
  return await requestApiPost('api_start_autoreply', { id });
}

export async function stopAutoreply(id) {
  return await requestApiPost('api_stop_autoreply', { id });
}

export async function editAutoreply(payload) {
  return await requestApiPost('api_update_autoreply', payload);
}

// vote
export async function startVote(id) {
  return await requestApiPost('api_start_vote', { id });
}

export async function stopVote(id) {
  return await requestApiPost('api_stop_vote', { id });
}

export async function editVote(payload) {
  // const {
  //     title,
  //     description,
  //     memo,
  //     notes,
  //     state } = payload;
  return await requestApiPost('api_update_vote', payload);
}

// registration =========

export async function editRegistration(payload) {
  return await requestApiPost('api_update_registration', payload);
}

export async function startRegistration(id) {
  return await requestApiPost('api_start_registration', { id });
}

export async function stopRegistration(id) {
  return await requestApiPost('api_stop_registration', { id });
}
export async function getRegistrationCards(id) {
  return await requestApiGet('api_get_registration_cards', { id });
}

export async function deleteRegistrationCard(id, registration_id) {
  return await requestApiPost('api_delete_registration_card', { id, registration_id });
}

export async function getRegistrationOneCard(id) {
  return await requestApiGet('api_get_registration_oneCard', { id });
}

export async function editAuthorCard(card_id, registration_id, author_data) {
  return await requestApiPost('api_edit_author_card', { card_id, registration_id, author_data });
}

export async function deleteRegistrationImg(id) {
  return await requestApiPost('api_delete_registration_image', { registrationId: id });
}

// ==========

export async function setVoteResult(payload) {
  const { id, varnum } = payload;
  return await requestApiPost('api_set_vote_result', {
    id,
    varnum
  });
}

export async function editRangeNumbers(payload) {
  const { id, range } = payload;
  return await requestApiPost('api_edit_range_numbers', {
    id,
    range
  });
}

export async function getRange(id) {
  return await requestApiGet('api_get_vote_range', { id });
}

export async function addVoteToVariant(payload) {
  const { id, varnum } = payload;
  return await requestApiPost('api_addvote_to_variant', {
    id,
    varnum
  });
}

export async function clearVoteStats(id) {
  return await requestApiPost('api_clear_vote_stats', { id });
}

export async function getVoteVariants(id) {
  return await requestApiGet('api_get_vote_variants', { id });
}

export async function addVoteVariant(payload) {
  const { id, name, comment, num } = payload;
  return await requestApiPost('api_add_vote_variant', {
    id,
    name,
    comment,
    num
  });
}

export async function deleteVoteVariant(payload) {
  const { id, varnum } = payload;
  return await requestApiPost('api_delete_vote_variant', {
    id,
    varnum
  });
}

export async function updateVoteVariant(payload) {
  const { id, name, comment, num } = payload;
  return await requestApiPost('api_update_vote_variant', {
    id,
    name,
    comment,
    num,
    varnum: num
  });
}

export async function deleteVoteImage(payload) {
  const { id, varnum } = payload;
  return await requestApiPost('api_delete_vote_image', {
    id,
    ...(varnum && { varnum })
  });
}

export async function getVoteWinners(payload) {
  const { id, type } = payload;
  return await requestApiPost('api_get_vote_winners', {
    id,
    type
  });
}

export async function bindSocialPoll(payload) {
  const { voteId, postId, socialName } = payload;
  return await requestApiPost('api_bind_social_poll', {
    voteId,
    postId,
    socialName
  });
}

export async function unbindSocialPoll(payload) {
  const { voteId, socialName } = payload;
  return await requestApiPost('api_unbind_social_poll', {
    voteId,
    socialName
  });
}

// export async function getSocialVars(payload) {
//     const { voteId, postId, socialName } = payload;
//     return await requestApiPost('api_refresh_social_var', {
//         voteId,
//         postId,
//         socialName
//     })
// }

export async function createPoll(payload) {
  const { voteId, postId, socialName } = payload;
  return await requestApiPost('api_create_poll', {
    voteId,
    postId,
    socialName
  });
}

export async function addOkPoll({ postUrl }) {
  return await requestApiPost('api_add_ok_poll', {
    postUrl
  });
}

export async function getVkVotes(payload) {
  const { postId, voteId } = payload;
  return await requestApiPost('api_get_vk_votes', {
    postId,
    voteId
  });
}

export async function getOkVotes(payload) {
  const { postId, voteId } = payload;
  return await requestApiPost('api_get_ok_votes', {
    postId,
    voteId
  });
}

export async function getSocialPolls() {
  return await requestApiGet('api_get_social_polls');
}

// broadcast
export async function getActiveBroadcast() {
  return await requestApiGet('api_get_active_broadcast');
}

export async function startBroadcast(id) {
  return await requestApiPost('api_start_broadcast', { id });
}

export async function stopBroadcast(id) {
  return await requestApiPost('api_stop_broadcast', { id });
}

export async function getBroadcastMessages(id) {
  return await requestApiGet('api_get_broadcast_messages', { id });
}

export async function getBroadcastNewMessages(payload) {
  const { id, lastMsgId } = payload;
  return await requestApiGet('api_get_broadcast_new_messages', {
    id,
    lastMsgId
  });
}

export async function editBroadcast(payload) {
  // const {
  //     iactive_id,
  //     description,
  //     memo,
  //     message_count,
  //     autofill,
  //     auto_withdrawal,
  //     activity,
  //     settings,
  //     visibility,
  //     state,
  //     id,
  //     type,
  //     notes } = payload;
  return await requestApiPost('api_update_broadcast', payload);
}

export async function addBroadcastMessage(payload) {
  const userInfoStr = localStorage.getItem('userInfo');
  const userInfo = userInfoStr ? JSON.parse(userInfoStr) : {};
  return await requestApiPost('api_add_broadcast_message', {
    id: payload.id,
    broadcast_id: payload.broadcast_id,
    author: payload.author || '',
    name: payload.name || '',
    type: payload.type || '',
    senderNumber: payload.is_created ? userInfo?.clientNum : payload.senderNumber,
    content: payload.content || '',
    channel: payload.channel || '',
    date: payload.date || '',
    image: payload.image || '',
    is_created: payload.is_created || '',
    status: payload.status || '',
    favorite: payload.favorite || '',
    attachments: payload.attachments || [],
    labels: payload.labels || '',
    region: payload.region || ''
  });
}

export async function deleteBroadcastMessage(payload) {
  const { id, broadcast_id } = payload;
  return await requestApiPost('api_delete_broadcast_message', {
    id,
    broadcast_id
  });
}

export async function deleteAllBroadcastMessages(payload) {
  const { broadcast_id } = payload;
  return await requestApiPost('api_delete_all_broadcast_messages', {
    broadcast_id
  });
}

export async function editBroadcastMessage(payload) {
  return await requestApiPost('api_update_broadcast_message', {
    id: payload.id,
    broadcast_id: payload.broadcast_id,
    author: payload.author || '',
    name: payload.name || '',
    type: payload.type || '',
    senderNumber: payload.senderNumber || '',
    content: payload.content || '',
    channel: payload.channel || '',
    date: payload.date || '',
    image: payload.image || '',
    is_created: payload.is_created || '',
    status: payload.status || '',
    favorite: payload.favorite || '',
    attachments: payload.attachments || [],
    labels: payload.labels || '',
    region: payload.region || ''
  });
}

export async function restoreBroadcastMessage(payload) {
  const { id, broadcast_id } = payload;
  return await requestApiPost('api_restore_broadcast_message', {
    id,
    broadcast_id
  });
}

export async function setBroadcastMessageFavoriteState(payload) {
  const { broadcast_id, favorite, id } = payload;
  return await requestApiPost('api_toggle_broadcast_message_favorite_state', {
    id,
    broadcast_id,
    favorite
  });
}

export async function getBroadcastMessageInfo(id) {
  return await requestApiGet('api_get_broadcast_message_info', { id });
}

export async function addMessageToTitre(payload) {
  const { id, interactiveId, key, messageFeedId } = payload;
  return await requestApiPost('api_add_message_to_titre', {
    id,
    msg_id: messageFeedId,
    interactive_id: interactiveId,
    side: key
  });
}

export async function deleteMessageFromTitre(payload) {
  const { id, key, messageFeedId } = payload;

  return await requestApiPost('api_delete_message_from_titre', {
    id,
    msg_id: messageFeedId,
    type: key
  });
}

export async function clearTitre(titreTag, broadcastId) {
  let body = titreTag || broadcastId ? {} : null;

  if (titreTag) body.titreTag = titreTag;
  if (broadcastId) body.broadcast_id = broadcastId;

  return await requestApiPost('api_clear_titre', body);
}

// pulse
export async function getPulseData(id) {
  return await requestApiPost('api_get_pulse_data', {
    actionName: 'LOAD',
    param1: id
  });
}

export async function getPulseChartData(payload) {
  const { dateFrom, dateTo } = payload;
  return await requestApiPost('api_get_pulse_chart_data', {
    timezoneOffset: '-300',
    dateFrom,
    dateTo
  });
}

export async function updatePulseData(payload) {
  const { id, json } = payload;
  return await requestApiPost('api_update_pulse_data', {
    actionName: 'IactiveChange',
    param1: id,
    param2: json
  });
}

export function setPulseStartTime(dateTime) {
  return Promise.resolve({ body: 'ok' });
}

export function setPulseEndTime(dateTime) {
  return Promise.resolve({ body: 'ok' });
}

export function startPulse(dateTime) {
  return Promise.resolve({ body: 'ok' });
}

export function stopPulse(dateTime) {
  return Promise.resolve({ body: 'ok' });
}

// quiz
export async function getQuizData(id) {
  return await requestApiPost('api_get_quiz_data', {
    actionName: 'LOAD',
    param1: id
  });
}

export async function editQuiz(payload) {
  const { id, title, description, memo } = payload;
  return await requestApiPost('api_update_quiz', {
    actionName: 'ChangeCommonData',
    param1: id,
    title,
    description,
    memo
  });
}

export async function addQuizMessage(payload) {
  const { id, msgId, timeId } = payload;
  return await requestApiPost('api_add_quiz_message', {
    actionName: 'AddMessage',
    param1: id,
    param2: msgId,
    param3: timeId
  });
}

export async function updateQuizData(payload) {
  const { id, json } = payload;
  return await requestApiPost('api_update_quiz_data', {
    actionName: 'ChangeCommonData',
    param1: id,
    param2: JSON.stringify(json)
  });
}

// chat
export async function sendChatMessage(payload) {
  const { to, message, channel } = payload;
  return await requestApiPost('api_add_message_to_titre', {
    to,
    message,
    channel
  });
}

export async function getChatMessages(authorId) {
  return await requestApiGet('api_add_message_to_titre', {
    authorId
  });
}

// profile card
export async function getProfileInfo(payload) {
  const { senderNumber, channel } = payload;
  return await requestApiPost('api_get_profile_user_info', {
    action: 'getUserInfo',
    identifier: senderNumber,
    channel
  });
}

export async function getProfileMedia(payload) {
  const { senderNumber, channel } = payload;
  return await requestApiPost('api_get_profile_media', {
    action: 'getMedia',
    identifier: senderNumber,
    channel
  });
}

export async function getProfileMessages(payload) {
  const { senderNumber, channel } = payload;
  return await requestApiPost('api_get_profile_messages', {
    action: 'getMessages',
    identifier: senderNumber,
    channel
  });
}

export async function getMoreProfileMessages(payload) {
  const { senderNumber, channel, loadedMessagesCount } = payload;
  return await requestApiPost('api_get_profile_messages', {
    action: 'getMessages',
    identifier: senderNumber,
    channel,
    offset: loadedMessagesCount,
    limit: 10
  });
}

export async function banProfile(payload) {
  const { senderNumber, channel, author } = payload;
  return await requestApiPost('api_ban_profile', {
    action: 'AuthorToBan',
    param1: channel,
    param2: senderNumber,
    param3: author
  });
}

export async function unbanProfile(banId) {
  return await requestApiPost('api_unban_profile', {
    action: 'DeBanAutor',
    param1: banId
  });
}

export async function searchProfileBanned(searchText) {
  return await requestApiPost('api_get_profile_banned', {
    action: 'GetBannedSearch',
    param1: searchText
  });
}

// instagram
export async function getConnectedInstagramUsers() {
  return await requestApiPost('api_get_instagram_connected_users');
}

export async function searchInstagramUsers(searchInfo) {
  return await requestApiPost('api_get_instagram_users', {
    searchInfo,
    from: 0,
    to: 10
  });
}

export async function trackInstagramUser(userId) {
  return await requestApiPost('api_track_instagram_user', { userId });
}

export async function untrackInstagramUser(userId) {
  return await requestApiPost('api_untrack_instagram_user', { userId });
}

// vk
export async function getVkGroups() {
  return await requestApiGet('api_get_vk_groups');
}

export async function getVkPosts() {
  return await requestApiPost('api_get_vk_posts', {
    actionName: 'getPosts',
    param1: 'vkcomm'
  });
}
export async function deleteVkGroup({ groupId, isComment, isMessage }) {
  return await requestApiPost('api_delete_vk_group', {
    gid: groupId,
    info: {
      ...(isComment !== undefined && { vkcomm: isComment }),
      ...(isMessage !== undefined && { vkgroup: isMessage })
    }
  });
}

// youtube
export async function getYoutubeStreams() {
  return await requestApiPost('api_get_youtube_streams', {
    actionName: 'getPosts',
    param1: 'yt_live'
  });
}

export async function logoutYoutube(phone) {
  return await requestApiGet('api_logout_youtube', { phone });
}

export async function getYoutubeGroups() {
  return await requestApiGet('api_get_youtube_groups');
}

export async function deleteYoutubeGroup({ groupId, isComment, isMessage }) {
  return await requestApiPost('api_delete_youtube_group', {
    gid: groupId,
    info: {
      ...(isComment !== undefined && { yt_live_comm: isComment }),
      ...(isMessage !== undefined && { yt_live: isMessage })
    }
  });
}

// ok
export async function getOkGroups() {
  return await requestApiGet('api_get_ok_groups');
}

export async function deleteOkGroup({ groupId, isComment, isMessage }) {
  return await requestApiPost('api_toggle_ok_subscribe', {
    gid: groupId,
    info: {
      ...(isComment !== undefined && { yt_live_comm: isComment }),
      ...(isMessage !== undefined && { yt_live: isMessage })
    }
  });
}

export async function addOkGroup(groupToken) {
  return await requestApiPost('api_toggle_ok_subscribe', {
    groupToken
  });
}

// whatsApp
export async function getWhatsAppQR() {
  return await requestApiPost('api_post_whatsapp_qr');
}

export async function getWhatsAppClients() {
  return await requestApiGet('api_get_whatsapp_clients');
}

export async function getWhatsAppStatus() {
  return await requestApiGet('api_get_whatsapp_status');
}

export async function postWhatsAppLogOut() {
  return await requestApiPost('api_post_whatsapp_logout');
}

// telegram
export async function getTelegramClients() {
  return await requestApiGet('api_get_telegram_clients');
}

export async function logoutTelegram(connectId) {
  return await requestApiPost('api_logout_telegram_client', {
    connectId
  });
}

export async function confirmTelegram(payload) {
  return await requestApiPost('api_post_telegram_confirm', payload);
}

export async function logoutTelegramBot(connectId) {
  return await requestApiPost('api_logout_telegram_bot', {
    connectId
  });
}

export async function checkTelegramStatus(connectId) {
  return await requestApiGet('api_get_telegram_client_status', {
    connectId
  });
}

export async function connectTelegramClient(payload) {
  const { appPhone, appPassword } = payload;
  return await requestApiPost('api_connect_telegram_client', {
    appPhone,
    appPassword
  });
}

export async function connectTelegramBot(botToken) {
  return await requestApiPost('api_connect_telegram_bot', {
    botToken
  });
}

// viber
export async function getViberGroups() {
  return await requestApiGet('api_get_viber_groups');
}

export async function addViberPhone(payload) {
  const { viberNum, contactNum, messenger } = payload;
  return await requestApiPost('api_toggle_viber_subscribe', {
    info: {
      action: 'add',
      viberNum,
      contactNum,
      messenger
    }
  });
}

export async function deleteViberPhone(payload) {
  const { groupId, isMessage } = payload;
  return await requestApiPost('api_toggle_viber_subscribe', {
    gid: groupId,
    info: {
      action: 'delete',
      viber: +isMessage
    }
  });
}

export async function takeOverWhatsAppSession() {
  const userInfoStr = localStorage.getItem('userInfo');
  const userInfo = userInfoStr ? JSON.parse(userInfoStr) : {};

  return await requestApiPost('api_takover_whatsapp_session', {
    clientNum: userInfo?.clientNum
  });
}

export async function logoutWhatsApp(payload) {
  const { groupId, isMessage } = payload;
  return await requestApiPost('api_logout_whatsapp', {
    clientId: groupId,
    info: {
      whatsapp: isMessage
    }
  });
}
// Facebook
export async function getFacebookGroups() {
  return await requestApiGet('api_get_fb_groups');
}

// socials
export async function getSocials() {
  return await requestApiPost('api_get_socials', {
    actionName: 'getSocials'
  });
}

export async function getSocialsPosts(channel) {
  return await requestApiPost('api_get_socials_posts', {
    actionName: 'getPosts',
    param1: channel
  });
}

export async function setSocialsPostCheckedState(payload) {
  const { id, channel, isChecked } = payload;
  return await requestApiPost('api_set_socials_post_checked_state', {
    actionName: 'togglePost',
    param1: id,
    param2: channel,
    param3: isChecked
    // param4: kval
  });
}

export async function setSocialsPostsCheckedState(payload) {
  const { ids, channel, isChecked } = payload;
  return await requestApiPost('api_set_socials_posts_checked_state', {
    actionName: 'toggleAllPost',
    param1: channel,
    param2: ids.join(','),
    param3: isChecked
  });
}

// social settings
export async function toggleCheckboxState({ groupId, key, channel, isChecked }) {
  const getKey = () => {
    switch (channel) {
      case 'vk':
        return key === 'isComment' ? 'vkcomm' : key === 'isMessage' ? 'vkgroup' : 'none';
      case 'youtube':
        return key === 'isComment' ? 'yt_live_comm' : key === 'isMessage' ? 'yt_live' : 'none';
      case 'ok':
        return key === 'isMessage' ? 'okgroup' : 'none';
      case 'viber':
        return key === 'isMessage' ? 'viber' : 'none';
      case 'whatsapp':
        return key === 'isMessage' ? 'whatsapp' : 'none';
      default:
        return 'none';
    }
  };

  return await requestApiPost('api_set_social_settings', {
    info: {
      gid: groupId,
      [getKey()]: isChecked
    }
  });
}

// content
export async function getSlots() {
  return await requestApiGet('api_get_slots');
}

export async function getContentNews(id) {
  return await requestApiGet('api_get_content_news', { id });
}

export async function editContent(payload) {
  return await requestApiPost('api_edit_content', payload);
}

// api_set_content_slot: '/api/content/slot/set',
// api_set_content_type: '/api/content/set/type',
export async function setContentType(payload) {
  const { contentId, contentType } = payload;
  return await requestApiPost('api_set_content_type', {
    contentId,
    contentType
  });
}

export async function setContentSlot(payload) {
  const { contentId, slotId } = payload;
  return await requestApiPost('api_set_content_slot', {
    contentId,
    slotId
  });
}

export async function publishContent(contentId) {
  return await requestApiPost('api_publish_content', {
    contentId
  });
}

export async function addContentAttachment(payload) {
  const { contentId, attachment } = payload;
  return await requestApiPost('api_add_content_attachment', {
    contentId,
    attachment
  });
}

export async function getProfiles() {
  return await requestApiGet('api_get_profiles');
}

export async function createProfile(payload) {
  return await requestApiPost('api_create_profile', payload, undefined, undefined, 'profileAvatar');
}

export async function editProfile(payload, ...rest) {
  return await requestApiPost('api_edit_profile', payload, ...rest);
}

export async function deleteProfile(payload) {
  return await requestApiPost('api_delete_profile', payload);
}

export async function sendMessageToMessenger(payload, fileKey = '') {
  return await requestApiPost(
    'api_send_message_to_messenger',
    payload,
    undefined,
    undefined,
    fileKey
  );
}

export async function exportMessage(type, recipient, message) {
  return await requestApiPost('api_export_message', {
    type,
    recipient,
    message
  });
}

export async function rssSubscribe(rssUrl) {
  return await requestApiPost('api_rss_subscribe', {
    rssUrl
  });
}

export async function rssUnSubscribe(rssId) {
  return await requestApiPost('api_rss_unsubscribe', {
    rssId
  });
}

export async function rssGet() {
  return await requestApiGet('api_rss_get');
}

export async function rssDelete(rssId) {
  return await requestApiGet('api_rss_delete', { rssId });
}

export async function getWeatherByCity(weatherParams) {
  return await requestApiPost('api_weather_get_by_city', { weatherParams });
}

export async function deleteVideo(videoUrl) {
  return await requestApiPost('api_video_delete', { videoUrl });
}
