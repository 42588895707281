import sanitizeHtml from 'sanitize-html';

import { Button, Icon, SvgCheckbox, Title } from 'components';
import { getFormattedHtml } from 'components/TextEditor/helpers';

import { useActions } from 'hooks';

export function Header({
  order,
  blockMinimizedPreviewRef,
  block,
  isFirst,
  isLast,
  removeBlock,
  moveDown,
  moveUp
}) {
  const { updateContentBlockField } = useActions();
  const { isMinimized, isActive } = block;
  const text = block?.text?.value;
  return (
    <div className="d-flex align-baseline justify-end" ref={blockMinimizedPreviewRef}>
      {order && <Title size={18}>№{order}</Title>}
      <div
        style={{
          height: isMinimized ? 'auto' : '22px',
          opacity: isMinimized ? 1 : 0,
          overflow: 'hidden',
          transition: 'height 0.2s'
        }}>
        {block?.background_audio?.value?.fileName || block?.geo_location?.value ? (
          <Title className="ml-2" size={18} color="#888">
            {block?.background_audio?.value?.fileName || block?.geo_location?.value}
          </Title>
        ) : block?.caption?.value ? (
          <Title className="ml-2" size={18} color="#888">
            {block?.caption?.value}
          </Title>
        ) : (
          text && (
            <Title
              className="ml-2"
              size={18}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(text?.text !== undefined ? getFormattedHtml(text) : text, {
                  allowedTags: ['br', 'font'],
                  allowedAttributes: { font: ['color'] }
                })
              }}
            />
          )
        )}
      </div>
      <Button
        className="ml-auto"
        startIcon={<Icon size={20} fontName="fal fa-chevron-up" />}
        onlyIcon
        variant="icon"
        clickHandler={() => moveUp(block)}
        style={{ padding: '6px 8px' }}
        color={isFirst ? '#ccc' : 'primary'}
        isDisabled={isFirst}
      />
      <Button
        startIcon={<Icon size={20} fontName="fal fa-chevron-down" />}
        onlyIcon
        variant="icon"
        clickHandler={() => moveDown(block)}
        style={{ padding: '6px 8px' }}
        color={isLast ? '#ccc' : 'primary'}
        isDisabled={isLast}
      />
      <Button
        startIcon={
          <Icon
            size={15}
            viewBox="0 0 18 18"
            icon="minimize"
            style={{ enableBackground: '0 0 18 18', verticalAlign: 'initial' }}
          />
        }
        onlyIcon
        variant="icon"
        clickHandler={() => updateContentBlockField(block?.id, 'isMinimized', !isMinimized, null)}
        style={{ padding: '6px 8px' }}
        color={isMinimized ? '#ccc' : 'primary'}
      />
      <SvgCheckbox
        isChecked={isActive}
        changeHandler={() => updateContentBlockField(block?.id, 'isActive', !isActive, null)}
        className="mr-2"
        style={{ alignSelf: 'flex-start', marginTop: '7px' }}
      />
      <Button
        startIcon={<Icon size={20} fontName="fal fa-times" />}
        onlyIcon
        variant="icon"
        color="#ff0033"
        clickHandler={() => removeBlock(block?.id)}
        // title={window.t('clearTitr')}
        style={{ padding: '6px 8px' }}
      />
    </div>
  );
}
