import useActions from 'hooks/useActions';

export const useVariant = ({
  data,
  voteId,
  isVoteActive,
  updateVariant,
  changeVoteVariant,
  deleteVoteVariant,
  updateVariantField,
  addVote,
  changeHandler
}) => {
  const { addSnack, setVoteResult } = useActions();
  const { varnum, var_img_url, percent, comment, name, count } = data;
  const toggleResult = (isChecked) => {
    if (isVoteActive) {
      addSnack({
        type: 'danger',
        message: window.t('quizChangeCorrectAnswer')
      });
      return;
    }
    const result = isChecked ? varnum : 0;
    setVoteResult(voteId, result);
  };
  const changePicture = (event) => changeHandler(event, varnum);
  const deleteVariant = () => deleteVoteVariant(varnum);
  const updateComment = (value) => updateVariant(varnum, 'comment', value);
  const blurComment = (value) => changeVoteVariant(varnum, 'comment', value);
  const addKeyword = (newKey) => {
    updateVariantField({ varnum, field: 'name', value: [...name, newKey] });
    changeVoteVariant(varnum, 'name', [...name, newKey]);
  };
  const deleteKeyword = (key) => {
    updateVariantField({
      varnum,
      field: 'name',
      value: name.filter((el) => el !== key)
    });
    changeVoteVariant(
      varnum,
      'name',
      name.filter((el) => el !== key)
    );
  };
  const showWarning = () => {
    addSnack({
      type: 'danger',
      message: window.t('variantAlreadyExist')
    });
  };
  const addVoteHandler = () => addVote(voteId, varnum);

  return {
    var_img_url,
    changePicture,
    varnum,
    deleteVariant,
    toggleResult,
    percent,
    comment,
    updateComment,
    blurComment,
    addKeyword,
    deleteKeyword,
    name,
    showWarning,
    count,
    addVoteHandler
  };
};
