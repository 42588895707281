export function getPropsByKey(letter, json) {
  const buttons = {};
  for (let [key, value] of Object.entries(json)) {
    if (key.includes(letter)) buttons[key] = value;
  }
  return buttons;
}

export function getDiff(obj1, obj2) {
  const diff = {};
  for (const [key, value] of Object.entries(obj1)) {
    if (!(key in obj2)) diff[key] = value;
  }

  return diff;
}

export function getObjectFromArray(arr) {
  const obj = {};
  arr.forEach((el) => {
    obj[el] = '';
  });

  return obj;
}
