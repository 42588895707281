import ChannelModeSearch from './ChannelModeSearch/ChannelModeSearch';
import LabelSearchMode from './LabelSearchMode/LabelSearchMode';
import MediaSearchMode from './MediaSearchMode/MediaSearchMode';
import SearchModeSwitcher from './SearchModeSwitcher/SearchModeSwitcher';
import TextSearchMode from './TextSearchMode/TextSearchMode';
import TimeSearchMode from './TimeSearchMode/TimeSearchMode';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';

import useActions from 'hooks/useActions';

import styles from './SearchModal.module.css';

function SearchModal({ scrollBoxRef }) {
  const { setSearchMode, setIsSearchCollapsed, setSearchConditions } = useActions();
  const modalRef = useRef(null);

  const { searchConditions, activeSearchMode, isSearchModalCollapsed } = useSelector(
    (state) => state.messageFilters
  );
  const currentTab = useSelector((state) => state.feed.currentTab);

  useEffect(() => {
    setSearchConditions(null, null, scrollBoxRef);
  }, [currentTab, setSearchConditions, scrollBoxRef]);

  const classes = classNames(styles['search-panel'], {
    [styles['collapsed']]: isSearchModalCollapsed
  });

  const toggleIsSearchCollapsed = () => setIsSearchCollapsed(!isSearchModalCollapsed);

  return (
    <div ref={modalRef} className={classes}>
      <Button
        startIcon={<Icon size={18} fontName="far fa-equals" />}
        clickHandler={toggleIsSearchCollapsed}
        onlyIcon
        color="#00000026"
        textColor="#ffffff"
        variant="text"
        style={{
          marginLeft: 'auto',
          padding: '8px 12px',
          float: 'right'
        }}
      />
      <SearchModeSwitcher
        scrollBoxRef={scrollBoxRef}
        activeSearchMode={activeSearchMode}
        setSearchMode={setSearchMode}
        searchConditions={searchConditions}
      />
      <TextSearchMode
        scrollBoxRef={scrollBoxRef}
        activeSearchMode={activeSearchMode}
        searchConditions={searchConditions}
        setSearchConditions={setSearchConditions}
      />
      <TimeSearchMode
        scrollBoxRef={scrollBoxRef}
        activeSearchMode={activeSearchMode}
        searchConditions={searchConditions}
        setSearchConditions={setSearchConditions}
      />
      <LabelSearchMode
        scrollBoxRef={scrollBoxRef}
        activeSearchMode={activeSearchMode}
        searchConditions={searchConditions}
        setSearchConditions={setSearchConditions}
      />
      <MediaSearchMode
        scrollBoxRef={scrollBoxRef}
        activeSearchMode={activeSearchMode}
        searchConditions={searchConditions}
        setSearchConditions={setSearchConditions}
      />
      <ChannelModeSearch
        scrollBoxRef={scrollBoxRef}
        activeSearchMode={activeSearchMode}
        searchConditions={searchConditions}
        setSearchConditions={setSearchConditions}
      />
    </div>
  );
}

export default SearchModal;

SearchModal.propTypes = {
  searchConditions: PropTypes.object,
  activeSearchMode: PropTypes.string,
  isSearchModalCollapsed: PropTypes.bool
};
