import React from 'react';

import styles from './SearchInput.module.css';

class SearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }

  onOpen = () => {
    this.props.toggleSearchActivity(true);
    setTimeout(() => {
      this.textInput.current.focus();
    }, 300);
  };

  onClose = () => {
    this.props.changeHandler('');
    this.props.toggleSearchActivity(false);
  };

  onFocus = () => {
    if (typeof this.props.onFocusHandler === 'function') this.props.onFocusHandler();
  };

  render() {
    const classNames = require('classnames');
    const classes = classNames([styles['md__form-group'], styles['md__search-input']], {
      [styles['active']]: this.props.isActive
    });

    // Guide
    const { interactiveSearchBtnRef } = this.props;
    return (
      <div style={this.props.styles} className={classes}>
        <div>
          {this.props.isFiltered && <i className={styles['active-state']} />}
          <i
            ref={interactiveSearchBtnRef}
            onClick={this.onOpen}
            className={`${styles['md__search-icon']} si-btn far fa-search`}
          />
          <i onClick={this.onClose} className={`${styles['md__clear-icon']} si-btn far fa-times`} />
          <input
            ref={this.textInput}
            type="text"
            required="required"
            value={this.props.searchText}
            onChange={(event) => {
              this.props.changeHandler(event.target.value);
            }}
            onFocus={this.onFocus}
            disabled={!this.props.isActive}
          />
          <i className={styles.bar} />
        </div>
      </div>
    );
  }
}

export default SearchInput;
