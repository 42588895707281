import { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import reactStringReplace from 'react-string-replace';

import { Button, Icon } from 'components';

export const setStepIcons = (text) => {
  const getColor = (icon) => {
    switch (icon) {
      case 'fal fa-plus':
        return 'primary';
      case 'fal fa-times':
        return '#ff0033';
      case 'fal fa-minus':
        return 'primary';
      case 'far fa-sync-alt':
        return 'primary';
      case 'fas fa-clone':
        return 'primary';
      case 'far fa-search':
        return 'primary';
      default:
        return '#000000';
    }
  };

  let replacedText = reactStringReplace(text, /\[([\s\w]+)\]/g, (match, i) => (
    <Button
      key={i}
      className="align-middle d-inline-block"
      startIcon={
        <Icon
          size={18}
          viewBox="0 -28 453.99791 453"
          icon={match}
          style={{ enableBackground: '0 -28 453.99791 453' }}
        />
      }
      onlyIcon
      variant="message-action"
      color={getColor(match)}
      style={{ padding: 0 }}
    />
  ));

  replacedText = reactStringReplace(replacedText, /\[([-\s\w]+)\]/g, (match, i) => (
    <Button
      key={i}
      className="align-middle d-inline-block"
      startIcon={<Icon size={20} fontName={match} />}
      onlyIcon
      color={getColor(match)}
      variant="icon"
      style={{ padding: '6px 8px' }}
    />
  ));
  return replacedText;
};

export const joyrideCallback = (setRun, setStepIndex) => (data) => {
  const { action, index, type, status } = data;

  if (action === ACTIONS.CLOSE) {
    setRun(false);
    return;
  }

  if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
    // Need to set our running state to false, so we can restart if we click start again.
    setRun(false);
    setStepIndex(0);
  } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
    const newIndex = index + (action === ACTIONS.PREV ? -1 : 1);
    setStepIndex(newIndex);
  }
};
