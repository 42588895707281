import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import BlurWrapper from 'components/BlurWrapper/BlurWrapper';
import Portal from 'components/Portal/Portal';

import styles from './Menu2.module.css';

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.activatorRef = React.createRef();
    this.menuRef = React.createRef();
  }

  state = {
    position: {
      left: 0,
      top: 0
    }
  };

  activatorHandler = (e) => {
    e.stopPropagation();
    // e.nativeEvent.stopImmediatePropagation();
    if (this.props.isActive) this.props.activatorHandler();
  };

  getPosition = (btnRect, horizontal, vertical, horizontalAdd, verticalAdd) => {
    if (!this.menuRef.current || !btnRect) return;

    const menuRect = this.menuRef.current.getBoundingClientRect();
    const horizontalPos = this.getHorizontalPosition(horizontal, btnRect, menuRect, horizontalAdd);
    const verticalPos = this.getVerticalPosition(vertical, btnRect, menuRect, verticalAdd);

    return {
      ...horizontalPos,
      ...verticalPos
    };
  };

  getHorizontalPosition(horizontal, btnRect, menuRect, horizontalAdd) {
    if (horizontal === 'center') {
      const { left, width: btnWidth } = btnRect;
      const { width } = menuRect;

      return {
        left: left - Math.abs(width - btnWidth) / 2 + horizontalAdd
      };
    }
  }

  getVerticalPosition(vertical, btnRect, menuRect, verticalAdd) {
    if (vertical === 'top') {
      const { top: btnTop, bottom: btnBottom } = btnRect;
      const { height } = menuRect;
      let top = btnTop - height + verticalAdd;
      top = top < 0 ? btnBottom - verticalAdd : top;

      return {
        top
      };
    }
  }

  render() {
    const classes = classNames(styles['menu-modal'], {
      // 'unvisible': !this.props.isActive
    });
    const {
      activator,
      isActive,
      children,
      btnRect,
      horizontal,
      vertical,
      horizontalAdd = 0,
      verticalAdd = 0
    } = this.props;

    return (
      <div style={{ alignSelf: 'center' }}>
        <div ref={this.activatorRef}>{activator}</div>
        <Portal>
          <BlurWrapper isOpened={isActive} clickHandler={this.activatorHandler}>
            <div
              ref={this.menuRef}
              style={this.getPosition(btnRect, horizontal, vertical, horizontalAdd, verticalAdd)}
              className={classes}>
              {children}
            </div>
          </BlurWrapper>
        </Portal>
      </div>
    );
  }
}

export default Menu;

Menu.propTypes = {
  activator: PropTypes.element.isRequired,
  isActive: PropTypes.bool,
  children: PropTypes.element
};

Menu.defaultProps = {
  activator: false
};
