import { messageWithData } from './HOC/componentHOCs/messageWithData';
import { messagesWithData } from './HOC/containerHOCs/messagesWithData';
import { newsWithData } from './HOC/containerHOCs/newsWithData';
import { TABS } from './constants';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import SearchModal from 'pages/MessageFeed/SearchModal/SearchModal';
import SocialLinksModal from 'pages/MessageFeed/SocialLinksModal/SocialLinksModal';

import { useFeed } from 'containers/MessageFeedMessagesContainer/hooks/useFeed';
import { PinnedAuthors } from 'containers/PinnedAuthors/PinnedAuthors';

import { Message, NewsCard } from 'components';
import WeatherCard from 'components/FeedItem/WeatherCard';

import { useActions } from 'hooks';

import { AddItemButton } from './components/AddItemButton/AddItemButton';
import { FeedBody } from './components/FeedBody/FeedBody';
import { FeedItems } from './components/FeedItems/FeedItems';
import { FeedTabs } from './components/FeedTabs/FeedTabs';
import { NewItemForm } from './components/NewItemForm/NewItemForm';
import { NewItemsButtonContainer } from './components/NewItemsButtonContainer/NewItemsButtonContainer';

import styles from './Feed.module.css';

const tabsArray = [TABS.messages, TABS.news, TABS.advertisements, TABS.weather];

const containerHOCs = {
  messages: messagesWithData(FeedItems),
  news: newsWithData(FeedItems)
};
const componentHOCs = {
  messages: messageWithData(Message)
};

const tabComponents = {
  messages: Message,
  news: NewsCard,
  weather: WeatherCard,
  // TODO
  advertisements: WeatherCard
};

export function Feed({ hasInitialData }) {
  // add a scroll box ref for a new feed
  const scrollBoxMessagesRef = useRef();
  const scrollBoxNewsRef = useRef();
  const scrollBoxWeatherRef = useRef();
  const scrollBoxAdvertisementsRef = useRef();
  const scrollBoxes = useMemo(
    () => ({
      messages: scrollBoxMessagesRef,
      news: scrollBoxNewsRef,
      weather: scrollBoxWeatherRef,
      advertisements: scrollBoxAdvertisementsRef
    }),
    []
  );
  const [isNewItemFormOpen, setIsNewItemFormOpen] = useState({});
  const [initialFormValues, setInitialFormValues] = useState(null);
  const feed = useSelector((state) => state.feed);
  const { setListPaneRef, setCurrentTab } = useActions();
  const listPaneRef = useRef(null);
  const listPaneWidth = useSelector((state) => state.resize.listPaneWidth);
  const currentTab = useSelector((state) => state.settings.currentTab);

  useEffect(() => {
    setCurrentTab(feed.tabs?.includes(currentTab) ? currentTab : feed.tabs[0]);
  }, [currentTab, setCurrentTab, feed.tabs]);

  const { isNewAtTop } = useSelector((state) => state.settings.messageFeedConfig);

  const { activeMenu } = useSelector((state) => state.messageFilters);

  useEffect(() => {
    if (!hasInitialData) return;

    setListPaneRef({ ref: listPaneRef });
    listPaneRef.current.style.width = `calc(${listPaneWidth}% - 38px)`;
  }, [hasInitialData, setListPaneRef]);

  const {
    isLoaded,
    isLoading,
    loadPrevious,
    state,
    isSelected,
    clickHandler,
    messageActionsConfig,
    broadcastId,
    toggleFavorite,
    activeBroadcastData
  } = useFeed({ scrollBoxes, isNewAtTop });

  const setScrollBoxRef = useCallback(
    (ref, tab) => {
      scrollBoxes[tab].current = ref.current;
    },
    [scrollBoxes]
  );

  const openFormWithInitialValues = (values) => {
    setInitialFormValues({ ...values });
    setIsNewItemFormOpen((state) => ({
      ...state,
      [currentTab]: true
    }));
  };

  const tabComponentsToRender = useMemo(
    () =>
      Object.fromEntries(Object.entries(tabComponents).filter(([key]) => feed.tabs?.includes(key))),
    [feed.tabs]
  );

  if (!feed.tabs?.length) return null;

  return (
    <div className={styles.container} ref={listPaneRef}>
      {feed.tabs.length > 1 && (
        <FeedTabs items={feed.tabs} current={feed.currentTab} setCurrent={setCurrentTab}>
          {currentTab === TABS.news && (
            <AddItemButton
              isActive={isNewItemFormOpen[currentTab]}
              clickHandler={() =>
                setIsNewItemFormOpen((state) => ({
                  ...state,
                  [currentTab]: !state[currentTab]
                }))
              }
            />
          )}
        </FeedTabs>
      )}
      {Object.entries(tabComponentsToRender).map(([tab, Component]) => (
        <FeedBody
          withTabs={feed.tabs.length > 1}
          isLoaded={isLoaded}
          key={tab}
          Container={containerHOCs[tab] || FeedItems}
          Component={componentHOCs[tab] || Component}
          copyItemHandler={openFormWithInitialValues}
          hasInitialData={hasInitialData}
          tab={tab}
          isLoading={isLoading}
          loadPrevious={loadPrevious}
          state={state[tab]}
          isSelected={isSelected}
          clickHandler={clickHandler}
          messageActionsConfig={messageActionsConfig}
          broadcastId={broadcastId}
          toggleFavorite={toggleFavorite}
          activeBroadcastData={activeBroadcastData}
          setScrollBoxRef={setScrollBoxRef}>
          <>
            {hasInitialData && currentTab === TABS.messages && <PinnedAuthors />}
            <NewItemForm
              type={currentTab}
              scrollBoxes={scrollBoxes}
              initialFormValues={initialFormValues}
              isActive={isNewItemFormOpen[currentTab]}
              closeForm={() => setIsNewItemFormOpen(false)}
            />
            {activeMenu === 'search' ? (
              <SearchModal scrollBoxRef={scrollBoxes[tab]} />
            ) : activeMenu === 'socials' ? (
              <SocialLinksModal activeChannel="vkcomm" />
            ) : activeMenu === 'youtube' ? (
              <SocialLinksModal activeChannel="yt_live" />
            ) : null}
            <NewItemsButtonContainer
              isNewAtTop={isNewAtTop}
              tab={tab}
              scrollBoxRef={scrollBoxes[tab]}
            />
          </>
        </FeedBody>
      ))}
    </div>
  );
}
