import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useRef, useState } from 'react';

import { Textarea } from 'components';
import EmojiPicker from 'components/EmojiPicker/EmojiPicker';

import styles from './Input.module.css';

const propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'email', 'password']).isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  isRequired: PropTypes.bool,
  error: PropTypes.string,
  classNames: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  isFocusable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  changeHandler: PropTypes.func,
  blurHandler: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

const defaultProps = {
  type: 'text',
  value: '',
  error: '',
  placeholder: '',
  isRequired: false,
  classNames: '',
  size: 'medium',
  isFocusable: true,
  isDisabled: false,
  inputName: null,
  changeHandler: () => {},
  blurHandler: () => {}
};

function isDescendant(parent, child) {
  var node = child.parentNode;
  while (node != null) {
    if (node == parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}

const Input = forwardRef(
  (
    {
      label,
      type,
      value,
      placeholder,
      style,
      isRequired,
      error,
      className,
      size,
      changeHandler,
      blurHandler,
      focusHandler,
      isFocusable,
      isDisabled,
      width,
      inputName,
      emojiPickerOffset,
      handleEmojiClick,
      withEmojiPicker,
      multiline,
      emoji,
      inpStyle,
      contentViewRef
    },
    inputRef
  ) => {
    const [isShownPicker, setIsShownPicker] = useState(false);
    const childrenContainerRef = useRef(null);
    const [isShownChildren, setIsShownChildren] = useState(false);
    const containerRef = useRef(null);
    const classes = classNames(styles['round-input'], styles[`round-input--${size}`], {
      [styles['round-input--focusable']]: isFocusable,
      [styles['has-error']]: error.length > 0,
      [styles['disabled']]: isDisabled
    });

    useEffect(() => {
      const listener = (event) => {
        if (event.target.closest('.emoji-picker-react')) return;
        if (containerRef.current?.contains(event.target)) return;
        inputRef?.current?.blur();
        setIsShownChildren(false);
      };

      document.addEventListener('mousedown', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
      };
    }, []);

    const handleChildrenClick = () => {
      if (isShownPicker) return;
      childrenContainerRef?.current?.focus();
    };
    return (
      <div ref={containerRef} className={`${classes} ${className}`} style={{ ...style, width }}>
        {label && (
          <label htmlFor="input" className="round-input__label">
            {label}
          </label>
        )}
        <div className={styles.inputWrapper} ref={contentViewRef}>
          {multiline && (
            <Textarea
              text={value}
              setText={(newVal) => changeHandler({ target: { value: newVal } })}
              changeHandler={changeHandler}
              focusable={false}
              {...(isShownChildren && {
                textareaStyle: {
                  paddingRight: 30
                }
              })}
              name={inputName}
              ref={inputRef}
              type={type}
              placeholder={placeholder}
              value={value}
              required={isRequired}
              disabled={isDisabled}
              focusHandler={(event) => {
                if (withEmojiPicker) setIsShownChildren(true);
                focusHandler?.(event);
              }}
              blurHandler={(event) => {
                if (!withEmojiPicker) {
                  blurHandler?.(event);
                }
              }}
              // afterLabel={
              //   <TexareaLengthInfoView
              //     length={length}
              //     maxLength={Number(maxLength)}
              //   />
              // }
            />
          )}
        </div>
        {!multiline && (
          <input
            {...(isShownChildren && {
              style: {
                paddingRight: 50
              }
            })}
            name={inputName}
            ref={inputRef}
            type={type}
            placeholder={placeholder}
            value={value}
            required={isRequired}
            onChange={(e) => changeHandler(e)}
            disabled={isDisabled}
            onFocus={(event) => {
              if (withEmojiPicker) setIsShownChildren(true);
              focusHandler?.(event);
            }}
            onBlur={(event) => {
              if (!withEmojiPicker) {
                blurHandler?.(event);
              }
            }}
            style={inpStyle || {}}
          />
        )}
        {emoji && (
          <div
            onClick={handleChildrenClick}
            className={classNames(styles.emojiContainer, {
              [styles.emojiContainer_multiline]: multiline
            })}
            ref={childrenContainerRef}>
            <EmojiPicker
              offset={emojiPickerOffset}
              className={styles.form__emojiPicker}
              handleEmojiClick={handleEmojiClick}
            />
          </div>
        )}
        {error && <p className={styles['round-input__error']}>{error}</p>}
      </div>
    );
  }
);

export default Input;

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;
