import React, { forwardRef } from 'react';

import styles from './ControlsView.module.css';

export const ControlsView = forwardRef(({ children }, ref) => {
  return (
    <div ref={ref} className={styles['vote-controls']}>
      {children}
    </div>
  );
});
