import classNames from 'classnames';
import React from 'react';

import styles from './TextInput.module.css';

function TextInput(props) {
  const { label, preIcon, errorMsg, placeholder, styles: wrapperStyles } = props;
  const classes = classNames(styles['input-group-wrapper'], {
    [styles['with-pre-icon']]: Boolean(preIcon)
  });
  return (
    // <div>
    //   <input
    //     type="text"
    //     value={props.value}
    //     onChange={(event) => { props.changeHandler(event.target.value) }}
    //   />
    // </div>
    <div className={classes} style={wrapperStyles}>
      {label && (
        <label className={styles['label']} for="name">
          {label}
        </label>
      )}

      <div className={styles['input-wrapper']}>
        {preIcon && <i className={`${styles['pre-icon']} ${preIcon}`} />}
        <input
          className={styles['input']}
          type="text"
          placeholder={placeholder || ''}
          value={props.value}
          onChange={(event) => {
            props.changeHandler(event.target.value);
          }}
        />
      </div>
      {errorMsg && (
        <div className={styles['error-msg']}>
          <i className="fal fa-exclamation-triangle" />
          <span>{errorMsg}</span>
        </div>
      )}
    </div>
  );
}

export default TextInput;
