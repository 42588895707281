import { setMessageUserMessagesHiddenState } from './feed';
import { addSnack } from './snackBox';

import {
  blockAuthorSuccess,
  fetchMediaSuccess,
  fetchMoreMessagesReverse,
  fetchMoreMessagesSuccess,
  fetchUserInfoError,
  fetchUserInfoStart,
  fetchUserInfoSuccess,
  fetchUserMessagesError,
  fetchUserMessagesStart,
  fetchUserMessagesSuccess,
  setHasMoreMessages,
  setIsBlockingAuthorStatus,
  unblockAuthorSuccess,
  updateProfileInfo
} from 'store/actions/profileCard';

import {
  banProfile,
  getMoreProfileMessages,
  getProfileInfo,
  getProfileMedia,
  getProfileMessages,
  unbanProfile
} from 'api/dataLayer';

export function resetProfileState() {
  return (dispatch) => {
    dispatch(updateProfileInfo({}));
    dispatch(fetchUserInfoSuccess({}));
    dispatch(fetchMediaSuccess([]));
    dispatch(fetchUserMessagesSuccess([]));
    dispatch(setHasMoreMessages(true));
    dispatch(blockAuthorSuccess(null));
  };
}

export function setProfileInfo(msgId) {
  return (dispatch, getState) => {
    const { currentTab, ...feedState } = getState().feed;
    const messages = feedState[currentTab]?.items;

    const message = messages.find((el) => el.id === msgId);
    dispatch(updateProfileInfo(message));
  };
}

export function fetchUserInfo() {
  return async (dispatch, getState) => {
    dispatch(fetchUserInfoStart());
    const { senderNumber, channel } = getState().profileCard.profileInfo;
    try {
      const res = await getProfileInfo({ senderNumber, channel });
      dispatch(fetchUserInfoSuccess(res));
    } catch (ex) {
      dispatch(fetchUserInfoError(ex));
    }
  };
}

export function fetchMedia() {
  return async (dispatch, getState) => {
    const { senderNumber, channel } = getState().profileCard.profileInfo;
    const res = await getProfileMedia({ senderNumber, channel });
    dispatch(fetchMediaSuccess(res));
  };
}

export function fetchMessages(senderNumber, channel, isReverse = false) {
  return async (dispatch, getState) => {
    dispatch(fetchUserMessagesStart());
    try {
      let res = await getProfileMessages({ senderNumber, channel });
      let messages = isReverse ? res.reverse() : res;

      if (Array.isArray(messages))
        messages = messages.map((el) => ({
          ...el,
          favorite: el.favorite === 'true' ? 1 : 0
        }));
      dispatch(fetchUserMessagesSuccess(messages));
      if (res.length < 18) dispatch(setHasMoreMessages(false));
    } catch (ex) {
      if (ex.message === 'no messages') {
        dispatch(setHasMoreMessages(false));
      } else {
        dispatch(
          addSnack({
            type: 'danger',
            message: window.t('errLoadingMessagesByAuthor')
          })
        );
        dispatch(fetchUserMessagesError(ex));
      }
    }
  };
}

export function fetchMoreMessages(senderNumber, channel, isReverse = false) {
  return async (dispatch, getState) => {
    dispatch(fetchUserMessagesStart());
    const profileCardState = getState().profileCard;
    const loadedMessagesCount = profileCardState.messages.length;
    try {
      const res = await getMoreProfileMessages({
        senderNumber,
        channel,
        loadedMessagesCount
      });
      if (!Array.isArray(res)) return;

      const messages = res.map((el) => ({
        ...el,
        favorite: el.favorite === 'true' ? 1 : 0
      }));
      if (isReverse) dispatch(fetchMoreMessagesReverse(messages.reverse()));
      else dispatch(fetchMoreMessagesSuccess(messages));
    } catch (ex) {
      if (ex.message === 'no messages') {
        dispatch(setHasMoreMessages(false));
      } else {
        dispatch(
          addSnack({
            type: 'danger',
            message: window.t('errLoadingMessagesByAuthor')
          })
        );
        dispatch(fetchUserMessagesError(ex));
      }
    }
  };
}

export function blockAuthor(channel, senderNumber, author) {
  return async (dispatch, getState) => {
    const isBlocking = getState().profileCard.isBlocking;
    if (isBlocking) return;
    dispatch(setIsBlockingAuthorStatus(true));
    try {
      const res = await banProfile({ channel, senderNumber, author });
      dispatch(blockAuthorSuccess(res));
      dispatch(setMessageUserMessagesHiddenState({ channel, senderNumber, author }, true));
    } catch (ex) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errBlockingAuthor')
        })
      );
    } finally {
      dispatch(setIsBlockingAuthorStatus(false));
    }
  };
}

export function unblockAuthor(channel, senderNumber, author) {
  return async (dispatch, getState) => {
    const profileCard = getState().profileCard;
    const { isBlocking, blockedId } = profileCard;
    // const { channel, senderNumber, author } = getState().profileCard.profileInfo;

    if (isBlocking) return;
    if (!blockedId) return;
    dispatch(setIsBlockingAuthorStatus(true));

    try {
      await unbanProfile(blockedId);
      dispatch(unblockAuthorSuccess());
      dispatch(setMessageUserMessagesHiddenState({ channel, senderNumber, author }, false));
    } catch (ex) {
      console.error(ex);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errUnblockAuthor')
        })
      );
    } finally {
      dispatch(setIsBlockingAuthorStatus(false));
    }
  };
}
