import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const useNewItemsButton = ({ isNewAtTop, scrollBoxRef, tab }) => {
  const feed = useSelector((state) => state.feed);
  let hasNewItems, newItemsCount;
  if (feed[tab]) {
    hasNewItems = feed[tab]?.hasNewItems;
    newItemsCount = feed[tab]?.newItemsCount;
  }

  const scrollToNewItems = useCallback(() => {
    const scrollBox = scrollBoxRef.current;
    if (isNewAtTop) {
      scrollBox.scrollTop = 0;
    } else {
      const offsetHeight = scrollBox.offsetHeight;
      const scrollHeight = scrollBox.scrollHeight;
      scrollBox.scrollTop = scrollHeight - offsetHeight;
    }
  }, [isNewAtTop, scrollBoxRef]);

  const icon = `fas fa-arrow-${isNewAtTop ? 'up' : 'down'}`;
  const buttonStyle = {
    zIndex: 100,
    right: 35,
    border: 'solid 1px #cfd9e1',
    padding: '8px 14px',
    borderRadius: 25,
    ...(isNewAtTop ? { top: 20 } : { bottom: 20 })
  };

  return {
    icon,
    buttonStyle,
    hasNewItems,
    newItemsCount,
    scrollToNewItems
  };
};
