import styles from './ProgressBarView.module.css';

export function ProgressBarView({ percent }) {
  return (
    <div className={styles['progress']}>
      <span
        style={{
          transition: `width 500ms`,
          width: `${percent}%`
        }}
      />
    </div>
  );
}
