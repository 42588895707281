import {
  setModalCurrentImage,
  setModalImageIsOpened,
  setModalImageList,
  setModalImageUserInfo
} from 'store/actions/modalImage';

export function openModalImage(userInfo, imageList, currentImage) {
  return (dispatch) => {
    dispatch(setModalImageUserInfo(userInfo));
    dispatch(setModalImageList(imageList));
    dispatch(setModalCurrentImage(currentImage));
    dispatch(setModalImageIsOpened(true));
  };
}

export function closeModalImage() {
  return (dispatch) => {
    dispatch(
      setModalImageUserInfo({
        avatar: '',
        name: ''
      })
    );
    dispatch(setModalImageList([]));
    dispatch(setModalCurrentImage(null));
    dispatch(setModalImageIsOpened(false));
  };
}
