import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { useActions, useLocalStorage } from 'hooks';

import { MainGuideContext } from 'providers/MainGuideProvider';

export const messagesWithData = (WrappedComponent) =>
  function ({ ...props }) {
    const { authors } = useSelector((state) => state.settings);
    const { togglePinnedAuthor } = useActions();

    const { isFeedbackEnabled, answer } = useSelector((state) => state.settings.messageFeedConfig);
    const [userInformation] = useLocalStorage('userInfo', null);

    const { newBtnRef } = useContext(MainGuideContext);

    return (
      <WrappedComponent
        newBtnRef={newBtnRef}
        quickReplies={answer}
        userInformation={userInformation}
        withFeedback={isFeedbackEnabled}
        canPin
        authors={authors}
        togglePinnedAuthor={(el) => togglePinnedAuthor(el, [...props.items].reverse())}
        {...props}
      />
    );
  };
