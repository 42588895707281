import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { joyrideCallback, setStepIcons } from 'helpers/guide';

export const useMainGuide = ({ type }) => {
  const [run, setRun] = useState(false);
  const [stepIndex, setStep] = useState(0);
  const { listPane, workPane } = useSelector((state) => state.resize);
  const newBtnRef = useRef();
  const leftToolbarRef = useRef();
  const interactivesBtn = useRef();
  const interactiveToolbarRef = useRef();
  const interactiveSearchBtnRef = useRef();
  const interactiveSearchMenuRef = useRef();
  const handleJoyrideCallback = joyrideCallback(setRun, setStep);

  const steps = [
    {
      content: <h2>{window.t && window.t('mainGuideStep0')}</h2>,
      placement: 'center',
      target: 'body'
    },
    {
      content: <p>{window.t && window.t('mainGuideStep1')}</p>,
      spotlightPadding: 5,
      target: leftToolbarRef?.current || 'body',
      hideBackButton: true,
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'right'
    },
    {
      content: <p>{window.t && window.t('mainGuideStep2')}</p>,
      spotlightPadding: 5,
      target: listPane?.current || 'body',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'right'
    },
    {
      content: (
        <p
          dangerouslySetInnerHTML={{
            __html: window.t && window.t('mainGuideStep3')
          }}
        />
      ),
      spotlightPadding: 5,
      target: newBtnRef?.current || 'body',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'top'
    },
    {
      content: <p>{window.t && window.t('mainGuideStep4')}</p>,
      spotlightPadding: 5,
      target: interactivesBtn?.current || 'body',
      hideBackButton: true,
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'right',
      spotlightClicks: true,
      hideCloseButton: true,
      hideFooter: true
    },
    {
      content: <p>{window.t && window.t('mainGuideStep5')}</p>,
      spotlightPadding: 5,
      target: workPane?.current || 'body',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'left'
    },
    {
      content: <p>{setStepIcons(window.t && window.t('mainGuideStep6'))}</p>,
      spotlightPadding: 5,
      target: interactiveToolbarRef?.current || 'body',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'left'
    },
    {
      content: <p>{setStepIcons(window.t && window.t('mainGuideStep7'))}</p>,
      spotlightPadding: 5,
      target: interactiveSearchBtnRef?.current || 'body',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'left',
      spotlightClicks: true,
      hideCloseButton: true,
      hideFooter: true
    },
    {
      content: <p>{window.t && window.t('mainGuideStep8')}</p>,
      spotlightPadding: 5,
      target: interactiveSearchMenuRef?.current || 'body',
      disableBeacon: true,
      disableOverlay: false,
      disableOverlayClose: true,
      placement: 'bottom',
      spotlightClicks: true,
      hideCloseButton: true,
      hideFooter: true
    },
    {
      content: <p>{window.t && window.t('mainGuideStep9')}</p>,
      placement: 'center',
      target: 'body',
      disableOverlay: false
    },
    {
      content: <h2>{window.t && window.t('interactiveFinish')}</h2>,
      placement: 'center',
      target: 'body'
    }
  ];

  !newBtnRef.current && steps.splice(3, 1);

  const setStepIndex = (clb) => {
    if (run) {
      setStep(clb);
    }
  };

  return {
    run,
    startGuide: () => {
      setStep(0);
      setRun(true);
    },
    steps,
    stepIndex,
    setStepIndex,
    handleJoyrideCallback,
    newBtnRef,
    leftToolbarRef,
    interactivesBtn,
    interactiveToolbarRef,
    interactiveSearchBtnRef,
    interactiveSearchMenuRef
  };
};
