import React, { useMemo } from 'react';

import { ClearTitreMenuContainer } from 'containers/ClearTitreMenuContainer/ClearTitreMenuContainer';

import { Button, Icon, Menu, SvgCheckbox } from 'components';

export function BroadcastControlsView({
  settingsClickHandler,
  startStopData,
  msgCreatorClickHandler,
  clearBtn,
  isMenuActive,
  clearMenuPosition,
  exportButtons,
  toggleClearMenu,
  visibility,
  changeVisibility,
  startButtonRef,
  addClearRef,
  clearBroadcastHandler,
  broadcastId,
  favoriteMsg,
  setFavoriteMsg
}) {
  const statusFavorite = useMemo(() => {
    return favoriteMsg.find((el) => el.id === broadcastId && el.type === 'broadcast');
  }, [favoriteMsg]);

  const { startStopIcon, startStopTitle, startStopColor, startStopClickHandler } = startStopData;
  return (
    <div className="d-flex align-center z-20 flex-1">
      <Button
        startIcon={<Icon size={20} fontName="fal fa-cog" />}
        onlyIcon
        variant="icon"
        color="#6f6f6f"
        clickHandler={settingsClickHandler}
        title={window.t('settings')}
        style={{ padding: '6px 8px' }}
      />
      <Button
        startIcon={<Icon size={20} fontName={startStopIcon} />}
        ref={startButtonRef}
        onlyIcon
        variant="icon"
        color={startStopColor}
        clickHandler={startStopClickHandler}
        title={window.t(startStopTitle)}
        style={{ padding: '6px 8px' }}
      />
      <div ref={addClearRef} className="d-flex">
        <Button
          startIcon={<Icon size={20} fontName="fal fa-plus" />}
          onlyIcon
          variant="icon"
          clickHandler={msgCreatorClickHandler}
          title={window.t('addMessage')}
          key={`btn${11}`}
          style={{ padding: '6px 8px' }}
        />
        <Menu
          activator={clearBtn}
          activatorHandler={toggleClearMenu}
          isActive={isMenuActive}
          left={clearMenuPosition ? clearMenuPosition.left : 0}
          top={clearMenuPosition ? clearMenuPosition.top : 0}
          key="menu">
          <ClearTitreMenuContainer
            broadcastId={broadcastId}
            exportButtons={exportButtons}
            toggleClearMenu={toggleClearMenu}
          />
        </Menu>
      </div>
      <SvgCheckbox
        isChecked={visibility}
        changeHandler={changeVisibility}
        className="ml-3"
        style={{
          color: '#6f6f6f',
          fontSize: 16,
          opacity: 0.75
        }}
        key="svg-cb">
        {window.t('titerVisibility')}
      </SvgCheckbox>
      <Button
        startIcon={
          <Icon
            size={18}
            fontName={`${statusFavorite && statusFavorite.isFavorite ? 'fa' : 'fal'} fa-star`}
          />
        }
        onlyIcon
        variant="message-action"
        clickHandler={() => {
          if (!statusFavorite) {
            setFavoriteMsg([
              ...favoriteMsg,
              {
                id: broadcastId,
                type: 'broadcast',
                isFavorite: true
              }
            ]);
          } else {
            setFavoriteMsg([
              ...favoriteMsg.filter((el) => el.id !== statusFavorite.id),
              {
                id: broadcastId,
                type: 'broadcast',
                isFavorite: !statusFavorite.isFavorite
              }
            ]);
          }
        }}
        title={window.t(
          statusFavorite && statusFavorite.isFavorite ? 'allMessages' : 'favoriteMsg'
        )}
        style={{ opacity: statusFavorite && statusFavorite.isFavorite && 1 }}
      />
      <Button
        startIcon={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon size={20} fontName="fas fa-broom" />
            <Icon style={{ marginLeft: 5 }} size={20} fontName="fal fa-times" />
          </div>
        }
        onlyIcon
        variant="icon"
        color="#ff0033"
        key="clear"
        clickHandler={clearBroadcastHandler}
        title={window.t('clearAll')}
        style={{ padding: '6px 8px', marginLeft: 'auto' }}
      />
    </div>
  );
}
