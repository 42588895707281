import { MessageCreatorView } from './MessageCreatorView';
import { fields } from './constants';
import React from 'react';

import { useMessageCreator } from './hooks/useMessageCreator';

export function MessageCreatorContainer({ toggleMessageCreator }) {
  const { changeChannel, createdMessage, updateMessageField, clearAll, save } = useMessageCreator({
    toggleMessageCreator
  });

  return (
    <MessageCreatorView
      changeChannel={changeChannel}
      createdMessage={createdMessage}
      fields={fields}
      updateMessageField={updateMessageField}
      clearAll={clearAll}
      toggleMessageCreator={toggleMessageCreator}
      save={save}
    />
  );
}
