import Button from '../Button/Button';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Alert.module.css';

const propTypes = {
  message: PropTypes.string,
  type: PropTypes.oneOf(['danger', 'success', 'info', 'warning']).isRequired,
  closeHandler: PropTypes.func,
  disableHandler: PropTypes.func
};

function Alert({ message, type, closeHandler, disableHandler }) {
  return (
    <div className={`${styles['alert']} ${styles[type]}`}>
      <p dangerouslySetInnerHTML={{ __html: message }} />
      <div className="d-flex justify-end mt-2">
        {disableHandler && (
          <Button color="#5d5d5d61" variant="contained" clickHandler={disableHandler}>
            Don't show again
          </Button>
        )}
        {closeHandler && (
          <Button variant="contained" className="ml-2" clickHandler={closeHandler}>
            {window.t('close')}
          </Button>
        )}
      </div>
    </div>
  );
}

export default Alert;

Alert.propTypes = propTypes;
