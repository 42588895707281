import PropTypes from 'prop-types';
import React from 'react';

import { getIconImage } from 'helpers/image';

import styles from './ListPaneMod.module.css';

function ListPaneMod({ setNewMessagesPosition, isNewAtTop }) {
  return (
    <div>
      <h3 className={styles['lp-settings__title']}>{window.t('panelViewWithMessages')}</h3>
      <div className={styles['select-msg-view']}>
        <label className={styles['lp-settings__label']} htmlFor="uptodown">
          {/* onchange="changeMsgView(true)"  */}
          <input
            onChange={() => {
              setNewMessagesPosition(false);
            }}
            className={styles['lp-settings__input']}
            checked={!isNewAtTop}
            type="radio"
            name="pos"
            id="uptodown"
          />
          <img src={getIconImage('sort.svg')} alt="sort" title="Сверху вниз" />
          <span className={styles['lp-settings__span']}>{window.t('topDown')}</span>
        </label>
        <label className={styles['lp-settings__label']} htmlFor="downtoup">
          {/* onchange="changeMsgView(false)" */}
          <input
            onChange={() => {
              setNewMessagesPosition(true);
            }}
            className={styles['lp-settings__input']}
            checked={isNewAtTop}
            name="pos"
            id="downtoup"
            type="radio"
          />
          <img
            src={getIconImage('sort.svg')}
            className={styles['rotated-img']}
            alt="sort"
            title="Снизу вверх"
          />
          <span className={styles['lp-settings__span']}>{window.t('upwards')}</span>
        </label>
      </div>
    </div>
  );
}

export default ListPaneMod;

ListPaneMod.propTypes = {
  setNewMessagesPosition: PropTypes.func,
  isNewAtTop: PropTypes.bool
};
