import { AdvBlock } from '../AdvBlock';
import { NewsBlock } from '../NewsBlock';
import { WeatherBlock } from '../WeatherBlock';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import styles from './ContentListView.module.css';

const propTypes = {
  contentId: PropTypes.string,
  blocks: PropTypes.arrayOf(PropTypes.object),
  removeBlock: PropTypes.func,
  moveUp: PropTypes.func,
  moveDown: PropTypes.func
};

const components = {
  weather: WeatherBlock,
  news: NewsBlock,
  avito: AdvBlock
};

export const ContentListView = memo(
  ({
    contentId,
    blocks,
    removeBlock,
    moveUp,
    moveDown,
    setStepIndex,
    newsBlockRef,
    textToSpeechRef,
    selectedType,
    textEffects,
    blockTypes,
    content_rule,
    initialAudioFiles,
    activeBlocksLength
  }) => {
    let order = 0;

    return (
      <div className={styles['news-list']}>
        {blocks.map((block, i) => {
          if (block.isActive) order++;

          if (!selectedType) return null;

          const ContentBlock = components[selectedType.value];

          return (
            <ContentBlock
              {...(i === 0 && { newsBlockRef })}
              {...(i === 0 && { textToSpeechRef })}
              initialAudioFiles={initialAudioFiles}
              content_rule={content_rule}
              blockTypes={blockTypes}
              textEffects={textEffects}
              isFirst={i === 0}
              isLast={i === blocks.length - 1}
              order={block.isActive ? order : null}
              contentId={contentId}
              key={block.id}
              block={block}
              moveUp={moveUp}
              moveDown={moveDown}
              removeBlock={removeBlock}
              setStepIndex={setStepIndex}
              blocks={blocks}
              activeBlocksLength={activeBlocksLength}
            />
          );
        })}
      </div>
    );
  }
);

ContentListView.propTypes = propTypes;
