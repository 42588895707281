import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './ChartStatsHeader.module.css';

function ChartStatsHeader({ chartStart, chartEnd, isActive }) {
  return (
    <h3 className={styles['chart-stats-header']}>
      {isActive ? (
        <div className="chart-stats-header__active">
          <p className={styles['saving']}>
            {`${window.t('dataCollection')} ${window.t('from')}`}
            <span className="stats-start-time">
              &nbsp;{chartStart ? format(new Date(chartStart), 'HH:mm') : '...'}
              &nbsp;
            </span>
            {window.t('to')}
            <span className="stats-end-time">
              &nbsp;{chartEnd ? format(new Date(chartEnd), 'HH:mm') : '...'}
              &nbsp;
            </span>
            <span className={styles['dot']}>.</span>
            <span className={styles['dot']}>.</span>
            <span className={styles['dot']}>.</span>
          </p>
        </div>
      ) : (
        <div className="chart-stats-header__unactive">
          <p className={styles['chart-header-text']}>
            {`${window.t('etherStatistics')} ${window.t('from')}`}
          </p>
          <span className="stats-start-time">
            &nbsp;{chartStart ? format(new Date(chartStart), 'HH:mm') : '...'}
            &nbsp;
          </span>
          {window.t('to')}
          <span className="stats-end-time">
            &nbsp;{chartEnd ? format(new Date(chartEnd), 'HH:mm') : '...'}
          </span>
          .
        </div>
      )}
    </h3>
  );
}

export default ChartStatsHeader;

ChartStatsHeader.propTypes = {
  chartStart: PropTypes.string,
  chartEnd: PropTypes.string,
  isActive: PropTypes.bool
};
