import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Button, Icon } from 'components';

import { INTERACTIVES } from 'store/constants';

import { getUIDateFromDBDate } from 'helpers/dateTime';
import { getIconImage } from 'helpers/image';

import styles from './SessionRowPinned.module.css';

const propTypes = {
  rowData: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  selectSession: PropTypes.func.isRequired,
  openSession: PropTypes.func.isRequired
};

const defaulProps = {
  rowData: {},
  isSelected: false,
  selectSession: () => {},
  openSession: () => {}
};

function SessionRow({
  rowData,
  isSelected,
  selectSession,
  openSession,
  pinInteractives,
  togglePinHandler
}) {
  const [timeoutId, setTimeoutId] = useState(null);

  const getActivityClass = (activity) => {
    const ActivityTypesArray = ['created', 'active', 'paused', 'finished'];
    return activity && ActivityTypesArray.indexOf(activity) > -1
      ? styles[activity]
      : styles.created;
  };

  const {
    id,
    isHidden,
    type,
    message_count,
    votes_count,
    activity,
    tag,
    start,
    finish,
    title,
    description,
    memo,
    slot_info
  } = rowData;

  const isPinnedInteractive = Boolean(
    pinInteractives.find((el) => el.id === id && el.type === type)
  );

  const rowClasses = classNames(styles['session-row'], {
    [styles['selected']]: isSelected,
    hidden: isHidden
  });

  const btnClasses = classNames(styles.btnPin, {
    [styles.pinned]: isPinnedInteractive
  });
  const activityClasses = classNames(styles.activity, getActivityClass(activity));
  const count =
    type === INTERACTIVES.broadcast ? message_count : type === INTERACTIVES.vote ? votes_count : '';

  function TimeSpan({ time, type }) {
    return (
      <>
        {time ? (
          <span className={styles[type]} style={{ paddingRight: '15px' }}>
            {type === 'start' ? 'Начало' : 'Окончание'}
            <br />
            {time}
          </span>
        ) : (
          <span className={styles[type]} />
        )}
      </>
    );
  }

  const clickHandler = (event) => {
    if (!timeoutId)
      setTimeoutId(
        setTimeout(() => {
          clearTimeout(timeoutId);
          setTimeoutId(null);

          if (rowData.broadcastId) {
            selectSession(
              [`${type}_${id}`, `broadcast_${rowData.broadcastId}_duplicate`],
              event.altKey
            );
          } else {
            selectSession([`${type}_${id}`], event.altKey);
          }
        }, 100)
      );
  };

  const dblClickHandler = () => {
    clearTimeout(timeoutId);
    setTimeoutId(null);
    openSession({ id, type });
  };

  return (
    <li
      id={id}
      onClick={clickHandler}
      onDoubleClick={dblClickHandler}
      className={rowClasses}
      draggable={true}>
      <div>
        <div className={`${styles['module-type']} ${styles[`type-${type}`]}`} />
        <div className={activityClasses} />
      </div>
      <div>
        <div className={styles['key-tag']}>{tag}</div>
        <TimeSpan time={start} type="start" />
        <TimeSpan time={finish} type="finish" />
        <span className={styles.votes}>
          {type === INTERACTIVES.broadcast
            ? window.t('messages')
            : type === INTERACTIVES.vote
            ? window.t('votes')
            : ''}
          {(type === INTERACTIVES.broadcast ||
            type === INTERACTIVES.vote ||
            type === INTERACTIVES.quiz) && <span className={styles.msgsCount}>{count || 0}</span>}
          {type === INTERACTIVES.content && slot_info && (
            <div className="d-flex">
              <span>
                {window.t('type')}:{' '}
                {slot_info?.type ? window.t(slot_info?.type) : window.t('notSelected')}
              </span>
              <span className="ml-2">
                {window.t('slot')}: {slot_info?.name || window.t('notSelected')}
              </span>
              <span className="ml-2">
                {window.t('publishDate')}:{' '}
                {slot_info?.publish_date
                  ? getUIDateFromDBDate(slot_info?.publish_date)
                  : window.t('notPublished')}
              </span>
            </div>
          )}
        </span>
        <div className={styles.title}>{title || ''}</div>
        <div className={styles.description}>{description || ''}</div>
        <div className={styles.memo}>{memo || ''}</div>
      </div>
      {/* this.getCreationDate */}
      {/* <div className={styles['date_time_item']}>{rowData.creation}</div> */}
      <Button
        className={btnClasses}
        title={window.t(isPinnedInteractive ? 'detach' : 'attach')}
        onlyIcon
        startIcon={
          <Icon
            size={16}
            style={{
              transform: isPinnedInteractive ? 'rotateZ(45deg)' : ''
            }}
            fontName="fad fa-thumbtack"
          />
        }
        variant="icon"
        color={isPinnedInteractive ? 'primary' : '#ccc'}
        clickHandler={(e) => {
          e.stopPropagation();
          togglePinHandler(rowData);
        }}
      />
      <span
        onClick={() => openSession({ id, type })}
        data-action="open-session"
        className={`${styles['lbSess__open-btn']} ${styles['lbsess-btn']}`}>
        <img src={getIconImage('right-arrow-blue@2x.png')} alt="arrow" />
      </span>
    </li>
  );
}

export default SessionRow;

SessionRow.propTypes = propTypes;
SessionRow.defaulProps = defaulProps;
