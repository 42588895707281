import PropTypes from 'prop-types';
import React, { forwardRef, memo } from 'react';

import { TextField } from 'components';

const propTypes = {
  interactiveName: PropTypes.string,
  description: PropTypes.string,
  notes: PropTypes.string,
  changeInteractiveName: PropTypes.func,
  changeDescription: PropTypes.func,
  changeNotes: PropTypes.func,
  updateInteractiveTitle: PropTypes.func,
  changeSessionInfo: PropTypes.func
};

export const InteractiveSettingsFormView = memo(
  forwardRef(
    (
      {
        interactiveName,
        description,
        notes,
        changeInteractiveName,
        changeDescription,
        changeNotes,
        updateInteractiveTitle,
        changeSessionInfo,
        extended = true
      },
      ref
    ) => {
      return (
        <div className="pt-5 -mt-5" ref={ref}>
          <div className="pb-3">
            <TextField
              value={interactiveName && interactiveName.toString()}
              changeHandler={(event) => changeInteractiveName(event.target.value)}
              blurHandler={(event) => {
                changeSessionInfo('interactiveName', event.target.value);
                updateInteractiveTitle();
              }}
              inputHeight="41px"
              inputFontSize="21px"
              borderStyle="dotted"
              label={window.t('interactiveName')}
            />
          </div>
          {extended && (
            <div className="d-flex pt-5 pb-4 justify-space-between">
              <TextField
                value={description?.toString()}
                changeHandler={(event) => changeDescription(event.target.value)}
                blurHandler={(event) => changeSessionInfo('description', event.target.value)}
                width="47%"
                inputHeight="30px"
                inputFontSize="14px"
                borderStyle="dotted"
                label={window.t('description')}
              />
              <TextField
                value={notes?.toString()}
                changeHandler={(event) => changeNotes(event.target.value)}
                blurHandler={(event) => changeSessionInfo('memo', event.target.value)}
                width="47%"
                inputHeight="30px"
                inputFontSize="14px"
                borderStyle="dotted"
                label={window.t('note')}
              />
            </div>
          )}
        </div>
      );
    }
  )
);

InteractiveSettingsFormView.propTypes = propTypes;
