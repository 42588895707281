import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAlertEnable: true,
  permission: 'default'
};

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    permissionChange: (state, action) => {
      const { permission } = action.payload;
      state.permission = permission;
    }
  }
});

export const notificationActions = notificationSlice.actions;
export const notificationReducer = notificationSlice.reducer;
