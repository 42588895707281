import {
  ADD_CHAT_MESSAGE,
  ADD_CHAT_MESSAGES,
  ADD_OWN_MESSAGE,
  FETCH_CHAT_MESSAGES_SUCCESS,
  HAS_MORE_NEXT_CHAT,
  HAS_MORE_PREVIOUS_CHAT,
  MAKE_CHAT_MESSAGE_SENDED,
  SET_AVAILABLE_CHAT_CHANNEL,
  SET_CHAT_SCROLLBOX_REF,
  SET_IS_SENDING_MESSAGE,
  SET_SELECTED_CHAT_CHANNEL
} from './actionTypes';

export function setAvailableChatChannel(channel, isActive) {
  return {
    type: SET_AVAILABLE_CHAT_CHANNEL,
    channel,
    isActive
  };
}

export function setSelectedChannel(channel, isSelected) {
  return {
    type: SET_SELECTED_CHAT_CHANNEL,
    channel,
    isSelected
  };
}

export function setChatScrollBoxRef(ref) {
  return {
    type: SET_CHAT_SCROLLBOX_REF,
    ref
  };
}

export function addOwnMessage(message) {
  return {
    type: ADD_OWN_MESSAGE,
    message
  };
}

export function makeMessageSended(msgId) {
  return {
    type: MAKE_CHAT_MESSAGE_SENDED,
    msgId
  };
}

export function fetchChatMessagesSuccess(messages) {
  return {
    type: FETCH_CHAT_MESSAGES_SUCCESS,
    messages
  };
}

export function addChatMessages(id, messages) {
  return {
    type: ADD_CHAT_MESSAGES,
    payload: {
      messageId: id,
      messages
    }
  };
}

export function updateHasMoreNext(flag) {
  return {
    type: HAS_MORE_NEXT_CHAT,
    flag
  };
}

export function updateHasMorePrevious(flag) {
  return {
    type: HAS_MORE_PREVIOUS_CHAT,
    flag
  };
}

export function setIsSendingMessage(payload) {
  return {
    type: SET_IS_SENDING_MESSAGE,
    payload
  };
}
