import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { TABS } from 'containers/Feed/constants';
import { useMessageFeedScrollBox } from 'containers/MessageFeedMessagesContainer/hooks/useMessageFeedScrollBox';

import { useActions } from 'hooks';

import styles from './FeedBody.module.css';

export function FeedBody({
  hasInitialData,
  Component,
  Container,
  className,
  children,
  isLoading,
  isLoaded,
  loadPrevious,
  state,
  isSelected,
  clickHandler,
  messageActionsConfig,
  broadcastId,
  toggleFavorite,
  activeBroadcastData,
  tab,
  setScrollBoxRef,
  copyItemHandler,
  withTabs
}) {
  const currentTab = useSelector((state) => state.feed.currentTab);

  const { isNewAtTop } = useSelector((state) => state.settings.messageFeedConfig);
  const { fetchActiveBroadcast } = useActions();

  const scrollBoxRef = useRef(null);

  useEffect(() => {
    if (!hasInitialData) return;

    if (currentTab === TABS.messages) {
      fetchActiveBroadcast();
    }
    setScrollBoxRef(scrollBoxRef, tab);
  }, [hasInitialData, setScrollBoxRef, fetchActiveBroadcast, tab, currentTab]);

  const { activeMenu } = useSelector((state) => state.messageFilters);

  const { onFeedScroll, loadPreviousButtonRef, scrollBoxPadding, containerRef } =
    useMessageFeedScrollBox({
      isLoaded,
      tab,
      scrollBoxRef,
      isNewAtTop,
      activeMenu,
      loadPrevious,
      isLoading
    });

  return (
    <Container
      hasMorePrevious={state?.hasMorePrevious}
      isLoaded={isLoaded}
      Component={Component}
      className={classNames(className, styles.body, {
        [styles.body__current]: tab === currentTab,
        [styles.body_withTabs]: withTabs
      })}
      loadPreviousButtonRef={loadPreviousButtonRef}
      hasInitialData={hasInitialData}
      isLoading={isLoading}
      loadPrevious={loadPrevious}
      items={state?.items}
      isSelected={isSelected}
      clickHandler={clickHandler}
      messageActionsConfig={messageActionsConfig}
      broadcastId={broadcastId}
      toggleFavorite={toggleFavorite}
      activeBroadcastData={activeBroadcastData}
      containerRef={containerRef}
      onFeedScroll={onFeedScroll}
      scrollBoxPadding={scrollBoxPadding}
      scrollBoxRef={scrollBoxRef}
      copyItemHandler={copyItemHandler}
      tab={tab}>
      {children}
    </Container>
  );
}
