import { useCallback, useEffect, useMemo, useState } from 'react';

import { useActions } from 'hooks';

import { PICTURE_LOADER_TYPES } from 'store/constants';

export const usePictureLoader = ({ type, picture }) => {
  const [isHover, setIsHover] = useState(false);
  const { openModalImage } = useActions();
  useEffect(() => {
    setIsHover(false);
  }, [picture]);

  const isModal = type === PICTURE_LOADER_TYPES.showModal;

  const closeBtnStyles = useMemo(
    () =>
      isModal
        ? {
            position: 'absolute',
            top: 0,
            right: 0,
            marginTop: 5,
            marginBottom: -5,
            border: '1px solid #524e4e',
            borderRadius: '50%',
            padding: '2px 6px',
            zIndex: 1
          }
        : {
            opacity: 1,
            padding: '4px 9px',
            background: 'transparent',
            width: '100%',
            height: '100%'
          },
    [isModal]
  );

  const openModal = useCallback(() => {
    const imageList = [
      {
        publishDate: '',
        src: picture,
        type: 'image'
      }
    ];
    openModalImage(null, imageList, picture);
  }, [picture, openModalImage]);

  return {
    setIsHover,
    openModal,
    isModal,
    closeBtnStyles
  };
};
