import Button from '../Button/Button';
import { createPortal } from 'react-dom';

import styles from './Modal.module.css';

const Modal = ({ children, onModalClose, showModal, onSaveHandler }) => {
  return createPortal(
    <div
      className={styles.modal}
      style={{
        opacity: showModal ? 1 : 0,
        pointerEvents: showModal ? 'all' : 'none'
      }}>
      <div
        onClick={onModalClose}
        role="button"
        className={styles.modal__backdrop}
        style={{
          display: showModal ? 'flex' : 'none'
        }}
      />
      <div className={styles.modal__content}>
        <div className={styles.modal__inner}>{children}</div>
        <footer className={styles.modal__footer}>
          <Button clickHandler={onModalClose}>{window.t('cancel')}</Button>
          <Button
            clickHandler={() => {
              onSaveHandler();
              onModalClose();
            }}>
            {window.t('save')}
          </Button>
        </footer>
      </div>
    </div>,
    document.getElementById('modal')
  );
};

export default Modal;
