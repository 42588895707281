import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { forwardRef, memo, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import { PictureLoaderContainer } from 'containers';

import {
  AudioPlayer,
  Button,
  Card,
  Icon,
  Select,
  SvgCheckbox,
  TextField,
  Textarea,
  Title
} from 'components';

import { PICTURE_LOADER_TYPES, WEATHER_CONDITIONS, WIND_DIRECTIONS } from 'store/constants';

import { orderedFields } from 'helpers/content';
import { selectedOptionByTitle } from 'helpers/interactives';

import { TextareaLengthInfoView } from './components/TextareaLengthInfoView';

import styles from './WeatherCardView.module.css';

const propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  removeBlock: PropTypes.func,
  updateBlockField: PropTypes.func,
  moveUp: PropTypes.func,
  moveDown: PropTypes.func,
  block: PropTypes.object,
  pictureChangeHandler: PropTypes.func,
  isImageUploading: PropTypes.bool,
  synthesisSpeech: PropTypes.func,
  isLoadingSynthesis: PropTypes.bool
};

export const WeatherCardView = memo(
  forwardRef(
    (
      {
        order,
        isFirst,
        isLast,
        removeBlock,
        updateBlockField,
        moveUp,
        moveDown,
        block,
        pictureChangeHandler,
        isImageUploading,
        synthesisSpeech,
        isLoadingSynthesis,
        controls,
        inputRef,
        textToSpeechRef,
        audioMetaData
      },
      ref
    ) => {
      const { isMinimized, isActive, isTouched } = block;

      return (
        <div>
          <AnimateHeight duration={600} height={isMinimized ? 62 : 'auto'}>
            <Card
              className={classNames(styles['weather-card'], {
                disabled: !isActive
              })}
              style={{ borderRadius: 2 }}>
              <div className="d-flex align-center justify-end">
                {order && <Title size={18}>№{order} </Title>}
                {isMinimized && (
                  <Title className="ml-2" size={18} color="#888">
                    {block?.caption?.value}
                  </Title>
                )}
                <Button
                  className="ml-auto"
                  startIcon={<Icon size={20} fontName="fal fa-chevron-up" />}
                  onlyIcon
                  variant="icon"
                  clickHandler={() => moveUp(block)}
                  style={{ padding: '6px 8px' }}
                  color={isFirst ? '#ccc' : 'primary'}
                  isDisabled={isFirst}
                />
                <Button
                  startIcon={<Icon size={20} fontName="fal fa-chevron-down" />}
                  onlyIcon
                  variant="icon"
                  clickHandler={() => moveDown(block)}
                  style={{ padding: '6px 8px' }}
                  color={isLast ? '#ccc' : 'primary'}
                  isDisabled={isLast}
                />
                <Button
                  startIcon={<Icon size={20} icon="minimize" />}
                  onlyIcon
                  variant="icon"
                  clickHandler={() => updateBlockField(block.id, 'isMinimized', !isMinimized, null)}
                  style={{ padding: '6px 8px' }}
                  color={isMinimized ? '#ccc' : 'primary'}
                />
                <SvgCheckbox
                  isChecked={isActive}
                  changeHandler={() => updateBlockField(block.id, 'isActive', !isActive, null)}
                  className="mr-2"
                />
                <Button
                  startIcon={<Icon size={20} fontName="fal fa-times" />}
                  onlyIcon
                  variant="icon"
                  color="#ff0033"
                  clickHandler={() => removeBlock(block.id)}
                  // title={window.t('clearTitr')}
                  style={{ padding: '6px 8px' }}
                />
              </div>

              <div className="d-flex flex-wrap justify-space-between">
                {orderedFields(block).map(([key, value], i) => {
                  const maxLength = value && value['max_length#'];

                  if (key === 'location') {
                    return (
                      <TextField
                        width="48%"
                        value={value.value}
                        changeHandler={(event) =>
                          updateBlockField(block.id, key, event.target.value)
                        }
                        label={window.t('location')}
                        className="mt-4"
                        inputHeight="30px"
                        inputFontSize="14px"
                      />
                    );
                  }
                  if (key === 'date') {
                    return (
                      <TextField
                        width="48%"
                        value={value.value}
                        changeHandler={(event) =>
                          updateBlockField(block.id, key, event.target.value)
                        }
                        label={window.t('date')}
                        className="mt-4"
                        inputFontSize="14px"
                        inputHeight="30px"
                      />
                    );
                  }
                  if (key === 't_day') {
                    return (
                      <TextField
                        width="48%"
                        value={value.value}
                        changeHandler={(event) =>
                          updateBlockField(block.id, key, event.target.value)
                        }
                        label={window.t('t_day')}
                        className="mt-2"
                        inputHeight="30px"
                        inputFontSize="14px"
                      />
                    );
                  }
                  if (key === 'feels_like') {
                    return (
                      <TextField
                        width="48%"
                        value={value.value}
                        changeHandler={(event) =>
                          updateBlockField(block.id, key, event.target.value)
                        }
                        label={window.t('feels_like')}
                        className="mt-2"
                        inputHeight="30px"
                        inputFontSize="14px"
                      />
                    );
                  }
                  if (key === 't_night') {
                    return (
                      <TextField
                        width="48%"
                        value={value.value}
                        changeHandler={(event) =>
                          updateBlockField(block.id, key, event.target.value)
                        }
                        label={window.t('t_night')}
                        className="mt-2"
                        inputFontSize="14px"
                        inputHeight="30px"
                      />
                    );
                  }
                  if (key === 'humidity') {
                    return (
                      <TextField
                        width="48%"
                        value={value.value}
                        changeHandler={(event) =>
                          updateBlockField(block.id, key, event.target.value)
                        }
                        label={window.t('humidity')}
                        className="mt-2"
                        inputHeight="30px"
                        inputFontSize="14px"
                      />
                    );
                  }
                  if (key === 'wind_speed') {
                    return (
                      <TextField
                        width="48%"
                        value={value.value}
                        changeHandler={(event) =>
                          updateBlockField(block.id, key, event.target.value)
                        }
                        label={window.t('wind_speed')}
                        className="mt-2"
                        inputHeight="30px"
                        inputFontSize="14px"
                      />
                    );
                  }
                  if (key === 'wind_direction') {
                    return (
                      <Select
                        label={window.t('wind_direction')}
                        options={WIND_DIRECTIONS}
                        selectedOption={selectedOptionByTitle(WIND_DIRECTIONS, value.value)}
                        setSelectedOption={(newVal) =>
                          updateBlockField(block.id, key, newVal.title)
                        }
                        width="100%"
                        className={styles.w48}
                        style={{
                          borderBottom: '1px solid',
                          borderRadius: '3px 3px 0 0',
                          paddingLeft: '0px'
                        }}
                      />
                    );
                  }
                  if (key === 'pressure') {
                    return (
                      <TextField
                        width="48%"
                        value={value.value}
                        changeHandler={(event) =>
                          updateBlockField(block.id, key, event.target.value)
                        }
                        label={window.t('pressure')}
                        className="mt-2"
                        inputHeight="30px"
                        inputFontSize="14px"
                      />
                    );
                  }
                  if (key === 'conditions') {
                    return (
                      <Select
                        label={window.t('conditions')}
                        options={WEATHER_CONDITIONS}
                        width="100%"
                        className={styles.w48}
                        selectedOption={selectedOptionByTitle(WEATHER_CONDITIONS, value.value)}
                        setSelectedOption={(newVal) =>
                          updateBlockField(block.id, key, newVal.title)
                        }
                        style={{
                          borderBottom: '1px solid',
                          borderRadius: '3px 3px 0 0',
                          paddingLeft: '0px'
                        }}
                      />
                    );
                  }
                  if (key === 'text_to_speech') {
                    return (
                      <Textarea
                        isValid={
                          !(
                            audioMetaData.duration > maxLength ||
                            (audioMetaData.duration === 0 && isTouched)
                          )
                        }
                        ref={textToSpeechRef}
                        inputRef={inputRef}
                        controls={controls}
                        key={`block${i}`}
                        text={value.value}
                        setText={(newVal) => updateBlockField(block.id, key, newVal)}
                        focusable={false}
                        label={window.t('textToRead')}
                        style={{
                          border: '0.5px solid #D0D0D0',
                          borderRadius: 2,
                          padding: '8px 10px',
                          margin: 0,
                          width: '100%'
                        }}
                        afterLabel={
                          <TextareaLengthInfoView
                            type="time"
                            length={audioMetaData.duration}
                            maxLength={Number(maxLength)}
                          />
                        }>
                        <div className="d-flex align-center mt-2">
                          <Button
                            className="mr-2"
                            clickHandler={() => synthesisSpeech(block.id, key)}
                            isLoading={isLoadingSynthesis}>
                            {window.t('generateSpeech')}
                          </Button>
                          <AudioPlayer
                            className="flex-grow"
                            src={value.src}
                            style={{
                              margin: 0,
                              padding: '8px 0'
                            }}
                          />
                          <Button
                            startIcon={<Icon size={20} fontName="fal fa-times" />}
                            onlyIcon
                            variant="icon"
                            color="#ff0033"
                            clickHandler={() => updateBlockField(block.id, key, null, 'src')}
                            // title={window.t('clearTitr')}
                            style={{ padding: '6px 8px' }}
                          />
                        </div>
                      </Textarea>
                    );
                  }
                  if (key === 'weather_image') {
                    return (
                      <PictureLoaderContainer
                        type={PICTURE_LOADER_TYPES.showModal}
                        style={{
                          width: 180,
                          height: 100,
                          backgroundPosition: '50% 50%',
                          border: '0.5px solid #D0D0D0',
                          marginTop: '10px'
                        }}
                        picture={value.value}
                        changeHandler={(event) => pictureChangeHandler(event, block.id, key)}
                        deleteHandler={() => pictureChangeHandler(null, block.id, key)}
                        isImageUploading={isImageUploading}
                      />
                    );
                  }
                })}
              </div>
            </Card>
          </AnimateHeight>
        </div>
      );
    }
  )
);

WeatherCardView.propTypes = propTypes;
