import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  interactives: [],
  isSetData: false
};

export const pinnedInteractivesSlice = createSlice({
  name: 'pinnedInteractives',
  initialState,
  reducers: {
    setPinnedInteractives: (state, action) => {
      state.interactives = action.payload;
      state.isSetData = true;
    },
    togglePinInteractive: (state, action) => {
      const isPinnedInteractive = Boolean(
        state.interactives.find(
          (el) => el.id === action.payload.id && el.type === action.payload.type
        )
      );
      if (isPinnedInteractive) {
        state.interactives = state.interactives.filter(
          (el) => el.type !== action.payload.type || el.id !== action.payload.id
        );
      } else {
        state.interactives.unshift(action.payload);
      }
    }
  }
});

export const pinnedInteractivesActions = pinnedInteractivesSlice.actions;
export const pinnedInteractivesReducer = pinnedInteractivesSlice.reducer;
