import { LeftToolbarView } from './LeftToolbarView';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { MainGuideContext } from 'providers/MainGuideProvider';

import { useLeftToolbar } from './hooks/useLeftToolbar';

export const LeftToolbarContainer = withRouter(({ history }) => {
  const currentProfile = useSelector((state) => state.profile.currentProfile);
  const { totalNoteCount, activeMenu, toggleHandler, changeRoute } = useLeftToolbar({ history });

  const {
    run,
    startGuide,
    steps,
    stepIndex,
    setStepIndex,
    handleJoyrideCallback,
    leftToolbarRef,
    interactivesBtn
  } = useContext(MainGuideContext);

  return (
    <LeftToolbarView
      currentProfile={currentProfile}
      totalNoteCount={totalNoteCount}
      activeMenu={activeMenu}
      toggleHandler={toggleHandler}
      changeRoute={changeRoute}
      // guide

      run={run}
      startGuide={startGuide}
      steps={steps}
      stepIndex={stepIndex}
      setStepIndex={setStepIndex}
      handleJoyrideCallback={handleJoyrideCallback}
      leftToolbarRef={leftToolbarRef}
      interactivesBtn={interactivesBtn}
    />
  );
});
