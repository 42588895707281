import {
  ADD_LABEL_SUCCESS,
  ADD_RESPONSE_MESSAGE,
  CLEAR_LABELS_SUCCESS,
  DELETE_LABEL_SUCCESS,
  FETCH_FEED_ITEMS_SUCCESS,
  FILTER_FEED_ITEMS,
  HAS_MORE_PREVIOUS,
  INIT_TABS,
  MULTIPLE_SELECT_FEED_ITEMS,
  PUSH_FEED_ITEM,
  REMOVE_FEED_ITEM,
  REMOVE_FEED_ITEM_BY_KEY,
  SELECT_FEED_ITEM,
  SET_ACTIONS_MENU_ID,
  SET_ACTION_MENU_BTN_RECT,
  SET_CURRENT_TAB,
  SET_DRAGGING_FEED_ITEM_ID,
  SET_FEED_ITEM_FAV_STATUS,
  SET_FEED_ITEM_HIDDEN_STATE,
  SET_FEED_ITEM_LIKE_STATUS,
  SET_FEED_ITEM_MINIMIZED_STATUS,
  SET_HAS_NEW_FEED_ITEMS,
  SET_LABELS_SUCCESS,
  SHOW_ALL_FEED_ITEMS,
  UPDATE_FEED_ITEMS,
  UPDATE_FEED_ITEM_AUDIO
} from './actionTypes';

export function setHasNewFeedItems(hasNewItems, count = 0, tab) {
  return {
    type: SET_HAS_NEW_FEED_ITEMS,
    hasNewItems,
    count,
    tab
  };
}

export function selectFeedItem(id, tab) {
  return {
    type: SELECT_FEED_ITEM,
    id,
    tab
  };
}

export function multipleSelectFeedItems(id, tab) {
  return {
    type: MULTIPLE_SELECT_FEED_ITEMS,
    id,
    tab
  };
}

export function setCurrentTab(currentTab) {
  return {
    type: SET_CURRENT_TAB,
    currentTab
  };
}
export function fetchFeedItemsSuccess(items, tab) {
  return {
    type: FETCH_FEED_ITEMS_SUCCESS,
    items,
    tab
  };
}

export function updateFeedItems(items, tab) {
  return {
    type: UPDATE_FEED_ITEMS,
    items,
    tab
  };
}

export function filterFeedItems(toShow, tab) {
  return {
    type: FILTER_FEED_ITEMS,
    toShow,
    tab
  };
}

export function showAllFeedItems(tab) {
  return {
    type: SHOW_ALL_FEED_ITEMS,
    tab
  };
}

/* ACTIONS */

export function setFeedItemFavStatus(id, value, senderNumber, updatedFromSocket = false, tab) {
  return {
    type: SET_FEED_ITEM_FAV_STATUS,
    id,
    value,
    senderNumber,
    updatedFromSocket,
    tab
  };
}

export function setFeedItemLikeStatus(id, value, senderNumber, updatedFromSocket = false, tab) {
  return {
    type: SET_FEED_ITEM_LIKE_STATUS,
    id,
    value,
    senderNumber,
    updatedFromSocket,
    tab
  };
}

export function setFeedItemMinimizedStatus(
  id,
  value,
  senderNumber,
  updatedFromSocket = false,
  tab
) {
  return {
    type: SET_FEED_ITEM_MINIMIZED_STATUS,
    id,
    value,
    senderNumber,
    updatedFromSocket,
    tab
  };
}

export function setActionsMenuId(id, tab) {
  return {
    type: SET_ACTIONS_MENU_ID,
    id,
    tab
  };
}

export function removeFeedItem(id, senderNumber, updatedFromSocket = false, tab) {
  return {
    type: REMOVE_FEED_ITEM,
    id,
    senderNumber,
    updatedFromSocket,
    tab
  };
}

export function removeFeedItemByKey(key, value, tab) {
  return {
    type: REMOVE_FEED_ITEM_BY_KEY,
    key,
    value,
    tab
  };
}

export function setFeedItemHiddenState(id, value, senderNumber, updatedFromSocket = false, tab) {
  return {
    type: SET_FEED_ITEM_HIDDEN_STATE,
    id,
    value,
    senderNumber,
    updatedFromSocket,
    tab
  };
}

export function setLabelsSuccess(id, labels, senderNumber, updatedFromSocket = false, tab) {
  return {
    type: SET_LABELS_SUCCESS,
    id,
    labels,
    senderNumber,
    updatedFromSocket,
    tab
  };
}

export function addLabelSuccess(id, label, senderNumber, updatedFromSocket = false, tab) {
  return {
    type: ADD_LABEL_SUCCESS,
    id,
    label,
    senderNumber,
    updatedFromSocket,
    tab
  };
}

export function deleteLabelSuccess(id, label, senderNumber, updatedFromSocket = false) {
  return {
    type: DELETE_LABEL_SUCCESS,
    id,
    label,
    senderNumber,
    updatedFromSocket
  };
}

export function clearLabelsSuccess(id, senderNumber, updatedFromSocket = false) {
  return {
    type: CLEAR_LABELS_SUCCESS,
    id,
    senderNumber,
    updatedFromSocket
  };
}

export function setDraggingFeedItemId(id) {
  return {
    type: SET_DRAGGING_FEED_ITEM_ID,
    id
  };
}

export function setActionMenuBtnRect(rect) {
  return {
    type: SET_ACTION_MENU_BTN_RECT,
    rect
  };
}

export function addResponseMessage(id, message, senderNumber, updatedFromSocket = false, tab) {
  return {
    type: ADD_RESPONSE_MESSAGE,
    id,
    message,
    senderNumber,
    updatedFromSocket,
    tab
  };
}

export function updateFeedItemAudio(id, prevSrc, newSrc, senderNumber, updatedFromSocket = false) {
  return {
    type: UPDATE_FEED_ITEM_AUDIO,
    id,
    prevSrc,
    newSrc,
    senderNumber,
    updatedFromSocket
  };
}

export function pushFeedItem(item) {
  return {
    type: PUSH_FEED_ITEM,
    item
  };
}

export function initTabs(tabs) {
  return {
    type: INIT_TABS,
    tabs
  };
}

export function setHasMorePrevious(value) {
  return {
    type: HAS_MORE_PREVIOUS,
    value
  };
}
