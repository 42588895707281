import React from 'react';

import VideoPlayer from 'components/VideoPlayer/VideoPlayer';

import styles from './VideoMediaItem.module.css';

function VideoMediaItem({ src, openMedia }) {
  return (
    <div className={styles['profile-card__media-item']}>
      <VideoPlayer
        src={src}
        height="100%"
        isMini
        isExpanded={false}
        expandHandler={() => {
          openMedia(src);
        }}
      />
    </div>
  );
}

export default VideoMediaItem;
