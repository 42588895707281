import useActions from './useActions';
import { useEffect, useState } from 'react';

export const useInteractive = ({ match, location, type }) => {
  const { setISettingsInfoFromSession, setISettingsInfo, setOpenedInteractiveType } = useActions();
  const [isSettingsOpened, setIsSettingsOpened] = useState(false);

  useEffect(() => {
    const detail = location?.state?.detail;
    setISettingsInfoFromSession(match.params.id, type);

    if (detail) {
      const { isCreated } = detail;
      setIsSettingsOpened(isCreated);
    }
    return () => {
      setOpenedInteractiveType(null);
      setISettingsInfo({
        id: null,
        title: '',
        description: '',
        notes: ''
      });
    };
  }, [
    match.params.id,
    location,
    setISettingsInfoFromSession,
    setISettingsInfo,
    setOpenedInteractiveType
  ]);

  return {
    interactiveId: match.params.id,
    isSettingsOpened,
    setIsSettingsOpened
  };
};
