import React, { memo } from 'react';

import {
  VoteMessagesContainer,
  VoteQuestionContainer,
  VoteStatePanelContainer,
  VoteVariantsContainer,
  VoteWinnersContainer
} from 'containers';

import { InteracitveNotFoundView } from 'components';

import { INTERACTIVES } from 'store/constants';

export const VoteBodyView = memo(
  ({
    type,
    voteId,
    isVoteActive,
    isInteractiveExist,
    broadcastId,
    voteInfo,
    questionRef,
    variantRef
  }) => {
    return isInteractiveExist ? (
      <>
        <div className="pt-5">
          <VoteStatePanelContainer voteInfo={voteInfo} />
          {type === INTERACTIVES.quiz && <VoteWinnersContainer />}
          <div className="p-relative pb-5">
            <VoteQuestionContainer ref={questionRef} voteInfo={voteInfo} />
            <VoteVariantsContainer type={type} voteInfo={voteInfo} variantRef={variantRef} />
          </div>
        </div>
        {broadcastId && (
          <VoteMessagesContainer
            type={type}
            voteInfo={voteInfo}
            isVoteActive={isVoteActive}
            broadcastId={voteId === '113' ? '53' : broadcastId}
            rangeNumbers={voteInfo.rangeNumbers}
          />
        )}
      </>
    ) : (
      <InteracitveNotFoundView
        message={window
          .t(type === INTERACTIVES.quiz ? 'quizDoesNotExist' : 'voteDoesNotExist')
          .replace('[id]', voteId)}
      />
    );
  }
);
