import React, { memo } from 'react';

import { Button, ChannelPicker, TextField, Title } from 'components';

import styles from './MessageCreatorView.module.css';

export const MessageCreatorView = memo(
  ({
    changeChannel,
    createdMessage,
    fields,
    updateMessageField,
    clearAll,
    toggleMessageCreator,
    save
  }) => {
    return (
      <div className={styles['message-creator-dialog']}>
        <div className={styles['dlg-container']}>
          <Title className="text-center" weight="700">
            {window.t('newMessage')}
          </Title>
          <ChannelPicker
            title={window.t('channel')}
            titleClassName={styles['caption']}
            clickHandler={changeChannel}
            selectedChannel={createdMessage['channel']}
            imageSize={33}
            className={styles['channel-mode-btn']}
            activeClass={styles['selected']}
          />
          {fields.map(({ fieldName, labelKey }, i) => {
            return (
              <TextField
                key={i}
                isMinimized={false}
                preIconActivity={false}
                value={createdMessage[fieldName]}
                changeHandler={(event) =>
                  updateMessageField({
                    field: fieldName,
                    value: event.target.value
                  })
                }
                className="pt-5 pb2 pl-5"
                inputHeight="31px"
                inputFontSize="14px"
                borderStyle="dotted"
                label={window.t(labelKey)}
              />
            );
          })}

          <div className="d-flex pt-5 pl-5 pb-2">
            <Button clickHandler={clearAll}>{window.t('clearAll')}</Button>
            <Button className="ml-2" clickHandler={toggleMessageCreator}>
              {window.t('cancel')}
            </Button>
            <Button className="ml-2" clickHandler={save}>
              {window.t('save')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
);
