import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchText: '',
  bannedAuthors: []
};

export const bannedSlice = createSlice({
  name: 'banned',
  initialState,
  reducers: {
    setBannedSearchText: (state, action) => {
      const { searchText } = action.payload;
      return {
        ...state,
        searchText
      };
    },
    setBannedAuthors: (state, action) => {
      const { authors } = action.payload;
      return {
        ...state,
        bannedAuthors: authors
      };
    }
  }
});

export const bannedActions = bannedSlice.actions;
export const bannedReducer = bannedSlice.reducer;
