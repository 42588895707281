import React from 'react';

import styles from './RadioBtn.module.css';

function RadioBtn(props) {
  return (
    <div className={styles.radio}>
      <input
        onChange={props.changeHandler}
        className={styles['radio_input']}
        name="rate"
        id={`udio-message-${props.id}_rate${props.value}`}
        checked={props.isActive}
        type="radio"
        value={props.value}
      />
      <label
        className={styles['radio_label']}
        htmlFor={`udio-message-${props.id}_rate${props.value}`}>
        {props.value}
      </label>
    </div>
  );
}

export default RadioBtn;
