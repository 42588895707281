import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ChannelPicker } from 'components';

import styles from './ChannelModeSearch.module.css';

function ChannelModeSearch({
  searchConditions,
  setSearchConditions,
  activeSearchMode,
  scrollBoxRef
}) {
  const { channel } = searchConditions;

  const classes = classNames(styles['pane'], {
    hidden: activeSearchMode !== 'channel'
  });

  return (
    <div id="searchModeChannelTab" className={classes}>
      <ChannelPicker
        title={window.t('searchByChannels')}
        titleClassName={styles['title']}
        clickHandler={(newChannel) => {
          const newVal = channel === newChannel ? null : newChannel;
          setSearchConditions('channel', newVal, scrollBoxRef);
        }}
        selectedChannel={channel}
        imageSize={33}
        className={styles['channel-mode-btn']}
        activeClass={styles['selected']}
        wrapperClassName="justify-center"
      />
    </div>
  );
}

export default ChannelModeSearch;

ChannelModeSearch.propTypes = {
  searchConditions: PropTypes.object,
  setSearchConditions: PropTypes.func,
  activeSearchMode: PropTypes.string
};
