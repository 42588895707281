import copy from 'copy-to-clipboard';
import { useSelector } from 'react-redux';

import useActions from 'hooks/useActions';

import { exportMessage } from 'api/dataLayer';

export const useMessageActionsMenu = ({ messageId, hideModal = () => {} }) => {
  const {
    setFavState,
    setMinimizedState,
    deleteMessage,
    addMessageToBroadcast,
    blockAuthor,
    addSnack
  } = useActions();
  const { currentTab, ...feedState } = useSelector((state) => state.feed);
  const messages = feedState[currentTab]?.items;

  const { email: listEmails, telegram: listTelegrams } = useSelector(
    (store) => store.settings?.exportConfig
  );

  const exportMessageHandler = async (type, recipient, message) => {
    try {
      const res = await exportMessage(type, recipient, message);
      if (res.code !== 200) {
        throw new Error('Сообщение не отправлено!');
      }
      addSnack({
        type: 'success',
        message: 'Сообщение отправлено!'
      });
    } catch (e) {
      addSnack({
        type: 'danger',
        message: e.message
      });
    } finally {
      hideModal();
    }
  };
  const data = messages.find((el) => el.id === messageId);
  const { senderNumber, channel, author, isCreated, labels, favorite, content, title } = data || {};
  const isMinimized = false; // data.status && data.status.includes('minimized');

  const toggleMinimize = () => setMinimizedState(messageId, !isMinimized, senderNumber);
  const toggleFavState = () => setFavState(messageId, !Boolean(favorite), senderNumber);
  const copyText = () => {
    copy(content || title);
    addSnack({
      type: 'success',
      message: 'Текст скопирован'
    });
    hideModal();
  };
  const copyAuthor = () => {
    copy(author);
    addSnack({
      type: 'success',
      message: 'Имя скопировано'
    });
  };
  const copyPhone = () => {
    copy(senderNumber);
    addSnack({
      type: 'success',
      message: 'Номер скопирован'
    });
    hideModal();
  };
  const sendMessageToBroadcast = () => {
    addMessageToBroadcast(data);
    hideModal();
  };
  const blockAuthorHandler = () => {
    blockAuthor(channel, senderNumber, author);
    hideModal();
  };
  const deleteMessageHandler = () => {
    deleteMessage(messageId, senderNumber);
    hideModal();
  };

  const listItems = [
    // {
    //   title: isMinimized ? window.t('uncollapseMessage') : window.t('collapseMessage'),
    //   clickHandler: () => {
    //     toggleMinimize();
    //     hideModal();
    //   }
    // },
    // {
    //   title: Boolean(favorite) ? window.t('deleteFromFavourites') : window.t('addToFavourites'),
    //   clickHandler: () => {
    //     toggleFavState();
    //     hideModal();
    //   }
    // },
    {
      title: window.t('copyMessageText'),
      clickHandler: () => {
        copyText();
        hideModal();
      }
    },
    {
      title: window.t('copyAuthorName'),
      clickHandler: () => {
        copyAuthor();
        hideModal();
      }
    },
    {
      title: window.t('copyAuthorPhone'),
      clickHandler: () => {
        copyPhone();
        hideModal();
      }
    }
    // {
    //   title: window.t('sendMessageToInteractive'),
    //   clickHandler: () => {
    //     sendMessageToBroadcast();
    //     hideModal();
    //   }
    // }
  ];

  return {
    labels,
    listItems,
    listTelegrams,
    listEmails,
    isCreated: Boolean(+isCreated),
    blockAuthorHandler,
    deleteMessageHandler,
    exportMessageHandler
  };
};
