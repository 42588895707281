import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Button, Icon, Menu } from 'components';

const propTypes = {
  settingsMenu: PropTypes.element.isRequired
};

function ActionMenu({ settingsMenu }) {
  const [isActive, setIsActive] = useState(false);
  const [btnRect, setBtnRect] = useState(null);
  const clickHandler = (e) => {
    if (e) {
      const rect = e.currentTarget.getBoundingClientRect();
      setBtnRect(rect);
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
    setIsActive(!isActive);
  };
  const activator = (
    <Button
      startIcon={<Icon size={18} fontName="fal fa-cog" />}
      onlyIcon
      variant="message-action"
      color="#000000"
      clickHandler={clickHandler}
      title={window.t('options')}
      style={{ display: 'inline-flex', marginLeft: 5, padding: 0 }}
    />
  );

  const cloneProps = {
    hideModal: () => setIsActive(false)
  };

  const settingsMenuWithProps = React.cloneElement(settingsMenu, cloneProps);

  return (
    <Menu
      activator={activator}
      isActive={isActive}
      activatorHandler={setIsActive}
      left={btnRect ? btnRect.left : 0}
      top={btnRect ? btnRect.bottom : 0}>
      {settingsMenuWithProps}
    </Menu>
  );
}

export default ActionMenu;

ActionMenu.propTypes = propTypes;
