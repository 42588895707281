import { BroadcastControlsView } from './BroadcastControlsView';
import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';

import { useActions, useLocalStorage } from 'hooks';

import { confirm } from 'helpers/common';

import { useBroadcastControls } from './hooks/useBroadcastControls';
import { useClearMenu } from './hooks/useClearMenu';

const propTypes = {
  settingsClickHandler: PropTypes.func,
  msgCreatorClickHandler: PropTypes.func,
  visibility: PropTypes.bool,
  activity: PropTypes.string,
  broadcastId: PropTypes.string,
  exportButtons: PropTypes.object
};

export const BroadcastControlsContainer = memo(
  ({
    settingsClickHandler,
    msgCreatorClickHandler,
    visibility,
    activity,
    broadcastId,
    exportButtons,
    startButtonRef,
    addClearRef,
    setStepIndex
  }) => {
    const { removeAllBroadcastMessages } = useActions();
    const { startStopData, changeVisibility } = useBroadcastControls({
      msgCreatorClickHandler,
      activity,
      broadcastId,
      setStepIndex
    });

    const [favoriteMsg, setFavoriteMsg] = useLocalStorage('favoriteMsg', []);

    const { clearBtn, toggleClearMenu, isMenuActive, clearMenuPosition } = useClearMenu();

    const clearBroadcast = useCallback(async () => {
      // TODO
      removeAllBroadcastMessages();
    }, [removeAllBroadcastMessages]);

    const clearBroadcastHandler = useCallback(() => {
      const message = window.t('clearBroadcastQuestion');
      if (confirm(message)) {
        clearBroadcast();
      }
    }, [clearBroadcast]);

    return (
      <BroadcastControlsView
        settingsClickHandler={settingsClickHandler}
        startStopData={startStopData}
        msgCreatorClickHandler={msgCreatorClickHandler}
        clearBtn={clearBtn}
        isMenuActive={isMenuActive}
        clearMenuPosition={clearMenuPosition}
        exportButtons={exportButtons}
        toggleClearMenu={toggleClearMenu}
        visibility={visibility}
        changeVisibility={changeVisibility}
        startButtonRef={startButtonRef}
        addClearRef={addClearRef}
        clearBroadcastHandler={clearBroadcastHandler}
        broadcastId={broadcastId}
        // фильтр избранных сообщений в интрективах
        setFavoriteMsg={setFavoriteMsg}
        favoriteMsg={favoriteMsg}
      />
    );
  }
);

BroadcastControlsContainer.propTypes = propTypes;
