import { RSSListItem } from './RSSListItem/RSSListItem';
import { useEffect, useState } from 'react';

import { TABS } from 'containers/Feed/constants';

import { Button, Input, List, ListItem, Text, Title } from 'components';

import { useActions } from 'hooks';

import { rssDelete, rssGet, rssSubscribe, rssUnSubscribe } from 'api/dataLayer';

import styles from './RSSCollapseBody.module.css';

export function RSSCollapseBody({ setIsRSSConnected }) {
  const [RSSList, setRSSList] = useState([]);
  const [rssUrl, setRssUrl] = useState('');
  const { addSnack, removeFeedItemByKey } = useActions();

  const fetchRss = async () => {
    try {
      const data = await rssGet();
      if (data?.code !== 200) throw new Error('Something went wrong!');
      setRSSList(data?.body);
    } catch (e) {
      console.log(e.messages);
    }
  };

  useEffect(() => {
    fetchRss();
  }, []);

  useEffect(() => {
    if (RSSList.length === 0) {
      setIsRSSConnected(false);
      return;
    }
    setIsRSSConnected(true);
  }, [RSSList]);

  const subscribe = async (rssUrl) => {
    try {
      const data = await rssSubscribe(rssUrl);

      if (data?.code !== 200) throw new Error('Something went wrong!');

      const { body } = data;
      setRSSList((rss) => {
        const currentRSS = rss.find((item) => item.rssId === body?.rssId);
        if (currentRSS) {
          return rss.map((item) => {
            if (item.rssId !== body?.rssId) return item;
            return { ...item, status: 'sub' };
          });
        } else {
          return [...rss, body];
        }
      });
      addSnack({
        message: window.t('successfullySubscribed'),
        type: 'success'
      });
    } catch (error) {
      addSnack({
        message: window.t('errFailedRssValidation'),
        type: 'danger'
      });
    }
  };

  const unsubscribe = async (rssInfo) => {
    const { rssName, rssId } = rssInfo;
    try {
      const data = await rssUnSubscribe(rssId);

      if (data?.code !== 200) throw new Error('Something went wrong!');

      setRSSList((rss) =>
        rss.map((item) => {
          if (item.rssId !== rssId) return item;
          return { ...item, status: 'unsub' };
        })
      );
      removeFeedItemByKey('rss_name', rssName, TABS.news);
    } catch (error) {
      addSnack({
        message: window.t('errFailedRssUnSubscribe'),
        type: 'danger'
      });
    }
  };

  const remove = async (rssInfo) => {
    const { rssName, rssId } = rssInfo;
    try {
      const data = await rssDelete(rssId);

      if (data?.code !== 200) throw new Error('Something went wrong!');

      setRSSList((rss) => rss.filter((item) => item.rssId !== rssId));
      removeFeedItemByKey('rss_name', rssName, TABS.news);
    } catch (error) {
      addSnack({
        message: window.t('errFailedRssRemove'),
        type: 'danger'
      });
    }
  };

  return (
    <div style={{ padding: '10px 0' }}>
      <Title className="d-inline-block" weight="normal" style={{ marginLeft: 50 }}>
        {window.t('rssFeeds')}
      </Title>
      <Text tag="span" color="#808080">
        {` ${RSSList.length}`}
      </Text>
      <List noStyle style={{ marginLeft: 50 }}>
        {RSSList.map((item) => (
          <RSSListItem
            key={item.rssId}
            rssInfo={item}
            remove={remove}
            subscribe={subscribe}
            unsubscribe={unsubscribe}
          />
        ))}
        <ListItem noHover className="ml-0 mr-0 mt-2 mb-0 pa-0">
          <form className={styles['input-group']}>
            <Input
              value={rssUrl}
              placeholder="RSS URL"
              changeHandler={(e) => setRssUrl(e.target.value)}
            />
            <Button
              variant="icon"
              className="ml-1"
              type="button"
              clickHandler={() => {
                subscribe(rssUrl);
                setRssUrl('');
              }}>
              {window.t('subscribe')}
            </Button>
          </form>
        </ListItem>
        <div
          className={styles['rss-connect-info']}
          dangerouslySetInnerHTML={{ __html: window.t('rssConnectInfo') }}
        />
      </List>
    </div>
  );
}
