import className from 'classnames';
import React, { forwardRef, memo } from 'react';

import { Button, CycleButton, Icon, StopButton, Title } from 'components';

import { STATE_TYPES } from 'store/constants';

import styles from './BroadcastStateView.module.css';

export const BroadcastStateView = memo(
  forwardRef(
    (
      {
        isBroadcastStateFixed,
        workPaneWidth,
        broadcastState,
        toggleState,
        setIsBroadcastStateFixed,
        setStepIndex
      },
      ref
    ) => {
      return (
        <div
          ref={ref}
          className={className('d-flex align-center pt-4 pb-4 pl-5 pr-5 w-full', {
            [styles['states--fixed']]: isBroadcastStateFixed
          })}
          // style={{
          //     width: isBroadcastStateFixed ? `calc(${workPaneWidth}% - 39px)` : '100%'
          // }}
        >
          <Title
            style={{ whiteSpace: 'nowrap', height: '38px' }}
            className="align-self-start d-flex align-center"
            color="#808080">
            {window.t('titreState')}
          </Title>
          <div className="d-flex ml-2 flex-wrap">
            {broadcastState &&
              Object.entries(broadcastState).map(([key, { duration, type, value, active }]) =>
                type === STATE_TYPES.stop ? (
                  <StopButton
                    key={key + type}
                    duration={duration}
                    isActive={Boolean(+active)}
                    setIsActive={(isActive) => {
                      setStepIndex((v) => ++v);
                      toggleState(key, isActive);
                    }}>
                    {value || key}
                  </StopButton>
                ) : (
                  <CycleButton
                    key={key + type}
                    duration={duration}
                    isActive={Boolean(+active)}
                    setIsActive={(isActive) => {
                      setStepIndex((v) => ++v);
                      toggleState(key, isActive);
                    }}>
                    {value || key}
                  </CycleButton>
                )
              )}
          </div>
          <Button
            title={window.t(isBroadcastStateFixed ? 'detach' : 'attach')}
            onlyIcon
            startIcon={
              <Icon
                size={16}
                style={{
                  transform: isBroadcastStateFixed ? 'rotateZ(45deg)' : ''
                }}
                fontName="fad fa-thumbtack"
              />
            }
            variant="icon"
            color={isBroadcastStateFixed ? 'primary' : '#ccc'}
            style={{
              marginLeft: 'auto'
            }}
            clickHandler={() => {
              setIsBroadcastStateFixed((isFixed) => !isFixed);
            }}
          />
        </div>
      );
    }
  )
);
