import parsePhoneNumberFromString, { AsYouType } from 'libphonenumber-js';

export function getPhoneNumber(phone) {
  let result = phone;
  if (phone?.[0] !== '+') {
    result = `+${result}`;
  }

  const phoneNumber = parsePhoneNumberFromString(result);
  if (phoneNumber && phoneNumber.number) {
    result = new AsYouType().input(phoneNumber.number);
    return result;
  }

  return null;
}
