import classNames from 'classnames';

import { Button, Icon } from 'components';

import styles from './AddItemButton.module.scss';

export function AddItemButton({ isActive, clickHandler }) {
  return (
    <Button
      startIcon={
        <Icon
          className={classNames(styles.icon, {
            [styles.icon__active]: isActive
          })}
          size={18}
          fontName="fa fa-plus"
        />
      }
      onlyIcon
      color={isActive ? 'red' : '#524e4e'}
      variant="message-action"
      clickHandler={clickHandler}
    />
  );
}
