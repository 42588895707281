import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import BlurWrapper from 'components/BlurWrapper/BlurWrapper';
import Portal from 'components/Portal/Portal';

import styles from './Menu.module.css';

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.activatorRef = React.createRef();
    this.menuRef = React.createRef();
  }

  state = {
    position: {
      left: 0,
      top: 0
    }
  };

  activatorHandler = (e) => {
    e.stopPropagation();
    // e.nativeEvent.stopImmediatePropagation();
    if (this.props.isActive) this.props.activatorHandler();
  };

  getPosition = ({ left, top }) => {
    let styles = {};
    if (this.menuRef.current) {
      const menuRect = this.menuRef.current.getBoundingClientRect();
      if (left + menuRect.width > window.innerWidth) styles.right = 5;
      else styles.left = left;

      if (top + menuRect.height > window.innerHeight) styles.top = top - menuRect.height - 20;
      else styles.top = top;
    } else styles.left = 0;

    return styles;
  };

  render() {
    const classes = classNames(styles['menu-modal'], {
      // 'unvisible': !this.props.isActive
    });
    const { activator, isActive, children } = this.props;

    return (
      <div className={`${this.props.className} align-self-center`}>
        <div ref={this.activatorRef}>{activator}</div>
        <Portal>
          <BlurWrapper
            blurRef={this.props.blurRef}
            isOpened={isActive}
            clickHandler={this.activatorHandler}>
            <div ref={this.menuRef} style={this.getPosition(this.props)} className={classes}>
              {children}
            </div>
          </BlurWrapper>
        </Portal>
      </div>
    );
  }
}

export default Menu;

Menu.propTypes = {
  activator: PropTypes.element.isRequired,
  isActive: PropTypes.bool,
  children: PropTypes.element
};

Menu.defaultProps = {
  activator: false
};
