import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

export const useBroadcast = ({ isSettingsOpened, setIsSettingsOpened }) => {
  const [isMessageCreatorOpened, setIsMessageCreatorOpened] = useState(false);
  const [isBroadcastStateFixed, setIsBroadcastStateFixed] = useState(true);

  const { isInteractiveExist, info } = useSelector((state) => state.isettings);

  const { activity, interactiveName, settings, state, visibility } = info;

  const toggleSettings = useCallback(() => {
    if (isMessageCreatorOpened) setIsMessageCreatorOpened(false);

    setIsSettingsOpened(!isSettingsOpened);
  }, [isMessageCreatorOpened, isSettingsOpened]);

  const toggleMessageCreator = useCallback(() => {
    if (isSettingsOpened) setIsSettingsOpened(false);

    setIsMessageCreatorOpened(!isMessageCreatorOpened);
  }, [isSettingsOpened, isMessageCreatorOpened]);

  return {
    interactiveName,
    isInteractiveExist,
    isBroadcastStateFixed,
    setIsBroadcastStateFixed,
    isMessageCreatorOpened,
    isSettingsOpened,
    toggleSettings,
    toggleMessageCreator,
    visibility,
    activity,
    settings,
    state
  };
};
