import { useCallback, useEffect, useRef, useState } from 'react';

import { useActions, useFetch } from 'hooks';
import { useAudioMetaData } from 'hooks/useAudioMetaData';

export const useAdvCard = ({ contentId, block, setStepIndex }) => {
  const { updateContentBlockField } = useActions();
  const audioMetaData = useAudioMetaData({
    src: block.text_to_speech?.src
  });
  const inputRef = useRef(null);
  const [uploadBlockData, setUploadBlockData] = useState(null);
  const [{ response, isLoading, error }, doFetch] = useFetch('api_add_content_attachment');
  const [
    { response: responseSynthesis, isLoading: isLoadingSynthesis, error: errorSynthesis },
    doFetchSynthesis
  ] = useFetch('api_synthesis_speech');

  const { id: blockId } = block;

  const synthesisSpeech = useCallback(
    (blockId, field) => {
      let text = block.text_to_speech.value;
      const { voice, emotion, speed } = block.text_to_speech;
      if (text.length === 0) {
        text = `${block.caption.value} ${block.text.value}`;
        updateContentBlockField(blockId, 'text_to_speech', text);
      }
      doFetchSynthesis({
        method: 'POST',
        postBody: {
          contentId,
          blockId,
          text,
          parameters: JSON.stringify([{ voice }, { emotion }, { speed }])
        }
      });
      setUploadBlockData({
        blockId,
        field
      });
    },
    [contentId, block, updateContentBlockField]
  );

  useEffect(() => {
    if (!responseSynthesis) return;

    const { blockId, field } = uploadBlockData;
    updateContentBlockField(blockId, field, responseSynthesis, 'src');
    setUploadBlockData(null);
    setTimeout(() => {
      setStepIndex((v) => ++v);
    }, 300);
  }, [responseSynthesis, updateContentBlockField]);

  const pictureChangeHandler = useCallback(
    (evt, blockId, field) => {
      if (evt === null) {
        updateContentBlockField(blockId, field, '');
      } else {
        const file = evt.target.files[0];
        if (!file) return;

        setUploadBlockData({
          blockId,
          field
        });
        file.isBinary = true;
        doFetch({
          method: 'POST',
          postBody: {
            contentId,
            attachment: file
          }
        });
      }
    },
    [contentId, updateContentBlockField]
  );

  useEffect(() => {
    if (!response) return;

    const { blockId, field } = uploadBlockData;
    updateContentBlockField(blockId, field, response);
  }, [response, updateContentBlockField]);

  useEffect(() => {
    updateContentBlockField(blockId, 'text_to_speech', audioMetaData.duration, 'duration');
  }, [audioMetaData, updateContentBlockField]);

  const insertTags = useCallback(
    (tags) => {
      const e = inputRef.current;
      let cursorPosition = e.selectionStart;
      let textBeforeCursorPosition = e.value.substring(0, cursorPosition);
      let textAfterCursorPosition = e.value.substring(cursorPosition, e.value.length);
      updateContentBlockField(
        blockId,
        'text_to_speech',
        textBeforeCursorPosition + tags + textAfterCursorPosition
      );
    },
    [inputRef.current, updateContentBlockField]
  );

  return {
    inputRef,
    insertTags,
    updateBlockField: updateContentBlockField,
    pictureChangeHandler,
    isImageUploading: isLoading,
    synthesisSpeech,
    isLoadingSynthesis,
    audioMetaData
  };
};
