import { PinnedAuthorBody } from './PinnedAuthorBody';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, Collapsible, CollaspibleHead, Icon } from 'components';

import { useActions, useFetch, useLocalStorage } from 'hooks';

import { LocaleContext } from 'providers/LocaleProvider';

import { hidePhoneNumber } from 'helpers/hidePhoneNumber';
import { goTo } from 'helpers/routing';

const option = { dataKey: null };

export function PinnedAuthor({ author, messages }) {
  const [hasBeenOpen, setHasBeenOpen] = useState(false);
  const newMessagesHaveBeenRequestRef = useRef(false);
  const history = useHistory();
  const location = useLocation();
  const [_, setProfileMessage] = useLocalStorage('profileMessage');
  const {
    togglePinnedAuthor,
    removeMessageFromPinnedAuthor,
    setFavState,
    setPinnedMessagesAsRead,
    addMessagesToPinnedAuthor
  } = useActions();
  const [userInformation] = useLocalStorage('userInfo', null);
  const { broadcastId, activeBroadcastData } = useSelector((state) => state.broadcast);
  const { isNewAtTop, isFeedbackEnabled, answer } = useSelector(
    (state) => state.settings.messageFeedConfig
  );
  const messageActionsConfig = useSelector((state) => state.settings.messageActionsConfig);
  const [locale] = useContext(LocaleContext);

  const [
    { response: responseMessages, isLoading: isLoadingMessages, error: errorMessages },
    doFetchMessages
  ] = useFetch('api_get_profile_messages', option);

  useEffect(() => {
    doFetchMessages({
      method: 'POST',
      postBody: {
        action: 'getMessages',
        identifier: author.senderNumber,
        channel: author.channel
      }
    });
  }, [author.senderNumber, author.channel, doFetchMessages]);

  useEffect(() => {
    if (newMessagesHaveBeenRequestRef.current) return;

    if (errorMessages || responseMessages === 'no messages') {
      return;
    }

    if (!responseMessages) {
      return;
    }
    // setMessages(messages => [...messages, ...responseMessages]);
    if (!Array.isArray(responseMessages)) {
      return;
    }

    newMessagesHaveBeenRequestRef.current = true;
    if (responseMessages.length) {
      addMessagesToPinnedAuthor(
        author.senderNumber,
        [...responseMessages]
          .reverse()
          .filter((msg) => msg.id >= author.firstMessageId)
          .map(
            (msg) =>
              msg && {
                ...msg,
                isRead: false,
                favorite: msg.favorite === 'true' ? '1' : '0'
              }
          )
      );
    }
  }, [
    responseMessages,
    author.senderNumber,
    author.firstMessageId,
    author.channel,
    errorMessages,
    doFetchMessages,
    addMessagesToPinnedAuthor
  ]);

  // const messageClickHandler = useCallback(
  //   (id) => {
  //     if (window.event.altKey) multipleSelectMessage(id);
  //     else selectMessage(id);
  //   },
  //   [selectMessage, multipleSelectMessage]
  // );

  // const isSelected = (id) => selectedMessages.includes(id);

  const toggleFavorite = useCallback(
    (id, value, senderNumber) => setFavState(id, value, senderNumber),
    [setFavState]
  );

  const isFeedbackPossible = useCallback(
    (channel) => {
      return (
        isFeedbackEnabled &&
        userInformation?.messagers?.some((messager) => {
          const [channelName, isActive] = Object.entries(messager)[0];
          return isActive && channelName === channel;
        })
      );
    },
    [isFeedbackEnabled, userInformation]
  );
  const iconClickHandler = (author, message) => (event) => {
    event.stopPropagation();
    // localStorage.setItem('profileMessage', JSON.stringify(data));
    setProfileMessage(message);
    if (isFeedbackPossible(author.channel)) {
      goTo(history, `/chat/${author.channel}_${author.senderNumber}`, null, location);
    } else {
      goTo(history, `/profile/${author.channel}_${author.senderNumber}`, null, location);
    }
  };

  return (
    <Collapsible
      key={author.senderNumber}
      onClosing={() => {
        messages?.some((msg) => !msg.isRead) &&
          setPinnedMessagesAsRead(author.senderNumber, messages?.[messages?.length - 1]?.id);
      }}
      onOpening={() => {
        setHasBeenOpen(true);
        messages?.some((msg) => !msg.isRead) &&
          setPinnedMessagesAsRead(author.senderNumber, messages?.[messages?.length - 1]?.id);
      }}
      trigger={
        <CollaspibleHead
          name={author.author}
          image={author.image}
          imageClickHandler={iconClickHandler(author, messages[messages.length - 1])}
          channel={author.channel}
          status={
            ['whatsapp', 'viber', 'sms', 'smsmoney', 'smsMoney', 'smsrub', 'smsRub'].includes(
              author.channel
            )
              ? hidePhoneNumber(author.senderNumber)
              : ''
          }
          message={messages.findLast((msg) => !msg.isHidden)?.content}
          noteCount={
            messages?.[messages?.length - 1]?.id > author.lastReadMessageId ? window.t('new') : 0
          }>
          <Button
            clickHandler={(e) => {
              e.stopPropagation();
              togglePinnedAuthor({ senderNumber: author.senderNumber });
            }}
            title={window.t('detach')}
            onlyIcon
            startIcon={
              <Icon
                size={16}
                style={{
                  transform: 'rotateZ(45deg)'
                }}
                fontName="fad fa-thumbtack"
              />
            }
            variant="icon"
            color="primary"
            style={{
              marginLeft: 'auto',
              marginRight: 10
            }}
          />
        </CollaspibleHead>
      }>
      {hasBeenOpen && (
        <PinnedAuthorBody
          removeMessageFromPinnedAuthor={removeMessageFromPinnedAuthor}
          author={author}
          isNewAtTop={isNewAtTop}
          messages={messages || []}
          userInformation={userInformation}
          togglePinnedAuthor={togglePinnedAuthor}
          isFeedbackEnabled={isFeedbackEnabled}
          answer={answer}
          locale={locale}
          messageActionsConfig={messageActionsConfig}
          broadcastId={broadcastId}
          toggleFavorite={toggleFavorite}
          activeBroadcastData={activeBroadcastData}
        />
      )}
    </Collapsible>
  );
}
