import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from 'hooks';

export const useRegistration = ({ isSettingsOpened, setIsSettingsOpened }) => {
  const {
    setConfig,
    setCheckboxes,
    updateRegistration,
    updateRegistration_json,
    setFilteredRegistration,
    handlePublishandSave
  } = useActions();

  const { info } = useSelector((state) => state.isettings);
  const { config, checkboxes, participants } = useSelector((state) => state.registration);
  const {
    activity,
    interactiveName,
    checkboxesTemplates,
    registration_json,
    registration_url,
    tags = []
  } = info;

  useEffect(() => {
    const checkboxes =
      registration_json?.checkboxes?.length !== 0
        ? registration_json?.checkboxes
        : checkboxesTemplates.filter((el) => el.shouldBe === true);
    const configNames = checkboxes?.map((el) => el.name);

    const config = checkboxesTemplates?.map((el) => {
      const newEl = { ...el };
      if (configNames?.includes(el.name)) {
        newEl.shouldBe = true;
        newEl.isRequired = checkboxes?.find((check) => check.name === el.name).isRequired;
      } else {
        newEl.shouldBe = false;
      }
      return newEl;
    });

    setConfig(config);
    setCheckboxes(checkboxes);
  }, [checkboxesTemplates, registration_json]);

  const toggleSettings = useCallback(() => {
    setIsSettingsOpened(!isSettingsOpened);
  }, [isSettingsOpened]);

  const saveSettings = useCallback(() => {
    if (isSettingsOpened) {
      updateRegistration_json();
    }
    setIsSettingsOpened(!isSettingsOpened);
  }, [isSettingsOpened]);

  const handlePublish = useCallback(() => {
    if (isSettingsOpened) {
      handlePublishandSave();
    }
    setIsSettingsOpened(!isSettingsOpened);
  }, [isSettingsOpened]);

  return {
    interactiveName,
    activity,
    config,
    checkboxes,
    registration_url,
    updateRegistration,
    setFilteredRegistration,
    participants,
    toggleSettings,
    handlePublish,
    saveSettings,
    dateRegistration: registration_json?.event_date || '',
    tags
  };
};
