import MediaImg from './MediaImg/MediaImg';
import MediaLink from './MediaLink/MediaLink';
import MediaLocation from './MediaLocation/MediaLocation';
import MediaVideo from './MediaVideo/MediaVideo';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import AudioPlayer from 'components/AudioPlayer/AudioPlayer';

import useActions from 'hooks/useActions';

import { requestApiPost } from 'api';

import { getImageByChannel } from 'helpers/image';

import styles from './Media.module.css';

const propTypes = {
  isHidden: PropTypes.bool,
  id: PropTypes.string.isRequired,
  attachments: PropTypes.array.isRequired,
  messageInfo: PropTypes.object
};

function Media({ isHidden, id, attachments, messageInfo, type, tab }) {
  const { openModalImage } = useActions();
  const messages = useSelector((state) =>
    type === 'messageFeed' ? state.feed[tab]?.items : state[type]?.messages
  );

  const openMedia = (url) => {
    if (!messages) return;

    const { author, rss_name, image, logo, channel, senderNumber } = messageInfo;

    const uInfo = {
      avatar: logo?.url || getImageByChannel(channel || 'rss', image),
      name: rss_name || author
    };

    const authorMessages = messages.filter(
      (el) =>
        el.senderNumber === senderNumber && el.channel === channel && el.attachments?.length > 0
    );

    const attachments = authorMessages
      .filter(
        (el) =>
          el.attachments[0].type?.includes('image') ||
          el.attachments[0].type?.includes('video') ||
          el.attachments[0].type?.includes('audio_msg')
      )
      .map((el) => {
        const arr = el.attachments;
        arr.forEach((elm) => {
          elm.messageId = el.id;
          elm.date = el.date || el.pubDate;
        });
        return arr;
      });

    const flatAttachments = [].concat.apply([], attachments);
    const imageList = flatAttachments.map((el) => {
      return {
        messageId: el.messageId,
        src: el.type?.includes('audio') ? el.ogg : el.url,
        type: el.type,
        publishDate: el.date || el.pubDate
      };
    });

    openModalImage(uInfo, imageList, url);
  };

  return (
    <div
      className={styles.media}
      style={{
        opacity: isHidden ? 0 : 1,
        transition: 'opacity 1s'
      }}>
      {attachments.map((el, i) => {
        const type = el.type;
        return type?.includes('image') ? (
          <MediaImg image={el} openMedia={openMedia} key={type + i} />
        ) : type?.includes('audio') ? (
          <AudioPlayer
            messageId={id}
            src={el.ogg}
            style={{ marginLeft: -15 }}
            key={type + i}
            senderNumber={el.senderNumber}
          />
        ) : type?.includes('video') ? (
          <MediaVideo video={el} openMedia={openMedia} key={type + i} />
        ) : type?.includes('link') ? (
          <MediaLink attachments={el} key={type + i} />
        ) : type?.includes('location') ? (
          <MediaLocation attachments={el} key={type + i} />
        ) : null;
      })}
    </div>
  );
}

export default Media;

Media.propTypes = propTypes;
