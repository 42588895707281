import React from 'react';
import { withRouter } from 'react-router-dom';

import styles from './WelcomeNav.module.css';

function WelcomeNav({ history }) {
  return (
    <div className={styles['welcome-nav-wrapper']}>
      {/* onclick="paneSelect('sessions');" */}
      <div
        onClick={() => history.push('/sessions')}
        className={`${styles['link']} ${styles['b1-2']} ${styles['link-ia']}`}>
        <span />
        {window.t('myInteractives')}
      </div>
      {/* onclick="paneSelect('user-preferences');" */}
      <div
        onClick={() => history.push('/settings')}
        className={`${styles['link']} ${styles['b1-2']} ${styles['link-prefs']}`}>
        <span />
        {window.t('settings')}
      </div>
    </div>
  );
}

export default withRouter(WelcomeNav);
