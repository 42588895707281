import classNames from 'classnames';

import styles from './FeedTabs.module.css';

export function FeedTabs({ className, items, current, setCurrent, children }) {
  return (
    <div className={styles.tabs}>
      {items.map((item) => (
        <button
          key={item}
          className={classNames(className, styles.tab, {
            [styles.tab__current]: current === item
          })}
          onClick={() => setCurrent(item)}>
          {window.t(item)}
        </button>
      ))}
      {children && <div className={styles.children}>{children}</div>}
    </div>
  );
}
