import React from 'react';

import { Divider, List } from 'components';
import ListItem from 'components/List/ListItem/ListItem';
import ListItemText from 'components/List/ListItem/ListItemText/ListItemText';

export function BroadcastSettingsMenuView({ restoreHandler, removeHandler }) {
  return (
    <List>
      <ListItem clickHandler={restoreHandler}>
        <ListItemText primary={window.t('restoreContent')} />
      </ListItem>
      <Divider />
      <ListItem clickHandler={removeHandler}>
        <ListItemText primary={window.t('remove')} />
      </ListItem>
    </List>
  );
}
