import className from 'classnames';
import React, { memo } from 'react';

import { CycleButton, StopButton, Title } from 'components';

export const VoteStatePanelView = memo(({ broadcastState, toggleState }) => {
  return (
    <div
      className={className('d-flex align-center pt-4 pb-4 pl-5 pr-5', {
        // [styles['states--fixed']]: isBroadcastStateFixed
      })}
      style={
        {
          // width: isBroadcastStateFixed ? `calc(${workPaneWidth}% - 53px)` : '100%'
        }
      }>
      <Title
        style={{ whiteSpace: 'nowrap', height: '38px' }}
        className="align-self-start d-flex align-center"
        color="#808080">
        {window.t('titreState')}
      </Title>
      <div className="d-flex ml-2 flex-wrap">
        {broadcastState &&
          Object.entries(broadcastState).map(([key, { duration, type, value, active, isRunned }]) =>
            type === 'stop' ? (
              <StopButton
                key={key + type}
                duration={duration}
                isActive={Boolean(+active)}
                setIsActive={(isActive) => toggleState(key, isActive)}>
                {value || key}
              </StopButton>
            ) : (
              <CycleButton
                key={key + type}
                duration={duration}
                isActive={Boolean(+active)}
                setIsActive={(isActive) => toggleState(key, isActive)}>
                {value || key}
              </CycleButton>
            )
          )}
      </div>
    </div>
  );
});
