import { filterSessions, toggleSearchActivity } from './sessionsFilter';
import { addSnack } from './snackBox';

import {
  createSessionError,
  createSessionSuccess,
  fetchSessionsError,
  fetchSessionsSuccess,
  removeSessions,
  setIsSessionCreated
} from 'store/actions/sessions';

import {
  createInteractive,
  deleteInteractive,
  deleteInteractiveOld,
  getInteractives
} from 'api/dataLayer';

import { confirm } from 'helpers/common';
import { getSessionData } from 'helpers/interactives';

export function fetchSessions() {
  return async (dispatch) => {
    try {
      const response = await getInteractives();
      const sessions = response.body;
      sessions.forEach((el) => {
        el.isHidden = false;
      });
      dispatch(fetchSessionsSuccess(sessions));
      dispatch(filterSessions());
    } catch (ex) {
      dispatch(fetchSessionsError(ex));
    }
  };
}

export function refreshSessions() {
  return async (dispatch) => {
    try {
      const response = await getInteractives();
      const sessions = response.data.body;
      dispatch(toggleSearchActivity(false));
      dispatch(fetchSessionsSuccess(sessions));
    } catch (ex) {
      console.error(ex);
    }
  };
}

export function createSession(type) {
  return async (dispatch) => {
    try {
      const response = await createInteractive({
        type,
        title: '',
        description: '',
        memo: ''
      });
      const session = getSessionData(type, response);
      dispatch(createSessionSuccess(session));
      dispatch(setIsSessionCreated(true));
      setTimeout(() => {
        setIsSessionCreated(false);
      }, 0);
    } catch (ex) {
      dispatch(createSessionError(ex));
    }
  };
}

export function removeSession() {
  return async (dispatch, getState) => {
    const sessionsState = getState().sessions;
    const selectedSessions = sessionsState.selectedSessions;
    const selectedCount = selectedSessions.length;
    if (selectedCount === 0) return;
    const confirmText = getConfirmText(sessionsState, selectedSessions, selectedCount);

    if (confirm(confirmText)) {
      const idsToDelete = [];
      const deletedBroadcasts = [];

      try {
        for (const session of selectedSessions) {
          const typeId = session.split('_');
          const type = typeId[0];
          const id = typeId[1];
          if (type === 'broadcast') {
            await deleteInteractive(id);
            deletedBroadcasts.push(+id);
          } else idsToDelete.push(+id);
        }
        // sessListChanged('IactiveDELETE', idsToDelete);
        await deleteInteractiveOld(idsToDelete.join(','));
      } catch (ex) {
        console.error(ex);
      }
      const deleted = [...idsToDelete, ...deletedBroadcasts];
      dispatch(removeSessions(deleted));

      dispatch(
        addSnack({
          type: 'success',
          message: window.t('errDeleted')
        })
      );
    }
  };
}

function getConfirmText(sessionsState, selectedSessions, selectedCount) {
  let confirmText = 'infoRemovingInteractives';
  if (selectedCount === 1) {
    const session = sessionsState.sessions.find(
      (el) => `${el.type}_${el.id}` === selectedSessions[0]
    );
    confirmText += `'${session.title}'?`;
  } else {
    confirmText += `${window.t('interactives').toLowerCast()} (${selectedCount} ${window.t(
      'piecesShort'
    )})?`;
  }

  return confirmText;
}
