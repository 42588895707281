import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './TextField.module.css';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  type: PropTypes.string,
  label: PropTypes.string,
  borderStyle: PropTypes.oneOf(['solid', 'dotted', 'dashed', 'none']),
  changeHandler: PropTypes.func,
  keyDownHandler: PropTypes.func,
  style: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  preIcon: PropTypes.string,
  preIconActivity: PropTypes.bool,
  isMinimized: PropTypes.bool,
  isDisabled: PropTypes.bool,
  errorMsg: PropTypes.string,
  isClearable: PropTypes.bool,
  preIconClickHandle: PropTypes.func,
  clearClickHandler: PropTypes.func,
  toggleSearchActivity: PropTypes.func,
  focusHandler: PropTypes.func,
  isValid: PropTypes.bool
};

const defaultProps = {
  value: '',
  type: 'text',
  borderStyle: 'solid',
  style: {},
  isClearable: false,
  isMinimized: false,
  isDisabled: false,
  preIconActivity: false,
  withoutLine: false,
  isValid: true,
  changeHandler: () => {},
  keyDownHandler: () => {},
  preIconClickHandle: () => {},
  clearClickHandler: () => {},
  toggleSearchActivity: () => {},
  focusHandler: () => {}
};

class TextField extends React.Component {
  constructor(props) {
    super(props);
    this.group = React.createRef();
    this.textInput = React.createRef();
  }

  componentDidMount() {
    const { mainColor, inputHeight, inputFontSize, borderStyle } = this.props;

    if (mainColor) this.setProperty('main-color', mainColor);
    if (inputHeight) this.setProperty('input-height', inputHeight);
    if (inputFontSize) this.setProperty('input-font-size', inputFontSize);

    this.setProperty('border-style', borderStyle);

    this.textInput.current.addEventListener(
      'wheel',
      (e) => {
        e.preventDefault();
      },
      { passive: false }
    );
  }

  setProperty(key, value) {
    this.group.current.style.setProperty(`--${key}`, value);
  }

  preIconClickHandler = () => {
    this.props.preIconClickHandler(); // toggleSearchActivity(true)
    setTimeout(() => {
      this.textInput.current.focus();
    }, 300);
  };

  clearClickHandler = () => {
    this.props.changeHandler('');
    this.props.clearClickHandler(); // toggleSearchActivity(false)
  };

  onFocus = () => {
    if (typeof this.props.focusHandler === 'function') this.props.focusHandler();
  };

  onBlur = (event) => {
    this.props.blurHandler?.(event);
  };

  render() {
    const {
      id,
      name,
      value,
      label,
      changeHandler,
      keyDownHandler,
      style,
      className,
      type,
      width,
      preIcon,
      preIconActivity,
      isMinimized,
      isDisabled,
      errorMsg,
      error,
      isClearable,
      postfix,
      isRequired = true,
      withError,
      isValid
    } = this.props;

    const classes = classNames(className, styles['input-group'], {
      [styles['minimized']]: isMinimized,
      [styles['with-error']]: withError,
      [styles['has-error']]: Boolean(errorMsg || error),
      [styles['with-pre-icon']]: Boolean(preIcon),
      [styles['clearable']]: isClearable,
      [styles['invalid']]: !isValid
    });

    const focus = () => {
      this.textInput.current.focus();
    };

    const minDate =
      type === 'datetime-local'
        ? new Date().toISOString().slice(0, -8)
        : type === 'date'
        ? new Date().toISOString().slice(0, -14)
        : undefined;

    const maxDate =
      type === 'datetime-local' ? '2100-02-22T20:20' : type === 'date' ? '2100-10-30' : undefined;

    return (
      <div ref={this.group} style={{ ...style, width }} className={classNames(classes, className)}>
        <div className={styles['input-group-inner']}>
          {preIcon && (
            <div className={styles['pre-icon-outer']}>
              <div className={styles['pre-icon-inner']}>
                {preIconActivity && <i className={styles['active-state']} />}
                <i
                  onClick={this.preIconClickHandler}
                  className={`${styles['pre-icon']} ${preIcon}`}
                />
              </div>
            </div>
          )}

          <div className={styles['input-wrapper']}>
            <input
              {...(isRequired && { required: 'required' })}
              id={id}
              name={name}
              className={classNames(styles['input'], {
                [styles.required]: isRequired,
                [styles.isFilled]: value?.trim?.()
              })}
              ref={this.textInput}
              type={type}
              value={value}
              onChange={changeHandler}
              onKeyDown={(event) => {
                keyDownHandler(event);
              }}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              min={type === 'datetime-local' || type === 'date' ? minDate : undefined}
              max={type === 'datetime-local' || type === 'date' ? maxDate : undefined}
              disabled={isDisabled || isMinimized}
              formNoValidate
            />
            <i className={styles.bar} />
            {label && (
              <label onClick={focus} className={styles['label']}>
                {label}
              </label>
            )}
          </div>
          {isClearable && (
            <div onClick={this.clearClickHandler} className={styles['clear-icon-wrapper']}>
              <i className={`${styles['clear-icon']} si-btn far fa-times`} />
            </div>
          )}
          {postfix && <span className={styles['postfix']}>{postfix}</span>}
        </div>
        {(errorMsg || error) && <div className={styles['error-msg']}>{errorMsg || error}</div>}
      </div>
    );
  }
}

export default TextField;

TextField.propTypes = propTypes;
TextField.defaultProps = defaultProps;
