import PropTypes from 'prop-types';
import React from 'react';

import styles from './NoteCount.module.css';

const propTypes = {
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  top: PropTypes.number,
  right: PropTypes.number
};

const defaultProps = {
  top: -10,
  right: 2
};

function NoteCount({ count, top, right }) {
  return (
    <div className={styles['count-notifications']} style={{ top, right }}>
      {count}
    </div>
  );
}

export default NoteCount;

NoteCount.propTypes = propTypes;
NoteCount.defaultProps = defaultProps;
