import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  Avatar,
  Button,
  Icon,
  List,
  ListItem,
  ListItemText,
  SvgCheckbox,
  Text,
  Title
} from 'components';

import { useAsyncEffect } from 'hooks';

import { deleteVkGroup, getFacebookGroups, toggleCheckboxState } from 'api/dataLayer';

import styles from '../SocialSettings.module.css';

const propTypes = {
  setDeleteCardData: PropTypes.func.isRequired,
  setIsDeleteCardActive: PropTypes.func.isRequired,
  setIsFbConnected: PropTypes.func.isRequired
};

const defaultProps = {
  setDeleteCardData: () => {},
  setIsDeleteCardActive: () => {},
  setIsFbConnected: () => {}
};

function FbCollapseBody({ setDeleteCardData, setIsDeleteCardActive, setIsFbConnected }) {
  const [groups, setGroups] = useState([]);

  const clickHandler = () => {
    // window.open("http://googleauth.iactive.pro/vkAuth?clientNum=79619002008", '_blank').focus();
  };

  useAsyncEffect(async () => {
    try {
      const res = await getFacebookGroups();
      const groups = res?.body;

      if (!groups) return;

      setGroups(Object.values(groups));
    } catch (ex) {
      console.error(ex);
    }
  }, []);

  useEffect(() => {
    setIsFbConnected(groups.length > 0);
  }, [groups]);

  const checkboxHandler = async (groupId, key, isChecked) => {
    try {
      await toggleCheckboxState({
        groupId,
        key,
        channel: 'vk',
        isChecked: Number(isChecked)
      });
      setGroups((groups) =>
        groups.map((el) => (el.groupId === groupId ? { ...el, [key]: isChecked } : el))
      );
    } catch (ex) {
      console.error(ex);
    }
  };

  const deleteRequest = async ({ groupId, isComment, isMessage }) => {
    try {
      await deleteVkGroup({ groupId, isComment, isMessage });
      setGroups((groups) => groups.filter((el) => el.groupId !== groupId));
      setIsDeleteCardActive(false);
    } catch (ex) {
      console.error(ex);
    }
  };

  const deleteHandler = (groupId) => {
    const groupData = groups.find((el) => el.groupId === groupId);
    groupData.deleteRequest = deleteRequest;
    groupData.removeMessage = window.t('realyWantToDeleteGroup');
    // для принудительного обновления стейта в DeleteCardModal
    groupData.random = Math.random();

    setDeleteCardData(groupData);
    setIsDeleteCardActive(true);
  };

  return (
    <div style={{ padding: '10px 0' }}>
      <Title className="d-inline-block" weight="normal" style={{ marginLeft: 50 }}>
        {window.t('accounts')}
      </Title>
      <Text tag="span" color="#808080">
        {` ${groups.length}`}
      </Text>
      <List noStyle style={{ marginLeft: 50 }}>
        {groups.map(({ groupId, groupName, isComment, isMessage, photo }) => (
          <ListItem key={groupId} noHover className="ml-0 mr-0 mt-2 mb-0 pa-0">
            <div className={`d-flex ${styles['group-name']}`}>
              <Avatar src={photo} size={30} className="flex-shrink-0 mr-2 align-self-center" />
              <ListItemText primary={groupName} />
            </div>
            <Button
              onlyIcon
              startIcon={<Icon size={14} color="#CDCDCD" fontName="far fa-times" />}
              variant="icon"
              hoverColor="transparent"
              style={{
                opacity: 1
              }}
              clickHandler={() => deleteHandler(groupId)}
            />
            <div className="d-flex ml-auto">
              {isComment !== null && isComment !== undefined && (
                <SvgCheckbox
                  isChecked={Boolean(+isComment)}
                  changeHandler={(isChecked) => checkboxHandler(groupId, 'isComment', isChecked)}
                  style={{
                    color: '#808080',
                    fontSize: 14,
                    marginLeft: 10
                  }}>
                  {window.t('comments')}
                </SvgCheckbox>
              )}
              {isMessage !== null && isMessage !== undefined && (
                <SvgCheckbox
                  isChecked={Boolean(+isMessage)}
                  changeHandler={(isChecked) => checkboxHandler(groupId, 'isMessage', isChecked)}
                  style={{
                    color: '#808080',
                    fontSize: 14,
                    marginLeft: 10
                  }}>
                  {window.t('messages')}
                </SvgCheckbox>
              )}
            </div>
          </ListItem>
        ))}
      </List>

      <Button
        clickHandler={clickHandler}
        color="#fcfcfc"
        textColor="#757575"
        variant="google"
        size="medium"
        className="ml-auto mr-auto mt-5 mb-5"
        // viewBox="0 0 227.656 227.656" style="enable-background:new 0 0 227.656 227.656;"
        startIcon={
          <Icon
            icon="facebook"
            width={20}
            height={20}
            viewBox="0 0 227.656 227.656"
            color="#0071BC"
          />
        }>
        {window.t('connectNewGroup')}
      </Button>
    </div>
  );
}

export default FbCollapseBody;

FbCollapseBody.propTypes = propTypes;
FbCollapseBody.defaultProps = defaultProps;
