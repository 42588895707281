import React from 'react';

import Button from 'components/Button/Button';
import SvgCheckbox from 'components/SvgCheckbox/SvgCheckbox';

import styles from './MessageRateSettings.module.css';

function MessageRateSettings() {
  const channels = [
    {
      id: 'cbx-msgs-change-fb',
      name: 'Facebook',
      setName: 'facebook'
    },
    {
      id: 'cbx-msgs-change-tlg',
      name: 'Telegram',
      setName: 'telegrambot'
    },
    {
      id: 'cbx-msgs-change-vk',
      name: 'ВКонтакте',
      setName: 'vkgroup'
    },
    {
      id: 'cbx-msgs-change-sms',
      name: 'СМС',
      setName: 'sms'
    },
    {
      id: 'cbx-msgs-change-vb',
      name: 'Viber',
      setName: 'viber'
    },
    {
      id: 'cbx-msgs-change-lc',
      name: 'Livechat',
      setName: 'livechat'
    },
    {
      id: 'cbx-msgs-change-wa',
      name: 'WhatsApp',
      setName: 'whatsapp'
    },
    {
      id: 'cbx-msgs-change-smsp',
      name: 'Платные СМС',
      setName: 'smsMoney'
    },
    {
      id: 'cbx-msgs-change-inst',
      name: 'Instagram',
      setName: 'fbinstagram'
    }
  ];

  const style = {
    fontWeight: 700,
    borderBottom: '1px dashed #d6d6d6',
    paddingBottom: 5,
    marginTop: 10,
    width: '45%'
  };

  return (
    //
    <div style={{ display: 'none' }} className={styles['other-sett']}>
      <h3 className={styles['other-sett__h3']}>Найстрока оценки сообщений</h3>
      <Button clickHandler={() => {}}>Добавить стикеры</Button>
      <h4 className={styles['other-sett__h4']} style={{ border: 'none' }}>
        Доступные стикеры
      </h4>
      <div className={styles['sett-assessments']}>
        <p>Положительные стикеры</p>
        <ul id="likeSett">
          <span>Список пуст</span>
        </ul>
      </div>
      <div className={styles['sett-assessments']}>
        <p>Отрицательные стикеры</p>
        <ul id="dislikeSett">
          <span>Список пуст</span>
        </ul>
      </div>

      <h4 className={styles['other-sett__h4']} style={{ border: 'none' }}>
        Каналы, сообщения на которых можно оценивать
      </h4>
      <div className={styles['chanelsForAss']}>
        <Button clickHandler={() => {}}>Сохранить и перезагрузить</Button>

        <div className={styles['socials-wrapper']}>
          {/* chanelsChange(tis) */}
          {channels.map(({ id, name }) => {
            return (
              <SvgCheckbox
                isChecked={false}
                changeHandler={(isChecked) => {}}
                svgMarginLeft="auto"
                style={style}
                key={id}>
                {name}
              </SvgCheckbox>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default MessageRateSettings;
