import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './DotsLoader.module.css';

function DotsLoader({ isActive, color, size }) {
  const classes = classNames(styles['loading-wrapper'], {
    hidden: !isActive
  });
  return (
    <div className={classes}>
      <div className={styles['loading']}>
        <div className={styles['loader']}>
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  );
}

export default DotsLoader;

DotsLoader.propTypes = {
  isActive: PropTypes.bool
};

DotsLoader.defaultProps = {
  isActive: true
};
