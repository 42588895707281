import { useCallback } from 'react';

import useActions from 'hooks/useActions';

export const useMessageFeedSettings = () => {
  const { addAdditionalValue, deleteAdditionalValue, updateMessageFeedConfig, addSnack } =
    useActions();

  const addValue = useCallback(
    (type) => (text) => {
      if (!text) return;
      return addAdditionalValue({ type, text });
    },
    [addAdditionalValue]
  );
  const deleteValue = useCallback(
    (type) => (text) => {
      if (!text) return;
      deleteAdditionalValue({ type, text });
    },
    [deleteAdditionalValue]
  );

  const toggleAutoScroll = (isChecked) => updateMessageFeedConfig('isAlwaysAutoScroll', isChecked);
  const toggleFeedback = (isChecked) => updateMessageFeedConfig('isFeedbackEnabled', isChecked);

  const showWarning = useCallback(() => {
    addSnack({
      type: 'danger',
      message: window.t('variantAlreadyExist')
    });
  }, [addSnack]);

  return {
    toggleAutoScroll,
    toggleFeedback,
    showWarning,
    addValue,
    deleteValue
  };
};
