import React, { memo } from 'react';

import { VariantContainer } from 'containers';

import { Button, Icon, SvgCheckbox } from 'components';

import { TotalVoteCountView } from './components';

import styles from './VoteVariantsView.module.css';

export const VoteVariantsView = memo(
  ({
    voteId,
    type,
    variants,
    result,
    isVoteActive,
    updateVariant,
    changeVoteVariant,
    deleteVoteVariant,
    updateVariantField,
    addVote,
    pictureChangeHandler,
    deleteVoteImage,
    isLoadingVariant,
    loadingImageVarnum,
    voteCount,
    addNewVariant,
    variantRef,
    isChecked,
    toggleCheck
  }) => {
    return (
      <>
        {/* { type === 'vote' && (
        <div className="d-flex">
          <SvgCheckbox
            isChecked={isChecked}
            changeHandler={toggleCheck}
            className="ml-1 pb-5"
            style={{
              color: '#6f6f6f',
              fontSize: 16,
              opacity: 0.75,
              flexDirection: 'row-reverse'
            }}
            
          >
            <span className="ml-2">
              {window.t('usePhoneNumbersForVoting')}
            </span>
          </SvgCheckbox>
        </div>
      ) } */}
        <TotalVoteCountView total={voteCount} type={type} reverse />
        <h3 className={styles['options-title']}>{window.t('variants')}</h3>
        <ul className={styles['options']}>
          {variants.map((el, i) => {
            return (
              <VariantContainer
                {...(i === 0 && { ref: variantRef })}
                isPhoneNumbersUsed={isChecked}
                voteId={voteId}
                data={el}
                type={type}
                result={result}
                key={i}
                index={i}
                isVoteActive={isVoteActive}
                updateVariant={updateVariant}
                changeVoteVariant={changeVoteVariant}
                deleteVoteVariant={deleteVoteVariant}
                updateVariantField={updateVariantField}
                addVote={addVote}
                changeHandler={pictureChangeHandler}
                deleteHandler={() => deleteVoteImage(voteId, el.varnum)}
                isImageUploading={isLoadingVariant}
                loadingImageVarnum={loadingImageVarnum}
              />
            );
          })}
        </ul>
        <TotalVoteCountView total={voteCount} type={type} />
        <Button
          startIcon={<Icon size={20} fontName="fal fa-plus" />}
          variant="text"
          clickHandler={addNewVariant}
          isDisabled={isVoteActive}
          style={{
            marginLeft: 14,
            fontSize: 13,
            marginBottom: 15
          }}>
          {window.t('addVariant')}
        </Button>
      </>
    );
  }
);
