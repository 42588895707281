import PropTypes from 'prop-types';
import React from 'react';

import { getChannelTypeByName } from 'helpers/channels';
import { getUIDateFromDBDate } from 'helpers/dateTime';

import styles from './BannedAuthorsItem.module.css';

function BannedAuthorsItem({ data, unbanAuthor }) {
  const { author, channel, dateBanned, id } = data;
  const channelType = getChannelTypeByName(channel);
  return (
    <li className={`${styles['banned-card']} ${styles[`chan-${channelType}`]}`}>
      <div className={styles.icon} />
      <div className={styles.idMark} />
      <span className={styles.author}>{author}</span>
      <span className={styles.dateTime}>{getUIDateFromDBDate(dateBanned)}</span>
      <div className={styles.text} />
      <div
        title={window.t('unblockAuthor')}
        className={styles.deban}
        onClick={() => {
          unbanAuthor(id);
        }}
      />
    </li>
  );
}

export default BannedAuthorsItem;

BannedAuthorsItem.propTypes = {
  data: PropTypes.object,
  unbanAuthor: PropTypes.func
};
