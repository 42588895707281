import React, { useEffect, useRef } from 'react';

import styles from './ProgressBar.module.css';

function ProgressBar(props) {
  const progressRef = useRef(null);

  const mousemoveHandler = (event) => {
    props.setTimeMove(event, progressRef);
  };

  useEffect(() => {
    window.addEventListener('mousemove', mousemoveHandler);

    return function cleanup() {
      window.removeEventListener('mousemove', mousemoveHandler);
    };
  });

  return (
    <div
      className={styles.progress}
      onMouseDown={() => {
        props.setTimeMouseDown(true);
      }}
      onClick={(event) => {
        props.setTimeClick(event, progressRef);
      }}>
      <div ref={progressRef} className={styles.progress_bar} data-progress="6rem">
        <div className={styles.progress_bottom} />
        <div className={styles.progress_line} style={{ width: `${props.pastTimePercent}%` }} />
        <div
          className={styles.progress_control}
          style={{ left: `calc(${props.pastTimePercent}% - 4.5px)` }}
        />
      </div>
    </div>
  );
}

export default ProgressBar;
