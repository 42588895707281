import { BroadcastFeedSettingsView } from './BroadcastFeedSettingsView';
import React, { forwardRef, memo } from 'react';

import { Title } from 'components';

import { useBroadcastFeedSettings } from './hooks/useBroadcastFeedSettings';

export const BroadcastFeedSettingsContainer = forwardRef((_, ref) => {
  const {
    isMessagesAutoSend,
    isMessagesAutoAdd,
    isNewsAutoFill,
    isNewsAutoWithDrawal,
    toggleMessagesAutoSend,
    toggleMessagesAutoAdd,
    toggleNewsAutoFill,
    toggleNewsAutoWithDrawal
  } = useBroadcastFeedSettings();

  return (
    <>
      <Title color="#808080" className="mb-2">
        {window.t('newsFeedSettings')}
      </Title>
      <BroadcastFeedSettingsView
        ref={ref}
        isAutoSend={isNewsAutoWithDrawal}
        isAutoAdd={isNewsAutoFill}
        toggleAutoSend={toggleNewsAutoWithDrawal}
        toggleAutoAdd={toggleNewsAutoFill}
      />
      <Title color="#808080" className="mb-2">
        {window.t('messagesFeedSettings')}
      </Title>
      <BroadcastFeedSettingsView
        ref={ref}
        isAutoSend={isMessagesAutoSend}
        isAutoAdd={isMessagesAutoAdd}
        toggleAutoSend={toggleMessagesAutoSend}
        toggleAutoAdd={toggleMessagesAutoAdd}
      />
    </>
  );
});
