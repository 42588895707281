import { useSelector } from 'react-redux';

import useActions from 'hooks/useActions';

export const useVoteWinners = () => {
  const { editRangeNumbers, addSnack } = useActions();

  const voteInfo = useSelector((state) => state.isettings.info);
  const { id, rangeNumbers } = voteInfo;

  const addRangeNumber = (newKey) => {
    const newRangeNumbers = [...rangeNumbers, newKey];
    editRangeNumbers(id, newRangeNumbers, rangeNumbers);
  };

  const deleteRangeNumber = (key) => {
    const filteredRange = rangeNumbers.filter((el) => el !== key);
    editRangeNumbers(id, filteredRange, rangeNumbers);
  };

  const showWarning = () => {
    addSnack({
      type: 'danger',
      message: window.t('variantAlreadyExist')
    });
  };

  return {
    rangeNumbers,
    addRangeNumber,
    deleteRangeNumber,
    showWarning
  };
};
