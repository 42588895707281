import Media from './Media/Media';
import ResponseMessage from './ResponseMessage/ResponseMessage';
import animateScrollTo from 'animated-scroll-to';
import classNames from 'classnames';
import { Fragment, useCallback, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import { Avatar } from 'components';
import Text from 'components/FeedItem/Content/Content';
import FeedbackActions from 'components/FeedItem/FeedbackActions/FeedbackActions';
import MessageActions from 'components/FeedItem/MessageActions/MessageActions';
import FeedbackInput from 'components/List/FeedbackInput/FeedbackInput';

import { useActions } from 'hooks';

import { getUITimeFromJSDate } from 'helpers/dateTime';
import { hidePhoneNumber } from 'helpers/hidePhoneNumber';
import { getImageByChannel } from 'helpers/image';
import { getFormattedContent } from 'helpers/text';

import styles from './NewsCard.module.css';

const inputToggleDuration = 700;

function NewsCard({
  isSelected,
  isDraggable,
  order,
  data,
  style,
  settingsMenu,
  isOrderActive,
  selectMessage,
  toggleFavorite,
  isBroadcastMsg,
  activeBroadcastData,
  messageActionsConfig,
  scrollBoxRef,
  isLast,
  copyItemHandler,
  tab,
  isNewAtTop,
  activeMenu,
  isLoading,
  loadPrevious,
  items,
  broadcastId,
  containerRef,
  onFeedScroll,
  scrollBoxPadding
}) {
  const { setDraggingFeedItemId } = useActions();
  const [isFeedbackActive, setIsFeedbackActive] = useState(false);

  const cardRef = useRef(null);
  const [isMsgHover, setIsMsgHover] = useState(false);

  const {
    id,
    isHidden,
    category,
    description,
    text,
    guid,
    item_index,
    item_json,
    link,
    date,
    pubDate,
    rss_name,
    author,
    user,
    logo,
    title,
    status,
    titr_tag,
    attachments,
    after,
    rss_image
  } = data;

  const actionsRef = useRef(null);

  const dragStart = () => {
    setDraggingFeedItemId(id);
  };

  const dragEnd = () => {
    setDraggingFeedItemId(null);
  };

  const onAnimationStart = useCallback(
    ({ newHeight }) => {
      if (!scrollBoxRef?.current) return;
      let rect = cardRef.current.getBoundingClientRect();
      if (rect.top < 0) return;
      if (!(rect.top >= 0 && rect.bottom + newHeight <= window.innerHeight)) {
        if (isLast) {
          scrollBoxRef.current.style.paddingBottom = `${newHeight}px`;
        }
        animateScrollTo([null, scrollBoxRef.current.scrollTop + newHeight], {
          elementToScroll: scrollBoxRef.current,
          minDuration: inputToggleDuration,
          maxDuration: inputToggleDuration
        }).then(() => {
          if (isLast) {
            scrollBoxRef.current.style.paddingBottom = 0;
          }
        });
      }
    },
    [scrollBoxRef]
  );

  const isMinimized = status && status.includes('minimized');
  const classes = classNames(styles.item, {
    [styles.selected]: isSelected,
    // [styles.minimized]: isMinimized,
    hidden: isHidden
  });

  const formattedContent = isBroadcastMsg
    ? (description || text)?.toString()
    : getFormattedContent((description || text)?.toString());

  let unformattedDate = new Date(date || pubDate);
  unformattedDate = `${unformattedDate.getFullYear()}-${(unformattedDate.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${unformattedDate.getDate().toString().padStart(2, '0')} ${
    unformattedDate.toTimeString().split(' ')[0]
  }`;

  const formattedDate = getUITimeFromJSDate(unformattedDate);

  return (
    <AnimateHeight duration={600} height={!isHidden && isMinimized ? 48 : 'auto'}>
      <div
        ref={cardRef}
        draggable={isDraggable}
        style={style}
        onMouseOver={() => {
          setIsMsgHover(true);
        }}
        onMouseLeave={() => {
          setIsMsgHover(false);
        }}
        onDragStart={dragStart}
        onDragEnd={dragEnd}
        className={classes}
        onClick={() => {
          selectMessage?.(id);
        }}
        data-message="true"
        data-id={id}>
        <div className="d-flex flex-column">
          <div className={styles['icon-wrapper']}>
            <Avatar
              src={logo?.url || rss_image || getImageByChannel('rss')}
              size={isMinimized ? 30 : 36}
              className={styles['icon']}
            />
          </div>
          {order && (
            <div
              className={classNames(styles['order'], {
                [styles['order--active']]: isOrderActive
              })}>
              {order}
            </div>
          )}
          <div
            className={classNames(styles.dateTime, {
              [styles['info-minimized']]: isMinimized
            })}>
            {formattedDate}
          </div>
        </div>
        <div className={styles['body']}>
          {(link || title || activeBroadcastData) && (
            <div className={styles['header']}>
              <div className={styles['info']}>
                <div className={classNames('d-flex align-baseline', styles.contactInfo)}>
                  {link && (
                    <a
                      href={link}
                      target="_blank"
                      className={classNames(styles.title, styles.title_link)}
                      rel="noreferrer">
                      {title || link}
                    </a>
                  )}
                  {!link && (
                    <p target="_blank" className={styles.title}>
                      {title}
                    </p>
                  )}
                </div>
              </div>
              <div className={styles['actions']}>
                <MessageActions
                  type={tab}
                  isExportTurnedOn
                  message={data}
                  isMinimized={Boolean(+isMinimized)}
                  isMsgHover={isMsgHover || isBroadcastMsg}
                  messageActionsConfig={messageActionsConfig}
                  // settingsMenu={settingsMenu}
                  toggleFavorite={toggleFavorite}
                  handleCopy={() => copyItemHandler(data)}
                />
                {/* {activeBroadcastData && (
                  <BroadcastActions
                    messageData={data}
                    activeBroadcastData={activeBroadcastData}
                  />
                )} */}
              </div>
            </div>
          )}

          <div className={styles['content']}>
            <Text
              className={styles.text}
              isHidden={isMinimized}
              isBroadcastMsg={isBroadcastMsg}
              content={formattedContent}
              id={id}
            />
            {attachments && attachments.length > 0 && (
              <Media
                tab={tab}
                type="messageFeed"
                isHidden={isMinimized}
                id={id}
                attachments={attachments}
                messageInfo={data}
              />
            )}
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.footer__contact}>
            <p className={styles.author}>
              <span
                className={classNames(styles.author__source, {
                  [styles['info-minimized']]: isMinimized
                })}>
                {hidePhoneNumber(rss_name || user)}
              </span>
              {author && <span className={styles.author__name}>{author}</span>}
            </p>

            {!!after?.filter((el) => el.message_type === 'message')?.length && (
              <div className={styles['responses']}>
                {after?.map(({ id, isNew, out_message, attachment }) => (
                  <Fragment key={id}>
                    {(out_message || attachment?.length > 0) && (
                      <ResponseMessage style={{ opacity: 0.7 }} isNew={isNew}>
                        {out_message} {attachment?.length > 0 && <i className="fas fa-file" />}
                      </ResponseMessage>
                    )}
                  </Fragment>
                ))}
              </div>
            )}
            <FeedbackInput
              onAnimationStart={onAnimationStart}
              duration={inputToggleDuration}
              id={id}
              senderNumber={rss_name || user}
              quickReplies={[]}
              isActive={isFeedbackActive}
              setIsActive={setIsFeedbackActive}
            />
          </div>
          <div className={classNames(styles.categories, 'd-flex align-center mt-1')}>
            {category?.map(
              (category) =>
                category && (
                  <span key={category} className={`${styles['category']} mr-2`}>
                    #{category}
                  </span>
                )
            )}
          </div>
          <div className="ml-auto">
            <FeedbackActions
              actionsRef={actionsRef}
              id={id}
              senderNumber={rss_name || user}
              isActive={isFeedbackActive}
              setIsActive={setIsFeedbackActive}
            />
          </div>
        </div>
      </div>
    </AnimateHeight>
  );
}

export default NewsCard;
