import PropTypes from 'prop-types';
import React from 'react';

import { List, ListItem, ListItemText } from 'components';

function StartMenu({ setStartTime, hideStartMenu, togglePicker }) {
  const startImmediatly = () => {
    setStartTime(new Date());
    hideStartMenu();
  };
  const currentHourStart = () => {
    const now = new Date();
    setStartTime(new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), 0, 0));
    hideStartMenu();
  };
  const nextHourStart = () => {
    const now = new Date();
    setStartTime(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 1, 0, 0)
    );
    hideStartMenu();
  };
  return (
    <List>
      <ListItem clickHandler={startImmediatly}>
        <ListItemText primary={window.t('startImmediatly')} />
      </ListItem>
      <ListItem clickHandler={currentHourStart}>
        <ListItemText primary={window.t('currentHourStart')} />
      </ListItem>
      <ListItem clickHandler={nextHourStart}>
        <ListItemText primary={window.t('nextHourStart')} />
      </ListItem>
      <ListItem
        clickHandler={() => {
          hideStartMenu();
          togglePicker();
        }}>
        <ListItemText primary={window.t('select')} />
      </ListItem>
    </List>
  );
}

export default StartMenu;

StartMenu.propTypes = {
  setStartTime: PropTypes.func,
  hideStartMenu: PropTypes.func,
  togglePicker: PropTypes.func
};
