export const messageWithData = (WrappedComponent) =>
  function ({ authors, togglePinnedAuthor, ...restProps }) {
    return (
      <WrappedComponent
        isAuthorPinned={authors?.some(
          (author) => author.senderNumber === restProps.data.senderNumber
        )}
        togglePinnedAuthor={() => togglePinnedAuthor(restProps.data)}
        {...restProps}
      />
    );
  };
