import { SOCIAL_KEYS } from '../constants';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useAsyncEffect } from 'hooks';
import useActions from 'hooks/useActions';

import { addSnack } from 'store/thunks/snackBox';

import {
  addOkPoll,
  bindSocialPoll,
  createPoll as createPollApi,
  getSocialPolls,
  unbindSocialPoll
} from 'api/dataLayer';

export const useSocialVotes = ({ voteId }) => {
  const { updateInfoField, fetchVoteVariants, setVoteAlerts } = useActions();
  const { info } = useSelector((state) => state.isettings);
  const [socialGroups, setSocialGroups] = useState({});
  const [url, setUrl] = useState();

  useAsyncEffect(async () => {
    try {
      const response = await getSocialPolls();
      const polls = response.body;

      if (!polls || !Array.isArray(polls?.vk)) return;

      setSocialGroups(polls);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const bindPoll = async (postId, socialName, isBinded) => {
    try {
      if (isBinded) {
        await bindSocialPoll({
          voteId,
          postId,
          socialName
        });

        updateInfoField(SOCIAL_KEYS[socialName], postId);
      } else {
        await unbindSocialPoll({
          voteId,
          socialName
        });

        updateInfoField(SOCIAL_KEYS[socialName], null);
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  const createPoll = async (evt, postId, socialName) => {
    evt.stopPropagation();
    try {
      const res = await createPollApi({
        voteId,
        postId,
        socialName
      });

      const { question } = res.body;
      setVoteAlerts({ alerts: [] });
      fetchVoteVariants(voteId);
      updateInfoField('title', question);
    } catch (ex) {
      console.error(ex);
    }
  };

  const addOkPollRequest = async () => {
    try {
      const res = await addOkPoll({
        postUrl: url
      });
      const { postId, question, pollVars } = res.body;

      const newGroups = { ...socialGroups };
      newGroups.ok.push({
        postId,
        question,
        pollVars
      });

      setSocialGroups(newGroups);
      setUrl('');
    } catch (ex) {
      console.error(ex);
      addSnack({
        message: 'Произошла ошибка при добавлении опроса',
        type: 'danger'
      });
    }
  };

  return {
    socialGroups,
    info,
    createPoll,
    url,
    setUrl,
    addOkPollRequest,
    bindPoll
  };
};
