import { useEffect, useState } from 'react';

import useActions from 'hooks/useActions';

import { editVote } from 'api/dataLayer';

export const useVoteStatePanel = ({ voteInfo }) => {
  const { updateInfoField } = useActions();
  const { state } = voteInfo;
  const [broadcastState, setBroadcastState] = useState(null);

  useEffect(() => {
    setBroadcastState(state);
  }, [state]);

  const updateBroadcast = async (states) => {
    const updatedInfo = { id: voteInfo.id, state: voteInfo.state };
    updatedInfo.state = JSON.stringify(states);
    try {
      await editVote(updatedInfo);
      updateInfoField('state', states);
    } catch (ex) {
      console.error(ex);
    }
  };

  const toggleState = (key, isActive) => {
    const newState = { ...broadcastState };

    newState[key].active = +isActive;

    for (const [_key, value] of Object.entries(newState)) {
      if (_key !== key) {
        if (value.type === 'stop') {
          newState[_key].isRunned = false;
          newState[_key].active = false;
        } else if (value.type === 'cycle') {
          newState[_key].isRunned = false;
          newState[_key].active = false;
        }
      }
    }
    setBroadcastState(newState);
    updateBroadcast(newState);
  };

  return {
    broadcastState,
    toggleState
  };
};
