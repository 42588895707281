import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { Input, SvgCheckbox } from 'components';

import useActions from 'hooks/useActions';

import styles from './InteractivesMessageSettings.module.css';

function InteractivesMessageSettings() {
  const { updateBroadcastConfig } = useActions();
  const { broadcastConfig } = useSelector((state) => state.settings);

  const { isExportAvailable, isCharCounterAvailable, charAmount } = broadcastConfig;

  const style = {
    fontWeight: 700,
    borderBottom: '1px dashed #d6d6d6',
    paddingBottom: 5,
    marginTop: 10
  };

  const toggleIsExportAvailable = (isChecked) =>
    updateBroadcastConfig('isExportAvailable', isChecked);
  const toggleIsCharCounterAvailable = (isChecked) =>
    updateBroadcastConfig('isCharCounterAvailable', isChecked);
  const changeCharAmount = (event) => updateBroadcastConfig('charAmount', event.target.value);

  return (
    <>
      <p style={{ marginTop: 30 }}>{window.t('messageInInteractive')}</p>
      <div className={styles['msg-view-box']} style={{ maxWidth: '50%' }}>
        {/* <SvgCheckbox
          isChecked={isExportAvailable}
          changeHandler={toggleIsExportAvailable}
          svgMarginLeft="auto"
          style={style}
        >
          {window.t('showInTitrBtn')}
        </SvgCheckbox> */}
        <SvgCheckbox
          isChecked={isCharCounterAvailable}
          changeHandler={toggleIsCharCounterAvailable}
          svgMarginLeft="auto"
          style={style}>
          {window.t('messageCharsCounter')}
        </SvgCheckbox>
        {isCharCounterAvailable && (
          <div className={styles['titre-symbols']}>
            <span>{window.t('charactersCountInTitr')}</span>
            <Input
              className={styles['ts__input']}
              type="number"
              min="10"
              max="10000"
              placeholder="Колличество символов"
              value={charAmount}
              changeHandler={changeCharAmount}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default InteractivesMessageSettings;

InteractivesMessageSettings.propTypes = {
  broadcastConfig: PropTypes.object,
  updateBroadcastConfig: PropTypes.func
};
