import { useRef, useState } from 'react';

import { joyrideCallback, setStepIcons } from 'helpers/guide';

export const useContentGuide = ({ type }) => {
  const [run, setRun] = useState(false);
  const [stepIndex, setStep] = useState(0);
  const interactiveInfoRef = useRef(null);
  const selectControlsRef = useRef(null);
  const okButtonRef = useRef(null);
  const controlsRef = useRef(null);
  const newsBlockRef = useRef(null);
  const textToSpeechRef = useRef(null);
  const contentViewRef = useRef(null);
  const handleJoyrideCallback = joyrideCallback(setRun, setStep);

  const setStepIndex = (clb) => {
    if (run) {
      setStep(clb);
    }
  };

  const steps = [
    {
      content: (
        <h2>{window.t('interactivePreview').replace('[interactiveName]', window.t(type))}</h2>
      ),
      placement: 'center',
      target: 'body'
    },
    {
      content: <p>{window.t('interactiveInfo')}</p>,
      spotlightPadding: 0,
      target: interactiveInfoRef.current || 'body',
      hideBackButton: true,
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'bottom',
      spotlightClicks: true
    },
    {
      content: <p>{window.t('contentStep0')}</p>,
      spotlightPadding: 0,
      target: selectControlsRef.current || 'body',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'bottom',
      spotlightClicks: true
    },
    {
      content: <p dangerouslySetInnerHTML={{ __html: window.t('interactiveOk') }} />,
      spotlightPadding: 0,
      target: okButtonRef.current || 'body',
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: 'bottom',
      spotlightClicks: true
    },
    {
      content: <p>{setStepIcons(window.t('contentStep1'))}</p>,
      spotlightPadding: 0,
      target: controlsRef.current || 'body',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'bottom',
      spotlightClicks: true
    },
    {
      content: <p>{window.t('contentStep2')}</p>,
      spotlightPadding: 0,
      target: newsBlockRef.current || 'body',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'bottom',
      spotlightClicks: true
    },
    {
      content: <p>{window.t('contentStep3')}</p>,
      spotlightPadding: 0,
      target: textToSpeechRef.current || 'body',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'bottom',
      spotlightClicks: true
    },
    {
      content: <p>{window.t('contentStep4')}</p>,
      spotlightPadding: 0,
      target: contentViewRef.current || 'body',
      // disableBeacon: true,
      // disableOverlayClose: true,
      // hideCloseButton: true,
      // hideFooter: true,
      placement: 'left',
      spotlightClicks: true
    },
    {
      content: <p>{window.t('contentStep5')}</p>,
      placement: 'center',
      target: 'body'
    }
  ];

  return {
    run,
    startGuide: () => {
      setStep(0);
      setRun(true);
    },
    steps,
    stepIndex,
    setStepIndex,
    handleJoyrideCallback,
    controlsRef,
    newsBlockRef,
    textToSpeechRef,
    contentViewRef,
    interactiveInfoRef,
    selectControlsRef,
    okButtonRef
  };
};
