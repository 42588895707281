export function kebabize(str) {
  return str
    .split('')
    .map((letter, idx) => {
      return letter.toUpperCase() === letter
        ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
        : letter;
    })
    .join('');
}

export function getFormattedContent(content, isForProfileCard = false) {
  if (!content) return '';
  content = content.toString();
  content = content.replace(/\uFFFC/g, '');
  content = content.replace(/(<([^>]+)>)/gi, '');
  content = content.replace(/&(.+?);/gi, '');

  let matchAll = content.matchAll(/\*\/(.*)\*\\/gm);
  matchAll = Array.from(matchAll); // теперь массив
  if (matchAll[0]) {
    content = content.replace(
      matchAll[0].input,
      `<span class='info-text-in-message'>${matchAll[0][1]}</span>`
    );
  }

  content = urlify(content);

  let contentValue = content;
  const regex = /\[(.*)\|(.*)\](.*)/gm;
  const str = contentValue;
  let m;
  let arr = [];

  while ((m = regex.exec(str)) !== null) {
    if (m.index === regex.lastIndex) regex.lastIndex++;

    m.forEach((match, groupIndex) => {
      arr.push(match);
    });
  }
  if (arr.length > 0)
    contentValue = `<a target="_blank" href="https://vk.com/${arr[1]}">${arr[2]}</a>${arr[3]}`;
  return contentValue;
}

export function getFormattedContentOLD(content, isForProfileCard = false) {
  if (!content) return '';
  content = content.toString();
  content = content.replace(/\uFFFC/g, '');
  let rex = '';
  rex = /(<([^>]+)>)/gi;
  content = content.replace(rex, '');
  rex = /&(.+?);/gi;
  content = content.replace(rex, '');

  rex = /\*\/(.*)\*\\/gm;
  let matchAll = content.matchAll(rex);
  matchAll = Array.from(matchAll); // теперь массив
  if (matchAll[0]) {
    content = content.replace(
      matchAll[0].input,
      `<span class='info-text-in-message'>${matchAll[0][1]}</span>`
    );
  }

  // content = content.replace(rex, "");

  content = urlify(content);

  // TODO: global settings
  // if (isForProfileCard)
  //   if (isSmallMessage)
  //     content = content.replace(/\r?\n/g, '<br>').trim();

  content = content.replace(/\s+/g, ' ').trim();

  let contentValue = content;
  const regex = /\[(.*)\|(.*)\](.*)/gm;
  const str = contentValue;
  let m;
  let arr = [];

  while ((m = regex.exec(str)) !== null) {
    if (m.index === regex.lastIndex) regex.lastIndex++;

    m.forEach((match, groupIndex) => {
      arr.push(match);
    });
  }
  if (arr.length > 0)
    contentValue = `<a target="_blank" href="https://vk.com/${arr[1]}">${arr[2]}</a>${arr[3]}`;
  return contentValue;
}

export function urlify(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url) {
    return `<a target="_blank" href="${url}">${url}</a>`;
  });
}

export function clearAndParseJSON(stringfied) {
  let str = stringfied.replace(/&/g, '');
  str = str.replace(/amp;/g, '');
  str = str.replace(/quot;/g, '"');
  return JSON.parse(str);
}

export function parseJSON(str, val = null) {
  try {
    return clearAndParseJSON(str);
  } catch (ex) {
    return val;
  }
}

export function spacedName(listName) {
  const numberIndex = listName.search(/\d/);
  return numberIndex === -1
    ? listName
    : `${listName.slice(0, numberIndex)} ${listName[numberIndex]}`;
}

export function clearText(str) {
  return getFormattedContent(str).replace(/\s+/g, ' ').trim();
}
