import PropTypes from 'prop-types';

import { Button, Icon, Text, Title } from 'components';

import VotingNumberForm from './components/VotingNumberForm';

import useVotingNumbers from './hooks/useVotingNumbers';

const propTypes = {
  setIsVotingNumbersConnected: PropTypes.func.isRequired
};

const defaultProps = {
  setIsVotingNumbersConnected: () => {}
};

function VotingNumberCollapseBody({ setIsVotingNumbersConnected }) {
  const {
    handleSubmit,
    totalNumbersCount,
    labels,
    rowsCount,
    values,
    errors,
    handleChange,
    addForm,
    handleRemoveNumber
  } = useVotingNumbers(setIsVotingNumbersConnected);

  return (
    <form onSubmit={handleSubmit} style={{ padding: '10px 0' }}>
      <Title
        className="d-inline-block"
        weight="normal"
        style={{ marginLeft: 50, marginBottom: 10 }}>
        {window.t('numbers')}
      </Title>
      <Text tag="span" color="#808080">
        {` ${totalNumbersCount}`}
      </Text>
      <div className="d-flex justify-center" style={{ marginLeft: 70, gap: 10, marginBottom: 5 }}>
        <p style={{ width: 200, paddingLeft: 5 }}>{labels.num}</p>
        <p style={{ width: 200, paddingLeft: 5 }}>{labels.aon}</p>
      </div>
      <div className="d-flex justify-center">
        <ul>
          {values.slice(0, rowsCount).map((num, i) => (
            <li key={i}>
              <VotingNumberForm
                errors={errors[i]}
                change={handleChange}
                clear={handleRemoveNumber}
                values={num}
                label={`${window.t('variant')} ${i + 1}`}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="d-flex justify-center">
        {rowsCount < 15 && (
          <Button
            startIcon={
              <Icon fontName="fal fa-plus" size={18} color="#81C2F5" style={{ marginRight: 11 }} />
            }
            variant="text"
            clickHandler={addForm}
            tag="div">
            {window.t('addPhoneNumber')}
          </Button>
        )}
      </div>
      <Button
        clickHandler={handleSubmit}
        color="#fcfcfc"
        textColor="#757575"
        variant="google"
        size="medium"
        className="ml-auto mr-auto mt-5 mb-5">
        {window.t('save')}
      </Button>
    </form>
  );
}

VotingNumberCollapseBody.propTypes = propTypes;
VotingNumberCollapseBody.defaultProps = defaultProps;

export default VotingNumberCollapseBody;
