import AppSettings from './AppSettings/AppSettings';
import BannedAuthors from './BannedAuthors/BannedAuthors';
import ExportSettings from './ExportSettings/ExportSettings';
import FillDataSettings from './FillDataSettings/FillDataSettings';
import InteractivesMessageSettings from './InteractivesMessageSettings/InteractivesMessageSettings';
import InternetTrafficSettings from './InternetTrafficSettings/InternetTrafficSettings';
import ListPaneMod from './ListPaneMod/ListPaneMod';
import MessageFeedSettings from './MessageFeedSettings/MessageFeedSettings';
import MessageRateSettings from './MessageRateSettings/MessageRateSettings';
import MessageViewSettings from './MessageViewSettings/MessageViewSettings';
import SocialSettings from './SocialSettings/SocialSettings';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { Button, Icon, SessionModuleView, Toolbar } from 'components';

import useActions from 'hooks/useActions';

import { LocaleContext } from 'providers/LocaleProvider';

import { confirm } from 'helpers/common';

import styles from './Settings.module.css';

export function Settings() {
  const [localeState, setLocaleState] = useContext(LocaleContext);
  const {
    setMessageActionsConfig,
    setMessageViewConfig,
    setMessageFeedConfig,
    setBroadcastConfig,
    setFeedbackActionsConfig,
    setNewMessagesPosition,
    updateProfileSettings,
    setAvatarForm
  } = useActions();

  const { isNewAtTop } = useSelector((state) => state.settings.messageFeedConfig);

  const resetMessageActionsConfig = () => {
    const messageActionsConfig = {
      copy: true,
      exportToBroadcast: true,
      minimize: true,
      settings: true,
      favorite: true
    };
    setMessageActionsConfig(messageActionsConfig);
  };

  const resetFeedbackActions = () => {
    const feedbackActions = {
      like: true,
      dislike: true,
      prize: true
    };
    setFeedbackActionsConfig(feedbackActions);
  };

  const resetMessageViewConfig = () => {
    const messageViewConfig = {
      idMark: true,
      dateTime: true,
      labels: true, // метки
      region: true, // город, с которого отправляется сообщение<
      // 'like': false,
      attachments: true // вложения
    };
    setMessageViewConfig(messageViewConfig);
    setAvatarForm('circle');
  };

  const resetMessageFeedConfig = () => {
    const messageFeedConfig = {
      isFeedbackEnabled: false,
      isAlwaysAutoScroll: false,
      isNewAtTop,
      tag: [],
      like: [],
      answer: []
    };
    setMessageFeedConfig(messageFeedConfig);
    setNewMessagesPosition(false);
  };

  const resetBroadcastConfig = () => {
    const broadcastConfig = {
      // 'titr': false, // показывать ли кнопки "в титр" в интерактиве
      isExportAvailable: true,
      // 'countChar': false,
      isCharCounterAvailable: false,
      charAmount: 20,
      newMessageBtn: true,
      titmeToAutoScroll: 5 * 1000,
      isAutoSend: false,
      isAutoAdd: false,
      noAutoload: false,
      isProfileCardModal: false
    };
    setBroadcastConfig(broadcastConfig);
  };

  const resetSettings = () => {
    if (confirm(window.t('sureResetSettings'))) {
      resetMessageActionsConfig();
      resetFeedbackActions();
      resetMessageViewConfig();
      resetMessageFeedConfig();
      resetBroadcastConfig();
    }
  };

  return (
    <SessionModuleView>
      <Toolbar title={window.t('settings')}>
        <div style={{ marginLeft: 'auto' }}>
          <Button
            startIcon={<Icon size={16} fontName="far fa-sync-alt" />}
            variant="icon"
            color="#57adf2"
            size="large"
            clickHandler={resetSettings}
            style={{ padding: '6px 8px' }}>
            {window.t('resetSettings')}
          </Button>
        </div>
      </Toolbar>
      <div style={{ height: '100%', padding: 20 }}>
        <h1 className={styles['settings-title']}>{window.t('settings')}</h1>
        <SocialSettings />
        <div className="d-flex mt-5">
          <Button
            className={localeState === window.LOCALE_RU ? styles['active-button'] : null}
            clickHandler={() => {
              setLocaleState(window.LOCALE_RU);
            }}
            color="primary"
            variant="text"
            style={{
              padding: '4px 8px',
              marginRight: '5px'
            }}>
            Русский
          </Button>
          <Button
            className={localeState === window.LOCALE_EN ? styles['active-button'] : null}
            clickHandler={() => {
              setLocaleState(window.LOCALE_EN);
            }}
            color="primary"
            variant="text"
            style={{
              padding: '4px 8px'
            }}>
            English
          </Button>
        </div>
        <AppSettings />
        <ListPaneMod isNewAtTop={isNewAtTop} setNewMessagesPosition={setNewMessagesPosition} />
        <ExportSettings />
        <MessageViewSettings
          updateProfileSettings={updateProfileSettings}
          setAvatarForm={setAvatarForm}
        />
        <MessageFeedSettings />
        <InteractivesMessageSettings />
        <MessageRateSettings />
        {/* <InternetTrafficSettings /> */}
        <BannedAuthors />
        {/* <FillDataSettings /> */}
      </div>
    </SessionModuleView>
  );
}

Settings.propTypes = {
  isNewAtTop: PropTypes.bool
};
