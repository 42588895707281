import { exportToTitr } from './broadcast';
import { addSnack } from './snackBox';

import { broadcastActions } from 'store/slices/broadcastSlice';
import { messageCreatorActions } from 'store/slices/messageCreatorSlice';

import { addBroadcastMessage } from 'api/dataLayer';

export function createMessage() {
  return async (dispatch, getState) => {
    try {
      const { isAutoSend } = getState().settings.broadcastConfig;
      const { broadcastId } = getState().broadcast;
      const { message } = getState().messageCreator;
      // message.broadcast_id = broadcastId;
      // message.date = `${message.date}:00`;

      const newMessage = {
        ...message,
        broadcast_id: broadcastId,
        date: `${message.date}:00`
      };

      const res = await addBroadcastMessage(newMessage);
      const messageCopy = { ...newMessage };
      messageCopy.id = res.body;

      // add to broadcast;
      dispatch(broadcastActions.addMessageToBroadcastSuccess({ msg: messageCopy }));
      dispatch(messageCreatorActions.markAsAddedToBroadcast({ id: messageCopy.id }));

      // export if need
      if (isAutoSend) dispatch(exportToTitr(messageCopy.id, 1)); // export to left
    } catch (ex) {
      console.error(ex);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errCreatingMessage')
        })
      );
    }
  };
}
