import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { SvgCheckbox, TagsInput } from 'components';

import { useMessageFeedSettings } from './hooks/useMessageFeedSettings';

import styles from './MessageFeedSettings.module.css';

function MessageFeedSettings() {
  const { isAlwaysAutoScroll, isFeedbackEnabled, answer, like, tag } = useSelector(
    (state) => state.settings.messageFeedConfig
  );

  const values = useMemo(() => ({ answer, like, tag }), [answer, like, tag]);

  const { toggleAutoScroll, toggleFeedback, showWarning, addValue, deleteValue } =
    useMessageFeedSettings();
  const style = {
    fontWeight: 700,
    borderBottom: '1px dashed #d6d6d6',
    paddingBottom: 5,
    marginTop: 10
  };

  return (
    <>
      <p style={{ marginTop: 30 }}>{window.t('messageInFeed')}</p>
      <div className={styles['msg-view-box']} style={{ maxWidth: '50%' }}>
        <SvgCheckbox
          isChecked={isAlwaysAutoScroll}
          changeHandler={toggleAutoScroll}
          svgMarginLeft="auto"
          style={style}>
          {window.t('autoscrollToNewMessages')}
        </SvgCheckbox>
        <SvgCheckbox
          isChecked={isFeedbackEnabled}
          changeHandler={toggleFeedback}
          svgMarginLeft="auto"
          style={style}>
          {window.t('withFeedback')}
        </SvgCheckbox>
        <div className={styles['msg-actions-box']}>
          {['answer', 'like'].map((type) => (
            <TagsInput
              key={type}
              withEdit
              withEmoji
              isMultiWord
              id={type}
              add={addValue(type)}
              remove={deleteValue(type)}
              tags={values[type]}
              textField="additional_text"
              keyField="id"
              label={window.t(`${type}Values`)}
              onRepeat={showWarning}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default MessageFeedSettings;

MessageFeedSettings.propTypes = {
  messageFeedConfig: PropTypes.object,
  updateMessageFeedConfig: PropTypes.func
};
