import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isVoteActive: false,
  voteId: null,
  variants: [],
  voteCount: 0,
  isMessagesReversed: false,
  winners: [],
  alerts: [],
  isAlertEnable: true
};

export const voteSlice = createSlice({
  name: 'vote',
  initialState,
  reducers: {
    // deleteVariant: (state, action) => {
    //   const { varnum } = action.payload;
    // },
    fetchVoteVariantsSuccess: (state, action) => {
      const { variants } = action.payload;
      return {
        ...state,
        variants
      };
    },
    updateVoteVariantsStatsSuccess: (state, action) => {
      const { variants } = action.payload;
      return {
        ...state,
        variants: state.variants.map((el) => ({
          ...el,
          count: variants[el.varnum].count,
          percent: variants[el.varnum].percent
        }))
      };
    },
    fetchVoteVariantsError: (state, action) => {
      const { error } = action.payload;
      return {
        ...state
      };
    },
    setVoteActivity: (state, action) => {
      const { isVoteActive } = action.payload;
      return {
        ...state,
        isVoteActive
      };
    },
    setTotalVoteCount: (state, action) => {
      const { voteCount } = action.payload;
      return {
        ...state,
        voteCount
      };
    },
    setVoteId: (state, action) => {
      const { id } = action.payload;
      return {
        ...state,
        voteId: id
      };
    },
    addVariant: (state, action) => {
      const { variant } = action.payload;
      return {
        ...state,
        variants: [...state.variants, variant]
      };
    },
    setVoteResultAction: (state, action) => {
      const { result } = action.payload;
      return {
        ...state,
        variants: state.variants.map((el) => ({
          ...el,
          result
        }))
      };
    },
    updateVariantField: (state, action) => {
      const { varnum, field, value } = action.payload;
      return {
        ...state,
        variants: state.variants.map((el) =>
          el.varnum === varnum ? { ...el, [field]: value } : el
        )
      };
    },
    updateVariants: (state, action) => {
      const { variants } = action.payload;
      return {
        ...state,
        variants
      };
    },
    setVoteMessagesOrderAction: (state, action) => {
      const { isMessagesReversed } = action.payload;
      return {
        ...state,
        isMessagesReversed
      };
    },
    setVoteWinnersAction: (state, action) => {
      const { winners } = action.payload;
      return {
        ...state,
        winners
      };
    },
    resetVoteStore: (state, action) => {
      return {
        ...initialState,
        isMessagesReversed: state.isMessagesReversed
      };
    },
    addVoteAlert: (state, action) => {
      const { alert } = action.payload;
      return {
        ...state,
        alerts: [...state.alerts, alert]
      };
    },
    setVoteAlerts: (state, action) => {
      const { alerts } = action.payload;
      return {
        ...state,
        alerts
      };
    },
    setIsAlertEnable: (state, action) => {
      const { isAlertEnable } = action.payload;
      return {
        ...state,
        isAlertEnable
      };
    }
  }
});

export const voteActions = voteSlice.actions;
export const voteReducer = voteSlice.reducer;
