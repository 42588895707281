import React from 'react';

import { getUIDateFromDBDateForChat } from 'helpers/dateTime';

import styles from './DateLine.module.css';

function DateLine(props) {
  return (
    <div
      data-date="2021-02-15 09:40:16"
      data-show-date="15 февр."
      className={styles['main-chat_datetime']}>
      <div data-v-1bcde71e="" className={styles['main-chat_datetime-line']} />
      <h3 data-v-1bcde71e="" className={styles['main-chat_datetime-info']}>
        {getUIDateFromDBDateForChat(props.date)}
      </h3>
      <div data-v-1bcde71e="" className={styles['main-chat_datetime-line']} />
    </div>
  );
}

export default DateLine;
