import React from 'react';

import { Divider, List } from 'components';
import ListItem from 'components/List/ListItem/ListItem';
import ListItemText from 'components/List/ListItem/ListItemText/ListItemText';

import useActions from 'hooks/useActions';

export function BroadcastSettingsMenu({ messageId, hideModal }) {
  const { removeBroadcastMessage, restoreMessage } = useActions();

  const restoreMessageHandler = async () => {
    await restoreMessage(messageId);
    hideModal();
  };

  const removeBroadcastMessageHandler = async () => {
    await removeBroadcastMessage(messageId);
    hideModal();
  };

  return (
    <List>
      <ListItem clickHandler={restoreMessageHandler}>
        <ListItemText primary={window.t('restoreContent')} />
      </ListItem>
      <Divider />
      <ListItem clickHandler={removeBroadcastMessageHandler}>
        <ListItemText primary={window.t('remove')} />
      </ListItem>
    </List>
  );
}
