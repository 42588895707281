import { useEffect } from 'react';

export const FormInitialValuesUpdater = ({
  initialValues,
  setValues,
  formErrors,
  setErrors,
  formTouched,
  setTouched,
  children
}) => {
  useEffect(() => {
    if (!initialValues) return;
    setValues(initialValues);
  }, [setValues, initialValues]);

  useEffect(() => {
    if (!formErrors) return;
    setErrors(formErrors);
  }, [formErrors, setErrors]);

  useEffect(() => {
    if (!formTouched) return;
    setTouched(formTouched);
  }, [formTouched, setTouched]);

  return children;
};
