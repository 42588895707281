import useScroll from './useScroll';
import throttle from 'lodash/throttle';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef
} from 'react';
import { useVirtual } from 'react-virtual';

const easeInOutQuint = (t) => {
  return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
};

const InfiniteScrollVirtualized = forwardRef(
  (
    {
      loadingComponent,
      nextDataFn,
      nextEnd,
      nextLoading,
      previousDataFn,
      previousEnd,
      previousLoading,
      renderRow,
      rows,
      virtualOptions = {},
      handleScroll,
      children
    },
    ref
  ) => {
    const [scrolledToBottom, scrolledToTop, parentRef] = useScroll();
    const scrollingRef = useRef();

    useEffect(() => {
      if (!handleScroll) return;
      const handleScrollWithThrottle = throttle(handleScroll, 100);
      parentRef.current.addEventListener('scroll', handleScrollWithThrottle);
      const ref = parentRef.current;
      return () => {
        ref.removeEventListener('scroll', handleScrollWithThrottle);
      };
    }, [handleScroll, parentRef]);

    const scrollToFn = useCallback((offset, defaultScrollTo) => {
      const duration = 5000;
      const start = parentRef.current.scrollTop;
      const startTime = (scrollingRef.current = Date.now());

      const run = () => {
        if (scrollingRef.current !== startTime) return;
        const now = Date.now();
        const elapsed = now - startTime;
        const progress = easeInOutQuint(Math.min(elapsed / duration, 1));
        const interpolated = start + (offset - start) * progress;

        if (elapsed < duration) {
          defaultScrollTo(interpolated);
          requestAnimationFrame(run);
        } else {
          defaultScrollTo(interpolated);
        }
      };

      requestAnimationFrame(run);
    }, []);

    const { virtualItems, totalSize, scrollToIndex } = useVirtual({
      ...virtualOptions,
      size: rows.length,
      parentRef
      // scrollToFn,
    });

    useImperativeHandle(ref, () => {
      return {
        containerRef: parentRef,
        scrollToIndex,
        totalSize,
        scrolledToBottom
      };
    });

    const anchorData = useRef();
    const isPrependRef = useRef(false);
    const prevLoadingRef = useRef(null);
    const nextLoadingRef = useRef(null);

    useEffect(() => {
      if (!parentRef.current) return;

      if (scrolledToTop && !previousLoading && !previousEnd) {
        previousDataFn();
        isPrependRef.current = true;
      }
    }, [scrolledToTop]);

    useEffect(() => {
      if (!parentRef.current) return;

      if (scrolledToBottom && !nextLoading && !nextEnd) {
        nextDataFn();
      }
    }, [scrolledToBottom]);

    useLayoutEffect(() => {
      if (!parentRef.current) return;

      const height = totalSize;
      if (
        anchorData.current &&
        anchorData.current.rowLength !== rows.length &&
        isPrependRef.current
      ) {
        const loadingHeights =
          (prevLoadingRef.current?.clientHeight || 0) + (nextLoadingRef.current?.clientHeight || 0);
        parentRef.current.scrollTop =
          parentRef.current.scrollTop + (height - anchorData.current.prevHeight - loadingHeights);
        isPrependRef.current = false;
      }

      anchorData.current = { prevHeight: height, rowLength: rows.length };
    }, [totalSize]);

    // const handleScroll = (event) => {
    //   event.target.
    //   console.log({ event })
    // }

    return (
      <div
        // onScroll={handleScroll}
        ref={parentRef}
        style={{
          height: '100%',
          overflowY: 'scroll',
          overflowX: 'hidden'
        }}>
        {previousLoading && <div ref={prevLoadingRef}>{loadingComponent}</div>}
        <div
          style={{
            height: `${totalSize}px`,
            width: '100%',
            position: 'relative'
          }}>
          {virtualItems.map((virtualRow) => (
            <div
              key={virtualRow.index}
              ref={virtualRow.measureRef}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${rows[virtualRow.index]}px`,
                transform: `translateY(${virtualRow.start}px)`
              }}>
              {renderRow(rows[virtualRow.index])}
            </div>
          ))}
        </div>
        {nextLoading && <div ref={nextLoadingRef}>{loadingComponent}</div>}
        {children}
      </div>
    );
  }
);

InfiniteScrollVirtualized.displayName = 'InfiniteScrollVirtualized';

export default InfiniteScrollVirtualized;
