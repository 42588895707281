import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Button, Icon, List, ListItem, ListItemText, Menu } from 'components';

const propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      title: PropTypes.string
    })
  ),
  selectedOption: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    title: PropTypes.string
  }),
  setSelectedOption: PropTypes.func,
  isSelectDisabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object
};

const defaultProps = {
  color: '#000'
};

export function Select({
  keyField,
  label,
  options,
  selectedOption,
  setSelectedOption,
  width,
  className,
  isSelectDisabled,
  disabledMessage,
  color,
  style,
  locale,
  listClassName,
  buttonClassName,
  optionClassName,
  withResetButton,
  setStepIndex,
  chooseTypeRef,
  chooseAnotherTypeRef,
  isTagsNoOption = true
}) {
  const [isActive, setIsActive] = useState(false);
  const [selectBtnRect, setSelectBtnRect] = useState(false);

  const startActivatorHandler = (e) => {
    if (e) {
      const rect = e.currentTarget.getBoundingClientRect();
      setSelectBtnRect(rect);
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
    setStepIndex?.((v) => ++v);
    setIsActive((v) => !v);
  };

  const handleResetValueButtonClick = (e) => {
    e.stopPropagation();
    setSelectedOption({ title: '', value: '' });
  };

  const isNoOptions = !options || (options.length === 0 && isTagsNoOption);
  const isDisabled = isSelectDisabled || isNoOptions;

  const resetButtonStyles = {
    padding: 0,
    minWidth: 0,
    marginRight: '10px',
    background: 'none',
    cursor: 'pointer'
  };

  const selectBtn = (
    <Button
      type="button"
      className={buttonClassName}
      endIcon={
        <div className="ml-3 d-flex">
          {withResetButton && (
            <span onClick={handleResetValueButtonClick} style={resetButtonStyles}>
              <Icon size={18} fontName="fal fa-times" color="red" />
            </span>
          )}
          <Icon size={16} fontName="far fa-chevron-down" />
        </div>
      }
      variant="icon"
      color={color}
      size="large"
      clickHandler={startActivatorHandler}
      style={{
        padding: '6px 8px',
        ...(style && style),
        ...(width && { width })
      }}
      textStyle={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre'
      }}
      endIconStyle={{
        marginLeft: 'auto'
      }}
      {...(isDisabled && {
        title: isNoOptions
          ? window.t('noAvailableSlots')
          : isSelectDisabled
          ? disabledMessage
          : null
      })}
      isDisabled={isDisabled}>
      <>
        <div style={{ alignSelf: 'flex-start' }}>
          {selectedOption?.title ||
            (selectedOption?.label?.[locale] ?? selectedOption?.label) ||
            label}
        </div>
        {selectedOption?.value?.tags && (
          <div
            style={{
              display: 'flex',
              gap: '10px',
              color: '#999'
            }}>
            {selectedOption?.value?.tags.map((tag) => (
              <div key={tag}>#{tag}</div>
            ))}
          </div>
        )}
      </>
    </Button>
  );

  return (
    <Menu
      activator={selectBtn}
      activatorHandler={startActivatorHandler}
      isActive={isActive}
      left={selectBtnRect ? selectBtnRect.left : 0}
      top={selectBtnRect ? selectBtnRect.bottom : 0}
      className={className}>
      {/* Два рефа для того, если у нас на экране есть два селектора, иначе ломается при гайде для автоответчика */}

      <div ref={options?.length === 2 ? chooseAnotherTypeRef : chooseTypeRef}>
        <List
          className={listClassName}
          style={{
            ...(width && { width })
          }}>
          {options.map((option) => (
            <ListItem
              className={optionClassName}
              key={option?.value?.[keyField] ?? option?.value}
              isActive={
                (option?.value?.[keyField] ?? option?.value) ===
                  selectedOption?.value?.[keyField] ?? selectedOption?.value
              }
              clickHandler={() => {
                setSelectedOption(option);
                setIsActive((v) => !v);
                setStepIndex?.((v) => ++v);
              }}
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}>
              <ListItemText
                style={{ alignSelf: 'flex-start' }}
                primary={option?.title || (option?.label?.[locale] ?? option?.label)}
                withTitle
              />
              {option?.value?.tags && (
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    color: '#999'
                  }}>
                  {option?.value?.tags.map((tag) => (
                    <div key={tag}>#{tag}</div>
                  ))}
                </div>
              )}
            </ListItem>
          ))}
        </List>
      </div>
    </Menu>
  );
}

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;
