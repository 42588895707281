import { BroadcastView } from './BroadcastView';
import React from 'react';

import { useInteractive } from 'hooks';

import { INTERACTIVES } from 'store/constants';

import { useBroadcast } from './hooks/useBroadcast';
import { useBroadcastGuide } from './hooks/useBroadcastGuide';

export function BroadcastContainer({ match, location }) {
  const { interactiveId, isSettingsOpened, setIsSettingsOpened } = useInteractive({
    match,
    location,
    type: INTERACTIVES.broadcast
  });

  const {
    interactiveName,
    isInteractiveExist,
    isBroadcastStateFixed,
    setIsBroadcastStateFixed,
    isMessageCreatorOpened,
    toggleSettings,
    toggleMessageCreator,
    visibility,
    activity,
    settings,
    state
  } = useBroadcast({ isSettingsOpened, setIsSettingsOpened });

  const {
    run,
    startGuide,
    steps,
    stepIndex,
    setStepIndex,
    handleJoyrideCallback,
    interactiveInfoRef,
    uploadExcelRef,
    autoCheckboxesRef,
    exportButtonsRef,
    statesSettingsRef,
    okButtonRef,
    statesPanelRef,
    startButtonRef,
    addClearRef
  } = useBroadcastGuide({ type: INTERACTIVES.broadcast });

  return (
    <BroadcastView
      interactiveName={interactiveName}
      isInteractiveExist={isInteractiveExist}
      isBroadcastStateFixed={isBroadcastStateFixed}
      setIsBroadcastStateFixed={setIsBroadcastStateFixed}
      isMessageCreatorOpened={isMessageCreatorOpened}
      isSettingsOpened={isSettingsOpened}
      toggleSettings={toggleSettings}
      toggleMessageCreator={toggleMessageCreator}
      visibility={visibility}
      activity={activity}
      broadcastId={interactiveId}
      settings={settings}
      state={state}
      // guide props
      run={run}
      startGuide={startGuide}
      steps={steps}
      stepIndex={stepIndex}
      setStepIndex={setStepIndex}
      handleJoyrideCallback={handleJoyrideCallback}
      interactiveInfoRef={interactiveInfoRef}
      uploadExcelRef={uploadExcelRef}
      autoCheckboxesRef={autoCheckboxesRef}
      exportButtonsRef={exportButtonsRef}
      statesSettingsRef={statesSettingsRef}
      okButtonRef={okButtonRef}
      statesPanelRef={statesPanelRef}
      startButtonRef={startButtonRef}
      addClearRef={addClearRef}
    />
  );
}
