export const filterByParticipants = (participants, registrationFilters) => {
  const activeParticipants = registrationFilters
    .map((el) => (el.isActive ? el.value : null))
    .filter((el) => el !== null);
  return activeParticipants.length === 0 || activeParticipants.length === registrationFilters.length
    ? participants
    : participants.filter((el) => activeParticipants.includes(el.author_data.participation));
};

export const filterByTextRegistration = (filteredSessions, searchText) => {
  const regPhrase = new RegExp(searchText, 'i');
  return searchText.length === 0
    ? filteredSessions
    : filteredSessions.filter(
        (el) =>
          regPhrase.test(el.author_data.name) ||
          regPhrase.test(el.author_data.surname) ||
          regPhrase.test(el.author_data.position)
      );
};
