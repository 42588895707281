import { generateUrlWithGetParams } from '.';
import { socketToken } from 'sockets/constants';

import { getCurrentDomain } from 'helpers/routing';

export const POST = 'POST';
export const GET = 'GET';

export const BASE_URL = `${window.location.protocol}//${getCurrentDomain()}.iactive.pro/scr`;

export async function requestApi({
  method = POST,
  fileKey,
  signal,
  url = '',
  getParams = {},
  postBody = null,
  successCallback = () => {},
  errorCallback = () => {},
  onLoad,
  onProgress
}) {
  url =
    url.indexOf('http://') === -1 && url.indexOf('https://') === -1
      ? BASE_URL + generateUrlWithGetParams(url, getParams)
      : generateUrlWithGetParams(url, getParams);
  const params = {
    method,
    signal
  };
  const userInfoStr = localStorage.getItem('userInfo');
  const userInfo = userInfoStr ? JSON.parse(userInfoStr) : {};
  params.headers = {
    // 'Content-Type': 'multipart/form-data',
    AUTHENTICATION: userInfo?.clientNum || '',
    'Version-back': userInfo?.versionBack || 'dev',
    'Version-front': userInfo?.versionFront || 'dev'
  };
  if (method === POST || postBody) {
    const formData = new FormData();

    formData.append('userName', userInfo?.clientNum || '');
    formData.append('socketPort', userInfo?.socketPort || 8001);
    formData.append('socketToken', socketToken);
    formData.append('token', '000000000000000000');

    if (postBody)
      for (const [key, val] of Object.entries(postBody)) {
        const value =
          typeof val === 'object' && val !== null && val.isBinary !== true
            ? JSON.stringify(val)
            : val;
        if (val && (key === 'file' || key === fileKey)) {
          if (!Array.isArray(val)) {
            formData.append(key, val, val.name);
          } else {
            val.forEach((file) => {
              formData.append(`${key}[]`, file, file.name);
            });
          }
        } else {
          formData.append(key, value);
        }
      }

    params.body = formData;
    // const xhr = new XMLHttpRequest();

    // if (onProgress) xhr.upload.addEventListener("progress", onProgress, false);
    // if (onLoad) xhr.addEventListener("load", onLoad, false);

    // xhr.open("POST", url);
    // xhr.setRequestHeader("AUTHENTICATION", userInfo?.clientNum || "");
    // xhr.setRequestHeader("Version-back", userInfo?.versionBack || "dev");
    // xhr.setRequestHeader("Version-front", userInfo?.versionFront || "dev");
    // xhr.send(formData);
  }

  const response = await fetch(url, params);

  // const contentType = response.headers.get('content-type');
  // const result = (contentType && contentType.indexOf("application/json") !== -1) ? await response.json() : await response.text();
  let responseText = await response.text();
  let result;

  try {
    result = JSON.parse(responseText);
  } catch (ex) {
    result = responseText;
  }

  if (result.statusCode !== 500) {
    if (successCallback) {
      successCallback(result);
    }
  }
  return result;
}
