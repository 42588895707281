import React from 'react';

import { ControlsView } from 'components';

import { useToolbarContentControls } from './hooks/useToolbarContentControls';

export function ContentToolbarControls({ settingsClickHandler }) {
  const { controls } = useToolbarContentControls({ settingsClickHandler });

  return <ControlsView>{controls}</ControlsView>;
}
