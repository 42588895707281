import classNames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';

import { checkIsValid } from 'containers/ContentContainer/components/NewsBlock/components/ContentFields/helpers/validationFields';

import { Button, Icon } from 'components';

import { useActions } from 'hooks';

import styles from './VideoLoader.module.scss';

const DEFAULT_MAX_FILE_SIZE_IN_MEGABYTES = 10;

export function VideoLoader({
  uploadVideo,
  isVideoUploading,
  convertSpeech,
  isSpeechConverting,
  video,
  width,
  height,
  label,
  isShowConvertButton,
  updateFilesCb,
  updateBlockField,
  block,
  elementKey,
  maxFileSizeInMB = DEFAULT_MAX_FILE_SIZE_IN_MEGABYTES,
  ...otherProps
}) {
  const { addSnack } = useActions();
  const inputRef = useRef(null);
  const videoRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);

  const isValid = useMemo(
    () => checkIsValid(elementKey, block, block.isTouched, null, updateBlockField),
    [video, block.isTouched]
  );

  // определение продолжительности видео
  useEffect(() => {
    if (!video) {
      updateBlockField(block.id, 'durationVideo', 0, 'value');
    } else if (videoRef.current) {
      videoRef.current.onloadeddata = () =>
        updateBlockField(block.id, 'durationVideo', videoRef?.current?.duration, 'value');
    }
  }, [video]);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
      e.dataTransfer.dropEffect = 'copy';
      e.dataTransfer.effectAllowed = 'copy';
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleUploadVideoFile = (file) => {
    const fileSizeInMB = Number((file.size / 1024 / 1024).toFixed(4));
    if (fileSizeInMB > +maxFileSizeInMB) {
      addSnack({
        type: 'danger',
        message: window.t('videoFileIsTooLarge').replace('[sizeLimitInMB]', +maxFileSizeInMB)
      });
      return false;
    }

    uploadVideo(file);
  };

  // triggers when file is selected with click
  const handleChange = async function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleUploadVideoFile(e.target.files[0]);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  const handleDrop = async function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    e = (e && e.originalEvent ? e.originalEvent : window.event) || e;
    const files = e.files || e.dataTransfer.files;

    if (files && files[0]) {
      handleUploadVideoFile(files[0]);
    }
  };
  // распознавание речи: 1 мин аудиофайла за 10 сек (yandex cloud)
  const yandex_speed_convertSpeech = 10;
  const durationSpeech = Math.round(block?.durationVideo?.value || 0);
  const waitingTime = Math.ceil(durationSpeech / 60) * yandex_speed_convertSpeech;

  const classes = classNames(styles.fileUploadContainer, {
    [styles.invalid]: !isValid
  });

  return (
    <div>
      {isSpeechConverting && (
        <div className={styles.prompt}>
          {window.t('approximateSpeechRecognitionTime')}
          {waitingTime}
          {window.t('timeSec')}
        </div>
      )}
      {video && typeof video === 'object' && (
        <div className={styles.video}>
          <video
            ref={videoRef}
            className={styles.video__player}
            width="100%"
            height={height}
            controls
            src={video.urlVideo}
          />
          <div className={styles.video__fileName}>{video.name}</div>
          <div className={styles.video__buttons}>
            {isShowConvertButton && (
              <Button
                clickHandler={() => convertSpeech(video)}
                title={window.t('generateText')}
                className={styles.video__generateText}
                isLoading={isSpeechConverting}
                isDisabled={isSpeechConverting}
                variant="icon"
                onlyIcon
                startIcon={
                  <div className={styles.video__iconsContainer}>
                    <Icon size={20} fontName="fas fa-film" />
                    <Icon size={20} fontName="fas fa-arrow-right" />
                    <Icon size={20} fontName="fas fa-text" />
                  </div>
                }
              />
            )}
            <Button
              clickHandler={() => uploadVideo(null, video.urlVideo)}
              title={window.t('remove')}
              className={styles.video__removeButton}
              startIcon={<Icon size={20} fontName="fas fa-trash" />}
              onlyIcon
              variant="icon"
            />
          </div>
        </div>
      )}
      {(!video || typeof video !== 'object') && (
        <form className={classes} onSubmit={(e) => e.preventDefault()}>
          <div
            className={styles.dropContainer}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}>
            <label className={styles.inputLabel} htmlFor="video-uploader">
              <Button
                isLoading={isVideoUploading}
                isDisabled={isVideoUploading}
                clickHandler={onButtonClick}
                className={styles.uploadFileBtn}
                startIcon={<Icon size={20} fontName="fas fa-file-upload" />}>
                <span>{window.t('uploadVideo')}</span>
              </Button>
            </label>
            <input
              className={styles.formField}
              accept="video/*"
              type="file"
              id="video-uploader"
              ref={inputRef}
              onChange={handleChange}
              title=""
              value=""
              {...otherProps}
            />
          </div>
        </form>
      )}
    </div>
  );
}
