import PropTypes from 'prop-types';
import React from 'react';

import styles from './RoundCheckbox.module.css';

function RoundCheckbox({ changeHandler, isChecked, label, style }) {
  return (
    <div id="select-all-btn" className={styles['select-all-btn']} style={style}>
      <label className={styles['pure-material-checkbox']}>
        {/* onchange="toggleCheckedAll(event.currentTarget)"  */}
        <input
          type="checkbox"
          onChange={() => {
            changeHandler(!isChecked);
          }}
          checked={isChecked}
          id="check-post"
          data-net="vkcomm"
        />
        <span style={{ border: 'none' }}>
          <span className={styles['select-all-text']}>{label}</span>
        </span>
      </label>
    </div>
  );
}

export default RoundCheckbox;

RoundCheckbox.propTypes = {
  changeHandler: PropTypes.func,
  isChecked: PropTypes.bool,
  label: PropTypes.string,
  style: PropTypes.object
};

RoundCheckbox.defaultProps = {
  isChecked: false,
  style: {}
};
