import {
  SET_MODAL_CURRENT_IMAGE,
  SET_MODAL_IMAGE_IS_OPENED,
  SET_MODAL_IMAGE_LIST,
  SET_MODAL_IMAGE_USER_INFO,
  UPDATE_FEED_ITEM_AUDIO
} from '../actions/actionTypes';

const initialState = {
  userInfo: {
    avatar: '',
    name: ''
  },
  imageList: [],
  currentImage: null,
  isOpened: false
};

export default function modalImageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MODAL_IMAGE_USER_INFO:
      return {
        ...state,
        userInfo: action.userInfo
      };
    case SET_MODAL_IMAGE_LIST:
      return {
        ...state,
        imageList: action.imageList
      };
    case SET_MODAL_CURRENT_IMAGE:
      return {
        ...state,
        currentImage: action.image
      };
    case SET_MODAL_IMAGE_IS_OPENED:
      return {
        ...state,
        isOpened: action.isOpened
      };
    case UPDATE_FEED_ITEM_AUDIO:
      return {
        ...state,
        currentImage: state.currentImage === action.prevSrc ? action.newSrc : action.prevSrc,
        imageList: state.imageList.map((image) =>
          image.messageId !== action.id ? image : { ...image, src: action.newSrc }
        )
      };
    default:
      return state;
  }
}
