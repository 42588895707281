import { DropAreaView } from './DropAreaView';
import React from 'react';

import { useDropArea } from './hooks/useDropArea';

export function DropAreaContainer() {
  const { isActive, isHovered, dragOver, dragEnter, dragLeave, dragDrop } = useDropArea();
  return (
    <DropAreaView
      isActive={isActive}
      isHovered={isHovered}
      dragOver={dragOver}
      dragEnter={dragEnter}
      dragLeave={dragLeave}
      dragDrop={dragDrop}
    />
  );
}
