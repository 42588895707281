import ProfileChooser from '../ProfileChooser/ProfileChooser';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { SvgCheckbox, Title } from 'components';

import useActions from 'hooks/useActions';

import { NOTIFICATION_PERMISSIONS } from 'store/constants';

function AppSettings() {
  const { addSnack, setIsAlertEnable, permissionChange } = useActions();
  const { permission } = useSelector((state) => state.notifications);
  const { isAlertEnable } = useSelector((state) => state.vote);

  useEffect(() => {
    if (Notification.permission !== permission)
      permissionChange({ permission: Notification.permission });
  }, [permission]);

  const togglePushNotification = (makeEnable = true) => {
    if (makeEnable) {
      Notification.requestPermission((permission) => {
        if (permission === NOTIFICATION_PERMISSIONS.granted) {
          permissionChange({ permission });
        } else {
          addSnack({
            type: 'info',
            message: window
              .t('enablePushNotificationsInSettings')
              .replace(/\[hostname\]/, window.location.hostname)
          });
        }
      });
    } else {
      Notification.requestPermission((permission) => {
        if (permission === NOTIFICATION_PERMISSIONS.granted) {
          addSnack({
            type: 'info',
            message: window
              .t('disablePushNotificationsInSettings')
              .replace(/\[hostname\]/, window.location.hostname)
          });
        } else {
          permissionChange({ permission: NOTIFICATION_PERMISSIONS.denied });
        }
      });
    }
  };

  return (
    <div>
      <Title
        size="1.17em"
        color="#000"
        style={{
          borderTop: '1px solid #ddd',
          padding: '1em 0',
          margin: '1em 0'
        }}>
        {window.t('appSettings')}
      </Title>
      <ProfileChooser />
      <SvgCheckbox
        isChecked={permission === NOTIFICATION_PERMISSIONS.granted}
        changeHandler={togglePushNotification}
        svgMarginLeft="auto"
        style={{
          fontWeight: 700,
          borderBottom: '1px dashed #d6d6d6',
          paddingBottom: 5,
          marginTop: 10,
          maxWidth: '50%'
        }}>
        {window.t('enablePushNotifications')}
      </SvgCheckbox>
      <SvgCheckbox
        isChecked={isAlertEnable}
        changeHandler={setIsAlertEnable}
        svgMarginLeft="auto"
        style={{
          fontWeight: 700,
          borderBottom: '1px dashed #d6d6d6',
          paddingBottom: 5,
          marginTop: 10,
          maxWidth: '50%'
        }}>
        {window.t('enableVoteNotifications')}
      </SvgCheckbox>

      {/* <button onClick={()=>{
                window.open('chrome://settings/content/notifications');
            }}> chrome</button> */}
    </div>
  );
}

export default AppSettings;
