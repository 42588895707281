import { AnswerMessage } from '../AnswerMessage/AnswerMessage';
import { LocationButton } from '../LocationButton/LocationButton';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { getAutoreplyTypeOptions } from 'containers/AutoReplyContainer/constants';
import { getItemStyle, reorder } from 'containers/AutoReplyContainer/utils';

import { Button, Icon, Input, Select } from 'components';

import { useActions } from 'hooks';

import { getWeatherByCity } from 'api/dataLayer';

import { getIconImage } from 'helpers/image';

import styles from './AutoReplyBody.module.scss';

const getTextValueByParams = async (type, weatherParams, textField) => {
  switch (type) {
    case 'text':
    case 'link':
      return textField;
    case 'weather':
      try {
        const weatherData = await getWeatherByCity(weatherParams);
        if (weatherData.code !== 200) {
          throw new Error(weatherData.body);
        }
        return weatherData.body;
      } catch {
        throw new Error('Weather data nof found');
      }
    case 'traffic':
      return 'На дорогах пробки - 10 баллов';
    default:
      return;
  }
};

export function AutoReplyBody({
  startWord,
  editAutoreply,
  autoReplyId,
  template,
  controlsRef,
  setStepIndex,
  newsTypeRef,
  chooseTypeRef,
  contentViewRef,
  greenRef
}) {
  const inputRef = useRef(null);
  const lastElementRef = useRef(null);
  const [valueOnEdit, setValueOnEdit] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [currentSelectedType, setCurrentSelectedType] = useState(null);
  const [weatherOptions, setWeatherOptions] = useState(null);
  const [textField, setTextField] = useState('');
  const [isFormEnabled, setIsFormEnabled] = useState(false);

  const { updateInfoField, addSnack } = useActions();

  const values = template || [];

  const fields = useMemo(
    () => ({
      text: { value: 'text', title: window.t('text') },
      weather: {
        value: 'weather',
        title: window.t('weather'),
        options: {}
      },
      link: { value: 'link', title: window.t('link') }
    }),
    []
  );

  const updateTemplate = (newTemplate) => {
    editAutoreply({
      id: autoReplyId,
      template: newTemplate
    });
    updateInfoField('template_message', newTemplate);
  };

  const onDragStart = () => {
    setIsDragging(true);
  };

  const onDragEnd = (result) => {
    setIsDragging(false);
    if (!result.destination) {
      return;
    }

    if (result.destination.droppableId === 'delete') {
      const newItems = values.filter((_, i) => i !== result.source.index);
      updateTemplate(newItems);
      return;
    }

    const newItems = reorder(values, result.source.index, result.destination.index);

    updateTemplate(newItems);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const textValue = await getTextValueByParams(
        currentSelectedType.value,
        weatherOptions,
        textField
      );

      if (!textValue) return;

      const getNewValues = () => {
        const targetValue = {
          textValue,
          type: currentSelectedType.value,
          ...(weatherOptions && {
            location: weatherOptions.location,
            params: weatherOptions
          })
        };

        let newValues;
        if (valueOnEdit) {
          newValues = values.map((value) =>
            value.id !== valueOnEdit.id
              ? value
              : {
                  ...value,
                  ...targetValue
                }
          );
        } else {
          newValues = [
            ...values,
            {
              id: Math.random(),
              ...targetValue
            }
          ];
        }
        return newValues;
      };

      const newValues = await getNewValues();

      updateTemplate(newValues);

      // setValueOnEdit(null);
      setTextField('');
      setCurrentSelectedType(null);
      setWeatherOptions(null);
      setIsFormEnabled(false);
    } catch (e) {
      addSnack({
        type: 'danger',
        message: 'Город не найден!'
      });
    }
  };

  useEffect(() => {
    if (valueOnEdit) {
      setValueOnEdit(null);
    } else {
      lastElementRef.current.scrollIntoView();
    }
  }, [values]);

  useEffect(() => {
    if (!valueOnEdit) return;

    setIsFormEnabled(true);
    const targetType = getAutoreplyTypeOptions().find((el) => el.value === valueOnEdit.type);
    setCurrentSelectedType(targetType);
    setWeatherOptions(valueOnEdit.params);
    setTextField(valueOnEdit.textValue);
  }, [valueOnEdit]);

  useEffect(() => {
    if (!isFormEnabled) setValueOnEdit(null);
  }, [isFormEnabled]);

  const handleEmojiClick = useCallback((emoji) => {
    if (emoji) {
      setTextField((value) => value + emoji);
      inputRef.current?.focus?.();
    }
  }, []);

  return (
    <div className={styles.container}>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <div className={styles.body}>
          <div className={styles.preview}>
            <img className={styles.preview__img} src={getIconImage('phone.png')} alt="" />
            <div className={styles.preview__inner}>
              <div className={styles.preview__scrollBox}>
                {startWord.map((word, i) => (
                  <AnswerMessage key={word + i} incoming value={{ textValue: word }} />
                ))}

                {!!values.length && (
                  <div className={styles.responses}>
                    <Droppable droppableId="messages">
                      {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          {values.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                    onDoubleClick={() => setValueOnEdit(item)}>
                                    <AnswerMessage
                                      value={item}
                                      className={classNames(styles.message, {
                                        [styles.message_onEdit]: item.id === valueOnEdit?.id
                                      })}
                                    />
                                  </div>
                                );
                              }}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                )}
                <div ref={lastElementRef} />
              </div>

              <div className={styles.preview__controls}>
                <div ref={controlsRef}>
                  {!isFormEnabled && (
                    <Button
                      className={styles.addButton}
                      startIcon={<Icon size={18} fontName="fa fa-plus" />}
                      onlyIcon
                      color="white"
                      hoverColor="#6fc345"
                      tag="button"
                      type="button"
                      clickHandler={() => {
                        setStepIndex?.((v) => ++v);
                        setIsFormEnabled(true);
                      }}
                    />
                  )}
                </div>

                <div ref={newsTypeRef}>
                  {isFormEnabled && (
                    <form className={styles.form} onSubmit={handleSubmit}>
                      <Select
                        setStepIndex={setStepIndex}
                        chooseTypeRef={chooseTypeRef}
                        className={classNames(styles.form__field, {
                          [styles.form__field_halfSize]: fields[currentSelectedType?.value]?.options
                        })}
                        label={window.t('type')}
                        options={getAutoreplyTypeOptions()}
                        selectedOption={currentSelectedType}
                        setSelectedOption={setCurrentSelectedType}
                      />
                      <div className={styles.form__textFieldContainer} ref={contentViewRef}>
                        {currentSelectedType?.value &&
                          !fields[currentSelectedType?.value]?.options && (
                            <Input
                              ref={inputRef}
                              value={textField}
                              changeHandler={(event) => {
                                setTextField(event.target.value);
                              }}
                              // emojiPickerOffset={200}
                              handleEmojiClick={handleEmojiClick}
                              withEmojiPicker
                              multiline
                              emoji={true}
                            />
                          )}
                      </div>

                      {['weather', 'traffic'].includes(currentSelectedType?.value) &&
                        fields[currentSelectedType?.value]?.options && (
                          <LocationButton
                            {...(weatherOptions && {
                              initialData: weatherOptions
                            })}
                            handleAdd={(data) =>
                              setWeatherOptions((value) => ({
                                ...value,
                                ...data
                              }))
                            }
                          />
                        )}
                      {currentSelectedType &&
                      (fields[currentSelectedType?.value]?.options ? weatherOptions : textField) ? (
                        <Button
                          startIcon={<Icon size={18} fontName="fa fa-check" />}
                          onlyIcon
                          color="green"
                          ref={greenRef}
                          variant="message-action"
                          tag="button"
                          type="submit"
                          setStepIndex={setStepIndex}
                        />
                      ) : (
                        <Button
                          startIcon={<Icon size={18} fontName="fa fa-times" />}
                          onlyIcon
                          color="red"
                          variant="message-action"
                          tag="button"
                          type="button"
                          clickHandler={() => setIsFormEnabled(false)}
                        />
                      )}
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Droppable droppableId="delete">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <div
                className={classNames(styles.delete, {
                  [styles.delete_isDragging]: isDragging
                })}>
                <i className="fas fa-trash" />
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
