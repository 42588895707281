import SessionRowPinned from './SessionRowPinned/SessionRowPinned';

import { useActions } from 'hooks';

import { debounceUpdateProfileSettings } from 'store/thunks/pinnedInteractives';

import styles from './PinInteractivesList.module.css';

function PinInteractivesList({
  pinInteractives,
  currentProfile,
  togglePinHandler,
  selectSession,
  openSession,
  selectedSessions,
  pins
}) {
  const { setPinnedInteractives } = useActions();

  const pinArrayInteractives = [...pinInteractives];

  const dragStartHandler = (evt) => {
    evt.target.classList.add(`selectedPin`);
  };
  const dragEndHandler = (evt) => {
    evt.target.classList.remove(`selectedPin`);

    const arrCurrentOrder = [...evt.currentTarget.childNodes].map((el) => el.id);
    const arrOrder = arrCurrentOrder.map((el, i) => {
      if (pinArrayInteractives[i].id === el) {
        return pinArrayInteractives[i];
      } else {
        return pinArrayInteractives.find((int) => int.id === el);
      }
    });
    setPinnedInteractives(arrOrder);
    debounceUpdateProfileSettings({ pinnedInteractives: arrOrder }, currentProfile.id);
  };
  const dragOverHandler = (evt) => {
    evt.preventDefault();
    const activeElement = evt.currentTarget.querySelector(`.selectedPin`);
    const currentElement = evt.target;
    const isMoveable = activeElement !== currentElement && currentElement.localName === 'li';
    if (!isMoveable) {
      return;
    }

    const nextElement =
      currentElement === activeElement.nextElementSibling
        ? currentElement.nextElementSibling
        : currentElement;

    evt.currentTarget.insertBefore(activeElement, nextElement);
  };

  return (
    <ul
      className={styles.pinnedList}
      onDragStart={dragStartHandler}
      onDragEnd={dragEndHandler}
      onDragOver={dragOverHandler}>
      {pins?.length !== 0 &&
        pins?.map((el) => {
          if (el) {
            return (
              <SessionRowPinned
                pinInteractives={pinInteractives}
                isSelected={selectedSessions.includes(`${el.type}_${el.id}`)}
                selectSession={selectSession}
                openSession={openSession}
                rowData={el}
                key={`${el.type}_${el.id}`}
                togglePinHandler={togglePinHandler}
              />
            );
          }
        })}
    </ul>
  );
}

export default PinInteractivesList;
