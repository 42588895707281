import { useCallback } from 'react';

import useActions from 'hooks/useActions';

export const useBroadcastSettingsMenu = ({ messageId }) => {
  const { removeBroadcastMessage, restoreMessage } = useActions();

  const restoreHandler = useCallback(() => restoreMessage(messageId), [messageId, restoreMessage]);
  const removeHandler = useCallback(
    () => removeBroadcastMessage(messageId),
    [messageId, removeBroadcastMessage]
  );

  return {
    restoreHandler,
    removeHandler
  };
};
