import PropsTypes from 'prop-types';
import React from 'react';

import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import CardContent from 'components/Card/CardContent/CardContent';
import CardHeader from 'components/Card/CardHeader/CardHeader';
import Icon from 'components/Icon/Icon';
import Title from 'components/Title/Title';

import styles from './AddGroupCardModal.module.css';

const propTypes = {
  title: PropsTypes.string,
  headerColor: PropsTypes.string,
  closeHandler: PropsTypes.func,
  children: PropsTypes.oneOfType([PropsTypes.element, PropsTypes.arrayOf(PropsTypes.element)]),
  style: PropsTypes.object
};

function AddGroupCardModal({ title, headerColor, closeHandler, children, style }) {
  return (
    <Card className={styles['delete-card-modal']} style={style}>
      <CardHeader style={{ background: headerColor }} className={styles['card-header']}>
        <Title color="#ffffff" size={20}>
          {title}
        </Title>
        <Button
          onlyIcon
          startIcon={<Icon size={18} color="#ffffff" fontName="far fa-times" />}
          variant="icon"
          hoverColor="transparent"
          className="ml-auto"
          style={{
            opacity: 1
          }}
          clickHandler={closeHandler}
        />
      </CardHeader>
      <CardContent style={{ padding: 40 }}>{children}</CardContent>
    </Card>
  );
}

export default AddGroupCardModal;

AddGroupCardModal.propTypes = propTypes;
