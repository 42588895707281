import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchText: '',
  isSearchActive: false,
  isFilterActive: false,
  isFiltered: false,
  interactives: [
    {
      type: 'vote',
      title: 'voting',
      img: 'module-vote.png',
      description: 'votingDesc',
      isActive: true
    },
    {
      type: 'quiz',
      title: 'Викторину',
      img: 'module-quiz.png',
      description: 'Задача респондентов – быстро и правильно ответить на поставленный вопрос.',
      isActive: true
    },
    {
      type: 'broadcast',
      title: 'broadcasting',
      img: 'module-tv.png',
      description: 'broadcastingDesc',
      isActive: true
    },
    // {
    //   type: 'pulse',
    //   title: 'Пульс эфира',
    //   img: 'module-pulse.png',
    //   description: 'Пульс эфира позволит отслеживать активность аудитории во время эфира',
    //   isActive: true
    // },
    {
      type: 'content',
      title: 'news',
      img: 'module-content.png',
      description: 'newsDesc',
      isActive: true
    }
  ]
};

export const sessionsFilterSlice = createSlice({
  name: 'sessionsFilter',
  initialState,
  reducers: {
    // setFilteredSessions: (state, action) => {
    //   const { toShow, interactives } = action.payload;
    //   return {
    //     ...state
    //   };
    // },
    setSearchActivity: (state, action) => {
      const { value } = action.payload;
      return {
        ...state,
        isSearchActive: value
      };
    },
    setFilterActivity: (state, action) => {
      const { value } = action.payload;
      return {
        ...state,
        isFilterActive: value
      };
    },
    setSessionsSearchTextSuccess: (state, action) => {
      const { value } = action.payload;
      return {
        ...state,
        searchText: value
      };
    },
    setInteractiveActivitySuccess: (state, action) => {
      const { interactiveType, value } = action.payload;
      return {
        ...state,
        interactives: state.interactives.map((el) =>
          el.type === interactiveType ? { ...el, isActive: value } : el
        )
      };
    },
    setIsFiltered: (state, action) => {
      const { value } = action.payload;
      return {
        ...state,
        isFiltered: value
      };
    }
  }
});

export const sessionsFilterActions = sessionsFilterSlice.actions;
export const sessionsFilterReducer = sessionsFilterSlice.reducer;
