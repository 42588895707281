import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Icon,
  SvgCheckbox,
  Text,
  Title
} from 'components';

import styles from './DeleteCardModal.module.css';

const propTypes = {
  data: PropTypes.object,
  closeHandler: PropTypes.func
};

function DeleteCardModal({ data, closeHandler }) {
  const [groupData, setGroupData] = useState({});

  useEffect(() => {
    setGroupData(data);
    return () => {
      setGroupData({});
    };
  }, [data.random]);

  const checkboxHandler = async (key, isChecked) => {
    setGroupData((data) => ({ ...data, [key]: isChecked }));
  };

  const { groupName, isComment, isMessage, deleteRequest, removeMessage } = groupData;
  return (
    <Card className={styles['delete-card-modal']}>
      <CardHeader className={styles['card-header']}>
        <Title size={20}>{window.t('removeConnection')}</Title>
        <Button
          onlyIcon
          startIcon={<Icon size={18} color="#404040" fontName="far fa-times" />}
          variant="icon"
          hoverColor="transparent"
          className="ml-auto"
          style={{
            opacity: 1
          }}
          clickHandler={closeHandler}
        />
      </CardHeader>
      <CardContent style={{ padding: 40 }}>
        <div className={styles['card-info']}>
          <Icon size={44} icon="exclamationTriangleRounded" color="#f30000" />
          <Text
            color="#404040"
            size={16}
            className="align-self-center">{`${removeMessage} “${groupName}”?`}</Text>
        </div>
        <Text className="mt-5" color="#404040" size={14} style={{ marginLeft: 64 }}>
          {window.t('showAfterDelete')}
        </Text>
        <div className="d-flex mt-3" style={{ marginLeft: 64 }}>
          {isComment !== null && isComment !== undefined && (
            <SvgCheckbox
              isChecked={Boolean(+isComment)}
              changeHandler={(isChecked) => checkboxHandler('isComment', isChecked)}
              style={{
                color: '#808080',
                fontSize: 14
              }}>
              {window.t('comments')}
            </SvgCheckbox>
          )}
          {isMessage !== null && isMessage !== undefined && (
            <SvgCheckbox
              isChecked={Boolean(+isMessage)}
              changeHandler={(isChecked) => checkboxHandler('isMessage', isChecked)}
              style={{
                color: '#808080',
                fontSize: 14,
                marginLeft: 10
              }}>
              {window.t('messages')}
            </SvgCheckbox>
          )}
        </div>
        <CardActions className="mt-5 d-flex justify-end">
          <Button
            style={{
              padding: '8px 20px',
              marginRight: 10,
              borderColor: 'transparent'
            }}
            clickHandler={closeHandler}>
            Отмена
          </Button>
          <Button style={{ padding: '8px 20px' }} clickHandler={() => deleteRequest(groupData)}>
            Удалить
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
}

export default DeleteCardModal;

DeleteCardModal.propTypes = propTypes;
