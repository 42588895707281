import { memo } from 'react';

import { Button, Icon } from 'components';

import s from './ActionBtnCard.module.css';

export const ActionBtnCard = memo(
  ({
    isMinimized,
    setIsMinimized,
    channel,
    isActive,
    setIsActive,
    edit,
    setEdit,
    deleteHandler
  }) => {
    return (
      <div className={s.btnBlock}>
        <Button
          startIcon={
            <Icon
              size={16}
              viewBox="0 0 18 18"
              icon="minimize"
              style={{ enableBackground: '0 0 18 18', verticalAlign: 'initial' }}
            />
          }
          onlyIcon
          variant="icon"
          clickHandler={() => setIsMinimized(!isMinimized)}
          style={{ padding: '6px 8px' }}
          color={isMinimized ? '#ccc' : 'primary'}
        />
        {channel && (
          <Button
            startIcon={<Icon size={18} fontName="fal fa-share" />}
            onlyIcon
            variant="icon"
            color={isActive ? '#0088EE' : '#313131'}
            clickHandler={() => {
              setIsActive((v) => !v);
            }}
            title={window.t('openChat')}
            style={{
              padding: '6px 8px',
              ...(isActive && { transform: 'scale(-1, 1)' })
            }}
          />
        )}

        {/* <Button
              startIcon={<Icon size={18} fontName="fal fa-cog" />}
              onlyIcon
              variant="icon"
              color="#6f6f6f"
              // clickHandler={el.clickHandler}
              title={window.t('settings')}
              style={{ padding: '6px 8px' }}
            /> */}
        <Button
          startIcon={<Icon size={18} fontName="fal fa-pencil-alt" />}
          onlyIcon
          variant="icon"
          color={edit ? '#0088EE' : '#313131'}
          clickHandler={() => {
            setEdit(!edit);
          }}
          title={window.t('edit')}
          style={{ padding: '6px 8px' }}
        />
        <Button
          startIcon={
            <div className="d-flex align-center">
              <Icon size={20} fontName="fal fa-times" />
            </div>
          }
          onlyIcon
          variant="icon"
          color="#ff0033"
          clickHandler={deleteHandler}
          title={window.t('delete')}
          style={{ padding: '6px 8px' }}
        />
      </div>
    );
  }
);
