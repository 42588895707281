import TreeSelect from 'rc-tree-select';
import { memo, useLayoutEffect, useMemo, useState } from 'react';

import { PictureLoaderContainer } from 'containers/PictureLoaderContainer/PictureLoaderContainer';

import { Select, TagsInput, TextField, Title } from 'components';

import { PICTURE_LOADER_TYPES } from 'store/constants';

import s from './RegistrationEventsView.module.css';

export const RegistrationEventsView = memo(
  ({
    event_title,
    event_description,
    event_place,
    event_date,
    event_image,
    changeSessionInfo,
    pictureChangeHandler,
    deletePicture,
    isLoading,
    changeEventField,
    tags,
    addTags,
    removeTags
  }) => {
    const [category, setCategory] = useState({});

    // категории для select
    const categoriesTags = useMemo(() => {
      return [...new Set(tags.map((el) => el.categoryName))].map((cat) => ({
        title: cat,
        value: cat
      }));
    }, [tags.length]);

    // по умолчанию выбрана первая категория
    useLayoutEffect(() => {
      if (categoriesTags.length !== 0) {
        setCategory(categoriesTags[0]);
      }
    }, [categoriesTags]);

    // теги для select
    const tagsOptions = useMemo(() => {
      return tags
        .filter((tag) => tag.categoryName === category.value)
        .map((el) => {
          return {
            title: el.tagName,
            value: el.id,
            key: el.id
          };
        });
    }, [category]);

    // теги для textarea

    const checkedTags = tags.reduce((acc, el) => {
      if (el.is_checked) {
        acc.push({ value: el.id, title: el.tagName });
      }
      return acc;
    }, []);

    return (
      <>
        <TextField
          value={event_title?.toString()}
          changeHandler={(event) => changeEventField('event_title', event.target.value)}
          blurHandler={(event) => changeSessionInfo('event_title', event.target.value)}
          width="100%"
          inputHeight="30px"
          inputFontSize="14px"
          borderStyle="dotted"
          label={window.t('eventName')}
          style={{ marginBottom: '20px' }}
        />
        <TextField
          value={event_description?.toString()}
          changeHandler={(event) => changeEventField('event_description', event.target.value)}
          blurHandler={(event) => changeSessionInfo('event_description', event.target.value)}
          width="100%"
          inputHeight="30px"
          inputFontSize="14px"
          borderStyle="dotted"
          label={window.t('eventDesc')}
          style={{ marginBottom: '20px' }}
        />
        <TextField
          value={event_place?.toString()}
          changeHandler={(event) => changeEventField('event_place', event.target.value)}
          blurHandler={(event) => changeSessionInfo('event_place', event.target.value)}
          width="100%"
          inputHeight="30px"
          inputFontSize="14px"
          borderStyle="dotted"
          label={window.t('eventPlace')}
          style={{ marginBottom: '20px' }}
        />
        <div>
          <div className={s.dateLabel}>{window.t('eventDate')}</div>
          <TextField
            value={event_date.toString()}
            type="date"
            width="170px"
            changeHandler={(event) => changeEventField('event_date', event.target.value)}
            blurHandler={(event) => changeSessionInfo('event_date', event.target.value)}
            inputFontSize="16px"
            inputHeight="30px"
            borderStyle="dotted"
          />
        </div>

        <div className={s.pictureBlock}>
          <Title color="#808080" size={16}>
            {window.t('bannerImage')}
          </Title>
          <PictureLoaderContainer
            type={PICTURE_LOADER_TYPES.showModal}
            style={{
              width: 192,
              height: 108,
              marginRight: 10,
              backgroundPosition: '50% 50%'
            }}
            changeHandler={pictureChangeHandler}
            deleteHandler={deletePicture}
            picture={event_image}
            isImageUploading={isLoading}
            iconSize={80}
            btnStyle={{ padding: '12px 45px' }}
          />
        </div>

        <div className={s.tagsBlock}>
          <div>
            <Title color="#808080" size={16} className={s.tagsTitle}>
              {window.t('tagsCategory')}
            </Title>
            <Select
              label={window.t('categoryName')}
              options={categoriesTags}
              selectedOption={category}
              setSelectedOption={setCategory}
              isSelectDisabled={categoriesTags.length === 0}
              disabledMessage={window.t('noCategoriesCreateACategory')}
              width={200}
              style={{ borderBottom: '1px solid' }}
              // для отображения правильно title, select требует доработки
              isTagsNoOption={false}
            />
          </div>

          <div>
            <Title color="#808080" size={16} className={s.tagsTitle}>
              {window.t('listOfTags')}
            </Title>
            <Select
              label={window.t('tagsName')}
              options={tagsOptions}
              // selectedOption={{ value: '1', title: 'Категория 1' }}
              setSelectedOption={({ value }) => addTags(value)}
              isSelectDisabled={tagsOptions.length === 0}
              disabledMessage={window.t('selectATagCategory')}
              width={200}
              style={{ borderBottom: '1px solid' }}
              // для отображения правильно title, select требует доработки
              isTagsNoOption={false}
            />
          </div>

          <TagsInput
            id="tagsRegistration"
            className={`${s.tagsInputBlock} flex-grow`}
            // label={window.t('Здесь будут выбранные вами теги')}
            placeholder={window.t('theTagsYouSelectWillBeHere')}
            // add={addRangeNumber}
            remove={(name, id) => removeTags(id)}
            // tagRegExp="^[0-9]+$"
            tags={checkedTags}
            keyField="value"
            textField="title"
            // onRepeat={showWarning}
            disabledTextarea={true}
          />
        </div>
      </>
    );
  }
);
