import { Button, Icon } from 'components';

export const useToolbarRegistrationControls = ({ settingsClickHandler, startStopData }) => {
  const { startStopIcon, startStopColor, startStopClickHandler, startStopTitle } = startStopData;
  // const { registration_json } = useSelector((state) => state.isettings.info);

  const buttons = [
    {
      titleKey: startStopTitle,
      icon: startStopIcon,
      isSelected: false,
      isDisabled: false,
      color: startStopColor,
      clickHandler: startStopClickHandler
    },
    {
      titleKey: 'settings',
      classes: ['tb-button', 'common-data'],
      icon: 'fal fa-cog',
      color: '#6f6f6f',
      isSelected: false,
      isDisabled: false,
      clickHandler: settingsClickHandler
    }
  ];

  const controls = [
    ...buttons.map((el, i) => {
      return (
        <Button
          startIcon={<Icon size={20} fontName={el.icon} />}
          onlyIcon
          variant="icon"
          {...(el.color ? { color: el.color } : {})}
          clickHandler={el.clickHandler}
          title={window.t(el.titleKey)}
          key={`btn${i}`}
          style={{ padding: '6px 8px' }}
        />
      );
    })
    // <Title color="#808080" size={16} key="titleEvent" style={{ marginLeft: '10px' }}>
    //   {registration_json?.event_title || ''}
    // </Title>
    // <Button key="btnPrev" color="success">
    //   Превью
    // </Button>
  ];

  return {
    controls
  };
};
