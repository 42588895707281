import { Profile } from './Profile/Profile';
import { ProfileForm } from './ProfileForm/ProfileForm';
import { ProfileLoader } from './ProfileLoader/ProfileLoader';
import { Fragment } from 'react';

import { useProfiles } from './hooks/useProfiles';

import styles from './ProfileChooser.module.scss';

function ProfileChooser() {
  const {
    profiles,
    hasProfiles,
    isLoading,
    isCreating,
    choose,
    create,
    current,
    remove,
    edit,
    toggleEdit
  } = useProfiles();

  return (
    <section className={styles.profileChooser}>
      <div className={styles.profileChooser__profilesContainer}>
        {!hasProfiles && (
          <p className={styles.profileChooser__noProfiles}>{window.t('noProfiles')}</p>
        )}
        {isLoading && new Array(3).fill(null).map((_, i) => <ProfileLoader key={i} />)}
        {profiles?.map((profile) => (
          <Fragment key={profile.id}>
            {profile.isLoading ? (
              <ProfileLoader />
            ) : profile.isOnEdit ? (
              <ProfileForm
                handleSubmit={edit(profile.id)}
                initialImage={profile.profile_avatar}
                initialName={profile.profile_name}
              />
            ) : (
              <Profile
                profile={profile}
                isCurrent={current?.id === profile?.id}
                handleSelect={choose}
                handleEdit={toggleEdit}
                handleDelete={remove(profile)}
              />
            )}
          </Fragment>
        ))}
        {isCreating && <ProfileLoader />}
        <ProfileForm handleSubmit={create} />
      </div>
    </section>
  );
}

export default ProfileChooser;
