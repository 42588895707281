import { getChannelTypeByName } from './channels';
import { getPhoneNumber } from './getPhoneNumber';
import { isNumber } from './validation';
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js';

export const authorId = (id) => {
  if (!id) return '';
  const f = id.toString().substring(id.length - 4);
  return `${f.substring(0, 2)}-${f.substring(2, 4)}`;
};

export function getExternalLink(author, senderNumber, channel, userName) {
  //* Author
  let authorValue = '';
  let newAuthor = '';
  if (author) {
    author = author.toString();
    if (author.trim() !== '') {
      authorValue = author.trim();
      newAuthor = authorValue;

      const phoneNumber = getPhoneNumber(newAuthor);
      if (phoneNumber) {
        newAuthor = phoneNumber;
      } else {
        newAuthor = authorValue;
      }
    }
  }

  switch (channel) {
    case 'whatsapp':
      if (isNumber(authorValue))
        return {
          href: `https://web.whatsapp.com/send?phone=${authorValue.trim()}&text=&source=&data=`,
          name: newAuthor
        };
      else {
        if (senderNumber && senderNumber.length >= 11) {
          if (senderNumber[0] === '+') {
            senderNumber = senderNumber.substring(1);
            return {
              href: `https://web.whatsapp.com/send?phone=${senderNumber.trim()}&text=&source=&data=`,
              name: newAuthor
            };
          } else
            return {
              href: `https://web.whatsapp.com/send?phone=${senderNumber.trim()}&text=&source=&data=`,
              name: newAuthor
            };
        } else {
          return {
            href: null,
            name: authorValue
          };
        }
      }
    case 'vbr':
    case 'viber':
      return {
        href: `viber://chat?number=${senderNumber}`,
        name: newAuthor
      };
    case 'live':
    case 'livechat':
      return {
        href: `https://direct.lc.chat/${senderNumber}`,
        name: newAuthor
      };
    case 'yt_live':
      return {
        href: `https://www.youtube.com/c/${userName || senderNumber}`,
        name: newAuthor
      };
    case 'ok':
    case 'okgroup':
      return {
        href: `https://ok.ru/messages/${senderNumber}`,
        name: newAuthor
      };
    case 'telegram':
    case 'telegrambot':
      return {
        href: `https://t.me/${userName || senderNumber}`,
        name: newAuthor
      };
    case 'vk':
    case 'vkcomm':
    case 'vkcomments':
    case 'vkgroup':
      return {
        href: `https://vk.com/write${senderNumber}`,
        name: newAuthor
      };
    case 'fb':
    case 'facebook':
    case 'fb-messenger':
      return {
        href: `https://m.me/${senderNumber}`,
        name: newAuthor
      };
    case 'sms':
    case 'smsMoney':
    case 'smsrub':
    case 'smsRub':
      return {
        href: `sms:${senderNumber}`,
        name: newAuthor
      };
    case 'instagram':
    case 'instagramcomments':
    case 'fbinstagram':
      return {
        href: `https://www.instagram.com/${userName || senderNumber}`,
        name: newAuthor
      };
    default:
      return {
        href: null,
        name: newAuthor || senderNumber
      };
  }
}
