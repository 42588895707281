import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const useAutoReply = ({ isSettingsOpened, setIsSettingsOpened }) => {
  const { isInteractiveExist, info } = useSelector((state) => state.isettings);

  const { activity, interactiveName, settings, state, visibility } = info;

  const toggleSettings = useCallback(() => {
    setIsSettingsOpened((prevValue) => !prevValue);
  }, []);

  return {
    interactiveName,
    isInteractiveExist,
    isSettingsOpened,
    toggleSettings,
    visibility,
    activity,
    settings,
    state
  };
};
