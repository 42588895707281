import VolumeProgressBar from './VolumeProgressBar/VolumeProgressBar';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';

import styles from './SubControls.module.css';

function SubControls({
  upload,
  setVolumeMouseDown,
  volumePercent,
  setVolumeMove,
  setVolumeClick,
  url
}) {
  const [isVolumeBarActive, setIsVolumeBarActive] = useState(false);
  const uploadInputRef = useRef(null);
  const [error, setError] = useState(null);
  const [fetching, setFetching] = useState(false);

  const handleChangeFile = useCallback(
    (e) => {
      if (!e.target.files[0]) return;
      upload(e.target.files[0]);
    },
    [upload]
  );

  const download = (url, name) => {
    if (!url) {
      throw new Error('Resource URL not provided! You need to provide one');
    }
    setFetching(true);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobURL;
        a.style = 'display: none';

        if (name) {
          a.download = name;
        } else {
          a.download = url.split('/').slice(-1)[0];
        }

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(blobURL);
        }, 100);
      })
      .catch(() => setError(true))
      .finally(() => {
        setFetching(false);
      });
  };

  const volumeStyle = { display: 'inline-flex', zIndex: 1 };
  if (isVolumeBarActive) volumeStyle.opacity = 1;

  return (
    <div className={styles['sub-controls']}>
      <div className={styles['volume']}>
        <Button
          startIcon={<Icon size={18} fontName="fa fa-volume-up" />}
          onlyIcon
          color={`${isVolumeBarActive ? '#b5b5b5' : '#524e4e'}`}
          variant="message-action"
          style={volumeStyle}
          clickHandler={() => {
            setIsVolumeBarActive(!isVolumeBarActive);
          }}
        />
        <VolumeProgressBar
          isVolumeBarActive={isVolumeBarActive}
          volumePercent={volumePercent}
          setVolumeMove={setVolumeMove}
          setVolumeMouseDown={setVolumeMouseDown}
          setVolumeClick={setVolumeClick}
        />
      </div>
      {/* <Button
        startIcon={<Icon size={18} fontName="fa fa-eject" />}
        onlyIcon
        color="#524e4e"
        variant="message-action"
        style={{ display: 'inline-flex' }}
        clickHandler={() => {
          uploadInputRef.current?.click();
        }}
      /> */}
      {/* class="uploadInput" */}
      <input
        type="file"
        ref={uploadInputRef}
        onChange={handleChangeFile}
        accept=".mp3,.ogg,.wav,.aiff,.ape,.flac"
        style={{ display: 'none' }}
      />
      {/* class="msgId" */}
      <input type="hidden" value="5158370" />
      {/* class="jId" */}
      <input type="hidden" value="0" />
      {/* class="audio" */}
      <input
        type="hidden"
        value="http://media.iactive.pro/ZTvhtW44WDYrU4R3wIOA/voice_messages/whatsapp/gZlDt3TzF5vpm35DKKotXVxkHUPrzKxu.ogg"
      />
      {/* class="type" */}
      <input type="hidden" value="audio_msg" />
      {/* class="interid" */}
      <input type="hidden" value="5158370" />
      <Button
        isDisabled={fetching}
        startIcon={<Icon size={18} fontName="fa fa-download" />}
        tag="button"
        onlyIcon
        color="#524e4e"
        variant="message-action"
        style={{ display: 'inline-flex' }}
        clickHandler={() => download(url)}
      />
    </div>
  );
}

export default SubControls;

SubControls.propTpes = {
  setVolumeMouseDown: PropTypes.func.isRequired,
  volumePercent: PropTypes.number.isRequired,
  setVolumeMove: PropTypes.func.isRequired,
  setVolumeClick: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired
};
