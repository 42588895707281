import classNames from 'classnames';
import React, { useState } from 'react';

import loader from 'assets/images/icons/loader.gif';

import styles from './MediaImg.module.css';

function MediaImg({ image, className, openMedia }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [maxWidth, setMaxWidth] = useState(146);

  return (
    <>
      <div
        className={classNames(styles['media-content'], className)}
        style={{
          maxWidth
        }}>
        {!isLoaded && <img src={loader} className={styles.loader} alt="" />}
        <img
          src={image.url}
          onClick={() => openMedia(image.url)}
          onLoad={(evt) => {
            const maxWidth = (146 * evt.target.naturalWidth) / evt.target.naturalHeight;
            setMaxWidth(maxWidth);
            setIsLoaded(true);
          }}
          alt=""
        />
      </div>
    </>
  );
}

export default MediaImg;
