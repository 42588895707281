import React from 'react';

import { Alert } from 'components';

export const VoteAlertsView = ({ alerts, alertCloseHandler, disableAlerts }) => {
  return (
    Array.isArray(alerts) &&
    alerts.length > 0 &&
    alerts.map(({ message, type }, i) => (
      <Alert
        type={type}
        key={type + i}
        message={message}
        closeHandler={alertCloseHandler}
        disableHandler={disableAlerts}
      />
    ))
  );
};
