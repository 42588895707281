import useActions from 'hooks/useActions';

export const useClearTitreMenu = ({ toggleClearMenu, broadcastId }) => {
  const { clearTitre } = useActions();

  const clearTitrHandler = (titreTag) => {
    clearTitre(titreTag, broadcastId);
    if (!titreTag) toggleClearMenu();
  };

  return {
    clearTitrHandler
  };
};
