import { useProfileForm } from '../hooks/useProfileForm';
import classNames from 'classnames';

import { PictureLoaderContainer } from 'containers';

import { Button, Icon, Input } from 'components';

import { PICTURE_LOADER_TYPES } from 'store/constants';

import styles from './ProfileForm.module.scss';

export function ProfileForm({ handleSubmit, initialName, initialImage }) {
  const { isOpen, imagePreview, submit, toggle, name, setName, setImage } = useProfileForm({
    handleSubmit,
    initialName,
    initialImage
  });

  return (
    <form className={styles.form} onSubmit={submit}>
      {isOpen && (
        <div className={styles.form__newProfile}>
          <PictureLoaderContainer
            type={PICTURE_LOADER_TYPES.showModal}
            style={{
              padding: 0,
              width: '70px',
              height: '70px',
              backgroundPosition: '50% 50%',
              border: '0.1rem solid #ccc',
              borderRadius: '10px'
            }}
            btnStyle={{
              background: 'none'
            }}
            picture={imagePreview}
            changeHandler={(e) => setImage(e.target.files[0])}
            deleteHandler={() => setImage(null)}
          />
          <Input
            type="text"
            value={name}
            changeHandler={(event) => setName(event.target.value)}
            isRequired
          />
        </div>
      )}
      <div className={styles.form__buttonsContainer}>
        <Button
          className={classNames(styles.form__add, {
            [styles.form__add_active]: isOpen
          })}
          startIcon={<Icon size={24} fontName="fas fa-plus" />}
          onlyIcon
          color="#8a8686"
          hoverTextColor="#524e4e"
          hoverColor="none"
          activeColor="none"
          clickHandler={toggle}
          type="button"
        />
        {isOpen && (
          <Button
            className={classNames(styles.form__done)}
            startIcon={<Icon size={24} fontName="fas fa-check" />}
            onlyIcon
            color="green"
            hoverTextColor="green"
            hoverColor="none"
            activeColor="none"
            type="submit"
          />
        )}
      </div>
    </form>
  );
}
