import { forwardRef, useMemo } from 'react';
import { useVirtual } from 'react-virtual';

const VirtualizedSessions = forwardRef(function (
  { rows, renderRow, debounceSetScrollPosition, children },
  ref
) {
  const opt = useMemo(
    () => ({
      estimateSize: (index) => {
        let result = 87;
        if (rows[index].description && rows[index].memo) {
          return result + 28;
        }
        if (rows[index].description || rows[index].memo) {
          return result + 14;
        }
        return result;
      }
    }),
    [rows]
  );

  const { virtualItems, totalSize } = useVirtual({
    size: rows.length,
    parentRef: ref,
    ...opt
  });

  return (
    <div
      onScroll={(e) => {
        debounceSetScrollPosition(e.target.scrollTop);
      }}
      ref={ref}
      style={{
        height: '100%',
        overflowY: 'scroll',
        overflowX: 'hidden'
      }}>
      {/* {previousLoading && <div ref={prevLoadingRef}>{loadingComponent}</div>} */}
      <div
        style={{
          height: `${totalSize}px`,
          width: '100%',
          position: 'relative'
        }}>
        {virtualItems.length !== 0
          ? virtualItems.map((virtualRow) => (
              <div
                key={virtualRow.index}
                ref={virtualRow.measureRef}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  transform: `translateY(${virtualRow.start}px)`
                }}>
                {virtualRow.index === 0 && children}
                {renderRow(rows[virtualRow.index])}
              </div>
            ))
          : children}
      </div>
      {/* {nextLoading && <div ref={nextLoadingRef}>{loadingComponent}</div>} */}
    </div>
  );
});

export default VirtualizedSessions;
