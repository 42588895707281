import PropTypes from 'prop-types';
import React from 'react';

import styles from './Card.module.css';

const propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  shadow: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.node
  ])
};

const defaultProps = {
  style: {}
};

function Card({ className, style, children }) {
  return (
    <div className={`${styles['card']} ${className}`} style={style}>
      {children}
    </div>
  );
}

export default Card;

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;
