import React, { memo } from 'react';

import { Button, Icon, Input, Text } from 'components';

import styles from './ExportButtonSettingsItemView.module.css';

export const ExportButtonSettingsItemView = memo(
  ({ label, value, removeHandler, updateValue, blurHandler }) => {
    const changeHandler = (evt) => {
      const value = evt.target.value;
      updateValue(value);
    };
    return (
      <div className="d-flex mb-4 align-center">
        <Text size={15} className={`${styles['bs__order']} mr-1`}>
          <span>{label}</span> –
        </Text>
        <Input
          type="text"
          value={value}
          placeholder={window.t('enterButtonName')}
          width={200}
          changeHandler={changeHandler}
          blurHandler={blurHandler}
        />
        <Button
          onlyIcon
          startIcon={<Icon fontName="fal fa-times-circle" size={18} />}
          variant="icon"
          color="#CDCDCD"
          className="ml-1"
          style={{
            borderRadius: '50%',
            padding: 0
          }}
          clickHandler={removeHandler}
        />
      </div>
    );
  }
);
