import {
  BLOCK_AUTHOR_SUCCESS,
  FETCH_MORE_MESSAGES_REVERSE,
  FETCH_MORE_MESSAGES_SUCCESS,
  FETCH_USER_INFO_ERROR,
  FETCH_USER_INFO_START,
  FETCH_USER_INFO_SUCCESS,
  FETCH_USER_MEDIA_SUCCESS,
  FETCH_USER_MESSAGES_ERROR,
  FETCH_USER_MESSAGES_START,
  FETCH_USER_MESSAGES_SUCCESS,
  SET_HAS_MORE_MESSAGES,
  SET_IS_BLOCKING_AUTHOR_STATUS,
  SET_PROFILE_INFO,
  UNBLOCK_AUTHOR_SUCCESS
} from './actionTypes';

export function updateProfileInfo(info) {
  return {
    type: SET_PROFILE_INFO,
    info
  };
}

export function fetchUserInfoSuccess(userInfo) {
  return {
    type: FETCH_USER_INFO_SUCCESS,
    userInfo
  };
}
export function fetchUserInfoStart() {
  return {
    type: FETCH_USER_INFO_START
  };
}
export function fetchUserInfoError() {
  return {
    type: FETCH_USER_INFO_ERROR
  };
}

export function fetchMediaSuccess(media) {
  return {
    type: FETCH_USER_MEDIA_SUCCESS,
    media
  };
}

export function fetchUserMessagesSuccess(messages) {
  return {
    type: FETCH_USER_MESSAGES_SUCCESS,
    messages
  };
}

export function fetchUserMessagesStart() {
  return {
    type: FETCH_USER_MESSAGES_START
  };
}

export function fetchUserMessagesError(ex) {
  return {
    type: FETCH_USER_MESSAGES_ERROR,
    ex
  };
}

export function fetchMoreMessagesSuccess(newMessages) {
  return {
    type: FETCH_MORE_MESSAGES_SUCCESS,
    newMessages
  };
}
export function fetchMoreMessagesReverse(newMessages) {
  return {
    type: FETCH_MORE_MESSAGES_REVERSE,
    newMessages
  };
}

export function setHasMoreMessages(hasMoreMessages) {
  return {
    type: SET_HAS_MORE_MESSAGES,
    hasMoreMessages
  };
}

export function blockAuthorSuccess(blockedId) {
  return {
    type: BLOCK_AUTHOR_SUCCESS,
    blockedId
  };
}

export function unblockAuthorSuccess() {
  return {
    type: UNBLOCK_AUTHOR_SUCCESS
  };
}
export function setIsBlockingAuthorStatus(isBlocking) {
  return {
    type: SET_IS_BLOCKING_AUTHOR_STATUS,
    isBlocking
  };
}
