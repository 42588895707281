import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { InteractiveSettingsContainer } from 'containers';

import { Select } from 'components/Select/Select';

import { INTERACTIVES } from 'store/constants';

const propTypes = {
  toggleSettings: PropTypes.func
};

export const ContentSettingsView = memo(
  ({
    types,
    slots,
    changeType,
    changeSlot,
    selectedType,
    selectedSlot,
    publishTime,
    activeBlocksLength,
    toggleSettings,
    contentId,
    startGuide,
    interactiveInfoRef,
    selectControlsRef,
    okButtonRef,
    setStepIndex
  }) => {
    const isTypeSelectDisabled = activeBlocksLength > 0;

    return (
      <InteractiveSettingsContainer
        type={INTERACTIVES.content}
        interactiveId={contentId}
        onCloseHandler={toggleSettings}
        startGuide={startGuide}
        interactiveInfoRef={interactiveInfoRef}
        okButtonRef={okButtonRef}
        setStepIndex={setStepIndex}>
        <div className="d-flex mb-5" ref={selectControlsRef}>
          <Select
            label={window.t('selectContentType')}
            options={types}
            selectedOption={selectedType}
            setSelectedOption={changeType}
            isSelectDisabled={isTypeSelectDisabled}
            disabledMessage={window.t('contentClearBlocksToChangeType')}
            width={220}
          />
          <Select
            label={window.t('selectSlot')}
            options={slots}
            selectedOption={selectedSlot}
            setSelectedOption={changeSlot}
            width={220}
            className="ml-3"
            isSelectDisabled={Boolean(publishTime)}
            disabledMessage={window.t('cantChangeSlot')}
          />
        </div>
      </InteractiveSettingsContainer>
    );
  }
);

ContentSettingsView.propTypes = propTypes;
