import React from 'react';

import Skeleton from 'components/Skeleton/Skeleton';

function MessageSkeleton() {
  return (
    <div
      className="d-flex w-full mt-3 pa-3"
      style={{
        background: '#fff',
        borderRadius: '2px'
        // boxShadow: '0px 2px 5px rgb(0 0 0 / 15%)'
      }}>
      <div className="d-flex flex-column align-center">
        <Skeleton width={46} height={46} borderRadius="50%" />
        <Skeleton className="mt-1" width={40} height={10} borderRadius={5} />
      </div>
      <div className="flex-grow ml-3">
        <div className="d-flex align-center">
          <Skeleton width={144} height={17} borderRadius={8} />
          <Skeleton className="ml-3" width={100} height={13} borderRadius={6} />
          <Skeleton className="ml-auto" width={77} height={13} borderRadius={6} />
        </div>
        <div className="mt-2">
          <Skeleton className="mt-1" width="100%" height={14} borderRadius={5} />
          {/* <Skeleton className="mt-1" width="100%" height={14} borderRadius={5} /> */}
          <Skeleton className="mt-1" width="70%" height={14} borderRadius={5} />
        </div>
      </div>
    </div>
  );
}

export default MessageSkeleton;
