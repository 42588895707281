import PropTypes from 'prop-types';
import React from 'react';

import { List, ListItem, SvgCheckbox } from 'components';

const propTypes = {
  voteId: PropTypes.string
};

export function SocialVotesCollapseBodyView({ socialName, groups, bindPoll, bindedPollId }) {
  return (
    <div style={{ padding: '10px 0' }}>
      <List noStyle>
        {Array.isArray(groups) &&
          groups.map(({ postId, question, pollVars }) => (
            <ListItem key={postId} noHover className="ml-0 mr-0 mt-2 mb-0 pa-0">
              <SvgCheckbox
                isChecked={postId === bindedPollId}
                changeHandler={(isChecked) => bindPoll(postId, socialName, isChecked)}
                cbStyle={{
                  order: -1,
                  marginRight: 10
                }}
                style={{
                  color: '#808080',
                  fontSize: 14,
                  marginRight: 10
                }}>
                {question}
              </SvgCheckbox>
              {/* <Tooltip content={<ul>{
                pollVars.map(el => (<li>{el.name}</li>))
              }</ul>} direction="right">
                <Icon size={14} fontName="fal fa-info-circle" />
              </Tooltip> */}
            </ListItem>
          ))}
      </List>
    </div>
  );
}

SocialVotesCollapseBodyView.propTypes = propTypes;
