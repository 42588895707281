import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  blocks: []
};

export const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    setContentBlocks: (state, action) => {
      const { blocks } = action.payload;
      return {
        ...state,
        blocks
      };
    }
  }
});

export const contentActions = contentSlice.actions;
export const contentReducer = contentSlice.reducer;
