import { getDateNow } from './dateTime';

import { INTERACTIVES, INTERACTIVE_ACTIVITIES } from 'store/constants';

export function isBroadcastActive(sessions, id) {
  const broadcast = sessions.find((el) => el.id === id);
  return broadcast.activity === INTERACTIVE_ACTIVITIES.active;
}

export function hasActiveBroadcastId(sessions, ids) {
  for (const id of ids) {
    if (isBroadcastActive(sessions, id)) return true;
  }

  return false;
}

export function getSessionData(type, response) {
  return type === 'broadcast' ||
    type === 'vote' ||
    type === 'quiz' ||
    type === INTERACTIVES.autoReply ||
    type === INTERACTIVES.content ||
    type === 'registration'
    ? getNewApiSession(type, response)
    : type === 'pulse'
    ? getOldApiSession(type, response)
    : {};
}

function getOldApiSession(type, response) {
  const id = response.data;
  const session = getMdlData(type, id);
  return session;
}

function getNewApiSession(type, response) {
  const session = response.body;
  session.type = type;
  session.title = session.name;
  session.description = '';
  session.memo = '';
  if (type === 'broadcast') session.message_count = 0;
  else if (type === 'vote') session.votes_count = 0;

  return session;
}

function getMdlData(type, id) {
  return type === 'pulse'
    ? {
        id,
        type: 'pulse',
        activity: 'active',
        description: '',
        finish: '',
        memo: '',
        msgcount: '',
        relmsgs: [],
        start: '',
        tag: '',
        title: `Пульс эфира №${id}`,
        creation: getDateNow(),
        chartStartOption: 'now',
        chartStart: new Date(),
        chartDuration: 60,
        data: []
      }
    : type === 'quiz'
    ? {
        id,
        type: 'quiz',
        activity: 'created',
        description: '',
        finish: '',
        memo: '',
        msgcount: '',
        relmsgs: [],
        start: '',
        tag: '',
        title: `Викторина №${id}`,
        backgroundColor: '#ffffff',
        backgroudImage: '',
        presentation: '',
        creation: getDateNow(),
        quizData: {}
      }
    : {};
}

export const getConfirmText = (sessions, selectedSessions, selectedCount) => {
  let confirmText = 'Удаление Интерактивов.\n\rВы действительно хотите удалить ';
  if (selectedCount === 1) {
    const session = sessions.find((el) => `${el.type}_${el.id}` === selectedSessions[0]);
    confirmText += `'${session.title}'?`;
  } else {
    confirmText += `интерактивы (${selectedCount} шт.)?`;
  }

  return confirmText;
};

export const filterByInteractives = (sessions, interactives) => {
  const activeInteractives = interactives
    .map((el) => (el.isActive ? el.type : null))
    .filter((el) => el !== null);
  return activeInteractives.length === 0 || activeInteractives.length === interactives.length
    ? sessions
    : sessions.filter((el) => activeInteractives.includes(el.type));
};

export const filterByText = (filteredSessions, searchText) => {
  const regPhrase = new RegExp(searchText, 'i');
  return searchText.length === 0
    ? filteredSessions
    : filteredSessions.filter(
        (el) =>
          regPhrase.test(el.title) || regPhrase.test(el.description) || regPhrase.test(el.memo)
      );
};

export const selectedOptionByTitle = (options, title) => {
  const selectedOption = {};
  options.forEach((item) => {
    if (item.title === title) {
      selectedOption.value = item.value;
      selectedOption.title = item.title;
    }
  });
  return selectedOption;
};

export const selectedOptionByValue = (options, value) => {
  const selectedOption = {};
  options.forEach((item) => {
    if (item.value === value) {
      selectedOption.value = item.value;
      selectedOption.title = item.title;
    }
  });
  return selectedOption;
};
