import MultipleMenu from './MultipleMenu/MultipleMenu';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button } from 'components';

import useActions from 'hooks/useActions';

import styles from './FeedBroadcastAction.module.css';

const propTypes = {
  id: PropTypes.string,
  messageData: PropTypes.object,
  activeBroadcastData: PropTypes.object
};

function BroadcastActions({ messageData }) {
  const { exportFromFeed, removeExportedFromFeed } = useActions();
  const { broadcastConfig } = useSelector((state) => state.settings);
  const activeBroadcastData = useSelector((state) => state.broadcast.activeBroadcastData);
  const { id } = messageData;
  const { isExportAvailable } = broadcastConfig;

  const [activeButtons, setActiveButtons] = useState([]);
  const [availableButtons, setAvailableButtons] = useState({});

  useEffect(() => {
    if (!activeBroadcastData || typeof activeBroadcastData !== 'object') {
      setActiveButtons([]);
      setAvailableButtons({});
      return;
    }

    const { settings, messagesInTitr } = activeBroadcastData;
    const activeButtons = messagesInTitr.filter((el) => el.id === id);

    setActiveButtons(activeButtons);
    setAvailableButtons(settings || {});
  }, [id, activeBroadcastData]);

  const clickHandler = (id, key, broadcastMessageId) => {
    if (activeButtons.map((el) => el.titr_tag).includes(key)) {
      removeExportedFromFeed(id, key, broadcastMessageId);
    } else {
      exportFromFeed(messageData, key, broadcastMessageId);
    }
  };

  return (
    <div className={`${styles['action-export']} d-inline-flex`}>
      {isExportAvailable && (
        <>
          {Object.entries(availableButtons).map(([key, value], i, array) => {
            const currentKeyButtons = activeButtons.filter((el) => el.titr_tag === key);
            const count = currentKeyButtons.length;

            return count > 1 ? (
              <MultipleMenu title={value || key} activeButtons={currentKeyButtons} />
            ) : (
              <Button
                key={key}
                isActive={activeButtons.map((el) => el.titr_tag).includes(key)}
                clickHandler={() => clickHandler(id, key, currentKeyButtons[0]?.broadcastMessageId)}
                style={{
                  fontSize: 12
                }}
                className={classNames(styles.button, {
                  [styles.button_small]: array.length > 5
                })}>
                {value || key}
              </Button>
            );
          })}
        </>
      )}
    </div>
  );
}

export default BroadcastActions;

BroadcastActions.propTypes = propTypes;
