import {
  ADD_MESSAGE_TO_PINNED_AUTHOR,
  ADD_PROFILE,
  EDIT_CURRENT_PROFILE,
  EDIT_PROFILE,
  REMOVE_PROFILE,
  SET_ARE_PROFILES_LOADING,
  SET_CURRENT_PROFILE,
  SET_CURRENT_PROFILE_CONFIG,
  SET_IS_NEW_PROFILE_CREATING,
  SET_PINNED_MESSAGES_AS_READ,
  SET_PROFILES,
  TOGGLE_PROFILE_EDIT
} from './actionTypes';

export function setCurrentProfile(profile) {
  return {
    type: SET_CURRENT_PROFILE,
    payload: profile
  };
}

export function setCurrentProfileConfig(profileConfig) {
  return {
    type: SET_CURRENT_PROFILE_CONFIG,
    payload: profileConfig
  };
}

export function setIsNewProfileCreating(flag) {
  return {
    type: SET_IS_NEW_PROFILE_CREATING,
    payload: flag
  };
}

export function toggleProfileEdit(id) {
  return {
    type: TOGGLE_PROFILE_EDIT,
    payload: id
  };
}

export function setAreProfilesLoading(flag) {
  return {
    type: SET_ARE_PROFILES_LOADING,
    payload: flag
  };
}

export function addProfile(profile) {
  return {
    type: ADD_PROFILE,
    payload: profile
  };
}

export function editProfile(payload) {
  return {
    type: EDIT_PROFILE,
    payload
  };
}

export function editCurrentProfile(payload) {
  return {
    type: EDIT_CURRENT_PROFILE,
    payload
  };
}

export function removeProfile(payload) {
  return {
    type: REMOVE_PROFILE,
    payload
  };
}

export function setProfiles(payload) {
  return {
    type: SET_PROFILES,
    payload
  };
}
