import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { Button, Card, Icon, Text } from 'components';

const propTypes = {
  exportButtons: PropTypes.object,
  clearTitrHandler: PropTypes.func
};

export const ClearTitreMenuView = memo(({ exportButtons, clearTitrHandler }) => {
  return (
    <Card
      style={{
        padding: '5px 10px 10px',
        minWidth: 120
      }}>
      {exportButtons &&
        typeof exportButtons === 'object' &&
        Object.entries(exportButtons).map(([key, value]) => (
          <div key={key} className="d-flex align-center">
            <Text>{value || key}</Text>
            <Button
              startIcon={<Icon size={20} fontName="fal fa-times" />}
              onlyIcon
              variant="icon"
              color="#ff0033"
              clickHandler={() => {
                clearTitrHandler(key);
              }}
              style={{
                padding: '6px 8px',
                marginLeft: 'auto'
              }}
            />
          </div>
        ))}
      <Button
        style={{
          margin: '10px auto 0'
        }}
        variant="text"
        clickHandler={() => {
          clearTitrHandler(null);
        }}>
        {window.t('clearAll')}
      </Button>
    </Card>
  );
});

ClearTitreMenuView.propTypes = propTypes;
