import { StatesSettingsItemView } from './StatesSettingsItemView';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { useStateItem, useStateSelectMenu } from './hooks';

const propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  removeHandler: PropTypes.func,
  updateValue: PropTypes.func,
  updateDuration: PropTypes.func,
  updateType: PropTypes.func,
  blurHandler: PropTypes.func,
  duration: PropTypes.string
};

export const StatesSettingsItemContainer = memo(
  ({
    label,
    value,
    type,
    removeHandler,
    updateValue,
    updateDuration,
    updateType,
    blurHandler,
    duration
  }) => {
    const {
      localeState,
      startMenuRect,
      startBtn,
      startActivatorHandler,
      startMenuActivity,
      setStartMenuActivity
    } = useStateItem({ type });

    const { selectList } = useStateSelectMenu({
      updateType,
      setStartMenuActivity
    });

    return (
      <StatesSettingsItemView
        label={label}
        value={value}
        removeHandler={removeHandler}
        updateValue={updateValue}
        updateDuration={updateDuration}
        blurHandler={blurHandler}
        duration={duration}
        localeState={localeState}
        startMenuRect={startMenuRect}
        startBtn={startBtn}
        selectList={selectList}
        startActivatorHandler={startActivatorHandler}
        startMenuActivity={startMenuActivity}
      />
    );
  }
);

StatesSettingsItemContainer.propTypes = propTypes;
