import { debounce } from 'lodash';
import throttle from 'lodash/throttle';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import useActions from 'hooks/useActions';
import useFetch from 'hooks/useFetch';

export const useVoteVariants = ({ voteInfo }) => {
  const {
    addNewVariant,
    deleteVoteImage,
    updateVariant,
    changeVoteVariant,
    deleteVoteVariant,
    updateVariantField,
    addVote
  } = useActions();

  const [{ response, isLoading, error }, doFetch] = useFetch('api_upload_vote_image');

  const { isVoteActive, variants, voteCount } = useSelector((state) => state.vote);

  const [loadingImageVarnum, setLoadingImageVarnum] = useState(null);

  const { id, result } = voteInfo;
  const pictureChangeHandler = useCallback(
    (evt, varnum = null) => {
      const file = evt.target.files[0];
      if (!file) return;

      file.isBinary = true;

      doFetch({
        method: 'POST',
        postBody: {
          varImg: file,
          id,
          varnum
        }
      });
      setLoadingImageVarnum(varnum);
    },
    [id, doFetch]
  );

  useEffect(() => {
    if (!response) return;
    const { varnum, url } = response;
    setLoadingImageVarnum(null);
    updateVariantField({ varnum, field: 'var_img_url', value: url });
  }, [response, updateVariantField]);

  const addNewVariantWithThrottle = useMemo(() => debounce(addNewVariant, 500), [addNewVariant]);

  return {
    voteId: id,
    variants,
    result,
    isVoteActive,
    updateVariant,
    changeVoteVariant,
    deleteVoteVariant,
    updateVariantField,
    addVote,
    pictureChangeHandler,
    deleteVoteImage,
    isLoadingVariant: isLoading,
    loadingImageVarnum,
    voteCount,
    addNewVariant: addNewVariantWithThrottle
  };
};
