import AudioPlayer from '../AudioPlayer/AudioPlayer';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Avatar, BlurWrapper, Button, Icon } from 'components';

import useActions from 'hooks/useActions';

import { getUIDateFromDBDate } from 'helpers/dateTime';

import styles from './ModalGallery.module.css';

function ModalGallery() {
  const { closeModalImage, setModalCurrentImage } = useActions();
  const { userInfo, imageList, currentImage, isOpened } = useSelector((state) => state.modalImage);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = useCallback(
    (event) => {
      if (event) event.stopPropagation();

      const currentIndex = currentImageIndex;
      const totalCount = imageList.length;
      const nextIndex = currentIndex + 1 >= totalCount ? 0 : currentIndex + 1;

      setCurrentImageIndex(nextIndex);

      const nextImage = imageList[nextIndex].src;
      setModalCurrentImage(nextImage);
    },
    [currentImageIndex, imageList, setModalCurrentImage]
  );

  const prevImage = useCallback(
    (event) => {
      if (event) event.stopPropagation();

      const currentIndex = currentImageIndex;
      const totalCount = imageList.length;
      const nextIndex = currentIndex - 1 < 0 ? totalCount - 1 : currentIndex - 1;

      setCurrentImageIndex(nextIndex);

      const nextImage = imageList[nextIndex].src;
      setModalCurrentImage(nextImage);
    },
    [currentImageIndex, imageList, setModalCurrentImage]
  );

  useEffect(() => {
    if (!Array.isArray(imageList)) return;

    const keydownHandler = (event) => {
      if (event.key === 'ArrowLeft') prevImage();
      else if (event.key === 'ArrowRight') nextImage();
    };

    const index = imageList.findIndex((el) => el.src === currentImage);
    setCurrentImageIndex(index);
    document.addEventListener('keydown', keydownHandler);
    return () => {
      document.removeEventListener('keydown', keydownHandler);
    };
  }, [currentImage, imageList, nextImage, prevImage]);

  const closeModalBody = () => {
    if (getCurrentItem().type?.includes('image')) closeModalImage();
  };

  const getCurrentItem = () => {
    return imageList.find((el) => el.src === currentImage) || null;
  };

  const isManyImages = Array.isArray(imageList) && imageList.length > 1;

  return (
    getCurrentItem() && (
      <BlurWrapper isOpened={isOpened} clickHandler={closeModalImage}>
        <div className={styles.wrapper} onClick={closeModalBody}>
          <div className={styles.header}>
            {userInfo && (
              <div className={styles.info}>
                <Avatar src={userInfo.avatar} />
                <div>
                  <a className={styles['user-name']} href="#">
                    {userInfo.name}
                  </a>
                  <p className={styles['publish-date']}>
                    {getUIDateFromDBDate(getCurrentItem().publishDate)}
                  </p>
                </div>
              </div>
            )}
            <Button
              startIcon={<Icon size={40} fontName="fal fa-times" />}
              onlyIcon
              className={styles.btn}
              variant="message-action"
              clickHandler={closeModalImage}
            />
          </div>
          <div className={styles['content-wrapper']}>
            {isManyImages && (
              <Button
                startIcon={<Icon size={64} fontName="far fa-angle-left" />}
                onlyIcon
                className={styles.btn}
                variant="message-action"
                clickHandler={prevImage}
              />
            )}

            <div className={styles.content}>
              {getCurrentItem().type?.includes('image') && (
                <img src={getCurrentItem().src} alt={userInfo?.name} />
              )}
              {getCurrentItem().type?.includes('video') && (
                <VideoPlayer
                  isExpanded
                  compressHandler={closeModalImage}
                  src={getCurrentItem().src}
                />
              )}
              {(getCurrentItem().type?.includes('audio_msg') ||
                getCurrentItem().type?.includes('audio')) && (
                <div>
                  <AudioPlayer
                    style={{ width: '100%' }}
                    src={getCurrentItem().src}
                    messageId={getCurrentItem().messageId}
                  />
                </div>
              )}
            </div>

            {isManyImages && (
              <Button
                startIcon={<Icon size={64} fontName="far fa-angle-right" />}
                onlyIcon
                className={styles.btn}
                variant="message-action"
                clickHandler={nextImage}
              />
            )}
          </div>

          {isManyImages && (
            <div className={styles['modal-window-image__desc']}>
              {`${currentImageIndex + 1} ${window.t('from')?.toLowerCase()} ${imageList.length}`}
            </div>
          )}
        </div>
      </BlurWrapper>
    )
  );
}

export default ModalGallery;
