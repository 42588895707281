import {
  ADD_OWN_MESSAGE,
  BLOCK_AUTHOR_SUCCESS,
  FETCH_MORE_MESSAGES_REVERSE,
  FETCH_MORE_MESSAGES_SUCCESS,
  FETCH_USER_INFO_ERROR,
  FETCH_USER_INFO_START,
  FETCH_USER_INFO_SUCCESS,
  FETCH_USER_MEDIA_SUCCESS,
  FETCH_USER_MESSAGES_ERROR,
  FETCH_USER_MESSAGES_START,
  FETCH_USER_MESSAGES_SUCCESS,
  MAKE_CHAT_MESSAGE_SENDED,
  SET_FEED_ITEM_FAV_STATUS,
  SET_HAS_MORE_MESSAGES,
  SET_IS_BLOCKING_AUTHOR_STATUS,
  SET_MSG_FAV_STATUS,
  SET_PROFILE_INFO,
  UNBLOCK_AUTHOR_SUCCESS,
  UPDATE_FEED_ITEM_AUDIO,
  UPDATE_MESSAGE_AUDIO
} from '../actions/actionTypes';

import { TABS } from 'containers/Feed/constants';

const initialState = {
  isActivityLoading: false,
  blockedId: null,
  hasMoreMessages: true,
  isMessagesLoading: false,
  profileInfo: {},
  userInfo: {},
  media: [],
  messages: []
};

export default function profileCardReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROFILE_INFO:
      return {
        ...state,
        profileInfo: action.info
      };
    case FETCH_USER_INFO_START:
      return {
        ...state,
        isActivityLoading: true
      };
    case FETCH_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: action.userInfo,
        isActivityLoading: false
      };
    case FETCH_USER_INFO_ERROR:
      return {
        ...state,
        isActivityLoading: false
      };
    case FETCH_USER_MEDIA_SUCCESS:
      return {
        ...state,
        media: action.media
      };
    case FETCH_USER_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.messages,
        isMessagesLoading: false
      };
    case FETCH_USER_MESSAGES_START:
      return {
        ...state,
        isMessagesLoading: true
      };
    case FETCH_USER_MESSAGES_ERROR:
      return {
        ...state,
        isMessagesLoading: false
      };
    case FETCH_MORE_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: [...state.messages, ...action.newMessages],
        isMessagesLoading: false
      };
    case FETCH_MORE_MESSAGES_REVERSE:
      return {
        ...state,
        messages: [...action.newMessages, ...state.messages],
        isMessagesLoading: false
      };
    case ADD_OWN_MESSAGE:
      return {
        ...state,
        messages: [action.message, ...state.messages]
      };
    case MAKE_CHAT_MESSAGE_SENDED:
      return {
        ...state,
        messages: state.messages.map((el) =>
          el.id === action.msgId
            ? {
                ...el,
                unsend: false
              }
            : el
        )
      };
    case SET_HAS_MORE_MESSAGES:
      return {
        ...state,
        hasMoreMessages: action.hasMoreMessages
      };
    case BLOCK_AUTHOR_SUCCESS:
      return {
        ...state,
        blockedId: action.blockedId
      };
    case UNBLOCK_AUTHOR_SUCCESS:
      return {
        ...state,
        blockedId: null
      };
    case SET_IS_BLOCKING_AUTHOR_STATUS:
      return {
        ...state,
        isBlocking: action.isBlocking
      };
    case SET_FEED_ITEM_FAV_STATUS:
      if (action.tab !== TABS.messages) return state;
      return {
        ...state,
        messages: state.messages.map((message) =>
          message.id === action.id ? { ...message, favorite: action.value } : message
        )
      };
    case UPDATE_FEED_ITEM_AUDIO:
      return {
        ...state,
        messages: state.messages.map((message) =>
          message.id !== action.id
            ? message
            : {
                ...message,
                attachments: message.attachments.map((media) =>
                  media.ogg !== action.prevSrc ? media : { ...media, ogg: action.newSrc }
                )
              }
        )
      };
    default:
      return state;
  }
}
