import { VoteWinnersView } from './VoteWinnersView';
import React from 'react';

import { useVoteWinners } from './hooks/useVoteWinners';

export function VoteWinnersContainer() {
  const { rangeNumbers, addRangeNumber, deleteRangeNumber, showWarning } = useVoteWinners();

  return (
    <VoteWinnersView
      rangeNumbers={rangeNumbers}
      addRangeNumber={addRangeNumber}
      deleteRangeNumber={deleteRangeNumber}
      showWarning={showWarning}
    />
  );
}
