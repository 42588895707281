import cn from 'classnames';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { TABS } from 'containers/Feed/constants';

import { Icon } from 'components';

import { getIconImage } from 'helpers/image';

import styles from './Statistics.module.css';

function Statistics({
  className,
  messages,
  isAuthors,
  isMessages,
  color,
  date,
  authorKey = 'senderNumber',
  dateKey = 'date',
  ...props
}) {
  const currentTab = useSelector((state) => state.feed.currentTab);

  const messagesCount = useMemo(
    () =>
      !isMessages
        ? 0
        : messages.filter((msg) => {
            let curr = new Date(msg[dateKey]);
            curr = [curr.getFullYear(), curr.getMonth(), curr.getDate()];
            return curr.every((_, i) => date[i] === curr[i]);
          }).length,
    [isMessages, date, messages, dateKey]
  );

  const authorsCount = useMemo(
    () =>
      !isAuthors
        ? 0
        : Object.keys(
            messages
              .filter((msg) => {
                let curr = new Date(msg[dateKey]);
                curr = [curr.getFullYear(), curr.getMonth(), curr.getDate()];
                return curr.every((_, i) => date[i] === curr[i]);
              })
              .reduce((acc, item) => ((acc[item[authorKey]] = item[authorKey]), acc), {})
          ).length,
    [isAuthors, date, messages, dateKey, authorKey]
  );

  return (
    <div className={cn(className, styles.statistics)} style={{ color }} {...props}>
      {isAuthors && (
        <div className={styles.statistics__group}>
          {currentTab === TABS.news && (
            <img className={styles.icon} src={getIconImage('rss_icon.png')} alt="arrow" />
          )}
          {currentTab === TABS.messages && (
            <Icon size={12} fontName="fad fa-solid fa-user" color={color} />
          )}{' '}
          <p>{authorsCount}</p>
        </div>
      )}
      {isMessages && (
        <div className={styles.statistics__group}>
          {currentTab === TABS.news && (
            <img className={styles.icon} src={getIconImage('module-content.png')} alt="arrow" />
          )}
          {currentTab === TABS.messages && (
            <Icon size={12} fontName="fad fa-solid fa-envelope" color={color} />
          )}{' '}
          <p>{messagesCount}</p>
        </div>
      )}
    </div>
  );
}

export default memo(Statistics);
