import { setSettings } from './settings';
import { addSnack } from './snackBox';

import {
  addProfile,
  editCurrentProfile,
  editProfile,
  removeProfile,
  setAreProfilesLoading,
  setCurrentProfile,
  setIsNewProfileCreating,
  setProfiles
} from 'store/actions/profile';
import { initialSettingsState } from 'store/reducers/settings';

import {
  createProfile,
  deleteProfile as deleteProfileApi,
  editProfile as editProfileApi,
  getProfiles
} from 'api/dataLayer';

export function chooseCurrentProfile(profile) {
  return async (dispatch, getState) => {
    const { currentProfile, profiles } = getState().profile;
    if (profile) {
      if (profile.id === currentProfile?.id) return;
      localStorage.setItem(`${window.location.hostname}-profileId`, JSON.stringify(profile.id));
      dispatch(setCurrentProfile(profile));
      dispatch(setSettings(profile.settings));
    } else {
      const newCurrentProfile = profiles[0];
      if (!newCurrentProfile) {
        localStorage.setItem(`${window.location.hostname}-profileId`, JSON.stringify(null));
        dispatch(setCurrentProfile(null));
      } else {
        localStorage.setItem(
          `${window.location.hostname}-profileId`,
          JSON.stringify(newCurrentProfile.id)
        );
        dispatch(setCurrentProfile(newCurrentProfile));
        dispatch(setSettings(newCurrentProfile.settings));
      }
    }
  };
}

export function addNewProfile({ name, avatar }, defaultProfile) {
  return async (dispatch, getState) => {
    dispatch(setIsNewProfileCreating(true));
    let settings = getState().settings;
    try {
      const result = await createProfile({
        profileName: name,
        profileAvatar: avatar,
        defaultSettings: initialSettingsState
      });
      const newProfile = {
        id: result.body,
        profile_avatar: avatar ? URL.createObjectURL(avatar) : '',
        profile_name: name,
        settings: initialSettingsState
      };
      dispatch(addProfile(newProfile));
      if (!settings.currentProfile) {
        dispatch(chooseCurrentProfile(newProfile));
      }
    } catch (error) {
      console.error(error);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errCreatingProfile')
        })
      );
    } finally {
      dispatch(setIsNewProfileCreating(false));
    }
  };
}

export function updateProfile(payload) {
  return async (dispatch, getState) => {
    const { profiles, currentProfile } = getState().profile;
    const target = profiles.find((profile) => profile.id === payload.id);
    try {
      dispatch(
        editProfile({
          ...payload,
          isLoading: true
        })
      );
      if (target.profile_avatar !== payload.profile_avatar) {
        await editProfileApi({
          profileId: target.id,
          parametr: 'profile_avatar',
          ...(payload.profile_avatar ? { file: payload.profile_avatar } : { value: '' })
        });
      }
      if (target.profile_name !== payload.profile_name) {
        await editProfileApi({
          profileId: target.id,
          parametr: 'profile_name',
          value: payload.profile_name
        });
      }
    } catch (error) {
      console.error(error);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errUpdatingProfile')
        })
      );
    } finally {
      dispatch(
        editProfile({
          ...payload,
          isLoading: false,
          ...(payload.profile_avatar instanceof File && {
            profile_avatar: URL.createObjectURL(payload.profile_avatar)
          })
        })
      );
      if (target.id === currentProfile) {
        dispatch(
          editCurrentProfile({
            ...payload,
            ...(payload.profile_avatar instanceof File && {
              profile_avatar: URL.createObjectURL(payload.profile_avatar)
            })
          })
        );
      }
    }
  };
}

export function deleteProfile(profileId) {
  return async (dispatch) => {
    try {
      await deleteProfileApi({ profileId });
      dispatch(removeProfile(profileId));
      dispatch(chooseCurrentProfile());
    } catch (error) {
      console.error(error);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errRemovingProfile')
        })
      );
    }
  };
}

export function updateCurrentProfileSettings() {
  return async (dispatch, getState) => {
    const { currentProfile } = getState().profile;
    if (currentProfile) {
      const { settings } = getState();
      dispatch(
        editProfile({
          id: currentProfile.id,
          settings
        })
      );
      dispatch(
        editCurrentProfile({
          id: currentProfile.id,
          settings
        })
      );
    }
  };
}

export function fetchProfiles() {
  return async (dispatch) => {
    dispatch(setAreProfilesLoading(true));
    try {
      let { body: profiles } = await getProfiles();
      profiles = profiles?.map((profile) => ({
        ...profile,
        settings: {
          ...JSON.parse(profile.settings),
          authorsUpdatedFromSocket: true
        }
      }));
      dispatch(setProfiles(profiles || []));
      const currentProfileId = JSON.parse(
        localStorage.getItem(`${window.location.hostname}-profileId`)
      );

      if (profiles.length) {
        const targetProfile = profiles.find((profile) => profile.id == currentProfileId);
        dispatch(chooseCurrentProfile(targetProfile || profiles[0]));
      } else {
        dispatch(addNewProfile({ name: 'Default Profile' }, true));
      }
    } catch (error) {
      console.error(error);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errLoadingProfiles')
        })
      );
    } finally {
      dispatch(setAreProfilesLoading(false));
    }
  };
}

export function updateProfileSettings(config) {
  return async (dispatch, getState) => {
    const { currentProfile } = getState().profile;
    if (!currentProfile) return;
    try {
      await editProfileApi({
        profileId: currentProfile.id,
        parametr: 'settings',
        value: config
      });
    } catch (error) {
      console.error(error);
    }
  };
}
