import classNames from 'classnames';
import React, { memo } from 'react';

import { INTERACTIVES } from 'store/constants';

import styles from './totalVoteCountView.module.css';

export const TotalVoteCountView = memo(({ total, type, reverse }) => {
  return (
    <div
      className={classNames(styles['count-all'], {
        [styles['count-all--reverse']]: reverse
      })}>
      <span className={styles['count-all-title']}>
        {type === INTERACTIVES.quiz ? window.t('totalAnswers') : window.t('totalVotes')}:{' '}
      </span>
      {total || 0}
    </div>
  );
});
