import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { List, ListItem, ListItemText, Menu } from 'components';

const propTypes = {
  settingsMenu: PropTypes.element
};

export function AddNewBlock({ locale, activator, listItems, select }) {
  const [isActive, setIsActive] = useState(false);
  const [btnRect, setBtnRect] = useState(null);
  const clickHandler = (e) => {
    if (!listItems) {
      select?.();
      return;
    }

    if (e) {
      const rect = e.currentTarget.getBoundingClientRect();
      setBtnRect(rect);
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
    setIsActive(!isActive);
  };

  return (
    <Menu
      activator={<div onClick={clickHandler}>{activator}</div>}
      isActive={isActive}
      activatorHandler={setIsActive}
      left={btnRect ? btnRect.left : 0}
      top={btnRect ? btnRect.bottom : 0}>
      <List>
        {listItems?.map((el) => (
          <ListItem
            key={el.value}
            clickHandler={() => {
              select?.(el);
              setIsActive(false);
            }}>
            <ListItemText primary={el.label[locale]} withTitle />
          </ListItem>
        ))}
      </List>
    </Menu>
  );
}

AddNewBlock.propTypes = propTypes;
