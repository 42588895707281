import classNames from 'classnames';
import React, { useState } from 'react';

import styles from './ChartLegends.module.css';

function ChartLegends(props) {
  const toggleAllMessages = () => {
    if (props.echartRef) {
      const echartInstance = props.echartRef.getEchartsInstance();
      echartInstance.dispatchAction({
        type: 'legendToggleSelect',
        name: 'Все сообщения:'
      });
      setAllMessagesState(!allMessagesState);
    }
  };

  const toggleAuthors = () => {
    if (props.echartRef) {
      const echartInstance = props.echartRef.getEchartsInstance();
      echartInstance.dispatchAction({
        type: 'legendToggleSelect',
        name: 'Авторы:'
      });
      setAuthorsState(!authorsState);
    }
  };

  const [allMessagesState, setAllMessagesState] = useState(false);
  const [authorsState, setAuthorsState] = useState(false);

  const allMessagesClasses = classNames(styles['chart_label'], styles['chart__allMessages-label'], {
    [styles['disabled']]: allMessagesState
  });

  const authorsClasses = classNames(styles['chart_label'], styles['chart__authors-label'], {
    [styles['disabled']]: authorsState
  });

  return (
    <div className={styles['chart-legends']}>
      <div onClick={toggleAllMessages}>
        <div className={allMessagesClasses} data-status="enabled" data-name="Все сообщения:">
          <span className={styles['chart__allMessages-icon']} />
          <span>Все сообщения:</span>
          <span className={styles['chart__allMessages']}>378</span>
        </div>
      </div>
      <div onClick={toggleAuthors} className={authorsClasses}>
        <span className={styles['chart__authors-icon']}>
          <svg height="20" width="30">
            <line x1="0" y1="12" x2="24" y2="12" style={{ stroke: '#767676', strokeWidth: 2 }} />
            <circle cx="12" cy="12" r="6" stroke="#767676" strokeWidth="1" fill="white" />
          </svg>
        </span>
        <span>Авторы:</span>
        <span className={styles['chart__authors']}>227</span>
      </div>
    </div>
  );
}

export default ChartLegends;
