import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './SvgCheckbox.module.css';

const propTypes = {
  children: PropTypes.string,
  style: PropTypes.object,
  isChecked: PropTypes.bool.isRequired,
  changeHandler: PropTypes.func.isRequired,
  cbStyle: PropTypes.object,
  className: PropTypes.string
};

const defaultProps = {
  style: {},
  isChecked: false,

  cbStyle: {
    marginLeft: 10
  }
};

function SvgCheckbox({
  isDisabled,
  children,
  style,
  isChecked,
  changeHandler,
  cbStyle,
  dataTitle,
  className
}) {
  return (
    <label
      className={classNames(className, styles['checkbox-label'], {
        [styles['checkbox-label--disabled']]: isDisabled
      })}
      style={style}
      data-title={dataTitle}>
      <span>{children}</span>
      <input
        disabled={isDisabled}
        type="checkbox"
        checked={isChecked}
        onChange={(evt) => {
          changeHandler(evt.target.checked);
        }}
        className={styles['checkbox-input']}
      />
      <svg width="18px" height="18px" viewBox="0 0 18 18" style={cbStyle}>
        <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z" />
        <polyline points="1 9 7 14 15 4" />
      </svg>
    </label>
  );
}

export default SvgCheckbox;

SvgCheckbox.propTypes = propTypes;
SvgCheckbox.defaultProps = defaultProps;
