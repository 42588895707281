import Logo from 'assets/images/icons/logo.png';

const defaultDuration = 3000;
let notifications = [];
document.addEventListener('visibilitychange', function () {
  if (document.visibilityState === 'visible') {
    notifications.forEach((not) => not.close());
    notifications = [];
  }
});
export const addNativeNotification = ({
  title,
  icon = Logo,
  message,
  image,
  duration = defaultDuration,
  vibrate,
  silent
}) => {
  const not = new Notification(title ? `${title} | IActive.pro` : 'IActive.pro', {
    body: message,
    image,
    icon,
    vibrate,
    silent
  });
  notifications.push(not);
  not.onclick = function () {
    window.parent.focus();
    this.close();
  };
  setTimeout(not.close.bind(not), duration);
};
