import { Fragment, memo } from 'react';

import { Button, Icon, Input, Select, SvgCheckbox, TagsInput, Text } from 'components';

import styles from './InputEdit.module.css';

export const InputEdit = memo(
  ({
    label,
    value,
    removeHandler,
    updateValue,
    blurHandler,
    isRequired,
    shouldBe,
    type,
    options,
    className,
    updateRegistration,
    name
  }) => {
    const changeHandler = (key, value) => {
      // const value = evt.target.value;
      updateRegistration({ name, key, value });
    };

    const types = [
      { value: 'select', title: 'select' },
      { value: 'text', title: 'text' },
      { value: 'phone', title: 'phone' },
      { value: 'checkbox', title: 'checkbox' },
      { value: 'e-mail', title: 'e-mail' }
    ];

    const selectedType = types.find((el) => el.value === type);

    return (
      <li className={className}>
        <SvgCheckbox
          isChecked={shouldBe}
          // changeHandler={toggleAutoSend}
          changeHandler={() => changeHandler('shouldBe', !shouldBe)}
          style={{
            color: '#6f6f6f',
            fontSize: 16,
            opacity: shouldBe ? 0.75 : 0.5
          }}
        />
        <Input
          isDisabled={!shouldBe}
          type="text"
          value={value}
          placeholder={window.t('enterButtonName')}
          width={200}
          changeHandler={() => {}}
          // changeHandler={changeHandler}
          blurHandler={blurHandler}
          inpStyle={{ border: 'none', opacity: shouldBe ? 1 : 0.5 }}
          style={{ display: 'block' }}
        />
        <SvgCheckbox
          isDisabled={!shouldBe}
          isChecked={isRequired}
          // changeHandler={toggleAutoSend}
          changeHandler={() => changeHandler('isRequired', !isRequired)}
          style={{
            color: '#6f6f6f',
            fontSize: 16,
            opacity: shouldBe ? 0.75 : 0.5
          }}
        />

        {/* для будующего редактирования и удаления полей формы
        <td>
          <Select
            label={value}
            options={types}
            selectedOption={selectedType}
            setSelectedOption={() => {}}
            // setSelectedOption={changeType}
            // isSelectDisabled={isTypeSelectDisabled}
            // disabledMessage={window.t('contentClearBlocksToChangeType')}
            width={150}
          />
        </td> */}

        {/* <Button
        onlyIcon
        startIcon={<Icon fontName="fal fa-times-circle" size={18} />}
        variant="icon"
        color="#CDCDCD"
        className="ml-1"
        style={{
          borderRadius: '50%',
          padding: 0
        }}
        clickHandler={removeHandler}
      /> */}
      </li>
    );
  }
);
