import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './BlurWrapper.module.css';

const propTypes = {
  className: PropTypes.string,
  isOpened: PropTypes.bool.isRequired,
  style: PropTypes.object,
  background: PropTypes.string,
  position: PropTypes.oneOf(['absolute', 'fixed']),
  clickHandler: PropTypes.func.isRequired,
  children: PropTypes.any
};

const defaultProps = {
  isOpened: false,
  style: {},
  position: 'fixed',
  clickHandler: () => {}
};

function BlurWrapper({
  blurRef,
  className,
  isOpened,
  style,
  background,
  position,
  clickHandler,
  children
}) {
  return (
    <div
      ref={blurRef}
      className={classNames(styles['blur-wrapper'], className, {
        [styles.shown]: isOpened
      })}
      style={{ ...style, background, position }}>
      <div className={styles['click-area']} style={{ position }} onClick={clickHandler} />
      {children}
    </div>
  );
}

export default BlurWrapper;

BlurWrapper.propTypes = propTypes;
BlurWrapper.defaultProps = defaultProps;
