import { INTERACTIVES, INTERACTIVE_ACTIVITIES } from '../constants';

import { updateInfoField } from 'store/actions/isettings';
import { setSessionActivity } from 'store/actions/sessions';
import { autoreplyActions } from 'store/slices/autoreplySlice';

import { requestApiPost } from 'api';
import {
  startAutoreply as startAutoreplyApi,
  stopAutoreply as stopAutoreplyApi
} from 'api/dataLayer';

// export function fetchActiveAutoreply() {
//   return async (dispatch, getState) => {
//     try {
//       const res = await getActiveAutoreply();
//       const data = res.body[0];

//       if (!data) {
//         return;
//       }

//       data.settings = clearAndParseJSON(data.settings);
//       dispatch(setActiveAutoreplyData(data));
//     } catch (ex) {
//       console.error(ex);
//     }
//   };
// }

export function updateStartAutoreply(id, infoField = 'activity', request, settings) {
  return async (dispatch, getState) => {
    try {
      await request?.(id);
      const { info } = getState().isettings;

      if (info?.id === id && info?.type === INTERACTIVES.autoReply) {
        dispatch(updateInfoField(infoField, INTERACTIVE_ACTIVITIES.active));
      } else {
        dispatch(updateInfoField(infoField, INTERACTIVE_ACTIVITIES.finished));
      }

      dispatch(setSessionActivity(id, INTERACTIVE_ACTIVITIES.active));
      dispatch(
        autoreplyActions.setActiveAutoreplyData({
          data: {
            id,
            settings: settings || info?.settings
          }
        })
      );
    } catch (ex) {
      console.error(ex);
    }
  };
}

export function startAutoreply(id, infoField = 'activity') {
  return updateStartAutoreply(id, infoField, startAutoreplyApi);
}

export function updateStopAutoreply(id, infoField = 'activity', request) {
  return async (dispatch, getState) => {
    try {
      const { info } = getState().isettings;
      await request?.(id);
      if (info?.type === INTERACTIVES.autoReply) {
        dispatch(updateInfoField(infoField, INTERACTIVE_ACTIVITIES.finished));
      }
      dispatch(setSessionActivity(id, INTERACTIVE_ACTIVITIES.finished));
      dispatch(autoreplyActions.setActiveAutoreplyData({ data: null }));
    } catch (ex) {
      console.error(ex);
    }
  };
}

export function stopAutoreply(id, infoField = 'activity') {
  return updateStopAutoreply(id, infoField, stopAutoreplyApi);
}
