import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { Button, Icon, Input, Menu, Text, TextField } from 'components';

import styles from './StatesSettingsItemView.module.css';

const propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  removeHandler: PropTypes.func,
  updateValue: PropTypes.func,
  updateDuration: PropTypes.func,
  updateType: PropTypes.func,
  blurHandler: PropTypes.func,
  duration: PropTypes.string
};

export const StatesSettingsItemView = memo(
  ({
    label,
    value,
    removeHandler,
    updateValue,
    updateDuration,
    blurHandler,
    duration,
    localeState,
    startMenuRect,
    startBtn,
    selectList,
    startActivatorHandler,
    startMenuActivity
  }) => {
    return (
      <div className="d-flex mb-4 align-center">
        <Text size={15} className={`${styles['bs__order']} mr-1`}>
          <span>{label}</span> –
        </Text>
        <Input
          type="text"
          value={value}
          placeholder={window.t('enterButtonName')}
          changeHandler={(evt) => {
            const value = evt.target.value;
            updateValue(value);
          }}
          blurHandler={blurHandler}
          width={200}
        />
        <TextField
          width={45}
          value={duration}
          mainColor="#808080"
          type="number"
          changeHandler={(event) => {
            updateDuration(event.target.value);
          }}
          blurHandler={(event) => blurHandler(event.target.value)}
          style={{
            margin: '0px 7px -2px'
          }}
          postfix={localeState === window.LOCALE_EN ? 's' : 'с'}
        />
        <Menu
          activator={startBtn}
          activatorHandler={startActivatorHandler}
          isActive={startMenuActivity}
          left={startMenuRect ? startMenuRect.left : 0}
          top={startMenuRect ? startMenuRect.bottom : 0}>
          {selectList}
        </Menu>
        <Button
          onlyIcon
          startIcon={<Icon fontName="fal fa-times-circle" size={18} />}
          variant="icon"
          color="#CDCDCD"
          className="ml-1"
          style={{
            borderRadius: '50%',
            padding: 0
          }}
          clickHandler={removeHandler}
        />
      </div>
    );
  }
);

StatesSettingsItemView.propTypes = propTypes;
