export const goTo = (history, path, data, location) => {
  if (history.location.pathname === path) {
    history.replace({
      pathname: path,
      state: { detail: data, prevPath: location.pathname }
    });
  } else {
    history.push({
      pathname: path,
      state: { detail: data, prevPath: location.pathname }
    });
  }
};

export const getCurrentDomain = () => {
  const hostname = window.location.hostname;
  return hostname === 'localhost' || hostname === 'react792.iactive.pro'
    ? 'dev671'
    : hostname.slice(0, -12);
};
