import { useActions } from 'hooks';

import { INTERACTIVE_ACTIVITIES } from 'store/constants';

export const useRegistrationStartStopData = ({ activity, id }) => {
  const { updateStopRegistration, updateStartRegistration } = useActions();
  return {
    startStopData: {
      startStopIcon: activity === INTERACTIVE_ACTIVITIES.active ? 'far fa-stop' : 'far fa-play',
      startStopTitle:
        activity === INTERACTIVE_ACTIVITIES.active ? 'stopRegistration' : 'startRegistration',
      startStopColor: activity === INTERACTIVE_ACTIVITIES.active ? '#ff4d29' : '#00bb00',
      startStopClickHandler:
        activity === INTERACTIVE_ACTIVITIES.active
          ? () => {
              updateStopRegistration(id);
            }
          : () => {
              updateStartRegistration(id);
            }
    }
  };
};
