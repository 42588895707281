import { memo } from 'react';

import { Button } from 'components';

import styles from './RegistrationState.module.css';

const ExcelJS = require('exceljs');

export const UploadToExcel = memo(
  ({ participants, dateRegistration, interactiveName, checkboxes }) => {
    const columns = checkboxes && checkboxes?.length !== 0 ? checkboxes?.map((el) => el.name) : [];

    if (!columns?.includes('participationFact')) {
      columns.push('participationFact');
    }

    const clickHandler = () => {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(dateRegistration || 'Регистрация');

      worksheet.addTable({
        name: 'MyTable',
        ref: 'A1',
        headerRow: true,
        // totalsRow: true,
        style: {
          theme: 'TableStyleMedium28',
          showRowStripes: true
          // showColumnStripes: true,
        },
        columns: columns.map((el, i) => {
          let name = '';
          // подумать о нейменге
          if (el === 'participation') {
            name = 'План участия';
          } else if (el === 'participationFact') {
            name = 'Факт участия';
          } else name = window.t(el);
          return {
            totalsRowLabel: i === 0 ? 'Всего' : false,
            name,
            filterButton: true,
            totalsRowFunction: i === 1 ? 'count' : 'none'
          };
        }),
        //   columns: [
        //     {
        //       name: 'Имя',
        //       filterButton: true,
        //       totalsRowLabel: 'Всего:'
        //     },
        //     { name: 'Дата', filterButton: true },
        //     { name: 'Сумма', filterButton: true, totalsRowFunction: 'sum' }
        //   ],
        rows: participants.map((el) => {
          let arrData = [];
          columns.forEach((col) => {
            if (el?.author_data[col]) {
              arrData.push(el?.author_data[col] || '-');
            } else {
              arrData.push('-');
            }
          });
          return arrData;
        })
      });

      const table = worksheet.getTable('MyTable');

      // for (let i = 0; i < table.table.rows.length; i++) {
      //   for (let j = 0; j < table.table.columns.length; j++) {
      //     worksheet.getRow(i + 2).findCell(j + 1).fill = {
      //       type: 'pattern',
      //       pattern: 'solid',
      //       fgColor: { argb: arrData[i]?.color }
      //     };
      //   }
      // }

      table.commit();

      worksheet.views = [
        {
          state: 'frozen',
          xSplit: 0,
          ySplit: 1
        }
      ];

      columns.forEach((el, i) => {
        const maxlength = Math.max(
          ...participants?.map((field) => field.author_data[el]?.length || 0)
        );
        const nameColumn =
          el === 'participation'
            ? 'План участия'
            : el === 'participationFact'
            ? 'Факт участия'
            : window.t(el);
        worksheet.getColumn(i + 1).width = Math.max(maxlength, nameColumn?.length * 1.2) + 5;
      });

      worksheet.getRow(1).font = {
        bold: true,
        size: 13
      };
      // worksheet.lastRow.font = {
      //   bold: true,
      //   size: 13
      // };
      // worksheet.lastRow.findCell(1).border = {
      //   right: {
      //     color: { argb: 'A5A5A5' },
      //     style: 'thin'
      //   }
      // };

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `${interactiveName}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    };
    return (
      <div className={styles.xlsBtnBlock}>
        <Button clickHandler={clickHandler} isDisabled={participants.length === 0}>
          В Excel
        </Button>
      </div>
    );
  }
);
