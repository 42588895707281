export const getGuideLocale = () => {
  // { back: 'Back', close: 'Close', last: 'Last', next: 'Next', open: 'Open the dialog', skip: 'Skip' }
  return {
    back: window.t('back'),
    close: window.t('close'),
    last: window.t('last'),
    next: window.t('next'),
    open: window.t('open'),
    skip: window.t('skip')
  };
};
