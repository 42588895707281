import { useCallback, useEffect } from 'react';

import useActions from 'hooks/useActions';
import useFetch from 'hooks/useFetch';

export const useVoteQuestion = ({ voteInfo }) => {
  const { deleteVoteImage, changeSessionInfo, updateInfoField } = useActions();

  const [{ response, isLoading, error }, doFetch] = useFetch('api_upload_vote_image');

  const { id, question_img_url, title } = voteInfo;
  const pictureChangeHandler = useCallback(
    (evt, varnum = null) => {
      const file = evt.target.files[0];
      if (!file) return;

      file.isBinary = true;

      doFetch({
        method: 'POST',
        postBody: {
          questionImg: file,
          id
        }
      });
    },
    [id, doFetch]
  );

  useEffect(() => {
    if (!response) return;

    updateInfoField('question_img_url', response);
  }, [response, updateInfoField]);

  const deletePicture = useCallback(() => deleteVoteImage(id), [id, deleteVoteImage]);
  const titleChangeHandler = useCallback(
    (value) => updateInfoField('title', value),
    [updateInfoField]
  );

  return {
    title,
    titleChangeHandler,
    changeSessionInfo,
    pictureChangeHandler,
    deletePicture,
    question_img_url,
    isLoading
  };
};
