import React, { useEffect, useState } from 'react';

import styles from './ChartTimer.module.css';

function ChartTimer({ startTime, setTimerActivity }) {
  const getRestSeconds = () => {
    const currentDate = new Date();
    return Math.round((+new Date(startTime) - +currentDate) / 1000);
  };

  const timerStep = () => {
    if (restTime > 0) {
      setRestTime(restTime - 1);
    } else {
      setTimerActivity(false);
    }
  };

  const getStr = (restSec) => {
    let distance = restSec * 1000;
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);
    return `Осталось ${hours} ч. ${minutes} мин. ${seconds} сек. `;
  };

  const [restTime, setRestTime] = useState(getRestSeconds());

  useEffect(() => {
    let id = setInterval(timerStep, 1000);
    return () => {
      clearInterval(id);
    };
  });

  return <div className={styles['chart-timer']}>{getStr(restTime)}.</div>;
}

export default ChartTimer;
