import { DateDivider } from '../DateDivider/DateDivider';
import { range } from 'lodash';
import { Fragment, useContext, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { MessageActionsMenuContainer } from 'containers';
import { LoadPreviousButton } from 'containers/Feed/components/LoadPreviousButton/LoadPreviousButton';
import { AUTHOR_KEY_BY_TAB, DATE_KEY_BY_TAB, TABS } from 'containers/Feed/constants';
import { checkIfTheSameDay, getDateArray } from 'containers/Feed/helpers/feed';

import { MessageSkeleton, MouseMover } from 'components';

import { useActions } from 'hooks';

import { LocaleContext } from 'providers/LocaleProvider';

import styles from './FeedItems.module.css';

const defaultKeyFieldNames = ['id'];

export function FeedItems({
  className,
  hasInitialData,
  containerRef,
  scrollBoxRef,
  onFeedScroll,
  scrollBoxPadding,
  isLoading,
  children,
  hasMorePrevious,
  items,
  messageActionsConfig,
  loadPrevious,
  isSelected,
  clickHandler,
  broadcastId,
  toggleFavorite,
  activeBroadcastData,
  activeMenu,
  Component,
  tab,
  isLoaded,
  loadPreviousButtonRef,
  message,
  keyFieldNames = defaultKeyFieldNames,
  ...props
}) {
  const { isNewAtTop } = useSelector((state) => state.settings.messageFeedConfig);

  const { setMinimizedState } = useActions();

  const [locale] = useContext(LocaleContext);

  const dateKey = DATE_KEY_BY_TAB[tab] || DATE_KEY_BY_TAB.default;
  const authorKey = AUTHOR_KEY_BY_TAB[tab] || AUTHOR_KEY_BY_TAB.default;

  const withDate = useMemo(() => [TABS.messages, TABS.news].includes(tab), [tab]);

  return (
    <div id={styles['list-pane']} className={className}>
      <div id="feedItems" className={styles['items']}>
        {children}
        {hasInitialData && (
          <div
            onScroll={onFeedScroll}
            ref={scrollBoxRef}
            className={styles['scroll-box']}
            style={{ paddingTop: scrollBoxPadding }}>
            <LoadPreviousButton
              hasMorePrevious={hasMorePrevious}
              ref={loadPreviousButtonRef}
              isLoading={isLoading}
              isNewAtTop={isNewAtTop}
            />
            <div ref={containerRef} className={styles['msg-list']}>
              {!isLoaded
                ? range(10).map((el) => <MessageSkeleton key={el} />)
                : items &&
                  (isNewAtTop ? items : [...items].reverse()).map((el, i, array) => {
                    const curr = getDateArray(el[dateKey]);
                    const prev = getDateArray(array[i - 1]?.[dateKey]);
                    const isTheSameDay = i && checkIfTheSameDay(curr, prev);
                    const key = keyFieldNames
                      .map((fieldName) => el[fieldName])
                      .filter((val) => val)
                      .join(' ');

                    return (
                      <Fragment key={key}>
                        {withDate && !isTheSameDay && (
                          <DateDivider
                            className={className}
                            currentDate={curr}
                            item={el}
                            items={array}
                            authorKey={authorKey}
                            dateKey={dateKey}
                          />
                        )}
                        <div data-items="true">
                          <Component
                            message={message}
                            {...props}
                            tab={tab}
                            data={el}
                            messageActionsConfig={messageActionsConfig}
                            scrollBoxRef={scrollBoxRef}
                            isNewAtTop={!!isNewAtTop}
                            activeMenu={activeMenu}
                            isLoading={isLoading}
                            loadPrevious={loadPrevious}
                            items={items}
                            isSelected={isSelected(el.id)}
                            broadcastId={broadcastId}
                            toggleFavorite={toggleFavorite}
                            activeBroadcastData={activeBroadcastData}
                            containerRef={containerRef}
                            onFeedScroll={onFeedScroll}
                            scrollBoxPadding={scrollBoxPadding}
                            settingsMenu={<MessageActionsMenuContainer message={el} />}
                            toggleMinimizeHandler={setMinimizedState}
                            isLast={i === items.length - 1}
                            locale={locale}
                            selectMessage={clickHandler}
                            isDraggable={!!broadcastId && tab === TABS.messages}
                            type="messageFeed"
                          />
                        </div>
                      </Fragment>
                    );
                  })}
            </div>
          </div>
        )}
        <MouseMover />
      </div>
    </div>
  );
}
