import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';

import { checkIsValid } from 'containers/ContentContainer/components/NewsBlock/components/ContentFields/helpers/validationFields';

import styles from './Textarea.module.css';

const propTypes = {
  text: PropTypes.string,
  setText: PropTypes.func,
  send: PropTypes.func,
  style: PropTypes.object,
  focusable: PropTypes.bool,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  blurHandler: PropTypes.func,
  focusHandler: PropTypes.func
};

const defaultProps = {
  text: '',
  setText: () => {},
  send: () => {},
  focusHandler: () => {},
  changeHandler: () => {},
  style: {},
  focusable: true,
  placeholder: '',
  isValid: true,
  isTouched: false
};

export const Textarea = forwardRef(
  (
    {
      id,
      name,
      value,
      text,
      setText,
      send,
      blurHandler,
      focusHandler,
      changeHandler,
      style,
      textareaStyle,
      focusable,
      onKeyDown,
      placeholder,
      label,
      children,
      controls,
      inputRef,
      afterLabel,
      className,
      isValidPreview,
      block,
      updateContentBlockField = () => {},
      elementKey
    },
    ref
  ) => {
    const localRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);

    const isValid = useMemo(
      () =>
        checkIsValid(
          elementKey,
          block,
          block?.isTouched,
          null,
          updateContentBlockField,
          isValidPreview
        ),
      [text, block?.isTouched, value?.duration, isValidPreview]
    );

    const classes = classNames(className, styles['main-chat-msg-input'], {
      [styles['focusable']]: focusable,
      [styles['invalid']]: !isValid
    });

    useEffect(() => {
      resizeHeight((inputRef || localRef)?.current);
    }, [text, inputRef, localRef]);

    const focus = () => {
      if (inputRef) inputRef.current.focus();
      else localRef.current.focus();
    };

    const resizeHeight = (target) => {
      target.style.height = 'inherit';
      target.style.height = `${target.scrollHeight}px`;
    };

    return (
      <div ref={ref} className={classes} style={style}>
        {/* <label> */}
        <div className="d-flex">
          {label && (
            <span
              onClick={focus}
              className={classNames(styles['label'], {
                [styles['label-focused']]: isFocused
              })}>
              {label}
              {afterLabel}
            </span>
          )}
          {controls && (
            <div className="d-flex ml-auto">
              {/* костыль - первая кнопка воспринимается как label для textarea */}
              <button
                style={{
                  outline: 'none',
                  background: 'transparent'
                }}
              />
              {controls}
            </div>
          )}
        </div>
        <div className={styles['main-chat-msg-input-wrapper']}>
          {text.length <= 0 && (
            <div className={styles['main-chat-msg-input-wrapper_placeholder']}>{placeholder}</div>
          )}
          <textarea
            id={id}
            name={name}
            ref={inputRef || localRef}
            // value={(text || value?.value)?.toString() || ''}
            value={
              (
                text || (typeof value?.value === 'string' ? value?.value : value?.value?.text)
              )?.toString() || ''
            }
            style={textareaStyle}
            className={styles['main-chat-msg-input-wrapper_text']}
            onChange={(event) => {
              resizeHeight(event.target);
              setText(event.target.value);
              changeHandler?.(event);
            }}
            onFocus={(event) => {
              resizeHeight(event.target);
              setIsFocused(true);
              focusHandler?.(event);
            }}
            onBlur={(event) => {
              resizeHeight(event.target);
              blurHandler?.(event);
              setIsFocused(false);
            }}
            onKeyDown={(event) => {
              event.target.style.height = 'inherit';
              event.target.style.height = `${event.target.scrollHeight}px`;

              if (onKeyDown) onKeyDown();

              // if (event.key === 'Enter') {
              //     document.execCommand('insertLineBreak')
              //     event.preventDefault()
              // }
              // TODO: fix chat and feedbackInput
              // if (event.key === 'Enter' && !event.shiftKey) {
              //     send(event.target.innerText)
              //     event.preventDefault();
              // } else if (event.key === 'Enter' && event.shiftKey) {
              //     document.execCommand('insertLineBreak')
              //     event.preventDefault()
              // }
            }}
          />
          {/* <ContentEditable
                    innerRef={inputRef}
                    onKeyDown={(event) => {
                        if (onKeyDown)
                            onKeyDown();

                        if (event.key === 'Enter') {
                            document.execCommand('insertLineBreak')
                            event.preventDefault()
                        }
                        // TODO: fix chat and feedbackInput
                        // if (event.key === 'Enter' && !event.shiftKey) {
                        //     send(event.target.innerText)
                        //     event.preventDefault();
                        // } else if (event.key === 'Enter' && event.shiftKey) {
                        //     document.execCommand('insertLineBreak')
                        //     event.preventDefault()
                        // }
                    }}
                    onChange={(event) => { setText(event.currentTarget.textContent) }}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => {
                        if (blurHandler)
                            blurHandler();
                        setIsFocused(false)
                    }}
                    html={text.toString()}
                    className={styles['main-chat-msg-input-wrapper_text']} /> */}
        </div>
        {/* </label> */}
        {children}
      </div>
    );
  }
);

Textarea.propTypes = propTypes;
Textarea.defaultProps = defaultProps;
