import {
  ADD_LABEL_SUCCESS,
  ADD_RESPONSE_MESSAGE,
  CLEAR_LABELS_SUCCESS,
  DELETE_LABEL_SUCCESS,
  DELETE_RESPONSE_MESSAGE,
  EDIT_RESPONSE_MESSAGE,
  FETCH_FEED_ITEMS_SUCCESS,
  FILTER_FEED_ITEMS,
  HAS_MORE_PREVIOUS,
  INIT_TABS,
  MULTIPLE_SELECT_FEED_ITEMS,
  REMOVE_FEED_ITEM,
  REMOVE_FEED_ITEM_BY_KEY,
  SELECT_FEED_ITEM,
  SET_ACTIONS_MENU_ID,
  SET_ACTION_MENU_BTN_RECT,
  SET_CURRENT_TAB,
  SET_DRAGGING_FEED_ITEM_ID,
  SET_FEED_ITEM_FAV_STATUS,
  SET_FEED_ITEM_HIDDEN_STATE,
  SET_FEED_ITEM_LIKE_STATUS,
  SET_FEED_ITEM_MINIMIZED_STATUS,
  SET_HAS_NEW_FEED_ITEMS,
  SET_LABELS_SUCCESS,
  SET_NEW_MESSAGES_POS,
  SET_SCROLL_BOX,
  SHOW_ALL_FEED_ITEMS,
  UPDATE_FEED_ITEMS,
  UPDATE_FEED_ITEM_AUDIO
} from '../actions/actionTypes';
import cloneDeep from 'lodash/cloneDeep';

const initialTabState = {
  hasMorePrevious: true,
  scrollBox: null,
  items: [],
  selectedItems: [],
  error: null,
  hasNewItems: false,
  newItemsCount: 0,
  actionsMenuId: null,
  actionMenuBtnRect: null,
  draggingItemId: null
};

const initialState = {
  tabs: null,
  currentTab: null
};
export default function feedReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_TABS:
      const initialTabs = Object.fromEntries(
        action.tabs.map((tab) => [tab, cloneDeep(initialTabState)])
      );
      return {
        ...state,
        ...initialTabs,
        tabs: action.tabs
        // TODO
        // weather: initialTestWeatherData,
      };
    case SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.currentTab
      };
    case FETCH_FEED_ITEMS_SUCCESS:
      return {
        ...state,
        [action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          loading: false,
          items: [...action.items]
        }
      };
    case SELECT_FEED_ITEM:
      return {
        ...state,
        [action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          selectedItems: [action.id]
        }
      };
    case MULTIPLE_SELECT_FEED_ITEMS:
      return {
        ...state,
        [action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          selectedItems: [...state[action.tab || state.currentTab]?.selectedItems, action.id]
        }
      };
    case SET_HAS_NEW_FEED_ITEMS:
      return {
        ...state,
        [action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          hasNewItems: action.hasNewItems,
          newItemsCount: action.count
        }
      };
    case SET_NEW_MESSAGES_POS:
      return {
        ...state,
        [action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          items: state[action.tab || state.currentTab]?.items.reverse()
        }
      };
    case SET_SCROLL_BOX:
      return {
        ...state,
        [action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          scrollBox: action.scrollBox
        }
      };
    case UPDATE_FEED_ITEMS:
      return {
        ...state,
        [action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          items: action.items
        }
      };
    case SET_FEED_ITEM_FAV_STATUS: {
      return {
        ...state,
        [action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          items: (state[action.tab || state.currentTab]?.items || []).map((item) =>
            item.id === action.id ? { ...item, favorite: action.value } : item
          )
        }
      };
    }
    case SET_FEED_ITEM_LIKE_STATUS: {
      return {
        ...state,
        [action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          items: (state[action.tab || state.currentTab]?.items || []).map((item) =>
            item.id === action.id ? { ...item, isLike: action.value } : item
          )
        }
      };
    }
    case SET_FEED_ITEM_MINIMIZED_STATUS:
      return {
        ...state,
        [action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          items: (state[action.tab || state.currentTab]?.items || []).map((item) =>
            item.id === action.id
              ? {
                  ...item,
                  status: action.value
                    ? [...(item.status || []), 'minimized']
                    : item.status.filter((el) => el !== 'minimized')
                }
              : item
          )
        }
      };
    case SET_ACTIONS_MENU_ID:
      return {
        ...state,
        [action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          actionsMenuId: action.id
        }
      };
    case SET_ACTION_MENU_BTN_RECT:
      return {
        ...state,
        [action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          actionMenuBtnRect: action.rect
        }
      };
    case SET_FEED_ITEM_HIDDEN_STATE:
      return {
        ...state,
        [action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          items: (state[action.tab || state.currentTab]?.items || []).map((msg) =>
            msg.id === action.id ? { ...msg, isHidden: action.value } : msg
          )
        }
      };
    case SET_LABELS_SUCCESS:
      return {
        ...state,
        [action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          items: (state[action.tab || state.currentTab]?.items || []).map((msg) =>
            msg.id === action.id ? { ...msg, labels: action.labels.split(',') } : msg
          )
        }
      };
    case ADD_LABEL_SUCCESS:
      return {
        ...state,
        [action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          items: (state[action.tab || state.currentTab]?.items || []).map((msg) =>
            msg.id === action.id ? { ...msg, labels: [...msg.labels, action.label] } : msg
          )
        }
      };
    case DELETE_LABEL_SUCCESS:
      return {
        ...state,
        [action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          items: (state[action.tab || state.currentTab]?.items || []).map((msg) =>
            msg.id === action.id
              ? {
                  ...msg,
                  labels: msg.labels.filter((el) => el !== action.label)
                }
              : msg
          )
        }
      };
    case CLEAR_LABELS_SUCCESS:
      return {
        ...state,
        [action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          items: (state[action.tab || state.currentTab]?.items || []).map((msg) =>
            msg.id === action.id ? { ...msg, labels: [] } : msg
          )
        }
      };
    case SET_DRAGGING_FEED_ITEM_ID:
      return {
        ...state,
        [action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          draggingMessageId: action.id
        }
      };
    case FILTER_FEED_ITEMS:
      return {
        ...state,
        [action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          items: (state[action.tab || state.currentTab]?.items || []).map((msg) =>
            action.toShow.includes(msg.id)
              ? { ...msg, isHidden: false }
              : { ...msg, isHidden: true }
          )
        }
      };
    case SHOW_ALL_FEED_ITEMS:
      return {
        ...state,
        [action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          items: (state[action.tab || state.currentTab]?.items || []).map((msg) => ({
            ...msg,
            isHidden: false
          }))
        }
      };
    case REMOVE_FEED_ITEM:
      return {
        ...state,
        [action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          items: (state[action.tab || state.currentTab]?.items || []).filter(
            (el) => el.id !== action.id
          )
        }
      };
    case REMOVE_FEED_ITEM_BY_KEY:
      return {
        ...state,
        [action.tab || action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          items: state[action.tab || state.currentTab].items.filter(
            (el) => el[action.key] !== action.value
          )
        }
      };
    case UPDATE_FEED_ITEM_AUDIO:
      return {
        ...state,
        [action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          items: (state[action.tab || state.currentTab]?.items || []).map((message) =>
            message.id !== action.id
              ? message
              : {
                  ...message,
                  attachments: message.attachments.map((media) =>
                    media.ogg !== action.prevSrc ? media : { ...media, ogg: action.newSrc }
                  )
                }
          )
        }
      };
    case ADD_RESPONSE_MESSAGE: {
      return {
        ...state,
        [action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          items: (state[action.tab || state.currentTab]?.items || []).map((message) =>
            message.id === action.id
              ? {
                  ...message,
                  after: [
                    ...(message.after || []),
                    {
                      ...action.message,
                      message_type: 'message',
                      isNew: true
                    }
                  ]
                }
              : message
          )
        }
      };
    }
    case EDIT_RESPONSE_MESSAGE: {
      const { id, newId } = action.payload;
      return {
        ...state,
        [action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          items: (state[action.tab || state.currentTab]?.items || []).map((message) =>
            message.id === action.id
              ? {
                  ...message,
                  after: (message.after || []).map((msg) =>
                    msg.id === id ? { ...msg, id: newId, isNew: false } : msg
                  )
                }
              : message
          )
        }
      };
    }
    case DELETE_RESPONSE_MESSAGE: {
      return {
        ...state,
        [action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          items: (state[action.tab || state.currentTab]?.items || []).map((message) =>
            message.id === action.id
              ? {
                  ...message,
                  after: (message.after || []).filter((msg) => msg.id === action.responseId)
                }
              : message
          )
        }
      };
    }
    case HAS_MORE_PREVIOUS: {
      return {
        ...state,
        [action.tab || state.currentTab]: {
          ...state[action.tab || state.currentTab],
          hasMorePrevious: action.value
        }
      };
    }
    default:
      return state;
  }
}

const initialTestWeatherData = {
  scrollBox: null,
  selectedItems: [],
  error: null,
  hasNewItems: false,
  newItemsCount: 0,
  actionsMenuId: null,
  actionMenuBtnRect: null,
  draggingItemId: null,
  items: [
    {
      id: 1,
      now: 1, //  Время сервера в формате Unixtime.
      now_dt: '', // 	Время сервера в UTC.
      now: 1470220206,
      now_dt: '2016-08-03T10:30:06.238Z',
      info: {
        // Объект информации о населенном пункте.
        lat: 1, // Широта (в градусах).
        lon: 1, // Долгота (в градусах).
        tzinfo: {
          // Информация о часовом поясе.
          // Содержит поля offset, name, abbr и dst.
        },
        offset: 1, // Часовой пояс в секундах от UTC.
        name: '', // Название часового пояса.
        abbr: '', // Сокращенное название часового пояса.
        dst: false, //	Признак летнего времени.
        def_pressure_mm: 1, // Норма давления для данной координаты (в мм рт. ст.).
        def_pressure_pa: 1, // Норма давления для данной координаты (в гектопаскалях).
        url: '', // Страница населенного пункта на сайте Яндекс.Погода
        lat: 55.833333,
        lon: 37.616667,
        tzinfo: {
          offset: 10800,
          name: 'Europe/Moscow',
          abbr: 'MSK',
          dst: false
        },
        def_pressure_mm: 746,
        def_pressure_pa: 994,
        url: 'https://yandex.ru/pogoda/moscow'
      },
      fact: {
        // Объект фактической информации о погоде.
        temp: 1, // Температура (°C).
        feels_like: 1, // Ощущаемая температура (°C).
        temp_water: 1, // Температура воды (°C). Параметр возвращается для населенных пунктов, где данная информация актуальна.
        icon: '', // Код иконки погоды. Иконка доступна по адресу https://yastatic.net/weather/i/icons/funky/dark/<значение из поля icon>.svg.
        condition: 'clear', // Код расшифровки погодного описания. Возможные значения:
        // clear — ясно.
        // partly-cloudy — малооблачно.
        // cloudy — облачно с прояснениями.
        // overcast — пасмурно.
        // drizzle — морось.
        // light-rain — небольшой дождь.
        // rain — дождь.
        // moderate-rain — умеренно сильный дождь.
        // heavy-rain — сильный дождь.
        // continuous-heavy-rain — длительный сильный дождь.
        // showers — ливень.
        // wet-snow — дождь со снегом.
        // light-snow — небольшой снег.
        // snow — снег.
        // snow-showers — снегопад.
        // hail — град.
        // thunderstorm — гроза.
        // thunderstorm-with-rain — дождь с грозой.
        // thunderstorm-with-hail — гроза с градом.
        wind_speed: 1, // Скорость ветра (в м/с).
        wind_gust: 1, // Скорость порывов ветра (в м/с).
        wind_dir: 'nw', // Направление ветра. Возможные значения:
        // «nw» — северо-западное.
        // «n» — северное.
        // «ne» — северо-восточное.
        // «e» — восточное.
        // «se» — юго-восточное.
        // «s» — южное.
        // «sw» — юго-западное.
        // «w» — западное.
        // «c» — штиль.
        pressure_mm: 1, // Давление (в мм рт. ст.).
        pressure_pa: 1, // Давление (в гектопаскалях).
        humidity: 1, // Влажность воздуха (в процентах).
        daytime: 'd', //	Светлое или темное время суток. Возможные значения:
        // «d» — светлое время суток.
        // «n» — темное время суток.
        polar: false, // Признак того, что время суток, указанное в поле daytime, является полярным.
        season: 'summer', // Время года в данном населенном пункте. Возможные значения:
        // «summer» — лето.
        // «autumn» — осень.
        // «winter» — зима.
        // «spring» — весна.
        obs_time: 1, // Время замера погодных данных в формате Unixtime.
        is_thunder: false, // Признак грозы.
        prec_type: 0, // Тип осадков. Возможные значения:
        // 0 — без осадков.
        // 1 — дождь.
        // 2 — дождь со снегом.
        // 3 — снег.
        // 4 — град.
        prec_strength: 0, // Сила осадков. Возможные значения:
        // 0 — без осадков.
        // 0.25 — слабый дождь/слабый снег.
        // 0.5 — дождь/снег.
        // 0.75 — сильный дождь/сильный снег.
        // 1 — сильный ливень/очень сильный снег.
        cloudness: 0, // Облачность. Возможные значения:
        // 0 — ясно.
        // 0.25 — малооблачно.
        // 0.5 — облачно с прояснениями.
        // 0.75 — облачно с прояснениями.
        // 1 — пасмурно.
        phenom_icon: '', // Код дополнительной иконки погодного явления. Обрабатывается по аналогии с icon.
        phenom_condition: 'fog', // Код расшифровки дополнительного погодного описания. Обрабатывается по аналогии с condition. Возможные значения:
        // fog — туман.
        // mist — дымка.
        // smoke — смог.
        // dust — пыль.
        // dust-suspension — пылевая взвесь.
        // duststorm — пыльная буря.
        // thunderstorm-with-duststorm — пыльная буря с грозой.
        // drifting-snow — слабая метель.
        // blowing-snow — метель.
        // ice-pellets — ледяная крупа.
        // freezing-rain — ледяной дождь.
        // tornado — торнадо.
        // volcanic-ash — вулканический пепел.
        temp: 20,
        feels_like: 21,
        icon: 'ovc',
        condition: 'overcast',
        wind_speed: 2,
        wind_gust: 5.9,
        wind_dir: 'n',
        pressure_mm: 745,
        pressure_pa: 994,
        humidity: 83,
        daytime: 'd',
        polar: false,
        season: 'summer',
        prec_type: 1,
        prec_strength: 0.25,
        is_thunder: false,
        cloudness: 1,
        obs_time: 1470214800,
        phenom_icon: 'ovm',
        'phenom-condition': 'fog'
      },
      forecasts: [
        {
          // Объект содержит данные прогноза погоды.
          date: '2023-02-08', // Дата прогноза в формате ГГГГ-ММ-ДД.
          date_ts: 1, // Дата прогноза в формате Unixtime.
          week: 1, // Порядковый номер недели.
          sunrise: '', // Время восхода Солнца, локальное время (может отсутствовать для полярных регионов).
          sunset: '', // Время заката Солнца, локальное время (может отсутствовать для полярных регионов).
          moon_code: 0, //	Код фазы Луны. Возможные значения:
          // 0 — полнолуние.
          // 1-3 — убывающая Луна.
          // 4 — последняя четверть.
          // 5-7 — убывающая Луна.
          // 8 — новолуние.
          // 9-11 — растущая Луна.
          // 12 — первая четверть.
          // 13-15 — растущая Луна.
          moon_text: 'moon-code-0', //	Текстовый код для фазы Луны. Возможные значения:
          // moon-code-0 — полнолуние.
          // moon-code-1 — убывающая луна.
          // moon-code-2 — убывающая луна.
          // moon-code-3 — убывающая луна.
          // moon-code-4 — последняя четверть.
          // moon-code-5 — убывающая луна.
          // moon-code-6 — убывающая луна.
          // moon-code-7 — убывающая луна.
          // moon-code-8 — новолуние.
          // moon-code-9 — растущая луна.
          // moon-code-10 — растущая луна.
          // moon-code-11 — растущая луна.
          // moon-code-12 — первая четверть.
          // moon-code-13 — растущая луна.
          // moon-code-14 — растущая луна.
          // moon-code-15 — растущая луна.
          date: '2016-08-03',
          date_ts: 1470171600,
          week: 32,
          sunrise: '04:38',
          sunset: '20:31',
          moon_code: 8,
          moon_text: 'moon-code-8',
          parts: {
            // Прогнозы по времени суток и 12-часовые прогнозы.
            // Содержит поля, различающиеся типом прогноза:
            // night — прогноз на ночь.
            // morning — прогноз на утро.
            // day — прогноз на день.
            // evening — прогноз на вечер.
            // day_short — 12-часовой прогноз на день.
            // night_short — прогноз на ночь, для которого исключены поля temp_min и temp_max, в поле temp указывается минимальная температура за ночной период.
            // Все прогнозы погоды на время суток имеют одинаковый набор полей.
            // Все 12-часовые прогнозы имеют одинаковый набор полей.
            // Примечание. В последнем возвращаемом дне прогноза состав частей может быть неполным.
            morning: {
              temp_min: 1, //	Минимальная температура для времени суток (°C).
              temp_max: 1, // Максимальная температура для времени суток (°C).
              temp_avg: 1, // Средняя температура для времени суток (°C).
              feels_like: 1, // Ощущаемая температура (°C).
              icon: '', // Код иконки погоды. Иконка доступна по адресу https://yastatic.net/weather/i/icons/funky/dark/<значение из поля icon>.svg.
              condition: 'clear', // 	Код расшифровки погодного описания. Возможные значения:
              // clear — ясно.
              // partly-cloudy — малооблачно.
              // cloudy — облачно с прояснениями.
              // overcast — пасмурно.
              // drizzle — морось.
              // light-rain — небольшой дождь.
              // rain — дождь.
              // moderate-rain — умеренно сильный дождь.
              // heavy-rain — сильный дождь.
              // continuous-heavy-rain — длительный сильный дождь.
              // showers — ливень.
              // wet-snow — дождь со снегом.
              // light-snow — небольшой снег.
              // snow — снег.
              // snow-showers — снегопад.
              // hail — град.
              // thunderstorm — гроза.
              // thunderstorm-with-rain — дождь с грозой.
              // thunderstorm-with-hail — гроза с градом.
              daytime: 'd', // Светлое или темное время суток. Возможные значения:
              // «d» — светлое время суток.
              // «n» — темное время суток.
              polar: false, // Признак того, что время суток, указанное в поле daytime, является полярным.
              wind_speed: 1, // Скорость ветра (в м/с).
              wind_gust: 1, // Скорость порывов ветра (в м/с).
              wind_dir: 'nw', // Направление ветра. Возможные значения:
              // «nw» — северо-западное.
              // «n» — северное.
              // «ne» — северо-восточное.
              // «e» — восточное.
              // «se» — юго-восточное.
              // «s» — южное.
              // «sw» — юго-западное.
              // «w» — западное.
              // «c» — штиль.
              pressure_mm: 1, // Давление (в мм рт. ст.).
              pressure_pa: 1, // Давление (в гектопаскалях).
              humidity: 1, // Влажность воздуха (в процентах).
              prec_mm: 1, // Прогнозируемое количество осадков (в мм).
              prec_period: 1, // Прогнозируемый период осадков (в минутах).
              prec_type: 0, // Тип осадков. Возможные значения:
              // 0 — без осадков.
              // 1 — дождь.
              // 2 — дождь со снегом.
              // 3 — снег.
              // 4 — град.
              prec_strength: 0, // Сила осадков. Возможные значения:
              // 0 — без осадков.
              // 0.25 — слабый дождь/слабый снег.
              // 0.5 — дождь/снег.
              // 0.75 — сильный дождь/сильный снег.
              // 1 — сильный ливень/очень сильный снег.
              cloudness: 0, //	Облачность. Возможные значения:
              // 0 — ясно.
              // 0.25 — малооблачно.
              // 0.5 — облачно с прояснениями.
              // 0.75 — облачно с прояснениями.
              // 1 — пасмурно.
              temp_min: 17,
              temp_max: 19,
              temp_avg: 18,
              feels_like: 19,
              icon: 'bkn_ra',
              condition: 'rain',
              daytime: 'd',
              polar: false,
              wind_speed: 1.8,
              wind_dir: 'n',
              pressure_mm: 745,
              pressure_pa: 994,
              humidity: 99,
              prec_mm: 17.95,
              prec_period: 360,
              prec_type: 1,
              prec_strength: 0.5,
              cloudness: 0.75
            },
            day: {
              temp_min: 1, //	Минимальная температура для времени суток (°C).
              temp_max: 1, // Максимальная температура для времени суток (°C).
              temp_avg: 1, // Средняя температура для времени суток (°C).
              feels_like: 1, // Ощущаемая температура (°C).
              icon: '', // Код иконки погоды. Иконка доступна по адресу https://yastatic.net/weather/i/icons/funky/dark/<значение из поля icon>.svg.
              condition: 'clear', // 	Код расшифровки погодного описания. Возможные значения:
              // clear — ясно.
              // partly-cloudy — малооблачно.
              // cloudy — облачно с прояснениями.
              // overcast — пасмурно.
              // drizzle — морось.
              // light-rain — небольшой дождь.
              // rain — дождь.
              // moderate-rain — умеренно сильный дождь.
              // heavy-rain — сильный дождь.
              // continuous-heavy-rain — длительный сильный дождь.
              // showers — ливень.
              // wet-snow — дождь со снегом.
              // light-snow — небольшой снег.
              // snow — снег.
              // snow-showers — снегопад.
              // hail — град.
              // thunderstorm — гроза.
              // thunderstorm-with-rain — дождь с грозой.
              // thunderstorm-with-hail — гроза с градом.
              daytime: 'd', // Светлое или темное время суток. Возможные значения:
              // «d» — светлое время суток.
              // «n» — темное время суток.
              polar: false, // Признак того, что время суток, указанное в поле daytime, является полярным.
              wind_speed: 1, // Скорость ветра (в м/с).
              wind_gust: 1, // Скорость порывов ветра (в м/с).
              wind_dir: 'nw', // Направление ветра. Возможные значения:
              // «nw» — северо-западное.
              // «n» — северное.
              // «ne» — северо-восточное.
              // «e» — восточное.
              // «se» — юго-восточное.
              // «s» — южное.
              // «sw» — юго-западное.
              // «w» — западное.
              // «c» — штиль.
              pressure_mm: 1, // Давление (в мм рт. ст.).
              pressure_pa: 1, // Давление (в гектопаскалях).
              humidity: 1, // Влажность воздуха (в процентах).
              prec_mm: 1, // Прогнозируемое количество осадков (в мм).
              prec_period: 1, // Прогнозируемый период осадков (в минутах).
              prec_type: 0, // Тип осадков. Возможные значения:
              // 0 — без осадков.
              // 1 — дождь.
              // 2 — дождь со снегом.
              // 3 — снег.
              // 4 — град.
              prec_strength: 0, // Сила осадков. Возможные значения:
              // 0 — без осадков.
              // 0.25 — слабый дождь/слабый снег.
              // 0.5 — дождь/снег.
              // 0.75 — сильный дождь/сильный снег.
              // 1 — сильный ливень/очень сильный снег.
              cloudness: 0, //	Облачность. Возможные значения:
              // 0 — ясно.
              // 0.25 — малооблачно.
              // 0.5 — облачно с прояснениями.
              // 0.75 — облачно с прояснениями.
              // 1 — пасмурно.
              temp_min: 17,
              temp_max: 19,
              temp_avg: 18,
              feels_like: 19,
              icon: 'bkn_ra',
              condition: 'rain',
              daytime: 'd',
              polar: false,
              wind_speed: 1.8,
              wind_dir: 'ne',
              pressure_mm: 745,
              pressure_pa: 994,
              humidity: 99,
              prec_mm: 17.95,
              prec_period: 360,
              prec_type: 1,
              prec_strength: 0.5,
              cloudness: 0.75
            },
            evening: {
              temp_min: 1, //	Минимальная температура для времени суток (°C).
              temp_max: 1, // Максимальная температура для времени суток (°C).
              temp_avg: 1, // Средняя температура для времени суток (°C).
              feels_like: 1, // Ощущаемая температура (°C).
              icon: '', // Код иконки погоды. Иконка доступна по адресу https://yastatic.net/weather/i/icons/funky/dark/<значение из поля icon>.svg.
              condition: 'clear', // 	Код расшифровки погодного описания. Возможные значения:
              // clear — ясно.
              // partly-cloudy — малооблачно.
              // cloudy — облачно с прояснениями.
              // overcast — пасмурно.
              // drizzle — морось.
              // light-rain — небольшой дождь.
              // rain — дождь.
              // moderate-rain — умеренно сильный дождь.
              // heavy-rain — сильный дождь.
              // continuous-heavy-rain — длительный сильный дождь.
              // showers — ливень.
              // wet-snow — дождь со снегом.
              // light-snow — небольшой снег.
              // snow — снег.
              // snow-showers — снегопад.
              // hail — град.
              // thunderstorm — гроза.
              // thunderstorm-with-rain — дождь с грозой.
              // thunderstorm-with-hail — гроза с градом.
              daytime: 'd', // Светлое или темное время суток. Возможные значения:
              // «d» — светлое время суток.
              // «n» — темное время суток.
              polar: false, // Признак того, что время суток, указанное в поле daytime, является полярным.
              wind_speed: 1, // Скорость ветра (в м/с).
              wind_gust: 1, // Скорость порывов ветра (в м/с).
              wind_dir: 'nw', // Направление ветра. Возможные значения:
              // «nw» — северо-западное.
              // «n» — северное.
              // «ne» — северо-восточное.
              // «e» — восточное.
              // «se» — юго-восточное.
              // «s» — южное.
              // «sw» — юго-западное.
              // «w» — западное.
              // «c» — штиль.
              pressure_mm: 1, // Давление (в мм рт. ст.).
              pressure_pa: 1, // Давление (в гектопаскалях).
              humidity: 1, // Влажность воздуха (в процентах).
              prec_mm: 1, // Прогнозируемое количество осадков (в мм).
              prec_period: 1, // Прогнозируемый период осадков (в минутах).
              prec_type: 0, // Тип осадков. Возможные значения:
              // 0 — без осадков.
              // 1 — дождь.
              // 2 — дождь со снегом.
              // 3 — снег.
              // 4 — град.
              prec_strength: 0, // Сила осадков. Возможные значения:
              // 0 — без осадков.
              // 0.25 — слабый дождь/слабый снег.
              // 0.5 — дождь/снег.
              // 0.75 — сильный дождь/сильный снег.
              // 1 — сильный ливень/очень сильный снег.
              cloudness: 0, //	Облачность. Возможные значения:
              // 0 — ясно.
              // 0.25 — малооблачно.
              // 0.5 — облачно с прояснениями.
              // 0.75 — облачно с прояснениями.
              // 1 — пасмурно.
              temp_min: 17,
              temp_max: 19,
              temp_avg: 18,
              feels_like: 19,
              icon: 'bkn_ra',
              condition: 'rain',
              daytime: 'd',
              polar: false,
              wind_speed: 1.8,
              wind_dir: 'e',
              pressure_mm: 745,
              pressure_pa: 994,
              humidity: 99,
              prec_mm: 17.95,
              prec_period: 360,
              prec_type: 1,
              prec_strength: 0.5,
              cloudness: 0.75
            },
            night: {
              temp_min: 1, //	Минимальная температура для времени суток (°C).
              temp_max: 1, // Максимальная температура для времени суток (°C).
              temp_avg: 1, // Средняя температура для времени суток (°C).
              feels_like: 1, // Ощущаемая температура (°C).
              icon: '', // Код иконки погоды. Иконка доступна по адресу https://yastatic.net/weather/i/icons/funky/dark/<значение из поля icon>.svg.
              condition: 'clear', // 	Код расшифровки погодного описания. Возможные значения:
              // clear — ясно.
              // partly-cloudy — малооблачно.
              // cloudy — облачно с прояснениями.
              // overcast — пасмурно.
              // drizzle — морось.
              // light-rain — небольшой дождь.
              // rain — дождь.
              // moderate-rain — умеренно сильный дождь.
              // heavy-rain — сильный дождь.
              // continuous-heavy-rain — длительный сильный дождь.
              // showers — ливень.
              // wet-snow — дождь со снегом.
              // light-snow — небольшой снег.
              // snow — снег.
              // snow-showers — снегопад.
              // hail — град.
              // thunderstorm — гроза.
              // thunderstorm-with-rain — дождь с грозой.
              // thunderstorm-with-hail — гроза с градом.
              daytime: 'd', // Светлое или темное время суток. Возможные значения:
              // «d» — светлое время суток.
              // «n» — темное время суток.
              polar: false, // Признак того, что время суток, указанное в поле daytime, является полярным.
              wind_speed: 1, // Скорость ветра (в м/с).
              wind_gust: 1, // Скорость порывов ветра (в м/с).
              wind_dir: 'nw', // Направление ветра. Возможные значения:
              // «nw» — северо-западное.
              // «n» — северное.
              // «ne» — северо-восточное.
              // «e» — восточное.
              // «se» — юго-восточное.
              // «s» — южное.
              // «sw» — юго-западное.
              // «w» — западное.
              // «c» — штиль.
              pressure_mm: 1, // Давление (в мм рт. ст.).
              pressure_pa: 1, // Давление (в гектопаскалях).
              humidity: 1, // Влажность воздуха (в процентах).
              prec_mm: 1, // Прогнозируемое количество осадков (в мм).
              prec_period: 1, // Прогнозируемый период осадков (в минутах).
              prec_type: 0, // Тип осадков. Возможные значения:
              // 0 — без осадков.
              // 1 — дождь.
              // 2 — дождь со снегом.
              // 3 — снег.
              // 4 — град.
              prec_strength: 0, // Сила осадков. Возможные значения:
              // 0 — без осадков.
              // 0.25 — слабый дождь/слабый снег.
              // 0.5 — дождь/снег.
              // 0.75 — сильный дождь/сильный снег.
              // 1 — сильный ливень/очень сильный снег.
              cloudness: 0, //	Облачность. Возможные значения:
              // 0 — ясно.
              // 0.25 — малооблачно.
              // 0.5 — облачно с прояснениями.
              // 0.75 — облачно с прояснениями.
              // 1 — пасмурно.
              temp_min: 20,
              temp_max: 21,
              temp_avg: 21,
              feels_like: 23,
              icon: 'bkn_n',
              condition: 'cloudy',
              daytime: 'n',
              polar: false,
              wind_speed: 0.9,
              wind_gust: 4,
              wind_dir: 'se',
              pressure_mm: 746,
              pressure_pa: 995,
              humidity: 81,
              prec_mm: 0,
              prec_period: 360,
              prec_type: 0,
              prec_strength: 0,
              cloudness: 0.75
            },
            day_short: {
              // Объект с 12-часовым прогнозом на день. Содержит следующие поля:
              // temp
              // temp_min
              // feels_like
              // icon
              // condition
              // wind_speed
              // wind_gust
              // wind_dir
              // pressure_mm
              // pressure_pa
              // humidity
              // prec_type
              // prec_strength
              // cloudness
              temp: 20,
              temp_min: 18,
              feels_like: 21,
              icon: 'ovc_-ra',
              condition: 'light-rain',
              wind_speed: 1.9,
              wind_gust: 5.9,
              wind_dir: 'nw',
              pressure_mm: 745,
              pressure_pa: 994,
              humidity: 88,
              prec_type: 1,
              prec_strength: 0.25,
              cloudness: 1
            }
          },
          night: {
            // Объект с прогнозом погоды на ночь. Содержит следующие поля:
            // temp_min
            // temp_max
            // temp_avg
            // feels_like
            // icon
            // condition
            // daytime
            // polar
            // wind_speed
            // wind_gust
            // wind_dir
            // pressure_mm
            // pressure_pa
            // humidity
            // prec_mm
            // prec_period
            // prec_type
            // prec_strength
            // cloudness
          },
          temp_min: 1, //	Минимальная температура для времени суток (°C).
          temp_max: 1, // Максимальная температура для времени суток (°C).
          temp_avg: 1, // Средняя температура для времени суток (°C).
          feels_like: 1, // Ощущаемая температура (°C).
          icon: '', // Код иконки погоды. Иконка доступна по адресу https://yastatic.net/weather/i/icons/funky/dark/<значение из поля icon>.svg.
          condition: 'clear', // 	Код расшифровки погодного описания. Возможные значения:
          // clear — ясно.
          // partly-cloudy — малооблачно.
          // cloudy — облачно с прояснениями.
          // overcast — пасмурно.
          // drizzle — морось.
          // light-rain — небольшой дождь.
          // rain — дождь.
          // moderate-rain — умеренно сильный дождь.
          // heavy-rain — сильный дождь.
          // continuous-heavy-rain — длительный сильный дождь.
          // showers — ливень.
          // wet-snow — дождь со снегом.
          // light-snow — небольшой снег.
          // snow — снег.
          // snow-showers — снегопад.
          // hail — град.
          // thunderstorm — гроза.
          // thunderstorm-with-rain — дождь с грозой.
          // thunderstorm-with-hail — гроза с градом.
          daytime: 'd', // Светлое или темное время суток. Возможные значения:
          // «d» — светлое время суток.
          // «n» — темное время суток.
          polar: false, // Признак того, что время суток, указанное в поле daytime, является полярным.
          wind_speed: 1, // Скорость ветра (в м/с).
          wind_gust: 1, // Скорость порывов ветра (в м/с).
          wind_dir: 'nw', // Направление ветра. Возможные значения:
          // «nw» — северо-западное.
          // «n» — северное.
          // «ne» — северо-восточное.
          // «e» — восточное.
          // «se» — юго-восточное.
          // «s» — южное.
          // «sw» — юго-западное.
          // «w» — западное.
          // «c» — штиль.
          pressure_mm: 1, // Давление (в мм рт. ст.).
          pressure_pa: 1, // Давление (в гектопаскалях).
          humidity: 1, // Влажность воздуха (в процентах).
          prec_mm: 1, // Прогнозируемое количество осадков (в мм).
          prec_period: 1, // Прогнозируемый период осадков (в минутах).
          prec_type: 0, // Тип осадков. Возможные значения:
          // 0 — без осадков.
          // 1 — дождь.
          // 2 — дождь со снегом.
          // 3 — снег.
          // 4 — град.
          prec_strength: 0, // Сила осадков. Возможные значения:
          // 0 — без осадков.
          // 0.25 — слабый дождь/слабый снег.
          // 0.5 — дождь/снег.
          // 0.75 — сильный дождь/сильный снег.
          // 1 — сильный ливень/очень сильный снег.
          cloudness: 0, //	Облачность. Возможные значения:
          // 0 — ясно.
          // 0.25 — малооблачно.
          // 0.5 — облачно с прояснениями.
          // 0.75 — облачно с прояснениями.
          // 1 — пасмурно.

          day_short: {
            // Объект с 12-часовым прогнозом на день. Содержит следующие поля:
            // temp
            // temp_min
            // feels_like
            // icon
            // condition
            // wind_speed
            // wind_gust
            // wind_dir
            // pressure_mm
            // pressure_pa
            // humidity
            // prec_type
            // prec_strength
            // cloudness
            temp: 20,
            temp_min: 18,
            feels_like: 21,
            icon: 'ovc_-ra',
            condition: 'light-rain',
            wind_speed: 1.9,
            wind_gust: 5.9,
            wind_dir: 'nw',
            pressure_mm: 745,
            pressure_pa: 994,
            humidity: 88,
            prec_type: 1,
            prec_strength: 0.25,
            cloudness: 1
          },
          temp: 1, // Максимальная дневная или минимальная ночная температура (°C).
          hours: [
            // Объект почасового прогноза погоды. Содержит 96 частей (часов), после возвращается пустая строка. Каждая часть имеет следующие поля:
            // hour
            // hour_ts
            // temp
            // feels_like
            // icon
            // condition
            // wind_speed
            // wind_gust
            // wind_dir
            // pressure_mm
            // pressure_pa
            // humidity
            // prec_mm
            // prec_period
            // prec_type
            // prec_strength
            // is_thunder
            // cloudness
            {
              hour: '0',
              hour_ts: 1470171600,
              temp: 21,
              feels_like: 23,
              icon: 'bkn_n',
              condition: 'partly-cloudy',
              wind_speed: 0.3,
              wind_gust: 4,
              wind_dir: 'nw',
              pressure_mm: 746,
              pressure_pa: 995,
              humidity: 80,
              prec_mm: 0,
              prec_period: 60,
              prec_type: 0,
              prec_strength: 0,
              is_thunder: false,
              cloudness: 0.25
            },
            {
              hour: '23',
              hour_ts: 1470254400,
              temp: 17,
              feels_like: 19,
              icon: 'ovc_-ra',
              condition: 'light-rain',
              wind_speed: 0.9,
              wind_gust: 3.6,
              wind_dir: 'nw',
              pressure_mm: 745,
              pressure_pa: 994,
              humidity: 100,
              prec_mm: 0.05,
              prec_period: 60,
              prec_type: 1,
              prec_strength: 0.25,
              is_thunder: false,
              cloudness: 1
            }
          ],
          hour: '', // Значение часа, для которого дается прогноз (0-23), локальное время.
          hour_ts: 1 // Время прогноза в Unixtime.
        }
      ]
    }
  ]
};
