import * as AC from '../actions/settings';
import { updateProfileSettings } from './profile';
import Cookies from 'universal-cookie';

export function setNewMessagesPos(value) {
  return (dispatch) => {
    const cookies = new Cookies();
    cookies.set('upToDown', value, { path: '/' });
    dispatch(setNewMessagesPosition(value));
  };
}

export function setNewMessagesPosition(value) {
  return (dispatch) => {
    dispatch(updateProfileSettings({ messageFeedConfig: { isNewAtTop: value } }));
    dispatch(AC.updateMessageFeedConfigAC('isNewAtTop', value));
  };
}

export function addAdditionalValue(payload) {
  return async (dispatch, getState) => {
    try {
      const { type, text } = payload;
      const array = getState().settings.messageFeedConfig[type] || [];
      const tempId = Math.random();
      const newArray = [
        ...array,
        {
          additional_text: text,
          id: tempId
        }
      ];
      dispatch(
        AC.updateMessageFeedConfigAC(type, [
          ...array,
          {
            additional_text: text,
            id: +array[array.length - 1]?.id + 1
          }
        ])
      );
      dispatch(updateMessageFeedConfig(type, newArray, false));
      dispatch(updateProfileSettings({ messageFeedConfig: { [type]: newArray } }));
    } catch (ex) {
      console.error(ex);
    }
  };
}

export function deleteAdditionalValue({ type, text }) {
  return async (dispatch, getState) => {
    try {
      const messageFeedConfig = getState().settings.messageFeedConfig;
      const found = messageFeedConfig[type].find((el) => el.additional_text === text);
      if (!found) return;
      const result = messageFeedConfig[type].filter((el) => el.id !== found.id);
      dispatch(AC.updateMessageFeedConfigAC(type, result));
      dispatch(updateProfileSettings({ messageFeedConfig: { [type]: result } }));
    } catch (ex) {
      console.error(ex);
    }
  };
}

export function setSettings(settings) {
  return async (dispatch, getState) => {
    dispatch(AC.setSettingsAC(settings));
  };
}

export function updateAppSettings(field, value) {
  return async (dispatch) => {
    dispatch(updateProfileSettings({ appSettings: { [field]: value } }));
    dispatch(AC.updateAppSettingsAC(field, value));
  };
}

export function updateSettingsArray(field, value) {
  return async (dispatch) => {
    dispatch(updateProfileSettings({ settingsArray: { [field]: value } }));
    dispatch(AC.updateSettingsArrayAC(field, value));
  };
}

export function updateMessageActionsConfig(field, value) {
  return async (dispatch) => {
    dispatch(updateProfileSettings({ messageActionsConfig: { [field]: value } }));
    dispatch(AC.updateMessageActionsConfigAC(field, value));
  };
}

export function updateMessageViewConfig(field, value) {
  return async (dispatch) => {
    dispatch(updateProfileSettings({ messageViewConfig: { [field]: value } }));
    dispatch(AC.updateMessageViewConfigAC(field, value));
  };
}

export function updateMessageFeedConfig(field, value, updateSettings = true) {
  return async (dispatch) => {
    if (updateSettings) dispatch(updateProfileSettings({ messageFeedConfig: { [field]: value } }));
    dispatch(AC.updateMessageFeedConfigAC(field, value));
  };
}

export function updateBroadcastConfig(field, value) {
  return async (dispatch) => {
    dispatch(updateProfileSettings({ broadcastConfig: { [field]: value } }));
    dispatch(AC.updateBroadcastConfigAC(field, value));
  };
}

export function updateFeedbackActionsConfig(field, value) {
  return async (dispatch) => {
    dispatch(AC.updateFeedbackActionsConfigAC(field, value));
  };
}

export function setSettingsArray(settingsArray) {
  return async (dispatch) => {
    dispatch(updateProfileSettings({ settingsArray }));
    dispatch(AC.setSettingsArrayAC(settingsArray));
  };
}

// форма аватарок сообщений
export function setAvatarForm(avatarForm) {
  return async (dispatch, getState) => {
    const settings = getState().settings;
    // отправка всех настроек, одно поле дает ошибку на беке
    // dispatch(updateProfileSettings({ ...settings, avatarForm }));
    dispatch(updateProfileSettings({ avatarForm }));
    dispatch(AC.setAvatarFormAC(avatarForm));
  };
}

export function setMessageActionsConfig(messageActionsConfig) {
  return async (dispatch) => {
    dispatch(updateProfileSettings({ messageActionsConfig }));
    dispatch(AC.setMessageActionsConfigAC(messageActionsConfig));
  };
}

export function setMessageViewConfig(messageViewConfig) {
  return async (dispatch) => {
    dispatch(updateProfileSettings({ messageViewConfig }));
    dispatch(AC.setMessageViewConfigAC(messageViewConfig));
  };
}

export function setMessageFeedConfig(messageFeedConfig) {
  return async (dispatch) => {
    dispatch(updateProfileSettings({ messageFeedConfig }));
    dispatch(AC.setMessageFeedConfigAC(messageFeedConfig));
  };
}

export function setBroadcastConfig(broadcastConfig) {
  return async (dispatch) => {
    dispatch(updateProfileSettings({ broadcastConfig }));
    dispatch(AC.setBroadcastConfigAC(broadcastConfig));
  };
}

export function setFeedbackActionsConfig(feedbackActionsConfig) {
  return async (dispatch) => {
    dispatch(AC.setFeedbackActionsConfigAC(feedbackActionsConfig));
  };
}

export function setProfileInteractive(profileInteractive) {
  return async (dispatch) => {
    dispatch(updateProfileSettings({ iconClickInteractive: profileInteractive }));
    dispatch(AC.setProfileInteractiveAC(profileInteractive));
  };
}

export function setTotalNoteCount(count) {
  return async (dispatch) => {
    dispatch(updateProfileSettings({ totalNoteCount: count }));
    dispatch(AC.setTotalNoteCountAC(count));
  };
}

export function addExportConfig(type, newValue) {
  return async (dispatch, getState) => {
    dispatch(
      updateProfileSettings({
        exportConfig: {
          ...getState().settings.exportConfig,
          [type]: [...getState().settings.exportConfig[type], newValue]
        }
      })
    );
    dispatch(AC.addExportConfigAC(type, newValue));
  };
}
export function updateExportConfig(type, index, value) {
  return async (dispatch, getState) => {
    dispatch(
      updateProfileSettings({
        exportConfig: {
          ...getState().settings.exportConfig,
          [type]: getState().settings.exportConfig[type].map((item, i) => {
            if (index === i) {
              return { ...item, ...value };
            }
            return item;
          })
        }
      })
    );
    dispatch(AC.updateExportConfigAC(type, index, value));
  };
}
export function deleteExportConfig(type, index) {
  return async (dispatch, getState) => {
    dispatch(
      updateProfileSettings({
        exportConfig: {
          ...getState().settings.exportConfig,
          [type]: getState().settings.exportConfig[type].filter((_, i) => i !== index)
        }
      })
    );
    dispatch(AC.deleteExportConfigAC(type, index));
  };
}
