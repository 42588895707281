import { useSelector } from 'react-redux';

import { useActions } from 'hooks';

import { confirm } from 'helpers/common';

export const useProfiles = () => {
  const { chooseCurrentProfile, addNewProfile, updateProfile, deleteProfile, toggleProfileEdit } =
    useActions();
  const { profiles, currentProfile, areProfilesLoading, isNewProfileCreating } = useSelector(
    (store) => store.profile
  );
  const handleNewProfileCreate = ({ image, name }) => {
    if (!name) return;
    addNewProfile({
      name: name.trim(),
      avatar: image
    });
  };
  const handleProfileEdit =
    (id) =>
    ({ name, image }) => {
      toggleProfileEdit(id);
      updateProfile({
        id,
        profile_name: name.trim(),
        profile_avatar: image
      });
    };
  const handleProfileDelete = (profile) => () => {
    if (confirm(window.t('sureDeleteProfile').replace('[name]', profile.profile_name))) {
      deleteProfile(profile.id);
    }
  };

  return {
    profiles,
    hasProfiles: !(!areProfilesLoading && !profiles.length && !isNewProfileCreating),
    isLoading: areProfilesLoading,
    isCreating: isNewProfileCreating,
    current: currentProfile,
    choose: chooseCurrentProfile,
    remove: handleProfileDelete,
    edit: handleProfileEdit,
    toggleEdit: toggleProfileEdit,
    create: handleNewProfileCreate
  };
};
