import React from 'react';

import styles from './ImgMediaItem.module.css';

function ImgMediaItem({ src, openMedia }) {
  return (
    <div onClick={() => openMedia(src)} className={styles['profile-card__media-item']}>
      <img src={src} alt="" />
    </div>
  );
}

export default ImgMediaItem;
