import React, { memo } from 'react';

import { Message } from 'components';

import { BroadcastSettingsMenu } from './components/BroadcastSettingsMenu';

export const BroadcastMessagesView = memo(
  ({
    messages,
    isBroadcastStateFixed,
    messageStyle,
    messageActionsConfig,
    lastExportedMessages,
    toggleFavorite,
    status
  }) => {
    return (
      <div>
        {[...messages]
          .reverse()
          .filter((msg) =>
            status && status.isFavorite ? [true, '1'].includes(msg.favorite) : true
          )
          .map((el) => {
            return (
              <div key={el.id}>
                <Message
                  type="broadcast"
                  data={el}
                  isSelected={false}
                  messageActionsConfig={messageActionsConfig}
                  style={messageStyle}
                  isBroadcastMsg
                  lastExportedMessages={lastExportedMessages}
                  settingsMenu={<BroadcastSettingsMenu messageId={el.id} />}
                  toggleFavorite={toggleFavorite}
                />
                <hr
                  style={{
                    height: 1,
                    background: '#ccc'
                  }}
                />
              </div>
            );
          })}
      </div>
    );
  }
);
