import { PICTURE_LOADER_TYPES } from 'store/constants';

import { getImageByChannel } from 'helpers/image';

export const fields = (currentProfile) => ({
  news: [
    {
      name: 'logo',
      type: PICTURE_LOADER_TYPES.showModal,
      component: 'PictureLoaderContainer',
      // size: "full",
      initialValue: {
        type: 'image',
        url: currentProfile?.profile_avatar || getImageByChannel('')
      },
      isAlwaysShown: true
    },
    {
      name: 'rss_name',
      placeholder: 'placeholder',
      type: 'text',
      isRequired: true,
      component: 'TextField',
      initialValue: '',
      isAlwaysShown: true
    },
    {
      name: 'link',
      type: 'text',
      component: 'TextField',
      initialValue: '',
      isAlwaysShown: true
    },
    {
      name: 'title',
      type: 'text',
      isRequired: true,
      component: 'TextField',
      size: 'full',
      initialValue: '',
      isAlwaysShown: true
    },
    {
      name: 'description',
      type: 'text',
      component: 'Textarea',
      size: 'full',
      initialValue: '',
      isAlwaysShown: true
    },
    {
      name: 'category',
      type: 'text',
      component: 'TagsInput',
      size: 'full',
      initialValue: []
    },

    {
      name: 'author',
      label: 'Автор',
      type: 'text',
      component: 'TextField',
      initialValue: ''
    },
    {
      name: 'pubDate',
      type: 'date',
      component: 'DateTimePickerField',
      initialValue: ''
    },
    {
      name: 'attachments',
      type: PICTURE_LOADER_TYPES.showModal,
      component: 'PictureLoaderContainer',
      size: 'full',
      initialValue: [],
      isArray: true
    }
  ]
});
