import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  Avatar,
  Button,
  Icon,
  List,
  ListItem,
  ListItemText,
  SvgCheckbox,
  Text,
  Title
} from 'components';

import { useAsyncEffect } from 'hooks';

import { deleteYoutubeGroup, getYoutubeGroups, toggleCheckboxState } from 'api/dataLayer';

import { getSocialsImage } from 'helpers/image';

const propTypes = {
  setDeleteCardData: PropTypes.func.isRequired,
  setIsDeleteCardActive: PropTypes.func.isRequired,
  setIsYoutubeConnected: PropTypes.func.isRequired
};

const defaultProps = {
  setDeleteCardData: () => {},
  setIsDeleteCardActive: () => {},
  setIsYoutubeConnected: () => {}
};

function YoutubeCollapseBody({ setDeleteCardData, setIsDeleteCardActive, setIsYoutubeConnected }) {
  const [groups, setGroups] = useState([]);

  useAsyncEffect(async () => {
    try {
      const res = await getYoutubeGroups();
      const groups = res.body;

      if (!groups) return;

      setGroups(Object.values(groups));
    } catch (ex) {
      console.error(ex);
    }
  }, []);
  useEffect(() => {
    setIsYoutubeConnected(groups.length > 0);
  }, [groups]);

  const checkboxHandler = async (groupId, key, isChecked) => {
    try {
      await toggleCheckboxState({
        groupId,
        key,
        channel: 'youtube',
        isChecked: Number(isChecked)
      });
      setGroups((groups) =>
        groups.map((el) => (el.groupId === groupId ? { ...el, [key]: isChecked } : el))
      );
    } catch (ex) {
      console.error(ex);
    }
  };

  const deleteRequest = async ({ groupId, isComment, isMessage }) => {
    try {
      await deleteYoutubeGroup({ groupId, isComment, isMessage });
      setGroups((groups) => groups.filter((el) => el.groupId !== groupId));
      setIsDeleteCardActive(false);
    } catch (ex) {
      console.error(ex);
    }
  };

  const deleteHandler = (groupId) => {
    const groupData = groups.find((el) => el.groupId === groupId);
    groupData.deleteRequest = deleteRequest;
    groupData.removeMessage = window.t('realyWantToDeleteChannel');
    // для принудительного обновления стейта в DeleteCardModal
    groupData.random = Math.random();

    setDeleteCardData(groupData);
    setIsDeleteCardActive(true);
  };

  return (
    <>
      <div style={{ padding: '10px 0' }}>
        <Title className="d-inline-block" weight="normal" style={{ marginLeft: 50 }}>
          {window.t('accounts')}
        </Title>
        <Text tag="span" color="#808080">
          {` ${groups.length}`}
        </Text>
        <List noStyle style={{ marginLeft: 50 }}>
          {groups.map(({ groupId, groupName, isComment, isMessage, photo }) => (
            <ListItem key={groupId} noHover className="ml-0 mr-0 mt-2 mb-0 pa-0">
              <Avatar src={photo} size={30} className="flex-shrink-0 mr-2 align-self-center" />
              <ListItemText primary={groupName} />
              <Button
                onlyIcon
                startIcon={<Icon size={14} color="#CDCDCD" fontName="far fa-times" />}
                variant="icon"
                hoverColor="transparent"
                style={{
                  opacity: 1
                }}
                clickHandler={() => deleteHandler(groupId)}
              />
              <div className="d-flex ml-auto">
                {isComment !== null && isComment !== undefined && (
                  <SvgCheckbox
                    isChecked={Boolean(+isComment)}
                    changeHandler={(isChecked) => checkboxHandler(groupId, 'isComment', isChecked)}
                    style={{
                      color: '#808080',
                      fontSize: 14,
                      marginLeft: 10
                    }}>
                    {window.t('comments')}
                  </SvgCheckbox>
                )}
                {isMessage !== null && isMessage !== undefined && (
                  <SvgCheckbox
                    isChecked={Boolean(+isMessage)}
                    changeHandler={(isChecked) => checkboxHandler(groupId, 'isMessage', isChecked)}
                    style={{
                      color: '#808080',
                      fontSize: 14,
                      marginLeft: 10
                    }}>
                    {window.t('messages')}
                  </SvgCheckbox>
                )}
              </div>
            </ListItem>
          ))}
        </List>

        <Button
          clickHandler={() => {
            window
              .open('http://googleauth.iactive.pro/youtubeLive/auth?user=79619002008', '_blank')
              .focus();
          }}
          color="#fcfcfc"
          textColor="#757575"
          variant="google"
          size="medium"
          className="ml-auto mr-auto mt-5 mb-5"
          startIcon={
            <img
              src={getSocialsImage('google.svg')}
              className="d-block"
              width="18px"
              height="18px"
            />
          }>
          {window.t('connectNewChannel')}
        </Button>
      </div>
    </>
  );
}

export default YoutubeCollapseBody;

YoutubeCollapseBody.propTypes = propTypes;
YoutubeCollapseBody.defaultProps = defaultProps;
