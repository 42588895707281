import ActionMenu from './ActionMenu/ActionMenu';
import classNames from 'classnames';
import copyToClipboard from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { TABS } from 'containers/Feed/constants';

import { Button, Icon } from 'components';

import useActions from 'hooks/useActions';

import { INTERACTIVES } from 'store/constants';

import styles from './MessageActions.module.css';

const propTypes = {
  message: PropTypes.object.isRequired,
  isMinimized: PropTypes.bool,
  isMsgHover: PropTypes.bool,
  messageActionsConfig: PropTypes.object.isRequired,
  settingsMenu: PropTypes.element,
  toggleFavorite: PropTypes.func,
  canPin: PropTypes.bool
};

const defaultProps = {
  message: {},
  isMinimized: false,
  isMsgHover: false,
  messageActionsConfig: {},
  toggleFavorite: () => {}
};

const copyTitles = () => ({
  messages: window.t('copyToClipboard'),
  news: window.t('copyNews')
});

function MessageActions({
  message,
  isMinimized,
  isMsgHover,
  messageActionsConfig,
  settingsMenu,
  toggleFavorite,
  control,
  isExportTurnedOn = true,
  handleCopy,
  type,
  canHide
}) {
  const {
    addMessageToBroadcast,
    addMessageToContent,
    addNewsToBroadcast,
    addSnack,
    setMinimizedState
  } = useActions();
  const { openedInteractiveType } = useSelector((state) => state.isettings);

  const isExportAvailable =
    isExportTurnedOn &&
    (openedInteractiveType === INTERACTIVES.broadcast ||
      openedInteractiveType === INTERACTIVES.quiz ||
      openedInteractiveType === INTERACTIVES.vote ||
      openedInteractiveType === INTERACTIVES.content);

  const { id, content, senderNumber, title } = message;
  const isFavorite = Boolean(+message.favorite);
  const { copy, exportToBroadcast, minimize, settings, favorite } = messageActionsConfig;
  const classes = classNames(styles['message-action']);

  const copyHandler = () => {
    if (handleCopy) {
      handleCopy();
      return;
    }
    copyToClipboard(content || title);
    addSnack({
      type: 'success',
      message: 'Текст скопирован'
    });
  };

  const favStyle = {
    display: 'inline-flex',
    marginLeft: 5,
    padding: 0
  };

  if (isFavorite) favStyle.opacity = 1;
  const exportToInteractive = () => {
    if (type === TABS.messages) {
      if (openedInteractiveType === INTERACTIVES.content) addMessageToContent(message, type);
      else addMessageToBroadcast(message);
    }
    if (type === TABS.news) {
      if (openedInteractiveType === INTERACTIVES.content) {
        addMessageToContent(message, type);
      } else {
        addNewsToBroadcast(message);
      }
    }
  };
  const toggleMinimize = () => setMinimizedState(id, !isMinimized, senderNumber);

  const copyTitle = useMemo(() => copyTitles()[type], [type]);

  return (
    <div className={classes}>
      {copy && isMsgHover && (
        <Button
          startIcon={
            <Icon
              size={18}
              viewBox="0 0 561 561"
              icon="copy"
              style={{ enableBackground: 'new 0 0 561 561' }}
            />
          }
          onlyIcon
          variant="message-action"
          color="#000000"
          clickHandler={copyHandler}
          title={copyTitle}
          style={{ display: 'inline-flex', padding: 0 }}
        />
      )}
      {exportToBroadcast && isMsgHover && isExportAvailable && (
        <Button
          startIcon={
            <Icon
              size={18}
              viewBox="0 -28 453.99791 453"
              icon="export"
              style={{ enableBackground: '0 -28 453.99791 453' }}
            />
          }
          onlyIcon
          variant="message-action"
          color="#000000"
          title={window.t('sendMessageToInteractive')}
          clickHandler={exportToInteractive}
          style={{ display: 'inline-flex', padding: 0 }}
        />
      )}
      {minimize && isMsgHover && canHide && (
        <Button
          startIcon={
            <Icon
              size={15}
              viewBox="0 0 18 18"
              icon="minimize"
              style={{ enableBackground: '0 0 18 18' }}
            />
          }
          onlyIcon
          variant="message-action"
          color="#000000"
          title={window.t('hideMessage')}
          clickHandler={toggleMinimize}
          style={{ display: 'inline-flex', padding: 0 }}
        />
      )}
      {settings && isMsgHover && settingsMenu && (
        <ActionMenu settingsMenu={settingsMenu} isMsgHover={isMsgHover} />
      )}
      {isMsgHover && control}
      {favorite && (isMsgHover || isFavorite) && (
        <Button
          startIcon={<Icon size={18} fontName={`${isFavorite ? 'fa' : 'fal'} fa-star`} />}
          onlyIcon
          variant="message-action"
          clickHandler={() => {
            toggleFavorite(id, !isFavorite, senderNumber);
          }}
          title={window.t('addToFavourites')}
          style={favStyle}
        />
      )}
    </div>
  );
}

export default MessageActions;

MessageActions.propTypes = propTypes;
MessageActions.defaultProps = defaultProps;
