import { useMemo, useState } from 'react';

export const useProfileForm = ({ handleSubmit, initialName = '', initialImage = null }) => {
  const [isOpen, setIsOpen] = useState(!!(initialName || initialImage));
  const [name, setName] = useState(initialName);
  const [image, setImage] = useState(initialImage);
  const newProfileImagePreview = useMemo(() => {
    if (initialImage === image) {
      return image;
    } else {
      return image && URL.createObjectURL(image);
    }
  }, [image, initialImage]);

  const handleFormSubmit = (event) => {
    event?.preventDefault();
    handleSubmit({ name, image });
    setName('');
    setImage(null);
    setIsOpen(false);
  };

  const handleCancel = () => {
    handleSubmit({ name: initialName, image: initialImage });
    setName('');
    setImage(null);
    setIsOpen(false);
  };

  const handleToggle = () => {
    if (initialName || initialImage) {
      handleCancel();
      return;
    }
    setIsOpen((state) => !state);
  };

  return {
    isOpen,
    imagePreview: newProfileImagePreview,
    submit: handleFormSubmit,
    toggle: handleToggle,
    name,
    setName,
    setImage
  };
};
