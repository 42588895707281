import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Avatar.module.css';

const propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string.isRequired,
  style: PropTypes.object,
  rounded: PropTypes.bool,
  size: PropTypes.number,
  className: PropTypes.string,
  title: PropTypes.string,
  clickHandler: PropTypes.func
};

const defaultProps = {
  alt: '',
  style: {},
  rounded: true,
  size: 80,
  className: '',
  clickHandler: () => {}
};

function Avatar({ alt, style, src, size, rounded, className, title, clickHandler }) {
  const classes = classNames(styles['avatar'], {
    [styles['rounded']]: rounded
  });
  return (
    <div
      onClick={clickHandler}
      className={`${classes} ${className}`}
      style={{ width: size, height: size, ...style }}
      {...(title ? { title } : {})}>
      <img src={src} alt={alt} />
    </div>
  );
}

export default Avatar;

Avatar.propTypes = propTypes;
Avatar.defaultProps = defaultProps;
