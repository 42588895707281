import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from 'hooks';

import { generateUUID } from 'helpers/common';
import { objectFromRules } from 'helpers/content';

export const useContent = ({ isSettingsOpened, setIsSettingsOpened }) => {
  const { addContentBlock, removeContentBlock, moveBlockUp, moveBlockDown, setContentBlocks } =
    useActions();

  const info = useSelector((state) => state.isettings.info);
  const {
    id,
    interactiveName,
    content_type,
    content_slot,
    content_json,
    content_rule,
    publish_time
  } = info;
  const blocks = useSelector((state) => state.content.blocks);

  const activeBlocksLength = blocks.filter((el) => el.isActive).length;

  const toggleSettings = useCallback(() => {
    setIsSettingsOpened(!isSettingsOpened);
  }, [isSettingsOpened]);

  useEffect(() => {
    return () => {
      setContentBlocks({ blocks: [] });
    };
  }, [setContentBlocks]);

  useEffect(() => {
    if (Array.isArray(content_json) && blocks.length === 0)
      setContentBlocks({ blocks: content_json });
  }, [content_json, setContentBlocks]);

  const addBlock = useCallback(
    (type) => {
      type =
        type === 'default' && content_rule['content#']
          ? Object.keys(content_rule['content#'])[0]
          : type;

      const block = type
        ? objectFromRules(content_rule['content#'][type.value]?.fields)
        : objectFromRules(content_rule.content);
      block.id = generateUUID();
      addContentBlock({ id, block, type });
    },
    [id, content_rule?.content, addContentBlock]
  );

  const removeBlock = useCallback(
    (blockId) => removeContentBlock({ id, blockId }),
    [id, removeContentBlock]
  );
  const moveUp = useCallback((block) => moveBlockUp({ id, block }), [id, moveBlockUp]);
  const moveDown = useCallback((block) => moveBlockDown({ id, block }), [id, moveBlockDown]);

  return {
    interactiveName,
    content_rule,
    content_json,
    content_type,
    content_slot,
    publishTime: publish_time,
    blocks,
    activeBlocksLength,
    needBlocksCount:
      content_rule && !Array.isArray(content_rule) ? +content_rule['blocksCount#'] : 0,
    toggleSettings,
    addBlock,
    removeBlock,
    moveUp,
    moveDown
  };
};
