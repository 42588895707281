import { sessionsFilterActions } from 'store/slices/sessionsFilterSlice';

export function toggleSearchActivity(value) {
  return (dispatch) => {
    dispatch(sessionsFilterActions.setFilterActivity({ value }));
    dispatch(sessionsFilterActions.setSearchActivity({ value }));
  };
}

export function setInteractiveActivity(type, value) {
  return async (dispatch) => {
    await dispatch(
      sessionsFilterActions.setInteractiveActivitySuccess({
        interactiveType: type,
        value
      })
    );
    dispatch(filterSessions());
  };
}

export function setSessionsSearchText(value) {
  return (dispatch) => {
    dispatch(sessionsFilterActions.setSessionsSearchTextSuccess({ value }));
    dispatch(filterSessions());
  };
}

export function clearAllFilters() {
  return (dispatch) => {
    dispatch(setSessionsSearchText(''));
    dispatch(setInteractiveActivity()); // make all selected
    // dispatch(sessions())make all visible
  };
}

export function filterSessions() {
  return (dispatch, getState) => {
    const { searchText, interactives } = getState().sessionsFilter;
    const sessions = [...getState().sessions.sessions];

    const filtered = filterByInteractives(sessions, interactives);
    const toShow = filterByText(filtered, searchText).map((el) => el.id);

    const activeInteractives = interactives.filter((el) => el.isActive);
    dispatch(
      sessionsFilterActions.setIsFiltered({
        value:
          (activeInteractives.length !== interactives.length && activeInteractives.length !== 0) ||
          toShow.length !== sessions.length
      })
    );
    // dispatch(sessionsFilterActions.setFilteredSessions({ toShow }));
  };
}

function filterByInteractives(sessions, interactives) {
  const activeInteractives = interactives
    .map((el) => (el.isActive ? el.type : null))
    .filter((el) => el !== null);
  return activeInteractives.length === 0 || activeInteractives.length === interactives.length
    ? sessions
    : sessions.filter((el) => activeInteractives.includes(el.type));
}

function filterByText(filteredSessions, searchText) {
  const regPhrase = new RegExp(searchText, 'i');
  return searchText.length === 0
    ? filteredSessions
    : filteredSessions.filter(
        (el) =>
          regPhrase.test(el.title) || regPhrase.test(el.description) || regPhrase.test(el.memo)
      );
}
