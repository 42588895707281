import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import styles from './DropAreaView.module.css';

const propTypes = {
  isActive: PropTypes.bool,
  isHovered: PropTypes.bool,
  dragOver: PropTypes.func,
  dragEnter: PropTypes.func,
  dragLeave: PropTypes.func,
  dragDrop: PropTypes.func
};

export const DropAreaView = memo(
  ({ isActive, isHovered, dragOver, dragEnter, dragLeave, dragDrop }) => {
    const classes = classNames(styles['drop-area'], {
      hidden: !isActive,
      [styles['is-hovered']]: isHovered
    });

    return (
      <div
        className={classes}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={dragDrop}>
        <h1>{window.t('dragHere')}</h1>
      </div>
    );
  }
);

DropAreaView.propTypes = propTypes;
