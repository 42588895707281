import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Avatar, ListItem, ListItemText, NoteCount } from 'components';

import { getImageByChannel } from 'helpers/image';

import styles from './CollaspibleHead.module.css';

const propTypes = {
  channel: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.string,
  noteCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.element,
  imageClickHandler: PropTypes.func,
  message: PropTypes.string
};

function CollaspibleHead({
  icon,
  image,
  channel,
  name,
  status,
  noteCount,
  children,
  imageClickHandler,
  message
}) {
  return (
    <div className="d-flex">
      <ListItem noHover className={classNames('ma-0 pa-0', styles['container'])}>
        <div className="p-relative">
          {icon}
          {!icon && (
            <Avatar
              clickHandler={imageClickHandler}
              src={image || getImageByChannel(channel)}
              size={32}
              className={classNames('flex-shrink-0 mr-2 align-self-center', {
                [styles['clickableImage']]: !!imageClickHandler
              })}
            />
          )}
          {!!noteCount && <NoteCount count={noteCount} top={-3} right={6} />}
        </div>
        <ListItemText
          className={styles['head']}
          primary={name}
          secondary={status}
          primaryClassName={styles['head-title']}
        />
        {message && <p className={styles['message']}>{message}</p>}
      </ListItem>
      {children}
    </div>
  );
}

export default CollaspibleHead;

CollaspibleHead.propTypes = propTypes;
