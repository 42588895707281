import classNames from 'classnames';

import styles from './AnswerMessage.module.scss';

export function AnswerMessage({ value, className, incoming }) {
  return (
    <div
      className={classNames(className, styles.message, {
        [styles.message_incoming]: incoming
      })}>
      {value.textValue}
    </div>
  );
}
