import { BroadcastStateView } from './BroadcastStateView';
import PropTypes from 'prop-types';
import React, { forwardRef, memo } from 'react';

import { useBroadcastState } from './hooks/useBroadcastState';

const propTypes = {
  isBroadcastStateFixed: PropTypes.bool,
  setIsBroadcastStateFixed: PropTypes.func
};

const defaultProps = {
  isBroadcastStateFixed: false
};

export const BroadcastStateContainer = memo(
  forwardRef(({ isBroadcastStateFixed, setIsBroadcastStateFixed, setStepIndex }, ref) => {
    const { workPaneWidth, broadcastState, toggleState } = useBroadcastState();

    return (
      <BroadcastStateView
        ref={ref}
        isBroadcastStateFixed={isBroadcastStateFixed}
        workPaneWidth={workPaneWidth}
        broadcastState={broadcastState}
        toggleState={toggleState}
        setIsBroadcastStateFixed={setIsBroadcastStateFixed}
        setStepIndex={setStepIndex}
      />
    );
  })
);

BroadcastStateContainer.propTypes = propTypes;
BroadcastStateContainer.defaultProps = defaultProps;
