import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';

import styles from './DateTimePickerField.module.scss';

import 'react-datepicker/dist/react-datepicker.css';

export function DateTimePickerField({ className, ...props }) {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <div className={classNames(styles.picker, className)}>
      {props.label && (
        <label htmlFor={props.id} className={styles.picker__label}>
          {props.label}
        </label>
      )}
      <DatePicker
        {...field}
        {...props}
        className={styles.picker__input}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
        dateFormat={props.dateFormat || 'yyyy-MM-dd HH:mm:ss'}
        showTimeInput
      />
    </div>
  );
}
