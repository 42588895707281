import { AutoReplyView } from './AutoReplyView';

import { useInteractive } from 'hooks';

import { INTERACTIVES } from 'store/constants';

import { useAutoReply } from './hooks/useAutoReply';

export function AutoReplyContainer({ match, location }) {
  const { interactiveId, isSettingsOpened, setIsSettingsOpened } = useInteractive({
    match,
    location,
    type: INTERACTIVES.autoReply
  });
  const { toggleSettings, isInteractiveExist } = useAutoReply({
    isSettingsOpened,
    setIsSettingsOpened
  });

  return (
    <AutoReplyView
      isSettingsOpened={isSettingsOpened}
      toggleSettings={toggleSettings}
      autoReplyId={interactiveId}
      isInteractiveExist={isInteractiveExist}
    />
  );
}
