import { useNewsCard } from '../../hooks/useNewsCard';
import { useNewsCardControls } from '../../hooks/useNewsCardControls';
import { AudioPicker } from '../AudioPicker/AudioPicker';
import { CheckCorrectElement } from '../CheckCorrectElement/CheckCorrectElement';
import FormattedTextPreview from '../FormattedTextPreview/FormattedTextPreview';
import { StringWithPreviewElement } from '../StringWithPreviewElement/StringWithPreviewElement';
import { TextareaLengthInfoView } from '../TextareaLengthInfoView/TextareaLengthInfoView';
import { TitreDurationElement } from '../TitreDurationElement/TitreDurationElement';
import { checkIsValid } from './helpers/validationFields';
import { useContext, useState } from 'react';
import sanitizeHtml from 'sanitize-html';

import { PictureLoaderContainer } from 'containers/PictureLoaderContainer/PictureLoaderContainer';
import { VideoLoader } from 'containers/VideoLoaderContainer/VideoLoader';

import {
  AudioPlayer,
  Button,
  DateTimePicker,
  Icon,
  Range,
  Select,
  TextField,
  Textarea,
  Title
} from 'components';
import { TextEditor } from 'components/TextEditor/TextEditor';

import { LocaleContext } from 'providers/LocaleProvider';

import { PICTURE_LOADER_TYPES, VOICES_INTONATION_SP, VOICES_YANDEX_SP } from 'store/constants';

import { orderedFields } from 'helpers/content';
import { formatTimeToPlayer } from 'helpers/dateTime';
import { selectedOptionByValue } from 'helpers/interactives';

import styles from './ContentFields.module.css';

export function ContentFields({
  contentId,
  content_rule,
  block,
  textEffects,
  setStepIndex,
  blockTypes,
  initialAudioFiles,
  textToSpeechRef,
  blocks,
  activeBlocksLength
}) {
  const [locale] = useContext(LocaleContext);
  const {
    inputRef,
    insertTags,
    updateBlockField,
    pictureChangeHandler,
    isImageUploading,
    synthesisSpeech,
    isLoadingSynthesis,
    audioMetaData,
    uploadVideo,
    isVideoUploading,
    convertSpeech,
    isSpeechConverting,
    textFromSpeech,
    updateBlockType
  } = useNewsCard({ contentId, block, setStepIndex, content_rule });

  const controls = useNewsCardControls({ insertTags });

  const [isShowPreview, setIsShowPreview] = useState(false);
  const [isValidPreview, setIsValidPreview] = useState(true);
  const [descText, setDescText] = useState('');
  const orderedBlockFields = orderedFields(block);

  return (
    <>
      {['horizontal', 'vertical'].includes(block?.titreType?.value) && (
        <div className={styles.blockType_label}>
          {window.t('titreDurationIncludingAnimation')}
          {formatTimeToPlayer(
            Math.round(block.text_to_speech.duration + (block.text.durationAnimate || 0))
          )}
        </div>
      )}
      {block?.titreType?.canSelect && blockTypes && (
        <div className={styles.blockType}>
          <div className={styles.blockType_label}>{window.t('titreType')}:</div>
          <Select
            locale={locale}
            options={blockTypes}
            selectedOption={block?.titreType}
            setSelectedOption={(newVal) => updateBlockType(block?.id, newVal, block)}
          />
        </div>
      )}
      {block?.titreType?.canSelect === false && (
        <div className={styles.blockType_label}>{block.titreType.label[locale]}</div>
      )}
      {orderedBlockFields.map(([key, value], i) => {
        const maxLength = Number(value && value['max_length#']);
        const length = value?.value?.length;

        if (key === 'preview') {
          const font = value['font#'] || value['type#'];
          const [width, fontSize, fontWeight] = value['max_length#'].split('|');
          return (
            <>
              <FormattedTextPreview
                label={value.label?.[locale] || window.t(key)}
                key={key}
                text={descText}
                font={font}
                width={Number(width)}
                fontSize={Number(fontSize)}
                fontWeight={Number(fontWeight)}
                isShow={isShowPreview}
                isValidPreview={isValidPreview}
                setIsValidPreview={setIsValidPreview}
              />
            </>
          );
        }

        switch (value['type#']) {
          case 'audio':
            return (
              <AudioPicker
                key={key}
                initialAudioFiles={initialAudioFiles}
                locale={locale}
                blockId={block.id}
                selected={value.value}
                block={block}
                backgroundAudioEffects={content_rule?.background_audio_effects}
                updateBlockField={updateBlockField}
              />
            );
          case 'string':
            if (key === 'duration') {
              return (
                <TextField
                  key={key}
                  isValid={checkIsValid(key, block, block.isTouched, activeBlocksLength)}
                  type="number"
                  value={value.value}
                  changeHandler={(event) => updateBlockField(block.id, key, event.target.value)}
                  className={styles.blockElementTextField}
                  label={`${value.label[locale]}`}
                  borderStyle="dotted"
                />
              );
            }

            if (key === 'text' && textEffects) {
              const length = sanitizeHtml((value.value?.text ?? value.value) || '').replaceAll(
                '\n',
                ''
              )?.length;

              return (
                <TextEditor
                  textFromSpeech={textFromSpeech}
                  text={value.value}
                  textEffects={textEffects}
                  key={key}
                  value={value}
                  updateContentBlockField={updateBlockField}
                  setText={(newVal) => updateBlockField(block?.id, key, newVal)}
                  blurHandler={() => {
                    // request to update json on back
                    setIsShowPreview(false);
                    setDescText(value.value);
                  }}
                  focusHandler={() => {
                    setIsShowPreview(true);
                    setDescText(value.value);
                  }}
                  changeHandler={(e) => {
                    setDescText(e.target.value);
                  }}
                  afterLabel={
                    <TextareaLengthInfoView
                      {...((maxLength || length) && { length })}
                      {...(maxLength && { maxLength })}
                    />
                  }
                  focusable={false}
                  isTouched={block.isTouched}
                  label={value.label?.[locale] || window.t(key)}
                  className={styles.blockElementText}
                />
              );
            }
            return (
              <Textarea
                elementKey={key}
                updateContentBlockField={updateBlockField}
                block={block}
                value={value}
                isTouched={block.isTouched}
                key={key}
                text={typeof value?.value === 'string' ? value?.value : value?.value?.text}
                setText={(newVal) => updateBlockField(block?.id, key, newVal)}
                blurHandler={() => {
                  // request to update json on back
                  setIsShowPreview(false);
                }}
                focusHandler={() => {
                  setDescText(value.value);
                  if (key === 'text') setIsShowPreview(true);
                }}
                changeHandler={(e) => {
                  setDescText(e.target.value);
                }}
                afterLabel={
                  <TextareaLengthInfoView
                    {...((maxLength || length) && { length })}
                    {...(maxLength && { maxLength })}
                  />
                }
                focusable={false}
                label={value.label?.[locale] || window.t(key)}
                className={styles.blockElementText}
              />
            );
          case 'preview':
            const font = value['font#'] || value['type#'];
            const [width, fontSize, fontWeight] = value['max_length#'].split('|');
            return (
              <>
                <FormattedTextPreview
                  label={value.label?.[locale] || window.t(key)}
                  key={key}
                  text={descText}
                  font={font}
                  width={Number(width)}
                  fontSize={Number(fontSize)}
                  fontWeight={Number(fontWeight)}
                  isShow={isShowPreview}
                  isValidPreview={isValidPreview}
                  setIsValidPreview={setIsValidPreview}
                />
              </>
            );
          case 'speech':
            return (
              <Textarea
                elementKey={key}
                updateContentBlockField={updateBlockField}
                value={value}
                block={block}
                ref={textToSpeechRef}
                inputRef={inputRef}
                controls={controls}
                key={key}
                text={value.value}
                setText={(newVal) => updateBlockField(block?.id, key, newVal)}
                focusable={false}
                label={value.label?.[locale] || window.t(key)}
                className={styles.blockElementText}
                afterLabel={
                  <TextareaLengthInfoView
                    type="time"
                    length={Math.round(audioMetaData.duration)}
                    maxLength={maxLength}
                  />
                }>
                <div className="d-flex align-center mt-2">
                  <Button
                    className="mr-2"
                    clickHandler={() => synthesisSpeech(block?.id, key)}
                    isLoading={isLoadingSynthesis}>
                    {window.t('generateSpeech')}
                  </Button>
                  <AudioPlayer
                    className="flex-grow"
                    src={value.src}
                    style={{
                      margin: 0,
                      padding: '8px 0'
                    }}
                    noPlayerSpeed={value.noPlayerSpeed}
                  />
                  <Button
                    startIcon={<Icon size={20} fontName="fal fa-times" />}
                    onlyIcon
                    variant="icon"
                    color="#ff0033"
                    clickHandler={() => updateBlockField(block?.id, key, null, 'src')}
                    // title={window.t('clearTitr')}
                    style={{ padding: '6px 8px' }}
                  />
                </div>
                <div className={styles.audioSetting}>
                  <Select
                    label={window.t('voiceList')}
                    options={VOICES_YANDEX_SP}
                    selectedOption={selectedOptionByValue(VOICES_YANDEX_SP, value.voice)}
                    setSelectedOption={(newVal) => {
                      updateBlockField(block?.id, key, newVal.value, 'voice');
                      updateBlockField(block?.id, key, '', 'emotion');
                    }}
                    width="100%"
                    className={styles.blockElementSelect}
                  />
                  {VOICES_INTONATION_SP[value.voice]?.length > 0 && (
                    <Select
                      label={window.t('voiceIntonation')}
                      options={VOICES_INTONATION_SP[value.voice]}
                      selectedOption={selectedOptionByValue(
                        VOICES_INTONATION_SP[value.voice],
                        value.emotion
                      )}
                      setSelectedOption={(newVal) =>
                        updateBlockField(block?.id, key, newVal.value, 'emotion')
                      }
                      width="100%"
                      className={styles.blockElementSelect}
                    />
                  )}
                  <Range
                    minValue={0.1}
                    maxValue={3.0}
                    step={0.1}
                    value={value.speed}
                    title={`${window.t('voiceSpeed')}:`}
                    blurHandler={(newVal) => updateBlockField(block?.id, key, newVal, 'speed')}
                  />
                </div>
              </Textarea>
            );
          case 'image':
            return (
              <PictureLoaderContainer
                className={styles.blockElementPicture}
                key={key}
                isValid={
                  block?.length !== 0 &&
                  checkIsValid(key, block, block?.isTouched, null, updateBlockField)
                }
                type={PICTURE_LOADER_TYPES.showModal}
                picture={value.value}
                changeHandler={(event) => pictureChangeHandler(event, block?.id, key)}
                deleteHandler={() => pictureChangeHandler(null, block?.id, key)}
                isImageUploading={isImageUploading}
                content_rule={content_rule}
                isExported={value?.isExported}
                isExportedToggleHandler={(newVal) =>
                  updateBlockField(block?.id, key, newVal, 'isExported')
                }
              />
            );
          case 'video':
            return (
              <VideoLoader
                maxFileSizeInMB={value?.maxSizeInMB}
                video={value.value}
                uploadVideo={uploadVideo(block?.id, key)}
                isVideoUploading={isVideoUploading}
                isShowConvertButton={block?.text?.value !== undefined}
                convertSpeech={convertSpeech(block?.id, 'text', block?.text?.value)}
                isSpeechConverting={isSpeechConverting}
                width={400}
                height={300}
                key={key}
                block={block}
                elementKey={key}
                updateBlockField={updateBlockField}
              />
            );
          case 'checkbox':
            return (
              <SvgCheckbox
                style={{ marginTop: '8px' }}
                isChecked={value.value}
                changeHandler={(isChecked) => updateBlockField(block?.id, key, isChecked)}>
                {value.label?.[locale] || window.t(key)}
              </SvgCheckbox>
            );
          case 'select':
            return (
              <Select
                label={value.label?.[locale] || window.t(key)}
                options={value.options}
                selectedOption={selectedOptionByValue(value.options, value.value)}
                setSelectedOption={(newVal) => {
                  updateBlockField(block.id, key, newVal.value, 'value');
                }}
                width="100%"
                buttonClassName={styles.blockElementSelect}
                withResetButton
                key={key}
              />
            );
          case 'title':
            return (
              <Title size={16} style={{ marginTop: '10px' }} key={key}>
                {value.label?.[locale] || window.t(key)}
              </Title>
            );
          case 'checkCorrectElement':
            return (
              <CheckCorrectElement
                key={key}
                locale={locale}
                value={value}
                block={block}
                propKey={key}
              />
            );
          case 'date':
            return (
              <TextField
                key={key}
                type="datetime-local"
                width="48%"
                value={value.value}
                changeHandler={(event) =>
                  updateBlockField(block.id, key, event.target.value, 'value')
                }
                className="ma-0"
                inputFontSize="14px"
              />
            );
          case 'stringWithPreviewElement':
            return (
              <StringWithPreviewElement
                key={key}
                locale={locale}
                value={value}
                block={block}
                elementKey={key}
                textEffects={textEffects}
                maxLength={maxLength}
                textFromSpeech={textFromSpeech}
              />
            );
          case 'titreDurationElement':
            return (
              <TitreDurationElement
                key={key}
                value={value}
                block={block}
                elementKey={key}
                locale={locale}
                updateBlockField={updateBlockField}
                blocks={blocks}
                activeBlocksLength={activeBlocksLength}
              />
            );
          default:
            return null;
        }
      })}
    </>
  );
}
