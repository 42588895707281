import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'components';

import styles from './TimeSearchMode.module.css';

function TimeSearchMode({ searchConditions, setSearchConditions, activeSearchMode, scrollBoxRef }) {
  const periods = [
    {
      title: window.t('inHour'), // 'В течение часа',
      type: '1h'
    },
    {
      title: window.t('forSixHours'),
      type: '6h'
    },
    {
      title: window.t('perDay'),
      type: '1d'
    },
    {
      title: window.t('forTwoDays'),
      type: '2d'
    },
    {
      title: window.t('duringWeek'),
      type: '1w'
    },
    {
      title: window.t('perMonth'),
      type: '1m'
    }
  ];

  const { timeType } = searchConditions;

  const setTimeType = (value) => {
    const newVal = timeType === value ? null : value;
    setSearchConditions('timeType', newVal, scrollBoxRef);
  };

  const setPeriod = (event) => {
    const { name, value } = event.target;
    setSearchConditions(name, value, scrollBoxRef);
  };

  const classes = classNames(styles['pane'], {
    hidden: activeSearchMode !== 'timeType'
  });

  const getButtonClasses = (isActive) => {
    return classNames({
      [styles['selected']]: isActive
    });
  };

  return (
    <div id="searchModeTimeTab" className={classes}>
      <p className={styles['title']}>{window.t('timeSearch')}</p>
      <ul>
        {periods.map(({ type, title }, i) => {
          return (
            <Button
              clickHandler={() => {
                setTimeType(type);
              }}
              className={getButtonClasses(timeType === type)}
              color="#00000026"
              hoverColor="#00000040"
              textColor="#ffffff"
              variant="contained"
              style={{
                borderRadius: 50,
                padding: '7px 12px',
                display: 'inline-block',
                margin: '0 1px 7px'
              }}
              size="small"
              key={i}>
              {' '}
              {title}
            </Button>
          );
        })}
        <Button
          clickHandler={() => {
            setTimeType('period');
          }}
          className={getButtonClasses(timeType === 'period')}
          color="#00000026"
          hoverColor="#00000040"
          textColor="#ffffff"
          variant="contained"
          style={{
            borderRadius: 50,
            padding: '7px 12px',
            display: 'inline-block',
            margin: '0 1px 7px'
          }}
          size="small">
          {window.t('inRange')}
          <div className={styles['inputs-wrapper']}>
            <input
              className={styles['time-start-input']}
              onChange={setPeriod}
              name="timeStart"
              type="date"
              min="2016-01-01"
              id="searchDateFrom"
            />
            <input
              className={styles['time-end-input']}
              onChange={setPeriod}
              name="timeEnd"
              min="2016-01-01"
              type="date"
              id="searchDateTo"
            />
          </div>
        </Button>
      </ul>
    </div>
  );
}

export default TimeSearchMode;

TimeSearchMode.propTypes = {
  searchConditions: PropTypes.object,
  setSearchConditions: PropTypes.func,
  activeSearchMode: PropTypes.string
};
