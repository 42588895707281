import { VariantView } from './VariantView';
import PropTypes from 'prop-types';
import React, { forwardRef, memo } from 'react';

import { useLocalStorage } from 'hooks';

import { INTERACTIVES } from 'store/constants';

import { useVariant } from './hooks/useVariant';

const propTypes = {
  data: PropTypes.object,
  type: PropTypes.oneOf([INTERACTIVES.vote, INTERACTIVES.quiz]),
  index: PropTypes.number,
  isVoteActive: PropTypes.bool,
  isImageUploading: PropTypes.bool,
  isPhoneNumbersUsed: PropTypes.bool
};

const defaultProps = {
  isVoteActive: false,
  isImageUploading: false,
  type: INTERACTIVES.vote
};

export const VariantContainer = memo(
  forwardRef(
    (
      {
        data,
        type,
        voteId,
        result,
        index,
        isVoteActive,
        updateVariant,
        changeVoteVariant,
        deleteVoteVariant,
        updateVariantField,
        addVote,
        changeHandler,
        deleteHandler,
        isImageUploading,
        loadingImageVarnum,
        isPhoneNumbersUsed
      },
      ref
    ) => {
      const {
        var_img_url,
        changePicture,
        varnum,
        deleteVariant,
        toggleResult,
        percent,
        comment,
        updateComment,
        blurComment,
        addKeyword,
        deleteKeyword,
        name,
        showWarning,
        count,
        addVoteHandler
      } = useVariant({
        data,
        voteId,
        isVoteActive,
        updateVariant,
        changeVoteVariant,
        deleteVoteVariant,
        updateVariantField,
        addVote,
        changeHandler
      });
      const [votingNumbers] = useLocalStorage('votingNumbers');

      return (
        <VariantView
          ref={ref}
          var_img_url={var_img_url}
          changePicture={changePicture}
          deleteHandler={deleteHandler}
          isImageUploading={isImageUploading}
          loadingImageVarnum={loadingImageVarnum}
          varnum={varnum}
          deleteVariant={deleteVariant}
          index={index}
          type={type}
          result={result}
          toggleResult={toggleResult}
          percent={percent}
          comment={comment}
          updateComment={updateComment}
          blurComment={blurComment}
          addKeyword={addKeyword}
          deleteKeyword={deleteKeyword}
          name={name}
          showWarning={showWarning}
          count={count}
          addVoteHandler={addVoteHandler}
          votingNumber={
            isPhoneNumbersUsed && votingNumbers.find((number) => number.id === index + 1)?.num
          }
        />
      );
    }
  )
);

VariantContainer.propTypes = propTypes;
VariantContainer.defaultProps = defaultProps;
