import React, { forwardRef, memo } from 'react';

import { PictureLoaderContainer } from 'containers';

import { Button, Icon, SvgCheckbox, TagsInput, TextField } from 'components';
import Tag from 'components/TagsInput/Tag/Tag';

import { INTERACTIVES, PICTURE_LOADER_TYPES } from 'store/constants';

import { CounterView, ProgressBarView } from './components';

import styles from './VariantView.module.css';

export const VariantView = memo(
  forwardRef(
    (
      {
        var_img_url,
        changePicture,
        deleteHandler,
        isImageUploading,
        loadingImageVarnum,
        varnum,
        deleteVariant,
        index,
        type,
        result,
        toggleResult,
        percent,
        comment,
        updateComment,
        blurComment,
        addKeyword,
        deleteKeyword,
        name,
        showWarning,
        count,
        addVoteHandler,
        votingNumber
      },
      ref
    ) => {
      return (
        <div ref={ref} className={styles['variant']}>
          <PictureLoaderContainer
            type={PICTURE_LOADER_TYPES.showModal}
            style={{
              width: 60,
              height: 66,
              marginRight: 10,
              backgroundPosition: '50% 50%'
            }}
            picture={var_img_url}
            changeHandler={changePicture}
            deleteHandler={deleteHandler}
            isImageUploading={isImageUploading && loadingImageVarnum === varnum}
          />
          <div>
            <Button
              startIcon={<Icon size={14} fontName="fal fa-times" />}
              onlyIcon
              color="#524e4e"
              variant="message-action"
              style={{
                // position: 'absolute',
                // top: 0,
                // left: 0,
                marginTop: 5,
                marginBottom: -5,
                border: '1px solid #524e4e',
                borderRadius: '50%',
                padding: '2px 6px',
                zIndex: 1
              }}
              clickHandler={deleteVariant}
            />
            <div className={styles['varnum']}>{index + 1}</div>
          </div>
          <div className="flex-100">
            <div className="d-flex">
              {type === INTERACTIVES.quiz && (
                <SvgCheckbox
                  isChecked={result === varnum}
                  changeHandler={toggleResult}
                  style={{
                    color: '#6f6f6f',
                    fontSize: 16,
                    opacity: 0.75,
                    marginRight: 15
                  }}
                  cbStyle={{}}
                />
              )}
              <ProgressBarView percent={percent} />
            </div>
            <div className="d-flex">
              <div className="flex-100">
                <TextField
                  isMinimized={false}
                  preIconActivity={false}
                  value={comment}
                  changeHandler={(event) => updateComment(event.target.value)}
                  blurHandler={(event) => blurComment(event.target.value)}
                  inputHeight="30px"
                  inputFontSize="14px"
                  style={{
                    marginTop: 24
                  }}
                  label={window.t('commentLabel')}
                />
                <TagsInput
                  id="keywords"
                  // label='Ключевые слова:'
                  add={addKeyword}
                  remove={deleteKeyword}
                  tags={name}
                  styles={{ marginTop: 10 }}
                  onRepeat={showWarning}>
                  {votingNumber && <Tag name={votingNumber} />}
                </TagsInput>
              </div>
              <CounterView
                type={type}
                label={type === INTERACTIVES.quiz ? window.t('answers') : window.t('votes')}
                varnum={varnum}
                count={count}
                percent={percent}
                addVote={addVoteHandler}
              />
            </div>
          </div>
        </div>
      );
    }
  )
);
