import { INTERACTIVES, INTERACTIVE_ACTIVITIES } from '../constants';
import { addSnack } from './snackBox';

import { updateInfoField } from 'store/actions/isettings';
import { setSessionActivity } from 'store/actions/sessions';
import { broadcastActions } from 'store/slices/broadcastSlice';

import {
  addBroadcastMessage,
  addMessageToTitre,
  clearTitre as clearTitreApi,
  deleteAllBroadcastMessages,
  deleteBroadcastMessage,
  deleteMessageFromTitre,
  editBroadcastMessage,
  getActiveBroadcast,
  getBroadcastMessageInfo,
  getBroadcastMessages,
  getBroadcastNewMessages,
  getInteractiveById,
  restoreBroadcastMessage,
  setBroadcastMessageFavoriteState as setBroadcastMessageFavoriteStateApi,
  startBroadcast as startBroadcastApi,
  stopBroadcast as stopBroadcastApi
} from 'api/dataLayer';

import { clearAndParseJSON } from 'helpers/text';

export function fetchBroadcastMessages(broadcastId, isReverse = false) {
  return async (dispatch) => {
    dispatch(broadcastActions.setBroadcastId({ id: broadcastId }));
    try {
      const response = await getBroadcastMessages(broadcastId);
      let messages = response.body || [];

      messages = messages.map((el) => ({
        ...el,
        labels: Array.isArray(el.labels) ? el.labels.filter((elm) => elm !== '') : []
      }));
      dispatch(
        broadcastActions.fetchBroadcastMessagesSuccess({
          messages: isReverse ? messages.reverse() : messages
        })
      );
    } catch (ex) {
      // dispatch(broadcastActions.fetchBroadcastMessagesError());
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errBroadcastMessagesLoading').replace(/#([\s\w]+)#/g, broadcastId)
        })
      );
    }
  };
}

export function fetchMoreBroadcastMessages(broadcastId) {
  return async (dispatch, getState) => {
    try {
      const { messages } = getState().broadcast;
      const { isMessagesReversed } = getState().vote;

      const lastMsgId =
        !Array.isArray(messages) || messages?.length === 0
          ? 0
          : isMessagesReversed
          ? messages[0].id
          : messages[messages.length - 1].id;

      const response = await getBroadcastNewMessages({
        id: broadcastId,
        lastMsgId
      });

      let newMessages = response.body || [];
      if (newMessages.length === 0) return;

      newMessages = newMessages.map((el) => ({
        ...el,
        labels: Array.isArray(el.labels) ? el.labels.filter((elm) => elm !== '') : []
      }));
      const updatedMessages = isMessagesReversed
        ? [...newMessages.reverse(), ...messages]
        : [...messages, ...newMessages];
      dispatch(
        broadcastActions.fetchBroadcastMessagesSuccess({
          messages: updatedMessages
        })
      );
    } catch (ex) {
      // dispatch(broadcastActions.fetchBroadcastMessagesError());
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errBroadcastMessagesLoading').replace(/#([\s\w]+)#/g, broadcastId)
        })
      );
    }
  };
}

export function addSelectedMessages() {
  return async (dispatch, getState) => {
    const { currentTab, ...feedState } = getState().feed;
    const items = feedState[currentTab]?.items;
    const selectedItems = feedState[currentTab]?.selectedItems;
    const messagesToAdd = items?.filter((el) => selectedItems.includes(el.id));
    for (let message of messagesToAdd) {
      await dispatch(addMessageToBroadcast(message));
    }
  };
}

export function addDraggingMessage() {
  return async (dispatch, getState) => {
    const { currentTab, ...feedState } = getState().feed;
    const messageFeedState = feedState[currentTab];
    const draggingMessageId = messageFeedState?.draggingMessageId;
    const items = messageFeedState?.items;
    let message = items?.find((el) => el.id === draggingMessageId);
    // перетаскивание новости в трансляцию
    if (currentTab === 'news') {
      dispatch(addNewsToBroadcast(message));
      return;
    }
    dispatch(addMessageToBroadcast(message));
  };
}

export function addSocketMessage(interactiveId, msgId) {
  return async (dispatch, getState) => {
    const { createdMessages } = getState().messageCreator;
    const { info } = getState().isettings;

    if (info.id != interactiveId && info.broadcastId !== interactiveId) return;

    if (createdMessages.includes(msgId)) return;

    try {
      const res = await getBroadcastMessageInfo(msgId);
      const msg = res.body[0];

      dispatch(broadcastActions.addMessageToBroadcastSuccess({ msg }));
    } catch (ex) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errRetrievingMessageInfo').replace(/#([\s\w]+)#/g, msgId)
        })
      );
    }
  };
}

export function addMessageToBroadcast(msg) {
  return async (dispatch, getState) => {
    try {
      const { isAutoSend } = getState().settings.broadcastConfig;
      const { broadcastId } = getState().broadcast;
      let msgCopy = {
        ...msg,
        broadcast_id: broadcastId,
        status: []
      };
      const res = await addBroadcastMessage(msgCopy);
      msgCopy = {
        ...msgCopy,
        msg_id: msg.id,
        id: res.body
      };
      dispatch(broadcastActions.addMessageToBroadcastSuccess({ msg: msgCopy }));
      if (isAutoSend) dispatch(exportToTitr(msgCopy.id, msgCopy.msg_id, 'F1', broadcastId)); // export to left
    } catch (ex) {
      console.error(ex);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errAddingMessageToBroadcast')
        })
      );
    }
  };
}
// добавление новости в трансляцию по кнопке
export function addNewsToBroadcast(msg) {
  return async (dispatch, getState) => {
    try {
      const { auto_withdrawal = '' } = getState().isettings.info;
      const { broadcastId } = getState().broadcast;

      const userInfoStr = localStorage.getItem('userInfo');
      const userInfo = userInfoStr ? JSON.parse(userInfoStr) : {};

      let news = {
        attachments: msg.attachments || [],
        author: msg.rss_name || '',
        channel: 'rssNews',
        content: `${msg.title?.length > 0 ? msg.title : ''}. ${
          msg.description?.length > 0 ? msg.description : ''
        }`,
        date: msg.created_in_DB || '',
        favorite: msg.favorite || '',
        id: msg.id,
        image: msg.rss_image || '',
        is_created: msg.is_created || '',
        labels: msg.labels || '',
        region: msg.region || '',
        senderNumber: userInfo?.clientNum || '',
        broadcast_id: broadcastId,
        status: []
      };

      const res = await addBroadcastMessage(news);
      news = {
        ...news,
        msg_id: msg.id,
        id: res.body
      };
      dispatch(broadcastActions.addMessageToBroadcastSuccess({ msg: news }));

      const isWithdrawal = Boolean(Number(auto_withdrawal));
      if (isWithdrawal) dispatch(exportToTitr(news.id, news.msg_id, 'F1', broadcastId)); // export to left
    } catch (ex) {
      console.error(ex);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errAddingNewsToBroadcast')
        })
      );
    }
  };
}

export function exportToTitr(id, messageFeedId, key, broadcastId = null) {
  return async (dispatch, getState) => {
    const info = getState().isettings.info;
    broadcastId = broadcastId === null ? info.interactive_id : broadcastId;
    try {
      // const interactiveId = broadcastId ? broadcastId : info.id;
      await addMessageToTitre({
        id,
        interactiveId: broadcastId,
        messageFeedId,
        key
      });
      dispatch(
        broadcastActions.exportMessageSuccess({
          id,
          messageFeedId,
          exportSideKey: key,
          interactiveId: broadcastId
        })
      );
    } catch (ex) {
      console.error(ex);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errAddingMessageToTitre')
        })
      );
    }
  };
}

export function delFromTitr(id, messageFeedId, key) {
  return async (dispatch) => {
    try {
      await deleteMessageFromTitre({ id, key, messageFeedId });
      dispatch(
        broadcastActions.delFromTitrSuccess({
          id,
          messageFeedId,
          exportSideKey: key
        })
      );
    } catch (ex) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errDeletingMessageFromTitre')
        })
      );
    }
  };
}

export function clearTitre(titreTag, broadcastId) {
  return async (dispatch, getState) => {
    const id = getState().broadcast.activeBroadcastData?.id || getState().broadcast.broadcastId;
    try {
      await clearTitreApi(titreTag, broadcastId);
      if (broadcastId === id) {
        dispatch(broadcastActions.clearTitreSuccess({ titreTag, broadcastId }));
      }
      dispatch(
        addSnack({
          type: 'success',
          message: window.t('sccTitreCleared')
        })
      );
    } catch (ex) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errTitreClear')
        })
      );
    }
  };
}

export function changeBroadcastText(id) {
  return async (dispatch, getState) => {
    try {
      const message = getState().broadcast.messages.find((el) => el.id === id);
      await editBroadcastMessage(message);
    } catch (ex) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errEditingMessage')
        })
      );
    }
  };
}

export function updateRemoveBroadcastMessage(id, request) {
  return async (dispatch, getState) => {
    try {
      const { broadcastId, messages } = getState().broadcast;
      const message = messages.find((el) => el.id === id);
      if (Array.isArray(message.titr_tag) && message.titr_tag.length > 0) {
        dispatch(
          addSnack({
            type: 'danger',
            message: window.t('errCantDeleteMessage')
          })
        );
        return;
      }

      await request?.({ id, broadcast_id: broadcastId });
      dispatch(broadcastActions.removeBroadcastMessageSuccess({ id }));
    } catch (ex) {
      console.error(ex);
    }
  };
}

export function removeBroadcastMessage(id) {
  return updateRemoveBroadcastMessage(id, deleteBroadcastMessage);
}

export function restoreMessage(id) {
  return async (dispatch, getState) => {
    try {
      const { broadcastId } = getState().broadcast;
      const res = await restoreBroadcastMessage({
        id,
        broadcast_id: broadcastId
      });
      dispatch(broadcastActions.updateMessageText({ id, text: res.body }));
    } catch (ex) {
      console.error(ex);
    }
  };
}

export function setFavoriteState(id, value) {
  return async (dispatch, getState) => {
    try {
      // getcurrent broadcast id
      const { broadcastId } = getState().broadcast;
      await setBroadcastMessageFavoriteStateApi({
        id,
        favorite: +value,
        broadcast_id: broadcastId
      });
      dispatch(broadcastActions.setBroadcastMessageFavoriteState({ id, value }));
      // if (value === false)
      // dispatch(executeFilterMessages());
    } catch (ex) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errMessageToFavorites')
        })
      );
      console.error(ex);
    }
  };
}
export function fetchActiveBroadcast() {
  return async (dispatch, getState) => {
    try {
      const res = await getActiveBroadcast();
      const data = res.body[0];

      if (!data) {
        return;
      }

      const activeMessages = data.messages.filter((el) => el.is_deleted === '0');
      data.messagesInTitr = activeMessages.map((el) => {
        const msg = JSON.parse(el.message);
        return {
          titr_tag: el.titr_tag,
          id: msg.msg_id,
          broadcastMessageId: el.broadcasts_messages,
          broadcast_id: el.iactive_id
        };
      });
      data.settings = clearAndParseJSON(data.settings);
      // broadcastMessageId: "301"
      // id: "485"
      // titr_tag: "F1"

      dispatch(broadcastActions.setActiveBroadcastData({ data }));
    } catch (ex) {
      console.error(ex);
    }
  };
}

export function updateStartBroadcast(broadcastId, infoField = 'activity', request, settings) {
  return async (dispatch, getState) => {
    try {
      await request?.(broadcastId);
      const broadcast = getState().broadcast;
      const { info } = getState().isettings;

      const messagesInTitr = broadcast.messages.flatMap((el) => {
        if (Array.isArray(el.titr_tag)) {
          const arr = [];
          el.titr_tag.forEach((elm) => {
            arr.push({
              broadcastMessageId: el.id,
              id: el.msg_id,
              titr_tag: elm,
              broadcast_id: el.iactive_id
            });
          });
          return arr;
        } else
          return {
            broadcastMessageId: el.id,
            id: el.msg_id,
            titr_tag: el.titr_tag,
            broadcast_id: el.iactive_id
          };
      });

      if (info?.id === broadcastId && info?.type === 'broadcast') {
        dispatch(updateInfoField('activity', INTERACTIVE_ACTIVITIES.active));
      } else {
        dispatch(updateInfoField('activity', INTERACTIVE_ACTIVITIES.finished));
      }

      if (info?.broadcastId === broadcastId) {
        dispatch(updateInfoField('broadcastActivity', INTERACTIVE_ACTIVITIES.active));
      } else {
        dispatch(updateInfoField('broadcastActivity', INTERACTIVE_ACTIVITIES.finished));
      }

      dispatch(setSessionActivity(broadcastId, INTERACTIVE_ACTIVITIES.active));
      const response = await getInteractiveById({
        id: broadcastId,
        type: 'broadcast'
      });
      if (response.body === 'not_found' && response.code === 404) {
        throw new Error(response);
      } else {
        const result = response.body[0];
        dispatch(
          broadcastActions.setActiveBroadcastData({
            data: {
              ...result,
              id: broadcastId,
              settings: settings || info?.settings,
              messagesInTitr
            }
          })
        );
      }
    } catch (ex) {
      console.error(ex);
    }
  };
}

export function startBroadcast(broadcastId, infoField = 'activity') {
  return async (dispatch, getState) => {
    // TODO
    // dispatch(updateStartBroadcast(broadcastId, undefined, undefined, settings))
    return dispatch(updateStartBroadcast(broadcastId, infoField, startBroadcastApi));
  };
}

export function updateStopBroadcast(broadcastId, infoField = 'activity', request) {
  return async (dispatch, getState) => {
    try {
      const { info } = getState().isettings;
      await request?.(broadcastId);
      if (info?.type === 'broadcast') {
        dispatch(updateInfoField('activity', INTERACTIVE_ACTIVITIES.finished));
      }
      if (info?.broadcastId === broadcastId) {
        dispatch(updateInfoField('broadcastActivity', INTERACTIVE_ACTIVITIES.finished));
      }
      dispatch(setSessionActivity(broadcastId, INTERACTIVE_ACTIVITIES.finished));
      dispatch(broadcastActions.setActiveBroadcastData({ data: null }));
    } catch (ex) {
      console.error(ex);
    }
  };
}

export function stopBroadcast(broadcastId, infoField = 'activity') {
  return updateStopBroadcast(broadcastId, infoField, stopBroadcastApi);
}

export function exportFromFeed(msg, key) {
  return async (dispatch, getState) => {
    try {
      const { broadcastId, activeBroadcastData } = getState().broadcast;
      const { id } = activeBroadcastData;

      const isIt = await isItQuizBroadcastAndAlreadyHasMessage(getState, msg);

      if (isIt) {
        dispatch(
          addSnack({
            type: 'danger',
            message: window.t('errQuizMessageRepeat')
          })
        );
        return;
      }

      let msgCopy = {
        ...msg,
        broadcast_id: id || broadcastId,
        status: []
      };

      const res = await addBroadcastMessage(msgCopy);
      msgCopy = {
        ...msgCopy,
        msg_id: msg.id,
        id: res.body
      };
      dispatch(exportToTitr(msgCopy.id, msgCopy.msg_id, key, id)); // export to left
      // если открытая трансляция не активна, не добавлять сообщение
      if (broadcastId && id !== broadcastId) return;
      dispatch(broadcastActions.addMessageToBroadcastSuccess({ msg: msgCopy }));
      // exportToTitr(id, messageFeedId, key)
    } catch (ex) {
      console.error(ex);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errAddingMessageToBroadcast')
        })
      );
    }
  };
}

export function removeExportedFromFeed(messageFeedId, key, broadcastMessageId = null) {
  return async (dispatch, getState) => {
    try {
      const { messagesInTitr, id: broadcastId } = getState().broadcast.activeBroadcastData;
      const target = messagesInTitr.find((el) => el.id === messageFeedId);
      const id = broadcastMessageId || target?.broadcastMessageId;
      await deleteMessageFromTitre({ id, key, messageFeedId });
      dispatch(
        broadcastActions.delFromTitrSuccess({
          id,
          messageFeedId,
          exportSideKey: key,
          broadcastId
        })
      );
    } catch (ex) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errDeletingMessageFromTitre')
        })
      );
    }
  };
}

function isItQuizBroadcastAndAlreadyHasMessage(getState, newMsg) {
  return new Promise(async (resolve, rej) => {
    const { activeBroadcastData, messages } = getState().broadcast;
    const { info } = getState().isettings;

    if (
      (info.type === INTERACTIVES.broadcast && info.binded_interactive === INTERACTIVES.quiz) ||
      info.type === INTERACTIVES.quiz
    ) {
      const index = messages.findIndex(
        (msg) =>
          msg.channel === newMsg.channel &&
          msg.senderNumber === newMsg.senderNumber &&
          msg.author === newMsg.author &&
          msg.content === newMsg.content
      );
      return resolve(index !== -1);
    }

    if (activeBroadcastData && activeBroadcastData.binded_interactive === INTERACTIVES.quiz)
      try {
        const response = await getBroadcastMessages(activeBroadcastData.id);
        let messages = response.body || [];
        const index = messages.findIndex(
          (msg) =>
            msg.channel === newMsg.channel &&
            msg.senderNumber === newMsg.senderNumber &&
            msg.author === newMsg.author &&
            msg.content === newMsg.content
        );
        return resolve(index !== -1);
      } catch (ex) {
        return resolve(false);
      }

    return resolve(false);
  });
}

export function removeAllBroadcastMessages() {
  return async (dispatch, getState) => {
    try {
      const { broadcastId } = getState().broadcast;
      dispatch(clearTitre(null, broadcastId));
      await deleteAllBroadcastMessages({ broadcast_id: broadcastId });
      dispatch(broadcastActions.fetchBroadcastMessagesSuccess({ messages: [] }));
    } catch (ex) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errorClearingTitre')
        })
      );
    }
  };
}

export function exportMessageSuccessThunk(id, messageFeedId, exportSideKey, interactiveId) {
  return async (dispatch, getState) => {
    const broadcast = getState().broadcast;
    if (
      interactiveId === broadcast.broadcastId &&
      broadcast.messages.every((msg) => msg.id !== id)
    ) {
      const res = await getBroadcastMessageInfo(id);
      const msg = res.body[0];
      dispatch(broadcastActions.addMessageToBroadcastSuccess({ msg }));
    }
    dispatch(
      broadcastActions.exportMessageSuccess({
        id,
        messageFeedId,
        exportSideKey,
        interactiveId
      })
    );
  };
}
