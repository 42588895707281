import { InteractiveSettingsContainer } from 'containers';

import { Select, TagsInput } from 'components';

import { INTERACTIVES } from 'store/constants';

import styles from './AutoReplySettings.module.css';

export function AutoReplySettings({
  startWords,
  addStartWordValue,
  deleteStartWordValue,
  autoReplyId,
  toggleSettings,
  selectedEvent,
  changeAutoreplayEvent,
  eventOptions,
  startGuide,
  setStepIndex,
  interactiveInfoRef,
  selectControlsRef,
  okButtonRef,
  chooseTypeRef,
  chooseAnotherTypeRef
}) {
  const showWarning = () => {};
  return (
    <InteractiveSettingsContainer
      extended={false}
      // withGuide={false}
      startGuide={startGuide}
      setStepIndex={setStepIndex}
      interactiveInfoRef={interactiveInfoRef}
      okButtonRef={okButtonRef}
      type={INTERACTIVES.autoReply}
      interactiveId={autoReplyId}
      onCloseHandler={toggleSettings}>
      <div className={styles.configuration}>
        <div className={styles.configuration__control} ref={selectControlsRef}>
          <div>{window.t('event')}:</div>
          <Select
            chooseAnotherTypeRef={chooseAnotherTypeRef}
            chooseTypeRef={chooseTypeRef}
            setStepIndex={setStepIndex}
            label={window.t('pickAnEvent')}
            options={eventOptions}
            selectedOption={selectedEvent}
            setSelectedOption={changeAutoreplayEvent}
          />
        </div>
        {selectedEvent?.value === 'keyWord' && (
          <TagsInput
            className={styles.words}
            withEmoji
            withEdit
            isMultiWord
            id="startWords"
            add={addStartWordValue}
            remove={deleteStartWordValue}
            tags={startWords}
            onRepeat={showWarning}
          />
        )}
      </div>
    </InteractiveSettingsContainer>
  );
}
