import AddGroupCardModal from '../AddGroupCardModal/AddGroupCardModal';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  Avatar,
  BlurWrapper,
  Button,
  Icon,
  Input,
  List,
  ListItem,
  ListItemText,
  Portal,
  SvgCheckbox,
  Text,
  Title
} from 'components';

import { useAsyncEffect } from 'hooks';
import useFetch from 'hooks/useFetch';

import { deleteOkGroup, getOkGroups, toggleCheckboxState } from 'api/dataLayer';

import { getImageByChannel } from 'helpers/image';

import styles from '../SocialSettings.module.css';

const propTypes = {
  setDeleteCardData: PropTypes.func.isRequired,
  setIsDeleteCardActive: PropTypes.func.isRequired,
  setIsOkConnected: PropTypes.func.isRequired
};

const defaultProps = {
  setDeleteCardData: () => {},
  setIsDeleteCardActive: () => {},
  setIsOkConnected: () => {}
};

const INVALID_TOKEN = 'GRAPH_PARAM_ACCESS_TOKEN : Invalid access token.';
const ALREADY_ADDED = 'Already added.';
const SESSION_EXPIRED = 'PARAM_SESSION_EXPIRED : Session expired.';
const NO_CHATS = 'The group has no chats';

function OkCollapseBody({ setDeleteCardData, setIsDeleteCardActive, setIsOkConnected }) {
  const [groups, setGroups] = useState([]);
  const [isModalActive, setIsModalActive] = useState(false);
  const [token, setToken] = useState('');
  const [inputError, setInputError] = useState('');
  const [
    { response: responseSubscribe, isLoading: isLoadingSubscribe, error: errorSubscribe },
    doFetchSubscribe
  ] = useFetch('api_toggle_ok_subscribe');

  useAsyncEffect(async () => {
    try {
      const res = await getOkGroups();
      const groups = res?.body;

      if (!groups) return;

      setGroups(Object.values(groups));
    } catch (ex) {
      console.error(ex);
    }
  }, []);

  useEffect(() => {
    setIsOkConnected(groups.length > 0);
  }, [groups]);

  const checkboxHandler = async (groupId, key, isChecked) => {
    try {
      await toggleCheckboxState({
        groupId,
        key,
        channel: 'ok',
        isChecked: Number(isChecked)
      });
      setGroups((groups) =>
        groups.map((el) => (el.groupId === groupId ? { ...el, [key]: isChecked } : el))
      );
    } catch (ex) {
      console.error(ex);
    }
  };

  const deleteRequest = async ({ groupId, isComment, isMessage }) => {
    try {
      await deleteOkGroup({ groupId, isComment, isMessage });
      setGroups((groups) => groups.filter((el) => el.groupId !== groupId));
      setIsDeleteCardActive(false);
    } catch (ex) {
      console.error(ex);
    }
  };

  const deleteHandler = (groupId) => {
    const groupData = groups.find((el) => el.groupId === groupId);
    groupData.deleteRequest = deleteRequest;
    groupData.removeMessage = window.t('realyWantToDeleteGroup');
    // для принудительного обновления стейта в DeleteCardModal
    groupData.random = Math.random();

    setDeleteCardData(groupData);
    setIsDeleteCardActive(true);
  };

  const subscribeHandler = () => {
    if (token.length === 0) {
      setInputError(window.t('requiredField'));
      return;
    }

    doFetchSubscribe({
      method: 'POST',
      postBody: {
        groupToken: token
      }
    });
  };

  useEffect(() => {
    if (!responseSubscribe) return;

    const getErrorKey = (error) => {
      switch (error) {
        case INVALID_TOKEN:
          return 'incorrectGroupToken';
        case ALREADY_ADDED:
          return 'groupAlreadyAdded';
        case SESSION_EXPIRED:
          return 'tokenSessionExpired';
        case NO_CHATS:
          return 'okNoChatError';
        default:
          return 'incorrectGroupToken';
      }
    };

    if (typeof responseSubscribe === 'object' && responseSubscribe.groupId) {
      setIsModalActive(false);
      setToken('');
      setGroups((groups) => [...groups, responseSubscribe]);
    } else {
      setInputError(window.t(getErrorKey(responseSubscribe)));
    }
  }, [responseSubscribe]);

  useEffect(() => {
    if (!errorSubscribe) return;
  }, [errorSubscribe]);

  const closeModal = () => {
    setToken('');
    setInputError('');
    setIsModalActive(false);
  };

  return (
    <div style={{ padding: '10px 0' }}>
      <Portal nodeId="work-pane-modals">
        <BlurWrapper
          isOpened={isModalActive}
          clickHandler={closeModal}
          position="absolute"
          background="#5a5a5a2e"
          className="d-flex justify-center align-center">
          <AddGroupCardModal
            title={window.t('connectNewOkGroup')}
            closeHandler={closeModal}
            headerColor="#ee8208">
            <Input
              type="text"
              value={token}
              label={`${window.t('token')}:`}
              placeholder={window.t('enterOkGroupToken')}
              changeHandler={(evt) => {
                const value = evt.target.value;
                setToken(value);
                const error = value.length === 0 ? window.t('requiredField') : '';
                setInputError(error);
              }}
              className="mb-4"
              error={inputError}
            />
            <div className="d-flex">
              <div className="d-flex align-self-baseline mr-1">
                <Icon size={16} color="#CDCDCD" fontName="fal fa-question-circle" />
                <Text className="ml-2 ml-1" size={12}>
                  Подробная инструкция получения токена доступна по{' '}
                  <a href="https://apiok.ru/dev/graph_api/bot_api" target="_blank" rel="noreferrer">
                    ссылке
                  </a>
                </Text>
              </div>
              <Button
                className="ml-auto flex-shrink-0 align-self-center"
                style={{
                  padding: '8px 20px'
                }}
                clickHandler={subscribeHandler}
                isDisabled={isLoadingSubscribe}>
                {window.t('connect')}
              </Button>
            </div>
          </AddGroupCardModal>
        </BlurWrapper>
      </Portal>

      <Title className="d-inline-block" weight="normal" style={{ marginLeft: 50 }}>
        {window.t('accounts')}
      </Title>
      <Text tag="span" color="#808080" size={14}>
        {` ${groups.length}`}
      </Text>
      <List noStyle style={{ marginLeft: 50 }}>
        {groups.map(({ groupId, groupName, isComment, isMessage, photo, screenName }) => (
          <ListItem key={groupId} noHover className="ml-0 mr-0 mt-2 mb-0 pa-0">
            <a
              className={`d-flex ${styles['group-name']}`}
              style={{ color: 'inherit' }}
              href={`https://ok.ru/${screenName}`}
              target="_blank"
              rel="noreferrer">
              <Avatar
                src={photo || getImageByChannel('ok')}
                size={30}
                className="flex-shrink-0 mr-2 align-self-center"
              />
              <ListItemText primary={groupName} />
            </a>
            <Button
              onlyIcon
              startIcon={<Icon size={14} color="#CDCDCD" fontName="far fa-times" />}
              variant="icon"
              hoverColor="transparent"
              style={{
                opacity: 1
              }}
              clickHandler={() => deleteHandler(groupId)}
            />
            <div className="d-flex ml-auto">
              {isComment !== null && isComment !== undefined && (
                <SvgCheckbox
                  isChecked={Boolean(+isComment)}
                  changeHandler={(isChecked) => checkboxHandler(groupId, 'isComment', isChecked)}
                  style={{
                    color: '#808080',
                    fontSize: 14,
                    marginLeft: 10
                  }}>
                  {window.t('comments')}
                </SvgCheckbox>
              )}
              {isMessage !== null && isMessage !== undefined && (
                <SvgCheckbox
                  isChecked={Boolean(+isMessage)}
                  changeHandler={(isChecked) => checkboxHandler(groupId, 'isMessage', isChecked)}
                  style={{
                    color: '#808080',
                    fontSize: 14,
                    marginLeft: 10
                  }}>
                  {window.t('messages')}
                </SvgCheckbox>
              )}
            </div>
          </ListItem>
        ))}
      </List>

      <Button
        clickHandler={() => setIsModalActive(true)}
        color="#fcfcfc"
        textColor="#757575"
        variant="google"
        size="medium"
        className="ml-auto mr-auto mt-5 mb-5"
        startIcon={<Icon icon="ok" color="#FF9800" width={14} height={20} />}>
        {window.t('connectNewGroup')}
      </Button>
    </div>
  );
}

export default OkCollapseBody;

OkCollapseBody.propTypes = propTypes;
OkCollapseBody.defaultProps = defaultProps;
