import ChatBody from './ChatBody/ChatBody';
import ChatFooter from './ChatFooter/ChatFooter';
import ChatHeader from './ChatHeader/ChatHeader';
import CurrentDate from './CurrentDate/CurrentDate';
import ToolbarControls from './ToolbarControls/ToolbarControls';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { SessionModuleView, Toolbar } from 'components';
import Media from 'components/Media/Media';

import { useLocalStorage } from 'hooks';

import { getRouteInfo } from 'helpers/profile';
import { goTo } from 'helpers/routing';

import { useChat } from './hooks/useChat';

import styles from './Chat.module.css';

const propTypes = {
  isActivityLoading: PropTypes.bool.isRequired,
  profileInfo: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired
};

const defaultProps = {
  isActivityLoading: false,
  profileInfo: {},
  userInfo: {}
};

export function Chat({ match }) {
  const [userInformation] = useLocalStorage('userInfo');
  const [hasMedia, setHasMedia] = useState(false);
  const { senderNumber, channel } = getRouteInfo(match.params.id);
  const history = useHistory();
  const location = useLocation();

  if (
    !userInformation.messagers.some((messager) => {
      const [channelName, isActive] = Object.entries(messager)[0];
      return isActive && channelName === channel;
    })
  ) {
    goTo(history, `/profile/${channel}_${senderNumber}`, null, location);
  }

  const {
    routeInfo,
    userInfo,
    author,
    messages,
    setMessages,
    loadMoreNext,
    hasMoreNext,
    isLoadingNext,
    loadMorePrevious,
    hasMorePrevious,
    isLoadingPrevious,
    isSendingMessage
  } = useChat(match);

  return (
    <SessionModuleView style={{ overflow: 'hidden' }}>
      <Toolbar
        // path="/"
        title="">
        <ToolbarControls routeInfo={routeInfo} author={author} />
      </Toolbar>
      <div style={{ height: '100%' }}>
        <div className={styles['main-chat']}>
          <CurrentDate />
          <ChatHeader author={author} userInfo={userInfo} routeInfo={routeInfo} messages={messages}>
            <Media
              setHasMedia={setHasMedia}
              userInfo={userInfo}
              routeId={match.params.id}
              channel={routeInfo.channel}
            />
          </ChatHeader>

          <ChatBody
            userInfo={userInfo}
            withMedia={hasMedia}
            author={author}
            routeInfo={routeInfo}
            messages={messages}
            loadMoreNext={loadMoreNext}
            hasMoreNext={hasMoreNext}
            isLoadingNext={isLoadingNext}
            loadMorePrevious={loadMorePrevious}
            hasMorePrevious={hasMorePrevious}
            isLoadingPrevious={isLoadingPrevious}
            isSendingMessage={isSendingMessage}
          />
          <ChatFooter
            hasMoreNext={hasMoreNext}
            setMessages={setMessages}
            senderNumber={senderNumber}
            channel={channel}
            messageId={messages?.reduce((res, el) => el.ID || res, null)}
          />
        </div>
      </div>
    </SessionModuleView>
  );
}

Chat.propTypes = propTypes;
Chat.defaultProps = defaultProps;
