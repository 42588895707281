import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Icon, Skeleton } from 'components';

import { getUIDateFromDBDate } from 'helpers/dateTime';

import styles from './ProfileInfoWrapper.module.css';

function ProfileInfoWrapper({
  routeInfo,
  userInfo,
  isLoadingUserInfo,
  isLoadingMessages,
  messages
}) {
  const { senderNumber, channel } = routeInfo || {};
  const { region, firstMessage, lastMessage, userName } = userInfo || {};

  const getAuthor = () => {
    if (messages && messages.length > 0)
      return messages[0].isCreated === '1' ? window.t('createdAuthor') : messages[0].author;
    return null;
  };
  const author = getAuthor();
  function ProfileInfo() {
    return (
      <div className={styles['profile-card__info-wrapper']}>
        <span className={styles['profile-card__info-name']}>
          {author || !isLoadingMessages ? (
            author
          ) : (
            <Skeleton width={185} height={23} borderRadius={12} />
          )}
        </span>
        {region && (
          <span className={styles['profile-card__info-location']}>
            <Icon size={14} fontName="fa fa-map-marker-alt" />
            <span> {region}</span>
          </span>
        )}
      </div>
    );
  }

  function ProfilePhone() {
    return (
      <div className={`${styles['profile-card__info-wrapper']} ${styles['phone-wrapper']}`}>
        <Icon fontName="fas fa-mobile-alt" />
        <span className={styles['profile-card__info-phone']}>
          {channel?.includes('telegram') ? userName : senderNumber}
        </span>
      </div>
    );
  }

  function ProfileActivity() {
    const fclasses = classNames(
      styles['profile-card__info-desc'],
      styles['profile-card__activity-first'],
      {
        [styles['string-loader']]: isLoadingUserInfo
      }
    );
    const lclasses = classNames(
      styles['profile-card__info-desc'],
      styles['profile-card__activity-last'],
      {
        [styles['string-loader']]: isLoadingUserInfo
      }
    );
    return (
      <div className={styles['profile-card__activity']}>
        <div>
          <p className={styles['profile-card__info-header']}>{window.t('firstMessage')}</p>
          <p className={fclasses}>{getUIDateFromDBDate(firstMessage)}</p>
        </div>
        <div>
          <p className={styles['profile-card__info-header']}>{window.t('lastMessage')}</p>
          <p className={lclasses}>{getUIDateFromDBDate(lastMessage)}</p>
        </div>
      </div>
    );
  }

  return (
    <div className={styles['profile-card__info']}>
      <ProfileInfo />
      <ProfilePhone />
      <ProfileActivity />
    </div>
  );
}

export default ProfileInfoWrapper;

ProfileInfoWrapper.propTypes = {
  profileInfo: PropTypes.object,
  userInfo: PropTypes.object,
  isActivityLoading: PropTypes.bool
};
