import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import {
  BigPlayButton,
  ControlBar,
  CurrentTimeDisplay,
  DurationDisplay,
  LoadingSpinner,
  PlayToggle,
  Player,
  ProgressControl,
  TimeDivider,
  VolumeMenuButton
} from 'video-react';

import 'video-react/dist/video-react.css';

const propTypes = {
  src: PropTypes.string,
  expandHandler: PropTypes.func,
  compressHandler: PropTypes.func,
  isExpanded: PropTypes.bool,
  isMini: PropTypes.bool
};

const defaultProps = {
  src: '',
  expandHandler: () => {},
  compressHandler: () => {},
  isExpanded: false,
  isMini: false
};

function VideoPlayer({ src, expandHandler, compressHandler, isExpanded, isMini, height }) {
  const [state, setState] = useState({});
  const playerRef = useRef();

  useEffect(() => {
    playerRef.current?.subscribeToStateChange(setState);
  }, []);

  const expandStopHandler = () => {
    playerRef.current.pause();
    expandHandler();
  };

  return (
    <Player ref={playerRef} height="100%" {...(height && { height, fluid: false })} src={src}>
      <BigPlayButton position="center" />
      <LoadingSpinner />
      {!state?.hasStarted && !isExpanded && (
        <Button
          startIcon={
            <Icon size={16} fontName={`far fa-${isExpanded ? 'compress' : 'expand'}-wide`} />
          }
          style={{
            position: 'absolute',
            bottom: '0px',
            right: '0px',
            zIndex: '1',
            background: 'black',
            borderRadius: '.5rem 0'
          }}
          onlyIcon
          color="#524e4e"
          variant="message-action"
          clickHandler={isExpanded ? compressHandler : expandStopHandler}
        />
      )}
      {isMini ? (
        <ControlBar disableDefaultControls>
          <PlayToggle />
          <Button
            startIcon={
              <Icon size={16} fontName={`far fa-${isExpanded ? 'compress' : 'expand'}-wide`} />
            }
            onlyIcon
            color="#524e4e"
            variant="message-action"
            clickHandler={isExpanded ? compressHandler : expandStopHandler}
          />
        </ControlBar>
      ) : (
        <ControlBar disableDefaultControls>
          <PlayToggle />
          <VolumeMenuButton />
          <CurrentTimeDisplay />
          <TimeDivider />
          <DurationDisplay />
          <ProgressControl />
          <Button
            startIcon={
              <Icon size={16} fontName={`far fa-${isExpanded ? 'compress' : 'expand'}-wide`} />
            }
            onlyIcon
            color="#524e4e"
            variant="message-action"
            clickHandler={isExpanded ? compressHandler : expandStopHandler}
          />
        </ControlBar>
      )}
    </Player>
  );
}

export default VideoPlayer;

VideoPlayer.propTypes = propTypes;
VideoPlayer.defaultProps = defaultProps;
