import { VoteVariantsView } from './VoteVariantsView';
import React, { memo } from 'react';

import { useVoteVariants } from './hooks/useVoteVariants';

export const VoteVariantsOtherType = memo(({ type, voteInfo, variantRef }) => {
  const {
    voteId,
    variants,
    result,
    isVoteActive,
    updateVariant,
    changeVoteVariant,
    deleteVoteVariant,
    updateVariantField,
    addVote,
    pictureChangeHandler,
    deleteVoteImage,
    isLoadingVariant,
    loadingImageVarnum,
    voteCount,
    addNewVariant
  } = useVoteVariants({ voteInfo });
  return (
    <VoteVariantsView
      voteId={voteId}
      type={type}
      variants={variants}
      addNewVariant={addNewVariant}
      voteCount={voteCount}
      result={result}
      isVoteActive={isVoteActive}
      updateVariant={updateVariant}
      changeVoteVariant={changeVoteVariant}
      deleteVoteVariant={deleteVoteVariant}
      updateVariantField={updateVariantField}
      addVote={addVote}
      pictureChangeHandler={pictureChangeHandler}
      deleteVoteImage={deleteVoteImage}
      isLoadingVariant={isLoadingVariant}
      loadingImageVarnum={loadingImageVarnum}
      variantRef={variantRef}
    />
  );
});
