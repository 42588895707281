import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useRef } from 'react';

import CircularLoader from 'components/CircularLoader/CircularLoader';

import { adjust, convertHexToRGBA, getButtonColor, setProperty } from 'helpers/color';

import styles from './Button.module.css';

const propTypes = {
  className: PropTypes.string,
  clickHandler: PropTypes.func.isRequired,
  transitionEndHandler: PropTypes.func,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isActive: PropTypes.bool,
  setIsActive: PropTypes.func,
  onlyIcon: PropTypes.bool,
  mode: PropTypes.oneOf(['light', 'dark']),
  variant: PropTypes.oneOf(['contained', 'outlined', 'text', 'message-action', 'icon', 'google']),
  color: PropTypes.oneOfType([
    PropTypes.oneOf(['primary', 'success', 'danger', 'warning', 'transparent']),
    function (props, propName, componentName) {
      if (!/^#/.test(props[propName])) {
        return new Error(
          `Проп \`${propName}\` компонента` +
            ` \`${componentName}\` должен быть цветом в формате HEX`
        );
      }
    }
  ]),
  // startIcon: PropTypes.instanceOf(Element),
  // endIcon: PropTypes.instanceOf(Element),
  title: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  textColor: PropTypes.string,
  hoverColor: PropTypes.string,
  hoverTextColor: PropTypes.string,
  activeColor: PropTypes.string,
  loaderColor: PropTypes.string,
  style: PropTypes.object,
  textStyle: PropTypes.object,
  stateType: PropTypes.oneOf(['cycle', 'stop']),
  stateDuration: PropTypes.number,
  tag: PropTypes.oneOf(['button', 'span', 'div', 'a']),
  animatedChildren: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

const defaultProps = {
  className: '',
  clickHandler: () => {},
  transitionEndHandler: () => {},
  setIsActive: () => {},
  isDisabled: false,
  isLoading: false,
  isActive: false,
  mode: 'dark',
  variant: 'outlined',
  size: 'medium',
  color: 'primary',
  tag: 'button',
  target: null
};

const Button = forwardRef(
  (
    {
      className,
      children,
      clickHandler,
      isDisabled,
      isLoading,
      onlyIcon,
      variant,
      color,
      startIcon,
      endIcon,
      title,
      size,
      textColor,
      hoverColor,
      hoverTextColor,
      activeColor,
      loaderColor,
      style,
      endIconStyle,
      textStyle,
      isActive,
      stateType,
      tag,
      animatedChildren,
      href,
      download,
      type,
      target,
      setStepIndex
    },
    ref
  ) => {
    const classes = classNames(
      styles['button'],
      styles[`button--${variant}`],
      styles[`button--${size}`],
      {
        [styles['button--only-icon']]: onlyIcon,
        [styles['button--active']]: isActive && stateType !== 'cycle'
      }
    );
    const buttonRef = useRef();

    const getTextColor = () => {
      if (textColor) return textColor;
      if (variant === 'contained') return '#fff';
      return color;
    };

    useEffect(() => {
      color = getButtonColor(color);
      const button = ref ? ref.current : buttonRef.current;
      setProperty(button, 'default-btn-color', color);
      setProperty(button, 'default-btn-color-text-hover', hoverTextColor || '#fff');
      setProperty(button, 'default-btn-color-text', textColor || getTextColor());
      setProperty(button, 'default-btn-color-hover', hoverColor || adjust(color, -20));
      setProperty(button, 'default-btn-color-active', activeColor || adjust(color, -40));
      setProperty(
        button,
        'default-text-btn-color-hover',
        hoverColor || convertHexToRGBA(adjust(color, -20), 10)
      );
      setProperty(
        button,
        'default-text-btn-color-active',
        activeColor || convertHexToRGBA(adjust(color, -40), 20)
      );
    }, [color, hoverColor, activeColor, ref, buttonRef]);

    const onClick = (evt) => {
      setStepIndex?.((v) => ++v);
      // if (stateType === 'stop') {
      //     setIsClicked(true);

      // }
      clickHandler(evt);
    };

    const TagName = tag;

    return (
      <TagName
        target={target}
        type={type}
        ref={ref || buttonRef}
        {...(title ? { title } : {})}
        {...(href ? { href } : {})}
        {...(download ? { download } : {})}
        style={{
          ...style,
          ...(isLoading && { overflow: 'hidden' })
        }}
        className={`${classes} ${className}`}
        onClick={onClick}
        disabled={isDisabled}>
        <>
          {isLoading && (
            <div className={styles['button__loader']}>
              <CircularLoader
                loaderColor={
                  loaderColor || (variant === 'contained' ? '#fff' : getButtonColor(color))
                }
              />
            </div>
          )}
          <span
            className={classNames(styles['button__icon'], styles['button__start-icon'], {
              unvisible: isLoading
            })}>
            {startIcon}
          </span>
          <span
            style={textStyle}
            className={classNames(styles['button__text'], {
              unvisible: isLoading
            })}>
            {children}
          </span>
          <span
            style={endIconStyle}
            className={classNames(styles['button__icon'], styles['button__end-icon'], {
              unvisible: isLoading
            })}>
            {endIcon}
          </span>
        </>
        {animatedChildren}
        {/* {
                stateType && stateDuration
                    ? <div
                        style={progressStyle}
                        // onTransitionEnd={() => {
                        //     if (isClicked) {
                        //         setIsClicked(false);
                        //         transitionEndHandler();
                        //     }
                        // }}
                        className={styles['button-progress']}></div>
                    : null
            } */}
      </TagName>
    );
  }
);

export default Button;

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;
