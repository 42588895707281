import { VoteStatePanelView } from './VoteStatePanelView';
import React, { memo } from 'react';

import { useVoteStatePanel } from './hooks/useVoteStatePanel';

const areEqualsInteractiveName = (prevProps, nextProps) => {
  return prevProps.voteInfo.state === nextProps.voteInfo.state;
};

export const VoteStatePanelContainer = memo(({ voteInfo }) => {
  const { broadcastState, toggleState } = useVoteStatePanel({ voteInfo });

  return <VoteStatePanelView broadcastState={broadcastState} toggleState={toggleState} />;
}, areEqualsInteractiveName);
