import { addSnack } from './snackBox';
import { addMinutes } from 'date-fns';
import cloneDeep from 'lodash/cloneDeep';

import {
  setChartFullData,
  setTimerActivity,
  updateChartFullData,
  updateChartOption
} from 'store/actions/pulse';
import { PULSE_STATES } from 'store/constants';

import {
  getPulseChartData,
  getPulseData as getPulseDataApi,
  setPulseStartTime,
  startPulse
} from 'api/dataLayer';

import { normalizeDataArray } from 'helpers/pulse';

// кол-во элементов в массиве, когда данных на 1час
const ONE_HOUR_LENGTH = 30;

export function сalcMiniMapPosition() {
  const newOption = cloneDeep(this.state.option);

  let currentElement = this.currentElement;
  const p = (15 * 100) / (((60 / this.interval) * this.duration) / 60);
  if (this.duration / 60 !== 1 && currentElement % 15 === 0 && currentElement / 15 !== 0) {
    newOption.dataZoom[0].start = p * (currentElement / 15 - 1);
    newOption.dataZoom[0].end = p * (currentElement / 15 + 1);

    this.setState({
      option: newOption
    });
  }
}

export function calcMiniMapSize() {
  return (dispatch, getState) => {
    const { option } = getState().pulseChart;
    const newOption = cloneDeep(option);
    // -1 потому-что данных n часов + 2 минуты;
    let duration = (newOption.series[0].data.length - 1) / ONE_HOUR_LENGTH;
    newOption.dataZoom[0].start = 0;
    newOption.dataZoom[0].end = 100 / duration;

    dispatch(updateChartOption(newOption));
  };
}

export function getChartData(dateTime) {
  return async (dispatch, getState) => {
    const res = await getPulseChartData({
      dateFrom: '2020-10-29 20:06:00',
      dateTo: '2020-10-29 22:08:00'
    });

    const { option } = getState().pulseChart;

    const newOption = cloneDeep(option); // immutable
    newOption.series[0].data = normalizeDataArray(res[0]);
    newOption.series[1].data = normalizeDataArray(res[1]);

    dispatch(updateChartOption(newOption));
    dispatch(calcMiniMapSize());
  };
}

export function getPulseData(id) {
  return async (dispatch) => {
    try {
      const res = await getPulseDataApi(id);
      res.chartEnd = new Date(addMinutes(new Date(res.chartStart), 20)).toString();
      dispatch(setChartFullData(res));
    } catch (ex) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errLoadingInteractiveInfo')
        })
      );
    }
  };
}

export function setStartTime(dateTime) {
  return async (dispatch) => {
    await setPulseStartTime();
    dispatch(updateChartFullData('chartStart', dateTime.toString()));
    const currentDate = new Date();
    if (+currentDate < +new Date(dateTime)) dispatch(setTimerActivity(true));
    else dispatch(setTimerActivity(false));
  };
}

export function setEndTime(dateTime) {
  return async (dispatch) => {
    await setPulseStartTime();
    dispatch(updateChartFullData('chartEnd', dateTime.toString()));
  };
}

export function start(start, end) {
  return async (dispatch) => {
    await startPulse();
    dispatch(updateChartFullData('activity', PULSE_STATES.active));
    // dispatch(updateChartFullData('chartStart', start));
    // dispatch(updateChartFullData('chartEnd', end));
  };
}

export function stop() {
  return async (dispatch) => {
    dispatch(updateChartFullData('activity', PULSE_STATES.finished));
  };
}
