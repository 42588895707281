import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import useActions from 'hooks/useActions';

export const useBroadcastMessages = ({ broadcastId }) => {
  const { setFavoriteState, fetchBroadcastMessages, fetchBroadcastMessagesSuccess } = useActions();
  const { messages, lastExportedMessages } = useSelector((state) => state.broadcast);

  const toggleFavorite = useCallback(
    (id, value) => setFavoriteState(id, value),
    [setFavoriteState]
  );

  useEffect(() => {
    fetchBroadcastMessages(broadcastId);

    return () => {
      fetchBroadcastMessagesSuccess({ messages: [] });
    };
  }, [broadcastId, fetchBroadcastMessages, fetchBroadcastMessagesSuccess]);

  return {
    messages,
    lastExportedMessages,
    toggleFavorite
  };
};
