import { useRef, useState } from 'react';

import { joyrideCallback } from 'helpers/guide';

export const useVoteGuide = ({ type }) => {
  const [run, setRun] = useState(false);
  const [stepIndex, setStep] = useState(0);
  const interactiveInfoRef = useRef(null);
  const socialPollsRef = useRef(null);
  const exportButtonsRef = useRef(null);
  const statesSettingsRef = useRef(null);
  const okButtonRef = useRef(null);
  const questionRef = useRef(null);
  const variantRef = useRef(null);
  const voteControlsRef = useRef(null);
  const handleJoyrideCallback = joyrideCallback(setRun, setStep);

  const setStepIndex = (clb) => {
    if (run) {
      setStep(clb);
    }
  };

  const steps = [
    {
      content: (
        <h2>{window.t('interactivePreview').replace('[interactiveName]', window.t(type))}</h2>
      ),
      placement: 'center',
      target: 'body'
    },
    {
      content: <p dangerouslySetInnerHTML={{ __html: window.t('interactiveInfo') }} />,
      spotlightPadding: 5,
      target: interactiveInfoRef.current || 'body',
      hideBackButton: true,
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'bottom',
      spotlightClicks: true
    },
    {
      content: <p dangerouslySetInnerHTML={{ __html: window.t('voteStep0') }} />,
      spotlightPadding: 0,
      target: socialPollsRef.current || 'body',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'bottom',
      spotlightClicks: true
    },
    {
      content: <p dangerouslySetInnerHTML={{ __html: window.t('broadcastStep2') }} />,
      spotlightPadding: 0,
      target: exportButtonsRef.current || 'body',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'bottom',
      spotlightClicks: true
    },
    {
      content: <p dangerouslySetInnerHTML={{ __html: window.t('broadcastStep3') }} />,
      spotlightPadding: 0,
      target: statesSettingsRef.current || 'body',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'bottom',
      spotlightClicks: true
    },
    {
      content: (
        <p
          className="text-justify"
          dangerouslySetInnerHTML={{ __html: window.t('interactiveOk') }}
        />
      ),
      spotlightPadding: 0,
      target: okButtonRef.current || 'body',
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: 'bottom',
      spotlightClicks: true
    },
    {
      content: <p dangerouslySetInnerHTML={{ __html: window.t('voteStep1') }} />,
      spotlightPadding: 0,
      target: questionRef.current || 'body',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'bottom',
      spotlightClicks: true
    },
    {
      content: <p dangerouslySetInnerHTML={{ __html: window.t('voteStep2') }} />,
      spotlightPadding: 0,
      target: variantRef.current || 'body',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'right',
      spotlightClicks: true
    },
    {
      content: (
        <p className="text-justify" dangerouslySetInnerHTML={{ __html: window.t('voteStep3') }} />
      ),
      spotlightPadding: 5,
      target: voteControlsRef.current || 'body',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'bottom',
      spotlightClicks: true
    },
    {
      content: (
        <h2>{window.t('interactiveFinish').replace('[interactiveName]', window.t(type))}</h2>
      ),
      placement: 'center',
      target: 'body'
    }
  ];

  return {
    run,
    startGuide: () => {
      setStep(0);
      setRun(true);
    },
    steps,
    stepIndex,
    setStepIndex,
    handleJoyrideCallback,
    interactiveInfoRef,
    socialPollsRef,
    exportButtonsRef,
    statesSettingsRef,
    okButtonRef,
    questionRef,
    variantRef,
    voteControlsRef
  };
};
