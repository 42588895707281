import React, { memo } from 'react';

import { TagsInput } from 'components';

export const VoteWinnersView = memo(
  ({ rangeNumbers, addRangeNumber, deleteRangeNumber, showWarning }) => {
    return (
      <div className="pt-2 pl-5 pr-5 pb-3">
        <div className="d-flex">
          <TagsInput
            id="rangeNumbers"
            className="flex-grow"
            label={window.t('whoWillWin')}
            placeholder={window.t('enterSequenceNumbers')}
            add={addRangeNumber}
            remove={deleteRangeNumber}
            tagRegExp="^[0-9]+$"
            tags={rangeNumbers}
            styles={{ marginTop: 10 }}
            onRepeat={showWarning}
          />
        </div>
      </div>
    );
  }
);
