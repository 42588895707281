import DeleteCardModal from './DeleteCardModal/DeleteCardModal';
import FbCollapseBody from './FbCollapseBody/FbCollapseBody';
import OkCollapseBody from './OkCollapseBody/OkCollapseBody';
import { RSSCollapseBody } from './RSSCollapseBody/RSSCollapseBody';
import TelegramCollapseBody from './TelegramCollapseBody/TelegramCollapseBody';
import ViberCollapseBody from './ViberCollapseBody/ViberCollapseBody';
import VkCollapseBody from './VkCollapseBody/VkCollapseBody';
import VotingNumberCollapseBody from './VotingNumberCollapseBody/VotingNumberCollapseBody';
import WhatsAppCollapseBody from './WhatsAppCollapseBody/WhatsAppCollapseBody';
import YoutubeCollapseBody from './YoutubeCollapseBody/YoutubeCollapseBody';
import React, { useEffect, useState } from 'react';

import { BlurWrapper, Collapsible, CollaspibleHead, Portal } from 'components';

import useActions from 'hooks/useActions';

function SocialSettings() {
  const { setTotalNoteCount } = useActions();
  // const [isInstActive, setIsInstActive] = useState(false);
  const [isDeleteCardActive, setIsDeleteCardActive] = useState(false);
  const [deleteCardData, setDeleteCardData] = useState({});
  const [isVkConnected, setIsVkConnected] = useState(false);
  const [isYoutubeConnected, setIsYoutubeConnected] = useState(false);
  const [isOkConnected, setIsOkConnected] = useState(false);
  const [isTelegramConnected, setIsTelegramConnected] = useState(false);
  const [isViberConnected, setIsViberConnected] = useState(false);
  const [isRSSConnected, setIsRSSConnected] = useState(false);
  const [isWhatsAppConnected, setIsWhatsAppConnected] = useState(false);
  const [isFbConnected, setIsFbConnected] = useState(false);
  const [isVotingNumbersConnected, setIsVotingNumbersConnected] = useState(false);
  const [noteCount, setNoteCount] = useState(0);

  useEffect(() => {
    setTotalNoteCount(noteCount);
  }, [noteCount, setTotalNoteCount]);

  return (
    <>
      <Collapsible
        trigger={
          <CollaspibleHead
            name="Whatsapp"
            channel="whatsapp"
            noteCount={noteCount}
            status={isWhatsAppConnected ? window.t('connected') : null}
          />
        }>
        <WhatsAppCollapseBody
          setDeleteCardData={setDeleteCardData}
          setIsDeleteCardActive={setIsDeleteCardActive}
          setIsWhatsAppConnected={setIsWhatsAppConnected}
          setNoteCount={setNoteCount}
        />
      </Collapsible>
      <Collapsible
        trigger={
          <CollaspibleHead
            name="Viber"
            channel="viber"
            status={isViberConnected ? window.t('connected') : null}
          />
        }>
        <ViberCollapseBody
          setDeleteCardData={setDeleteCardData}
          setIsDeleteCardActive={setIsDeleteCardActive}
          setIsViberConnected={setIsViberConnected}
        />
      </Collapsible>
      <Collapsible
        trigger={
          <CollaspibleHead
            name={window.t('telegram')}
            channel="telegram"
            status={isTelegramConnected ? window.t('connected') : null}
          />
        }>
        <TelegramCollapseBody
          setDeleteCardData={setDeleteCardData}
          setIsDeleteCardActive={setIsDeleteCardActive}
          setIsTelegramConnected={setIsTelegramConnected}
        />
      </Collapsible>
      <Collapsible
        trigger={
          <CollaspibleHead
            name="Youtube"
            channel="yt_live"
            status={isYoutubeConnected ? window.t('connected') : null}
          />
        }>
        <YoutubeCollapseBody
          setDeleteCardData={setDeleteCardData}
          setIsDeleteCardActive={setIsDeleteCardActive}
          setIsYoutubeConnected={setIsYoutubeConnected}
        />
      </Collapsible>
      <Collapsible
        trigger={
          <CollaspibleHead
            name={window.t('vk')}
            channel="vkgroup"
            status={isVkConnected ? window.t('connected') : null}
          />
        }>
        <VkCollapseBody
          setDeleteCardData={setDeleteCardData}
          setIsDeleteCardActive={setIsDeleteCardActive}
          setIsVkConnected={setIsVkConnected}
        />
      </Collapsible>
      <Collapsible
        trigger={
          <CollaspibleHead
            name={window.t('ok')}
            channel="ok"
            status={isOkConnected ? window.t('connected') : null}
          />
        }>
        <OkCollapseBody
          setDeleteCardData={setDeleteCardData}
          setIsDeleteCardActive={setIsDeleteCardActive}
          setIsOkConnected={setIsOkConnected}
        />
      </Collapsible>
      <Collapsible
        trigger={
          <CollaspibleHead
            name="Facebook"
            channel="fb"
            status={isFbConnected ? window.t('connected') : null}
          />
        }>
        <FbCollapseBody
          setDeleteCardData={setDeleteCardData}
          setIsDeleteCardActive={setIsDeleteCardActive}
          setIsFbConnected={setIsFbConnected}
        />
      </Collapsible>

      <Collapsible
        trigger={
          <CollaspibleHead
            name="RSS"
            channel="rss"
            status={isRSSConnected ? window.t('connected') : null}
          />
        }>
        <RSSCollapseBody setIsRSSConnected={setIsRSSConnected} />
      </Collapsible>

      {/* <Collapsible trigger={<CollaspibleHead name={window.t('phoneVoting')} channel="phoneVoting" status={isVotingNumbersConnected ? window.t('connected') : null} />}>
        <VotingNumberCollapseBody
          setDeleteCardData={setDeleteCardData}
          setIsDeleteCardActive={setIsDeleteCardActive}
          setIsVotingNumbersConnected={setIsVotingNumbersConnected}
        />
      </Collapsible> */}

      {/* <Collapsible
        onOpen={() => { setIsInstActive(true) }}
        onClosing={() => { setIsInstActive(false) }}
        trigger={<CollaspibleHead name="Instagram" channel="inst" />}>
        <InstagramCollapseBody isActive={isInstActive} />
      </Collapsible> */}
      <Portal nodeId="work-pane-modals">
        <BlurWrapper
          isOpened={isDeleteCardActive}
          clickHandler={() => setIsDeleteCardActive(false)}
          position="absolute"
          background="#5a5a5a2e"
          className="d-flex justify-center align-center">
          <DeleteCardModal
            data={deleteCardData}
            closeHandler={() => setIsDeleteCardActive(false)}
          />
        </BlurWrapper>
      </Portal>
    </>
  );
}

export default SocialSettings;
