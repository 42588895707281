import { createSlice } from '@reduxjs/toolkit';
import { format } from 'date-fns';

const initialState = {
  message: {
    id: '',
    broadcast_id: '',
    name: '',
    type: 'broadcast',
    description: 'comment',
    memo: [1],
    author: '*****',
    senderNumber: '',
    content: '',
    channel: 'sms',
    date: format(new Date(), 'yyyy-MM-dd HH:mm'),
    image: 1,
    is_created: 1,
    status: '',
    favorite: '',
    attachments: [],
    labels: [],
    region: ''
  },
  createdMessages: []
};

export const messageCreatorSlice = createSlice({
  name: 'messageCreator',
  initialState,
  reducers: {
    updateMessageField: (state, action) => {
      const { field, value } = action.payload;
      return {
        ...state,
        message: { ...state.message, [field]: value }
      };
    },
    setCreatedMessage: (state, action) => {
      const { message } = action.payload;
      return {
        ...state,
        message
      };
    },
    markAsAddedToBroadcast: (state, action) => {
      const { id } = action.payload;
      return {
        ...state,
        createdMessages: [...state.createdMessages, id]
      };
    }
  }
});

export const messageCreatorActions = messageCreatorSlice.actions;
export const messageCreatorReducer = messageCreatorSlice.reducer;
