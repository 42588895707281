// Feed
export const INIT_TABS = 'INIT_TABS';
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';
export const FETCH_FEED_ITEMS_START = 'FETCH_FEED_ITEMS_START';
export const FETCH_FEED_ITEMS_SUCCESS = 'FETCH_FEED_ITEMS_SUCCESS';
export const FETCH_FEED_ITEMS_ERROR = 'FETCH_FEED_ITEMS_ERROR';
export const SELECT_FEED_ITEM = 'SELECT_FEED_ITEM';
export const MULTIPLE_SELECT_FEED_ITEMS = 'MULTIPLE_SELECT_FEED_ITEMS';
export const FETCH_NEW_FEED_ITEMS_SUCCESS = 'FETCH_NEW_FEED_ITEMS_SUCCESS';
export const SET_HAS_NEW_FEED_ITEMS = 'SET_HAS_NEW_FEED_ITEMS';
export const SET_SCROLL_BOX = 'SET_SCROLL_BOX';
export const UPDATE_FEED_ITEMS = 'UPDATE_FEED_ITEMS';
export const SET_FEED_ITEM_HIDDEN_STATE = 'SET_FEED_ITEM_HIDDEN_STATE';
export const HAS_MORE_PREVIOUS = 'HAS_MORE_PREVIOUS';
// Feed actions
export const SET_FEED_ITEM_FAV_STATUS = 'SET_FEED_ITEM_FAV_STATUS';
export const SET_FEED_ITEM_LIKE_STATUS = 'SET_FEED_ITEM_LIKE_STATUS';
export const SET_FEED_ITEM_MINIMIZED_STATUS = 'SET_FEED_ITEM_MINIMIZED_STATUS';
export const SET_ACTIONS_MENU_ID = 'SET_ACTIONS_MENU_ID';
export const UPDATE_FEED_ITEM_AUDIO = 'UPDATE_FEED_ITEM_AUDIO';
// feed item
export const SET_LABELS_SUCCESS = 'SET_LABELS_SUCCESS';
export const ADD_LABEL_SUCCESS = 'ADD_LABEL_SUCCESS';
export const DELETE_LABEL_SUCCESS = 'DELETE_LABEL_SUCCESS';
export const CLEAR_LABELS_SUCCESS = 'CLEAR_LABELS_SUCCESS';
export const SET_DRAGGING_FEED_ITEM_ID = 'SET_DRAGGING_FEED_ITEM_ID';
export const SET_ACTION_MENU_BTN_RECT = 'SET_ACTION_MENU_BTN_RECT';
export const FILTER_FEED_ITEMS = 'FILTER_FEED_ITEMS';
export const SHOW_ALL_FEED_ITEMS = 'SHOW_ALL_FEED_ITEMS';
export const REMOVE_FEED_ITEM = 'REMOVE_FEED_ITEM';
export const REMOVE_FEED_ITEM_BY_KEY = 'REMOVE_FEED_ITEM_BY_KEY';
export const PUSH_FEED_ITEM = 'PUSH_FEED_ITEM';
// feed message
export const ADD_RESPONSE_MESSAGE = 'ADD_RESPONSE_MESSAGE';
export const EDIT_RESPONSE_MESSAGE = 'EDIT_RESPONSE_MESSAGE';
export const DELETE_RESPONSE_MESSAGE = 'DELETE_RESPONSE_MESSAGE';

// MessageFilters
export const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU';
export const SET_SEARCH_CONDITIONS = 'SET_SEARCH_CONDITIONS';
export const SET_SEARCH_MODE = 'SET_SEARCH_MODE';
export const SET_IS_SEARCH_COLLAPSED = 'SET_IS_SEARCH_COLLAPSED';
export const SET_SEARCH_MODAL_HEIGHT = 'SET_SEARCH_MODAL_HEIGHT';

// ISettings
export const SET_ISETTINGS_INFO = 'SET_ISETTINGS_INFO';
export const UPDATE_ISETTINGS_INFO = 'UPDATE_ISETTINGS_INFO';
export const SET_IS_INTERACTIVE_EXIST = 'SET_IS_INTERACTIVE_EXIST';
export const SET_OPENED_INTERACTIVE_TYPE = 'SET_OPENED_INTERACTIVE_TYPE';

// Broadcast
export const SET_BROADCAST_ID = 'SET_BROADCAST_ID';
export const FETCH_BROADCAST_MESSAGES_START = 'FETCH_BROADCAST_MESSAGES_START';
export const FETCH_BROADCAST_MESSAGES_SUCCESS = 'FETCH_BROADCAST_MESSAGES_SUCCESS';
export const FETCH_BROADCAST_MESSAGES_ERROR = 'FETCH_BROADCAST_MESSAGES_ERROR';
export const SELECT_BROADCAST_MESSAGE = 'SELECT_BROADCAST_MESSAGE';
export const EXPORT_MESSAGE_SUCCESS = 'EXPORT_MESSAGE_SUCCESS';
export const ADD_MESSAGE_TO_BROADCAST_SUCCESS = 'ADD_MESSAGE_TO_BROADCAST_SUCCESS';
export const DEL_FROM_TITR_SUCCESS = 'DEL_FROM_TITR_SUCCESS';
export const CLEAR_TITR_SUCCESS = 'CLEAR_TITR_SUCCESS';
export const UPDATE_BROADCAST_MESSAGE_TEXT = 'UPDATE_BROADCAST_MESSAGE_TEXT';
export const CHANGE_BROADCAST_MESSAGE_TEXT = 'CHANGE_BROADCAST_MESSAGE_TEXT';
export const REMOVE_BROADCAST_MESSAGE = 'REMOVE_BROADCAST_MESSAGE';
export const SET_BROADCAST_MESSAGE_FAVORITE_STATE = 'SET_BROADCAST_MESSAGE_FAVORITE_STATE';
export const SET_ACTIVE_BROADCAST_DATA = 'SET_ACTIVE_BROADCAST_DATA';
export const UPDATE_ACTIVE_BROADCAST_FIELD = 'UPDATE_ACTIVE_BROADCAST_FIELD';
export const RESET_BROADCAST_STORE = 'RESET_BROADCAST_STORE';

// Sessions
export const FETCH_SESSIONS_START = 'FETCH_SESSIONS_START';
export const FETCH_SESSIONS_SUCCESS = 'FETCH_SESSIONS_SUCCESS';
export const FETCH_SESSIONS_ERROR = 'FETCH_SESSIONS_ERROR';
export const SELECT_SESSION = 'SELECT_SESSION';
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS';
export const CREATE_SESSION_ERROR = 'CREATE_SESSION_ERROR';
export const SET_SEARCH_ACTIVITY = 'SET_SEARCH_ACTIVITY';
export const SET_FILTER_ACTIVITY = 'SET_FILTER_ACTIVITY';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const SET_FILTERED_SESSIONS = 'SET_FILTERED_SESSIONS';
export const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION';
export const SET_SESSIONS = 'SET_SESSIONS';
export const SET_INTERACTIVE_ACTIVITY = 'SET_INTERACTIVE_ACTIVITY';
export const SET_IS_FILTERED = 'SET_IS_FILTERED';
export const UPDATE_SESSION_FROM_INFO = 'UPDATE_SESSION_FROM_INFO';
export const SET_IS_SESSION_CREATED = 'SET_IS_SESSION_CREATED';
export const REMOVE_SESSION = 'REMOVE_SESSION';
export const REMOVE_SESSIONS = 'REMOVE_SESSIONS';
export const UPDATE_SESSION_INFO = 'UPDATE_SESSION_INFO';
export const SET_ENABLE_INTERACTIVES = 'SET_ENABLE_INTERACTIVES';
export const SET_SESSION_ACTIVITY = 'SET_SESSION_ACTIVITY';
export const SET_IS_LOADING_SESSIONS = 'SET_IS_LOADING_SESSIONS';
export const CREATE_SESSION_TEMP_SUCCESS = 'CREATE_SESSION_TEMP_SUCCESS';
export const CLEAR_SESSION_TEMP_SUCCESS = 'CLEAR_SESSION_TEMP_SUCCESS';

// Vote
export const SET_VOTE_ID = 'SET_VOTE_ID';
export const FETCH_VOTE_VARIANTS_START = 'FETCH_VOTE_VARIANTS_START';
export const FETCH_VOTE_VARIANTS_SUCCESS = 'FETCH_VOTE_VARIANTS_SUCCESS';
export const FETCH_VOTE_VARIANTS_ERROR = 'FETCH_VOTE_VARIANTS_ERROR';
export const UPDATE_VOTE_VARIANTS_STATS = 'UPDATE_VOTE_VARIANTS_STATS';
export const SET_TOTAL_VOTE_COUNT = 'SET_TOTAL_VOTE_COUNT';
export const ADD_VOTE_VARIANT_SUCCESS = 'ADD_VOTE_VARIANT_SUCCESS';
export const ADD_VOTE_VARIANT_ERROR = 'ADD_VOTE_VARIANT_ERROR';
export const DELETE_VOTE_VARIANT_SUCCESS = 'DELETE_VOTE_VARIANT_SUCCESS';
export const DELETE_VOTE_VARIANT_ERROR = 'DELETE_VOTE_VARIANT_ERROR';
export const UPDATE_VOTE_VARIANTS = 'UPDATE_VOTE_VARIANTS';
export const UPDATE_VOTE_VARIANT_PROP = 'UPDATE_VOTE_VARIANT_PROP';
export const UPDATE_VOTE_VARIANT_EXIST = 'UPDATE_VOTE_VARIANT_EXIST';
export const UPDATE_VOTE_VARIANT_ERROR = 'UPDATE_VOTE_VARIANT_ERROR';
export const SET_VOTE_ACTIVITY = 'SET_VOTE_ACTIVITY';
export const START_VOTE_SUCCESS = 'START_VOTE_SUCCESS';
export const START_VOTE_ERROR = 'START_VOTE_ERROR';
export const SET_VOTE_RESULT = 'SET_VOTE_RESULT';
export const SET_VOTE_MESSAGES_ORDER = 'SET_VOTE_MESSAGES_ORDER';
export const SET_VOTE_WINNERS = 'SET_VOTE_WINNERS';
export const RESET_VOTE_STORE = 'RESET_VOTE_STORE';
export const ADD_VOTE_ALERT = 'ADD_VOTE_ALERT';
export const SET_VOTE_ALERTS = 'SET_VOTE_ALERTS';
export const SET_VOTE_IS_ALERT_ENABLE = 'SET_VOTE_IS_ALERT_ENABLE';

// Settings
export const SET_SETTINGS = 'SET_SETTINGS';
export const UPDATE_APP_SETTINGS = 'UPDATE_APP_SETTINGS';
export const SET_TOTAL_NOTE_COUNT = 'SET_TOTAL_NOTE_COUNT';
export const SET_NEW_MESSAGES_POS = 'SET_NEW_MESSAGES_POS';
export const UPDATE_SETTINGS_ARRAY = 'UPDATE_SETTINGS_ARRAY';
export const SET_SETTINGS_ARRAY = 'SET_SETTINGS_ARRAY';

export const UPDATE_MESSAGE_ACTIONS_CONFIG = 'UPDATE_MESSAGE_ACTIONS_CONFIG';
export const UPDATE_MESSAGE_VIEW_CONFIG = 'UPDATE_MESSAGE_VIEW_CONFIG';
export const UPDATE_MESSAGE_FEED_CONFIG = 'UPDATE_MESSAGE_FEED_CONFIG';
export const UPDATE_BROADCAST_CONFIG = 'UPDATE_BROADCAST_CONFIG';
export const UPDATE_FEEDBACK_ACTIONS = 'UPDATE_FEEDBACK_ACTIONS';

export const SET_AVATAR_FORM = 'SET_AVATAR_FORM';
export const SET_MESSAGE_ACTIONS_CONFIG = 'SET_MESSAGE_ACTIONS_CONFIG';
export const SET_MESSAGE_VIEW_CONFIG = 'SET_MESSAGE_VIEW_CONFIG';
export const SET_MESSAGE_FEED_CONFIG = 'SET_MESSAGE_FEED_CONFIG';
export const SET_BROADCAST_CONFIG = 'SET_BROADCAST_CONFIG';
export const SET_FEEDBACK_ACTIONS = 'SET_FEEDBACK_ACTIONS';
export const SET_PROFILE_INTERACTIVE = 'SET_PROFILE_INTERACTIVE';

export const SET_AUTHORS = 'SET_AUTHORS';
export const SET_PINNED_AUTHORS = 'SET_PINNED_AUTHORS';
export const UPDATE_PINNED_AUTHORS = 'UPDATE_PINNED_AUTHORS';
export const TOGGLE_PINNED_AUTHOR = 'TOGGLE_PINNED_AUTHOR';
export const ADD_MESSAGE_TO_PINNED_AUTHOR = 'ADD_MESSAGE_TO_PINNED_AUTHOR';
export const REMOVE_MESSAGE_FROM_PINNED_AUTHOR = 'REMOVE_MESSAGE_FROM_PINNED_AUTHOR';
export const SET_PINNED_MESSAGES_AS_READ = 'SET_PINNED_MESSAGES_AS_READ';
export const ADD_MESSAGES_TO_PINNED_AUTHOR = 'ADD_MESSAGES_TO_PINNED_AUTHOR';
export const UPDATE_PINNED_AUTHOR_MESSAGES = 'UPDATE_PINNED_AUTHOR_MESSAGES';

// email config
export const ADD_EXPORT_CONFIG = 'ADD_EXPORT_CONFIG';
export const DELETE_EXPORT_CONFIG = 'DELETE_EXPORT_CONFIG';
export const UPDATE_EXPORT_CONFIG = 'UPDATE_EXPORT_CONFIG';

// Socials
export const FETCH_SOCIALS_SUCCESS = 'FETCH_SOCIALS_SUCCESS';
export const FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS';
export const MAKE_SOCIALS_CHANNEL_ACTIVE = 'MAKE_SOCIALS_CHANNEL_ACTIVE';
export const TOGGLE_POST_SUCCESS = 'TOGGLE_POST_SUCCESS';
export const TOGGLE_ALL_POSTS_SUCCESS = 'TOGGLE_ALL_POSTS_SUCCESS';
export const SET_FILTERED_SOCIALS_POSTS = 'SET_FILTERED_SOCIALS_POSTS';
export const SET_SOCIALS_SEARCH_TEXT = 'SET_SOCIALS_SEARCH_TEXT';
export const SET_SOCIALS_AUTH_STATE = 'SET_SOCIALS_AUTH_STATE';

// ProfileCard
export const SET_PROFILE_INFO = 'SET_PROFILE_INFO';
export const FETCH_USER_INFO_START = 'FETCH_USER_INFO_START';
export const FETCH_USER_INFO_SUCCESS = 'FETCH_USER_INFO_SUCCESS';
export const FETCH_USER_INFO_ERROR = 'FETCH_USER_INFO_ERROR';
export const FETCH_USER_MESSAGES_SUCCESS = 'FETCH_USER_MESSAGES_SUCCESS';
export const FETCH_USER_MESSAGES_START = 'FETCH_USER_MESSAGES_START';
export const FETCH_USER_MESSAGES_ERROR = 'FETCH_USER_MESSAGES_ERROR';
export const FETCH_MORE_MESSAGES_SUCCESS = 'FETCH_MORE_MESSAGES_SUCCESS';
export const SET_HAS_MORE_MESSAGES = 'SET_HAS_MORE_MESSAGES';
export const FETCH_USER_MEDIA_SUCCESS = 'FETCH_USER_MEDIA_SUCCESS';
export const BLOCK_AUTHOR_SUCCESS = 'BLOCK_AUTHOR_SUCCESS';
export const UNBLOCK_AUTHOR_SUCCESS = 'UNBLOCK_AUTHOR_SUCCESS';
export const SET_IS_BLOCKING_AUTHOR_STATUS = 'SET_IS_BLOCKING_AUTHOR_STATUS';

// SnackBox
export const ADD_SNACK = 'ADD_SNACK';
export const SET_SNACK_HEIGHT = 'SET_SNACK_HEIGHT';
export const SET_SNACK_BOTTOM = 'SET_SNACK_BOTTOM';
export const REMOVE_SNACK = 'REMOVE_SNACK';
export const SET_SNACK_TRANSITION = 'SET_SNACK_TRANSITION';
export const RESET_SNACKS_TRANSITION = 'RESET_SNACKS_TRANSITION';

// Message creator
export const SET_CREATED_MESSAGE = 'SET_CREATED_MESSAGE';
export const UPDATE_CREATED_MESSAGE = 'UPDATE_CREATED_MESSAGE';
export const MARK_AS_ADDED_TO_BROADCAST = 'MARK_AS_ADDED_TO_BROADCAST';

// Modal Image
export const SET_MODAL_IMAGE_USER_INFO = 'SET_MODAL_IMAGE_USER_INFO';
export const SET_MODAL_IMAGE_LIST = 'SET_MODAL_IMAGE_LIST';
export const SET_MODAL_CURRENT_IMAGE = 'SET_MODAL_CURRENT_IMAGE';
export const SET_MODAL_IMAGE_IS_OPENED = 'SET_MODAL_IMAGE_IS_OPENED';

// Resize
export const SET_LIST_PANE_REF = 'SET_LIST_PANE_REF';
export const SET_WORK_PANE_REF = 'SET_WORK_PANE_REF';
export const SET_LIST_PANE_WIDTH = 'SET_LIST_PANE_WIDTH';
export const SET_WORK_PANE_WIDTH = 'SET_WORK_PANE_WIDTH';

// banned
export const SET_BANNED_SEARCH_TEXT = 'SET_BANNED_SEARCH_TEXT';
export const SET_BANNED_AUTHORS = 'SET_BANNED_AUTHORS';

// Routes
export const SET_ROUTES_HISTORY = 'SET_ROUTES_HISTORY';
export const ADD_ROUTE_TO_HISTORY = 'ADD_ROUTE_TO_HISTORY';

// ScrollBoxRef
export const SET_SCROLL_BOX_REF = 'SET_SCROLL_BOX_REF';
export const SAVE_SCROLL_TOP_VALUE = 'SAVE_SCROLL_TOP_VALUE';

// Quiz
export const ADD_QUIZ_MESSAGE = 'ADD_QUIZ_MESSAGE';
export const ADD_QUIZ_KEYWORD = 'ADD_QUIZ_KEYWORD';
export const REMOVE_QUIZ_KEYWORD = 'REMOVE_QUIZ_KEYWORD';
export const ADD_QUIZ_RANGE_NUMBER = 'ADD_QUIZ_RANGE_NUMBER';
export const REMOVE_QUIZ_RANGE_NUMBER = 'REMOVE_QUIZ_RANGE_NUMBER';
export const SET_QUIZ_VARIANTS = 'SET_QUIZ_VARIANTS';

// Pulse
export const UPDATE_PULSE_DATA = 'UPDATE_PULSE_DATA';
export const UPDATE_CHART_CONFIG = 'UPDATE_CHART_CONFIG';
export const UPDATE_OPTION_MENU = 'UPDATE_OPTION_MENU';
export const UPDATE_AUTHORS_PER_BAR = 'UPDATE_AUTHORS_PER_BAR';

export const UPDATE_CHART_FULL_DATA = 'UPDATE_CHART_FULL_DATA';
export const SET_CHART_FULL_DATA = 'SET_CHART_FULL_DATA';
//
export const UPDATE_CHART_OPTION = 'UPDATE_CHART_OPTION';
export const SET_IS_CHART_LOADING = 'SET_IS_CHART_LOADING';
export const SET_PULSE_TIMER_ACTIVITY = 'SET_PULSE_TIMER_ACTIVITY';

// Chat
export const SET_AVAILABLE_CHAT_CHANNEL = 'SET_AVAILABLE_CHAT_CHANNEL';
export const SET_SELECTED_CHAT_CHANNEL = 'SET_SELECTED_CHAT_CHANNEL';
export const FETCH_MORE_MESSAGES_REVERSE = 'FETCH_MORE_MESSAGES_REVERSE';
export const SET_CHAT_SCROLLBOX_REF = 'SET_CHAT_SCROLLBOX_REF';
export const ADD_OWN_MESSAGE = 'ADD_OWN_MESSAGE';
export const MAKE_CHAT_MESSAGE_SENDED = 'MAKE_CHAT_MESSAGE_SENDED';
export const SET_CHAT_MESSAGES = 'SET_CHAT_MESSAGES';
export const ADD_CHAT_MESSAGES = 'ADD_CHAT_MESSAGES';
export const SET_HAS_MORE_CHAT_MESSAGES = 'SET_HAS_MORE_CHAT_MESSAGES';
export const FETCH_CHAT_MESSAGES_SUCCESS = 'FETCH_CHAT_MESSAGES_SUCCESS';
export const HAS_MORE_NEXT_CHAT = 'HAS_MORE_NEXT_CHAT';
export const HAS_MORE_PREVIOUS_CHAT = 'HAS_MORE_PREVIOUS_CHAT';
export const SET_IS_SENDING_MESSAGE = 'SET_IS_SENDING_MESSAGE';

// CONTENT
export const SET_CONTENT_BLOCKS = 'SET_CONTENT_BLOCKS';

// Profile
export const TOGGLE_PROFILE_EDIT = 'TOGGLE_PROFILE_EDIT';
export const SET_CURRENT_PROFILE = 'SET_CURRENT_PROFILE';
export const SET_CURRENT_PROFILE_CONFIG = 'SET_CURRENT_PROFILE_CONFIG';
export const SET_IS_NEW_PROFILE_CREATING = 'SET_IS_NEW_PROFILE_CREATING';
export const SET_ARE_PROFILES_LOADING = 'SET_ARE_PROFILES_LOADING';
export const ADD_PROFILE = 'ADD_PROFILE';
export const REMOVE_PROFILE = 'REMOVE_PROFILE';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const SET_PROFILES = 'SET_PROFILES';
export const EDIT_CURRENT_PROFILE = 'EDIT_CURRENT_PROFILE';

// autoreply
export const SET_AUTOREPLY_ID = 'SET_AUTOREPLY_ID';
export const SET_ACTIVE_AUTOREPLY_DATA = 'SET_ACTIVE_AUTOREPLY_DATA';
export const UPDATE_ACTIVE_AUTOREPLY_FIELD = 'UPDATE_ACTIVE_AUTOREPLY_FIELD';
export const RESET_AUTOREPLY_STORE = 'RESET_AUTOREPLY_STORE';

// pinnedAuthor

// TODO: change SET_FEED_ITEM_FAV_STATUS ...

export const SET_MSG_FAV_STATUS = 'SET_MSG_FAV_STATUS';
export const SET_MSG_LIKE_STATUS = 'SET_MSG_LIKE_STATUS';
export const SET_MESSAGE_HIDDEN_STATE = 'SET_MESSAGE_HIDDEN_STATE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const UPDATE_MESSAGE_AUDIO = 'UPDATE_MESSAGE_AUDIO';
