import EmailSettings from './EmailSettings/EmailSettings';
import TelegramSettings from './TelegramSettings/TelegramSettings';

import { Collapsible, CollaspibleHead } from 'components';

import styles from './ExportSettings.module.css';

function ExportSettings() {
  return (
    <div className={styles['wrapper']}>
      <h3 className={styles['title']}>{window.t('exportSettings')}</h3>
      <Collapsible trigger={<CollaspibleHead name="Email" channel="email" />}>
        <EmailSettings />
      </Collapsible>
      <Collapsible trigger={<CollaspibleHead name="Telegram" channel="telegram" />}>
        <TelegramSettings />
      </Collapsible>
    </div>
  );
}
export default ExportSettings;
