import {
  SET_ISETTINGS_INFO,
  SET_IS_INTERACTIVE_EXIST,
  SET_OPENED_INTERACTIVE_TYPE,
  UPDATE_ISETTINGS_INFO
} from '../actions/actionTypes';

const initialState = {
  isInteractiveExist: true,
  openedInteractiveType: null,
  info: {
    id: null,
    title: '',
    description: '',
    notes: ''
  }
};

export default function isettingsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ISETTINGS_INFO:
      return {
        ...state,
        info: action.info
      };
    case UPDATE_ISETTINGS_INFO:
      return {
        ...state,
        info: { ...state.info, [action.field]: action.value }
      };
    case SET_IS_INTERACTIVE_EXIST:
      return {
        ...state,
        isInteractiveExist: action.isInteractiveExist
      };
    case SET_OPENED_INTERACTIVE_TYPE:
      return {
        ...state,
        openedInteractiveType: action.interactiveType
      };
    default:
      return state;
  }
}
