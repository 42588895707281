import { uniqBy } from 'lodash';

import {
  ADD_LABEL_SUCCESS,
  ADD_MESSAGES_TO_PINNED_AUTHOR,
  ADD_MESSAGE_TO_PINNED_AUTHOR,
  ADD_RESPONSE_MESSAGE,
  CLEAR_LABELS_SUCCESS,
  DELETE_LABEL_SUCCESS,
  REMOVE_MESSAGE,
  REMOVE_MESSAGE_FROM_PINNED_AUTHOR,
  SET_MESSAGE_HIDDEN_STATE,
  SET_MSG_FAV_STATUS,
  SET_MSG_LIKE_STATUS,
  SET_MSG_MINIMIZED_STATUS,
  SET_PINNED_AUTHORS,
  SET_PINNED_MESSAGES_AS_READ,
  TOGGLE_PINNED_AUTHOR,
  UPDATE_MESSAGE_AUDIO,
  UPDATE_PINNED_AUTHORS,
  UPDATE_PINNED_AUTHOR_MESSAGES
} from 'store/actions/actionTypes';

const initialState = {
  authors: []
};

export default function pinnedAuthorsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PINNED_AUTHORS:
      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: action.authors.map((author) => ({
          ...author,
          messages: uniqBy(action.messages, 'id'),
          lastMessageId: action.messages?.[action.messages?.length - 1]?.id || author.lastMessageId
        }))
      };
    case UPDATE_PINNED_AUTHORS:
      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: action.authors.map((author) => ({
          ...state.authors.find((el) => el.senderNumber === author.senderNumber),
          ...author
        }))
      };
    case ADD_MESSAGES_TO_PINNED_AUTHOR:
      if (!state.authors?.some((author) => author.senderNumber === action.senderNumber))
        return state;

      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: state.authors.map((author) =>
          author.senderNumber !== action.senderNumber
            ? author
            : {
                ...author,
                messages: uniqBy([...(author.messages || []), ...action.messages], 'id'),
                lastMessageId:
                  action.messages[action.messages.length - 1]?.id || author.lastMessageId
              }
        )
      };
    case TOGGLE_PINNED_AUTHOR:
      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: state.authors?.some(
          (author) => author.senderNumber === action.message.senderNumber
        )
          ? (state.authors || []).filter(
              (author) => author.senderNumber !== action.message.senderNumber
            )
          : [
              ...(state.authors || []),
              {
                senderNumber: action.message.senderNumber,
                image: action.message.image,
                author: action.message.author,
                messages: [
                  { ...action.message, isRead: true },
                  ...action.messageFeed
                    .filter(
                      (msg) =>
                        msg.senderNumber === action.message.senderNumber &&
                        +msg.id > action.message.id
                    )
                    .map((el) => ({ ...el, status: [] }))
                ],
                lastMessageId: [
                  { ...action.message, isRead: true },
                  ...action.messageFeed.filter(
                    (msg) =>
                      msg.senderNumber === action.message.senderNumber &&
                      +msg.id > action.message.id
                  )
                ].slice(-1)[0]?.id,
                channel: action.message.channel
              }
            ]
      };
    case UPDATE_PINNED_AUTHOR_MESSAGES:
      if (!state.authors?.some((author) => author.senderNumber === action.senderNumber))
        return state;

      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: (state.authors || []).map((author) =>
          author.senderNumber !== action.senderNumber
            ? author
            : {
                ...author,
                messages: action.messages
              }
        )
      };
    case REMOVE_MESSAGE_FROM_PINNED_AUTHOR:
      if (!state.authors?.some((author) => author.senderNumber === action.message.senderNumber))
        return state;

      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: (state.authors || []).map((author) =>
          author.senderNumber !== action.message.senderNumber
            ? author
            : {
                ...author,
                messages: author.messages.filter((msg) => msg.id !== action.message.id)
              }
        )
      };
    case ADD_MESSAGE_TO_PINNED_AUTHOR:
      if (!state.authors?.some((author) => author.senderNumber === action.message.senderNumber))
        return state;
      if (
        state.authors.some((author) => author.messages.some((msg) => msg.id === action.message.id))
      )
        return state;

      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: (state.authors || []).map((author) =>
          author.senderNumber !== action.message.senderNumber
            ? author
            : {
                ...author,
                messages: [...(author.messages || []), { ...action.message, isRead: false }],
                lastMessageId: action.message.id
              }
        )
      };
    case SET_PINNED_MESSAGES_AS_READ:
      if (!state.authors?.some((author) => author.senderNumber === action.senderNumber))
        return state;
      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: (state.authors || []).map((author) =>
          author.senderNumber !== action.senderNumber
            ? author
            : {
                ...author,
                lastReadMessageId: action.lastReadMessageId
              }
        )
      };
    case SET_MSG_FAV_STATUS: {
      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: (state.authors || []).map((author) => ({
          ...author,
          messages: (author.messages || []).map((message) =>
            message.id === action.id ? { ...message, favorite: action.value } : message
          )
        }))
      };
    }
    case SET_MSG_LIKE_STATUS: {
      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: (state.authors || []).map((author) => ({
          ...author,
          messages: (author.messages || []).map((message) =>
            message.id === action.id ? { ...message, isLike: action.value } : message
          )
        }))
      };
    }
    case ADD_RESPONSE_MESSAGE: {
      const targetAuthor = state.authors.find(
        (author) => author.senderNumber === action.senderNumber
      );
      if (!targetAuthor) return state;
      if (!(targetAuthor.messages || [] || []).some((msg) => msg.id === action.id)) return state;

      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: (state.authors || []).map((author) =>
          author.senderNumber !== action.senderNumber
            ? author
            : {
                ...author,
                messages: (author.messages || []).map((message) =>
                  message.id === action.id
                    ? {
                        ...message,
                        after: [
                          ...message.after,
                          {
                            ...action.message,
                            message_type: 'message',
                            isNew: true
                          }
                        ]
                      }
                    : message
                )
              }
        )
      };
    }
    case SET_MESSAGE_HIDDEN_STATE: {
      const targetAuthor = state.authors.find(
        (author) => author.senderNumber === action.senderNumber
      );
      if (!targetAuthor) return state;
      if (!(targetAuthor.messages || []).some((msg) => msg.id === action.id)) return state;

      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: (state.authors || []).map((author) =>
          author.senderNumber !== action.senderNumber
            ? author
            : {
                ...author,
                messages: (author.messages || []).map((msg) =>
                  msg.id === action.id ? { ...msg, isHidden: action.value } : msg
                )
              }
        )
      };
    }
    case ADD_LABEL_SUCCESS:
      const targetAuthor = state.authors.find(
        (author) => author.senderNumber === action.senderNumber
      );
      if (!targetAuthor) return state;
      if (!(targetAuthor.messages || []).some((msg) => msg.id === action.id)) return state;

      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: (state.authors || []).map((author) =>
          author.senderNumber !== action.senderNumber
            ? author
            : {
                ...author,
                messages: (author.messages || []).map((msg) =>
                  msg.id === action.id ? { ...msg, labels: [...msg.labels, action.label] } : msg
                )
              }
        )
      };
    case DELETE_LABEL_SUCCESS: {
      const targetAuthor = state.authors.find(
        (author) => author.senderNumber === action.senderNumber
      );
      if (!targetAuthor) return state;
      if (!(targetAuthor.messages || []).some((msg) => msg.id === action.id)) return state;

      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: (state.authors || []).map((author) =>
          author.senderNumber !== action.senderNumber
            ? author
            : {
                ...author,
                messages: (author.messages || []).map((msg) =>
                  msg.id === action.id
                    ? {
                        ...msg,
                        labels: msg.labels.filter((el) => el !== action.label)
                      }
                    : msg
                )
              }
        )
      };
    }
    case CLEAR_LABELS_SUCCESS: {
      const targetAuthor = state.authors.find(
        (author) => author.senderNumber === action.senderNumber
      );
      if (!targetAuthor) return state;
      if (!(targetAuthor.messages || []).some((msg) => msg.id === action.id)) return state;

      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: (state.authors || []).map((author) =>
          author.senderNumber !== action.senderNumber
            ? author
            : {
                ...author,
                messages: (author.messages || []).map((msg) =>
                  msg.id === action.id ? { ...msg, labels: [] } : msg
                )
              }
        )
      };
    }
    case REMOVE_MESSAGE: {
      const targetAuthor = state.authors.find(
        (author) => author.senderNumber === action.senderNumber
      );
      if (!targetAuthor) return state;
      if (!(targetAuthor.messages || []).some((msg) => msg.id === action.id)) return state;

      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: (state.authors || []).map((author) =>
          author.senderNumber !== action.senderNumber
            ? author
            : {
                ...author,
                messages: (author.messages || []).filter((el) => el.id !== action.id)
              }
        )
      };
    }
    case UPDATE_MESSAGE_AUDIO: {
      const targetAuthor = state.authors.find(
        (author) => author.senderNumber === action.senderNumber
      );
      if (!targetAuthor) return state;
      if (!(targetAuthor.messages || []).some((msg) => msg.id === action.id)) return state;

      return {
        ...state,
        authorsUpdatedFromSocket: action.updatedFromSocket,
        authors: (state.authors || []).map((author) =>
          author.senderNumber !== action.senderNumber
            ? author
            : {
                ...author,
                messages: (author.messages || []).map((message) =>
                  message.id !== action.id
                    ? message
                    : {
                        ...message,
                        attachments: message.attachments.map((media) =>
                          media.ogg !== action.prevSrc ? media : { ...media, ogg: action.newSrc }
                        )
                      }
                )
              }
        )
      };
    }
    default:
      return state;
  }
}
