import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import ActionCreators from 'store/actions';
import Thunks from 'store/thunks';

const useActions = () => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          ...ActionCreators,
          ...Thunks
        },
        dispatch
      ),
    [dispatch]
  );
  return actions;
};

export default useActions;
