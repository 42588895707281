import {
  CLEAR_SESSION_TEMP_SUCCESS,
  CREATE_SESSION_ERROR,
  CREATE_SESSION_SUCCESS,
  CREATE_SESSION_TEMP_SUCCESS,
  EXPORT_MESSAGE_SUCCESS,
  FETCH_SESSIONS_ERROR,
  FETCH_SESSIONS_START,
  FETCH_SESSIONS_SUCCESS,
  REMOVE_SESSIONS,
  SELECT_SESSION,
  SET_ENABLE_INTERACTIVES,
  SET_FILTERED_SESSIONS,
  SET_IS_LOADING_SESSIONS,
  SET_IS_SESSION_CREATED,
  SET_SCROLL_POSITION,
  SET_SESSIONS,
  SET_SESSION_ACTIVITY,
  UPDATE_SESSION_FROM_INFO,
  UPDATE_SESSION_INFO
} from '../actions/actionTypes';

import { INTERACTIVES, INTERACTIVE_ACTIVITIES } from 'store/constants';

const initialState = {
  isLoading: false,
  isSessionCreated: false,
  selectedSessions: [],
  sessions: [],
  tempSessions: [],
  enableInteractives: [],
  scrollPosition: 0
  // searchText: '',
  // isSearchActive: false,
  // isFilterActive: false,
  // isFiltered: false,
  // selectedToFilterInteractives: [],
};
export default function sessionsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ENABLE_INTERACTIVES:
      return {
        ...state,
        enableInteractives: action.enableInteractives
      };
    case FETCH_SESSIONS_START:
      return {
        ...state
      };
    case FETCH_SESSIONS_SUCCESS:
      return {
        ...state,
        sessions: action.sessions,
        isFiltered: false
      };
    case FETCH_SESSIONS_ERROR:
      return {
        ...state
      };
    case SELECT_SESSION:
      const selectedSessions = action.isMultiple
        ? [...state.selectedSessions, action.sessionId]
        : [action.sessionId];
      return {
        ...state,
        selectedSessions
      };
    case CREATE_SESSION_SUCCESS: {
      const { session } = action;
      if ((session.type === 'vote' || session.type === 'quiz') && session.broadcastName) {
        const {
          id,
          title,
          type,
          state: sessionState,
          visibility,
          vars,
          votes_count,
          description,
          memo,
          broadcastId,
          broadcastName,
          broadcastState,
          broadcastSettings,
          broadcastVisibility
        } = session;
        return {
          ...state,
          sessions: [
            {
              type,
              id,
              broadcastId,
              title,
              state: sessionState,
              visibility,
              vars,
              votes_count,
              description,
              memo
            },
            {
              type: 'broadcast',
              id: broadcastId,
              title: broadcastName,
              state: broadcastState,
              settings: broadcastSettings,
              visibility: broadcastVisibility,
              description,
              memo
            },
            ...state.sessions
          ]
        };
      }
      return {
        ...state,
        sessions: state.sessions.find(
          (el) => (el.id === session.id || el.id === session.iactive_id) && el.type === session.type
        )
          ? [
              ...state.sessions.map((el) => {
                if (
                  (el.id === session.id || el.id === session.iactive_id) &&
                  el.type === session.type
                ) {
                  return {
                    ...el,
                    ...session
                  };
                } else return el;
              })
            ]
          : [session, ...state.sessions]
      };
    }
    case CLEAR_SESSION_TEMP_SUCCESS: {
      return {
        ...state,
        tempSessions: []
      };
    }
    case CREATE_SESSION_TEMP_SUCCESS: {
      const { session } = action;
      if ((session.type === 'vote' || session.type === 'quiz') && session.broadcastName) {
        const {
          id,
          title,
          type,
          state: sessionState,
          visibility,
          vars,
          votes_count,
          description,
          memo,
          broadcastId,
          broadcastName,
          broadcastState,
          broadcastSettings,
          broadcastVisibility
        } = session;
        return {
          ...state,
          tempSessions: [
            {
              type,
              id,
              title,
              state: sessionState,
              visibility,
              vars,
              votes_count,
              description,
              memo
            },
            {
              type: 'broadcast',
              id: broadcastId,
              title: broadcastName,
              state: broadcastState,
              settings: broadcastSettings,
              visibility: broadcastVisibility,
              description,
              memo
            },
            ...state.tempSessions
          ]
        };
      }
      return {
        ...state,
        tempSessions: [session, ...state.tempSessions]
      };
    }
    case CREATE_SESSION_ERROR:
      return {
        ...state
      };
    case SET_IS_SESSION_CREATED:
      return {
        ...state,
        isSessionCreated: action.value
      };
    case SET_IS_LOADING_SESSIONS:
      if (state.tempSessions.length) {
        return {
          ...state,
          isLoading: action.isLoading,
          sessions: [
            ...state.tempSessions,
            ...state.sessions
              .slice(0, state.tempSessions.length)
              .filter(
                (session) =>
                  !state.tempSessions.some(
                    (tempSession) =>
                      session.id === tempSession.id && session.type === tempSession.type
                  )
              ),
            ...state.sessions.slice(state.tempSessions.length)
          ],
          tempSessions: []
        };
      } else {
        return {
          ...state,
          isLoading: action.isLoading
        };
      }
    case SET_SESSIONS:
      return {
        ...state,
        sessions: action.sessions
      };
    case SET_FILTERED_SESSIONS:
      return {
        ...state,
        sessions: state.sessions.map((el) =>
          action.toShow.includes(el.id) && action.interactives.includes(el.type)
            ? { ...el, isHidden: false }
            : { ...el, isHidden: true }
        )
      };
    case UPDATE_SESSION_FROM_INFO: {
      const { field, id, value } = action.info;
      return {
        ...state,
        sessions: state.sessions.map((session) =>
          session.id === id
            ? {
                ...session,
                [field]: value
              }
            : session
        )
      };
    }
    case REMOVE_SESSIONS:
      return {
        ...state,
        sessions: state.sessions.filter((el) => !action.toDel.includes(el.id.toString()))
      };
    case UPDATE_SESSION_INFO:
      const {
        id,
        notes,
        memo,
        description,
        interactiveName,
        settings,
        state: sessionState
      } = action.info;

      return {
        ...state,
        sessions: state.sessions.map((el) =>
          el.id === id
            ? {
                ...el,
                title: interactiveName || el.title,
                description: description || el.description,
                memo: notes || memo || el.memo,
                settings,
                state: sessionState
              }
            : el
        )
      };
    case EXPORT_MESSAGE_SUCCESS:
      return {
        ...state,
        sessions: state.sessions.map((el) =>
          el.id === action.interactiveId ? { ...el, message_count: +el.message_count + 1 } : el
        )
      };
    case SET_SESSION_ACTIVITY:
      const interactive = state.sessions.find((el) => el.id === action.interactiveId);
      return {
        ...state,
        sessions: state.sessions.map((el) => {
          if (el.id === action.interactiveId) {
            return {
              ...el,
              activity: action.activity
            };
          } else if (
            action.activity !== INTERACTIVE_ACTIVITIES.finished &&
            (el.type === interactive.type ||
              (el.type === INTERACTIVES.vote && interactive.type === INTERACTIVES.quiz) ||
              (el.type === INTERACTIVES.quiz && interactive.type === INTERACTIVES.vote)) &&
            el.activity === INTERACTIVE_ACTIVITIES.active &&
            el.type !== INTERACTIVES.registration
          ) {
            return {
              ...el,
              activity: INTERACTIVE_ACTIVITIES.finished
            };
          } else {
            return el;
          }
        })
      };
    case SET_SCROLL_POSITION:
      return {
        ...state,
        scrollPosition: action.scrollPosition
      };
    default:
      return state;
  }
}
