import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { joyrideCallback, setStepIcons } from 'helpers/guide';

export const useAutoReplyGuide = ({ type }) => {
  const [run, setRun] = useState(false);
  const { listPane } = useSelector((state) => state.resize);
  const [stepIndex, setStep] = useState(0);
  const interactiveInfoRef = useRef(null);
  const selectControlsRef = useRef(null);
  const okButtonRef = useRef(null);
  const controlsRef = useRef(null);
  const newsTypeRef = useRef(null);
  const chooseTypeRef = useRef(null);
  const textToSpeechRef = useRef(null);
  const contentViewRef = useRef(null);
  const startBroadcast = useRef(null);
  const contentViewRefToAutoReply = useRef(null);
  const greenRef = useRef(null);
  const chooseAnotherTypeRef = useRef(null);
  const handleJoyrideCallback = joyrideCallback(setRun, setStep);

  const setStepIndex = (clb) => {
    if (run) {
      setStep(clb);
    }
  };

  let steps = [
    {
      content: <h2>{window.t('autoReplyStep0')}</h2>,
      placement: 'center',
      target: 'body'
    },
    {
      content: <p>{window.t('autoReplyStep1')}</p>,
      spotlightPadding: 0,
      target: interactiveInfoRef.current || 'body',
      hideBackButton: true,
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'bottom',
      spotlightClicks: true
    },
    {
      content: <p>{window.t('autoReplyStep2')}</p>,
      spotlightPadding: 0,
      target: selectControlsRef.current || 'body',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'bottom',
      spotlightClicks: true,
      hideFooter: true
    },
    {
      content: (
        <p
          className="text-justify"
          dangerouslySetInnerHTML={{ __html: window.t('autoReplyStep2a') }}
        />
      ),
      spotlightPadding: 0,
      target: chooseAnotherTypeRef.current || 'body',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'bottom',
      spotlightClicks: true,
      hideFooter: true
    },
    {
      content: (
        <p
          className="text-justify"
          dangerouslySetInnerHTML={{ __html: window.t('autoReplyStep3') }}
        />
      ),
      spotlightPadding: 0,
      target: okButtonRef.current || 'body',
      disableBeacon: true,
      disableOverlayClose: true,
      hideFooter: true,
      placement: 'bottom',
      spotlightClicks: true
    },
    {
      content: <p>{window.t('autoReplyStep4')}</p>,
      spotlightPadding: 0,
      target: controlsRef.current,
      disableBeacon: true,
      disableOverlayClose: true,
      hideFooter: controlsRef.current?.childNodes.length >= 1,
      placement: 'bottom',
      spotlightClicks: true
    },
    {
      content: <p>{window.t('autoReplyStep5')}</p>,
      spotlightPadding: 0,
      target: newsTypeRef.current || 'body',
      disableBeacon: true,
      disableOverlayClose: true,
      hideFooter: true,
      placement: 'bottom',
      spotlightClicks: true
    },
    {
      content: (
        <p
          className="text-justify"
          dangerouslySetInnerHTML={{ __html: window.t('autoReplyStep6') }}
        />
      ),
      spotlightPadding: 0,
      target: chooseTypeRef.current || 'body',
      disableBeacon: true,
      disableOverlayClose: true,
      hideFooter: true,
      placement: 'bottom',
      spotlightClicks: true
    },
    {
      content: <p>{window.t('autoReplyStep7')}</p>,
      spotlightPadding: 0,
      target: contentViewRef.current || 'body',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'bottom',
      spotlightClicks: true
    },
    {
      content: <p>{window.t('autoReplyStep7a')}</p>,
      spotlightPadding: 0,
      target: greenRef.current || 'body',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'bottom',
      spotlightClicks: true
    },
    {
      content: <p>{window.t('autoReplyStep8')}</p>,
      spotlightPadding: 0,
      target: startBroadcast.current || 'body',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'bottom',
      spotlightClicks: true
    },
    {
      content: <p>{window.t('autoReplyStep9')}</p>,
      placement: 'bottom',
      target: listPane?.current || 'body',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'right'
    }
  ];

  controlsRef.current?.childNodes.length < 1 && steps.splice(5, 1);

  return {
    run,
    startGuide: () => {
      setStep(0);
      setRun(true);
    },
    steps,
    stepIndex,
    setStepIndex,
    handleJoyrideCallback,
    controlsRef,
    newsTypeRef,
    chooseTypeRef,
    textToSpeechRef,
    contentViewRef,
    interactiveInfoRef,
    selectControlsRef,
    okButtonRef,
    startBroadcast,
    contentViewRefToAutoReply,
    greenRef,
    chooseAnotherTypeRef
  };
};
