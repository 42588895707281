import AddGroupCardModal from '../../AddGroupCardModal/AddGroupCardModal';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import reactStringReplace from 'react-string-replace';

import { Avatar, Button, CircularLoader, Icon, Text } from 'components';

import { getWhatsAppQR, getWhatsAppStatus } from 'api/dataLayer';

import styles from './AddPhoneModal.module.css';

const propTypes = {
  setIsModalActive: PropTypes.func,
  setQrUrl: PropTypes.func
};

const defaultProps = {
  setIsModalActive: () => {},
  setQrUrl: () => {}
};

function AddPhoneModal({ setIsModalActive, setUserInfo }) {
  const [qrImage, setQrImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const replaceStep = (text) => {
    let replacedText = reactStringReplace(text, /#([а-яА-ЯЁё\s\w]+)#/g, (match, i) => (
      <strong key={match + i}>{match}</strong>
    ));

    replacedText = reactStringReplace(replacedText, /\[([\s\w]+)\]/g, (match, i) => (
      <Icon className="align-middle" size={24} key={match + i} icon={match} />
    ));

    return replacedText;
  };

  const updateQR = async () => {
    setIsLoading(true);
    try {
      const res = await getWhatsAppQR();
      const qrImg = res?.body;
      const code = res?.code;
      if (qrImg && code === 200) setQrImage(qrImg);
    } catch (ex) {
      setQrImage('');
      console.log(ex);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const fd = async () => {
      await updateQR();
    };
    fd();
  }, []);

  useEffect(() => {
    if (qrImage) {
      const checkStatus = async () => {
        try {
          const res = await getWhatsAppStatus();
          const user = res?.body;
          if (!user) {
            throw new Error('some error');
          }

          if (!user.length) {
            // delete string below if tou don't need loading between update QR code
            setQrImage('');
            await updateQR();
          }

          if (user.length) {
            setIsModalActive(false);
            setUserInfo(
              Object.values(user).map((el) => ({
                groupId: el.groupId,
                image: el.image,
                pushname: el.pushname
              }))
            );
          }
        } catch (error) {
          setQrImage('');
          console.error(error);
        }
      };
      checkStatus();
    }
  }, [qrImage]);

  const loadQr = () => {
    updateQR();
  };

  return (
    <AddGroupCardModal
      title={window.t('connectWhatsappNumber')}
      closeHandler={() => setIsModalActive(false)}
      headerColor="#31d74e"
      style={{
        width: '90%',
        maxWidth: 810
      }}>
      <div className="d-flex flex-wrap-reverse">
        {qrImage ? (
          <Avatar
            src={qrImage}
            size={200}
            style={{ marginRight: '70px', marginTop: '-10px' }}
            rounded={0}
          />
        ) : isLoading ? (
          <div
            className="d-flex justify-center align-center"
            style={{
              width: 200,
              height: 200,
              marginRight: '70px'
            }}>
            <CircularLoader size={50} />
          </div>
        ) : (
          <Button
            clickHandler={loadQr}
            className={styles['refresh-qr-btn']}
            startIcon={<Icon size={48} icon="reload" />}>
            Нажмите, чтобы перезагрузить QR-код
          </Button>
        )}

        <div>
          <Text size={16} className="mb-4">
            {window.t('toConnectWhatsApp')}
          </Text>
          <ul>
            <li className="mb-3">{window.t('toConnectWhatsAppStep1')}</li>
            <li className="mb-3">{replaceStep(window.t('toConnectWhatsAppStep2'))}</li>
            <li className="mb-3">{window.t('toConnectWhatsAppStep3')}</li>
          </ul>
        </div>
      </div>
    </AddGroupCardModal>
  );
}

export default AddPhoneModal;

AddPhoneModal.propTypes = propTypes;
AddPhoneModal.defaultProps = defaultProps;
