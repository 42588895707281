import { ExportButtonsSettingsView } from './ExportButtonsSettingsView';
import PropTypes from 'prop-types';
import React, { forwardRef, memo } from 'react';

import { useExportButtons } from './hooks/useExportButtons';

const propTypes = {
  editRequest: PropTypes.func.isRequired,
  settings: PropTypes.object
};

const defaultProps = {
  buttonsField: 'settings'
};

export const ExportButtonsSettingsContainer = memo(
  forwardRef(({ sessionId, editRequest, settings, buttonsField, activity, setStepIndex }, ref) => {
    const { addButton, removeButton, settingsState, updateRequest, updateValue } = useExportButtons(
      {
        settings,
        sessionId,
        editRequest,
        buttonsField,
        activity
      }
    );

    return (
      <ExportButtonsSettingsView
        ref={ref}
        addButton={() => {
          setStepIndex((v) => ++v);
          addButton();
        }}
        removeButton={removeButton}
        settingsState={settingsState}
        updateRequest={updateRequest}
        updateValue={updateValue}
      />
    );
  })
);

ExportButtonsSettingsContainer.propTypes = propTypes;
ExportButtonsSettingsContainer.defaultProps = defaultProps;
