import ChartStats from './ChartStats/ChartStats';
import ChartStatsHeader from './ChartStatsHeader/ChartStatsHeader';
import ChartTimer from './ChartTimer/ChartTimer';
import PulseModal from './PulseModal/PulseModal';
import ToolbarControls from './ToolbarControls/ToolbarControls';
import { addMilliseconds, addMinutes, format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { InteractiveSettingsContainer } from 'containers/InteractiveSettingsContainer/InteractiveSettingsContainer';

import { DateTimePicker, InteracitveNotFoundView, SessionModuleView, Toolbar } from 'components';

import { updateInfoField } from 'store/actions/isettings';
import { setTimerActivity } from 'store/actions/pulse';
import { MIN_CHART_DURATION } from 'store/constants';
import { changeSessionInfo, setISettingsInfoFromSession } from 'store/thunks/isettings';
import { getPulseData, setEndTime, setStartTime, start, stop } from 'store/thunks/pulse';

class Pulse extends React.Component {
  state = {
    isSettingsOpened: false,
    isStartPickerActive: false,
    isEndPickerActive: false,
    isStartDialogOpened: false,
    isEndDialogOpened: false,
    startMessage: '',
    endMessage: ''
  };

  componentWillMount() {
    const id = this.props.match.params.id;
    // this.props.setISettingsInfoFromSession(id, 'pulse');
    this.props.getPulseData(id);
    // this.props.fetchBroadcastMessages(this.props.match.params.id);
  }

  componentWillUnmount() {
    // this.props.resetBroadcastState();
  }

  toggleSettings = () => {
    const isSettingsOpened = !this.state.isSettingsOpened;
    this.setState({
      isSettingsOpened
    });
  };

  toggleStartPicker = () => {
    const isStartPickerActive = !this.state.isStartPickerActive;
    this.setState({
      isStartPickerActive
    });
  };

  toggleEndPicker = () => {
    const isEndPickerActive = !this.state.isEndPickerActive;
    this.setState({
      isEndPickerActive
    });
  };

  toggleStartDialog = () => {
    const isStartDialogOpened = !this.state.isStartDialogOpened;
    this.setState({
      isStartDialogOpened
    });
  };

  toggleEndDialog = () => {
    const isEndDialogOpened = !this.state.isEndDialogOpened;
    this.setState({
      isEndDialogOpened
    });
  };

  makeStartDialogActive = () => {
    const { chartStart, chartEnd } = this.props.fullData;
    const diff = +new Date(chartEnd) - +new Date(chartStart);
    const startDate = new Date();
    const endDate = addMilliseconds(startDate, diff);
    const startMessage = `Вы действительно хотите установить график в промежутке 
    от ${format(startDate, 'HH:mm')} до ${format(endDate, 'HH:mm')}.`;
    this.setState({
      startMessage
    });
    this.toggleStartDialog();
  };

  startAcceptHandler = () => {
    this.toggleStartDialog();
    this.props.start();
  };

  stopAcceptHandler = () => {
    this.toggleEndDialog();
    this.props.stop();
  };

  render() {
    const localeState = this.context[0];
    const {
      info,
      isInteractiveExist,
      start,
      stop,
      setStartTime,
      setEndTime,
      updateInfoField,
      changeSessionInfo,
      match,
      isTimerActive,
      fullData
    } = this.props;

    const { chartStart, chartEnd, activity } = this.props.fullData;
    // let minimumTime = format(new Date(addMinutes(new Date(chartStart), 15)), 'HH:mm');
    let minTime = chartStart
      ? format(new Date(addMinutes(new Date(chartStart), MIN_CHART_DURATION)), 'HH:mm')
      : null;
    return (
      <SessionModuleView>
        <Toolbar title={window.t('etherPulse')}>
          <ToolbarControls
            toggleSettings={this.toggleSettings}
            toggleStartPicker={this.toggleStartPicker}
            toggleEndPicker={this.toggleEndPicker}
            startPulse={start}
            stopPulse={stop}
            activity={activity}
            startTime={chartStart}
            endTime={chartEnd}
            setStartTime={setStartTime}
            setEndTime={setEndTime}
          />
        </Toolbar>
        <div style={{ height: '100%' }}>
          {isInteractiveExist ? (
            <>
              {this.state.isSettingsOpened && (
                <InteractiveSettingsContainer onCloseHandler={this.toggleSettings} />
              )}
              <PulseModal
                isActive={this.state.isStartDialogOpened}
                message={this.state.startMessage}
                cancelHandler={this.toggleStartDialog}
                acceptHandler={this.startAcceptHandler}
              />
              <PulseModal
                isActive={this.state.isEndDialogOpened}
                message="Вы действительно хотите остановить сбор данных?"
                cancelHandler={this.toggleEndDialog}
                acceptHandler={this.stopAcceptHandler}
              />
              {chartStart && (
                <DateTimePicker
                  isActive={this.state.isStartPickerActive}
                  togglePicker={this.toggleStartPicker}
                  dateTime={chartStart}
                  setDateTime={setStartTime}
                />
              )}
              {chartEnd && (
                <DateTimePicker
                  isActive={this.state.isEndPickerActive}
                  togglePicker={this.toggleEndPicker}
                  dateTime={chartEnd}
                  setDateTime={setEndTime}
                  minDate={new Date(chartStart)}
                  minTime={minTime}
                />
              )}
              <ChartStatsHeader isActive={false} chartStart={chartStart} chartEnd={chartEnd} />

              {isTimerActive && (
                <ChartTimer startTime={chartStart} setTimerActivity={setTimerActivity} />
              )}
              <ChartStats id={match.params.id} />
              {Object.keys(fullData).map((key) => {
                return (
                  <p key={key}>
                    {key}:{fullData[key]}
                  </p>
                );
              })}
            </>
          ) : (
            <InteracitveNotFoundView
              message={window.t('pulseDoesNotExist').replace('[id]', match.params.id)}
            />
          )}
        </div>
      </SessionModuleView>
    );
  }
}

function mapStateToProps(state) {
  return {
    info: state.isettings.info,
    isInteractiveExist: state.isettings.isInteractiveExist,
    fullData: state.pulse.fullData,
    isTimerActive: state.pulse.isTimerActive
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateInfoField: (field, value) => {
      dispatch(updateInfoField(field, value));
    },
    changeSessionInfo: () => {
      dispatch(changeSessionInfo());
    },
    setISettingsInfoFromSession: (id, type) => {
      dispatch(setISettingsInfoFromSession(id, type));
    },
    getPulseData: (id) => {
      dispatch(getPulseData(id));
    },
    setStartTime: (dateTime) => dispatch(setStartTime(dateTime)),
    setEndTime: (dateTime) => dispatch(setEndTime(dateTime)),
    start: () => dispatch(start()),
    stop: () => dispatch(stop()),
    setTimerActivity: (isActive) => dispatch(setTimerActivity(isActive))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Pulse);

Pulse.propTypes = {
  info: PropTypes.object,
  isInteractiveExist: PropTypes.bool,
  fullData: PropTypes.object,
  isTimerActive: PropTypes.bool
};
