import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { BroadcastMessagesContainer, BroadcastStateContainer } from 'containers';

import styles from './BroadcastBodyView.module.css';

const propTypes = {
  broadcastId: PropTypes.string,
  isBroadcastStateFixed: PropTypes.bool,
  setIsBroadcastStateFixed: PropTypes.func
};

export const BroadcastBodyView = memo(
  ({
    broadcastId,
    isBroadcastStateFixed,
    setIsBroadcastStateFixed,
    statesPanelRef,
    setStepIndex
  }) => {
    return (
      <div className={styles['module-body']}>
        <BroadcastStateContainer
          ref={statesPanelRef}
          isBroadcastStateFixed={isBroadcastStateFixed}
          setIsBroadcastStateFixed={setIsBroadcastStateFixed}
          setStepIndex={setStepIndex}
        />
        <BroadcastMessagesContainer
          isBroadcastStateFixed={isBroadcastStateFixed}
          broadcastId={broadcastId}
        />
      </div>
    );
  }
);

BroadcastBodyView.propTypes = propTypes;
