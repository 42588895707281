import {
  ADD_MESSAGES_TO_PINNED_AUTHOR,
  ADD_MESSAGE_TO_PINNED_AUTHOR,
  REMOVE_MESSAGE_FROM_PINNED_AUTHOR,
  SET_PINNED_AUTHORS,
  UPDATE_PINNED_AUTHORS,
  UPDATE_PINNED_AUTHOR_MESSAGES
} from './actionTypes';

export function setPinnedAuthors(authors, updatedFromSocket = false) {
  return {
    type: SET_PINNED_AUTHORS,
    authors,
    updatedFromSocket
  };
}

export function updatePinnedAuthors(authors, updatedFromSocket = false) {
  return {
    type: UPDATE_PINNED_AUTHORS,
    authors,
    updatedFromSocket
  };
}

export function addMessagesToPinnedAuthor(senderNumber, messages, updatedFromSocket = false) {
  return {
    type: ADD_MESSAGES_TO_PINNED_AUTHOR,
    senderNumber,
    messages,
    updatedFromSocket
  };
}

export function updatePinnedAuthorMessages(senderNumber, messages, updatedFromSocket = false) {
  return {
    type: UPDATE_PINNED_AUTHOR_MESSAGES,
    senderNumber,
    messages,
    updatedFromSocket
  };
}

export function addMessageToPinnedAuthor(message) {
  return {
    type: ADD_MESSAGE_TO_PINNED_AUTHOR,
    message
  };
}

export function removeMessageFromPinnedAuthor(message) {
  return {
    type: REMOVE_MESSAGE_FROM_PINNED_AUTHOR,
    message
  };
}
