import ChartLegends from './ChartLegends/ChartLegends';
import ReactECharts from 'echarts-for-react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import useActions from 'hooks/useActions';

import styles from './ChartStats.module.css';

function ChartStats({ id }) {
  const { getChartData } = useActions();
  const echartRef = useRef(null);
  const { option, isChartLoading } = useSelector((state) => state.pulseChart);

  useEffect(() => {
    getChartData(id);
  }, [getChartData]);

  return (
    <>
      <ChartLegends echartRef={echartRef} />
      <div className={styles['chart-wrapper']}>
        <ReactECharts
          ref={echartRef}
          option={option}
          showLoading={isChartLoading}
          loadingOption={{
            text: window.t('loading')
          }}
          style={{ width: '117%', left: '-8%', height: 400 }}
        />
      </div>
    </>
  );
}

export default ChartStats;

ChartStats.propTypes = {
  id: PropTypes.string
};

// state = {
//   option: {
//     tooltip: {
//       trigger: 'axis',
//       formatter: function (params) {
//         const formatter = (val) => (val < 10) ? "0" + val : val;
//         let date = new Date(params[0].name);
//         let h = formatter(date.getHours());
//         let min = formatter(date.getMinutes());
//         var res = h + ":" + min;
//         for (var i = 0, l = params.length; i < l; i++) {
//           res += '<br/>' + params[i].seriesName + " " + params[i].value[1];
//         }
//         return res;
//       },
//       axisPointer: {
//         animation: false
//       }
//     },
//     legend: {
//       show: false,
//       data: ['Все сообщения:', 'Авторы:'],//['Все сообщения:', 'Авторы:', 'Новые авторы:'],
//       align: 'left',
//       textStyle: {
//         padding: [0, 100, 0, 0],
//       }
//     },
//     toolbox: {
//       show: false,
//       right: 100,
//       feature: {
//         restore: {
//           title: 'Сбросить'
//         },
//         saveAsImage: {
//           title: 'Скачать'
//         }
//       }
//     },
//     dataZoom: [
//       {
//         type: 'slider',
//         start: 0,
//         end: 100,
//         zoomLock: true,
//         brushSelect: false
//       },
//       {
//         type: 'inside',
//         zoomLock: true,
//       }
//     ],
//     xAxis: {
//       type: 'time',
//       maxInterval: 6 * 60000,//2 * 60000,
//       splitLine: {
//         show: false
//       },
//       axisLabel: {
//         formatter: function (value) {
//           const formatter = (val) => (val < 10) ? "0" + val : val;
//           let date = new Date(value);
//           let h = formatter(date.getHours());
//           let m = formatter(date.getMinutes());
//           return h + ":" + m;
//         }
//       },
//     },
//     yAxis: {
//       type: 'value',
//       boundaryGap: [0, '100%'],
//       splitNumber: 1,
//       splitLine: {
//         show: false
//       }
//     },
//     series: [{
//       name: 'Все сообщения:',
//       type: 'bar',
//       showSymbol: false,
//       hoverAnimation: false,
//       data: [],
//       label: {
//         normal: {
//           show: true,
//           position: 'top'
//         }
//       }
//     },
//     {
//       name: 'Авторы:',
//       type: 'line',
//       showSymbol: false,
//       hoverAnimation: false,
//       data: [],
//       label: {
//         normal: {
//           show: false,
//           position: 'top'
//         }
//       },
//     },
//     ]
//   }
// }
