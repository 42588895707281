import classNames from 'classnames';
import { Fragment } from 'react';

import { MessageActionsMenuContainer } from 'containers';

import { Button, Icon, Message } from 'components';

import styles from './PinnedAuthors.module.css';

export function PinnedAuthorBody({
  author,
  isNewAtTop,
  className,
  messages,
  userInformation,
  togglePinnedAuthor,
  isFeedbackEnabled,
  answer,
  locale,
  messageActionsConfig,
  broadcastId,
  toggleFavorite,
  activeBroadcastData,
  removeMessageFromPinnedAuthor
}) {
  return (
    <div key={author.senderNumber} className={classNames(className, styles.body)}>
      {!messages.length && <p className={styles.emptyMessage}>{window.t('noNewMessages')}</p>}
      {[...(messages || [])].reverse().map((el, i) => {
        return (
          <Fragment key={el.id}>
            <div data-items="true">
              <Message
                // control={
                //   <Button
                //     title={window.t("detach")}
                //     onlyIcon={true}
                //     startIcon={<Icon size={16} fontName="fad fa-trash" />}
                //     variant="icon"
                //     color={"red"}
                //     style={{
                //       marginLeft: "auto",
                //     }}
                //     clickHandler={() => removeMessageFromPinnedAuthor(el)}
                //   />
                // }
                canHide={false}
                userInformation={userInformation}
                isAuthorPinned
                togglePinnedAuthor={() => togglePinnedAuthor(el, messages)}
                isLast={i === author.messages?.length - 1}
                locale={locale}
                quickReplies={answer}
                withFeedback={isFeedbackEnabled}
                data={el}
                type="messageFeed"
                // isSelected={isSelected(el.id)}
                // selectMessage={messageClickHandler}
                messageActionsConfig={messageActionsConfig}
                isDraggable={!!broadcastId}
                settingsMenu={<MessageActionsMenuContainer message={el} />}
                toggleFavorite={toggleFavorite}
                activeBroadcastData={activeBroadcastData}
                isPinnedMessage
              />
            </div>
          </Fragment>
        );
      })}
    </div>
  );
}
