export const TABS = {
  messages: 'messages',
  news: 'news',
  advertisements: 'advertisements',
  weather: 'weather'
};

export const RESPONSE_KEYS = {
  messages: 'Messages',
  news: 'News',
  advertisements: 'Advertisements',
  weather: 'Weather'
};

export const AUTHOR_KEY_BY_TAB = {
  news: 'rss_name',
  messages: 'senderNumber',
  default: 'senderNumber'
};

export const DATE_KEY_BY_TAB = {
  news: 'pubDate',
  messages: 'date',
  default: 'date'
};
