import classNames from 'classnames';
import { useState } from 'react';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';

import useActions from 'hooks/useActions';

import { ResponseTypes } from 'store/constants';

import styles from './FeedbackActions.module.css';

function FeedbackActions({
  id,
  senderNumber,
  channel,
  isActive,
  setIsActive,
  likeText,
  actionsRef
}) {
  const { sendResponseMessage } = useActions();
  const [isLiked, setIsLiked] = useState(false);

  const send = (text, type) => {
    sendResponseMessage(
      {
        id,
        senderNumber,
        channel
      },
      {
        text,
        type
      }
    );
  };

  return (
    <div ref={actionsRef} className={styles.feedbackActions}>
      <Button
        startIcon={<Icon size={20} fontName="fal fa-share" />}
        onlyIcon
        variant="message-action"
        color={isActive ? '#0088EE' : '#313131'}
        clickHandler={() => {
          setIsActive((v) => !v);
        }}
        title={window.t('openChat')}
        style={{
          display: 'inline-flex',
          marginLeft: 6,
          padding: 0,
          ...(isActive && { opacity: 1 }),
          transform: 'scale(-1, 1)'
        }}
      />
      <Button
        startIcon={<Icon size={20} fontName="fal fa-thumbs-up" />}
        isLoading={!!(likeText && !likeText?.trim())}
        isDisabled={!!likeText}
        onlyIcon
        variant="message-action"
        color={likeText || isLiked ? 'primary' : '#313131'}
        clickHandler={() => {
          if (!likeText) setIsLiked(true);
          send('', ResponseTypes.like);
        }}
        className={classNames(styles.feedbackActions__like, {
          [styles['feedbackActions__like--animation']]: isLiked
        })}
        title={likeText?.trim() || window.t('positiveReply')}
        style={{
          display: 'inline-flex',
          marginLeft: 6,
          padding: 0,
          ...(likeText?.trim() && { opacity: 1 })
        }}
      />
      <div
        className={classNames(styles.feedbackActions__likeText, {
          [styles['feedbackActions__likeText--animation']]: isLiked && likeText?.trim()
        })}>
        {likeText?.trim()}
      </div>
    </div>
  );
}

export default FeedbackActions;
