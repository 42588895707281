import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useActions from 'hooks/useActions';
import useLocalStorage from 'hooks/useLocalStorage';

export const useVoteMessages = ({ broadcastId }) => {
  const {
    fetchBroadcastMessages,
    setFavoriteState,
    setVoteMessagesOrder,
    fetchBroadcastMessagesSuccess
  } = useActions();

  const { messages, lastExportedMessages, activeBroadcastData } = useSelector(
    (state) => state.broadcast
  );
  const isMessagesReversed = useSelector((state) => state.vote.isMessagesReversed);
  const { id: activeBroadcastId } = activeBroadcastData || {};

  const [isBroadcastMsgState, setIsBroadcastMsgState] = useState(false);
  const [activeBroadcastDataState, setActiveBroadcastDataState] = useState(null);
  const [isOnlyWinners, setIsOnlyWinners] = useLocalStorage(false);

  useEffect(() => {
    setIsBroadcastMsgState(activeBroadcastData == null || broadcastId === activeBroadcastId);
  }, [activeBroadcastData, broadcastId, activeBroadcastId]);

  useEffect(() => {
    setActiveBroadcastDataState(broadcastId === activeBroadcastId ? null : activeBroadcastData);
  }, [activeBroadcastData, broadcastId, activeBroadcastId]);

  useEffect(() => {
    if (!broadcastId) return;
    // dispatch(getBroadcastInfoForVote(broadcastId));
    fetchBroadcastMessages(broadcastId, isMessagesReversed);
    return () => {
      fetchBroadcastMessagesSuccess({ messages: [] });
    };
  }, [broadcastId, fetchBroadcastMessages, fetchBroadcastMessagesSuccess, isMessagesReversed]);

  const toggleMessagesOrder = () => setVoteMessagesOrder(!isMessagesReversed);

  return {
    messages,
    isMessagesReversed,
    toggleMessagesOrder,
    isOnlyWinners,
    setIsOnlyWinners,
    isBroadcastMsgState,
    lastExportedMessages,
    setFavoriteState,
    activeBroadcastDataState
  };
};
