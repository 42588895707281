import { ContentToolbarControls } from '../ContentToolbarControls/ContentToolbarControls';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { Toolbar } from 'components';

const propTypes = {
  interactiveName: PropTypes.string,
  toggleSettings: PropTypes.func
};

export const ContentToolbarView = memo(({ interactiveName, toggleSettings }) => {
  return (
    <div>
      <Toolbar title={interactiveName || window.t('content')}>
        <ContentToolbarControls settingsClickHandler={toggleSettings} />
      </Toolbar>
    </div>
  );
});

ContentToolbarView.propTypes = propTypes;
