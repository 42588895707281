import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Button, Icon } from 'components';

import styles from './MediaSearchMode.module.css';

function MediaSearchMode({
  searchConditions,
  setSearchConditions,
  activeSearchMode,
  scrollBoxRef
}) {
  const mediaTypes = [
    {
      type: 'image',
      icon: 'fas fa-image',
      padding: '9px 9px'
    },
    {
      type: 'audio_msg',
      icon: 'fas fa-volume-up',
      padding: '9px 8.5px'
    },
    {
      type: 'video',
      icon: 'fas fa-file-video',
      padding: '9px 12px'
    },
    {
      type: 'location',
      icon: 'fas fa-map-marker-alt',
      padding: '9px 12px'
    },
    {
      type: 'link',
      icon: 'fas fa-external-link',
      padding: '9px 10px'
    },
    {
      type: 'any'
    }
  ];
  const classes = classNames(styles['pane'], {
    hidden: activeSearchMode !== 'media'
  });

  const { media } = searchConditions;

  const setMediaType = (value) => {
    const newVal = media === value ? null : value;
    setSearchConditions('media', newVal, scrollBoxRef);
  };

  const getButtonClasses = (isActive) => {
    return classNames({
      [styles['selected']]: isActive
    });
  };

  return (
    <div id="searchModeMediaTab" className={classes}>
      <p className={styles['title']}>{window.t('searchByMedia')}</p>
      <ul className="d-flex justify-center align-center">
        {mediaTypes.map(({ type, icon, padding }, i) => {
          if (type === 'any') return null;

          return (
            <Button
              startIcon={<Icon size={15} fontName={icon} />}
              clickHandler={() => {
                setMediaType(type);
              }}
              className={getButtonClasses(media === type)}
              onlyIcon
              color="#cccccc"
              hoverColor="#dddddd"
              textColor="#000000"
              variant="contained"
              style={{
                borderRadius: 50,
                padding,
                margin: '0 1px 7px'
              }}
              size="small"
              key={i}
            />
          );
        })}
        <Button
          clickHandler={() => {
            setMediaType('any');
          }}
          className={getButtonClasses(media === 'any')}
          color="#cccccc"
          hoverColor="#dddddd"
          textColor="#000000"
          variant="contained"
          style={{
            borderRadius: 50,
            width: 32,
            height: 32,
            display: 'inline-block',
            margin: '0 1px 7px'
          }}
          size="small">
          {' '}
          <span>&nbsp;{window.t('any')}&nbsp;</span>
        </Button>
      </ul>
    </div>
  );
}

export default MediaSearchMode;

MediaSearchMode.propTypes = {
  searchConditions: PropTypes.object,
  setSearchConditions: PropTypes.func,
  activeSearchMode: PropTypes.string
};
