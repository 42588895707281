import React from 'react';
import { connect } from 'react-redux';

import Avatar from 'components/Avatar/Avatar';
import BlurWrapper from 'components/BlurWrapper/BlurWrapper';
import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';

import { setModalCurrentImage } from 'store/actions/modalImage';
import { closeModalImage } from 'store/thunks/modalImage';

import { getUIDateFromDBDate } from 'helpers/dateTime';

import styles from './ModalImage.module.css';

class ModalWindowImage extends React.Component {
  state = {
    currentImageIndex: 0,
    currentImageDate: ''
  };

  componentDidMount() {
    const { imageList } = this.props;
    const index = imageList.findIndex((el) => el.src === this.props.currentImage);
    this.setState({
      currentImageIndex: index,
      currentImageDate: imageList[index].publishDate
    });
    document.addEventListener('keydown', this.keydownHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keydownHandler);
  }

  closeModal = () => {
    this.props.closeModalImage();
  };

  keydownHandler = (event) => {
    if (event.key === 'ArrowLeft') this.prevImage();
    else if (event.key === 'ArrowRight') this.nextImage();
  };

  nextImage = () => {
    const { imageList } = this.props;
    const currentIndex = this.state.currentImageIndex;
    const totalCount = imageList.length;
    const nextIndex = currentIndex + 1 >= totalCount ? 0 : currentIndex + 1;
    this.setState({
      currentImageIndex: nextIndex,
      currentImageDate: imageList[nextIndex].publishDate
    });

    const nextImage = imageList[nextIndex].src;
    this.props.setModalCurrentImage(nextImage);
  };

  prevImage = () => {
    const { imageList } = this.props;
    const currentIndex = this.state.currentImageIndex;
    const totalCount = imageList.length;
    const nextIndex = currentIndex - 1 < 0 ? totalCount - 1 : currentIndex - 1;
    this.setState({
      currentImageIndex: nextIndex,
      currentImageDate: imageList[nextIndex].publishDate
    });

    const nextImage = imageList[nextIndex].src;
    this.props.setModalCurrentImage(nextImage);
  };

  render() {
    const totalCount = this.props.imageList.length;

    return (
      <BlurWrapper isOpened={this.props.isOpened} clickHandler={() => {}}>
        <div
          className={styles['modal-window-image']}
          style={{ backgroundImage: `url("${this.props.currentImage}")` }}>
          <div className={styles['modal-window-image__header']}>
            <Avatar src={this.props.userInfo.avatar} />
            <div className={styles['modal-window-image__header-info']}>
              <a className={styles['modal-window-image__header-info-name']} href="#">
                {this.props.userInfo.name}
              </a>
              <p className={styles['modal-window-image__header-info-date']}>
                {getUIDateFromDBDate(this.state.currentImageDate)}
              </p>
            </div>
          </div>
          <Button
            startIcon={<Icon size={40} fontName="fal fa-times" />}
            onlyIcon
            color="#cccccc"
            style={{
              zIndex: 11,
              position: 'fixed',
              right: 12,
              top: 0,
              opacity: 1
            }}
            variant="message-action"
            clickHandler={this.closeModal}
          />
          {totalCount > 1 && (
            <>
              <Button
                startIcon={<Icon size={64} fontName="far fa-angle-left" />}
                onlyIcon
                color="#cccccc"
                style={{
                  zIndex: 1,
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  opacity: 1,
                  height: '100%',
                  width: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: 13,
                  justifyContent: 'flex-start'
                }}
                variant="message-action"
                clickHandler={this.prevImage}
              />
              <Button
                startIcon={<Icon size={64} fontName="far fa-angle-right" />}
                onlyIcon
                color="#cccccc"
                style={{
                  zIndex: 1,
                  position: 'fixed',
                  top: 0,
                  right: 0,
                  opacity: 1,
                  height: '100%',
                  width: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: 13,
                  justifyContent: 'flex-end'
                }}
                variant="message-action"
                clickHandler={this.nextImage}
              />
              <div className={styles['modal-window-image__desc']}>
                {this.state.currentImageIndex + 1} из {totalCount}
              </div>
            </>
          )}
        </div>
      </BlurWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.modalImage.userInfo,
    imageList: state.modalImage.imageList,
    currentImage: state.modalImage.currentImage,
    isOpened: state.modalImage.isOpened
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModalImage: () => dispatch(closeModalImage()),
    setModalCurrentImage: (image) => dispatch(setModalCurrentImage(image))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalWindowImage);

ModalWindowImage.propTypes = {};
