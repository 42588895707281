import { PictureLoaderView } from './PictureLoaderView';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { centerCrop, makeAspectCrop } from 'react-image-crop';

import { PICTURE_LOADER_TYPES } from 'store/constants';

import { usePictureLoader } from './hooks/usePictureLoader';

const propTypes = {
  style: PropTypes.object,
  changeHandler: PropTypes.func,
  picture: PropTypes.string,
  iconSize: PropTypes.number,
  btnStyle: PropTypes.object,
  deleteHandler: PropTypes.func,
  type: PropTypes.oneOf(Object.values(PICTURE_LOADER_TYPES))
};

const defaultProps = {
  iconSize: 40,
  btnStyle: {
    padding: '12px 3px'
  },
  type: 'default'
};

export const PictureLoaderContainer = React.memo(
  ({
    className,
    style,
    changeHandler,
    picture,
    iconSize,
    btnStyle,
    isImageUploading,
    deleteHandler,
    type,
    id,
    name,
    multiple,
    isValid = true,
    content_rule,
    // exported news from the news feed
    isExported,
    isExportedToggleHandler
  }) => {
    const { setIsHover, openModal, isModal, closeBtnStyles } = usePictureLoader({ type, picture });

    const [isShownModal, setIsShownModal] = useState(false);
    const [crop, setCrop] = useState();
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [fileName, setFileName] = useState(null);

    function onImageLoad(event) {
      setImage(event.currentTarget);

      if (content_rule && content_rule['aspect_width'] && content_rule['aspect_height']) {
        const { naturalWidth: width, naturalHeight: height } = event.currentTarget;

        const crop = centerCrop(
          makeAspectCrop(
            {
              unit: '%',
              width: 100
            },
            content_rule['aspect_width'] / content_rule['aspect_height'],
            width,
            height
          ),
          width,
          height
        );
        setCrop(crop);
      }
    }

    function imageCrop(crop) {
      setCrop(crop);
    }

    function onSelectFile(e) {
      if (e.target.files && e.target.files.length > 0) {
        setFileName(e.target.files[0].name);
        setCrop(undefined); // Makes crop preview update between images.
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          setImageUrl(reader.result?.toString() || '');
          setIsShownModal(true);
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    }

    const makeClientCrop = async () => {
      // если новость с фото из ленты новостей экспортирована
      if (isExported !== undefined) {
        isExportedToggleHandler(false);
      }

      if ((image, crop?.width && crop?.height)) {
        const croppedImage = await getCroppedImage(image, crop, fileName);

        let file = await fetch(croppedImage)
          .then((r) => r.blob())
          .then(
            (blobFile) =>
              new File([blobFile], fileName, {
                type: `image/${fileName.split('.').pop()}`
              })
          );
        changeHandler({
          target: {
            files: [file]
          }
        });
      } else {
        let file = await fetch(imageUrl)
          .then((r) => r.blob())
          .then(
            (blobFile) =>
              new File([blobFile], fileName, {
                type: `image/${fileName.split('.').pop()}`
              })
          );
        changeHandler({
          target: {
            files: [file]
          }
        });
      }
      setIsShownModal(false);
      setCrop(null);
      setImage(null);
      setImageUrl(null);
      setFileName(null);
    };

    function getCroppedImage(image, crop, fileName) {
      const imageCanvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const origResolutionCropWidth = crop.width * scaleX;
      const origResolutionCropHeight = crop.height * scaleY;
      imageCanvas.width = origResolutionCropWidth;
      imageCanvas.height = origResolutionCropHeight;
      const imgCx = imageCanvas.getContext('2d');
      imgCx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        origResolutionCropWidth,
        origResolutionCropHeight,
        0,
        0,
        origResolutionCropWidth,
        origResolutionCropHeight
      );
      // const ext = fileName.split('.').pop();
      // const extToBlob = ext === 'jpg' || ext === 'jfif' ? 'jpeg' : fileName.split('.').pop();
      try {
        return new Promise((resolve) => {
          imageCanvas.toBlob((file) => {
            resolve(URL.createObjectURL(file));
          }, `image/jpeg`);
        });
      } catch (error) {
        console.log(error);
        return null;
      }
    }

    return (
      <PictureLoaderView
        isValid={isValid}
        multiple={multiple}
        id={id}
        name={name}
        className={className}
        style={style}
        setIsHover={setIsHover}
        iconSize={iconSize}
        btnStyle={btnStyle}
        picture={picture}
        isImageUploading={isImageUploading}
        openModal={openModal}
        isModal={isModal}
        closeBtnStyles={closeBtnStyles}
        deleteHandler={deleteHandler}
        isShownModal={isShownModal}
        setIsShownModal={setIsShownModal}
        makeClientCrop={makeClientCrop}
        crop={crop}
        imageCrop={imageCrop}
        imageUrl={imageUrl}
        onImageLoad={onImageLoad}
        onSelectFile={onSelectFile}
        image={image}
        aspect_width={content_rule ? content_rule['aspect_width'] : null}
        aspect_height={content_rule ? content_rule['aspect_height'] : null}
        isExported={isExported}
        isExportedToggleHandler={isExportedToggleHandler}
      />
    );
  }
);

PictureLoaderContainer.propTypes = propTypes;
PictureLoaderContainer.defaultProps = defaultProps;
