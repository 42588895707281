export const getEventOptions = () => [
  { value: 'firstMes', title: window?.t?.('firstMessage') },
  { value: 'keyWord', title: window?.t?.('keyWord') }
];

export const getScheludeOptions = () => [
  { value: 'everyDay', title: window?.t?.('everyDay') },
  { value: 'everyMonday', title: window?.t?.('everyMonday') }
];

export const getAutoreplyTypeOptions = () => [
  { value: 'text', title: window.t('text') },
  { value: 'weather', title: window.t('weather') },
  { value: 'link', title: window.t('link') }
];

export const WEATHER_PARAMS = {
  location: '',
  humidity: false,
  pressure: false,
  windSpeed: false,
  feelsLike: false,
  tFact: false
};
