import { message } from '../constants';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useActions from 'hooks/useActions';

export const useMessageCreator = ({ toggleMessageCreator }) => {
  const { updateMessageField, setCreatedMessage, createMessage } = useActions();
  const createdMessage = useSelector((state) => state.messageCreator.message);

  useEffect(() => {
    updateMessageField({
      field: 'date',
      value: format(new Date(), 'yyyy-MM-dd HH:mm')
    });
  }, []);

  const changeChannel = (channel) => updateMessageField({ field: 'channel', value: channel });
  const save = () => {
    toggleMessageCreator();
    createMessage();
    clearAll();
  };

  const clearAll = () => {
    setCreatedMessage({ message });
  };

  return {
    changeChannel,
    createdMessage,
    updateMessageField,
    clearAll,
    save
  };
};
