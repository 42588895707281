import { VoteQuestionView } from './VoteQuestionView';
import React, { forwardRef, memo } from 'react';

import { useVoteQuestion } from './hooks/useVoteQuestion';

export const VoteQuestionContainer = memo(
  forwardRef(({ voteInfo }, ref) => {
    const {
      title,
      titleChangeHandler,
      changeSessionInfo,
      pictureChangeHandler,
      deletePicture,
      question_img_url,
      isLoading
    } = useVoteQuestion({ voteInfo });
    return (
      <VoteQuestionView
        ref={ref}
        title={title}
        titleChangeHandler={titleChangeHandler}
        changeSessionInfo={changeSessionInfo}
        pictureChangeHandler={pictureChangeHandler}
        deletePicture={deletePicture}
        question_img_url={question_img_url}
        isLoading={isLoading}
      />
    );
  })
);
