import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  workPane: null,
  listPane: null,
  listPaneWidth: 50,
  workPaneWidth: 50
};

export const resizeSlice = createSlice({
  name: 'resize',
  initialState,
  reducers: {
    setListPaneRef: (state, action) => {
      const { ref } = action.payload;
      return {
        ...state,
        listPane: ref
      };
    },
    setWorkPaneRef: (state, action) => {
      const { ref } = action.payload;
      return {
        ...state,
        workPane: ref
      };
    },
    setListPaneWidth: (state, action) => {
      const { width } = action.payload;
      return {
        ...state,
        listPaneWidth: width
      };
    },
    setWorkPaneWidth: (state, action) => {
      const { width } = action.payload;
      return {
        ...state,
        workPaneWidth: width
      };
    }
  }
});

export const resizeActions = resizeSlice.actions;
export const resizeReducer = resizeSlice.reducer;
