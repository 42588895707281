import React, { forwardRef, memo } from 'react';

import { Button, Icon, Title } from 'components';

import { ExportButtonSettingsItemView } from './components/ExportButtonSettingsItemView/ExportButtonSettingsItemView';

export const ExportButtonsSettingsView = memo(
  forwardRef(({ addButton, removeButton, settingsState, updateRequest, updateValue }, ref) => {
    return (
      <div ref={ref} style={{ width: '53%' }}>
        <Title color="#808080" className="mb-2">
          {window.t('buttonsName')}
        </Title>
        {settingsState &&
          Object.entries(settingsState).map(([key, value]) => (
            <ExportButtonSettingsItemView
              key={key}
              label={key}
              value={value}
              updateValue={(newValue) => updateValue(key, newValue)}
              removeHandler={() => removeButton(key)}
              blurHandler={() => updateRequest(settingsState)}
            />
          ))}
        <Button
          startIcon={
            <Icon fontName="fal fa-plus" size={18} color="#81C2F5" style={{ marginRight: 11 }} />
          }
          variant="text"
          style={{
            marginTop: 25,
            marginBottom: 25,
            marginLeft: 34
          }}
          clickHandler={addButton}>
          {window.t('addButton')}
        </Button>
      </div>
    );
  })
);
