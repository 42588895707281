import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'components';

import styles from './LabelSearchMode.module.css';

function LabelSearchMode({
  searchConditions,
  setSearchConditions,
  activeSearchMode,
  scrollBoxRef
}) {
  const labels = ['red', 'orange', 'yellow', 'green', 'blue', 'purple', 'gray', 'any'];
  const classes = classNames(styles['pane'], {
    hidden: activeSearchMode !== 'label'
  });

  const setLabelType = (value) => {
    const newVal = searchConditions.label === value ? null : value;
    setSearchConditions('label', newVal, scrollBoxRef);
  };

  const getButtonClasses = (isActive) => {
    return classNames({
      [styles['selected']]: isActive
    });
  };

  function Label({ color }) {
    return <span className={`${styles['label']} ${styles[`label-${color}`]}`} />;
  }

  return (
    <div id="searchModeLabelTab" className={classes}>
      <p className={styles['title']}>{window.t('searchByMarkers')}</p>
      <ul className="d-flex justify-center align-center">
        {labels.map((label, i) => {
          return (
            <Button
              clickHandler={() => {
                setLabelType(label);
              }}
              className={getButtonClasses(searchConditions.label === label)}
              color="#00000026"
              hoverColor="#00000040"
              textColor="#ffffff"
              variant="contained"
              style={{
                borderRadius: 75,
                padding: 6,
                minWidth: 'unset',
                margin: '0 2px 7px'
              }}
              size="small"
              key={i}>
              {label === 'any' ? (
                <span>&nbsp;{window.t('any')}&nbsp;</span>
              ) : (
                <Label color={label} />
              )}
            </Button>
          );
        })}
      </ul>
    </div>
  );
}

export default LabelSearchMode;

LabelSearchMode.propTypes = {
  searchConditions: PropTypes.object,
  setSearchConditions: PropTypes.func,
  activeSearchMode: PropTypes.string
};
