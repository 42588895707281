import ChatMessage from './ChatMessage/ChatMessage';
import ChatMessageLoader from './ChatMessageLoader/ChatMessageLoader';
import DateLine from './DateLine/DateLine';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Button from 'components/Button/Button';
import CircularLoader from 'components/CircularLoader/CircularLoader';
import Icon from 'components/Icon/Icon';
import InfiniteScrollVirtualized from 'components/InfiniteScroll/InfiniteScrollVirtualized';

import { useLocalStorage } from 'hooks';
import useActions from 'hooks/useActions';

import { getChatFeed } from 'helpers/chat';
import { getImageByChannel } from 'helpers/image';
import { getFormattedContent } from 'helpers/text';

import styles from './ChatBody.module.css';

const visualAttachmentCount = (atch) => {
  return atch?.reduce(
    (acc, item) => acc + !!(item?.type?.includes('image') || item?.type?.includes('video')),
    0
  );
};
const audioAttachmentCount = (atch) => {
  return atch?.reduce((acc, item) => acc + !!item?.type?.includes('audio_msg'), 0);
};

function ChatBody({
  userInfo,
  author,
  messages,
  routeInfo,
  withMedia,
  loadMoreNext,
  hasMoreNext,
  isLoadingNext,
  loadMorePrevious,
  hasMorePrevious,
  isLoadingPrevious,
  isSendingMessage
}) {
  const scrollRef = useRef();
  const [profileMessage] = useLocalStorage('profileMessage', null);
  const { setChatScrollBoxRef, openModalImage } = useActions();
  setChatScrollBoxRef(scrollRef.current?.containerRef.current);
  const hasScrolled = useRef(null);
  const [scrollButtonEnabled, setScrollButtonEnabled] = useState(false);

  useEffect(() => {
    hasScrolled.current = false;
  }, [routeInfo.senderNumber, profileMessage?.id]);

  const feed = useMemo(() => getChatFeed(messages), [messages]);

  useEffect(() => {
    if (!feed.length) return;
    if (hasScrolled.current) return;
    if (!profileMessage?.id) {
      scrollRef.current.scrollToIndex(feed.length - 1, {
        align: 'start'
      });
      return;
    }
    const index = feed.findIndex((el) => el.ID === profileMessage.id);
    if (index !== -1) {
      scrollRef.current.scrollToIndex(index, {
        align: 'start'
      });
    }
    hasScrolled.current = true;
  }, [feed]);

  useEffect(() => {
    if (messages[messages.length - 1]?.isNew) {
      setScrollButtonEnabled(true);
      if (scrollRef.current.scrolledToBottom) {
        scrollToBottom();
      }
    }
  }, [messages, scrollRef]);

  const scrollToBottom = () => {
    scrollRef.current.scrollToIndex(feed.length - 1, {
      align: 'start'
    });
    setScrollButtonEnabled(false);
  };

  const openMedia = useCallback(
    (url) => {
      const { channel } = routeInfo;

      const uInfo = {
        avatar: getImageByChannel(channel, userInfo.image),
        name: author
      };
      // const imageList = messages
      //   .filter(
      //     (el) =>
      //       Array.isArray(el.attachments) &&
      //       el.attachments.length > 0 &&
      //       (el.attachments[0].type === "image" ||
      //         el.attachments[0].type === "video" ||
      //         el.attachments[0].type === "audio_msg" ||
      //         el.attachments[0].type === "audio")
      //   )
      //   .map((el) => {
      //     return {
      //       src:
      //         el.attachments[0].type === "audio_msg"
      //           ? el.attachments[0].ogg
      //           : el.attachments[0].url,
      //       type: el.attachments[0].type,
      //       publishDate: el.date,
      //     };
      //   });

      const imageList = messages
        .filter((el) => el.attachments && el.attachments.length > 0)
        .map((el) => {
          const newAttachments = el.attachments
            .filter((att) => ['image', 'audio', 'video', 'audio_msg'].includes(att.type))
            .map((att) => {
              return {
                src: att.type?.includes('audio_msg') ? att.ogg : att.url,
                type: att.type,
                publishDate: el.date
              };
            });
          return newAttachments;
        })
        .flat();

      openModalImage(uInfo, imageList, url);
    },
    [routeInfo, messages, openModalImage, userInfo, author]
  );

  useEffect(() => {
    if (messages.length && messages.length < 20) {
      if (hasMoreNext) loadMoreNext();
    }
  }, [messages, hasMoreNext]);

  useEffect(() => {
    if (scrollRef.current.scrolledToBottom) {
      setScrollButtonEnabled(false);
    }
  }, [scrollRef]);

  const virtualOptions = useMemo(
    () => ({
      estimateSize: (index) => {
        const visualAttCount = visualAttachmentCount(feed[index]?.attachments) || 0;
        const audioAttCount = audioAttachmentCount(feed[index]?.attachments) || 0;
        const lineCount = getFormattedContent(feed[index]?.content)
          ?.split('\n')
          ?.reduce((acc, line) => acc + Math.ceil(line.length / 42), 0);
        const contentHeight = lineCount ? 40 + 14 * 1.5 * lineCount : 0;
        let result;
        if (feed[index]?.content) {
          result = contentHeight + 200 * visualAttCount + 37 * audioAttCount;
        } else if (!feed[index]?.content) {
          result = 200 * visualAttCount + 37 * audioAttCount;
        }
        return result;
      }
    }),
    [feed]
  );

  const handleScroll = useMemo(
    () =>
      debounce((event) => {
        if (event.target.scrollTop === event.target.scrollHeight - event.target.clientHeight) {
          setScrollButtonEnabled(false);
        } else {
          setScrollButtonEnabled(true);
        }
      }, 100),
    []
  );

  return (
    <>
      <div
        className={classNames(styles['main-chat-main-msg-list'], {
          [styles['main-chat-main-msg-list--with-media']]: withMedia
        })}
        style={{
          minHeight: '300px',
          height: '100%'
        }}>
        <Button
          className={styles['scroll-bottom-btn']}
          onlyIcon
          variant="icon"
          color="#989898"
          startIcon={<Icon size={16} fontName="fas fa-chevron-down" />}
          clickHandler={scrollToBottom}
          style={{ transform: scrollButtonEnabled ? 'scale(1)' : 'scale(0)' }}
        />
        <InfiniteScrollVirtualized
          handleScroll={handleScroll}
          virtualOptions={virtualOptions}
          ref={scrollRef}
          loadingComponent={
            <div style={{ padding: '8px 16px' }}>
              <CircularLoader />
            </div>
          }
          nextDataFn={messages.length ? loadMoreNext : () => {}}
          nextEnd={!hasMoreNext}
          nextLoading={isLoadingNext}
          previousDataFn={messages.length ? loadMorePrevious : () => {}}
          previousEnd={!hasMorePrevious}
          previousLoading={isLoadingPrevious}
          rows={feed}
          renderRow={(item) => {
            if (item?.type?.includes('dateLine'))
              return <DateLine key={item?.date} date={item?.date} />;
            else
              return (
                <ChatMessage key={item?.id || item?.ID} message={item} openMedia={openMedia} />
              );
          }}>
          {isSendingMessage && <ChatMessageLoader>{window.t('sendingMessage')}</ChatMessageLoader>}
        </InfiniteScrollVirtualized>
      </div>
    </>
  );
}

export default ChatBody;
