import { restoreMessages } from './feed';
import { addSnack } from './snackBox';

import { bannedActions } from 'store/slices/bannedSlice';

import { searchProfileBanned, unbanProfile } from 'api/dataLayer';

export function searchBanned(searchText) {
  return async (dispatch) => {
    dispatch(bannedActions.setBannedSearchText({ searchText }));
    if (searchText.length <= 0) {
      dispatch(bannedActions.setBannedAuthors({ authors: [] }));
      return;
    }

    try {
      const res = await searchProfileBanned(searchText);
      const { Authors = [] } = res;
      dispatch(bannedActions.setBannedAuthors({ authors: Authors }));
    } catch (ex) {
      console.error(ex);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errSearchBlockedAuthors')
        })
      );
    }
  };
}

export function unbanAuthor(id) {
  return async (dispatch, getState) => {
    try {
      const bannedAuthors = getState().banned.bannedAuthors;
      await unbanProfile(id);
      const authorToUnban = bannedAuthors.find((el) => el.id === id);
      dispatch(restoreMessages(authorToUnban));
      const authors = bannedAuthors.filter((el) => el !== authorToUnban);
      dispatch(bannedActions.setBannedAuthors({ authors }));
    } catch (ex) {
      console.error(ex);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errUnblockAuthor')
        })
      );
    }
  };
}
