import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { Button, Icon, Menu } from 'components';

import { getImageByChannel } from 'helpers/image';

import styles from './Profile.module.scss';

export function Profile({ handleEdit, handleDelete, profile, isCurrent, handleSelect }) {
  const [isMoreActive, setIsMoreActive] = useState(false);
  const [btnRect, setBtnRect] = useState(null);
  const blurRef = useRef();

  useEffect(() => {
    if (!blurRef.current) return;
    const listener = () => setIsMoreActive(false);
    blurRef.current.addEventListener('click', listener);
    const lastRef = blurRef.current;
    return () => lastRef.removeEventListener('click', listener);
  }, []);

  const toggleMore = (e) => {
    if (e) {
      const rect = e.currentTarget.getBoundingClientRect();
      setBtnRect(rect);
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
    setIsMoreActive((state) => !state);
  };

  const activator = (
    <Button
      startIcon={<Icon size={18} fontName="fa fa-ellipsis-v" />}
      className={classNames(styles.profile__more, {
        [styles.profile__more_active]: isMoreActive
      })}
      clickHandler={toggleMore}
      onlyIcon
      variant="message-action"
      color="#666"
      title={window.t('actions')}
      style={{ margin: 0 }}
    />
  );

  return (
    <div className={styles.container}>
      <div
        onClick={() => handleSelect(profile)}
        key={profile.id}
        className={classNames(styles.profile, {
          [styles.profile_current]: isCurrent
        })}>
        <div className={styles.profile__imageContainer}>
          <img src={profile.profile_avatar || getImageByChannel('')} alt="" />
        </div>
        <p className={styles.profile__name}>{profile.profile_name}</p>
      </div>
      <Menu
        blurRef={blurRef}
        activator={activator}
        isActive={isMoreActive}
        activatorHandler={toggleMore}
        left={btnRect ? btnRect.left : 0}
        top={btnRect ? btnRect.bottom : 0}>
        <ul className={styles.profile__options}>
          <li onClick={() => handleEdit(profile.id)} className={styles.profile__option}>
            {window.t('edit')}
          </li>
          <li
            onClick={handleDelete}
            className={classNames(styles.profile__option, styles.profile__option_delete)}>
            {window.t('delete')}
          </li>
        </ul>
      </Menu>
    </div>
  );
}
