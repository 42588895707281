import { List, ListItem, ListItemText } from 'components';

export const useStateSelectMenu = ({ updateType, setStartMenuActivity }) => {
  const selectType = (type) => {
    updateType(type);
    setStartMenuActivity(false);
  };

  const selectList = (
    <List>
      <ListItem clickHandler={() => selectType('cycle')}>
        <ListItemText primary={window.t('cycle')} />
      </ListItem>
      <ListItem clickHandler={() => selectType('stop')}>
        <ListItemText primary={window.t('stop')} />
      </ListItem>
    </List>
  );

  return {
    selectList
  };
};
