import React, { forwardRef, memo } from 'react';

import { PictureLoaderContainer } from 'containers';

import { TextField } from 'components';

import { PICTURE_LOADER_TYPES } from 'store/constants';

export const VoteQuestionView = memo(
  forwardRef(
    (
      {
        title,
        titleChangeHandler,
        changeSessionInfo,
        pictureChangeHandler,
        deletePicture,
        question_img_url,
        isLoading
      },
      ref
    ) => {
      return (
        <div
          ref={ref}
          style={{
            padding: '24px 15px 15px'
          }}>
          <div className="d-flex">
            <PictureLoaderContainer
              type={PICTURE_LOADER_TYPES.showModal}
              style={{
                width: 60,
                height: 66,
                marginRight: 10,
                marginTop: -24,
                backgroundPosition: '50% 50%'
              }}
              changeHandler={pictureChangeHandler}
              deleteHandler={deletePicture}
              picture={question_img_url}
              isImageUploading={isLoading}
            />
            <TextField
              isMinimized={false}
              preIconActivity={false}
              value={title}
              // errorMsg={"data.errorMsg"}
              changeHandler={(event) => titleChangeHandler(event.target.value)}
              blurHandler={(event) => changeSessionInfo('title', event.target.value)}
              inputHeight="30px"
              inputFontSize="14px"
              // width="91.5%"
              borderStyle="none"
              isDisabled={false}
              label={window.t('titleLabel')}
              // preIcon="fal fa-user"
              // width="30%"
            />
          </div>
        </div>
      );
    }
  )
);
