import PropTypes from 'prop-types';
import React from 'react';
import Joyride from 'react-joyride';

import { DropAreaContainer, MessageCreatorContainer } from 'containers';

import { InteracitveNotFoundView, SessionModuleView } from 'components';

import { INTERACTIVES } from 'store/constants';

import { getGuideLocale } from 'helpers/locale';

import { BroadcastBodyView, BroadcastSettingsView, BroadcastToolbarView } from './components';

const propTypes = {
  type: PropTypes.oneOf([INTERACTIVES.broadcast, INTERACTIVES.news]),
  interactiveName: PropTypes.string,
  isInteractiveExist: PropTypes.bool,
  isBroadcastStateFixed: PropTypes.bool,
  setIsBroadcastStateFixed: PropTypes.func,
  isMessageCreatorOpened: PropTypes.bool,
  isSettingsOpened: PropTypes.bool,
  toggleSettings: PropTypes.func,
  toggleMessageCreator: PropTypes.func,
  visibility: PropTypes.string,
  activity: PropTypes.string,
  broadcastId: PropTypes.string,
  settings: PropTypes.object,
  state: PropTypes.object
};

const defaultProps = {
  type: INTERACTIVES.broadcast
};

export function BroadcastView({
  interactiveName,
  isInteractiveExist,
  isBroadcastStateFixed,
  setIsBroadcastStateFixed,
  isMessageCreatorOpened,
  isSettingsOpened,
  toggleSettings,
  toggleMessageCreator,
  visibility,
  activity,
  broadcastId,
  settings,
  state,
  // guide props
  run,
  startGuide,
  steps,
  stepIndex,
  setStepIndex,
  handleJoyrideCallback,
  interactiveInfoRef,
  uploadExcelRef,
  autoCheckboxesRef,
  exportButtonsRef,
  statesSettingsRef,
  okButtonRef,
  statesPanelRef,
  startButtonRef,
  addClearRef
}) {
  return (
    <>
      {isInteractiveExist ? (
        <>
          <DropAreaContainer />
          <SessionModuleView className="overflow-hidden">
            <Joyride
              callback={handleJoyrideCallback}
              continuous
              run={run}
              scrollToFirstStep
              showProgress
              steps={steps}
              stepIndex={stepIndex}
              locale={getGuideLocale()}
            />
            <BroadcastToolbarView
              interactiveName={interactiveName}
              isBroadcastStateFixed={isBroadcastStateFixed}
              toggleSettings={toggleSettings}
              toggleMessageCreator={toggleMessageCreator}
              visibility={visibility}
              activity={activity}
              broadcastId={broadcastId}
              exportButtons={settings}
              startButtonRef={startButtonRef}
              addClearRef={addClearRef}
              setStepIndex={setStepIndex}
            />
            <div className="h-full">
              {isSettingsOpened && (
                <BroadcastSettingsView
                  broadcastId={broadcastId}
                  settings={settings}
                  state={state}
                  activity={activity}
                  toggleSettings={toggleSettings}
                  startGuide={startGuide}
                  setStepIndex={setStepIndex}
                  interactiveInfoRef={interactiveInfoRef}
                  uploadExcelRef={uploadExcelRef}
                  autoCheckboxesRef={autoCheckboxesRef}
                  exportButtonsRef={exportButtonsRef}
                  statesSettingsRef={statesSettingsRef}
                  okButtonRef={okButtonRef}
                />
              )}
              <BroadcastBodyView
                broadcastId={broadcastId}
                isBroadcastStateFixed={isBroadcastStateFixed}
                setIsBroadcastStateFixed={setIsBroadcastStateFixed}
                statesPanelRef={statesPanelRef}
                setStepIndex={setStepIndex}
              />

              {isMessageCreatorOpened && (
                <MessageCreatorContainer toggleMessageCreator={toggleMessageCreator} />
              )}
            </div>
          </SessionModuleView>
        </>
      ) : (
        <InteracitveNotFoundView
          message={window.t('broadcastDoesNotExist').replace('[id]', broadcastId)}
        />
      )}
    </>
  );
}

BroadcastView.propTypes = propTypes;
BroadcastView.defaultProps = defaultProps;
