import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './List.module.css';

const propTypes = {
  style: PropTypes.object,
  noStyle: PropTypes.bool,
  children: PropTypes.node
};

const defaultProps = {
  style: {},
  noStyle: false
};

function List({ className, style, noStyle, children }) {
  return (
    <ul
      style={style}
      className={classNames(className, {
        [styles['list']]: !noStyle
      })}>
      {children}
    </ul>
  );
}

export default List;

List.propTypes = propTypes;
List.defaultProps = defaultProps;
