import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularLoader,
  Input,
  RoundCheckbox,
  Text,
  Title
} from 'components';

import useActions from 'hooks/useActions';
import useFetch from 'hooks/useFetch';

import { getUIDateFromDBDate } from 'helpers/dateTime';
import { getImageByChannel } from 'helpers/image';

import styles from './SocialLinksModal.module.css';

const propTypes = {
  activeChannel: PropTypes.string
};

const option = { dataKey: null };

function SocialLinksModal({ activeChannel }) {
  const { setPosts, togglePost, toggleAllPosts, filterBySearchText, resetSocialsState } =
    useActions();
  const { socials, posts, filteredPosts, searchText } = useSelector((state) => state.socials);
  const [isAuthed, setIsAuthed] = useState(true);
  const [activeTab, setActiveTab] = useState('');
  const [{ response, error, isLoading }, doFetch] = useFetch('api_get_socials_posts', option);

  useEffect(() => {
    resetSocialsState();
    setActiveTab(activeChannel);
  }, [activeChannel]);

  useEffect(() => {
    const channel = activeTab;
    if (channel)
      doFetch({
        method: 'POST',
        postBody: {
          actionName: 'getPosts',
          param1: channel
        }
      });
    return () => {
      resetSocialsState();
    };
  }, [socials, resetSocialsState, activeTab]);

  useEffect(() => {
    if (error) {
      return;
    }

    if (!response) return;

    if (response === 'not_auth') {
      setIsAuthed(false);
      return;
    }
    setPosts(response.Posts);
  }, [response, error, setPosts]);

  // useEffect(() => {
  //   dispatch(resetSocialsState());
  // }, [activeChannel])

  const getCheckedPosts = () => posts.map((el) => el.checked);
  const isSomeUncheckedPost = () => posts.some((el) => el.checked === false);

  const changeSearchText = (event) => filterBySearchText(event.target.value);
  const toggleAll = () => toggleAllPosts(!!isSomeUncheckedPost());

  const channels = [
    {
      type: 'vkgroup',
      param: 'vkcomm',
      title: window.t('vkontakte')
    },
    {
      type: 'facebookcomments',
      param: 'fbcomm',
      title: 'Facebook'
    },
    {
      type: 'ok',
      param: 'okcomm',
      title: window.t('ok')
    }
  ];

  const getButtonClasses = (isActive) => {
    return classNames([styles['channel-mode-btn']], {
      [styles['selected']]: isActive
    });
  };

  return (
    <div id="social-links" className={styles['social-conent']}>
      <div className={styles['social-head']}>
        <Title size={18} weight="300" color="#333">
          {activeChannel === 'yt_live'
            ? window.t('youtubeChatsFilter')
            : window.t('socialBookmarks')}
        </Title>
        <div className="d-flex justify-center">
          {activeChannel !== 'yt_live'
            ? channels.map(({ type, title, param }, i) => (
                <Button
                  clickHandler={() => {
                    setActiveTab(param);
                  }}
                  className={`${getButtonClasses(param === activeTab)}`}
                  title={title}
                  color="#00000026"
                  textColor="#ffffff"
                  variant="text"
                  style={{
                    padding: 0,
                    minWidth: 0,
                    borderRadius: '50%',
                    margin: '0 2px 7px'
                  }}
                  key={i}>
                  <Avatar src={getImageByChannel(type)} size={32} />
                </Button>
              ))
            : null}
        </div>
        <Input
          type="text"
          value={searchText}
          placeholder={window.t('searchByPosts')}
          changeHandler={changeSearchText}
          className="mt-4 mb-4"
        />
        <div style={{ borderBottom: '1px solid #c7c7c7' }}>
          {getCheckedPosts().length > 0 && (
            <RoundCheckbox
              label={window.t('selectAll')}
              isChecked={!isSomeUncheckedPost()}
              changeHandler={toggleAll}
            />
          )}
        </div>
      </div>
      <hr style={{ clear: 'both' }} />
      {isLoading ? (
        <CircularLoader />
      ) : !isAuthed ? (
        <h3>Вы еще не авторизованы</h3>
      ) : error ? (
        <h3>Произошла ошибка при загрузке постов</h3>
      ) : (
        posts.length > 0 && (
          <div className={styles['social-body']} style={{ padding: 0 }}>
            {filteredPosts.map(({ author, channel, checked, content, date, href, id, title }) => {
              return (
                <Card
                  key={id}
                  style={{
                    boxShadow: 'rgb(0 0 0 / 15%) 0px 2px 5px',
                    padding: 9
                  }}>
                  <CardContent className="overflow-hidden">
                    <RoundCheckbox
                      isChecked={checked}
                      changeHandler={() => togglePost(id, channel, !checked)}
                      style={{ float: 'left' }}
                    />
                    <Text className={styles['selector-group-time']} tag="span">
                      {getUIDateFromDBDate(date) !== 'Invalid date'
                        ? getUIDateFromDBDate(date)
                        : null}
                    </Text>
                    <Text className={styles['post-body']}>{content || title}</Text>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="text"
                      size="small"
                      color="#385898"
                      style={{
                        marginLeft: 28,
                        fontSize: 13
                      }}
                      clickHandler={() => {
                        window.open(href, '_blank');
                      }}>
                      {window.t('open')}
                    </Button>
                  </CardActions>
                </Card>
              );
            })}
          </div>
        )
      )}
    </div>
  );
}

export default SocialLinksModal;

SocialLinksModal.propTypes = propTypes;
