import { addSnack } from './snackBox';

import { socialsActions } from 'store/slices/socialsSlice';

import {
  logoutYoutube,
  setSocialsPostCheckedState,
  setSocialsPostsCheckedState
} from 'api/dataLayer';

export function resetSocialsState() {
  return (dispatch) => {
    dispatch(socialsActions.fetchPostsSuccess({ posts: [] }));
    dispatch(socialsActions.setSocialsSearchText({ searchText: '' }));
    dispatch(socialsActions.setAuthState({ isAuthenticated: true }));
  };
}

export function setSocials(socials) {
  return async (dispatch) => {
    let res;
    try {
      // res = await getSocials();
      const filtered = socials?.map((el, i) => {
        el.isActive = i === 0;
        el.channel = el.channel === 'vkcomments' ? 'vkcomm' : el.channel;
        return el;
      }); // .filter(el => el.channel !== 'yt_live');
      dispatch(socialsActions.fetchSocialsSuccess({ socials: filtered }));
    } catch (ex) {
      console.error(ex);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errLoadingListSocialNetworks')
        })
      );
    }
  };
}

export function setPosts(posts) {
  return async (dispatch) => {
    try {
      // const res = await getSocialsPosts(channel);
      // let posts = [];
      // if (res === 'not_auth') {
      //   dispatch(setAuthState(false));
      // }
      if (typeof posts === 'object' && Array.isArray(posts))
        posts = posts
          .map((post) => {
            post.checked = Boolean(+post.checked);
            return post;
          })
          .reverse();
      dispatch(socialsActions.fetchPostsSuccess({ posts }));
    } catch (ex) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errLoadingPosts')
        })
      );
    }
  };
}

export function logoutYtube() {
  return async (dispatch) => {
    try {
      await logoutYoutube();
      dispatch(socialsActions.setAuthState({ isAuthenticated: false }));
    } catch (ex) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errLogoutYoutube')
        })
      );
    }
  };
}

export function makeChannelActive(channel) {
  return async (dispatch) => {
    try {
      dispatch(socialsActions.makeChannelActiveSuccess({ channel }));
      // dispatch(fetchPosts(channel));
    } catch (ex) {
      console.error(ex);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errRequestingServer')
        })
      );
    }
  };
}

export function togglePost(id, channel, checked) {
  return async (dispatch) => {
    try {
      await setSocialsPostCheckedState({ id, channel, isChecked: +checked });
      dispatch(socialsActions.togglePostSuccess({ id, checked }));
    } catch (ex) {
      console.error(ex);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errRequestingServer')
        })
      );
    }
  };
}

export function toggleAllPosts(isChecked) {
  return async (dispatch, getState) => {
    try {
      const socialsState = getState().socials;
      const ids = socialsState.posts.map((el) => el.id);
      const activeSocial = socialsState.socials.find((el) => el.isActive);
      const { channel } = activeSocial;
      await setSocialsPostsCheckedState({ channel, isChecked, ids });
      dispatch(socialsActions.toggleAllPostsSuccess({ checked: isChecked }));
    } catch (ex) {
      console.error(ex);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errRequestingServer')
        })
      );
    }
  };
}

export function filterBySearchText(searchText) {
  return (dispatch, getState) => {
    dispatch(socialsActions.setSocialsSearchText({ searchText }));
    const posts = getState().socials.posts;
    const regPhrase = new RegExp(searchText, 'i');
    const filtered = posts.filter((el) => regPhrase.test(el.content));
    dispatch(socialsActions.setFilteredPosts({ posts: filtered }));
  };
}
