import { checkIsValid } from '../ContentFields/helpers/validationFields';
import { useEffect, useMemo } from 'react';

import { Text, TextField } from 'components';

import { useActions } from 'hooks';

import { formatTimeToPlayer } from 'helpers/dateTime';

import styles from './TitreDurationElement.module.css';

export function TitreDurationElement({
  value,
  block,
  updateBlockField,
  elementKey,
  locale,
  blocks,
  activeBlocksLength
}) {
  const { addSnack } = useActions();

  const isValid = useMemo(
    () => checkIsValid(elementKey, block, block.isTouched, activeBlocksLength, updateBlockField),
    [value.value, block.isTouched, activeBlocksLength]
  );

  const currentIndexTitre = blocks.findIndex((item) => item.id === block.id);
  const lastBlockIndex = Number(block.lastBlockNumber.value) - 1 || 0;

  // оптимизировать код
  const allDuration = blocks
    .slice(currentIndexTitre, Math.abs(lastBlockIndex + 1))
    .reduce((acc, block) => {
      if (block.pause) {
        return acc + (Number(block.pause.value) || 0) / 1000;
      }
      if (['horizontal', 'vertical'].includes(block.titreType.value) && block.text_to_speech) {
        return (
          acc + Number(block.text_to_speech.duration || 0) + Number(block.text.durationAnimate || 0)
        );
      }
      return acc;
    }, 0);

  useEffect(() => {
    if (value.value === '') {
      updateBlockField(block.id, elementKey, currentIndexTitre + 1, 'value');
    }
  }, []);
  useEffect(() => {
    updateBlockField(block.id, 'allDuration', allDuration, 'value');
  }, [lastBlockIndex, allDuration]);

  const changeEndTitr = (event) => {
    if (Number(event.target.value) > activeBlocksLength) {
      addSnack({
        type: 'danger',
        message: `${window.t('errCantEndBlockNumber')} ${activeBlocksLength}`
      });
    } else {
      updateBlockField(block.id, elementKey, event.target.value, 'value');
    }
  };

  return (
    <div className="d-flex flex-column mt-4" style={{ gap: '10px' }}>
      <Text tag="div" color="#666" size={16} style={{ paddingLeft: '10px' }}>
        {window.t('titrsNextDuration')} {formatTimeToPlayer(Math.round(allDuration))}
      </Text>

      {block.video.value && block.durationVideo?.value < allDuration && (
        <Text tag="div" color="#ff2525" style={{ paddingLeft: '10px' }}>
          {window.t('titrsLongerThanVideo')}
        </Text>
      )}

      <TextField
        isValid={isValid}
        type="number"
        value={value.value}
        changeHandler={changeEndTitr}
        label={`${value.label[locale]}`}
        borderStyle="dotted"
        className={styles.textField}
      />
    </div>
  );
}
