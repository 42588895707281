import React from 'react';

import styles from './Radio.module.css';

function Radio({ label, name, value, isChecked, changeHandler, className }) {
  return (
    <div className={`${styles['md-radio__radio']} ${className}`}>
      <label className={styles['md-radio__label']}>
        <input
          onChange={(e) => changeHandler(e)}
          className={styles['md-radio__input']}
          value={value}
          type="radio"
          checked={isChecked}
          name={name}
        />
        <i className={styles['md-radio__helper']} />
        <span>{label}</span>
      </label>
    </div>
  );
}

export default Radio;
