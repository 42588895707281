import PropTypes from 'prop-types';
import React, { memo } from 'react';

import {
  BroadcastFeedSettingsContainer,
  ExportButtonsSettingsContainer,
  InteractiveSettingsContainer,
  StatesSettingsContainer,
  UploadExcelContainer
} from 'containers';

import { INTERACTIVES } from 'store/constants';

import { editBroadcast } from 'api/dataLayer';

const propTypes = {
  broadcastId: PropTypes.string,
  settings: PropTypes.object,
  state: PropTypes.object,
  activity: PropTypes.string,
  toggleSettings: PropTypes.func
};

export function BroadcastSettingsView({
  broadcastId,
  settings,
  state,
  activity,
  toggleSettings,
  startGuide,
  setStepIndex,
  interactiveInfoRef,
  uploadExcelRef,
  autoCheckboxesRef,
  exportButtonsRef,
  statesSettingsRef,
  okButtonRef
}) {
  return (
    <InteractiveSettingsContainer
      type={INTERACTIVES.broadcast}
      interactiveId={broadcastId}
      onCloseHandler={toggleSettings}
      startGuide={startGuide}
      setStepIndex={setStepIndex}
      interactiveInfoRef={interactiveInfoRef}
      okButtonRef={okButtonRef}>
      {/* <UploadExcelContainer ref={uploadExcelRef} broadcastId={broadcastId} /> */}
      <BroadcastFeedSettingsContainer ref={autoCheckboxesRef} />
      <div className="d-flex flex-wrap">
        <ExportButtonsSettingsContainer
          ref={exportButtonsRef}
          sessionId={broadcastId}
          editRequest={editBroadcast}
          settings={settings}
          activity={activity}
          setStepIndex={setStepIndex}
        />
        <StatesSettingsContainer
          ref={statesSettingsRef}
          sessionId={broadcastId}
          editRequest={editBroadcast}
          state={state}
          activity={activity}
          setStepIndex={setStepIndex}
        />
      </div>
    </InteractiveSettingsContainer>
  );
}

BroadcastSettingsView.propTypes = propTypes;
