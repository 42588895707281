import React from 'react';

import logo from 'assets/images/logo.jpg';

import styles from './WelcomeHeader.module.css';

function WelcomeHeader() {
  return (
    <div className={styles.container}>
      <img className={styles.logoImage} src={logo} alt="logo" />
      {/* <div className={styles.logo}><span></span></div> */}
      <h1
        className={styles['welcome-header']}
        dangerouslySetInnerHTML={{ __html: window.t('header') }}
      />
      <p className={styles.tgray}>{window.t('subheader')}</p>
    </div>
  );
}

export default WelcomeHeader;
