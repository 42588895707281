export const messageActionsConfig = {
  copy: true,
  exportToBroadcast: false,
  minimize: false,
  settings: true,
  favorite: true
};

export const messageStyle = {
  // backgroundColor: '#f2f2f2',
  marginTop: '0px',
  borderRadius: 0,
  borderBottom: 'unset'
};
