import { checkIsValid } from '../components/NewsBlock/components/ContentFields/helpers/validationFields';
import { types } from '../constants';
import { useCallback, useEffect, useState } from 'react';

import { useActions } from 'hooks';

import {
  editContent,
  getSlots,
  publishContent,
  setContentSlot,
  setContentType
} from 'api/dataLayer';

import { parseJSON } from 'helpers/text';

export const useContentControls = ({
  contentId,
  content_type,
  content_slot,
  setStepIndex,
  blocks
}) => {
  const { updateInfoField, addSnack, updateContentBlockField } = useActions();
  const [selectedType, setSelectedType] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [slots, setSlots] = useState([]);
  const [filteredSlots, setFilteredSlots] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getSlots();
        if (Array.isArray(res?.body)) {
          const slots = res.body.map((slot) => ({
            ...slot,
            value: slot.slotId,
            title: slot.slot
          }));

          setSlots(slots);
        }
      } catch (ex) {
        console.error(ex);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!content_type) return;
    const selectedType = types.find((el) => el.value === content_type);
    setSelectedType(selectedType);
  }, [content_type]);

  useEffect(() => {
    if (!content_slot || filteredSlots.length === 0) return;

    const selectedSlot = filteredSlots.find((el) => el.slotId === content_slot);
    setSelectedSlot(selectedSlot);
  }, [content_slot, filteredSlots]);

  useEffect(() => {
    const filteredSlots =
      selectedType === null ? slots : slots.filter((slot) => slot.slotType === selectedType.value);
    setFilteredSlots(filteredSlots);
  }, [slots, selectedType]);

  useEffect(() => {
    if (selectedType?.value !== selectedSlot?.slotType) setSelectedSlot(null);
  }, [selectedType]);

  useEffect(() => {
    if (selectedSlot !== null && selectedType === null) {
      const newSelectedType = types.find((el) => el.value === selectedSlot.slotType);
      setSelectedType(newSelectedType);
    }
  }, [selectedSlot]);

  const changeType = useCallback(
    async (newType) => {
      let oldType = null;
      setSelectedType((selectedType) => {
        oldType = selectedType;
        return newType;
      });
      const res = await setContentType({
        contentId,
        contentType: newType.value
      });
      updateInfoField('content_type', newType.value);
      updateInfoField('content_rule', parseJSON(res.body, {}));
    },
    [contentId, updateInfoField]
  );

  const changeSlot = useCallback(
    async (newSlot) => {
      let oldSlot = null;
      setSelectedSlot((selectedSlot) => {
        oldSlot = selectedSlot;
        return newSlot;
      });
      const res = await setContentSlot({
        contentId,
        slotId: newSlot.slotId
      });
      const content_rule = res?.body?.content_rule;
      if (content_rule) {
        updateInfoField('content_rule', parseJSON(content_rule, {}));
        updateInfoField('publish_time', '');
      }
    },
    [contentId, updateInfoField]
  );

  const validate = () => {
    const invalidBlocks = [];
    const activeBlocksLength = blocks.filter((el) => el.isActive === true).length;

    blocks.forEach((fields, i) => {
      fields.isTouched === false && updateContentBlockField(fields.id, 'isTouched', true, null);

      if (fields.isActive === false) return;

      Object.entries(fields).forEach(([key, value]) => {
        const type = value && value['type#'];

        if (!type) return;

        if (
          value.isValid === false ||
          !checkIsValid(key, fields, true, activeBlocksLength, updateContentBlockField, null)
          // запрет на публикацию видео меньше чем продолжительность титров
          // || (type === 'video' && fields.durationVideo.value < fields.allDuration.value)
        ) {
          const number = `№${i + 1}`;
          if (!invalidBlocks.includes(number)) invalidBlocks.push(number);
        }
      });
    });

    return invalidBlocks;
  };

  const publish = useCallback(async () => {
    const invalidBlocks = validate();

    if (invalidBlocks.length > 0) {
      addSnack({
        message: window
          .t(
            invalidBlocks.length !== 1 ? 'errFailedBlocksValidation' : 'errFailedOneBlockValidation'
          )
          .replace('[blocks]', invalidBlocks.join(', ')),
        type: 'danger'
      });
      return;
    }

    try {
      await editContent({ id: contentId, content_json: JSON.stringify(blocks) });
      const res = await publishContent(contentId);
      addSnack({
        message: window.t('sccPublishContent').replace('[blocks]', invalidBlocks.join(', ')),
        type: 'success'
      });
      updateInfoField('publish_time', res?.body?.publish_time?.toString());
      setStepIndex((v) => ++v);
    } catch (ex) {
      console.error(ex);
    }
  }, [contentId, blocks, addSnack, updateInfoField]);

  return {
    slots: filteredSlots,
    selectedType,
    changeType,
    selectedSlot,
    changeSlot,
    publish
  };
};
