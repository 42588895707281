import { useSelector } from 'react-redux';

import useActions from 'hooks/useActions';

export const useISettingsForm = () => {
  const { updateInfoField, changeSessionInfo, updateInteractiveTitle } = useActions();

  const { info } = useSelector((state) => state.isettings);
  let { interactiveName = '', description = '', memo = '' } = info;

  const changeInteractiveName = (value) => updateInfoField('interactiveName', value);
  const changeDescription = (value) => updateInfoField('description', value);
  const changeNotes = (value) => updateInfoField('memo', value);

  return {
    interactiveName,
    description,
    notes: memo,
    changeInteractiveName,
    changeDescription,
    changeNotes,
    updateInteractiveTitle,
    changeSessionInfo
  };
};
