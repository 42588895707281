import useActions from 'hooks/useActions';

import { INTERACTIVE_ACTIVITIES } from 'store/constants';

export const useAutoReplyControls = ({ activity, autoReplyId }) => {
  const { startAutoreply, stopAutoreply } = useActions();

  return {
    startStopData: {
      startStopIcon: activity === INTERACTIVE_ACTIVITIES.active ? 'far fa-stop' : 'far fa-play',
      startStopTitle:
        activity === INTERACTIVE_ACTIVITIES.active ? 'stopBroadcast' : 'startBroadcast',
      startStopColor: activity === INTERACTIVE_ACTIVITIES.active ? '#ff4d29' : '#00bb00',
      startStopClickHandler:
        activity === INTERACTIVE_ACTIVITIES.active
          ? () => {
              stopAutoreply(autoReplyId);
            }
          : () => {
              startAutoreply(autoReplyId);
            }
    }
  };
};
