import BannedAuthorsList from './BannedAuthorsList/BannedAuthorsList';
import React from 'react';
import { useSelector } from 'react-redux';

import useActions from 'hooks/useActions';

import styles from './BannedAuthors.module.css';

// searchBanned
function BannedAuthors() {
  const { searchText, bannedAuthors } = useSelector((state) => state.banned);
  const { searchBanned, unbanAuthor } = useActions();
  const changeHandler = (event) => searchBanned(event.target.value);
  return (
    <div className="pb-4">
      <h3 className={styles['ba__title']}>{window.t('blockedAuthors')}</h3>
      <label className={styles['ba__label']} htmlFor="bannedSearch">
        {window.t('howSearch')}
      </label>
      <br />
      {/* onchange="changeMsgView(false)"  onkeyup="bannedSerch(event)" */}
      <input
        className={styles['ba__input']}
        type="text"
        id="bannedSearch"
        value={searchText}
        onChange={changeHandler}
      />
      <BannedAuthorsList
        searchText={searchText}
        bannedAuthors={bannedAuthors}
        unbanAuthor={unbanAuthor}
      />
    </div>
  );
}

export default BannedAuthors;
