import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { MarkersContainer } from 'containers';

import { List, ListItem, ListItemText } from 'components';

import styles from './MessageActionsMenuView.module.css';

const propTypes = {
  message: PropTypes.object,
  labels: PropTypes.arrayOf(PropTypes.string),
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      clickHandler: PropTypes.func
    })
  ),
  isCreated: PropTypes.bool,
  blockAuthorHandler: PropTypes.func,
  deleteMessageHandler: PropTypes.func,
  listEmails: PropTypes.array,
  exportMessageHandler: PropTypes.func,
  pinAuthorHandler: PropTypes.func
};

export const MessageActionsMenuView = memo(
  ({
    message,
    labels,
    listItems,
    isCreated,
    listEmails,
    listTelegrams,
    blockAuthorHandler,
    deleteMessageHandler,
    exportMessageHandler
  }) => {
    function Separator() {
      return <li className={styles.separator} />;
    }

    return (
      <>
        <List>
          {/* <MarkersContainer
            messageId={message.id}
            labels={labels}
            senderNumber={message.senderNumber}
          />
          <Separator /> */}
          {listEmails &&
            listEmails.map((item) => {
              if (item.email) {
                return (
                  <ListItem
                    key={item.email}
                    className={styles['menu-item']}
                    clickHandler={() => {
                      exportMessageHandler('email', item.email, message);
                    }}>
                    <ListItemText primary={`${window.t('send')} ${item.name || item.email}`} />
                  </ListItem>
                );
              }
              return null;
            })}
          {listTelegrams &&
            listTelegrams.map((item) => {
              if (item.userName) {
                return (
                  <ListItem
                    key={item.userName}
                    className={styles['menu-item']}
                    clickHandler={() => {
                      exportMessageHandler('telegram', item.userName, message);
                    }}>
                    <ListItemText primary={`${window.t('send')} ${item.name || item.userName}`} />
                  </ListItem>
                );
              }
              return null;
            })}
          {listTelegrams && listTelegrams.some((item) => item.userName) && <Separator />}
          {listItems &&
            listItems.map(({ title, clickHandler }) => (
              <ListItem key={title} className={styles['menu-item']} clickHandler={clickHandler}>
                <ListItemText primary={title} />
              </ListItem>
            ))}
          <Separator />
          {!isCreated && (
            <ListItem
              className={styles['menu-item']}
              title={window.t('block')}
              clickHandler={blockAuthorHandler}>
              <ListItemText primary={window.t('block')} />
            </ListItem>
          )}
          <ListItem className={styles['menu-item']} clickHandler={deleteMessageHandler}>
            <ListItemText
              primary={isCreated ? window.t('deleteCreatedMessage') : window.t('deleteMessage')}
            />
          </ListItem>
        </List>
      </>
    );
  }
);

MessageActionsMenuView.propTypes = propTypes;
