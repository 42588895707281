import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeMenu: null,
  activeSearchMode: 'text',
  isSearchCollapsed: false,
  searchConditions: {
    text: '',
    timeType: null,
    timeStart: null,
    timeEnd: null,
    label: null,
    media: null,
    channel: null
  },
  searchModalHeight: 0
};

export const messageFiltersSlice = createSlice({
  name: 'messageFilters',
  initialState,
  reducers: {
    setActiveLeftMenuSuccess: (state, action) => {
      const { activeMenu } = action.payload;
      return {
        ...state,
        activeMenu
      };
    },
    setSearchConditionsSuccess: (state, action) => {
      const { field, value } = action.payload;
      return {
        ...state,
        searchConditions: {
          ...state.searchConditions,
          [field]: value
        }
      };
    },
    setSearchMode: (state, action) => {
      const { mode } = action.payload;
      return {
        ...state,
        activeSearchMode: mode
      };
    },
    setIsSearchCollapsed: (state, action) => {
      const value = action.payload;
      return {
        ...state,
        isSearchModalCollapsed: value
      };
    },
    setSearchModalHeight: (state, action) => {
      const { height } = action.payload;
      return {
        ...state,
        searchModalHeight: height
      };
    }
  }
});

export const messageFiltersActions = messageFiltersSlice.actions;
export const messageFiltersReducer = messageFiltersSlice.reducer;
