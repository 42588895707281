import React from 'react';

import { Button, Icon } from 'components';

import { getIconByFile } from 'helpers/chat';

import styles from './FileItem.module.css';

function FileItem({
  file,
  removeHandler = () => {},
  withRemoveBtn = false,
  clickHandler = () => {},
  type = 'big'
}) {
  return (
    <div className={type === 'small' ? styles.fileItem : styles.bigFileItem} onClick={clickHandler}>
      {withRemoveBtn && (
        <Button
          startIcon={<Icon size={18} fontName="far fa-times" />}
          onlyIcon
          className={styles.fileItemDeleteBtn}
          color="#524e4e"
          variant="message-action"
          clickHandler={removeHandler}
        />
      )}
      <Icon size={40} fontName={getIconByFile(file.type)} />
      <div className={styles.fileName}>{file.name}</div>
    </div>
  );
}

export default FileItem;
