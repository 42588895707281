import BannedAuthorsItem from './BannedAuthorsItem/BannedAuthorsItem';
import NoResultItem from './NoResultItem/NoResultItem';
import React from 'react';

import styles from './BannedAuthorsList.module.css';

function BannedAuthorsList(props) {
  return (
    <ul className={styles['search-result-ban']} id="search_result">
      {props.searchText.length > 0 && props.bannedAuthors.length <= 0 ? (
        <NoResultItem />
      ) : (
        props.bannedAuthors.map((el) => {
          return <BannedAuthorsItem data={el} unbanAuthor={props.unbanAuthor} />;
        })
      )}
    </ul>
  );
}

export default BannedAuthorsList;
