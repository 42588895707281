import { VoteVariantsView } from './VoteVariantsView';
import React, { memo, useCallback, useEffect } from 'react';

import { useActions } from 'hooks';

import { editVote } from 'api/dataLayer';

import usePhoneNumbers from './hooks/usePhoneNumbers';
import { useVoteVariants } from './hooks/useVoteVariants';

export const VoteVariantsVoteType = memo(({ type, voteInfo, variantRef }) => {
  const { updateInfoField } = useActions();
  const {
    voteId,
    variants,
    result,
    isVoteActive,
    updateVariant,
    changeVoteVariant,
    deleteVoteVariant,
    updateVariantField,
    addVote,
    pictureChangeHandler,
    deleteVoteImage,
    isLoadingVariant,
    loadingImageVarnum,
    voteCount,
    addNewVariant
  } = useVoteVariants({ voteInfo });

  const updateVote = useCallback(
    async (field, value) => {
      await editVote({ ...voteInfo, [field]: value });
      updateInfoField(field, value);
    },
    [voteInfo, updateInfoField]
  );

  const {
    isPhoneNumbersUsed,
    toggleIsPhoneNumbersUsed,
    handleAddNewVariant,
    variantsWithPhoneVoting,
    voteCountWithPhoneVoting
  } = usePhoneNumbers({
    updateVote,
    variants,
    isVoteActive,
    addNewVariant,
    voteCount,
    title: voteInfo.title,
    phone_voting_poll_id: voteInfo.phone_voting_poll_id,
    start_time: voteInfo.start_time,
    end_time: voteInfo.end_time
  });

  return (
    <VoteVariantsView
      isChecked={isPhoneNumbersUsed}
      toggleCheck={toggleIsPhoneNumbersUsed}
      voteId={voteId}
      type={type}
      variants={isPhoneNumbersUsed ? variantsWithPhoneVoting : variants}
      addNewVariant={handleAddNewVariant}
      voteCount={isPhoneNumbersUsed ? voteCountWithPhoneVoting : voteCount}
      result={result}
      isVoteActive={isVoteActive}
      updateVariant={updateVariant}
      changeVoteVariant={changeVoteVariant}
      deleteVoteVariant={deleteVoteVariant}
      updateVariantField={updateVariantField}
      addVote={addVote}
      pictureChangeHandler={pictureChangeHandler}
      deleteVoteImage={deleteVoteImage}
      isLoadingVariant={isLoadingVariant}
      loadingImageVarnum={loadingImageVarnum}
      variantRef={variantRef}
    />
  );
});
