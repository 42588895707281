import iconPath from './IconsPathes';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  className: PropTypes.string,
  fontName: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string,
  icon: PropTypes.string,
  viewBox: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.element
};

const defaultProps = {
  size: 20,
  color: 'currentColor',
  style: {}
};

function Icon({ className, fontName, size, width, height, color, icon, viewBox, style }) {
  return (
    <>
      {fontName ? (
        <i className={`${fontName} ${className}`} style={{ ...style, fontSize: size, color }} />
      ) : icon ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          focusable="false"
          aria-hidden="true"
          role="presentation"
          viewBox={viewBox || `0 0 ${width || size} ${height || size}`}
          width={`${width || size}px`}
          height={`${height || size}px`}
          style={style}
          className={className}>
          {Array.isArray(iconPath[icon]) ? (
            iconPath[icon].map((path, i) => {
              if (typeof path === 'object')
                return (
                  <path
                    fill={path.fill || color}
                    {...(path.transform && { transform: path.transform })}
                    {...(path.style && { style: path.style })}
                    d={path.d}
                    key={i}
                  />
                );
              else return <path fill={color} d={path} key={i} />;
            })
          ) : (
            <path fill={color} d={iconPath[icon]} />
          )}
        </svg>
      ) : (
        <span>No icon info</span>
      )}
    </>
  );
}

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default Icon;
