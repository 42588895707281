import PropTypes from 'prop-types';
import React from 'react';

import styles from './CardContent.module.css';

function CardContent({ children, className, style }) {
  return (
    <div className={`${styles['card-content']} ${className}`} style={style}>
      {children}
    </div>
  );
}

export default CardContent;

CardContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  className: PropTypes.string,
  style: PropTypes.object
};

CardContent.defaultProps = {
  className: '',
  style: {}
};
