import App from './App';
import reportWebVitals from './reportWebVitals';
import startSocket from './sockets';
import { configureStore } from '@reduxjs/toolkit';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { ErrorBoundary } from 'providers/ErrorBoundary';
import { LocaleProvider } from 'providers/LocaleProvider';
import { MainGuideProvider } from 'providers/MainGuideProvider';

import { updateSideProfilePinnedAuthors } from 'store/middlewares';
import { rootReducer } from 'store/reducers/rootReducer';
import { initialState as initialVoteState } from 'store/slices/voteSlice';

import { loadState, saveState } from 'helpers/localStorage';

import './assets/styles/style.css';

// https://egghead.io/lessons/javascript-redux-persisting-the-state-to-the-local-storage
const persistedState = loadState();

if (persistedState?.settings?.appSettings && !persistedState.settings.appSettings.permission)
  localStorage.clear();

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  preloadedState: persistedState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    }).concat(updateSideProfilePinnedAuthors)
});

store.subscribe(() => {
  // listPane,workPane - хранят htmlNode,
  // stringify htmlNode вызывает ошибку: Converting circular structure to JSON
  const resizeState = {
    ...store.getState().resize,
    listPane: null,
    workPane: null
  };
  saveState({
    settings: store.getState().settings,
    resize: resizeState,
    messageFilters: store.getState().messageFilters,
    vote: {
      ...initialVoteState,
      isMessagesReversed: store.getState().vote.isMessagesReversed,
      isAlertEnable: store.getState().vote.isAlertEnable
    },
    notifications: store.getState().notifications
  });
});

startSocket(store.dispatch, store.getState, 8001);
// window.store = store;
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ErrorBoundary>
        <Provider store={store}>
          <LocaleProvider>
            <MainGuideProvider>
              <DndProvider backend={HTML5Backend}>
                <App />
              </DndProvider>
            </MainGuideProvider>
          </LocaleProvider>
        </Provider>
      </ErrorBoundary>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// } catch (ex) {
//   console.error(ex);
// }
// })
// .catch(ex => {
//   if (window.confirm(`Для вашего домена ${window.location.hostname} не удалось получить данные. Перезагрузить страницу и попробовать еще раз?`)) {
//     window.location.reload();
//   }
// })
