import PropTypes from 'prop-types';
import React from 'react';
import { useLocation, withRouter } from 'react-router-dom';

import { Button, Icon, Text } from 'components';

import { goTo } from 'helpers/routing';

import styles from './Toolbar.module.css';

function Toolbar({ history, title, path, children, className }) {
  const location = useLocation();
  const formatText = (text) => (text?.length >= 15 ? `${text.slice(0, 15)}...` : text);

  const clickHandler = () => {
    if (path) {
      goTo(history, path, null, location);
      return;
    }
    history.length <= 2 || !location.state?.prevPath
      ? goTo(history, '/', null, location)
      : history.push({
          pathname: location.state.prevPath
        });
  };

  return (
    <div className={`${className || ''} ${styles['module-header']}`}>
      <Button
        startIcon={<Icon size={26} fontName="fal fa-chevron-left" />}
        onlyIcon
        variant="icon"
        color="#57adf2"
        clickHandler={clickHandler}
        style={{ padding: '3px 10px', margin: '0 3px' }}
      />
      <Text title={title} color="#0000008a" size={15} className={styles['module-type-name']}>
        {formatText(title)}
      </Text>
      {children}
    </div>
  );
}

export default withRouter(Toolbar);

Toolbar.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  className: PropTypes.string
};
