import { useState } from 'react';

import { Button, Menu } from 'components';

import styles from './MoreButton.module.css';

export function MoreButton({ text, geoData }) {
  const [isActive, setIsActive] = useState(false);
  const [btnRect, setBtnRect] = useState(null);
  const clickHandler = (e) => {
    if (e) {
      const rect = e.currentTarget.getBoundingClientRect();
      setBtnRect(rect);
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
    setIsActive(!isActive);
  };
  const activator = (
    <Button type="button" clickHandler={clickHandler} variant="text">
      {window.t('show_more')}
    </Button>
  );

  return (
    <Menu
      activator={activator}
      isActive={isActive}
      activatorHandler={setIsActive}
      left={btnRect ? btnRect.left : 0}
      top={btnRect ? btnRect.bottom : 0}>
      <div>
        <div className={styles.wrapper}>
          <div>{text}</div>
          <Button
            tag="a"
            target="_blank"
            href={`https://yandex.ru/maps/?ll=${geoData.longitude},${geoData.latitude}&z=16`}
            variant="text">
            {window.t('showOnMap')}
          </Button>
        </div>
      </div>
    </Menu>
  );
}
