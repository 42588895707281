import PinInteractivesList from './PinnedInteractives/PinInteractivesList';
import SessionRow from './SessionRow/SessionRow';
import SessionSkeleton from './SessionSkeleton/SessionSkeleton';
import Toolbar from './Toolbar/Toolbar';
import VirtualizedSessions from './VirtualizedSessions/VirtualizedSessions';
import { debounce, range } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, Icon } from 'components';

import useActions from 'hooks/useActions';
import useFetch from 'hooks/useFetch';
import useLocalStorage from 'hooks/useLocalStorage';

import { MainGuideContext } from 'providers/MainGuideProvider';

import { copyInteractive, createInteractive, deleteInteractive } from 'api/dataLayer';

import { confirm } from 'helpers/common';
import {
  filterByInteractives,
  filterByText,
  getConfirmText,
  getSessionData,
  hasActiveBroadcastId
} from 'helpers/interactives';
import { goTo } from 'helpers/routing';

import styles from './Sessions.module.css';

const propTypes = {
  hasInitialData: PropTypes.bool.isRequired
};

const defaultProps = {
  hasInitialData: false
};

const option = { withCache: true };

export function Sessions({ hasInitialData }) {
  const {
    addSnack,
    createSessionSuccess,
    removeSessions,
    setFilteredSessions,
    setSessions,
    setIsLoadingSessions,
    setActiveBroadcastData,
    setPinnedInteractives,
    updatePinInteractive,
    setScrollPosition
  } = useActions();

  const history = useHistory();
  const location = useLocation();
  const [{ response, isLoading, error }, doFetch, doForceFetch] = useFetch(
    'api_get_interactives',
    option
  );

  // const [sessions, setSessions] = useState([]);
  const { sessions, enableInteractives, scrollPosition } = useSelector((state) => state.sessions);
  const { currentProfile } = useSelector((state) => state.profile);
  const { interactives: pinInteractives, isSetData } = useSelector(
    (state) => state.pinnedInteractives
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedSessions, setSelectedSessions] = useState([]);
  const [searchText, setSearchText] = useLocalStorage('sessionSearchText', ''); // useState('');
  // const [userInfo, setUserInfo] = useLocalStorage('userInfo', null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [interactives, setInteractives] = useLocalStorage('interactives', [
    {
      type: 'vote',
      title: 'voting',
      img: 'module-vote.png',
      description: 'votingDesc',
      isActive: true
    },
    {
      type: 'quiz',
      title: 'quiz',
      img: 'module-quiz.png',
      description: 'quizDesc',
      isActive: true
    },
    {
      type: 'broadcast',
      title: 'broadcasting',
      img: 'module-tv.png',
      description: 'broadcastingDesc',
      isActive: true
    },
    // {
    //   type: "pulse",
    //   title: "pulse",
    //   img: "module-pulse.png",
    //   description: "pulseDesc",
    //   isActive: true,
    // },
    {
      type: 'content',
      title: 'news',
      img: 'module-content.png',
      description: 'newsDesc',
      isActive: true
    },
    {
      type: 'autoreply',
      title: 'autoreplyTitle',
      img: 'module-autoreply.png',
      description: 'autoreplyDesc',
      isActive: true
    },
    // регистрация участников мероприятий
    {
      type: 'registration',
      title: 'registration',
      img: 'module-registration.png',
      description: 'registrationDesc',
      isActive: true
    }
  ]);

  const listBoxRef = useRef(null);

  useEffect(() => {
    listBoxRef.current.scrollTop = scrollPosition;
  }, []);

  useEffect(() => {
    if (!currentProfile) return;

    if (!isSetData) {
      currentProfile.settings.pinnedInteractives &&
        setPinnedInteractives(currentProfile.settings.pinnedInteractives);
    }
  }, [currentProfile]);

  // todo: подумать над оптимизацией и хранением закрепленных интерактивов
  const pins = useMemo(() => {
    if (sessions?.length === 0 || pinInteractives?.length === 0) {
      return [];
    } else {
      return pinInteractives.map((el) => {
        const item = sessions.find((int) => int.id === el.id);
        if (item) {
          return item;
        }
      });
    }
  }, [pinInteractives, sessions]);

  useEffect(() => {
    setIsLoadingSessions(isLoading);
  }, [isLoading]);

  // const enableInteractives = () => ['pulse', 'vote'];// () => interactives.map(el => el.type);

  useEffect(() => {
    if (!hasInitialData || isLoaded) return;
    doFetch({
      method: 'GET'
    });
    setIsLoaded(true);
  }, [hasInitialData, doFetch]);

  useEffect(() => {
    if (!response) return;
    if (sessions.length) return;

    // setSessions(response)

    let filtered = response.filter((el) => enableInteractives.includes(el.type));

    setSessions(filtered);
    filterSessions(filtered);
  }, [response, setSessions]);

  const selectSession = (sessionId, isMultiple) => {
    const newSelectedSessions = isMultiple ? [...selectedSessions, ...sessionId] : [...sessionId];
    setSelectedSessions(newSelectedSessions);
  };

  const openSession = ({ id, type }, data = null) => {
    goTo(history, `/${type}/${id}`, data, location);
  };

  const createSession = async (type) => {
    try {
      const response = await createInteractive({
        type,
        title: '',
        description: '',
        memo: ''
      });
      const session = getSessionData(type, response);
      // setSessions([session, ...sessions]);
      createSessionSuccess(session);
      openSession(session, { ...session, isCreated: true });
    } catch (ex) {
      addSnack({
        type: 'danger',
        message: window.t('anErrorAccruedDuringAnInteractiveCreation')
      });
    }
  };

  const LengthIsNotDuplicate = useMemo(() => {
    return (
      selectedSessions.length - selectedSessions.filter((el) => el.includes('duplicate')).length
    );
  }, [selectedSessions]);

  const removeSelectedSessions = async () => {
    const selectedCount = LengthIsNotDuplicate;

    if (selectedCount === 0) return;
    const confirmText = getConfirmText(sessions, selectedSessions, selectedCount);
    const idsToDel = [];

    if (confirm(confirmText)) {
      const toDel = {};
      try {
        for (const session of selectedSessions) {
          const typeId = session.split('_');
          const type = typeId[0];
          const id = typeId[1];
          if (toDel[type]) toDel[type].push(id);
          else toDel[type] = [id];
        }
        for (const [type, ids] of Object.entries(toDel)) {
          await deleteInteractive({
            type,
            param1: ids.join()
          });
          idsToDel.push(...ids);
          if (type === 'broadcast') {
            if (hasActiveBroadcastId(sessions, ids)) setActiveBroadcastData(null);
          }
        }
      } catch (ex) {
        console.error(ex);
      }
      removeSessions(idsToDel);
      setSelectedSessions([]);
      // удаление из списка закрепленных интерактивов
      idsToDel.forEach((id) => {
        const interactive = pinInteractives.find((el) => el.id === id);
        if (!interactive) return;
        togglePinHandler(interactive);
      });

      addSnack({
        type: 'success',
        message: window.t('interactiveRemovedSuccessfully')
      });
    }
  };

  const filterSessions = (sessionsList = null) => {
    const toFilter = sessionsList || sessions;

    if (toFilter.length === 0) return;

    const filtered = filterByInteractives(toFilter, interactives);
    const toShow = filterByText(filtered, searchText).map((el) => el.id);

    const activeInteractives = [...interactives].filter((el) => el.isActive).map((el) => el.type);
    setIsFiltered(
      (activeInteractives.length !== interactives.length && activeInteractives.length !== 0) ||
        toShow.length !== toFilter.length
    );
    // setSessions(toFilter => toFilter.map(el => toShow.includes(el.id)
    //   ? { ...el, isHidden: false }
    //   : { ...el, isHidden: true }
    // ));
    const formatedInteractiveList =
      activeInteractives.length === interactives.length || activeInteractives.length === 0
        ? interactives.map((el) => el.type)
        : activeInteractives;

    setFilteredSessions(toShow, formatedInteractiveList);
  };

  useEffect(() => {
    filterSessions();
  }, [searchText, interactives]);

  const copySession = useCallback(async () => {
    const session = selectedSessions[0];
    const [type, iactive_id] = session.split('_');

    try {
      const response = await copyInteractive({
        type,
        iactive_id
      });
      const session = getSessionData(type, response);
      // setSessions([session, ...sessions]);
      createSessionSuccess(session);
      openSession(session, { ...session, isCreated: true });
    } catch (ex) {
      addSnack({
        type: 'danger',
        message: window.t('anErrorAccruedDuringAnInteractiveCreation')
      });
    }
  }, [selectedSessions, addSnack, createSessionSuccess]);

  // Скрытие дублирующих трансляций викрорины и голосования
  const hiddenBroadcastId = useMemo(
    () => sessions.filter((el) => el.broadcastId).map((el) => el.broadcastId),
    [sessions]
  );
  const sessionsIsNotHidden = useMemo(
    () => sessions.filter((el) => el.type !== 'broadcast' || !hiddenBroadcastId.includes(el.id)),
    [sessions]
  );

  const togglePinHandler = (el) => {
    updatePinInteractive(el);
  };

  const debounceSetScrollPosition = debounce(setScrollPosition, 500);

  const rowsVirtualized = useMemo(() => {
    return sessionsIsNotHidden.filter(
      (el) => el.isHidden === false && !pinInteractives.find((int) => int && int.id === el.id)
    );
  }, [sessionsIsNotHidden, pinInteractives]);

  // Guide

  const {
    setStepIndex,
    interactiveToolbarRef,
    interactiveSearchBtnRef,
    interactiveSearchMenuRef,
    stepIndex
  } = useContext(MainGuideContext);

  useEffect(() => {
    if (stepIndex > 5) {
      const activeInteractives = interactives.filter(({ type, isActive }) => isActive);
      if (activeInteractives.length === 1 && activeInteractives[0].type === 'broadcast') {
        setStepIndex((v) => ++v);
      }
    }
  }, [interactives]);

  return (
    <div className={styles.workContent}>
      <Toolbar
        refreshSessions={() => {
          doForceFetch({
            method: 'GET'
          });
        }}
        createSession={createSession}
        removeSelectedSessions={removeSelectedSessions}
        searchText={searchText}
        setSearchText={setSearchText}
        isFiltered={isFiltered}
        interactives={interactives}
        setInteractives={setInteractives}
        copySession={copySession}
        selectedSessionsLength={LengthIsNotDuplicate}
        // Guide
        setStepIndex={setStepIndex}
        interactiveToolbarRef={interactiveToolbarRef}
        interactiveSearchMenuRef={interactiveSearchMenuRef}
        interactiveSearchBtnRef={interactiveSearchBtnRef}
        stepIndex={stepIndex}
      />

      <ul className={styles.listbox}>
        {
          isLoading ? (
            range(10).map((el) => <SessionSkeleton key={el} index={el} />)
          ) : (
            <VirtualizedSessions
              ref={listBoxRef}
              debounceSetScrollPosition={debounceSetScrollPosition}
              rows={rowsVirtualized}
              renderRow={(el) => (
                <SessionRow
                  pinInteractives={pinInteractives}
                  isSelected={selectedSessions.includes(`${el.type}_${el.id}`)}
                  selectSession={selectSession}
                  openSession={openSession}
                  rowData={el}
                  key={`${el.type}_${el.id}_pin`}
                  togglePinHandler={togglePinHandler}
                />
              )}>
              {pinInteractives.length !== 0 && (
                <PinInteractivesList
                  pinInteractives={pinInteractives}
                  currentProfile={currentProfile}
                  togglePinHandler={togglePinHandler}
                  selectSession={selectSession}
                  openSession={openSession}
                  selectedSessions={selectedSessions}
                  pins={pins}
                />
              )}
            </VirtualizedSessions>
          )

          // sessions.map((el) => {
          //     return (
          //       <SessionRow
          //         pinInteractives={pinInteractives}
          //         isSelected={selectedSessions.includes(`${el.type}_${el.id}`)}
          //         selectSession={selectSession}
          //         openSession={openSession}
          //         rowData={el}
          //         key={`${el.type}_${el.id}_pin`}
          //         togglePinHandler={togglePinHandler}
          //       />
          //       // <SessionRawContainer
          //       //   isSelected={selectedSessions.includes(`${el.type}_${el.id}`)}
          //       //   selectSession={selectSession}
          //       //   openSession={openSession}
          //       //   rowData={el}
          //       //   key={i} />
          //     );
          //   })
        }
      </ul>
      <Button
        className={styles.scrollTopBtn}
        onlyIcon
        variant="icon"
        color="#989898"
        startIcon={<Icon size={16} fontName="fas fa-chevron-up" />}
        clickHandler={() => {
          listBoxRef.current.scrollTop = 0;
          setScrollPosition(0);
        }}
        style={{ transform: scrollPosition > 1100 ? 'scale(1)' : 'scale(0)' }}
      />
    </div>
  );
}

Sessions.propTypes = propTypes;
Sessions.defaultProps = defaultProps;
