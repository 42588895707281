import { useAutoReplyControls } from 'containers/AutoReplyContainer/hooks/useAutoReplyControls';

import { Button, Icon, Toolbar } from 'components';

export function AutoReplyToolbar({
  interactiveName,
  autoReplyId,
  activity,
  toggleSettings,
  startBroadcast
}) {
  const { startStopData } = useAutoReplyControls({
    activity,
    autoReplyId
  });
  const { startStopIcon, startStopTitle, startStopColor, startStopClickHandler } = startStopData;

  return (
    <div>
      <Toolbar title={interactiveName || window.t('autoReply')}>
        <div className="d-flex align-center z-20 flex-1">
          <Button
            startIcon={<Icon size={20} fontName="fal fa-cog" />}
            onlyIcon
            variant="icon"
            color="#6f6f6f"
            clickHandler={toggleSettings}
            title={window.t('settings')}
            style={{ padding: '6px 8px' }}
          />
          <Button
            startIcon={<Icon size={20} fontName={startStopIcon} />}
            onlyIcon
            variant="icon"
            color={startStopColor}
            clickHandler={startStopClickHandler}
            title={window.t(startStopTitle)}
            style={{ padding: '6px 8px' }}
            ref={startBroadcast}
          />
        </div>
      </Toolbar>
    </div>
  );
}
