import { VoteView } from './VoteView';
import PropTypes from 'prop-types';
import React from 'react';

import { useInteractive } from 'hooks';

import { INTERACTIVES } from 'store/constants';

import { useVote, useVoteGuide } from './hooks';

const propTypes = {
  type: PropTypes.oneOf([INTERACTIVES.vote, INTERACTIVES.quiz])
};

const defaultProps = {
  type: INTERACTIVES.vote
};

export function VoteContainer({ match, location, type }) {
  const { interactiveId, isSettingsOpened, setIsSettingsOpened } = useInteractive({
    match,
    location,
    type
  });

  const {
    voteInfo,
    interactiveName,
    isInteractiveExist,
    isVoteActive,
    result,
    broadcastId,
    broadcastActivity,
    toggleSettings,
    visibility,
    settings,
    state,
    activity
  } = useVote({
    interactiveId,
    match,
    location,
    isSettingsOpened,
    setIsSettingsOpened
  });

  const {
    run,
    startGuide,
    steps,
    stepIndex,
    setStepIndex,
    handleJoyrideCallback,
    interactiveInfoRef,
    socialPollsRef,
    exportButtonsRef,
    statesSettingsRef,
    okButtonRef,
    questionRef,
    variantRef,
    voteControlsRef
  } = useVoteGuide({ type });

  return (
    <VoteView
      voteInfo={voteInfo}
      interactiveName={interactiveName}
      type={type}
      isInteractiveExist={isInteractiveExist}
      interactiveId={interactiveId}
      isVoteActive={isVoteActive}
      result={result}
      broadcastId={broadcastId}
      broadcastActivity={broadcastActivity}
      toggleSettings={toggleSettings}
      visibility={visibility}
      isSettingsOpened={isSettingsOpened}
      settings={settings}
      state={state}
      activity={activity}
      // guide props
      run={run}
      startGuide={startGuide}
      steps={steps}
      stepIndex={stepIndex}
      setStepIndex={setStepIndex}
      handleJoyrideCallback={handleJoyrideCallback}
      interactiveInfoRef={interactiveInfoRef}
      socialPollsRef={socialPollsRef}
      exportButtonsRef={exportButtonsRef}
      statesSettingsRef={statesSettingsRef}
      okButtonRef={okButtonRef}
      questionRef={questionRef}
      variantRef={variantRef}
      voteControlsRef={voteControlsRef}
    />
  );
}

VoteContainer.propTypes = propTypes;
VoteContainer.defaultProps = defaultProps;
