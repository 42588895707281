import { useCallback, useEffect, useRef, useState } from 'react';

export const useAudioMetaData = ({ src }) => {
  const audio = useRef(null);
  const [info, setInfo] = useState({
    duration: 0
  });

  const loadMetaData = useCallback(() => {
    setInfo({
      duration: audio.current.duration
    });
  }, []);

  useEffect(() => {
    audio.current = new Audio();
    audio.current.addEventListener('loadedmetadata', loadMetaData);
    audio.current.src = src;
    return () => {
      audio.current.removeEventListener('loadedmetadata', loadMetaData);
    };
  }, [src]);

  return info;
};
