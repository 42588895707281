import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import styles from './FormatedTextPreview.module.css';

const propTypes = {
  text: PropTypes.string,
  font: PropTypes.string,
  width: PropTypes.number,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.number,
  isShow: PropTypes.bool,
  setIsValidPreview: PropTypes.func
};

function FormatedTextPreview({
  text,
  font,
  width,
  fontSize,
  fontWeight,
  isShow,
  setIsValidPreview,
  isValidPreview
}) {
  const contentRef = useRef();

  const isOverflowed = (el) => {
    return el.scrollWidth > el.offsetWidth;
  };

  useEffect(() => {
    if (isValidPreview !== !isOverflowed(contentRef.current)) {
      setIsValidPreview(!isOverflowed(contentRef.current));
    }
  }, [text, isValidPreview, setIsValidPreview]);

  return (
    <div className={styles['wrapper']} style={isShow ? { maxHeight: '500px' } : null}>
      <div className={styles['head']}>Preview</div>
      <div
        ref={contentRef}
        className={styles['content']}
        style={{
          fontWeight,
          fontFamily: `${font}, sans-serif`,
          width: `${width}px`,
          fontSize: `${fontSize}px`
        }}>
        {text}
      </div>
    </div>
  );
}

FormatedTextPreview.propTypes = propTypes;

export default FormatedTextPreview;
