import { throttle } from 'lodash';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useLocalStorage } from 'hooks';

import { addSnack } from 'store/thunks/snackBox';
import { stopVote } from 'store/thunks/vote';

import { formatDateAdd, formatDateEdit } from 'helpers/formatDate';

let interval;

const usePhoneNumbers = ({
  updateVote,
  variants,
  isVoteActive,
  addNewVariant,
  voteCount,
  title,
  phone_voting_poll_id,
  end_time
}) => {
  const dispatch = useDispatch();
  const poll_arr_ref = useRef(null);
  const previous_poll_arr_ref = useRef(null);
  const variantsRef = useRef(variants);
  const titleRef = useRef(title);
  const isVoteActiveRef = useRef();
  const [isPhoneNumbersUsed, setIsPhoneNumbersUsed] = useState(false);
  const [votingNumbers] = useLocalStorage('votingNumbers');
  const [variantsWithPhoneVoting, setVariantsWithPhoneVoting] = useState(variants);
  const [voteCountWithPhoneVoting, setVoteCountWithPhoneVoting] = useState(voteCount);
  const [poll_arr, set_poll_arr] = useState(null);

  const fetchPhoneVotingData = useCallback(async () => {
    const body = {
      client: '4channel343',
      start_interface: 'express',
      poll_type: 'multinum',
      povtor: 'distinct',
      delta_time_client: '0',
      max_vars: votingNumbers.length.toString()
    };
    const formData = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      formData.append(key, value);
    });
    votingNumbers.forEach((number) => {
      body[`num${number.id}_aon`] = number.aon;
      body[`name_num${number.id}`] = number.num;
    });
    const response = await fetch('https://iactive.pro/tmpl_archive_v4/poll_view.php', {
      method: 'POST',
      body: formData
    });
    if (!response.ok) {
      throw new Error(`${response.status} - ${response.statusText}`);
    }
    const resultText = await response.text();
    const resultArray = resultText.split('|').map((s) => s.trim());
    return resultArray;
  }, [votingNumbers]);

  const stopPhoneVoting = useCallback(async () => {
    const body = {
      id: poll_arr_ref.current[24],
      start_poll: poll_arr_ref.current[19],
      stop_poll: poll_arr_ref.current[20],
      client: '4channel343',
      max_vars: '4',
      start_interface: 'express',
      poll_type: 'multinum',
      povtor: 'distinct',
      delta_time_client: '0'
    };
    const formData = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const response = await fetch('https://iactive.pro/tmpl_archive_v4/poll_stop.php', {
      method: 'POST',
      body: formData
    });
    if (!response.ok) {
      throw new Error(`${response.status} - ${response.statusText}`);
    }
  }, []);

  const setVariants = useCallback(
    async (operation) => {
      if (operation === 'edit' && !poll_arr_ref.current) return;
      const vars = Object.fromEntries(
        variantsRef.current.map((variant) => [`var${variant.varnum}`, variant.comment])
      );
      vars.num_vars = variantsRef.current.length;

      // TODO remove 2hours difference
      let start_poll;
      let stop_poll;
      if (operation === 'add') {
        start_poll = formatDateAdd(new Date(Date.now() + 2 * 60 * 60 * 1000));
        stop_poll = formatDateAdd(new Date(new Date(end_time).getTime() + 2 * 60 * 60 * 1000));
      }
      if (operation === 'edit') {
        start_poll = formatDateEdit(new Date(poll_arr_ref.current[19].slice(1, -1)));
        stop_poll = formatDateEdit(new Date(Date.now() + 3 * 60 * 60 * 1000));
      }

      const body = {
        id: operation === 'edit' ? phone_voting_poll_id : poll_arr_ref.current[24],
        start_poll,
        stop_poll,
        vopros: titleRef.current,
        voicemail: '0',
        site: '0',
        tv_out: '0',
        oper: operation,
        user: '4channel343',
        delta_time_client: '0',
        max_vars: votingNumbers.length.toString(),
        ...vars
      };
      votingNumbers.forEach((number) => {
        body[`aon_var${number.id}`] = number.aon;
      });
      const formData = new FormData();
      Object.entries(body).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const response = await fetch('https://iactive.pro/tmpl_archive_v4/archive_poll_change.php', {
        method: 'POST',
        body: formData
      });
      if (!response.ok) {
        throw new Error(`${response.status} - ${response.statusText}`);
      }
    },
    [votingNumbers, phone_voting_poll_id, end_time]
  );

  useEffect(() => {
    if (!isPhoneNumbersUsed) return;
    if (!poll_arr_ref.current) {
      setVariantsWithPhoneVoting(variants);
      setVoteCountWithPhoneVoting(voteCount);
    } else {
      if (phone_voting_poll_id !== poll_arr_ref.current?.[24]) {
        return;
      }
      setVariantsWithPhoneVoting(
        variants.map((variant, i) => ({
          ...variant,
          count: (+variant.count + (+poll_arr_ref.current[4 * i + 3] || 13)).toString()
        }))
      );
      setVoteCountWithPhoneVoting(
        (+voteCount + (+poll_arr_ref.current[17].split(' ')[0] || 13)).toString()
      );
    }
  }, [voteCount, variants, phone_voting_poll_id, isPhoneNumbersUsed]);

  const handleFetch = useCallback(async () => {
    const new_poll_arr = await fetchPhoneVotingData();
    set_poll_arr(new_poll_arr);
  }, [fetchPhoneVotingData]);

  useEffect(() => {
    if (!isVoteActive || !isPhoneNumbersUsed) {
      clearInterval(interval);
      return;
    }
    handleFetch();
    interval = setInterval(handleFetch, 10000);
    return () => clearInterval(interval);
  }, [isVoteActive, isPhoneNumbersUsed, handleFetch]);

  useEffect(() => {
    if (
      !isVoteActive &&
      poll_arr_ref.current &&
      phone_voting_poll_id === poll_arr_ref.current[24] &&
      poll_arr_ref.current[18] === 'ACTIVE'
    ) {
      stopPhoneVoting();
      poll_arr_ref.current = null;
      previous_poll_arr_ref.current = null;
    }
  }, [isVoteActive, phone_voting_poll_id, stopPhoneVoting, handleFetch]);

  useEffect(() => {
    previous_poll_arr_ref.current = poll_arr_ref.current;
    poll_arr_ref.current = poll_arr;
  }, [poll_arr]);

  const handleUpdate = useMemo(
    () =>
      debounce(async () => {
        await setVariants('edit');
        const new_poll_arr = await fetchPhoneVotingData();
        set_poll_arr(new_poll_arr);
      }, 1000),
    [setVariants, fetchPhoneVotingData]
  );

  useEffect(() => {
    if (titleRef.current === title && isEqual(variantsRef.current, variants)) return;
    variantsRef.current = variants;
    titleRef.current = title;
    if (
      isVoteActive &&
      isPhoneNumbersUsed &&
      phone_voting_poll_id &&
      phone_voting_poll_id === poll_arr_ref?.current?.[24] &&
      poll_arr_ref?.current?.[18] === 'ACTIVE'
    ) {
      handleUpdate();
    }
  }, [isVoteActive, isPhoneNumbersUsed, title, variants, handleUpdate, phone_voting_poll_id]);

  useEffect(() => {
    if (!isVoteActive || !isPhoneNumbersUsed || !poll_arr_ref.current) {
      return;
    }
    if (!phone_voting_poll_id) {
      const handleCreate = async () => {
        await setVariants('add');
        const new_poll_arr = await fetchPhoneVotingData();
        const id = new_poll_arr[24];
        poll_arr_ref.current = new_poll_arr;
        updateVote('phone_voting_poll_id', id);
      };
      handleCreate();
      return;
    }

    if (phone_voting_poll_id === poll_arr_ref.current[24] && poll_arr_ref.current[18] === 'END') {
      if (
        previous_poll_arr_ref.current &&
        previous_poll_arr_ref.current[24] === poll_arr_ref.current[24] &&
        previous_poll_arr_ref.current[18] === 'ACTIVE'
      ) {
        dispatch(stopVote());
        previous_poll_arr_ref.current = null;
        poll_arr_ref.current = null;
        return;
      }
      const handleUpdate = async () => {
        await setVariants('edit');
        const new_poll_arr = await fetchPhoneVotingData();
        set_poll_arr(new_poll_arr);
      };
      handleUpdate();
    }
  }, [
    dispatch,
    handleFetch,
    updateVote,
    setVariants,
    isVoteActive,
    isPhoneNumbersUsed,
    phone_voting_poll_id,
    fetchPhoneVotingData
  ]);

  const toggleIsPhoneNumbersUsed = useCallback(() => {
    if (!votingNumbers || votingNumbers?.length < variants?.length) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('votingNumbersNotEnough')
        })
      );
      setIsPhoneNumbersUsed(false);
      return;
    }

    setIsPhoneNumbersUsed((state) => !state);
  }, [votingNumbers, variants, dispatch]);

  const handleAddNewVariant = useCallback(() => {
    if (isPhoneNumbersUsed && variants.length >= votingNumbers.length) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('votingNumbersNotEnough')
        })
      );
    } else {
      addNewVariant();
    }
  }, [isPhoneNumbersUsed, variants, votingNumbers, dispatch, addNewVariant]);

  const handleAddNewVariantWithThrottle = useMemo(
    () => debounce(handleAddNewVariant, 500),
    [handleAddNewVariant]
  );

  return {
    variantsWithPhoneVoting,
    isPhoneNumbersUsed,
    toggleIsPhoneNumbersUsed,
    handleAddNewVariant: handleAddNewVariantWithThrottle,
    voteCountWithPhoneVoting
  };
};

export default usePhoneNumbers;
