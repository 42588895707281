import Snack from './Snack/Snack';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import styles from './SnackBox.module.css';

class SnackBox extends React.Component {
  render() {
    return (
      <div className={styles['snack-box']}>
        {this.props.snacks.map((el, i, arr) => {
          return (
            <Snack
              type={el.type}
              message={el.message}
              cancelCallback={el.cancelCallback}
              key={el.id}
              bottom={el.bottom || 0}
              index={i}
              id={el.id}
              transition={el.transition}
              timeout={el.timeout}
            />
          );
        })}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    snacks: state.snackBox.snacks
  };
}

export default connect(mapStateToProps)(SnackBox);

SnackBox.propTypes = {
  snacks: PropTypes.array.isRequired
  // {
  //   type: PropTypes.oneOf(['danger', 'success', 'info', 'warning']).isRequired,
  //   message: PropTypes.string.isRequired,
  //   cancelCallback: PropTypes.func,
  //   bottom: PropTypes.number.isRequired,
  //   index: PropTypes.number.isRequired,
  //   id: PropTypes.string.isRequired,
  //   transition: PropTypes.string.isRequired,
  //   timeout: PropTypes.number.isRequired,
  // }
};

SnackBox.defaultProps = {
  snacks: []
};
