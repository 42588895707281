import {
  SET_MODAL_CURRENT_IMAGE,
  SET_MODAL_IMAGE_IS_OPENED,
  SET_MODAL_IMAGE_LIST,
  SET_MODAL_IMAGE_USER_INFO
} from './actionTypes';

export function setModalImageUserInfo(userInfo) {
  return {
    type: SET_MODAL_IMAGE_USER_INFO,
    userInfo
  };
}

export function setModalImageList(imageList) {
  return {
    type: SET_MODAL_IMAGE_LIST,
    imageList
  };
}

export function setModalCurrentImage(image) {
  return {
    type: SET_MODAL_CURRENT_IMAGE,
    image
  };
}

export function setModalImageIsOpened(isOpened) {
  return {
    type: SET_MODAL_IMAGE_IS_OPENED,
    isOpened
  };
}
