export const types = [
  {
    value: 'news',
    title: 'Новости'
  },
  {
    value: 'weather',
    title: 'Погода'
  },
  {
    value: 'avito',
    title: 'Объявление'
  },
  {
    value: 'congratulations',
    title: 'Поздравления'
  }
];

export const TEXT_EFFECTS = [
  {
    id: 1,
    type: 'color',
    value: '#53C3F1'
  },
  {
    id: 2,
    type: 'color',
    value: '#AFCA0B'
  }
];
