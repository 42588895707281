import throttle from 'lodash/throttle';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { RESPONSE_KEYS } from 'containers/Feed/constants';

import useActions from 'hooks/useActions';
import useFetch from 'hooks/useFetch';
import useInterval from 'hooks/useInterval';

import { getFetchOptions } from 'helpers/feed';

const option = { dataKey: null };

export const useFeed = ({ scrollBoxes, isNewAtTop }) => {
  const {
    setFavState,
    setItems,
    addSnack,
    multipleSelectFeedItems,
    selectFeedItem,
    setHasMorePrevious
  } = useActions();

  const [isLoaded, setIsLoaded] = useState(false);
  const { currentTab, tabs, ...feedState } = useSelector((state) => state.feed);
  const feedStateRef = useRef(null);
  const timeoutRef = useRef(null);

  const currentTabRef = useRef(currentTab);
  const { broadcastId, activeBroadcastData } = useSelector((state) => state.broadcast);
  const messageActionsConfig = useSelector((state) => state.settings.messageActionsConfig);
  const isLoadingRef = useRef(null);

  const [{ response, error, isLoading }, doFetch] = useFetch('api_get_feed', option);

  const [
    { response: responsePrevious, error: errorPrevious, isLoading: isLoadingPrevious },
    doFetchPrevious
  ] = useFetch('api_get_feed', option);

  const [
    { response: responseInterval, error: errorInterval, isLoading: isLoadingInterval },
    doFetchInterval
  ] = useFetch('api_get_feed', option);

  useEffect(() => {
    currentTabRef.current = currentTab;
  }, [currentTab]);

  useEffect(() => {
    feedStateRef.current = feedState;
  }, [feedState]);

  useEffect(() => {
    const value = !isLoaded || isLoading || isLoadingPrevious;
    if (!value) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        isLoadingRef.current = value;
      }, 100);
    } else {
      isLoadingRef.current = value;
    }
  }, [isLoading, isLoadingPrevious, isLoaded]);

  useEffect(() => {
    doFetch(getFetchOptions(feedState, currentTabRef.current, false, tabs));
  }, []);

  useInterval(
    () => {
      doFetchInterval(getFetchOptions(feedState, currentTabRef.current, false, tabs));
    },
    isLoaded ? 5000 : null
  );

  useEffect(() => {
    if (error) {
      addSnack({
        type: 'danger',
        message: `Произошла ошибка при загрузке фида`
      });
      return;
    }
    if (!response || response === null) return;

    for (const [tab, responseKey] of Object.entries(RESPONSE_KEYS)) {
      if (response[responseKey]?.length) {
        setItems(response[responseKey], scrollBoxes[tab], false, tab);
      }
    }

    setIsLoaded(true);
  }, [response, error, addSnack, setItems, scrollBoxes]);

  useEffect(() => {
    if (!responseInterval) return;

    for (const [tab, responseKey] of Object.entries(RESPONSE_KEYS)) {
      if (responseInterval[responseKey]?.length) {
        setItems(responseInterval[responseKey], scrollBoxes[tab], true, tab);
      }
    }
  }, [responseInterval, setItems, scrollBoxes]);

  useEffect(() => {
    if (errorPrevious) {
      addSnack({
        type: 'danger',
        message: `Произошла ошибка при загрузке фида`
      });
      return;
    }
    if (!responsePrevious || responsePrevious === null) return;

    setIsLoaded(true);
    for (const [tab, responseKey] of Object.entries(RESPONSE_KEYS)) {
      if (responsePrevious[responseKey]?.length) {
        setItems(responsePrevious[responseKey], scrollBoxes[tab], false, tab);
      } else if (tab === currentTabRef.current) {
        setHasMorePrevious(false);
      }
    }
  }, [responsePrevious, errorPrevious, addSnack, setItems, scrollBoxes, setHasMorePrevious]);

  const itemClickHandler = useCallback(
    (id) => {
      if (window.event.altKey) multipleSelectFeedItems(id, currentTab);
      else selectFeedItem(id, currentTab);
    },
    [currentTab]
  );

  const toggleFavorite = useCallback(
    (id, value, senderNumber) => setFavState(id, value, senderNumber, currentTab),
    [setFavState, currentTab]
  );

  const isSelected = (id) => feedState[currentTab]?.selectedItems?.includes(id);

  const loadPrevious = useMemo(
    () =>
      throttle(() => {
        if (isLoadingRef.current) return;
        doFetchPrevious(getFetchOptions(feedStateRef.current, currentTabRef.current, true, tabs));
      }, 200),
    []
  );

  return {
    isLoaded,
    isLoading: !isLoaded || isLoading || isLoadingPrevious,
    state: feedState,
    loadPrevious,
    isNewAtTop,
    isSelected,
    messageClickHandler: itemClickHandler,
    messageActionsConfig,
    broadcastId,
    toggleFavorite,
    activeBroadcastData
  };
};
