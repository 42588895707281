import { MoreButton } from '../MoreButton/MoreButton';

import { SvgCheckbox } from 'components';

import { useActions } from 'hooks';

import { getUIDateFromJSDate } from 'helpers/dateTime';

import styles from './CheckCorrectElement.module.css';

const getGeoCoords = async () => {
  const pos = await new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });

  return {
    longitude: pos.coords.longitude,
    latitude: pos.coords.latitude
  };
};

export function CheckCorrectElement({ locale, value, block, propKey }) {
  const { updateContentBlockField, addSnack } = useActions();

  const checkboxChangeHandler = async (isChecked) => {
    updateContentBlockField(block?.id, propKey, isChecked, 'isCheck');

    let checkDate = '';
    const value = { text: '', geoData: null };

    if (isChecked) {
      try {
        const coords = await getGeoCoords();
        checkDate = new Date().toLocaleString();
        value.text += `ОС: ${navigator.userAgentData.platform}\n`;
        value.text += `Браузер: ${navigator.userAgent}\n`;
        value.text += `Широта: ${coords.latitude}\n`;
        value.text += `Долгота: ${coords.longitude}\n`;
        value.geoData = coords;
      } catch (error) {
        updateContentBlockField(block?.id, propKey, !isChecked, 'isCheck');
        addSnack({
          type: 'danger',
          message: window.t('errGetGeoData')
        });
      }
    }

    updateContentBlockField(block?.id, propKey, checkDate, 'checkDate');
    updateContentBlockField(block?.id, propKey, value, 'value');
  };

  return (
    <div className={styles.wrapper}>
      <SvgCheckbox
        style={{ marginLeft: '-10px' }}
        isChecked={value.isCheck}
        changeHandler={checkboxChangeHandler}
      />
      <span>{value.label?.[locale] || window.t(propKey)}</span>
      {value.checkDate && (
        <>
          <span>{value.checkDate && getUIDateFromJSDate(new Date(value.checkDate))}</span>
          <MoreButton text={value.value.text} geoData={value.value.geoData} />
        </>
      )}
    </div>
  );
}
