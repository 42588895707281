import { VoteMessagesView } from './VoteMessagesView';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import { useVoteVariants } from 'containers/VoteVariantsContainer/hooks/useVoteVariants';

import { useLocalStorage } from 'hooks';

import { useVoteMessages } from './hooks/useVoteMessages';

const propTypes = {
  isVoteActive: PropTypes.bool,
  broadcastId: PropTypes.string
};

const defaultProps = {
  isVoteActive: false
};

export const VoteMessagesContainer = memo(
  ({ type, isVoteActive, broadcastId, rangeNumbers, voteInfo }) => {
    const {
      messages,
      isMessagesReversed,
      toggleMessagesOrder,
      isOnlyWinners,
      setIsOnlyWinners,
      isBroadcastMsgState,
      lastExportedMessages,
      setFavoriteState,
      activeBroadcastDataState
    } = useVoteMessages({ broadcastId, isVoteActive });
    const { variants, result } = useVoteVariants({ voteInfo });
    const rightQuestionVariants = variants[result - 1]?.name || null;
    const winnersId = messages
      .filter(
        (message) =>
          rightQuestionVariants &&
          rightQuestionVariants
            .map((item) => item.toLowerCase())
            .includes(message.content.toLowerCase())
      )
      .map((message) => message.id);

    // favorites in interactive

    const [favoriteMsg, setFavoriteMsg] = useLocalStorage('favoriteMsg', []);

    const statusFavorite = useMemo(() => {
      return favoriteMsg.find((el) => el.id === voteInfo?.id && el.type === type);
    }, [favoriteMsg]);

    return (
      <VoteMessagesView
        messages={messages}
        type={type}
        isMessagesReversed={isMessagesReversed}
        toggleMessagesOrder={toggleMessagesOrder}
        isOnlyWinners={isOnlyWinners}
        setIsOnlyWinners={setIsOnlyWinners}
        rangeNumbers={rangeNumbers}
        isBroadcastMsgState={isBroadcastMsgState}
        lastExportedMessages={lastExportedMessages}
        setFavoriteState={setFavoriteState}
        activeBroadcastDataState={activeBroadcastDataState}
        winnersId={winnersId}
        // favorites messages
        statusFavorite={statusFavorite}
      />
    );
  }
);

VoteMessagesContainer.propTypes = propTypes;
VoteMessagesContainer.defaultProps = defaultProps;
