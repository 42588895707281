import { scrollBoxActions } from 'store/slices/scrollBoxSlice';

let lastScrollTop = null;

export function saveScrollTop(scrollBoxRef) {
  return (dispatch, getState) => {
    lastScrollTop = scrollBoxRef;
    // const { scrollBoxRef } = getState().scrollBox;
    const scrollTop = scrollBoxRef?.current.scrollTop;
    dispatch(scrollBoxActions.saveScrollTopValue({ scrollTop }));
  };
}

export function returnScrollTopPosition(scrollBoxRef) {
  return (dispatch, getState) => {
    // const { lastScrollTop } = getState().scrollBox;
    setTimeout(() => {
      if (lastScrollTop !== null) dispatch(setScrollTop(lastScrollTop, scrollBoxRef));
      dispatch(scrollBoxActions.saveScrollTopValue({ scrollTop: null }));
    });
  };
}

export function setScrollTop(value, scrollBoxRef) {
  return (dispatch, getState) => {
    // const { scrollBoxRef } = getState().scrollBox;
    if (scrollBoxRef) scrollBoxRef.current.scrollTop = value;
  };
}

export function scrollToNewMessages(isNewAtTop, scrollBoxRef) {
  return (dispatch, getState) => {
    // const { scrollBoxRef } = getState().scrollBox;
    if (isNewAtTop) {
      scrollBoxRef.current.scrollTop = 0;
    } else {
      const offsetHeight = scrollBoxRef.current.offsetHeight;
      const scrollHeight = scrollBoxRef.current.scrollHeight;
      scrollBoxRef.current.scrollTop = scrollHeight - offsetHeight;
    }
  };
}
