import { SessionModuleView } from 'components';

import { useInteractive } from 'hooks';

import { INTERACTIVES } from 'store/constants';

import {
  RegistrationBody,
  RegistrationSettingsView,
  RegistrationState,
  RegistrationToolbarView
} from './components';

import { useRegistration } from './hooks/useRegistration';

export function RegistrationContainer({ match, location }) {
  const { interactiveId, isSettingsOpened, setIsSettingsOpened } = useInteractive({
    match,
    location,
    type: INTERACTIVES.registration
  });

  const {
    interactiveName,
    activity,
    dateRegistration,
    config,
    checkboxes,
    registration_url,
    updateRegistration,
    participants,
    tags,
    saveSettings,
    toggleSettings,
    handlePublish
  } = useRegistration({ isSettingsOpened, setIsSettingsOpened });

  return (
    <SessionModuleView className="overflow-hidden">
      <RegistrationToolbarView
        interactiveName={interactiveName}
        toggleSettings={toggleSettings}
        interactiveId={interactiveId}
        activity={activity}
      />
      <RegistrationState
        participants={participants}
        count={participants?.length}
        activity={activity}
        registration_url={registration_url}
        dateRegistration={dateRegistration}
        interactiveName={interactiveName}
        checkboxes={checkboxes}
      />
      {isSettingsOpened && (
        <RegistrationSettingsView
          saveSettings={saveSettings}
          config={config}
          interactiveId={interactiveId}
          updateRegistration={updateRegistration}
          activity={activity}
          handlePublish={handlePublish}
          tags={tags}
        />
      )}
      <RegistrationBody
        shouldBeConfig={checkboxes}
        interactiveId={interactiveId}
        participants={participants}
      />
    </SessionModuleView>
  );
}
