import { INTERACTIVE_ACTIVITIES, SOCIAL_DOMAINS } from '../constants';
import { updateAppSettings } from './settings';
import { addSnack } from './snackBox';

import { updateInfoField } from 'store/actions/isettings';
import { setSessionActivity } from 'store/actions/sessions';
import { broadcastActions } from 'store/slices/broadcastSlice';
import { voteActions } from 'store/slices/voteSlice';

import {
  addVoteToVariant,
  addVoteVariant,
  clearTitre as clearTitreApi,
  clearVoteStats,
  deleteVoteImage as deleteVoteImageApi,
  deleteVoteVariant as deleteVoteVariantApi,
  editRangeNumbers as editRangeNumbersApi,
  getOkVotes,
  getVkVotes,
  getVoteVariants,
  getVoteWinners,
  setVoteResult as setVoteResultApi,
  startVote as startVoteApi,
  stopVote as stopVoteApi,
  updateVoteVariant
} from 'api/dataLayer';

import { parseJSON } from 'helpers/text';

export function fetchVoteVariants(id) {
  return async (dispatch, getState) => {
    try {
      const { info } = getState().isettings;
      const { alerts, isAlertEnable } = getState().vote;
      const response = await getVoteVariants(id);
      const variants = response.body;

      const [updatedVariants, errorMessage] = await getUpdatedVariants(variants, info, dispatch);

      if (isAlertEnable && alerts.length === 0 && errorMessage !== '')
        dispatch(
          voteActions.addVoteAlert({
            alert: {
              type: 'danger',
              message: errorMessage
            }
          })
        );

      const [percentList, countAll] = calcVotes(updatedVariants);

      dispatch(voteActions.setTotalVoteCount({ voteCount: countAll }));

      updatedVariants.forEach((el, i) => {
        const keywords = parseJSON(el.name, [el.name]);
        el.name = Array.isArray(keywords) ? keywords : [el.name];
        el.percent = percentList[i];
        el.order = i + 1;
        el.oldName = el.name;
        el.picture = null;
        el.tags = [];
      });

      dispatch(voteActions.fetchVoteVariantsSuccess({ variants: updatedVariants }));
    } catch (ex) {
      console.info(ex);
      dispatch(voteActions.fetchVoteVariantsError({ error: ex }));
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errLoadingVariants')
        })
      );
    }
  };
}

export function updateVoteVariantsStats(id) {
  return async (dispatch, getState) => {
    try {
      const { info } = getState().isettings;
      const { alerts, isAlertEnable } = getState().vote;
      const response = await getVoteVariants(id);
      const variants = response.body;

      const [updatedVariants, errorMessage] = await getUpdatedVariants(variants, info);

      if (isAlertEnable && alerts.length === 0 && errorMessage !== '')
        dispatch(
          voteActions.addVoteAlert({
            alert: {
              type: 'danger',
              message: errorMessage
            }
          })
        );

      const [percentList, countAll] = calcVotes(updatedVariants);

      dispatch(voteActions.setTotalVoteCount({ voteCount: countAll }));
      const variantsStats = {};

      updatedVariants.forEach((el, i) => {
        variantsStats[el.varnum] = {
          percent: percentList[i],
          count: el.count
        };
      });
      dispatch(voteActions.updateVoteVariantsStatsSuccess({ variants: variantsStats }));
    } catch (ex) {
      console.info(ex);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errLoadingVariants')
        })
      );
    }
  };
}

export function startVote() {
  return async (dispatch, getState) => {
    try {
      const voteId = getState().vote.voteId;
      await startVoteApi(voteId);
      dispatch(updateInfoField('activity', INTERACTIVE_ACTIVITIES.active));
      dispatch(setSessionActivity(voteId, INTERACTIVE_ACTIVITIES.active));
      dispatch(voteActions.setVoteActivity({ isVoteActive: true }));
    } catch (ex) {
      // dispatch(startVoteError(ex))
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errStartingInteractive')
        })
      );
    }
  };
}

export function updateStartVote(voteId) {
  return async (dispatch, getState) => {
    const { info } = getState().isettings;

    if (info.id === voteId && info.type === 'vote') {
      dispatch(updateInfoField('activity', INTERACTIVE_ACTIVITIES.active));
    }
    dispatch(setSessionActivity(voteId, INTERACTIVE_ACTIVITIES.active));
    dispatch(voteActions.setVoteActivity({ isVoteActive: true }));
  };
}

export function stopVote() {
  return async (dispatch, getState) => {
    try {
      const voteId = getState().vote.voteId;
      await stopVoteApi(voteId);
      dispatch(updateInfoField('activity', INTERACTIVE_ACTIVITIES.finished));
      dispatch(setSessionActivity(voteId, INTERACTIVE_ACTIVITIES.finished));
      dispatch(voteActions.setVoteActivity({ isVoteActive: false }));
    } catch (ex) {
      // dispatch()
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errStopInteractive')
        })
      );
    }
  };
}

export function updateStopVote(voteId) {
  return async (dispatch, getState) => {
    const { info } = getState().isettings;

    if (info.id === voteId && info.type === 'vote') {
      dispatch(updateInfoField('activity', INTERACTIVE_ACTIVITIES.finished));
    }
    dispatch(setSessionActivity(voteId, INTERACTIVE_ACTIVITIES.finished));
    dispatch(voteActions.setVoteActivity({ isVoteActive: false }));
  };
}

export function addNewVariant() {
  return async (dispatch, getState) => {
    try {
      const voteState = getState().vote;
      const voteId = voteState.voteId;
      const next = +voteState.variants[voteState.variants.length - 1].varnum + 1 || 1;
      const variant = {
        id: voteId,
        name: [`Вариант${next}`],
        num: next,
        comment: ''
      };
      await addVoteVariant(variant);
      dispatch(fetchVoteVariants(voteId));
    } catch (ex) {
      // dispatch(addVariantError(ex))
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errAddingNewVariant')
        })
      );
    }
  };
}

export function deleteVoteVariant(varnum) {
  return async (dispatch, getState) => {
    const { info } = getState().isettings;
    const voteState = getState().vote;
    const variants = voteState.variants;
    let message = '';

    if (info.type === 'quiz') {
      if (variants.length <= 1) {
        message = window.t('errCantDeleteVariantQuiz');
      }
    } else if (variants.length <= 2) {
      message = window.t('errCantDeleteVariantVote');
    }

    if (voteState.isVoteActive) message = window.t('errInteractiveIsActive');

    if (message) {
      dispatch(
        addSnack({
          type: 'danger',
          message
        })
      );
      return;
    }

    try {
      const id = voteState.voteId;
      await deleteVoteVariantApi({
        id,
        varnum
      });
      // dispatch(deleteVariant(varnum));
      dispatch(fetchVoteVariants(id));
    } catch (ex) {
      // dispatch(deleteVariantError(ex));
    }
  };
}

export function changeVoteVariant(varnum, field, value) {
  return async (dispatch, getState) => {
    const voteState = getState().vote;
    const voteId = voteState.voteId;
    const variants = [...voteState.variants];
    const variant = variants.find((el) => el.varnum === varnum);

    if (variant.errorMsg && variant.errorMsg.length > 0) {
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errCantChangeVariantAlreadyExist')
        })
      );
      dispatch(fetchVoteVariants(voteId));
      return;
    }

    // variant[field] = value;
    // if (field === 'name')
    //   variant.oldName = value;

    // TODO: check before production

    // variant.id = voteId;

    // variant.num = variant.varnum;
    const updatedVariant = {
      ...variant,
      num: variant.varnum,
      id: voteId
    };

    try {
      await updateVoteVariant(updatedVariant);
      dispatch(voteActions.updateVariantField({ varnum, field, value }));
      // dispatch(updateVariants(variants));
    } catch (ex) {
      // dispatch(updateVariantError(ex))
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errChangingVariant')
        })
      );
    }
  };
}

export function updateVariant(varnum, field, value) {
  return (dispatch, getState) => {
    dispatch(voteActions.updateVariantField({ varnum, field, value }));
    if (field === 'name') {
      const variants = getState().vote.variants;
      if (isAlreadyExist(varnum, variants, value)) {
        dispatch(
          voteActions.updateVariantField({
            varnum,
            field: 'errorMsg',
            value: 'errVariantAlreadyExist'
          })
        );
      } else {
        dispatch(
          voteActions.updateVariantField({
            varnum,
            field: 'errorMsg',
            value: ''
          })
        );
      }
    }
  };
}

export function setVoteResult(voteId, varnum) {
  return async (dispatch) => {
    try {
      await setVoteResultApi({ id: voteId, varnum });
      dispatch(updateInfoField('result', varnum));
    } catch (ex) {
      console.error(ex);
    }
  };
}

export function editRangeNumbers(voteId, range, oldState) {
  return async (dispatch) => {
    try {
      dispatch(updateInfoField('rangeNumbers', range));
      await editRangeNumbersApi({ id: voteId, range });
    } catch (ex) {
      console.error(ex);
      dispatch(updateInfoField('rangeNumbers', oldState));
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errRangeNumbers')
        })
      );
    }
  };
}

export function addVote(id, varnum) {
  return async (dispatch, getState) => {
    try {
      await addVoteToVariant({ id, varnum });

      const variants = Array.from(getState().vote.variants);

      const index = variants.findIndex((el) => el.varnum === varnum);

      const newVariants = variants.map((variant, i) => {
        if (i === index) {
          return {
            ...variant,
            count: +variant.count + 1
          };
        }
        return variant;
      });

      const [percentList, countAll] = calcVotes(newVariants);

      dispatch(voteActions.setTotalVoteCount({ count: countAll }));

      const newVariantsWithCalcPercent = newVariants.map((el, i) => {
        return {
          ...el,
          percent: percentList[i]
        };
      });

      dispatch(voteActions.fetchVoteVariantsSuccess({ variants: newVariantsWithCalcPercent }));
    } catch (ex) {
      console.error(ex);
    }
  };
}

export function deleteVoteImage(id, varnum = null) {
  return async (dispatch) => {
    try {
      await deleteVoteImageApi({ id, varnum });
      if (varnum) dispatch(voteActions.updateVariantField({ varnum, field: 'var_img_url' }));
      else dispatch(updateInfoField('question_img_url'));
    } catch (ex) {
      console.error(ex);
    }
  };
}

export function clearVotes() {
  return async (dispatch, getState) => {
    try {
      const { id, broadcastId } = getState().isettings.info;
      await clearVoteStats(id);
      await clearTitreApi(null, broadcastId);

      dispatch(broadcastActions.clearTitreSuccess({ titreTag: null, broadcastId }));

      const variants = [...getState().vote.variants].map((variant) => ({
        ...variant,
        count: 0,
        percent: 0
      }));

      dispatch(voteActions.setTotalVoteCount({ count: 0 }));
      dispatch(voteActions.fetchVoteVariantsSuccess({ variants }));
      if (broadcastId) dispatch(broadcastActions.fetchBroadcastMessagesSuccess({ messages: [] }));
      dispatch(
        addSnack({
          type: 'success',
          message: window.t('sccStatisticsCleared')
        })
      );
    } catch (ex) {
      console.error(ex);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errStatisticsClear')
        })
      );
    }
  };
}

export function setVoteMessagesOrder(isMessagesReversed) {
  return (dispatch, getState) => {
    const { messages } = getState().broadcast;
    const isAlreadyReversed = checkIsMessagesReversed(messages);
    if (isMessagesReversed === isAlreadyReversed) return;

    dispatch(voteActions.setVoteMessagesOrderAction({ isMessagesReversed }));
    dispatch(
      broadcastActions.fetchBroadcastMessagesSuccess({
        messages: messages.reverse()
      })
    );
  };
}

export function setVoteWinners(id, type) {
  return async (dispatch) => {
    try {
      const res = await getVoteWinners({ id, type });
      const winners = Array.isArray(res.body) ? res.body.map((el) => el.id) : [];
      dispatch(voteActions.setVoteWinnersAction({ winners }));
    } catch (ex) {
      console.error(ex);
      dispatch(
        addSnack({
          type: 'danger',
          message: window.t('errLoadingVoteWinners')
        })
      );
    }
  };
}

async function getUpdatedVariants(variants, info) {
  let errorMessage = '';
  return [variants, errorMessage];
  // console.log({ info });
  // const { id, vk_poll_id, ok_poll_id } = info;
  // const queries = [
  //   ...(vk_poll_id
  //     ? [
  //         getVkVotes({
  //           voteId: id,
  //           postId: vk_poll_id,
  //         }),
  //       ]
  //     : []),
  //   ...(ok_poll_id
  //     ? [
  //         getOkVotes({
  //           voteId: id,
  //           postId: ok_poll_id,
  //         }),
  //       ]
  //     : []),
  // ];
  // const res = await Promise.all(queries);
  // if (res.length === 0) return [variants, errorMessage];

  // const vlength = variants.length;

  // res.forEach((response) => {
  //   const { pollVars, socialName } = response.body;

  //   if (!pollVars) return;

  //   const svlength = pollVars.length;

  //   if (vlength !== pollVars.length) {
  //     errorMessage +=
  //       window
  //         .t("errWrongVoteCount")
  //         .replace(/\[vlength\]/, vlength)
  //         .replace(/\[socialName\]/, SOCIAL_DOMAINS[socialName])
  //         .replace(/\[svlength\]/, svlength) + "<br/>";
  //   }

  //   variants.forEach((el) => {
  //     const socialVar = pollVars.find((elm) => elm.varnum === el.varnum);
  //     if (socialVar) el.count = +el.count + socialVar.count;
  //   });
  // });

  // return [variants, errorMessage];
}

function getPercentFromTotal(countList) {
  let totalSum = 0;
  countList = countList.map((el) => +el);
  countList.forEach((el) => (totalSum += el));
  let percentList = [];
  let percentListRounded = [];
  countList.forEach((el) => {
    if (el === 0 || totalSum === 0) {
      percentList.push(0);
    } else {
      percentList.push((el * 100) / totalSum);
    }
  });
  countList.forEach((el) => {
    if (el === 0 || totalSum === 0) {
      percentListRounded.push(0);
    } else {
      percentListRounded.push(Math.round((el * 100) / totalSum));
    }
  });

  let totalPercent = 0;
  percentListRounded.forEach((el) => (totalPercent += el));
  if (totalPercent === 0 || totalPercent === 100) {
    return percentListRounded;
  } else if (totalPercent > 100) {
    let roundDiff = [];
    let count = parseInt(totalPercent - 100);
    percentList.forEach((el) => roundDiff.push(Math.round(el) - el));
    for (let i = 1; i <= count; i++) {
      let maxValue = Math.max.apply(Math, roundDiff);
      let maxValueIndex = roundDiff.findIndex((el) => el === maxValue);
      roundDiff[maxValueIndex] -= 1;
      percentListRounded[maxValueIndex] = Math.round(percentListRounded[maxValueIndex] - 1);
    }
    return percentListRounded;
  } else if (totalPercent < 100) {
    let roundDiff = [];
    percentList.forEach((el) => roundDiff.push(Math.round(el) - el));
    let count = Math.round(100 - totalPercent);
    for (let i = 1; i <= count; i++) {
      let minValue = Math.min.apply(Math, roundDiff);
      let minValueIndex = roundDiff.findIndex((el) => el === minValue);
      roundDiff[minValueIndex] += 1;
      percentListRounded[minValueIndex] = Math.round(percentListRounded[minValueIndex] + 1);
    }
    return percentListRounded;
  }
}

export function isAlreadyExist(varnum, variants, newVal) {
  const variant = variants.find(
    (el) =>
      el.varnum !== varnum && el.name.toString().toLowerCase() === newVal.toString().toLowerCase()
  );
  return variant !== undefined;
}

export function calcVotes(variants) {
  const countList = [];
  variants.forEach((el) => countList.push(el.count));

  const percentList = getPercentFromTotal(countList);
  const countAll = variants.reduce((acc, el) => acc + Number(el.count), 0);

  return [percentList, countAll];
}

function checkIsMessagesReversed(messages) {
  const firstId = +messages[0].id;
  const lastId = +messages[messages.length - 1].id;
  return firstId > lastId;
}

export function setIsAlertEnable(isAlertEnable) {
  return async (dispatch) => {
    dispatch(voteActions.setIsAlertEnable({ isAlertEnable }));
    dispatch(updateAppSettings('isAlertEnable', isAlertEnable));
  };
}
