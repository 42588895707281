import PropTypes from 'prop-types';
import React from 'react';

import { Toolbar } from 'components';

import styles from './InteracitveNotFoundView.module.css';

const propTypes = {
  message: PropTypes.string
};

export function InteracitveNotFoundView({ message }) {
  return (
    <div className={styles['container-404']}>
      <Toolbar className={styles.toolbar} />
      <div className={styles['wrapper-404']}>
        <span className={styles['span-404']}>404</span>
        <span>{message}</span>
      </div>
    </div>
  );
}

InteracitveNotFoundView.propTypes = propTypes;
