import React, { forwardRef, memo } from 'react';

import { StatesSettingsItemContainer } from 'containers';

import { Button, Icon, Title } from 'components';

import { MAX_COUNT_STATE } from 'store/constants';

export const StatesSettingsView = memo(
  forwardRef(({ statesState, addState, removeState, updatePropertyValue }, ref) => {
    return (
      <div ref={ref} style={{ width: '47%' }}>
        <Title color="#808080" className="mb-2">
          {window.t('statesName')}
        </Title>
        {statesState &&
          Object.entries(statesState).map(([key, { duration, type, value }]) => (
            <StatesSettingsItemContainer
              key={key}
              label={key}
              value={value}
              duration={duration}
              type={type}
              updateValue={(newValue) => updatePropertyValue(key, 'value', newValue)}
              updateDuration={(newValue) => updatePropertyValue(key, 'duration', newValue)}
              updateType={(newValue) => updatePropertyValue(key, 'type', newValue)}
              removeHandler={() => removeState(key)}
              // blurHandler={() => {}}
            />
          ))}
        {statesState && Object.keys(statesState).length < MAX_COUNT_STATE ? (
          <Button
            startIcon={
              <Icon fontName="fal fa-plus" size={18} color="#81C2F5" style={{ marginRight: 11 }} />
            }
            variant="text"
            style={{
              marginTop: 25,
              marginBottom: 25,
              marginLeft: 34
            }}
            clickHandler={addState}>
            {window.t('addState')}
          </Button>
        ) : null}
      </div>
    );
  })
);
