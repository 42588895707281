import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { formatTimeToPlayer } from 'helpers/dateTime';

import styles from './TextareaLengthInfoView.module.css';

const propTypes = {
  type: PropTypes.oneOf(['time', 'symbols']),
  length: PropTypes.number,
  maxLength: PropTypes.number
};

const defaultProps = {
  type: 'symbols'
};

export function TextareaLengthInfoView({ type, length, maxLength }) {
  const isMore = maxLength !== 0 ? length > maxLength : false;
  length = type === 'time' ? formatTimeToPlayer(length) : length;
  maxLength = type === 'time' ? formatTimeToPlayer(maxLength) : maxLength;

  return (
    <span
      className={classNames('ml-2', {
        [styles['danger']]: isMore
      })}>
      {length}
      {maxLength ? '/' : ''}
      {maxLength !== 0 && maxLength}
    </span>
  );
}

TextareaLengthInfoView.propTypes = propTypes;
TextareaLengthInfoView.defaultProps = defaultProps;
