import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './ListItemText.module.css';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  isHover: PropTypes.bool,
  isActive: PropTypes.bool,
  className: PropTypes.string,
  primary: PropTypes.string,
  secondary: PropTypes.string,
  primaryClassName: PropTypes.string,
  secondaryClassName: PropTypes.string,
  withTitle: PropTypes.bool
};

const defaultProps = {
  isHover: false,
  isActive: false,
  primary: '',
  secondary: '',
  primaryClassName: '',
  secondaryClassName: '',
  withTitle: false
};

function ListItemText({
  isHover,
  isActive,
  className,
  primary,
  secondary,
  primaryClassName,
  secondaryClassName,
  withTitle,
  style
}) {
  const classes = classNames(styles['list-item-text'], className, {
    [styles['list-item-text--hover']]: isHover,
    [styles['list-item-text--selected']]: isActive
  });
  return (
    <div className={classes} {...(withTitle && { title: primary })} style={style}>
      {primary && (
        <span className={`${styles['list-item-text__primary']} ${primaryClassName}`}>
          {primary}
        </span>
      )}
      {secondary && (
        <p className={`${styles['list-item-text__secondary']} ${secondaryClassName}`}>
          {secondary}
        </p>
      )}
    </div>
  );
}

export default ListItemText;

ListItemText.propTypes = propTypes;
ListItemText.defaulProps = defaultProps;
