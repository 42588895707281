import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useActions from 'hooks/useActions';

import { STATE_TYPES } from 'store/constants';

import { editBroadcast } from 'api/dataLayer';

export const useBroadcastState = () => {
  const { updateInfoField } = useActions();
  const { info } = useSelector((state) => state.isettings);
  const { state } = info;

  const { workPaneWidth } = useSelector((state) => state.resize);
  const [broadcastState, setBroadcastState] = useState(null);

  useEffect(() => {
    setBroadcastState(state);
  }, [state]);

  const updateBroadcast = async (states) => {
    const updatedInfo = { ...info };
    updatedInfo.state = JSON.stringify(states);
    try {
      await editBroadcast(updatedInfo);
      updateInfoField('state', states);
    } catch (ex) {
      console.error(ex);
    }
  };

  const toggleState = (key, isActive) => {
    const newState = { ...broadcastState };

    newState[key].active = +isActive;

    for (const [_key, value] of Object.entries(newState)) {
      if (_key !== key) {
        if (value.type === STATE_TYPES.stop) {
          newState[_key].isRunned = false;
          newState[_key].active = false;
        } else if (value.type === STATE_TYPES.cycle) {
          newState[_key].isRunned = false;
          newState[_key].active = false;
        }
      }
    }
    setBroadcastState(newState);
    updateBroadcast(newState);
  };

  return {
    workPaneWidth,
    broadcastState,
    toggleState
  };
};
