import { СhannelNamesArray, СhannelTypesArray } from 'store/constants';

export function getChannelTypeByName(channel) {
  let chIndex = -1;
  if (channel) {
    chIndex = СhannelNamesArray.indexOf(channel);
  }
  if (chIndex === -1) {
    chIndex = 2;
  }

  return СhannelTypesArray[chIndex];
}
