import * as AutoreplyThunks from './autoreply';
import * as BannedThunks from './banned';
import * as BroadcastThunks from './broadcast';
import * as ChatThunks from './chat';
import * as ContentThunks from './content';
import * as FeedThunks from './feed';
import * as ISettingsThunks from './isettings';
import * as MessageCreatorThunks from './messageCreator';
import * as MessageFiltersThunks from './messageFilters';
import * as ModalImageThunks from './modalImage';
import * as PinnedInteractivesThunks from './pinnedInteractives';
import * as ProfileThunks from './profile';
import * as ProfileCardThunks from './profileCard';
import * as PulseThunks from './pulse';
import * as RegistrationThunks from './registration';
import * as ScrollBoxThunks from './scrollBox';
import * as SessionsThunks from './sessions';
import * as SessionsFilterThunks from './sessionsFilter';
import * as SettingsThunks from './settings';
import * as SnackBoxThunks from './snackBox';
import * as SocialsThunks from './socials';
import * as VoteThunks from './vote';

export default {
  ...BannedThunks,
  ...BroadcastThunks,
  ...ChatThunks,
  ...ISettingsThunks,
  ...MessageCreatorThunks,
  ...MessageFiltersThunks,
  ...ModalImageThunks,
  ...ProfileCardThunks,
  ...ScrollBoxThunks,
  ...SessionsThunks,
  ...SessionsFilterThunks,
  ...SettingsThunks,
  ...SnackBoxThunks,
  ...SocialsThunks,
  ...VoteThunks,
  ...PulseThunks,
  ...ContentThunks,
  ...ProfileThunks,
  ...FeedThunks,
  ...AutoreplyThunks,
  ...PinnedInteractivesThunks,
  ...RegistrationThunks
};
