import { RegistrationEventsView } from './RegistrationEventsView';
import { memo } from 'react';

import { useRegistrationEvents } from './hooks/useRegistrationEvents';

export const RegistrationEventsContainer = memo(({ tags }) => {
  const {
    event_title,
    event_description,
    event_place,
    event_image,
    event_date,
    changeSessionInfo,
    pictureChangeHandler,
    deletePicture,
    isLoading,
    changeEventField,
    addTags,
    removeTags
  } = useRegistrationEvents();

  return (
    <RegistrationEventsView
      changeEventField={changeEventField}
      event_title={event_title}
      event_description={event_description}
      event_place={event_place}
      event_image={event_image}
      event_date={event_date}
      changeSessionInfo={changeSessionInfo}
      pictureChangeHandler={pictureChangeHandler}
      deletePicture={deletePicture}
      isLoading={isLoading}
      tags={tags}
      addTags={addTags}
      removeTags={removeTags}
    />
  );
});
