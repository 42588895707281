import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';

import { setSnackBottom, setSnackHeight } from 'store/actions/snackBox';
import { removeSnack } from 'store/thunks/snackBox';

import styles from './Snack.module.css';

class Snack extends React.Component {
  constructor(props) {
    super(props);
    this.snackRef = React.createRef();
  }

  state = {
    left: -1000,
    removed: false,
    timeoutId: null
  };

  componentDidMount() {
    if (!this.state.timeoutId) {
      const timeoutId = setTimeout(() => {
        // console.log(`${this.props.id} is ready to del`);
        this.closeHandler();
      }, this.props.timeout);
      this.setState({ timeoutId });
    }

    setTimeout(() => {
      this.setState({
        left: 24
      });
    }, 1);

    // this.snackRef.current.'
    const index = this.props.index;
    const rect = this.snackRef.current.getClientRects();
    const height = rect[0].height;
    this.props.setSnackHeight(this.props.index, height);

    if (index >= 1) {
      // в цикле у снеков меняется отступ снизу
      // отступ предыдущего элемента, получает новый элемент
      // и наоборот, таким образом старые снеки всплывают вверх
      this.props.snacks.forEach((el, i) => {
        if (i >= 1) {
          const prevBottom = this.props.snacks[i - 1].bottom;
          const nextBottom = prevBottom + height + 10;
          this.props.setSnackBottom(i - 1, nextBottom);
          this.props.setSnackBottom(i, prevBottom);
        }
      });
    } else {
      this.props.setSnackBottom(this.props.index, 0);
    }
  }

  closeHandler = () => {
    this.setState({
      removed: true
    });
    setTimeout(() => {
      this.props.removeSnack(this.props.id);
    }, 400);
  };

  onCancel = () => {
    this.closeHandler();
    this.props.cancelCallback();
  };

  getIconByType = (type) => {
    switch (type) {
      case 'success':
        return 'checkCircle';
      case 'warning':
        return 'exclamationTriangle';
      case 'danger':
      case 'info':
      default:
        return 'exclamationCircle';
    }
  };

  render() {
    const classes = classNames(styles['snack_elem'], styles[this.props.type], {
      [styles['backOutLeft']]: this.state.removed
    });
    return (
      <>
        <div
          ref={this.snackRef}
          data-type="snack"
          className={classes}
          style={{
            transition: this.props.transition,
            bottom: `${this.props.bottom}px`,
            left: `${this.state.left}px`
          }}>
          <div className={styles['snack-content']}>
            <Icon
              size={22}
              style={{ opacity: 0.9, flexShrink: 0 }}
              icon={this.getIconByType(this.props.type)}
              color="#fff"
            />
            <p className="ml-2">{this.props.message}</p>
          </div>
          {typeof this.props.cancelCallback === 'function' && (
            <Button
              clickHandler={this.onCancel}
              color="#ffffff"
              hoverColor="#67676738"
              activeColor="#6767674f"
              variant="outlined"
              style={{
                fontSize: '13px',
                padding: '7px 10px',
                marginRight: 5,
                flexShrink: 0
              }}>
              Отмена
            </Button>
          )}
          <Button
            clickHandler={this.closeHandler}
            onlyIcon
            color="#ffffff"
            hoverColor="#67676738"
            activeColor="#6767674f"
            variant="outlined"
            style={{ padding: '5px 11px', marginRight: -8 }}
            startIcon={<Icon size={18} fontName="fal fa-times" />}
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    snacks: state.snackBox.snacks
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSnackHeight: (index, height) => dispatch(setSnackHeight(index, height)),
    setSnackBottom: (index, bottom) => dispatch(setSnackBottom(index, bottom)),
    removeSnack: (index) => dispatch(removeSnack(index))
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Snack);

Snack.propTypes = {
  type: PropTypes.oneOf(['danger', 'success', 'info', 'warning']).isRequired,
  message: PropTypes.string.isRequired,
  cancelCallback: PropTypes.func,
  bottom: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  transition: PropTypes.string.isRequired,
  timeout: PropTypes.number.isRequired,
  snacks: PropTypes.array.isRequired,
  // type: PropTypes.oneOf(['danger', 'success', 'info', 'warning']).isRequired,
  // message: PropTypes.string.isRequired,
  // cancelCallback: PropTypes.func,
  // bottom: PropTypes.number.isRequired,
  // index: PropTypes.number.isRequired,
  // id: PropTypes.string.isRequired,
  // transition: PropTypes.string.isRequired,
  // timeout: PropTypes.number.isRequired,
  setSnackHeight: PropTypes.func.isRequired,
  setSnackBottom: PropTypes.func.isRequired,
  removeSnack: PropTypes.func.isRequired
};

Snack.defaultProps = {
  snacks: []
};
