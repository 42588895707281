import { ParticipantCard } from './ParticipantCard/ParticipantCard';
import { memo, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useActions, useLocalStorage } from 'hooks';

import styles from './RegistrationBody.module.css';

export const RegistrationBody = memo(({ shouldBeConfig, interactiveId, participants }) => {
  const { answer } = useSelector((state) => state.settings.messageFeedConfig);

  const {
    updateParticipants,
    deleteParticipant,
    resetRegistrationStore,
    editParticipantAuthorData,
    editAuthorData
  } = useActions();

  useEffect(() => {
    updateParticipants(interactiveId);
    return () => {
      resetRegistrationStore();
    };
  }, [interactiveId]);

  const namesFieldsFilter = ['name', 'surname', 'phone', 'participation', 'patronymic'];

  const shouldBeBodyConfig = useMemo(
    () => shouldBeConfig?.filter((el) => !namesFieldsFilter.includes(el.name)),
    [shouldBeConfig]
  );
  const shouldBeHeadConfig = useMemo(
    () => shouldBeConfig?.filter((el) => namesFieldsFilter.includes(el.name)),
    [shouldBeConfig]
  );

  return (
    <div className={styles.container}>
      {participants.length !== 0 &&
        participants
          ?.filter((part) => part?.isHidden === false)
          ?.map((el) => (
            <ParticipantCard
              card={el}
              key={el.card_id}
              shouldBeBodyConfig={shouldBeBodyConfig}
              shouldBeHeadConfig={shouldBeHeadConfig}
              quickReplies={answer}
              deleteParticipant={deleteParticipant}
              editAuthorData={editAuthorData}
              editParticipantAuthorData={editParticipantAuthorData}
              date={el.create_in_db}
            />
          ))}
    </div>
  );
});
