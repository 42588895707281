import {
  ADD_SNACK,
  REMOVE_SNACK,
  RESET_SNACKS_TRANSITION,
  SET_SNACK_BOTTOM,
  SET_SNACK_HEIGHT,
  SET_SNACK_TRANSITION
} from '../actions/actionTypes';

const initialState = {
  snacks: [
    // {
    //   type: 'success',
    //   message: 'successinfo',
    //   cancelCallback: () => { alert('wwww') }
    // },
    // {
    //   type: 'info',
    //   message: 'info',
    //   cancelCallback: () => { alert('wwww') }
    // },
    // {
    //   type: 'danger',
    //   message: 'danger',
    //   cancelCallback: () => { alert('wwww') }
    // },
    // {
    //   type: 'warning',
    //   message: 'warning',
    // },
  ]
};
export default function snackBoxReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_SNACK:
      return {
        ...state,
        snacks: [...state.snacks, action.snack]
      };
    case SET_SNACK_HEIGHT:
      return {
        ...state,
        snacks: state.snacks.map((snack, i) =>
          i === action.index ? { ...snack, height: action.height } : snack
        )
      };
    case SET_SNACK_BOTTOM:
      return {
        ...state,
        snacks: state.snacks.map((snack, i) =>
          i === action.index ? { ...snack, bottom: action.bottom } : snack
        )
      };
    case SET_SNACK_TRANSITION:
      return {
        ...state,
        snacks: state.snacks.map((snack, i) =>
          snack.id === action.id ? { ...snack, transition: action.transition } : snack
        )
      };
    case RESET_SNACKS_TRANSITION:
      return {
        ...state,
        snacks: state.snacks.map((snack) => {
          return { ...snack, transition: 'all 200ms ease 0s' };
        })
      };
    case REMOVE_SNACK:
      return {
        ...state,
        snacks: state.snacks.filter((el) => el.id !== action.id)
      };
    default:
      return state;
  }
}
