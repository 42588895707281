import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, Icon, Input } from 'components';

import { useActions } from 'hooks';

import styles from './TelegramSettings.module.css';

function TelegramSettings() {
  const accounts = useSelector((store) => store.settings?.exportConfig.telegram);
  const { addExportConfig, deleteExportConfig, updateExportConfig } = useActions();

  if (!accounts) {
    return null;
  }

  return (
    <div>
      <div className={styles['input-groups__wrapper']}>
        {accounts?.length > 0 &&
          accounts.map((item, i) => (
            <TelegramInputGroup
              key={i + item.name + item.userName}
              value={item}
              index={i}
              deleteTelegramConfig={deleteExportConfig}
              updateTelegramConfig={updateExportConfig}
            />
          ))}
        <Button
          clickHandler={() => addExportConfig('telegram', { userName: '', name: '' })}
          isDisabled={accounts?.length > 9 || accounts?.some((item) => !item.userName)}
          startIcon={
            <Icon fontName="fal fa-plus" size={18} color="#81C2F5" style={{ marginRight: 11 }} />
          }
          variant="text"
          className={styles['add-btn']}>
          {window.t('addTelegramAccount')}
        </Button>
      </div>
    </div>
  );
}

export default TelegramSettings;

function TelegramInputGroup({ value, index, updateTelegramConfig, deleteTelegramConfig }) {
  const [nameValue, setNameValue] = useState(value.name);
  const [userNameValue, setTelegramValue] = useState(value.userName);

  return (
    <div className={styles['input-group']}>
      <Button
        onlyIcon
        startIcon={<Icon fontName="fal fa-times-circle" size={18} />}
        variant="icon"
        color="#CDCDCD"
        className="ml-1"
        style={{
          borderRadius: '50%',
          padding: 0
        }}
        clickHandler={() => deleteTelegramConfig('telegram', index)}
      />
      <Input
        blurHandler={(e) => updateTelegramConfig('telegram', index, { userName: e.target.value })}
        value={userNameValue}
        placeholder="Telegram user name"
        changeHandler={(e) => setTelegramValue(e.target.value)}
      />
      <Input
        blurHandler={(e) => updateTelegramConfig('telegram', index, { name: e.target.value })}
        value={nameValue}
        placeholder={window.t('name')}
        changeHandler={(e) => setNameValue(e.target.value)}
      />
    </div>
  );
}
