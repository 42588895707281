import chatReducer from './chat';
import feedReducer from './feed';
import isettingsReducer from './isettings';
import modalImageReducer from './modalImage';
import pinnedAuthorsReducer from './pinnedAuthors';
import profileReducer from './profile';
import profileCardReducer from './profileCard';
import pulseReducer from './pulse/pulse';
import pulseChartReducer from './pulse/pulseChart';
import sessionsReducer from './sessions';
import settingsReducer from './settings';
import snackBoxReducer from './snackBox';

import { autoreplyReducer } from 'store/slices/autoreplySlice';
import { bannedReducer } from 'store/slices/bannedSlice';
import { broadcastReducer } from 'store/slices/broadcastSlice';
import { contentReducer } from 'store/slices/contentSlice';
import { messageCreatorReducer } from 'store/slices/messageCreatorSlice';
import { messageFiltersReducer } from 'store/slices/messageFiltersSlice';
import { notificationReducer } from 'store/slices/notificationSlice';
import { pinnedInteractivesReducer } from 'store/slices/pinnedInteractivesSlice';
import { registrationReducer } from 'store/slices/registrationSlise';
import { resizeReducer } from 'store/slices/resizeSlice';
import { scrollBoxReducer } from 'store/slices/scrollBoxSlice';
import { sessionsFilterReducer } from 'store/slices/sessionsFilterSlice';
import { socialsReducer } from 'store/slices/socialsSlice';
import { voteReducer } from 'store/slices/voteSlice';

export const rootReducer = {
  autoreplay: autoreplyReducer,
  broadcast: broadcastReducer,
  banned: bannedReducer,
  scrollBox: scrollBoxReducer,
  messageFilters: messageFiltersReducer,
  messageCreator: messageCreatorReducer,
  content: contentReducer,
  vote: voteReducer,
  socials: socialsReducer,
  sessionsFilter: sessionsFilterReducer,
  resize: resizeReducer,

  settings: settingsReducer,
  feed: feedReducer,
  sessions: sessionsReducer,
  isettings: isettingsReducer,
  profileCard: profileCardReducer,
  snackBox: snackBoxReducer,
  modalImage: modalImageReducer,
  // pulse: pulseReducer,
  // pulseChart: pulseChartReducer,
  chat: chatReducer,
  profile: profileReducer,
  pinnedAuthors: pinnedAuthorsReducer,
  pinnedInteractives: pinnedInteractivesReducer,
  registration: registrationReducer,
  notifications: notificationReducer
};
