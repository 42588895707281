import { VoteMessageActionsConfig } from './constants';
import React from 'react';

import { BroadcastSettingsMenuContainer } from 'containers';

import { Button, Icon, Message, SvgCheckbox, Title } from 'components';

import styles from './VoteMessagesView.module.css';

export function VoteMessagesView({
  messages,
  isMessagesReversed,
  toggleMessagesOrder,
  isOnlyWinners,
  setIsOnlyWinners,
  rangeNumbers,
  isBroadcastMsgState,
  lastExportedMessages,
  setFavoriteState,
  activeBroadcastDataState,
  type,
  winnersId,
  statusFavorite
}) {
  return (
    <>
      {messages.length > 0 && (
        <div className="d-flex align-center ml-5 mb-2">
          <Title color="#808080">{window.t('votesWithComments')}</Title>
          <Button
            startIcon={
              <Icon
                size={20}
                fontName={
                  isMessagesReversed ? 'fal fa-sort-amount-down' : 'fal fa-sort-amount-up-alt'
                }
              />
            }
            className="ml-4 align-self-center"
            onlyIcon
            title={isMessagesReversed ? window.t('newOnTop') : window.t('oldOnTop')}
            variant="icon"
            color="#6f6f6f"
            clickHandler={toggleMessagesOrder}
            style={{ padding: '6px 8px' }}
          />
          {type === 'quiz' && (
            <SvgCheckbox
              isChecked={isOnlyWinners}
              changeHandler={setIsOnlyWinners}
              style={{
                color: '#6f6f6f',
                fontSize: 16,
                opacity: 0.75,
                marginLeft: 10
              }}>
              {window.t('showWinners')}
            </SvgCheckbox>
          )}
        </div>
      )}
      {messages?.length > 0 && (
        <div className={styles['messages-wrapper']}>
          {messages
            .filter((msg) =>
              statusFavorite && statusFavorite.isFavorite
                ? [true, '1'].includes(msg.favorite)
                : true
            )
            .map((el, i) => {
              const isEventualWinner = winnersId.includes(el.id);

              const winnerOrder =
                isEventualWinner &&
                (isMessagesReversed
                  ? winnersId.length - winnersId.indexOf(el.id)
                  : winnersId.indexOf(el.id) + 1);

              const isWinner =
                winnerOrder &&
                (rangeNumbers.length > 0
                  ? rangeNumbers?.includes(winnerOrder.toString())
                  : isEventualWinner);

              return !isOnlyWinners || (isOnlyWinners && isWinner) ? (
                <Message
                  type="broadcast"
                  data={el}
                  order={winnerOrder}
                  isOrderActive={isWinner}
                  key={el.id}
                  isSelected={false}
                  selectMessage={() => {}}
                  messageActionsConfig={VoteMessageActionsConfig}
                  // styles={{
                  //     backgroundColor: '#f2f2f2',
                  //     marginTop: '1px'
                  // }}
                  isBroadcastMsg
                  lastExportedMessages={lastExportedMessages}
                  settingsMenu={<BroadcastSettingsMenuContainer messageId={el.id} />}
                  toggleFavorite={setFavoriteState}
                  activeBroadcastData={activeBroadcastDataState}
                />
              ) : null;
            })}
        </div>
      )}
    </>
  );
}
