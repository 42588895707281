import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  autoreplyId: null,
  isSettingsOpened: false,
  activeAutoreplyData: null
};

export const autoreplySlice = createSlice({
  name: 'autoreply',
  initialState,
  reducers: {
    setAutoreplyId: (state, action) => {
      const { id } = action.payload;
      return {
        ...state,
        autoreplyId: id
      };
    },
    setActiveAutoreplyData: (state, action) => {
      const { data } = action.payload;
      return {
        ...state,
        activeAutoreplyData: data
      };
    },
    updateActiveAutoreplyField: (state, action) => {
      const { field, value } = action.payload;
      return {
        ...state,
        activeAutoreplyData: {
          ...state.activeAutoreplyData,
          [field]: value
        }
      };
    },
    resetAutoreplyStore: () => {
      return {
        ...initialState
      };
    }
  }
});

export const autoreplyActions = autoreplySlice.actions;
export const autoreplyReducer = autoreplySlice.reducer;
