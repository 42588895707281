import PropTypes from 'prop-types';
import React from 'react';

import styles from './Title.module.css';

const propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  weight: PropTypes.oneOf([
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
    'bold',
    'normal'
  ]),
  fontStyle: PropTypes.oneOf(['normal', 'italic']),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

const defaultProps = {
  size: 14,
  level: 3,
  weight: '400',
  fontStyle: 'normal',
  className: '',
  style: {}
};

function Title({
  size,
  level,
  weight,
  fontStyle,
  children,
  className,
  style,
  color,
  ...otherProps
}) {
  const Tag = `h${level}`;
  return (
    <Tag
      className={`${styles['title']} ${className}`}
      style={{ ...style, fontSize: size, fontWeight: weight, fontStyle, color }}
      {...otherProps}>
      {children}
    </Tag>
  );
}

export default Title;

Title.propTypes = propTypes;
Title.defaultProps = defaultProps;
