import React from 'react';

import { formatTimeToPlayer } from 'helpers/dateTime';

import styles from './Time.module.css';

function PastTime(props) {
  return (
    <span className={`${styles.time} ${styles[props.type]}`}>{formatTimeToPlayer(props.time)}</span>
  );
}

export default PastTime;
