import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';

import styles from './Chip.module.css';

const propTypes = {
  label: PropTypes.string.isRequired,
  clickHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  style: PropTypes.object,
  radius: PropTypes.number,
  color: PropTypes.string,
  fontSize: PropTypes.number,
  height: PropTypes.number,
  margin: PropTypes.string,
  className: PropTypes.string
};

const defaultProps = {
  label: '',
  radius: 16,
  color: '#333333',
  style: {},
  fontSize: 32,
  height: 14,
  margin: '4px'
};

function Chip({
  label,
  clickHandler,
  deleteHandler,
  style,
  radius,
  color,
  fontSize,
  height,
  margin,
  className
}) {
  return (
    <div
      className={`${styles['chip']} ${className}`}
      style={{
        ...style,
        fontSize,
        height,
        margin,
        borderRadius: radius,
        color
      }}
      onClick={clickHandler}>
      <span className={styles['chip-label']}>{label}</span>
      {deleteHandler && (
        <Button
          startIcon={<Icon size={14} fontName="fal fa-times" />}
          onlyIcon
          color="#000000de"
          variant="message-action"
          style={{
            display: 'inline-flex',
            opacity: 1,
            padding: 5,
            marginRight: 5
          }}
          clickHandler={deleteHandler}
        />
      )}
    </div>
  );
}

export default Chip;

Chip.propTypes = propTypes;
Chip.defaultProps = defaultProps;
