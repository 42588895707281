import { RANGE_STATUS } from 'store/constants';

export function isValidTime(time) {
  const timeArr = time.split(':');
  return Number.isInteger(+timeArr[0]) && Number.isInteger(+timeArr[1]);
}

export function isTimesEqual(time1, time2) {
  const time1Arr = time1.split(':');
  const time2Arr = time2.split(':');

  return time1Arr[0] === time2Arr[0] && time2Arr[1] === time2Arr[1];
}

export function checkRangeStatus(chartStartDateTime, duration) {
  let now = +new Date();
  let start = +new Date(chartStartDateTime);
  let diff = now - start;
  if (diff > 0) {
    if (Math.abs(diff) <= duration * 60 * 1000) return RANGE_STATUS.active;
    return RANGE_STATUS.unActive;
  } else return RANGE_STATUS.nextStart;
}

export function getFormattedBtnTime(dateTime) {
  let now = new Date();
  let diff = +now - +dateTime;
  let formatted;
  let h = dateTime.getHours();
  let m = formatter(dateTime.getMinutes());
  if (diff / 1000 / 60 / 60 / 24 >= 1) {
    formatted = `${dateTime.getDate()}.${formatter(dateTime.getMonth() + 1)} `;
    formatted += `${h}:${m}`;
    return formatted;
  }
  return `${h}:${m}`;
}

export function normalizeDataArray(data) {
  return data.map((el) => {
    el.name = new Date(el.name).toString();
    return el;
  });
}

export const formatter = (val) => (val < 10 ? `0${val}` : val);
