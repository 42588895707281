import { Icon } from 'components';

import styles from './Wind.module.scss';

const directions = ['nw', 'n', 'ne', 'e', 'se', 's', 'sw', 'w'];

export function Wind({ speed, direction }) {
  if (direction === 'c') {
    return <div>{window.t(`weather_wind_dir_${direction}`)}</div>;
  }

  return (
    <div className={styles.wind}>
      <div className={styles.wind__speed}>{speed}</div>
      <div className={styles.wind__direction}>
        <div
          className={styles.wind__iconContainer}
          style={{
            transform: `rotate(${-90 + directions.indexOf(direction) * 45}deg)`
          }}>
          <Icon size={10} fontName="fas fa-location-arrow" />
        </div>
        <div>{window.t(`weather_wind_dir_${direction}`)}</div>
      </div>
    </div>
  );
}
