import React from 'react';

import {
  ExportButtonsSettingsContainer,
  InteractiveSettingsContainer,
  SocialVotesContainer,
  StatesSettingsContainer
} from 'containers';

import { INTERACTIVES } from 'store/constants';

import { editBroadcast } from 'api/dataLayer';

export function VoteSettingsView({
  type,
  interactiveId,
  toggleSettings,
  broadcastId,
  settings,
  broadcastActivity,
  state,
  activity,
  // guide props
  startGuide,
  setStepIndex,
  interactiveInfoRef,
  socialPollsRef,
  exportButtonsRef,
  statesSettingsRef,
  okButtonRef
}) {
  return (
    <InteractiveSettingsContainer
      type={type}
      interactiveId={interactiveId}
      onCloseHandler={toggleSettings}
      startGuide={startGuide}
      setStepIndex={setStepIndex}
      interactiveInfoRef={interactiveInfoRef}
      okButtonRef={okButtonRef}>
      {type === INTERACTIVES.vote && (
        <SocialVotesContainer voteId={interactiveId} ref={socialPollsRef} />
      )}
      <div className="d-flex flex-wrap mt-5">
        <ExportButtonsSettingsContainer
          ref={exportButtonsRef}
          setStepIndex={setStepIndex}
          sessionId={broadcastId}
          editRequest={editBroadcast}
          settings={settings}
          activity={broadcastActivity}
        />
        <StatesSettingsContainer
          ref={statesSettingsRef}
          setStepIndex={setStepIndex}
          sessionId={broadcastId}
          editRequest={editBroadcast}
          state={state}
          activity={activity}
        />
      </div>
    </InteractiveSettingsContainer>
  );
}
