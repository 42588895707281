import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import Button from 'components/Button/Button';

import styles from './Button.module.css';

function CycleButton({ duration, isActive, setIsActive, children }) {
  const [inProp, setInProp] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    setInProp(isActive);
  }, [isActive]);

  return (
    <Button
      style={{
        margin: 5
      }}
      clickHandler={() => {
        setIsClicked(true);
        setInProp((v) => !v);
      }}
      animatedChildren={
        <CSSTransition
          in={inProp}
          timeout={2 * duration * 1000}
          onEnter={() => {
            if (isClicked) {
              setIsClicked(false);
              setIsActive(!isActive);
            }
          }}
          onExit={() => {
            if (isClicked) {
              setIsClicked(false);
              setIsActive(!isActive);
            }
          }}
          classNames={{
            enterActive: styles['cycle-active'],
            enterDone: styles['cycle-active-done'],
            exitActive: styles['cycle-unactive'],
            exitDone: styles['cycle-unactive-done']
          }}>
          <div
            className="button-progress"
            style={{
              position: 'absolute',
              height: '100%',
              left: 0,
              top: 0,
              zIndex: -1,
              background: '#008fff5e',
              animationDuration: `${2 * duration}s`
            }}
          />
        </CSSTransition>
      }>
      {children}
    </Button>
  );
}

export default CycleButton;
