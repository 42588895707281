import React, { useEffect, useState } from 'react';

import styles from './Range.module.css';

function Range({ minValue, maxValue, step, value, blurHandler, title }) {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <div className={styles.slidecontainer}>
      <div className={styles.title}>
        <span>{title}</span>
        <span>{localValue}x</span>
      </div>
      <input
        onChange={(e) => setLocalValue(e.target.value)}
        onMouseUp={() => blurHandler(localValue)}
        type="range"
        value={localValue}
        min={minValue}
        max={maxValue}
        step={step}
        className={styles.slider}
      />
    </div>
  );
}

export default Range;
