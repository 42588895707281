import cn from 'classnames';
import { memo } from 'react';

import styles from './FeedDate.module.css';

function FeedDate({ className, children, ...props }) {
  return (
    <div className={cn(className, styles.date)} {...props}>
      {children}
    </div>
  );
}

export default memo(FeedDate);
