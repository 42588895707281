import { TABS } from 'containers/Feed/constants';

export function getFetchOptions(feed, currentTab, loadPrev = false, tabs) {
  const options = {
    method: 'POST',
    postBody: {
      action: 'MessagesLoad'
    }
  };
  const length = feed.messages?.items.length;

  options.postBody.feeds = tabs;

  if (loadPrev) {
    options.postBody.param2 = true;

    if (currentTab === TABS.news) {
      const newsLength = feed.news?.items.length;
      const target = feed.news?.items[newsLength - 1];
      const created_in_DB = target?.created_in_DB || target?.create_in_db;
      if (created_in_DB) {
        options.postBody.param4 = created_in_DB;
      }
    }

    if (loadPrev && currentTab === TABS.messages) {
      let id;
      id = feed.messages?.items[length - 1]?.id;
      if (id) {
        options.postBody.param1 = id;
      }
    }
  } else {
    if (
      (!feed.news?.items.length && !feed.messages?.items.length) ||
      (currentTab === TABS.messages && !feed.messages?.items.length)
    ) {
      options.postBody.param1 = 0;
    }

    if (Array.isArray(feed.news?.items) && feed.news?.items.length > 0) {
      const target = feed.news?.items[0];
      const created_in_DB = target?.created_in_DB || target?.create_in_db;
      if (created_in_DB) {
        options.postBody.param4 = created_in_DB;
      }
    }
    if (Array.isArray(feed.messages?.items) && feed.messages?.items.length > 0) {
      let id;
      id = feed.messages?.items[0].id;
      if (id) {
        options.postBody.param1 = id;
      }
    }
  }

  return options;
}
