import classNames from 'classnames';
import React, { useEffect } from 'react';
import ReactCrop from 'react-image-crop';

import { Button, CircularLoader, Icon, InputFile } from 'components';
import Modal from 'components/Modal/Modal';

import styles from './PictureLoaderView.module.css';
import 'react-image-crop/dist/ReactCrop.css';

export function PictureLoaderView({
  id,
  name,
  className,
  style,
  setIsHover,
  iconSize,
  btnStyle,
  picture,
  isImageUploading,
  openModal,
  isModal,
  closeBtnStyles,
  deleteHandler,
  isShownModal,
  setIsShownModal,
  makeClientCrop,
  crop,
  imageCrop,
  imageUrl,
  onImageLoad,
  onSelectFile,
  multiple,
  isValid,
  image,
  aspect_width,
  aspect_height,
  isExported = false,
  isExportedToggleHandler
}) {
  // если фото экспортировано из ленты новостей

  useEffect(() => {
    if (picture && picture.includes('http') && isExported) {
      let fileName = picture.split('/').pop();

      fetch(`${picture}?t=${Date.now()}`)
        .then((res) => {
          return res.blob();
        })
        .then((data) => {
          data.name = fileName;

          onSelectFile({
            target: {
              files: [data]
            }
          });
        });
    }
  }, []);

  return (
    <div className={styles['container']}>
      <div
        className={classNames(className, styles['picture-loader'], {
          [styles['invalidImg']]: !isValid
        })}
        style={{
          ...style,
          ...(picture &&
            picture !== 'null' && {
              backgroundImage: `url("${picture}")`
            }),
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
        onMouseEnter={() => {
          setIsHover(true);
        }}
        onMouseLeave={() => {
          setIsHover(false);
        }}>
        <Modal
          onModalClose={() => {
            setIsShownModal(false);
            // если фото экспортировано из ленты новостей
            if (isExported) {
              isExportedToggleHandler(false);
            }
          }}
          onSaveHandler={makeClientCrop}
          showModal={isShownModal}>
          <ReactCrop
            aspect={aspect_width && aspect_height ? aspect_width / aspect_height : undefined}
            className={styles.reactCrop}
            crop={crop}
            onChange={imageCrop}
            onComplete={imageCrop}>
            <img
              className={styles.reactCrop__image}
              alt="Crop me"
              src={imageUrl}
              onLoad={onImageLoad}
            />
          </ReactCrop>
          <div className={styles.resolutionInfo}>
            {image && (
              <span>
                {window.t('image')}: {image.naturalWidth} Х {image.naturalHeight}
              </span>
            )}
            {crop && image && (
              <span>
                {window.t('frame')}: {Math.round((image.naturalWidth / image.width) * crop.width)} Х{' '}
                {Math.round((image.naturalHeight / image.height) * crop.height)}
              </span>
            )}
          </div>
        </Modal>
        {isImageUploading ? (
          <CircularLoader size={40} loaderColor="#aaaaaa" />
        ) : !picture || picture === 'null' ? (
          <InputFile
            multiple={multiple}
            id={id}
            name={name}
            changeHandler={onSelectFile}
            accept="image/*">
            <Button
              startIcon={<Icon fontName="fal fa-image" size={iconSize} style={{ margin: 0 }} />}
              tag="span"
              variant="icon"
              color="#aaaaaa"
              style={{ ...btnStyle, inlineSize: 'fit-content' }}
              isDisabled={false}
            />
          </InputFile>
        ) : (
          <>
            <div onClick={openModal} className={styles['picture-loader__controls']}>
              <Button
                startIcon={<Icon size={isModal ? 14 : 26} fontName="fal fa-times" />}
                onlyIcon
                type="button"
                variant={isModal ? 'contained' : 'text'}
                color="#fff"
                textColor={isModal ? '#524e4e' : '#fff'}
                activeColor="#fff"
                hoverColor="#fff"
                style={closeBtnStyles}
                clickHandler={(event) => {
                  event.stopPropagation();
                  deleteHandler(event);
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
