import RadioBtn from './RadioBtn/RadioBtn';
import classNames from 'classnames';
import React from 'react';

import styles from './RatePicker.module.css';

function RatePicker(props) {
  const rates = [0.5, 1, 1.25, 1.5, 1.75, 2, 2.5];
  const classes = classNames(styles['rate_list'], {
    hidden: !props.isRatePickerActive
  });
  return (
    <div className={styles['rate_picker']}>
      <div className={classes}>
        {rates.map((el) => {
          return (
            <RadioBtn
              id={props.id}
              changeHandler={() => {
                props.pickRate(el);
              }}
              value={el}
              isActive={el === props.currentRate}
              key={el}
            />
          );
        })}
      </div>
    </div>
  );
}

export default RatePicker;
