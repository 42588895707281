import AudioMediaItem from './AudioMediaItem/AudioMediaItem';
import ImgMediaItem from './ImgMediaItem/ImgMediaItem';
import VideoMediaItem from './VideoMediaItem/VideoMediaItem';
import classNames from 'classnames';
import { range } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Skeleton } from 'components';

import useActions from 'hooks/useActions';
import useFetch from 'hooks/useFetch';

import { getImageByChannel } from 'helpers/image';
import { getRouteInfo } from 'helpers/profile';

import styles from './Media.module.css';

const option = { dataKey: null };

function Media({ setHasMedia, userInfo, routeId, channel }) {
  const { openModalImage } = useActions();
  const [media, setMedia] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [{ response, isLoading, error }, doFetch] = useFetch('api_get_profile_media', option);

  useEffect(() => {
    if (media?.length) {
      setHasMedia?.(true);
    } else {
      setHasMedia?.(false);
    }
  }, [media, routeId]);

  useEffect(() => {
    const { senderNumber, channel } = getRouteInfo(routeId);

    doFetch({
      method: 'POST',
      postBody: {
        action: 'getMedia',
        identifier: senderNumber,
        channel
      }
    });
    return () => {
      setIsActive(true);
      setMedia(null);
    };
  }, [routeId]);

  useEffect(() => {
    if (!response) return;
    if (error || (Array.isArray(response) && response.length === 0)) {
      setIsActive(false);
      return;
    }
    setMedia(response);
  }, [response, error]);

  const openMedia = (imageSrc) => {
    const { author, image } = userInfo;
    const uInfo = {
      avatar: getImageByChannel(channel, image),
      name: author
    };

    const imageList = media
      .filter(
        (el) =>
          el.attachments[0].type?.includes('image') ||
          el.attachments[0].type?.includes('video') ||
          el.attachments[0].type?.includes('audio_msg')
      )
      .map((el) => {
        return {
          src: el.attachments[0].type?.includes('audio_msg')
            ? el.attachments[0].ogg
            : el.attachments[0].url,
          type: el.attachments[0].type,
          publishDate: el.date
        };
      });

    openModalImage(uInfo, imageList, imageSrc);
  };

  const classes = classNames(styles['media-wrapper'], {
    hidden: !isActive
  });

  return (
    <>
      <div className={classes}>
        <div className={styles['media']}>
          <div id="style-3" className={styles['media-content-wrapper']}>
            <div className={styles['media-content']}>
              {isLoading && !media
                ? range(15).map((el) => (
                    <Skeleton className={styles['empty-media-item']} key={el} />
                  ))
                : media?.map?.((el, i) => {
                    const obj = el.attachments[0];
                    return obj.type?.includes('audio_msg') ? (
                      <AudioMediaItem src={obj.ogg} key={obj.type + i} />
                    ) : obj.type?.includes('image') ? (
                      <ImgMediaItem src={obj.url} key={obj.type + i} openMedia={openMedia} />
                    ) : obj.type?.includes('video') ? (
                      <VideoMediaItem src={obj.url} key={obj.type + i} openMedia={openMedia} />
                    ) : null;
                  })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Media;

Media.propTypes = {
  media: PropTypes.array,
  openImage: PropTypes.func
};
