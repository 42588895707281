import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import Button from 'components/Button/Button';

import styles from './Button.module.css';

function StopButton({ duration, isActive, setIsActive, children }) {
  const [inProp, setInProp] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isActivateFinished, setIsActivateFinished] = useState(false);

  useEffect(() => {
    setInProp(isActive);
  }, [isActive]);

  return (
    <Button
      style={{
        margin: 5
      }}
      clickHandler={() => {
        setIsClicked(true);
        setInProp((v) => !v);
      }}
      isActive={isActive && isActivateFinished}
      animatedChildren={
        <CSSTransition
          in={inProp}
          timeout={duration * 1000}
          onEnter={() => {
            if (isClicked) {
              setIsClicked(false);
              setIsActive(!isActive);
            }
          }}
          onEntered={() => {
            setIsActivateFinished(true);
          }}
          // onExited={() => {
          //     if (isClicked) {
          //         setIsClicked(false);
          //         setIsActive(!isActive)
          //     }
          // }}
          onExit={() => {
            if (isClicked) {
              setIsActivateFinished(false);
              setIsClicked(false);
              setIsActive(!isActive);
            }
          }}
          classNames={{
            enterActive: styles['stop-active'],
            enterDone: styles['stop-active-done'],
            exitActive: styles['stop-unactive'],
            exitDone: styles['stop-unactive-done']
          }}>
          <div
            className="button-progress"
            style={{
              position: 'absolute',
              height: '100%',
              left: 0,
              top: 0,
              zIndex: -1,
              background: '#008fff5e',
              animationDuration: `${duration}s`
            }}
          />
        </CSSTransition>
      }>
      {children}
    </Button>
  );
}

export default StopButton;
