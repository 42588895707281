import PropsTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Calendar } from 'react-modern-calendar-datepicker';
import { useSelector } from 'react-redux';

import BlurWrapper from 'components/BlurWrapper/BlurWrapper';
import Button from 'components/Button/Button';
import TimePicker from 'components/TimePicker/TimePicker';

import { DATE_PICKER_LOCALE } from 'store/constants';

import { getPickerObject, getTimePickerValue, pickerObjToString } from 'helpers/dateTime';
import { isTimesEqual } from 'helpers/pulse';

import styles from './DateTimePicker.module.css';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';

function DateTimePicker(props) {
  // const modalRef = useRef(null);
  const date = props.dateTime ? new Date(props.dateTime) : undefined;
  const maxDate = props.maxDate ? getPickerObject(props.maxDate) : undefined;
  const minDate = props.minDate ? getPickerObject(props.minDate) : undefined;
  const currentDate = date ? getPickerObject(date) : undefined;
  const timeValue = date ? getTimePickerValue(date) : undefined;

  // if (minDate)
  //   console.log(format(new Date(minDate), 'hh:mm'));
  // const [selectedTime, setSelectedTime] = useState(timeValue);
  const [selectedDay, setSelectedDay] = useState(currentDate);
  const [isTimeValid, setIsTimeValid] = useState(true);
  const [position, setPosition] = useState({ right: 0, top: 0 });

  const [time, setTime] = useState(timeValue);
  const [errorMsg, setErrorMsg] = useState('');

  const { workPane } = useSelector((state) => state.resize);

  useEffect(() => {
    if (!isTimesEqual(timeValue, time)) setTime(timeValue);
  }, [timeValue]);

  useEffect(() => {
    const w = workPane.current.offsetWidth;
    const h = window.innerHeight;
    if (position.right === 0 && position.top === 0) {
      setPosition({
        right: w / 2,
        top: h / 2
      });
    }
    // modalRef.current.style.right = w / 2 + 'px';
    // modalRef.current.style.top = h / 2 + 'px';
  }, []);

  const isDateTimeLessThanMin = (value) => {
    return (
      minDate &&
      selectedDay &&
      +new Date(`${pickerObjToString(minDate)} ${props.minTime}`) >
        +new Date(`${pickerObjToString(selectedDay)} ${time}`)
    );
  };

  const changeHandler = (dateObj) => {
    setSelectedDay(dateObj);
    if (minDate && isDateTimeLessThanMin()) {
      setErrorMsg('Разница между стартом и финшом должна быть больше n минут.');
    } else {
      setErrorMsg('');
    }
  };

  const okHandler = () => {
    // setSelectedTime("12:34");
    // setTime("dddddd")
    const dateTime = new Date(`${pickerObjToString(selectedDay)} ${time}`);
    props.setDateTime(dateTime);
    props.togglePicker();
  };

  return (
    <BlurWrapper isOpened={props.isActive} clickHandler={props.togglePicker}>
      <div className={styles['picker-wrapper']} style={position}>
        <Calendar
          value={selectedDay}
          onChange={changeHandler}
          shouldHighlightWeekends
          locale={DATE_PICKER_LOCALE}
          renderFooter={() => (
            <div className={styles['picker-footer']}>
              {/* <input className={styles['input-time']} type="time" /> */}
              {/* {date.toString()} */}
              <TimePicker
                value={time}
                changeHandler={(time) => {
                  setTime(time);
                }}
                minDate={minDate}
                selectedDate={selectedDay}
                errorMsg={errorMsg}
                minTime={props.minTime}
                isValid={isTimeValid}
                setErrorMsg={setErrorMsg}
              />
              <div className={styles['buttons-wrapper']}>
                <Button
                  isDisabled={errorMsg.length > 0}
                  color="success"
                  style={{ width: '49%' }}
                  size="large"
                  clickHandler={okHandler}>
                  ОК
                </Button>
                <Button
                  color="danger"
                  styles={{ width: '49%' }}
                  size="large"
                  style={{ width: '49%' }}
                  clickHandler={props.togglePicker}>
                  Отмена
                </Button>
              </div>
            </div>
          )}
          // timeValue={selectedTime} changeHandler={setSelectedTime}
          maxDate={maxDate}
          minDate={minDate}
        />
      </div>
    </BlurWrapper>
  );
}

DateTimePicker.propsTypes = {
  dateTime: PropsTypes.string
};

DateTimePicker.defaultProps = {};

export default DateTimePicker;
