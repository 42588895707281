import random from 'lodash/random';
import React from 'react';

import Skeleton from 'components/Skeleton/Skeleton';

function SessionSkeleton() {
  const type = random(1, 3);

  const getRadius = () => (type === 1 ? '50%' : type === 2 ? 10 : 15);

  return (
    <div
      className="d-flex w-full pa-3"
      style={{
        background: '#fff',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
        // boxShadow: '0px 2px 5px rgb(0 0 0 / 15%)'
      }}>
      <div className="d-flex flex-column align-center">
        <Skeleton width={46} height={46} borderRadius={getRadius()} />
      </div>
      <div className="flex-grow ml-3 d-flex flex-column">
        <Skeleton width={144} height={17} borderRadius={8} />
        <Skeleton className="mt-1" width={20} height={13} borderRadius={6} />
        <Skeleton className="mt-3" width={200} height={14} borderRadius={8} />
      </div>
    </div>
  );
}

export default SessionSkeleton;
