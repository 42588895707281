import { format } from 'date-fns';

export const fields = [
  {
    title: 'Автор',
    fieldName: 'author',
    labelKey: 'author'
  },
  {
    title: 'Дата и время',
    fieldName: 'date',
    labelKey: 'dateAndTime'
  },
  {
    title: 'Сообщение',
    fieldName: 'content',
    labelKey: 'message'
  }
];

export const message = {
  id: '',
  broadcast_id: '',
  name: '',
  type: 'broadcast',
  description: 'comment',
  memo: [1],
  author: '*****',
  senderNumber: '',
  content: '',
  channel: 'sms',
  date: format(new Date(), 'yyyy-MM-dd HH:mm'),
  image: 1,
  is_created: 1,
  status: '',
  favorite: '',
  attachments: [],
  labels: [],
  region: ''
};
