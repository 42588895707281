export const move = (array, element, delta) => {
  const index = array.indexOf(element);
  const newIndex = index + delta;
  if (newIndex < 0 || newIndex === array.length) return;
  const indexes = [index, newIndex].sort();
  array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]);
};

export const moveUp = (array, element) => {
  const newArray = [...array];
  move(newArray, element, -1);
  return newArray;
};

export const moveDown = (array, element) => {
  const newArray = [...array];
  move(newArray, element, 1);
  return newArray;
};
