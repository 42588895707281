import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  socials: [],
  posts: [],
  filteredPosts: [],
  searchText: '',
  isAuthenticated: true
};

export const socialsSlice = createSlice({
  name: 'socials',
  initialState,
  reducers: {
    fetchSocialsSuccess: (state, action) => {
      const { socials } = action.payload;
      return {
        ...state,
        socials
      };
    },
    fetchPostsSuccess: (state, action) => {
      const { posts } = action.payload;
      return {
        ...state,
        posts,
        filteredPosts: posts,
        searchText: ''
      };
    },
    setAuthState: (state, action) => {
      const { isAuthenticated } = action.payload;
      return {
        ...state,
        isAuthenticated
      };
    },
    makeChannelActiveSuccess: (state, action) => {
      const { channel } = action.payload;
      return {
        ...state,
        socials: state.socials.map((social) =>
          social.channel === channel
            ? {
                ...social,
                isActive: true
              }
            : {
                ...social,
                isActive: false
              }
        )
      };
    },
    togglePostSuccess: (state, action) => {
      const { id, checked } = action.payload;
      return {
        ...state,
        posts: state.posts.map((post) =>
          post.id === id
            ? {
                ...post,
                checked
              }
            : post
        ),
        filteredPosts: state.filteredPosts.map((post) =>
          post.id === id
            ? {
                ...post,
                checked
              }
            : post
        )
      };
    },
    toggleAllPostsSuccess: (state, action) => {
      const { checked } = action.payload;
      return {
        ...state,
        posts: state.posts.map((post) => {
          return {
            ...post,
            checked
          };
        }),
        filteredPosts: state.filteredPosts.map((post) => {
          return {
            ...post,
            checked
          };
        })
      };
    },
    setFilteredPosts: (state, action) => {
      const { posts } = action.payload;
      return {
        ...state,
        filteredPosts: posts
      };
    },
    setSocialsSearchText: (state, action) => {
      const { searchText } = action.payload;
      return {
        ...state,
        searchText
      };
    }
  }
});

export const socialsActions = socialsSlice.actions;
export const socialsReducer = socialsSlice.reducer;
