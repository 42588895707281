import { breakTagsAndInsertText, getFormattedHtml, getTextAndEffects, selectText } from './helpers';
import classNames from 'classnames';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import sanitizeHtml from 'sanitize-html';

import { checkIsValid } from 'containers/ContentContainer/components/NewsBlock/components/ContentFields/helpers/validationFields';

import styles from './TextEditor.module.css';

export const TextEditor = forwardRef(
  (
    {
      textFromSpeech,
      text,
      setText,
      send,
      blurHandler,
      focusHandler,
      changeHandler,
      style,
      focusable,
      onKeyDown,
      placeholder,
      label,
      children,
      controls,
      inputRef,
      afterLabel,
      textEffects,
      className,
      block,
      updateContentBlockField = () => {},
      isValidPreview,
      elementKey
    },
    ref
  ) => {
    const innerRef = useRef(null);
    const [html, setHtml] = useState(null);
    const localRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);

    const isValid = useMemo(
      () =>
        checkIsValid(
          elementKey,
          block,
          block?.isTouched,
          null,
          updateContentBlockField,
          isValidPreview
        ),
      [text.text, block?.isTouched, isValidPreview]
    );

    const classes = classNames(className, styles['main-chat-msg-input'], {
      [styles['focusable']]: focusable,
      [styles['invalid']]: !isValid
    });
    const hasBeenInitiallySet = useRef(false);

    useEffect(() => {
      if (hasBeenInitiallySet.current) return;
      hasBeenInitiallySet.current = true;

      const result = getFormattedHtml(text);

      setHtml(
        sanitizeHtml(result, {
          allowedTags: ['br', 'font'],
          allowedAttributes: { font: ['color'] }
        })
      );
    }, [text]);

    useEffect(() => {
      if (textFromSpeech === null) return;

      changeHandler({ target: { value: textFromSpeech } });
      const result = getFormattedHtml(textFromSpeech);

      setHtml(
        sanitizeHtml(result, {
          allowedTags: ['br', 'font'],
          allowedAttributes: { font: ['color'] }
        })
      );
      resizeHeight();
    }, [textFromSpeech]);

    useEffect(() => {
      const node = selectText('temp-font');
      if (node) {
        document.execCommand('removeFormat', false, 'foreColor');
      }
    }, [html]);

    useEffect(() => {
      if (html === null) return;
      const result = getTextAndEffects(innerRef.current);
      setText(result);
      changeHandler({ target: { value: result } });
    }, [html]);

    const focus = () => {
      if (innerRef) innerRef.current.focus();
      else if (inputRef) inputRef.current.focus();
      else localRef.current.focus();
    };

    const resizeHeight = () => {
      const target = innerRef.current;
      if (!target) return;
      target.style.height = 'inherit';
      target.style.height = `${target.scrollHeight}px`;
    };

    const handleChange = (event) => {
      setHtml(
        sanitizeHtml(event.target.value, {
          allowedTags: ['br', 'font'],
          allowedAttributes: { font: ['color', 'id'] }
        })
      );
      changeHandler(event);
      resizeHeight();
    };

    return (
      <div ref={ref} className={classes} style={style}>
        <div className="d-flex">
          {label && (
            <span
              onClick={focus}
              className={classNames(styles['label'], {
                [styles['label-focused']]: isFocused
              })}>
              {label}
              {afterLabel}
            </span>
          )}
          <div className="d-flex ml-auto">
            {/* костыль - первая кнопка воспринимается как label для textarea */}
            <button
              style={{
                outline: 'none',
                background: 'transparent'
              }}
            />
            {!!textEffects?.length && (
              <div className={styles.colors}>
                <EditButton
                  className={classNames(styles.colors__button, styles.colors__button_clear)}
                  children={<i className="fas fa-plus" />}
                  cmd="removeFormat"
                  arg="foreColor"
                />
                {textEffects
                  .filter((el) => el.type === 'color')
                  .map((el) => (
                    <EditButton
                      className={styles.colors__button}
                      key={el.id}
                      cmd="foreColor"
                      arg={el.value}
                    />
                  ))}
              </div>
            )}
            {controls}
          </div>
        </div>
        <div className={styles['main-chat-msg-input-wrapper']}>
          {(text?.text ?? text).length <= 0 && (
            <div className={styles['main-chat-msg-input-wrapper_placeholder']}>{placeholder}</div>
          )}
          {html !== null && (
            <ContentEditable
              innerRef={innerRef}
              className={styles['main-chat-msg-input-wrapper_text']}
              html={html} // innerHTML of the editable div
              onFocus={(event) => {
                resizeHeight(event.target);
                setIsFocused(true);
                focusHandler(event);
              }}
              onBlur={(event) => {
                resizeHeight(event.target);
                if (blurHandler) blurHandler();
                setIsFocused(false);
              }}
              onKeyDown={(event) => {
                const key = event.key;

                if (
                  key === 'Meta' &&
                  !event.altKey &&
                  !event.ctrlKey &&
                  (isFinite(parseInt(key)) || key.length === 1)
                ) {
                  breakTagsAndInsertText(key);
                }

                event.target.style.height = 'inherit';
                event.target.style.height = `${event.target.scrollHeight}px`;

                onKeyDown?.();
                if (event.key === 'Enter') {
                  document.execCommand('insertLineBreak');
                  event.preventDefault();
                } else if (event.key === 'Enter' && event.shiftKey) {
                  document.execCommand('insertLineBreak');
                  event.preventDefault();
                }
              }}
              onChange={handleChange}
            />
          )}
        </div>
        {children}
      </div>
    );
  }
);

function EditButton(props) {
  return (
    <button
      className={props.className}
      style={props.style}
      {...(props.cmd === 'foreColor' &&
        props.arg && {
          style: {
            ...props.style,
            background: props.arg
          }
        })}
      key={props.cmd}
      onMouseDown={(evt) => {
        evt.preventDefault(); // Avoids loosing focus from the editable area
        document.execCommand(props.cmd, false, props.arg); // Send the command to the browser
        const selection = window.getSelection();
        selection.removeAllRanges();
      }}>
      {props.children}
    </button>
  );
}
