import { updateProfileSettings } from './profile';
import { debounce } from 'lodash';

import { pinnedInteractivesActions } from 'store/slices/pinnedInteractivesSlice';

import { editProfile } from 'api/dataLayer';

export function updatePinInteractive(currentInteractive) {
  if (!currentInteractive) {
    return;
  }
  return async (dispatch, getState) => {
    const pinInteractives = getState().pinnedInteractives.interactives;

    const isPinnedInteractive = Boolean(
      pinInteractives.find(
        (el) => el.id === currentInteractive.id && el.type === currentInteractive.type
      )
    );
    if (isPinnedInteractive) {
      dispatch(
        updateProfileSettings({
          pinnedInteractives: pinInteractives.filter(
            (el) => el.type !== currentInteractive.type || el.id !== currentInteractive.id
          )
        })
      );
    } else {
      dispatch(
        updateProfileSettings({
          pinnedInteractives: pinInteractives?.length
            ? [currentInteractive, ...pinInteractives]
            : [currentInteractive]
        })
      );
    }
    dispatch(pinnedInteractivesActions.togglePinInteractive(currentInteractive));
  };
}

export const debounceUpdateProfileSettings = debounce(async (config, id) => {
  if (!id) return;
  try {
    await editProfile({
      profileId: id,
      parametr: 'settings',
      value: config
    });
  } catch (error) {
    console.error(error);
  }
}, 2000);
