import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import useActions from 'hooks/useActions';

import { goTo } from 'helpers/routing';

export const useLeftToolbar = ({ history }) => {
  const location = useLocation();
  const { setActiveLeftMenu } = useActions();
  const { activeMenu } = useSelector((state) => state.messageFilters);
  const { totalNoteCount } = useSelector((state) => state.settings);

  const toggleHandler = (menu) => {
    const newActiveMenu = activeMenu === menu ? null : menu;
    setActiveLeftMenu(newActiveMenu);
  };
  // const [{ response, isLoading, error }, doFetch] = useFetch('api_get_token');

  // useEffect(() => {
  //   if (!response)
  //     return;

  //   if (response) {
  //     const { clientDomain, token, userNum } = response;
  //     window.open(`http://atelier.iactive.pro/auth/client?token=${token}&userNum=${userNum}&clientDomain=${clientDomain}`, '_blank')
  //   }

  // }, response)

  // useEffect(() => {
  //   if (error)
  //     addSnack({
  //       type: 'danger',
  //       message: window.t('errDomainInfo'),
  //     })
  // }, [error])

  const changeRoute = (path) => goTo(history, path, null, location);

  return {
    totalNoteCount,
    activeMenu,
    toggleHandler,
    changeRoute
  };
};
