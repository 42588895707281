import { Skeleton } from 'components';

import styles from './ProfileLoader.module.scss';

export function ProfileLoader() {
  return (
    <div className={styles['profile-loading']}>
      <Skeleton className={styles['profile-loading__image']} />
      <Skeleton className={styles['profile-loading__name']} />
    </div>
  );
}
