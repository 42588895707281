import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import {
  AutoReply,
  Broadcast,
  Chat,
  Content,
  ProfileCard,
  Pulse,
  Registration,
  Sessions,
  Settings,
  Vote,
  Welcome
} from 'pages';

import { LeftToolbarContainer } from 'containers';
import { Feed } from 'containers/Feed/Feed';

import { ErrorScreen, ModalGallery, SnackBox } from 'components';

import useActions from 'hooks/useActions';
import useLocalStorage from 'hooks/useLocalStorage';
import { useNotifications } from 'hooks/useNotifications';

import { LocaleContext } from 'providers/LocaleProvider';

import { getDomainInfo } from 'api/dataLayer';

import { getCurrentDomain } from 'helpers/routing';

import './assets/styles/style.css';

function App() {
  const { updateProfileSettings } = useActions();
  const [localeState] = useContext(LocaleContext);
  const {
    setWorkPaneRef,
    setSocials,
    setEnableInteractives,
    fetchProfiles,
    updateCurrentProfileSettings,
    initTabs,
    updatePinnedAuthors,
    setPinnedAuthors
  } = useActions();
  const [_, setUserInfo] = useLocalStorage('userInfo', null);
  const [hasInitialData, setHasInitialData] = useState(false);

  const workPaneRef = useRef(null);
  const settings = useSelector((state) => state.settings);
  const { isOpened } = useSelector((state) => state.modalImage);
  const { workPaneWidth } = useSelector((state) => state.resize);
  const currentProfile = useSelector((state) => state.profile.currentProfile);
  const authors = useSelector((state) => state.settings.authors);
  const previousProfileIdRef = useRef(null);
  const currentProfileIdRef = useRef(null);
  const hasBeenSet = useRef(null);

  useEffect(() => {
    if (hasBeenSet.current) return;
    setPinnedAuthors(authors.map((item) => ({ ...item, messages: [] })));
    hasBeenSet.current = true;
  }, [authors, setPinnedAuthors]);

  useEffect(() => {
    updatePinnedAuthors(authors);
  }, [authors, updatePinnedAuthors]);

  useEffect(() => {
    if (!currentProfile) return;
    previousProfileIdRef.current = currentProfileIdRef.current;
    currentProfileIdRef.current = currentProfile.id;
  }, [currentProfile]);

  window.t = window.tr(localeState);

  useNotifications({ localeState });

  useEffect(() => {
    if (settings.authorsUpdatedFromSocket) return;

    if (
      previousProfileIdRef.current &&
      currentProfileIdRef.current &&
      previousProfileIdRef.current !== currentProfileIdRef.current
    )
      return;

    updateProfileSettings({
      authors: settings.authors
    });
  }, [settings.authors, settings.authorsUpdatedFromSocket]);

  useEffect(() => {
    setWorkPaneRef({ ref: workPaneRef });
  }, [setWorkPaneRef]);

  useEffect(() => {
    updateCurrentProfileSettings(settings);
  }, [settings]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getDomainInfo(getCurrentDomain());
        const info = res.body;

        initTabs(info.feeds?.length ? info.feeds : ['messages']);

        setEnableInteractives(
          info?.interactives || ['content', 'quiz', 'vote', 'broadcast', 'autoreply']
        );
        setSocials(info.socials);
        setUserInfo(info);
        setHasInitialData(true);
        fetchProfiles();
      } catch (ex) {
        console.error(ex);
        setHasInitialData(null);
      }
    };
    fetchData();
  }, [setSocials, setEnableInteractives]);
  return (
    <>
      {hasInitialData === null ? (
        <ErrorScreen />
      ) : (
        <>
          <LeftToolbarContainer />
          {hasInitialData && <Feed hasInitialData={hasInitialData} />}
          <div
            className="work-panel"
            style={{ width: `calc(${workPaneWidth}% - 38px)` }}
            ref={workPaneRef}>
            <Switch>
              <Route path="/" exact component={Welcome} />
              <Route path="/sessions" exact>
                <Sessions hasInitialData={hasInitialData} />
              </Route>
              <Route path="/settings" exact component={Settings} />
              <Route path="/broadcast/:id" component={Broadcast} />
              <Route path="/content/:id" component={Content} />
              <Route path="/pulse/:id" component={Pulse} />
              <Route path="/autoreply/:id" component={AutoReply} />
              <Route exact path="/quiz/:id" render={(props) => <Vote type="quiz" {...props} />} />
              <Route path="/vote/:id" component={Vote} />
              <Route path="/chat/:id" component={Chat} />
              <Route path="/registration/:id" component={Registration} />
              <Route path="/profile/:id" component={ProfileCard} />
              <Route component={Welcome} />
            </Switch>
            <div id="work-pane-modals" />
          </div>
          {isOpened && <ModalGallery />}
          <SnackBox />
        </>
      )}
    </>
  );
}

export default App;
