import React from 'react';
import { useSelector } from 'react-redux';

import { SvgCheckbox } from 'components';

import useActions from 'hooks/useActions';

import styles from './InternetTrafficSettings.module.css';

function InternetTrafficSettings() {
  const { updateBroadcastConfig } = useActions();

  const { broadcastConfig } = useSelector((state) => state.settings);
  const { noAutoload } = broadcastConfig;

  const style = {
    fontWeight: 700,
    borderBottom: '1px dashed #d6d6d6',
    paddingBottom: 5,
    marginTop: 10,
    maxWidth: 350
  };

  const toggleAutoload = (isChecked) => updateBroadcastConfig('noAutoload', isChecked);

  return (
    <div className={styles['other-sett']}>
      <h3 className={styles['its__title']}>{window.t('internetAndTraffic')}</h3>
      <SvgCheckbox
        isChecked={noAutoload}
        changeHandler={toggleAutoload}
        svgMarginLeft="auto"
        style={style}
        dataTitle={window.t('saveTraffic')}>
        {window.t('loadingAudioOnPlayback')}
      </SvgCheckbox>
    </div>
  );
}

export default InternetTrafficSettings;
