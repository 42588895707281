import React, { useCallback, useMemo } from 'react';

import Icon from 'components/Icon/Icon';

import useActions from 'hooks/useActions';

import { INTERACTIVE_ACTIVITIES } from 'store/constants';

export const useBroadcast = ({ broadcastId, broadcastActivity }) => {
  const { startBroadcast, stopBroadcast } = useActions();

  const startVoteBroadcast = useCallback(
    () => startBroadcast(broadcastId, 'broadcastActivity'),
    [startBroadcast, broadcastId]
  );
  const stopVoteBroadcast = useCallback(
    () => stopBroadcast(broadcastId, 'broadcastActivity'),
    [stopBroadcast, broadcastId]
  );

  const broadcastProps = useMemo(() => {
    const isBroadcastActive = broadcastId && broadcastActivity === INTERACTIVE_ACTIVITIES.active;

    return {
      endIcon: isBroadcastActive ? (
        <Icon size={20} fontName="far fa-stop" />
      ) : (
        <Icon size={20} fontName="far fa-play" />
      ),
      title: isBroadcastActive ? window.t('stopBroadcast') : window.t('startBroadcast'),
      color: isBroadcastActive ? '#ff4d29' : '#00bb00',
      clickHandler: isBroadcastActive ? stopVoteBroadcast : startVoteBroadcast,
      key: isBroadcastActive ? 'stopTV' : 'startTV'
    };
  }, [broadcastId, broadcastActivity, startVoteBroadcast, stopVoteBroadcast]);

  return {
    broadcastProps
  };
};
