import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { Button, Icon } from 'components';

const propTypes = {
  icon: PropTypes.string,
  buttonStyle: PropTypes.object,
  newItemsCount: PropTypes.number,
  scrollToNewItems: PropTypes.func
};

export const NewItemsButtonView = memo(({ icon, buttonStyle, newItemsCount, scrollToNewItems }) => {
  return (
    <Button
      startIcon={<Icon size={14} color="#9db4cc" fontName={icon} />}
      clickHandler={scrollToNewItems}
      className="p-absolute"
      variant="contained"
      color="#fff"
      hoverColor="#ececec"
      activeColor="#ececec"
      textColor="#808080"
      style={buttonStyle}>
      {newItemsCount}
    </Button>
  );
});

NewItemsButtonView.propTypes = propTypes;
