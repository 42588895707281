import classNames from 'classnames';
import { memo, useEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import { Button } from 'components';

import styles from './ResponseMessage.module.css';

function ResponseMessage({ style, channel, isNew, children }) {
  const [needHide, setNeedHide] = useState(false);
  const [isHided, setIsHided] = useState(true);
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current.offsetHeight > 36) {
      setNeedHide(true);
    }
  }, []);

  return (
    <div
      style={style}
      className={classNames(styles['response'], channel && styles[`chan-${channel.trim()}`], {
        [styles['response--animation']]: isNew
      })}>
      <AnimateHeight duration={400} height={needHide && isHided ? 36 : 'auto'}>
        <p ref={textRef} className={styles.text}>
          {children}
        </p>
      </AnimateHeight>
      {needHide && (
        <Button
          variant="text"
          clickHandler={() => setIsHided(!isHided)}
          color="#949494"
          hoverColor="transparent"
          activeColor="transparent"
          style={{
            padding: '5px 0',
            minWidth: 'unset',
            fontSize: '12px',
            display: 'flex'
          }}>
          {isHided ? window.t('more') : window.t('hide')}
        </Button>
      )}
    </div>
  );
}

export default memo(ResponseMessage);
