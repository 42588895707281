import PropsTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import BlurWrapper from 'components/BlurWrapper/BlurWrapper';
import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import CardActions from 'components/Card/CardActions/CardActions';
import CardContent from 'components/Card/CardContent/CardContent';
import Typography from 'components/Typography/Typography';

import styles from './PulseModal.module.css';

function PulseModal({ isActive, togglePicker, message, cancelHandler, acceptHandler }) {
  const [position, setPosition] = useState({ right: 0, top: 0 });
  const { workPane } = useSelector((state) => state.resize);

  useEffect(() => {
    const w = workPane.current.offsetWidth;
    const h = window.innerHeight;

    if (position.right === 0 && position.top === 0) {
      setPosition({
        right: w / 2,
        top: h / 2,
        width: 370
      });
    }
  });

  return (
    <BlurWrapper isOpened={isActive} clickHandler={togglePicker}>
      <div className={styles['modal-wrapper']} style={position}>
        <Card>
          <CardContent>
            <Typography>{message}</Typography>
          </CardContent>
          <CardActions styles={{ justifyContent: 'flex-end' }}>
            <Button color="danger" clickHandler={cancelHandler}>
              Отмена
            </Button>
            <Button color="success" clickHandler={acceptHandler}>
              ОК
            </Button>
          </CardActions>
        </Card>
      </div>
    </BlurWrapper>
  );
}

PulseModal.propsTypes = {
  isActive: PropsTypes.bool,
  togglePicker: PropsTypes.func,
  message: PropsTypes.string,
  cancelHandler: PropsTypes.func,
  acceptHandler: PropsTypes.func
};

PulseModal.defaultProps = {
  isActive: false
};

export default PulseModal;
