import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  scrollBoxRef: null,
  lastScrollTop: null
};

export const scrollBoxSlice = createSlice({
  name: 'scrollBox',
  initialState,
  reducers: {
    setScrollBoxRef: (state, action) => {
      const { ref, tab } = action.payload;
      return {
        ...state,
        scrollBoxRef: ref
      };
    },
    saveScrollTopValue: (state, action) => {
      const { scrollTop } = action.payload;
      return {
        ...state,
        lastScrollTop: scrollTop
      };
    }
  }
});

export const scrollBoxActions = scrollBoxSlice.actions;
export const scrollBoxReducer = scrollBoxSlice.reducer;
