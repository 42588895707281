import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { requestApiGet, requestApiPost } from 'api';

const cache = {};

const defaultOption = {};
const defaultOptions = {};

const useFetch = (routeID, option = defaultOption) => {
  const [isForceFetch, setIsForceFetch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [options, setOptions] = useState(null);
  const abortControllers = useRef([]);

  const doFetch = useCallback((options = defaultOptions) => {
    setResponse(null);
    setError(null);
    setOptions(options);
  }, []);

  const doForceFetch = useCallback((options = defaultOptions) => {
    setIsForceFetch(true);
    setResponse(null);
    setError(null);
    setOptions(options);
  }, []);

  const handleFetch = useCallback(async () => {
    const { dataKey = 'body', withCache } = option;
    try {
      if (!isForceFetch && withCache && cache[routeID]) {
        setResponse(cache[routeID]);
      } else {
        if (options) {
          setIsForceFetch(false);
          setIsLoading(true);
          const { method, getParams, postBody } = options;
          abortControllers.current.forEach((controller, i) => {
            controller?.abort();
            abortControllers.current.splice(i, 1);
          });
          const controller = new AbortController();
          abortControllers.current.push(controller);
          const res =
            method === 'GET'
              ? await requestApiGet(routeID, getParams, controller.signal)
              : await requestApiPost(routeID, postBody, getParams, controller.signal);
          const data = dataKey === null ? res : res[dataKey];

          if (withCache) cache[routeID] = data;

          setResponse(data);
          setIsLoading(false);
        }
      }
    } catch (ex) {
      if (ex.name !== 'AbortError') {
        setError(ex);
        setIsLoading(false);
      }
    }
  }, [option, options, routeID]);

  const handleFetchWithDebounce = useMemo(() => debounce(handleFetch, 100), [handleFetch]);

  useEffect(() => {
    handleFetchWithDebounce();
    const controllers = abortControllers.current;
    return () => controllers.forEach((controller) => controller?.abort());
  }, [handleFetchWithDebounce]);

  const data = useMemo(() => ({ isLoading, response, error }), [isLoading, response, error]);
  const result = useMemo(() => [data, doFetch, doForceFetch], [data, doFetch, doForceFetch]);

  return result;
};

export default useFetch;
