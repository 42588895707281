import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import styles from './SessionModuleView.module.css';

const propTypes = {
  scrollHandler: PropTypes.func,
  style: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string
};

export const SessionModuleView = forwardRef(
  ({ scrollHandler, style, children, className }, ref) => {
    return (
      <div
        ref={ref}
        onScroll={scrollHandler}
        style={style}
        className={`${styles['work-content']} ${className}`}>
        {children}
      </div>
    );
  }
);

SessionModuleView.propTypes = propTypes;
