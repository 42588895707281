import { memo } from 'react';

import s from './Participation.module.css';

export const Participation = memo(
  ({
    edit,
    typeOfParticipation,
    toggleParticipation,
    card_id,
    type,
    title,
    editAuthorData = () => {},
    iactive_id = null,
    author_data = {}
  }) => {
    const myStyle =
      type === 'plan'
        ? { cursor: edit ? 'pointer' : 'auto', pointerEvents: edit ? 'all' : 'none' }
        : typeOfParticipation
        ? { cursor: edit ? 'pointer' : 'auto', pointerEvents: edit ? 'all' : 'none' }
        : {};

    const handleClick = (value) => {
      if (type === 'plan') {
        toggleParticipation({
          card_id,
          field: 'participation',
          value
        });
      } else {
        toggleParticipation({
          card_id,
          field: 'participationFact',
          value
        });
        if (typeOfParticipation === null) {
          editAuthorData(card_id, iactive_id, { ...author_data, participationFact: value });
        }
      }
    };
    return (
      <div className={s.participation}>
        <span className={s.title}>{title}</span>
        <div className={s.participation__btnBlock}>
          <span
            className={`${s.participation__btn} ${typeOfParticipation === 'On-line' && s.active}`}
            style={myStyle}
            onClick={(e) => {
              if (typeOfParticipation === 'On-line') return;
              handleClick('On-line');
            }}>
            {window.t('online')}
          </span>
          <span
            className={`${s.participation__btn} ${typeOfParticipation === 'Off-line' && s.active}`}
            style={myStyle}
            onClick={(e) => {
              if (typeOfParticipation === 'Off-line') return;
              handleClick('Off-line');
            }}>
            {window.t('offline')}
          </span>
        </div>
      </div>
    );
  }
);
