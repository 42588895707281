export function hidePhoneNumber(string, replaceTo = '*') {
  const number = parseInt(string);
  if (!number) return string;
  const numberString = number?.toString();
  if (number) {
    return `${numberString.startsWith('7') ? '+' : ''}${numberString[0]}${numberString
      .slice(1, -4)
      .split('')
      .map(() => replaceTo)
      .join('')}-${numberString.slice(-4, -2)}-${numberString.slice(-2)}`;
  }
  return numberString;
}
